// @flow
import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'
import { API_BASE_URL } from 'config'
import { generateUrl } from 'utils'
import { getApiErrorKey } from 'apiErrors'
import FormData from 'form-data'

import AppLogic from 'containers/App/logic'
// eslint-disable-next-line max-len
import TechnicianUnitsLogic from 'scenes/TechnicianProfile/TechnicianUnits/logic'

const SEARCH_ENDPOINT = `${API_BASE_URL}/devices/search/{inverterNumber}`
// eslint-disable-next-line max-len
const REQUEST_ACCESS_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/roles/requests`
// eslint-disable-next-line max-len
const SHOW_PANEL_ACCESS_ENDPOINT = `${API_BASE_URL}/devices/roles/requests?deviceId={deviceId}`

export default kea({
  path: () => ['scenes', 'containers', 'UnitsSearchPage'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'user'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'fetchCurrentDeviceInfo'
      ]
    ]
  },

  actions: () => ({
    setValue: (value) => ({ value }),
    performSearch: () => ({ }),
    setSearchedUnit: (result) => ({ result }),
    setSearch: (value) => ({ value }),
    requestAccessToUnit: () => ({ }),
    goToDashboard: (unit) => ({ unit }),
    error: (error) => ({ error }),
    setPendingUnitSearched: (result) => ({ result }),
    goToPendingUnitDetails: () => ({ }),
    clearSearch: () => ({ }),

    reset: () => ({ })
  }),

  reducers: ({ actions }) => ({
    unitSearch: ['', PropTypes.string, {
      [actions.setValue]: (state, payload) => payload.value,
      [actions.reset]: () => ''
    }],

    searched: [false, PropTypes.bool, {
      [actions.setSearchedUnit]: () => true,
      [actions.setValue]: (state, payload) => false,
      [actions.setSearch]: (state, payload) => payload.value,
      [actions.reset]: () => false,
      [actions.clearSearch]: () => false
    }],

    searchedUnit: [{}, PropTypes.object, {
      [actions.setSearchedUnit]: (state, payload) => payload.result,
      [actions.reset]: () => ({ }),
      [actions.clearSearch]: () => ({ })
    }],

    error: [null, PropTypes.any, {
      [actions.error]: (state, payload) => payload.error,
      [actions.reset]: () => null,
      [actions.clearSearch]: () => null
    }],

    pendingUnitSearched: [{}, PropTypes.object, {
      [actions.setPendingUnitSearched]: (state, payload) => payload.result,
      [actions.reset]: () => ({ }),
      [actions.clearSearch]: () => ({ })
    }]
  }),

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.performSearch]: workers.performSearch,
    [actions.requestAccessToUnit]: workers.requestAccessToUnit,
    [actions.goToDashboard]: workers.goToDashboard,
    [actions.goToPendingUnitDetails]: workers.goToPendingUnitDetails
  }),

  workers: {

    * performSearch () {
      const { setSearchedUnit, setSearch } = this.actions

      yield put(setSearch(false))

      const unitSearch: string = yield this.get('unitSearch')
      let searchResult = {}

      if (unitSearch.trim() === '') {
        return false
      }

      const url = generateUrl(SEARCH_ENDPOINT, { inverterNumber: unitSearch })

      try {
        const response = yield call(axios.get, url)
        let { result, successful } = response.data

        if (successful) {
          searchResult = result
        }
      } catch (er) {
        console.log(er)
      }

      yield put(setSearchedUnit(searchResult))
    },

    * requestAccessToUnit () {
      const searchedUnit = yield this.get('searchedUnit')
      const isMobile = yield this.get('isMobile')
      const user = yield this.get('user')

      const { navigate, error } = this.actions

      // eslint-disable-next-line max-len
      const url = generateUrl(REQUEST_ACCESS_ENDPOINT, { deviceId: searchedUnit.id })

      const data = new FormData()
      data.append('userId', user.id)

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }

      try {
        const response = yield call(axios.post, url, data, config)
        let { successful } = response.data

        if (successful) {
          yield put(TechnicianUnitsLogic.actions.setSwitcherSelected('pending'))
          yield put(TechnicianUnitsLogic.actions.fetchPendingUnits())
          if (isMobile) {
            yield put(navigate('/'))
          } else {
            yield put(TechnicianUnitsLogic.actions.setPanelToShow(''))
          }
        }
      } catch (err) {
        if (err.response) {
          const result = err.response
          const { message } = result.data

          const translations = yield this.get('translations')
          const errorKey = getApiErrorKey(message, '')

          yield put(error(translations[errorKey] || ''))
        }
      }
    },

    * goToDashboard (actionPayload) {
      const unit = actionPayload.payload.unit
      const {
        navigate,
        fetchCurrentDeviceInfo
      } = this.actions

      yield put(TechnicianUnitsLogic.actions.setPanelToShow(''))
      yield put(navigate((`/${unit.id}/dashboard/`)))
      yield put(fetchCurrentDeviceInfo())
    },

    /**
     * worker to get the pending unit data
     * if a search to a unit already pending is performed
     * and a request to access this unit is made
     * The request to access returns an error
     * and the user is redirected to the unit details
     */
    * goToPendingUnitDetails () {
      const searchedUnit = yield this.get('searchedUnit')
      const isMobile = yield this.get('isMobile')
      const user = yield this.get('user')

      const { navigate } = this.actions

      // eslint-disable-next-line max-len
      const url = generateUrl(SHOW_PANEL_ACCESS_ENDPOINT, { deviceId: searchedUnit.id, userid: user.id })
      try {
        const response = yield call(axios.get, url)
        let { successful, result } = response.data

        if (successful) {
          if (!isMobile) {
            // eslint-disable-next-line max-len
            yield put(TechnicianUnitsLogic.actions.showUnitPanel(result.data[0]))
          } else {
            yield put(navigate(`/${searchedUnit.id}/dashboard`))
            // eslint-disable-next-line max-len
            yield put(TechnicianUnitsLogic.actions.showUnitPanel(result.data[0]))
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

})
