// @flow
import React, { Component, Fragment } from 'react'
import { type User } from 'types/api'
import Logic from './logic'
import styled from 'styled-components'
import { CURRENTDEVICE_MOCK, DEVICESTATUS_MOCK, getTourSteps } from './utils'

import Background from 'containers/App/images/background-blue.svg'
import BGMobile from 'assets/background-blue-tablet.svg'
import NavBar from 'containers/NavBar'
// eslint-disable-next-line max-len
import DashboardDesktopConsumptions from 'containers/DashboardDesktopConsumptions'
import DashboardGridConsumptions from 'containers/DashboardGridConsumptions'
import UserBar from 'containers/UserBar'
import EnergyFlow from 'components/EnergyFlow'
import { multipleSolarPanelObject } from 'scenes/DashboardMenu/Dashboard/utils'
import Tour from 'components/Tour'

import {
  PageTitle,
  Switcher,
  BatteryStatus
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  user: User,
  switcherSelected: string,
  showHelpTour: boolean
}

class TourLayout extends Component<Props> {
  actions: {
    setSwitcherSelected: Function,
    setShowHelpTour: Function
  }

  render () {
    const {
      isMobile,
      translations,
      user,
      switcherSelected,
      showHelpTour
    } = this.props

    const {
      DASH_TITLE,
      DASH_WELCOME,
      DASH_BATTERY_STATUS,
      DASH_ENERGY_FLOW,
      OPERATION_MODE_BASIC
    } = translations

    const { firstName, lastName } = user

    const { setSwitcherSelected, setShowHelpTour } = this.actions

    const switcherItems = [
      { slug: 'battery', name: DASH_BATTERY_STATUS },
      { slug: 'energy', name: DASH_ENERGY_FLOW }
    ]

    const deviceStatus = DEVICESTATUS_MOCK
    const currentDevice = CURRENTDEVICE_MOCK

    const steps = getTourSteps(translations, isMobile, setSwitcherSelected)

    return (
      <Fragment>
        <RouteContainer mobile={isMobile}>
          <Wrapper bgfull={switcherSelected === 'energy'} mobile={isMobile}>
            {isMobile && (
              <Fragment>
                <Switcher
                  items={switcherItems}
                  selected={switcherSelected}
                  onChange={selected => setSwitcherSelected(selected)}
                />
                <SwitcherContainer visible={switcherSelected === 'battery'}>
                  <BatteryStatus
                    mode={OPERATION_MODE_BASIC}
                    isMobile
                    translations={translations}
                    currentDevice={currentDevice}
                    deviceStatus={deviceStatus}
                  />
                  <DashboardGridConsumptions />
                </SwitcherContainer>
                <SwitcherContainer visible={switcherSelected === 'energy'}>
                  <EnergyFlow
                    deviceStatus={deviceStatus}
                    isMobile
                    currentDevice={currentDevice}
                    multipleSolarPanels={multipleSolarPanelObject(deviceStatus)}
                    translations={translations}
                    multipvdetails={false}
                  />
                </SwitcherContainer>
              </Fragment>
            )}
            {!isMobile && (
              <Fragment>
                <PageTitle
                  paddingTop={isMobile ? '12px' : '0px'}
                  mobile={isMobile}
                >
                  {DASH_TITLE}
                </PageTitle>
                <div>
                  <PageSubTitle>
                    {DASH_WELCOME} {firstName} {lastName}
                    ...
                  </PageSubTitle>
                  <DesktopEnergyFlow>
                    <EnergyFlow
                      deviceStatus={deviceStatus}
                      isMobile={isMobile}
                      multipleSolarPanels={multipleSolarPanelObject(
                        deviceStatus
                      )}
                      multipvdetails={false}
                      currentDevice={currentDevice}
                      translations={translations}
                    />
                  </DesktopEnergyFlow>
                  <DashboardDesktopConsumptions />
                </div>
              </Fragment>
            )}
          </Wrapper>
        </RouteContainer>
        <NavBar />
        {!isMobile && <UserBar />}
        <Tour
          steps={steps}
          isOpen={showHelpTour}
          onRequestClose={() => setShowHelpTour(false)}
        />
      </Fragment>
    )
  }
}

export default Logic(TourLayout)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }

  ${({ mobile, bgfull }) => mobile && !bgfull && `
    min-height: calc(100vh - 56px);
    background: #f7f7f9;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    background-image: url(${BGMobile})
  `}

  ${({ mobile, bgfull }) => mobile && bgfull && `
    min-height: calc(100vh - 56px);
    background: #007ac1;
  `}
`

const RouteContainer = styled.div`
  height: auto;
  position: absolute;
  min-height: 100vh;

  ${({ mobile }) =>
    mobile &&
    `
    padding-bottom: 56px;
    background: #FFF;
    min-width: 300px;
    width: 100vw;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    background: #f7f7f9;
    min-width: 1024px;
    width: 100%;
    display: inline-block;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding-left: 120px;
  `}
`

const PageSubTitle = styled.h3`
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  line-height: 0;
`

const DesktopEnergyFlow = styled.div`
  margin-top: 56px;
`

const SwitcherContainer = styled.div`
  display: none;
  max-width: 450px;
  margin: 0 auto;

  ${({ visible }) =>
    visible &&
    `
    animation: show 1s;
    display: block;
  `}

  @keyframes show {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      display: block !important;
    }
  }
`
