// @flow
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Paragraph, TourDescription } from 'xstorage-components'

export const CURRENTDEVICE_MOCK = {
  id: '2840f0fb-a38e-4284-a0ba-aa01b23f35fc',
  updatedAt: 1575899085826,
  createdAt: 1557251649318,
  name: 'Ubiwhere',
  description: null,
  firmwareVersion: '00.01.0006-154-g8abe5be0',
  commCardFirmwareVersion: '',
  hasPv: true,
  address: 'Travessa Senhor das Barrocas_',
  country: {
    id: 'c5b8872a-6285-4e0f-ae82-e5ed29fff5ce',
    continentId: '5ad06a9c-4343-4d0a-bc3a-9fe23136c3f4',
    geonameId: 2264397,
    code: 'PT',
    name: 'Portugal',
    eu: true,
    locale: 'en',
    createdAt: 1560875990365,
    updatedAt: 1560875990365
  },
  city: {
    id: '0192755c-da96-4a74-a29e-f020707a3121',
    countryId: 'c5b8872a-6285-4e0f-ae82-e5ed29fff5ce',
    geonameId: 2743458,
    subdivision1Code: '03',
    subdivision1Name: 'Braga',
    subdivision2Code: '',
    subdivision2Name: '',
    name: 'Abade de Neiva',
    timeZone: 'Europe/Lisbon',
    metroCode: '',
    eu: true,
    locale: 'en',
    createdAt: 1560876054538,
    updatedAt: 1560876054538
  },
  postalCode: '3800-075',
  latitude: 41.549896,
  longitude: -8.6435585,
  defaultOperationMode: 'BASIC',
  inverterManufacturer: 'EATON',
  inverterModelName: 'XSTH1P036P048V01',
  inverterTidaProtocol: '1.0',
  inverterSerialNumber: 'RB76H39016',
  inverterIsSinglePhase: true,
  inverterFirmwareVersion: '00.04.0057',
  inverterVaRating: 3600,
  inverterNominalVpv: 3600,
  bmsSerialNumber: 'H-B60-H-46-007',
  bmsModel: 'RESIDENCIAL',
  bmsFirmwareVersion: '4004',
  bmsCapacity: 4.2,
  bmsBackupLevel: 21,
  powerState: true,
  deviceLastScheduleUpdate: 1575471643,
  deviceLastUpdate: 1575556226,
  lastTechParamsUpdate: -62135596800,
  timezone: {
    id: '78931bbb-a3d4-49a2-acf0-dfd9aff1351a',
    countryId: 'c5b8872a-6285-4e0f-ae82-e5ed29fff5ce',
    timezone: 'Atlantic/Madeira',
    name: 'Atlantic/Madeira - Madeira Islands',
    version: '00.01.0001 2018-02-06',
    createdAt: 1560876105929,
    updatedAt: 1560876105929
  },
  dns: '8.8.8.8',
  networkInterfaces: [
    {
      id: '6e886e54-8694-4d17-8e91-82180a49ec67',
      updatedAt: 1575899085998,
      createdAt: 1575899085998,
      name: 'wlan1',
      macAddress: '76:da:38:99:5a:af',
      ipAddress: '192.168.3.99'
    },
    {
      id: '682624ae-0606-47e5-8c5e-d62f45752216',
      updatedAt: 1575899085951,
      createdAt: 1575899085951,
      name: 'eth0',
      macAddress: '00:20:85:f2:aa:ad',
      ipAddress: '10.20.0.9'
    },
    {
      id: 'f489eb5a-ee10-4d09-b14f-2e8d10608024',
      updatedAt: 1575899085951,
      createdAt: 1575899085951,
      name: 'wlan0',
      macAddress: '74:da:38:99:5a:af',
      ipAddress: '192.168.2.34'
    }
  ],
  roles: [
    {
      id: '98b63f63-1026-4cfc-bc21-cd6beb6787ea',
      updatedAt: 1575657359970,
      createdAt: 1575657359970,
      device: null,
      user: {
        id: 'c049e07f-4d27-433f-8429-05b662bd2747',
        firstName: 'Bruno',
        lastName: 'Silva',
        email: 'bsilva@ubiwhere.com',
        phone: '911111112',
        language: 'en-gb'
      },
      createdBy: {
        id: 'c049e07f-4d27-433f-8429-05b662bd2747',
        firstName: 'Bruno',
        lastName: 'Silva',
        email: 'bsilva@ubiwhere.com',
        phone: '911111112',
        language: 'en-gb'
      },
      updatedBy: {
        id: 'c049e07f-4d27-433f-8429-05b662bd2747',
        firstName: 'Bruno',
        lastName: 'Silva',
        email: 'bsilva@ubiwhere.com',
        phone: '911111112',
        language: 'en-gb'
      },
      name: null,
      owner: true,
      technician: false,
      write: true,
      roleManagement: true,
      status: 'ACTIVE'
    }
  ],
  powerMeters: [
    {
      id: '1cd8f5ff-2c80-4901-b384-92a14801b443',
      updatedAt: 1575899085904,
      createdAt: 1575899085904,
      position: 1,
      model: 'Sfere DDS1946-DS',
      singlePhase: true
    },
    {
      id: '1608b059-12c9-4c18-835c-5525cdcdf311',
      updatedAt: 1575899085888,
      createdAt: 1575899085888,
      position: 2,
      model: 'None',
      singlePhase: true
    }
  ],
  updateStatus: 'UPDATE_COMPLETE',
  connected: true,
  hasBattery: true,
  updateBlockedState: false,
  bundleVersion: '',
  active: true,
  gridCode: '',
  clockSyncMethod: 'CLOCKSYNC_MANUAL',
  installer: null,
  lastSyncOfMetrics: null,
  localPortalRemoteId: null,
  hadBattery: false,
  energySavingMode: null
}

export const DEVICESTATUS_MOCK = {
  energyFlow: {
    operationMode: 'MAXIMIZE_AUTO_CONSUMPTION',
    batteryStatus: 'CHARGE',
    batteryBackupLevel: 5,
    batteryEnergyFlow: 1100,
    selfConsumption: 0,
    stateOfCharge: 75,
    gridRole: 'NONE',
    gridValue: 0,
    criticalLoadRole: 'PRODUCER',
    criticalLoadValue: 300,
    nonCriticalLoadRole: 'CONSUMER',
    nonCriticalLoadValue: 2400,
    dcPvRole: 'CONSUMER',
    dcPvValue: 2500,
    acPvRole: 'CONSUMER',
    acPvValue: 1300,
    selfSufficient: 0
  },
  today: {
    gridConsumption: 1014.2166748046875,
    gridInjection: 0,
    photovoltaicProduction: 0,
    selfConsumption: 0,
    selfSufficient: 0
  },
  last30daysEnergyFlow: {
    gridConsumption: 41654.29861450195,
    gridInjection: 88.50000190734863,
    photovoltaicProduction: 0,
    selfConsumption: 0,
    selfSufficient: 0
  },
  currentMode: {
    id: null,
    updatedAt: 1575449634724,
    createdAt: 1575449634724,
    user: {
      id: '5f2f6e7e-f5bb-4c6b-a84c-ef1fd5a8a1db',
      firstName: 'Tech1324!"#$àéíç1',
      lastName: '1324!"#$àéíç1',
      email: 'technician_test_poliveira_250319@mailinator.com',
      phone: '1333742135461',
      language: 'en-gb'
    },
    updatedBy: {
      id: '00000000-0000-0000-0000-000000000000',
      firstName: 'Local',
      lastName: 'User'
    },
    command: 'SET_MAXIMIZE_AUTO_CONSUMPTION',
    parameters: null,
    startTime: null,
    endTime: null,
    duration: null,
    status: 'ACTIVE',
    type: 'DEFAULT'
  }
}

export const MOBILE_STYLE_STEP_TOUR = {
  backgroundColor: '#FDFDFD',
  borderRadius: '6px',
  boxShadow: '0 0 10px 0 rgba(81,141,197,0.1)',
  padding: '12px 12px 22px 16px',
  maxWidth: 'calc(100vw - 30px)',
  marginLeft: '8px'
}

export const STYLE_STEP_TOUR = {
  backgroundColor: '#FDFDFD',
  borderRadius: '6px',
  boxShadow: '0 0 10px 0 rgba(81,141,197,0.1)',
  padding: '12px 12px 22px 16px',
  maxWidth: '400px',
  marginTop: '8px'
}

export const getTourSteps = (translations, isMobile, setSwitcherSelected) => {
  let steps = [
    {
      selector: '.first-step',
      content: () => (
        <div>
          <Paragraph weight={'bold'}>
            {translations['HELP_TOUR_MAIN_MENU']}
          </Paragraph>
          <TourDescription paragraphColor={'#727E84'} paragraphSize={'14px'}>
            {ReactHtmlParser(translations['HELP_TOUR_MAIN_MENU_DESCRIPTION'])}
          </TourDescription>
        </div>
      ),
      style: isMobile ? MOBILE_STYLE_STEP_TOUR : STYLE_STEP_TOUR,
      action: () => {
        setSwitcherSelected('battery')
      }
    },
    {
      selector: '.second-step',
      content: () => (
        <div>
          <Paragraph weight={'bold'}>
            {translations['HELP_TOUR_NOTIFICATIONS']}
          </Paragraph>
          <Paragraph color={'#727E84'} size={'14px'}>
            {translations['HELP_TOUR_NOTIFICATIONS_DESCRIPTION']}
          </Paragraph>
        </div>
      ),
      style: isMobile ? MOBILE_STYLE_STEP_TOUR : STYLE_STEP_TOUR
    },
    {
      selector: '.third-step',
      content: () => (
        <div>
          <Paragraph weight={'bold'}>
            {translations['HELP_TOUR_UNIT']}
          </Paragraph>
          <TourDescription paragraphColor={'#727E84'} paragraphSize={'14px'}>
            {ReactHtmlParser(translations['HELP_TOUR_UNIT_DESCRIPTION'])}
          </TourDescription>
        </div>
      ),
      style: isMobile ? MOBILE_STYLE_STEP_TOUR : STYLE_STEP_TOUR,
      action: () => {
        setSwitcherSelected('battery')
      }
    },
    {
      selector: '.fourth-step',
      content: () => (
        <div>
          <Paragraph weight={'bold'}>
            {translations['HELP_TOUR_PHOTOVOLTAIC_PANELS']}
          </Paragraph>
          <Paragraph color={'#727E84'} size={'14px'}>
            {translations['HELP_TOUR_PHOTOVOLTAIC_PANELS_DESCRIPTION']}
          </Paragraph>
        </div>
      ),
      style: isMobile ? MOBILE_STYLE_STEP_TOUR : STYLE_STEP_TOUR,
      action: () => {
        setSwitcherSelected('energy')
      }
    },
    {
      selector: '.fifth-step',
      content: () => (
        <div>
          <Paragraph weight={'bold'}>
            {translations['HELP_TOUR_CRITICAL_LOADS']}
          </Paragraph>
          <Paragraph color={'#727E84'} size={'14px'}>
            {translations['HELP_TOUR_CRITICAL_LOADS_DESCRIPTION']}
          </Paragraph>
        </div>
      ),
      style: isMobile ? MOBILE_STYLE_STEP_TOUR : STYLE_STEP_TOUR,
      action: () => {
        setSwitcherSelected('energy')
      }
    },
    {
      selector: '.sixth-step',
      content: () => (
        <div>
          <Paragraph weight={'bold'}>
            {translations['HELP_TOUR_NON_CRITICAL_LOADS']}
          </Paragraph>
          <Paragraph color={'#727E84'} size={'14px'}>
            {translations['HELP_TOUR_NON_CRITICAL_LOADS_DESCRIPTION']}
          </Paragraph>
        </div>
      ),
      style: isMobile ? MOBILE_STYLE_STEP_TOUR : STYLE_STEP_TOUR,
      action: () => {
        setSwitcherSelected('energy')
      }
    },
    {
      selector: '.seventh-step',
      content: () => (
        <div>
          <Paragraph weight={'bold'}>
            {translations['HELP_TOUR_GRID']}
          </Paragraph>
          <Paragraph color={'#727E84'} size={'14px'}>
            {translations['HELP_TOUR_GRID_DESCRIPTION']}
          </Paragraph>
        </div>
      ),
      style: isMobile ? MOBILE_STYLE_STEP_TOUR : STYLE_STEP_TOUR,
      action: () => {
        setSwitcherSelected('energy')
      }
    }
  ]

  if (isMobile) {
    steps = steps.filter(step => step.selector !== '.second-step')
  }

  return steps
}
