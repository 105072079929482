/* eslint-disable linebreak-style */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import {
  Modal,
  ModalHeader,
  ModalContent,
  List,
  ListItemCollapse
} from 'xstorage-components'

type Props = {
  open: boolean,
  onClose: Function,
  bmsfaultcode: [],
  isMobile: boolean,
  translations: {
    MAINTENANCE_TECH_PARAMS_BMS_FAULT_CODE: string,
    MAINTENANCE_ERROR: string,
    MAINTENANCE_ERRORS: string
  }
}

class BmsFaultCodeModal extends Component<Props> {
  render () {
    const { open, onClose, bmsfaultcode, isMobile, translations } = this.props

    const {
      MAINTENANCE_TECH_PARAMS_BMS_FAULT_CODE,
      MAINTENANCE_ERROR,
      MAINTENANCE_ERRORS
    } = translations

    let bmsFaultTranslations = []

    if (bmsfaultcode) {
      bmsfaultcode.forEach(element => {
        bmsFaultTranslations.push(
          translations[`MAINTENANCE_BMSFAULTCODE_${element}`]
        )
      })
    }

    return (
      <Modal open={open} size={'medium'} onClose={onClose}>
        <div>
          <ModalHeader
            title={MAINTENANCE_TECH_PARAMS_BMS_FAULT_CODE}
            subtitle={`${(bmsfaultcode && bmsfaultcode.length) || '0'} ${
              bmsfaultcode && bmsfaultcode.length === 1
                ? MAINTENANCE_ERROR
                : MAINTENANCE_ERRORS
            }`}
            closeButton
            onClose={onClose}
          />
          <ModalContent type={'diagonal'} size={'medium'} mobile={isMobile}>
            <List>
              {bmsFaultTranslations.map((topic, index) => (
                <ListItemCollapse key={index} title={topic} shadow />
              ))}
            </List>
          </ModalContent>
        </div>
      </Modal>
    )
  }
}

export default Logic(BmsFaultCodeModal)
