// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

type Props = {
}

class DiskInfoTable extends Component<Props, void> {
  render () {
    const { columns, data, translations } = this.props

    return (
      <Table>
        <thead>
          <tr>
            {columns && columns.map(column => (
              <TableHeader
                key={column.id}
              >
                {translations[column.key]}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <TableBody>
          {data && data.map((value, index) => (
            <TableBodyRow
              key={index}
            >
              {columns.map(column => (
                <TableCell
                  key={column.id}
                >
                  <div>
                    {value[column.dataMapping]}
                  </div>
                </TableCell>
              ))}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default Logic(DiskInfoTable)

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 4px;
`
const TableBody = styled.tbody`
  > tr:not(:last-child) > td > div {
    border-bottom: 1px solid rgba(114,126,132,0.1);
  }
`

const TableBodyRow = styled.tr`
  
  background-color: #F0F0F4;
  
  > td:first-child {
    padding-left: 16px;
  }

  > td:last-child {
    padding-right: 16px;
  }
`

const TableHeader = styled.th`
  color: #5B6770;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  padding: 21px 0;
  border-bottom: 2px solid rgba(114,126,132,0.1); 
`

const TableCell = styled.td`
  color: #5B6770;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;

  div {
    padding: 16px 0;
  }
`
