// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import { PageTitle, BackButton } from 'xstorage-components'
import MaintenanceMenu from 'containers/MaintenanceMenu'

type Props = {
  isMobile: boolean,
  translations: {
    MORE_MAINTENANCE: string
  },
  currentDeviceId: string
}

class MaintenanceMenuMobile extends Component<Props> {
  actions: {
    navigate: Function
  }

  render () {
    const { isMobile, translations, currentDeviceId } = this.props

    const { navigate } = this.actions

    const { MORE_MAINTENANCE } = translations

    return (
      <Wrapper>
        <BackButton
          id={'BackButton'}
          onClick={() => navigate(`/${currentDeviceId}/more/`)}
        />
        <PageTitle mobile={isMobile} blue>
          {MORE_MAINTENANCE}
        </PageTitle>
        <MaintenanceMenu />
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceMenuMobile)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;
`
