// @flow
import { kea } from 'kea'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import { API_BASE_URL } from 'config'
import { log } from 'utils'
import AppLogic from 'containers/App/logic'

// eslint-disable-next-line max-len
const FETCH_TERMS_ENDPOINT: string = `${API_BASE_URL}/internal/termsandconditions/current`
const UPDATE_TERMS_ENDPOINT: string = `${API_BASE_URL}/users/me`

export default kea({
  path: () => ['scenes', 'TermsAndConditions'],

  connect: {
    props: [
      AppLogic,
      ['isMobile', 'translations', 'user', 'language', 'userValid']
    ],
    actions: [AppLogic, ['navigate', 'start']]
  },

  actions: () => ({
    error: () => true,
    reset: () => true,
    fetchCurrentTerms: () => true,
    setCurrentTerms: terms => ({ terms }),
    acceptCurrentTerms: () => true
  }),

  reducers: ({ actions }) => ({
    currentTerms: [
      {},
      PropTypes.object,
      {
        [actions.reset]: () => ({}),
        [actions.setCurrentTerms]: (state, payload) => payload.terms
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.reset]: () => true,
        [actions.setCurrentTerms]: () => false,
        [actions.error]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.error]: () => true
      }
    ]
  }),

  start: function * () {
    const { fetchCurrentTerms } = this.actions

    log('[XS-TermsAndConditions] Start Scene', 'yellow')

    yield put(fetchCurrentTerms())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-TermsAndConditions] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchCurrentTerms]: workers.fetchCurrentTerms,
    [actions.acceptCurrentTerms]: workers.acceptCurrentTerms
  }),

  workers: {
    * fetchCurrentTerms () {
      const { setCurrentTerms, error } = this.actions

      const language = yield this.get('language')
      try {
        const data = {
          language: language
        }
        const response = yield call(axios.get, FETCH_TERMS_ENDPOINT, {
          params: data
        })
        const statusResult = response.data
        const { result, successful } = statusResult

        if (successful) {
          yield put(setCurrentTerms(result))
        } else {
          yield put(error())
        }
      } catch (error) {
        console.log(error)
      }
    },

    * acceptCurrentTerms () {
      const { navigate, start } = this.actions

      const currentTerms = yield this.get('currentTerms')
      const language = yield this.get('language')

      try {
        const data = {
          tcVersion: currentTerms.version,
          language: language
        }
        const response = yield call(axios.put, UPDATE_TERMS_ENDPOINT, data)
        const statusResult = response.data
        const { successful } = statusResult

        if (successful) {
          yield put(navigate('/'))
          yield put(start())
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})
