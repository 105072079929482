// @flow
import Logic from './logic'
import { type ChartFilters, type ChartVariables } from './types'
import { type Device } from 'types/api'
import React, { Component } from 'react'
import styled from 'styled-components'
import { getEnergyValue } from 'utils'
import moment from 'moment-timezone'
import {
  TIME_FILTER_OPTIONS,
  TIME_MOBILE_FILTER_OPTIONS,
  FILTER_DATE_FORMAT
} from './utils'
import Icon from '@material-ui/core/Icon'
import round from 'lodash/round'

import DateFilterChart from 'components/DateFilterChart'
import ChartDayStats from 'containers/ChartDayStats'
import ChartPeriodStats from 'containers/ChartPeriodStats'
import WeatherBar from 'containers/WeatherBar'
import SlidePanel from 'components/SlidePanel'
import ExportChartPanel from 'containers/ExportChartPanel'
import PageNavMenu from 'containers/PageNavMenu'

import {
  Switcher,
  PageTitle,
  ModalMessage,
  Loading,
  LoadingCircular,
  Button,
  Checkbox,
  ChartFilterVal,
  SelectBox,
  ItemTotalHouseConsumption,
  ItemCriticalLoads,
  ItemNonCriticalLoads,
  ItemPhotovoltaicProduction,
  ItemBatteryStateOfCharge,
  ItemPvSelfConsumption,
  ItemSelfSufficiency,
  IcCalendar,
  Link,
  FileChart,
  NoBatteryLabel,
  IcXstorageMissing
} from 'xstorage-components'

type Props = {
  filters: ChartFilters,
  variables: ChartVariables,
  loading: boolean,
  fetching: boolean,
  todayChart: boolean,
  metrics: {
    from: number,
    to: number
  },
  averages: any,
  isMobile: boolean,
  translations: any,
  ticksToHide: string,
  invalidTimezone: boolean,
  viewTzMessage: boolean,
  currentDevice: Device
}

type State = {}

export class Chart extends Component<Props, State> {
  actions: any

  render () {
    const {
      filters,
      variables,
      loading,
      fetching,
      metrics,
      averages,
      todayChart,
      isMobile,
      ticksToHide,
      translations,
      invalidTimezone,
      viewTzMessage,
      currentDevice,
      exportChartPanelState,
      batteryReadyMode,
      noBatteryDetails
    } = this.props

    const {
      changeFilter,
      changeVariable,
      navigate,
      showInvalidTzMessage,
      hideInvalidTzMessage,
      openExportChartPanel,
      closeExportChartPanel,
      setNoBatteryDetails
    } = this.actions

    const {
      CHART_TITLE,
      CHART_VAR_TOTALHOUSECONSUMPTION,
      CHART_VAR_CRITICALLOADS,
      CHART_VAR_NONCRITICALLOADS,
      CHART_VAR_PHOTOVOLTAICPRODUCTION,
      CHART_VAR_BATTERYSTATEOFCHARGE,
      CHART_VAR_SELFCONSUMPTION,
      CHART_INTERVAL_FROM,
      CHART_INTERVAL_TO,
      CHART_INVALID_TIMEZONE_WAR_TITLE,
      CHART_INVALID_TIMEZONE_WAR_DESC,
      CHART_INVALID_TIMEZONE_WAR_BUTTON,
      CHART_VAR_SUM,
      CHART_VAR_AVERAGE,
      CHART_EXPORT_BUTTON,
      CHART_EXPORT_TITLE,
      CHART_VAR_SELFSUFFICIENCY,
      DASH_NO_BATTERY,
      CHART_NOBATTERY_ERROR_DESCRIPTION
    } = translations

    // translate each option label
    let filterOptions = {}

    if (isMobile) {
      filterOptions = TIME_MOBILE_FILTER_OPTIONS.map(f => ({
        ...f,
        ...{ label: translations[f.label] }
      }))
    } else {
      filterOptions = TIME_FILTER_OPTIONS.map(f => ({
        ...f,
        ...{ label: translations[f.label] }
      }))
    }

    // chart interval time based on devide mode
    const dateFormat = isMobile ? 'DD/MM/YYYY' : FILTER_DATE_FORMAT

    let from = metrics.from ? moment.unix(metrics.from).format(dateFormat) : ''
    let to = metrics.to ? moment.unix(metrics.to).format(dateFormat) : ''

    let periodChartLabel = isMobile ? (
      `${from} - ${to}`
    ) : (
      <div>
        <span>{CHART_INTERVAL_FROM}</span> {from}{' '}
        <span>{CHART_INTERVAL_TO}</span> {to}
      </div>
    )

    const label =
      filters.timeOption === 'today'
        ? moment(
          filters.selectedDayPicker.format('DD-MM-YYYY'),
          'DD-MM-YYYY'
        ).format(FILTER_DATE_FORMAT)
        : periodChartLabel

    // chart time based on chart type
    let chartTime = (
      <DateFilterChart
        label={label}
        icon={<ImageIconCalendar />}
        filters={filters}
        changeFilter={changeFilter}
        isMobile={isMobile}
      />
    )

    let chartContainer = (
      <ChartContainer>
        {fetching && <LoadingCircular />}
        {todayChart ? (
          <ChartDayStats
            ticksToHide={ticksToHide}
            viewSun={!invalidTimezone}
            showInvalidTzMessage={showInvalidTzMessage}
          />
        ) : (
          <ChartPeriodStats ticksToHide={ticksToHide} />
        )}
      </ChartContainer>
    )

    // function to draw a chart filter
    let chartFilter = (
      filterName: string,
      icon: any,
      label: string,
      energy: boolean,
      unit: any = 'w',
      valueLabel: string = '',
      green: boolean = false
    ) => {
      let value: number = averages && averages[filterName]

      if (energy) {
        const energyValue = getEnergyValue(value, 'Wh')
        value = energyValue ? energyValue.value : value
        unit = energyValue && energyValue.unit
      } else {
        value = value && round(value, 2)
      }

      /**
       * Red color - no battery detected mode
       * Green color - argument green passed to the function
       * Default: blue
       */
      const filterColor = green ? '#A0C599' : '#0067C6'

      return (
        <ChartFilterContainer>
          <FilterCheckbox
            disabled={fetching}
            checked={variables[filterName]}
            onChange={() =>
              changeVariable({ [filterName]: !variables[filterName] })
            }
          />
          <FilterImageIcon>{icon}</FilterImageIcon>
          <FilterLabel>
            <span>{label}</span>
            {filterName === 'batteryStateOfCharge' && batteryReadyMode && (
              <NoBatteryLabel
                label={DASH_NO_BATTERY}
                onClick={() => setNoBatteryDetails(true)}
              />
            )}
          </FilterLabel>
          <ChartFilterVal
            value={value}
            unit={unit}
            filterColor={filterColor}
            label={valueLabel}
          />
        </ChartFilterContainer>
      )
    }

    let chartFilters = (
      <ChartFiltersContainer>
        {chartFilter(
          'totalHouseConsumption',
          <ItemTotalHouseConsumption />,
          CHART_VAR_TOTALHOUSECONSUMPTION,
          true,
          'w',
          CHART_VAR_SUM
        )}
        {chartFilter(
          'criticalLoads',
          <ItemCriticalLoads />,
          CHART_VAR_CRITICALLOADS,
          true,
          'w',
          CHART_VAR_SUM
        )}
        {chartFilter(
          'nonCriticalLoads',
          <ItemNonCriticalLoads />,
          CHART_VAR_NONCRITICALLOADS,
          true,
          'w',
          CHART_VAR_SUM
        )}
        {chartFilter(
          'batteryStateOfCharge',
          <ItemBatteryStateOfCharge />,
          CHART_VAR_BATTERYSTATEOFCHARGE,
          false,
          '%',
          CHART_VAR_AVERAGE,
          true
        )}
        {currentDevice.hasPv &&
          chartFilter(
            'photovoltaicProduction',
            <ItemPhotovoltaicProduction />,
            CHART_VAR_PHOTOVOLTAICPRODUCTION,
            true,
            'w',
            CHART_VAR_SUM
          )}
        {!todayChart &&
          currentDevice.hasPv &&
          chartFilter(
            'selfConsumption',
            <ItemPvSelfConsumption />,
            CHART_VAR_SELFCONSUMPTION,
            false,
            '%',
            CHART_VAR_AVERAGE,
            true
          )}
        {!todayChart &&
          currentDevice.hasPv &&
          chartFilter(
            'selfSufficient',
            <ItemSelfSufficiency />,
            CHART_VAR_SELFSUFFICIENCY,
            false,
            '%',
            CHART_VAR_AVERAGE,
            true
          )}
      </ChartFiltersContainer>
    )

    const menuItems = [
      {
        id: 'ExportChart',
        key: 'export-print',
        icon: (
          <ExportIcon>
            <FileChart />
          </ExportIcon>
        ),
        title: CHART_EXPORT_BUTTON,
        link: true,
        onClick: () => openExportChartPanel()
      }
    ]

    return (
      <Wrapper>
        {!!loading && <Loading />}

        {!loading && invalidTimezone && viewTzMessage && (
          <ModalMessage
            icon={<Icon>{'report'}</Icon>}
            title={CHART_INVALID_TIMEZONE_WAR_TITLE}
            text={CHART_INVALID_TIMEZONE_WAR_DESC}
            onClose={hideInvalidTzMessage}
            buttons={
              <Button
                id={'PrimaryButton_GoToSettings'}
                iconfloat={'center'}
                buttonWidth={'156px'}
                onClick={() =>
                  navigate(`/${currentDevice.id}/settings/general/`)
                }
              >
                <Icon>{'settings'}</Icon>
                {CHART_INVALID_TIMEZONE_WAR_BUTTON}
              </Button>
            }
            mobile={isMobile}
            closable
          />
        )}

        <PageTitle
          paddingTop={isMobile ? '12px' : '0px'}
          mobile={isMobile}
          blue={isMobile}
        >
          {CHART_TITLE}
        </PageTitle>
        {isMobile && (
          <div>
            <WeatherBar color={'#007BC1'} />
            {chartTime}
            <ChartTimeFilter>
              <SelectBox
                value={filters.timeOption}
                onChange={val => changeFilter({ timeOption: val })}
                options={filterOptions}
              />
            </ChartTimeFilter>
            {chartContainer}
            {chartFilters}
            <PageNavMenu margin={'48px 0 16px 0'} items={menuItems} page={''} />
          </div>
        )}

        {!isMobile && (
          <div>
            <SwitcherContainer>
              <Switcher
                items={filterOptions.map(o => ({ slug: o.val, name: o.label }))}
                selected={filters.timeOption}
                onChange={val => changeFilter({ timeOption: val })}
              />
            </SwitcherContainer>
            <DesktopContent>
              <DesktopChart>
                {chartTime}
                {chartContainer}
              </DesktopChart>
              <DesktopFilters>
                <React.Fragment>
                  <ButtonWrapper>
                    <Link onClick={openExportChartPanel}>
                      <ExportIcon>
                        <FileChart />
                      </ExportIcon>
                      <span>{CHART_EXPORT_BUTTON}</span>
                    </Link>
                  </ButtonWrapper>
                  {chartFilters}
                </React.Fragment>
              </DesktopFilters>
            </DesktopContent>
          </div>
        )}
        {!isMobile && (
          <SlidePanel
            overlay
            open={exportChartPanelState}
            onClose={closeExportChartPanel}
            fixed={exportChartPanelState}
            title={CHART_EXPORT_TITLE}
          >
            <ExportChartPanel
              timeFilter={filters.timeOption}
              variables={variables}
              timeInterval={[metrics.from, metrics.to]}
              onCancel={closeExportChartPanel}
            />
          </SlidePanel>
        )}
        {isMobile && exportChartPanelState && (
          <ExportChartPanel
            timeFilter={filters.timeOption}
            variables={variables}
            timeInterval={[metrics.from, metrics.to]}
            onCancel={closeExportChartPanel}
          />
        )}
        {noBatteryDetails && (
          <ModalMessage
            icon={
              <IcXstorageMissing
                fillColor={'#CA3C3D'}
                size={{ width: 20, height: 20 }}
              />
            }
            title={DASH_NO_BATTERY}
            text={CHART_NOBATTERY_ERROR_DESCRIPTION}
            mobile={isMobile}
            onClose={() => setNoBatteryDetails(false)}
            closable
          />
        )}
      </Wrapper>
    )
  }
}

export default Logic(Chart)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const ChartContainer = styled.div`
  position: relative;
`

const ChartTimeFilter = styled.div`
  position: absolute;
  right: 20px;
  top: 55px;
  display: flex;
  align-items: center;
  height: 30px;
}

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    right: 70px;
    top: 75px;
  }

  label{
    @media screen and (max-width: 368px) {
      font-size: 12px;
    }
  }
`

const ImageIconCalendar = styled(props => <IcCalendar {...props} />)`
  margin: 0 5px 0;
  float: left;
  width: 20px;
  height: 20px;
`

const ChartFiltersContainer = styled.div`
  margin-top: 14px;
`

const ChartFilterContainer = styled.div`
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  padding: 8px 0;
  position: relative;
`

const FilterImageIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  svg {
    @media screen and (max-width: 400px) {
      width: 40px;
      height: 40px;
    }
  }
`

const FilterLabel = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 14px;
  color: #5b6770;
  line-height: 20px;
  width: 51%;

  @media screen and (max-width: 1350px) {
    width: 29%;
  }

  @media screen and (max-width: 400px) {
    width: 43%;
  }
`

const FilterCheckbox = styled(Checkbox)`
  font-size: 18px !important;
`

const SwitcherContainer = styled.div`
  margin-top: 30px;
`

const DesktopContent = styled.div`
  display: flex;
  padding-top: 20px;
  align-items: center;
  position: relative;
`

const DesktopChart = styled.div`
  width: 66%;
  background-color: #fdfdfd;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(114, 126, 132, 0.1);
  position: relative;
  padding: 20px;
  padding-top: 30px;

  > div {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: #424e54;
    opacity: 1;

    span {
      opacity: 0.5;
      font-weight: 400;
    }
  }
`

const DesktopFilters = styled.div`
  width: 33%;
  padding-left: 30px;
  position: absolute;
  top: 0;
  left: 66%;
`

const ExportIcon = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 4px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
