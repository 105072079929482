// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { FormControlLabel, Icon, Grid } from '@material-ui/core'
import moment from 'moment-timezone'

import FormFieldError from 'containers/FormFieldError'
import FormGeneralError from 'containers/FormGeneralError'

import {
  Button,
  LoadingCircular,
  List,
  Checkbox,
  FormFieldInfo,
  InputText,
  StatusIndicator,
  Typography,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  SelectAutocomplete,
  DatetimePicker,
  Form
} from 'xstorage-components'

type Props = {
  unitId?: any,
  isMobile: boolean,
  inputMode: boolean,
  onCancel: Function,
  form: any,
  dirty: boolean,
  error: string,
  unitInfo: any,
  loading: boolean,
  translations: {
    TECH_UNIT_RESEND_INVITATION: string,
    TECH_UNIT_RESEND_INVITATION_CONFIRM_MESSAGE_1: string,
    TECH_UNIT_RESEND_INVITATION_CONFIRM_MESSAGE_2: string,
    TECH_UNIT_RESEND_INVITATION_CANCEL: string,
    TECH_UNIT_RESEND_INVITATION_RESEND: string,
    TECH_UNIT_CANCEL_OWNERSHIP_SUBMIT: string,
    TECH_UNIT_CANCEL_OWNERSHIP_MESSAGE_2: string,
    TECH_UNIT_CANCEL_OWNERSHIP_MESSAGE_1: string,
    TECH_UNIT_CANCEL_OWNERSHIP_PROCESS: string,
    TECH_UNITS_NOT_ONBOARDED: string,
    TECH_UNITS_STATUS: string,
    TECH_UNITS_INSTALLATION_DATE: string,
    TECH_UNITS_UPDATE_DATE: string,
    TECH_UNITS_INV_SERIAL_NUMBER: string,
    TECH_UNITS_REQ_INFO: string,
    TECH_UNITS_CITY: string,
    TECH_UNITS_COUNTRY: string,
    TECH_UNITS_EDIT_DETAILS: string,
    TECH_UNITS_FIRST_NAME: string,
    TECH_UNITS_LAST_NAME: string,
    TECH_UNITS_EMAIL: string,
    TECH_UNITS_CONFIRM_EMAIL: string,
    TECH_UNITS_CONFIRM_INFO: string,
    TECH_UNITS_MODAL_CANCEL: string,
    TECH_UNITS_MODAL_SAVE: string,
    TECH_UNITS_POSTAL_CODE: string,
    TECH_UNITS_ADDRESS: string,
    TECH_UNITS_NO_OPTIONS: string
  },
  resendModal: boolean,
  cancelOwnershipModal: boolean,
  countries: [],
  cities: [],
  currentUnit: {},
  loadingInProgress: boolean
}

class UnitInfoForm extends Component<Props> {
  actions: {
    editUnit: Function,
    cancelInputMode: Function,
    change: Function,
    submit: Function,
    showResendModal: Function,
    hideResendModal: Function,
    resendInvitation: Function,
    cancelOwnership: Function,
    showCancelOwnershipModal: Function,
    hideCancelOwnershipModal: Function,
    setCities: Function
  }

  render () {
    const {
      isMobile,
      inputMode,
      onCancel,
      form,
      dirty,
      error,
      unitInfo,
      loading,
      translations,
      resendModal,
      cancelOwnershipModal,
      countries,
      cities,
      currentUnit,
      loadingInProgress
    } = this.props

    const {
      TECH_UNIT_RESEND_INVITATION,
      TECH_UNIT_RESEND_INVITATION_CONFIRM_MESSAGE_1,
      TECH_UNIT_RESEND_INVITATION_CONFIRM_MESSAGE_2,
      TECH_UNIT_RESEND_INVITATION_CANCEL,
      TECH_UNIT_RESEND_INVITATION_RESEND,
      TECH_UNIT_CANCEL_OWNERSHIP_SUBMIT,
      TECH_UNIT_CANCEL_OWNERSHIP_MESSAGE_2,
      TECH_UNIT_CANCEL_OWNERSHIP_MESSAGE_1,
      TECH_UNIT_CANCEL_OWNERSHIP_PROCESS,
      TECH_UNITS_NOT_ONBOARDED,
      TECH_UNITS_STATUS,
      TECH_UNITS_INSTALLATION_DATE,
      TECH_UNITS_UPDATE_DATE,
      TECH_UNITS_INV_SERIAL_NUMBER,
      TECH_UNITS_REQ_INFO,
      TECH_UNITS_CITY,
      TECH_UNITS_COUNTRY,
      TECH_UNITS_EDIT_DETAILS,
      TECH_UNITS_FIRST_NAME,
      TECH_UNITS_LAST_NAME,
      TECH_UNITS_EMAIL,
      TECH_UNITS_CONFIRM_EMAIL,
      TECH_UNITS_CONFIRM_INFO,
      TECH_UNITS_MODAL_CANCEL,
      TECH_UNITS_MODAL_SAVE,
      TECH_UNITS_POSTAL_CODE,
      TECH_UNITS_ADDRESS,
      TECH_UNITS_NO_OPTIONS
    } = translations

    const {
      editUnit,
      cancelInputMode,
      change,
      submit,
      showResendModal,
      hideResendModal,
      resendInvitation,
      cancelOwnership,
      showCancelOwnershipModal,
      hideCancelOwnershipModal,
      setCities
    } = this.actions

    const {
      firstName,
      lastName,
      email,
      confirmEmail,
      country,
      city,
      installationDate,
      inverterSerialNumber,
      confirmInfo,
      address,
      postalCode
    } = form

    if (loading) {
      return <LoadingCircular />
    }

    const unitForm = (
      <React.Fragment>
        <Form
          mobile
          containerHeight={!isMobile && 'calc(100vh - 170px)'}
        >
          <FormGeneralError dirty={dirty} error={error} full />
          <Form.Row>
            <InputText
              id={'FirstName'}
              label={TECH_UNITS_FIRST_NAME}
              value={firstName.value}
              error={dirty && !firstName.valid}
              onChange={({ target }) => change({ firstName: target.value })}
            />
            <FormFieldError
              id={'FirstName'}
              showError={dirty && !firstName.valid}
              errorMessage={firstName.message}
            />
          </Form.Row>
          <Form.Row>
            <InputText
              id={'LastName'}
              label={TECH_UNITS_LAST_NAME}
              value={lastName.value}
              error={dirty && !lastName.valid}
              onChange={({ target }) => change({ lastName: target.value })}
            />
            <FormFieldError
              id={'LastName'}
              showError={dirty && !lastName.valid}
              errorMessage={lastName.message}
            />
          </Form.Row>
          <Form.Row>
            <InputText
              id={'Email'}
              label={TECH_UNITS_EMAIL}
              value={email.value}
              error={dirty && !email.valid}
              onChange={({ target }) => change({ email: target.value })}
            />
            <FormFieldError
              id={'Email'}
              showError={dirty && !email.valid}
              errorMessage={email.message}
            />
          </Form.Row>
          <Form.Row>
            <InputText
              id={'ConfirmEmail'}
              label={TECH_UNITS_CONFIRM_EMAIL}
              value={confirmEmail.value}
              error={dirty && !confirmEmail.valid}
              onChange={({ target }) =>
                change({ confirmEmail: target.value.toLowerCase() })
              }
            />
            <FormFieldError
              id={'ConfirmEmail'}
              showError={dirty && !confirmEmail.valid}
              errorMessage={confirmEmail.message}
            />
          </Form.Row>
          <Form.Row>
            <DatetimePicker
              id={'InstallationDate'}
              label={TECH_UNITS_INSTALLATION_DATE}
              onChange={value => change({ installationDate: value })}
              value={installationDate.value}
              dateFormat={'DD/MM/YYYY'}
              timeFormat={'HH:mm'}
            />
            <FormFieldError
              id={'InstallationDate'}
              showError={dirty && !installationDate.valid}
              errorMessage={installationDate.message}
            />
          </Form.Row>
          <Form.Row>
            <SelectAutocomplete
              id={'Country'}
              noOptionsLabel={TECH_UNITS_NO_OPTIONS}
              options={countries}
              selected={country}
              dirty={dirty}
              label={TECH_UNITS_COUNTRY}
              onChange={selected => {
                change({ country: selected })
                change({ city: '' })
                setCities([])
              }}
            />
            <FormFieldError
              id={'Country'}
              showError={dirty && !country.valid}
              errorMessage={country.message}
            />
          </Form.Row>
          <Form.Row>
            <SelectAutocomplete
              id={'City'}
              noOptionsLabel={TECH_UNITS_NO_OPTIONS}
              options={cities}
              selected={city}
              dirty={dirty}
              label={TECH_UNITS_CITY}
              onChange={selected => change({ city: selected })}
            />
            <FormFieldError
              id={'City'}
              showError={dirty && !city.valid}
              errorMessage={city.message}
            />
          </Form.Row>
          <Form.Row>
            <InputText
              id={'InstallationAddress'}
              label={TECH_UNITS_ADDRESS}
              type='text'
              value={address.value}
              error={dirty && !address.valid}
              onChange={({ target }) => change({ address: target.value })}
            />
            <FormFieldError
              id={'InstallationAddress'}
              showError={dirty && !address.valid}
              errorMessage={address.message}
            />
          </Form.Row>
          <Form.Row>
            <InputText
              id={'PostalCode'}
              label={TECH_UNITS_POSTAL_CODE}
              value={postalCode.value}
              error={dirty && !postalCode.valid}
              onChange={({ target }) => change({ postalCode: target.value })}
            />
            <FormFieldError
              id={'PostalCode'}
              showError={dirty && !postalCode.valid}
              errorMessage={postalCode.message}
            />
          </Form.Row>
          <Form.Row>
            <InputText
              id={'InverterSerialNumber'}
              label={TECH_UNITS_INV_SERIAL_NUMBER}
              value={inverterSerialNumber.value}
              error={dirty && !inverterSerialNumber.valid}
              onChange={({ target }) =>
                change({ inverterSerialNumber: target.value })
              }
            />
            <FormFieldError
              id={'InverterSerialNumber'}
              showError={dirty && !inverterSerialNumber.valid}
              errorMessage={inverterSerialNumber.message}
            />
          </Form.Row>
          <Form.Row padding shadow={'bottom-shadow'}>
            <FormControlLabel
              control={
                <Checkbox
                  id={'Confirmation'}
                  checked={confirmInfo.value}
                  onChange={({ target }) =>
                    change({ confirmInfo: target.checked })
                  }
                />
              }
              label={<Label>{TECH_UNITS_CONFIRM_INFO}</Label>}
            />
            <FormFieldError
              id={'Confirmation'}
              showError={dirty && !confirmInfo.valid}
              errorMessage={confirmInfo.message}
            />
          </Form.Row>
        </Form>
        <Form.Buttons fixedSideBar mobile={isMobile}>
          <Button
            id={'PrimaryButton_Cancel'}
            buttonType={'primary-cancel'}
            margin={'0 12px'}
            buttonWidth={'163px'}
            onClick={() => (unitInfo ? cancelInputMode() : onCancel())}
          >
            {TECH_UNITS_MODAL_CANCEL}
          </Button>
          <Button
            id={'PrimaryButton_Submit'}
            margin={'0 12px'}
            buttonWidth={'163px'}
            onClick={submit}
          >
            {TECH_UNITS_MODAL_SAVE}
          </Button>
        </Form.Buttons>
      </React.Fragment>
    )

    const info = (
      <React.Fragment>
        <UnitDetails mobile={isMobile}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <FormFieldInfo id={'Status_Info'} label={TECH_UNITS_STATUS}>
                <StatusIndicator isPending />
                <b>{TECH_UNITS_NOT_ONBOARDED}</b>
              </FormFieldInfo>
            </Grid>
            <Grid item xs={5}>
              <FormFieldInfo
                id={'InstallationDate_Info'}
                label={TECH_UNITS_INSTALLATION_DATE}
              >
                <b>
                  {unitInfo &&
                    moment(unitInfo.installationDate).format('DD/MM/YYYY')}
                </b>
              </FormFieldInfo>
            </Grid>
            <Grid item xs={3}>
              <FormFieldInfo
                id={'UpdateDate_Info'}
                label={TECH_UNITS_UPDATE_DATE}
              >
                <b>
                  {unitInfo &&
                    moment(unitInfo.updatedAt).format('DD/MM/YYYY HH:mm')}
                </b>
              </FormFieldInfo>
            </Grid>
          </Grid>
          <FormFieldInfo
            id={'InverterSerialNumber_Info'}
            label={TECH_UNITS_INV_SERIAL_NUMBER}
          >
            <b>{inverterSerialNumber.value}</b>
          </FormFieldInfo>
          <FormFieldInfo id={'FirstName_Info'} label={TECH_UNITS_REQ_INFO}>
            <b>{`${firstName.value} ${lastName.value}`}</b>
            <RequesterEmail>{email.value}</RequesterEmail>
          </FormFieldInfo>
          <FormFieldInfo id={'Address_Info'} label={TECH_UNITS_ADDRESS}>
            <b>{address.value}</b>
          </FormFieldInfo>
          <FormFieldInfo id={'PostalCode_Info'} label={TECH_UNITS_POSTAL_CODE}>
            <b>{postalCode.value}</b>
          </FormFieldInfo>
          <FormFieldInfo id={'City_Info'} label={TECH_UNITS_CITY}>
            <b>{currentUnit && currentUnit.city && currentUnit.city.name}</b>
          </FormFieldInfo>
          <FormFieldInfo id={'Country_Info'} label={TECH_UNITS_COUNTRY}>
            <b>
              {currentUnit && currentUnit.country && currentUnit.country.name}
            </b>
          </FormFieldInfo>
        </UnitDetails>
        <UnitOptionsContainer mobile={isMobile}>
          <List>
            <List.Item id={'EditDetails_Button'} button onClick={editUnit}>
              <List.ItemText primary={TECH_UNITS_EDIT_DETAILS} />
              <Icon style={{ color: '#007BC1' }}>{'keyboard_arrow_right'}</Icon>
            </List.Item>
            <List.Item
              id={'ResendInvitation_Button'}
              button
              onClick={showResendModal}
            >
              <List.ItemText primary={TECH_UNIT_RESEND_INVITATION} />
              <Icon style={{ color: '#007BC1' }}>{'keyboard_arrow_right'}</Icon>
            </List.Item>
            <List.Item
              id={'CancelOwnership_Button'}
              button
              onClick={showCancelOwnershipModal}
            >
              <CancelItemText primary={TECH_UNIT_CANCEL_OWNERSHIP_PROCESS} />
              <Icon style={{ color: '#CA3C3D' }}>{'keyboard_arrow_right'}</Icon>
            </List.Item>
          </List>
        </UnitOptionsContainer>
        <Modal open={resendModal} size={'small'}>
          <div>
            <ModalHeader title={TECH_UNIT_RESEND_INVITATION} />
            <ModalContent padding size={'small'} mobile={isMobile}>
              <Typography variant={'body1'}>
                {TECH_UNIT_RESEND_INVITATION_CONFIRM_MESSAGE_1}
                {firstName.value} {lastName.value} .
                <br />
                {TECH_UNIT_RESEND_INVITATION_CONFIRM_MESSAGE_2}
              </Typography>
            </ModalContent>
            <ModalButtons>
              <Button
                id={'PrimaryButton_Cancel'}
                buttonType={'primary-cancel'}
                margin={'0 12px'}
                buttonWidth={'156px'}
                onClick={hideResendModal}
                disabled={loadingInProgress}
              >
                {TECH_UNIT_RESEND_INVITATION_CANCEL}
              </Button>
              <Button
                id={'PrimaryButton_Resend'}
                margin={'0 12px'}
                buttonWidth={'156px'}
                onClick={resendInvitation}
                disabled={loadingInProgress}
              >
                {TECH_UNIT_RESEND_INVITATION_RESEND}
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal open={cancelOwnershipModal} size={'small'}>
          <div>
            <ModalHeader title={TECH_UNIT_CANCEL_OWNERSHIP_PROCESS} red />
            <ModalContent padding size={'small'} mobile={isMobile}>
              <Typography variant={'body1'}>
                {TECH_UNIT_CANCEL_OWNERSHIP_MESSAGE_1}
                <br />
                {TECH_UNIT_CANCEL_OWNERSHIP_MESSAGE_2}
              </Typography>
            </ModalContent>
            <ModalButtons>
              <Button
                id={'PrimaryButton_Cancel'}
                buttonType={'primary-cancel'}
                margin={'0 12px'}
                buttonWidth={'156px'}
                onClick={hideCancelOwnershipModal}
                disabled={loadingInProgress}
              >
                {TECH_UNIT_RESEND_INVITATION_CANCEL}
              </Button>
              <Button
                id={'PrimaryButton_Submit'}
                margin={'0 12px'}
                buttonWidth={'156px'}
                buttonType={'primary-danger'}
                onClick={cancelOwnership}
                disabled={loadingInProgress}
              >
                {TECH_UNIT_CANCEL_OWNERSHIP_SUBMIT}
              </Button>
            </ModalButtons>
          </div>
        </Modal>
      </React.Fragment>
    )

    return (
      <Wrapper>
        {!inputMode && info}
        {inputMode && unitForm}
      </Wrapper>
    )
  }
}

export default Logic(UnitInfoForm)

const Wrapper = styled.div``

const Label = styled.span`
  color: #5b6770;
  font-size: 14px;
  font-weight: bold;
`

const UnitOptionsContainer = styled.div`
  box-shadow: inset 0 1px 0 0 rgba(224, 238, 248, 0.4);
  background-color: #f9fbfd;
  padding: 14px;
  width: 100%;
  bottom: 0;
  left: 0;

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    padding: 14px 44px 0;
  }

  ${({ mobile }) =>
    !mobile &&
    `
    position: absolute;
  `}
`

const CancelItemText = styled(props => <List.ItemText {...props} />)`
  &&&& {
    span {
      color: #ca3c3d!important;
    }
  }
`

const UnitDetails = styled.div`
  padding: 0 4px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  ${({ mobile }) => mobile && `
    padding: 0 16px;
  `}

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    padding: 18px 50px 0;
  }
`

const RequesterEmail = styled.p`
  margin: 6px 0;
`
