// @flow
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

import {
  EnergyComponent,
  ComponentShadowButton,
  IcSolarPanel,
  IcSolarPanelExternal,
  EnergyFlowLabel
} from 'xstorage-components'

import EnergySolarMultipleFlow from 'components/EnergySolarMultipleFlow'
import MultipleSolarPVModal from 'components/MultipleSolarPVModal'

import SolarFlow from './images/SolarFlow'

type Props = {
  status: string,
  value: number,
  mobile: boolean,
  onClick: Function,
  onClose: Function,
  multipvdetails: boolean,
  multipleSolarPanels: any
}

export default class EnergySolarFlow extends Component<Props, void> {
  render () {
    const {
      mobile,
      onClick,
      onClose,
      multipvdetails,
      multipleSolarPanels
    } = this.props

    let none =
      multipleSolarPanels.numberPV === 0 ||
      multipleSolarPanels.onePvRole === 'NONE' ||
      (multipleSolarPanels.acPvRole === 'NONE' &&
        multipleSolarPanels.dcPvRole === 'NONE')
    let disconnected = multipleSolarPanels.numberPV === 0
    let consumer =
      multipleSolarPanels.acPvRole === 'CONSUMER' ||
      multipleSolarPanels.dcPvRole === 'CONSUMER'

    return (
      <div>
        {/* multi pv modal is open */}
        {multipvdetails && (
          <Wrapper mobile={mobile} multipvdetails={multipvdetails}>
            {mobile ? (
              <MultipleSolarPVModal
                multipleSolarPanels={multipleSolarPanels}
                open={multipvdetails}
                onClose={onClose}
                mobile={mobile}
              />
            ) : (
              <EnergySolarMultipleFlow
                multipleSolarPanels={multipleSolarPanels}
                onClose={onClose}
                mobile={mobile}
              />
            )}

            {!disconnected && !none && !mobile && (
              <EnergyFlowContainer>
                <SolarFlow
                  multipvdetails={multipvdetails}
                  reverse={consumer}
                  mobile={mobile}
                />
                <FlowLabel mobile={mobile} multipvdetails={multipvdetails}>
                  <EnergyFlowLabel
                    direction={'up'}
                    value={multipleSolarPanels.pvValueSum}
                  />
                </FlowLabel>
              </EnergyFlowContainer>
            )}
          </Wrapper>
        )}

        {!multipvdetails && (
          <div>
            {/* Multi PV modal is closed */}

            {/* Number of solar panels is bigger
              than one (2 is the maximum up to now) */}

            {multipleSolarPanels.numberPV > 1 && (
              <Wrapper
                mobile={mobile}
                multipvdetails={multipvdetails}
                onClick={onClick}
              >
                <div>
                  <EnergyComponent
                    disconnected={disconnected}
                    clickable
                    className='fourth-step'
                  >
                    <Fragment>
                      <ImageSolarPanel />
                    </Fragment>
                  </EnergyComponent>
                  <ComponentShadowButton mobile={mobile}>
                    {multipleSolarPanels.numberPV}
                  </ComponentShadowButton>
                </div>
                {!disconnected && !none && (
                  <EnergyFlowContainer>
                    <SolarFlow
                      multipvdetails={multipvdetails}
                      reverse={consumer}
                      mobile={mobile}
                    />
                    <FlowLabel mobile={mobile}>
                      <EnergyFlowLabel
                        direction={'up'}
                        value={multipleSolarPanels.pvValueSum}
                      />
                    </FlowLabel>
                  </EnergyFlowContainer>
                )}
              </Wrapper>
            )}

            {/* Only one solar panel */}
            {multipleSolarPanels.numberPV === 1 && (
              <Wrapper mobile={mobile}>
                <EnergyComponent disconnected={disconnected}>
                  {multipleSolarPanels.onePvType === 'ac' && (
                    <ImageSolarPanelExternal />
                  )}
                  {multipleSolarPanels.onePvType === 'dc' && (
                    <ImageSolarPanel />
                  )}
                </EnergyComponent>

                {!disconnected && !none && (
                  <EnergyFlowContainer>
                    <SolarFlow
                      multipvdetails={multipvdetails}
                      reverse={consumer}
                      mobile={mobile}
                    />
                    <FlowLabel mobile={mobile}>
                      <EnergyFlowLabel
                        direction={'up'}
                        value={multipleSolarPanels.pvValueSum}
                      />
                    </FlowLabel>
                  </EnergyFlowContainer>
                )}
              </Wrapper>
            )}
            {!multipleSolarPanels.numberPV && (
              <Wrapper mobile={mobile}>
                <EnergyComponent disconnected={disconnected}>
                  <ImageSolarPanel />
                </EnergyComponent>
              </Wrapper>
            )}
          </div>
        )}
      </div>
    )
  }
}

const Wrapper = styled.div`
  ${({ mobile, multipvdetails }) =>
    mobile && !multipvdetails
      ? `
    height: 100px;
    position: relative;

  `
      : mobile && multipvdetails
        ? `
    height: 300px;
    position: absolute;
    top: -20%;
    left: -12%;
  `
        : !mobile && !multipvdetails
          ? `
  height: 100px;
    position: absolute;
    top: 0;
    left: 0;
  `
          : `
  height: 300px;
    position: absolute;
    top: -34%;
    left: -12%;
  `};

  ${({ onClick }) =>
    !!onClick &&
    `
  cursor: pointer;
  `};
`

const ImageSolarPanel = styled(props => <IcSolarPanel {...props} />)`
  position: absolute !important;
  top: 38px !important;
  left: 32px !important;
`

const ImageSolarPanelExternal = styled(props => (
  <IcSolarPanelExternal {...props} />
))`
  position: absolute !important;
  top: 38px !important;
  left: 32px !important;
`

const EnergyFlowContainer = styled.div`
  position: relative;
`

const FlowLabel = styled.div`
  position: absolute;
  width: 80px;

  ${({ mobile, multipvdetails }) =>
    mobile && multipvdetails
      ? `
    left: 75px;
    top: 115px;
  `
      : mobile && !multipvdetails
        ? `
    left: 75px;
    top: 115px;
  `
        : !mobile && !multipvdetails
          ? `
    left: 0;
    top: 130px;
  `
          : `
    left: 250px;
    top: 15px;
  `};
`
