// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, Typography } from '@material-ui/core'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  Button,
  Typography as SharedTypography,
  List,
  Modal,
  ModalHeader,
  ModalContent,
  Divider,
  FormErrorMessage,
  ModalButtons,
  PageMainSection,
  Loading
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  deleteModal: boolean,
  error: any,
  isOwner: boolean
}

export class DeviceDelete extends Component<Props, void> {
  actions: any

  render () {
    const {
      isMobile,
      translations,
      deleteModal,
      error,
      isOwner,
      loading,
      currentDeviceId
    } = this.props

    const {
      navigate,
      deleteUser,
      showDeleteModal,
      hideDeleteModal
    } = this.actions

    const {
      UNITSETT_TITLE,
      UNITSETT_DELETE_TITLE,
      UNITSETT_DELETE_SUB_TITLE,
      UNITSETT_DELETE_TEXT_1,
      UNITSETT_DELETE_TEXT_2,
      UNITSETT_OWNER_DELETE_SUB_TITLE,
      UNITSETT_OWNER_DELETE_TEXT_1,
      UNITSETT_DELETE_BUTTON,
      UNITSETT_DELETE_MODAL_TITLE,
      UNITSETT_DELETE_MODAL_SUB_TITLE,
      UNITSETT_DELETE_MODAL_TEXT,
      UNITSETT_DELETE_MODAL_CANCEL,
      UNITSETT_DELETE_MODAL_DELETE
    } = translations

    return (
      <Wrapper>
        <PageAdaptable
          title={UNITSETT_TITLE}
          menu={<DeviceSettingsMenu page={'delete-unit'} />}
          isForm={false}
          onBack={() => navigate(`/${currentDeviceId}/settings/`)}
        >
          <PageMainSection padding mobile={isMobile}>
            {loading && <Loading />}
            <PageTitle mobile={isMobile}>{UNITSETT_DELETE_TITLE}</PageTitle>
            <Typography variant='h4' gutterBottom>
              {isOwner
                ? UNITSETT_OWNER_DELETE_SUB_TITLE
                : UNITSETT_DELETE_SUB_TITLE}
            </Typography>
            <SharedTypography paragraph>
              {isOwner ? UNITSETT_OWNER_DELETE_TEXT_1 : UNITSETT_DELETE_TEXT_1}
            </SharedTypography>
            <SharedTypography paragraph>
              {isOwner ? '' : UNITSETT_DELETE_TEXT_2}
            </SharedTypography>
            {!isOwner && (
              <List>
                <React.Fragment>
                  <Divider variant={'inset'} component='li' />
                  <List.Item button onClick={showDeleteModal}>
                    <DeleteItemText primary={UNITSETT_DELETE_BUTTON} />
                    <Icon style={{ color: '#CA3C3D' }}>
                      {'keyboard_arrow_right'}
                    </Icon>
                  </List.Item>
                  <Divider variant={'inset'} component='li' />
                </React.Fragment>
              </List>
            )}
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </PageMainSection>
          {deleteModal && (
            <Modal open={deleteModal} size={'small'} onClose={hideDeleteModal}>
              {/* TODO: maybe we can create a common container for this */}
              <div>
                <ModalHeader red title={UNITSETT_DELETE_MODAL_TITLE} />
                <ModalContent padding size={'small'} mobile={isMobile}>
                  <SharedTypography>
                    <b>{UNITSETT_DELETE_MODAL_SUB_TITLE}</b>
                  </SharedTypography>
                  <SharedTypography>
                    {UNITSETT_DELETE_MODAL_TEXT}
                  </SharedTypography>
                </ModalContent>
                <ModalButtons>
                  <Button
                    id={'PrimaryButton_Cancel'}
                    buttonType={'primary-cancel'}
                    margin={'0 12px'}
                    buttonWidth={'156px'}
                    onClick={hideDeleteModal}
                  >
                    {UNITSETT_DELETE_MODAL_CANCEL}
                  </Button>
                  <Button
                    id={'PrimaryButton_Delete'}
                    buttonType={'primary-danger'}
                    margin={'0 12px'}
                    buttonWidth={'156px'}
                    onClick={deleteUser}
                  >
                    {UNITSETT_DELETE_MODAL_DELETE}
                  </Button>
                </ModalButtons>
              </div>
            </Modal>
          )}
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(DeviceDelete)

const Wrapper = styled.div``

const DeleteItemText = styled(props => <List.ItemText {...props} />)`
  &&&& {
    span {
      color: #ca3c3d !important;
    }
  }
`
