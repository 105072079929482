import React, { Component } from 'react'
import { ENERGY_FLOW_DURATION_FLOW } from 'config'
import anime from 'animejs'
import styled from 'styled-components'

export default class MultipleEnergyFlow extends Component {
  componentDidMount () {
    const pathAcElement = document.querySelector('#motion-ac path')

    if (pathAcElement) {
      const pathAcAnime = anime.path('#motion-ac path')
      anime({
        targets: '#motion-ac > div',
        translateX: pathAcAnime('x'),
        translateY: pathAcAnime('y'),
        rotate: pathAcAnime('angle'),
        easing: 'linear',
        duration: 2000,
        loop: true,
        direction: 'normal'
      })
    }

    const pathDcElement = document.querySelector('#motion-dc path')

    if (pathDcElement) {
      const pathDcAnime = anime.path('#motion-dc path')
      anime({
        targets: '#motion-dc > div',
        translateX: pathDcAnime('x'),
        translateY: pathDcAnime('y'),
        rotate: pathDcAnime('angle'),
        easing: 'linear',
        duration: 2000,
        loop: true,
        direction: 'normal'
      })
    }
  }

  render () {
    const { mobile, acPanelOn, dcPanelOn, bothPanelsOn } = this.props
    let dimensionsAc, dimensionsDc, pathAc, pathDc

    dimensionsDc = {
      height: '300',
      width: '300',
      viewBox: '-76 -122 300 300'
    }
    pathDc = 'M 0 0 V 50 H 46 V 140'

    dimensionsAc = {
      height: '300',
      width: '300',
      viewBox: '-168 -122 300 300'
    }
    pathAc = 'M 0 0 V 50 H -46 V 140'
    return (
      <div>
        { (acPanelOn || bothPanelsOn) &&
          <Wrapper key={'ac'} id={'motion-ac'} mobile={mobile}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              {...dimensionsAc}
            >
              <path
                strokeDasharray='5, 16'
                strokeLinecap='round'
                d={pathAc}
                fill='none'
                stroke='#fff'
                strokeWidth='3'
                id={'ac-wire'}
              />
            </svg>
            <Circle circleItem={'ac'} mobile={mobile} />
          </Wrapper>
        }

        { (dcPanelOn || bothPanelsOn) &&
          <Wrapper key={'dc'} id={'motion-dc'} mobile={mobile}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              {...dimensionsDc}
            >
              <path
                strokeDasharray='5, 16'
                strokeLinecap='round'
                d={pathDc}
                fill='none'
                stroke='#fff'
                strokeWidth='3'
                id={'dc-wire'}
              />
            </svg>
            <Circle circleItem={'dc'} mobile={mobile} />
          </Wrapper>
        }
      </div>
    )
  }
}

MultipleEnergyFlow.defaultProps = {
  id: 'MultipleEnergyFlow',
  duration: ENERGY_FLOW_DURATION_FLOW,
  begin: 0,
  reverse: false,
  mobile: false
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;

  ${({ mobile }) =>
    !mobile &&
    `
    left: 0px;
    top: 0px;
    padding: 20px 20px 0;

  `} ${({ mobile }) =>
  mobile &&
    `
    left: 51px;
    top: 80px;
  `};
`

const Circle = styled.div`
  box-shadow: 0px 0px 3px 2px rgba(51, 51, 51, 0.5);
  border-radius: 50%;
  position: absolute;

  ${({ mobile }) =>
    !mobile
      ? `
    background: #007BC1;
    border: 3px solid #FFF;
    top: 135px;
    width: 14px;
    height: 14px;
    box-shadow: 0px 0px 15px 1px rgba(0, 123, 193, 0.4);
  `
      : `
    background: #FFF;
    width: 12px;
    height: 12px;
    top: 0;
  `};

  ${({ circleItem }) =>
    circleItem === 'dc'
      ? `
    left:  26%;
  `
      : `
    left:  53%;
  `};
`
