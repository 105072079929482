// @flow
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Logic from './logic'
import styled from 'styled-components'
import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'
import {
  EXPORT_INCLUDED_INFO_VARIABLES,
  granularityOptionsFilter
} from './utils'

import FormGeneralError from 'containers/FormGeneralError'
import FormFieldError from 'containers/FormFieldError'
import {
  Button,
  SelectMultiple,
  DatepickerLabeled,
  Select,
  Modal,
  ModalHeader,
  ModalContent,
  NoBatteryLabel,
  LoadingCircular,
  Form
} from 'xstorage-components'

type Props = {}

class ExportChartPanel extends Component<Props, void> {
  detailsHeight: number = 0

  componentDidUpdate () {
    const node: any = ReactDOM.findDOMNode(this.refs.details)
    this.detailsHeight = node ? node.scrollHeight : 0
  }

  render () {
    const {
      onCancel,
      translations,
      form,
      error,
      dirty,
      loading,
      isMobile,
      batteryReadyMode
    } = this.props
    const { change, submit } = this.actions

    const {
      CHART_EXPORT_PANEL_CANCEL_BUTTON,
      CHART_EXPORT_PANEL_EXPORT_BUTTON,
      CHART_EXPORT_INCLUDED_INFORMATION,
      CHART_EXPORT_SELECTED,
      CHART_EXPORT_STARTDATE,
      CHART_EXPORT_ENDDATE,
      CHART_EXPORT_DATA_GRANULARITY,
      CHART_EXPORT_TITLE,
      DASH_NO_BATTERY
    } = translations

    const { startDate, endDate, granularity, includedInfo } = form

    if (loading) {
      return <LoadingCircular />
    }

    const includedInfoOptions = cloneDeep(EXPORT_INCLUDED_INFO_VARIABLES)

    includedInfoOptions.forEach(element => {
      element.name = translations[element.name]

      if (element.key === 'BATTERY_STATE_OF_CHARGE' && batteryReadyMode) {
        element.help = <NoBatteryLabel label={DASH_NO_BATTERY} />
      }
    })

    const granularityOptions = granularityOptionsFilter(
      startDate.value,
      endDate.value
    )

    granularityOptions.forEach(element => {
      element.label = translations[element.label]
    })

    return (
      <Wrapper>
        {!isMobile && (
          <React.Fragment>
            <Form
              mobile={isMobile}
              position={'absolute'}
              leftPosition={'0'}
              containerHeight={!isMobile && 'calc(100vh - 168px)'}
              formmargin={isMobile}
            >
              <FormGeneralError dirty={dirty} error={error} full />
              <Form.Row displayType={'flex'}>
                <DatepickerLabeled
                  id={'datepickerOne'}
                  onChange={val => change({ startDate: val })}
                  maxDate={moment()}
                  locale={moment.locale()}
                  utcOffset={moment().utcOffset()}
                  popperPlacement={'bottom-start'}
                  label={CHART_EXPORT_STARTDATE}
                  selected={moment(startDate.value, 'DD/MM/YYYY')}
                  margin={'16px 0 0 8px'}
                />
                <FormFieldError
                  id={'StartDate'}
                  showError={dirty && !startDate.valid}
                  errorMessage={startDate.message}
                  full
                />
                <DatepickerLabeled
                  id={'datepickerTwo'}
                  onChange={val => change({ endDate: val })}
                  maxDate={moment()}
                  locale={moment.locale()}
                  utcOffset={moment().utcOffset()}
                  popperPlacement={'bottom-end'}
                  label={CHART_EXPORT_ENDDATE}
                  margin={'16px 8px 0 8px'}
                  selected={moment(endDate.value, 'DD/MM/YYYY')}
                  className={'popupDatepickerTwo'}
                />
                <FormFieldError
                  id={'EndDate'}
                  showError={dirty && !endDate.valid}
                  errorMessage={endDate.message}
                  full
                />
              </Form.Row>
              <Form.Row>
                <Select
                  id={'Granularity'}
                  label={CHART_EXPORT_DATA_GRANULARITY}
                  options={granularityOptions}
                  selected={granularity.value}
                  onChange={selected => change({ granularity: selected })}
                  heightAuto
                  containerMargin={!isMobile && '0 8px'}
                />
                <FormFieldError
                  id={'Granularity'}
                  showError={dirty && !granularity.valid}
                  errorMessage={granularity.message}
                  full
                />
              </Form.Row>
              <Form.Row>
                <SelectMultiple
                  options={includedInfoOptions}
                  onChange={selected => change({ includedInfo: selected })}
                  label={CHART_EXPORT_INCLUDED_INFORMATION}
                  selectedLabel={CHART_EXPORT_SELECTED}
                  selected={includedInfo.value}
                  containerMargin={!isMobile && '0 8px'}
                  containerPadding={'16px 0 16px 8px'}
                  color={'#007BC1'}
                />
                <FormFieldError
                  id={'IncludedInfo'}
                  showError={dirty && !includedInfo.valid}
                  errorMessage={includedInfo.message}
                  full
                />
              </Form.Row>
            </Form>
            <Form.Buttons fixedSideBar mobile={false}>
              <Button
                id={'PrimaryButton_Cancel'}
                margin={'0 12px'}
                buttonType={'primary-cancel'}
                buttonWidth={'163px'}
                onClick={onCancel}
              >
                {CHART_EXPORT_PANEL_CANCEL_BUTTON}
              </Button>
              <Button
                id={'PrimaryButton_Export'}
                margin={'0 12px'}
                smallShadow
                buttonWidth={'163px'}
                onClick={submit}
                disabled={loading}
              >
                {CHART_EXPORT_PANEL_EXPORT_BUTTON}
              </Button>
            </Form.Buttons>
          </React.Fragment>
        )}
        {isMobile && (
          <Modal open size={'medium'} onClose={onCancel}>
            <div>
              <ModalHeader title={CHART_EXPORT_TITLE} />
              <ModalContent type={'diagonal'} size={'medium'} mobile={isMobile}>
                <Form
                  mobile
                  formModal
                  padding={'10px'}
                  containerHeight={'calc(80vh - 300px)'}
                >
                  <FormGeneralError dirty={dirty} error={error} />
                  <Form.Row padding displayType={'inline-flex'}>
                    <DatepickerLabeled
                      id={'datepickerOne'}
                      onChange={val => change({ startDate: val })}
                      maxDate={moment()}
                      locale={moment.locale()}
                      utcOffset={moment().utcOffset()}
                      popperPlacement={'bottom-start'}
                      label={CHART_EXPORT_STARTDATE}
                      selected={moment(startDate.value, 'DD/MM/YYYY')}
                    />
                    <FormFieldError
                      id={'StartDate'}
                      showError={dirty && !startDate.valid}
                      errorMessage={startDate.message}
                    />
                    <DatepickerLabeled
                      id={'datepickerTwo'}
                      onChange={val => change({ endDate: val })}
                      maxDate={moment()}
                      locale={moment.locale()}
                      utcOffset={moment().utcOffset()}
                      popperPlacement={'bottom-end'}
                      label={CHART_EXPORT_ENDDATE}
                      margin={'0 0 0 24px'}
                      selected={moment(endDate.value, 'DD/MM/YYYY')}
                    />
                    <FormFieldError
                      id={'EndDate'}
                      showError={dirty && !endDate.valid}
                      errorMessage={endDate.message}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Select
                      label={CHART_EXPORT_DATA_GRANULARITY}
                      options={granularityOptions}
                      selected={granularity.value}
                      onChange={selected => change({ granularity: selected })}
                      heightAuto
                    />
                    <FormFieldError
                      id={'Granularity'}
                      showError={dirty && !granularity.valid}
                      errorMessage={granularity.message}
                    />
                  </Form.Row>
                  <Form.Row>
                    <SelectMultiple
                      options={includedInfoOptions}
                      onChange={selected => change({ includedInfo: selected })}
                      label={CHART_EXPORT_INCLUDED_INFORMATION}
                      selectedLabel={CHART_EXPORT_SELECTED}
                      selected={includedInfo.value}
                      color={'#007BC1'}
                    />
                    <FormFieldError
                      id={'IncludedInfo'}
                      showError={dirty && !includedInfo.valid}
                      errorMessage={includedInfo.message}
                    />
                  </Form.Row>
                </Form>
                <Form.Buttons mobile>
                  <Button
                    id={'PrimaryButton_Cancel'}
                    margin={'0 7px'}
                    buttonType={'primary-cancel'}
                    onClick={onCancel}
                    disabled={loading}
                    buttonWidth={'156px'}
                  >
                    {CHART_EXPORT_PANEL_CANCEL_BUTTON}
                  </Button>
                  <Button
                    id={'PrimaryButton_Export'}
                    smallShadow
                    onClick={submit}
                    disabled={loading}
                    buttonWidth={'156px'}
                    margin={'0 7px'}
                  >
                    {CHART_EXPORT_PANEL_EXPORT_BUTTON}
                  </Button>
                </Form.Buttons>
              </ModalContent>
            </div>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default Logic(ExportChartPanel)

const Wrapper = styled.div``
