// @flow

export const matchRefTranslations = {
  '#ref!role-management': 'ROLE_MANAGEMENT',
  '#ref!role-owner': 'ROLE_OWNER',
  '#ref!role-technician': 'ROLE_TECHNICIAN',
  '#ref!role-view': 'ROLE_VIEW',
  '#ref!role-write': 'ROLE_WRITE',
  '#ref!update-err-update-commcard-failure':
    'UPDATE_ERR_UPDATE_COMMCARD_FAILURE',
  '#ref!update-err-update-download-failed': 'UPDATE_ERR_UPDATE_DOWNLOAD_FAILED',
  '#ref!update-err-update-insufficient-disk-space':
    'UPDATE_ERR_UPDATE_INSUFFICIENT_DISK_SPACE',
  '#ref!update-err-update-invalid-hash': 'UPDATE_ERR_UPDATE_INVALID_HASH',
  '#ref!update-err-update-low-battery': 'UPDATE_ERR_UPDATE_LOW_BATTERY'
}

export const replaceRefByTranslation = (stringToReplace, translations) => {
  const keysContent = Object.keys(matchRefTranslations)
  keysContent.forEach(keyContent => {
    if (stringToReplace.includes(keyContent)) {
      stringToReplace = stringToReplace.replace(
        keyContent,
        translations[matchRefTranslations[keyContent]]
      )
    }
  })

  return stringToReplace
}
