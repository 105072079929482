// @flow
import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'

import { generateUrl } from 'utils'
import { API_BASE_URL } from 'config'
import AppLogic from 'containers/App/logic'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeaturesLogic from 'containers/MaintenanceSupportedFeatures/logic'

const REBOOT_ENDPOINT = `${API_BASE_URL}/devices/{id}/maintenance/reboot`

export default kea({
  path: () => ['scenes', 'containers', 'MaintenanceMenu'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDeviceId',
        'currentDevice'
      ],
      MaintenanceSupportedFeaturesLogic, [
        'featureSupport'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    setSuccessfullyRebooting: status => status,
    rebootUnit: () => true,
    reset: () => true,
    setRebootModal: status => status
  }),

  reducers: ({ actions }) => ({
    successfullyRebooting: [false, PropTypes.bool, {
      [actions.setSuccessfullyRebooting]: (state, payload) => payload,
      [actions.reset]: () => false
    }],

    rebootModal: [false, PropTypes.bool, {
      [actions.setRebootModal]: (state, payload) => payload,
      [actions.setSuccessfullyRebooting]: () => false,
      [actions.reset]: () => false
    }]
  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.rebootUnit]: workers.rebootUnit
  }),

  workers: {
    * rebootUnit () {
      const { setSuccessfullyRebooting } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      try {
        const url = generateUrl(REBOOT_ENDPOINT, { id: currentDeviceId })
        yield call(axios.post, url)
        yield put(setSuccessfullyRebooting(true))
      } catch (catchError) {
        console.log(catchError)
      }
    }
  }
})
