// @flow
import { kea } from 'kea'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'containers', 'ExternalAppAccepted'],

  connect: {
    props: [
      AppLogic, [
        'translations'
      ]
    ]
  }
})
