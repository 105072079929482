// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import { log } from 'utils'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'DeviceSupport'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDeviceId'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    reset: () => true
  }),

  start: function * () {
    const { navigate } = this.actions

    log('[XS-DeviceSupportList] Start Scene', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')

    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-DeviceSupportList] Stop Scene')

    yield put(reset())
  }
})
