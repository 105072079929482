// @flow
import { kea } from 'kea'
import axios from 'axios'
import omit from 'lodash/omit'
import FormData from 'form-data'
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import { put, call, delay } from 'redux-saga/effects'

import * as Check from 'validations'
import { API_BASE_URL } from 'config'
import { getApiErrorKey } from 'apiErrors'
import AppLogic from 'containers/App/logic'
import { requiredOnAddMode } from './utils'
import { log, generateUrl, getUserRole } from 'utils'
import { checkRolePermission } from 'containers/UserPermission/utils'

// eslint-disable-next-line max-len
const REJECT_REQUEST_ENDPOINT = `${API_BASE_URL}/devices/{deviceid}/roles/requests/{requestid}/reject`
// eslint-disable-next-line max-len
const ACCEPT_REQUEST_ENDPOINT = `${API_BASE_URL}/devices/{deviceid}/roles/requests/{requestid}/accept`
// eslint-disable-next-line max-len
const REQUEST_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles/requests/{inviteId}`
const INVITE_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles/invites/{inviteId}`
const CREATE_INVITE_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles/invites`
const ROLES_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles/{inviteId}`

export const DEFAULT_VALUES = {
  formType: {
    value: 'add'
  },
  email: {
    value: '',
    valid: false
  },
  role: {
    value: 'view',
    valid: true
  },
  grantedAccessTime: {
    value: '0',
    valid: true
  }
}

const VALIDATIONS = {
  email: [requiredOnAddMode, Check.Email],
  role: [Check.isRequired]
}

export default kea({
  path: () => ['scenes', 'DeviceUserInfo'],

  connect: {
    props: [
      AppLogic,
      [
        'isMobile',
        'translations',
        'currentRoute',
        'currentDevice',
        'currentDeviceId',
        'userRole',
        'user',
        'showBackBar'
      ]
    ],
    actions: [
      AppLogic,
      ['navigate', 'setCurrentDeviceInfo', 'setDevices', 'fetchDevices']
    ]
  },

  actions: () => ({
    setInviteId: id => ({ id }),
    fetchInviteInfo: () => true,
    setInviteInfo: info => ({ info }),
    deleteUser: () => true,
    resendInvitation: () => true,
    acceptRequest: () => true,
    revokeRequest: () => true,

    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => true
  }),

  reducers: ({ actions }) => ({
    inviteId: [
      null,
      PropTypes.string,
      {
        [actions.setInviteId]: (state, payload) => payload.id,
        [actions.reset]: () => null
      }
    ],

    inviteInfo: [
      {},
      PropTypes.object,
      {
        [actions.setInviteInfo]: (state, payload) => payload.info,
        [actions.reset]: () => ({})
      }
    ],

    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.navigate]: () => false,
        [actions.change]: () => true,
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.setInviteId]: () => false
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setInviteId]: () => true,
        [actions.setInviteInfo]: () => false,
        [actions.revokeRequest]: () => true,
        [actions.acceptRequest]: () => true,
        [actions.submit]: () => true,
        [actions.navigate]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => true
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ],

    deletingUser: [
      false,
      PropTypes.bool,
      {
        [actions.deleteUser]: () => true,
        [actions.navigate]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    edit: [
      () => [selectors.inviteId],
      (inviteId: string) => !!inviteId,
      PropTypes.bool
    ],

    userCanEdit: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('EDIT_USER_INVITE', userRole),
      PropTypes.bool
    ],

    showButtons: [
      () => [
        selectors.edit,
        selectors.dirty,
        selectors.userCanEdit,
        selectors.inviteInfo
      ],
      (edit, dirty, userCanEdit, inviteInfo) => userCanEdit && (
        (dirty && edit) ||
        !edit ||
        (inviteInfo.status === 'PENDING' && inviteInfo.type === 'REQUEST')
      ),
      PropTypes.bool
    ]
  }),

  start: function * () {
    const { setInviteId, setInviteInfo, change, navigate } = this.actions

    log('[XS-DeviceUserInfo] Start scene', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    const currentRoute = yield this.get('currentRoute')
    const { userId } = currentRoute.params

    if (userId && userId !== 'new') {
      yield put(change({ formType: 'edit' }))
      yield put(setInviteId(userId))
    } else {
      yield put(setInviteInfo({}))
    }
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-DeviceUserInfo] Stop scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.setInviteId]: workers.fetchInviteInfo,
    [actions.submit]: workers.submit,
    [actions.deleteUser]: workers.deleteUser,
    [actions.resendInvitation]: workers.resendInvitation,
    [actions.acceptRequest]: workers.acceptRequest,
    [actions.revokeRequest]: workers.revokeRequest
  }),

  workers: {
    * submit () {
      const { setError, setForm, navigate } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const validation = Check.checkValidation(form, VALIDATIONS)
      const translations = yield this.get('translations')

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      const edit: boolean = yield this.get('edit')
      const inviteInfo: any = yield this.get('inviteInfo')

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)
      params = omit(params, ['formType'])

      params.owner = edit ? inviteInfo.owner : false
      params.roleManagement = false
      params.write = false
      params.technician = false

      if (params.role === 'roleManagement') {
        params.roleManagement = true
        params.write = true
      } else if (params.role === 'write') {
        params.write = true
      } else if (params.role === 'technician') {
        params.technician = true
      }

      try {
        const currentDeviceId = yield this.get('currentDeviceId')

        if (edit) {
          params.email = inviteInfo.email
          const inviteId = yield this.get('inviteId')
          const currentRoute = yield this.get('currentRoute')
          const { type } = currentRoute.params

          const urlParams = {
            id: currentDeviceId,
            inviteId: inviteId
          }

          let url = ''
          /**
           * there are two different endpoints for roles:
           * one for invites and one for actual roles
           * the selectedinvitetype is set on the page DeviceUsers
           * when a user from the list is selected
           */
          if (type === 'INVITE') {
            url = INVITE_ENDPOINT
          } else if (type === 'REQUEST') {
            url = REQUEST_ENDPOINT
          } else {
            url = ROLES_ENDPOINT
          }

          const urlGenerated = generateUrl(url, urlParams)
          yield call(axios.put, urlGenerated, params)
        } else {
          const url = generateUrl(CREATE_INVITE_ENDPOINT, {
            id: currentDeviceId
          })
          yield call(axios.post, url, params)
        }

        yield put(navigate(`/${currentDeviceId}/settings/users/`))
      } catch (catchError) {
        const { data } = catchError.response
        let errorKey = 'UNEXPECTED_ERROR_SAVE_FORM'

        if (data && data.message) {
          errorKey = getApiErrorKey(data.message)
        }

        yield put(setError(translations[errorKey]))
      }
    },

    * fetchInviteInfo () {
      const { setInviteInfo, setForm, navigate } = this.actions
      const currentDeviceId: string = yield this.get('currentDeviceId')

      try {
        const inviteId = yield this.get('inviteId')
        const currentRoute = yield this.get('currentRoute')
        const { type } = currentRoute.params

        const params = {
          id: currentDeviceId,
          inviteId: inviteId
        }

        let url = ''
        /**
         * there are two different endpoints for roles:
         * one for invites and one for actual roles
         * the selectedinvitetype is set on the page DeviceUsers
         * when a user from the list is selected
         */
        if (type === 'INVITE') {
          url = INVITE_ENDPOINT
        } else if (type === 'REQUEST') {
          url = REQUEST_ENDPOINT
        } else {
          url = ROLES_ENDPOINT
        }

        const generatedURL = generateUrl(url, params)
        const response = yield call(axios.get, generatedURL)
        const requestResult = response.data
        const { successful } = requestResult
        const result = requestResult.result

        if (successful) {
          const formData = {}
          let role = getUserRole(result)

          if (type === 'REQUEST') {
            role = 'technician'
            result.technician = true
          }
          result.type = type

          formData.role = { value: role }
          formData.grantedAccessTime = { value: '0' }

          yield put(setForm(formData))
          yield put(setInviteInfo(result))
        }
      } catch (catchError) {
        const { response } = catchError

        if (response && response.status === 404) {
          yield put(navigate(`/${currentDeviceId}/settings/users/`))
        }
      }
    },

    * deleteUser () {
      const {
        navigate,
        setCurrentDeviceInfo,
        setDevices,
        fetchDevices
      } = this.actions

      try {
        const currentDeviceId = yield this.get('currentDeviceId')
        const inviteId = yield this.get('inviteId')
        const inviteInfo = yield this.get('inviteInfo')
        const user = yield this.get('user')
        const currentRoute = yield this.get('currentRoute')
        const { type } = currentRoute.params

        const params = {
          id: currentDeviceId,
          inviteId: inviteId
        }

        let url = ''

        if (type === 'INVITE') {
          url = INVITE_ENDPOINT
        } else if (type === 'REQUEST') {
          url = REQUEST_ENDPOINT
        } else {
          url = ROLES_ENDPOINT
        }

        const urlgenerated = generateUrl(url, params)
        yield call(axios.delete, urlgenerated)

        if (user.id === inviteInfo && inviteInfo.user.id) {
          // reset all devices data on App Logic
          yield put(setCurrentDeviceInfo({}))

          yield put(setDevices([]))
          yield put(fetchDevices())

          // wait for the refresh of the devices on App Component
          for (let index = 0; index < 3; index++) {
            const waitForDevices = yield AppLogic.get('waitForDevices')
            if (!waitForDevices) {
              break
            }
            yield delay(1000)
          }

          yield put(navigate(`/${currentDeviceId}/dashboard/`))
        } else {
          yield put(navigate(`/${currentDeviceId}/settings/users/`))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * resendInvitation () {
      const { setError, navigate } = this.actions

      try {
        const currentDeviceId = yield this.get('currentDeviceId')
        const inviteInfo: any = yield this.get('inviteInfo')

        const inviteId = yield this.get('inviteId')
        const urlParams = {
          id: currentDeviceId,
          inviteId: inviteId
        }
        const url = generateUrl(INVITE_ENDPOINT, urlParams)
        yield call(axios.put, url, inviteInfo)

        yield put(navigate(`/${currentDeviceId}/settings/users/`))
      } catch (catchError) {
        const translations = yield this.get('translations')
        yield put(setError(translations['UNEXPECTED_ERROR_SAVE_FORM']))
      }
    },

    /**
     * Worker called when a technician request to access the unit is rejected
     */
    * revokeRequest () {
      const { navigate } = this.actions
      const inviteInfo = yield this.get('inviteInfo')

      const userid = inviteInfo.user.id
      const requestid = inviteInfo.id
      const currentDeviceId = yield this.get('currentDeviceId')

      const data = new FormData()
      data.append('userId', userid)

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }

      try {
        const url = generateUrl(REJECT_REQUEST_ENDPOINT, {
          deviceid: currentDeviceId,
          requestid: requestid
        })
        const response = yield call(axios.post, url, data, config)
        const { successful } = response.data

        if (successful) {
          yield put(navigate(`/${currentDeviceId}/settings/users/`))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    /**
     * Worker called when a technician request to access the unit is accepted
     */
    * acceptRequest () {
      const { navigate } = this.actions
      const inviteInfo = yield this.get('inviteInfo')

      const requestid = inviteInfo.id
      const currentDeviceId = yield this.get('currentDeviceId')
      const form = yield this.get('form')
      const { grantedAccessTime } = form

      try {
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }

        const url = generateUrl(ACCEPT_REQUEST_ENDPOINT, {
          deviceid: currentDeviceId,
          requestid: requestid
        })

        const data = new FormData()
        data.append('validityPeriod', grantedAccessTime.value)

        const response = yield call(
          axios.post,
          url,
          data,
          config
        )

        const { successful } = response.data

        if (successful) {
          yield put(navigate(`/${currentDeviceId}/settings/users/`))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    }
  }
})
