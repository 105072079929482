// @flow
import { type DeviceStatus, type Device } from 'types/api'

import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import {
  GridCollapse,
  DescriptiveSegment,
  ConsumptionValue
} from 'xstorage-components'

type Props = {
  deviceStatus: DeviceStatus,
  translations: any,
  currentDevice: Device,
  currentDeviceId: string
}

type State = {
  gridSelected: string
}

class DashboardGridConsumptions extends Component<Props, State> {
  actions: any

  state = {
    gridSelected: 'consumptions'
  }

  render () {
    const {
      deviceStatus,
      translations,
      currentDevice,
      currentDeviceId
    } = this.props

    const { navigate } = this.actions

    if (!deviceStatus) {
      return ''
    }

    const {
      DASH_GRID_CONSUMPTION,
      DASH_GRID_CONSUMPTION_TODAY,
      DASH_GRID_CONSUMPTION_LAST30D,
      DASH_PV_PROD,
      DASH_PV_PROD_TODAY,
      DASH_PV_PROD_LAST30D,
      DASH_PV_SELF,
      DASH_PV_SELF_TODAY,
      DASH_PV_SELF_LAST30D
    } = translations

    const today = deviceStatus.today || {}
    const last30daysEnergyFlow = deviceStatus.last30daysEnergyFlow || {}

    const { gridSelected } = this.state

    return (
      <Wrapper>
        <GridCollapse>
          <GridCollapse.Item
            open={gridSelected === 'consumptions'}
            onCollapse={() => this.setState({ gridSelected: 'consumptions' })}
            onClose={() => this.setState({ gridSelected: '' })}
            onLinkClick={() =>
              navigate(`/${currentDeviceId}/chart/grid-consumption/`)
            }
            title={DASH_GRID_CONSUMPTION}
          >
            <Grid container>
              <Grid item xs={6}>
                <DescriptiveSegment label={DASH_GRID_CONSUMPTION_TODAY}>
                  <ConsumptionValue
                    showPercentage={false}
                    value={today.gridConsumption}
                    color={'#007BC1'}
                    showArrow={
                      today.gridConsumption !==
                      last30daysEnergyFlow.gridConsumption
                    }
                    arrow={
                      today.gridConsumption >
                      last30daysEnergyFlow.gridConsumption
                        ? 'up'
                        : 'down'
                    }
                    arrowColor={
                      today.gridConsumption >
                      last30daysEnergyFlow.gridConsumption
                        ? 'red'
                        : 'green'
                    }
                  />
                </DescriptiveSegment>
              </Grid>
              <Grid item xs={6}>
                <DescriptiveSegment label={DASH_GRID_CONSUMPTION_LAST30D}>
                  <ConsumptionValue
                    value={last30daysEnergyFlow.gridConsumption}
                    showArrow={false}
                  />
                </DescriptiveSegment>
              </Grid>
            </Grid>
          </GridCollapse.Item>
          {currentDevice.hasPv && (
            <GridCollapse.Item
              open={gridSelected === 'billing'}
              onCollapse={() => this.setState({ gridSelected: 'billing' })}
              onClose={() => this.setState({ gridSelected: '' })}
              onLinkClick={() =>
                navigate(`/${currentDeviceId}/chart/pv-production/`)
              }
              title={DASH_PV_PROD}
            >
              <Grid container>
                <Grid item xs={6}>
                  <DescriptiveSegment label={DASH_PV_PROD_TODAY}>
                    <ConsumptionValue
                      color={'#007BC1'}
                      value={today.photovoltaicProduction}
                      showArrow={
                        today.photovoltaicProduction !==
                        last30daysEnergyFlow.photovoltaicProduction
                      }
                      arrow={
                        today.photovoltaicProduction >
                        last30daysEnergyFlow.photovoltaicProduction
                          ? 'up'
                          : 'down'
                      }
                      arrowColor={
                        today.photovoltaicProduction >
                        last30daysEnergyFlow.photovoltaicProduction
                          ? 'green'
                          : 'red'
                      }
                    />
                  </DescriptiveSegment>
                </Grid>
                <Grid item xs={6}>
                  <DescriptiveSegment label={DASH_PV_PROD_LAST30D}>
                    <ConsumptionValue
                      value={last30daysEnergyFlow.photovoltaicProduction}
                    />
                  </DescriptiveSegment>
                </Grid>
              </Grid>
            </GridCollapse.Item>
          )}
          {currentDevice.hasPv && (
            <GridCollapse.Item
              open={gridSelected === 'consumption'}
              onCollapse={() => this.setState({ gridSelected: 'consumption' })}
              onClose={() => this.setState({ gridSelected: '' })}
              onLinkClick={() =>
                navigate(`/${currentDeviceId}/chart/pv-consumption/`)
              }
              title={DASH_PV_SELF}
            >
              <Grid container>
                <Grid item xs={6}>
                  <DescriptiveSegment label={DASH_PV_SELF_TODAY}>
                    <ConsumptionValue
                      color={'#007BC1'}
                      showPercentage
                      value={today.selfConsumption}
                      showArrow={
                        today.selfConsumption !==
                        last30daysEnergyFlow.selfConsumption
                      }
                      arrow={
                        today.selfConsumption >
                        last30daysEnergyFlow.selfConsumption
                          ? 'up'
                          : 'down'
                      }
                      arrowColor={
                        today.selfConsumption >
                        last30daysEnergyFlow.selfConsumption
                          ? 'green'
                          : 'red'
                      }
                    />
                  </DescriptiveSegment>
                </Grid>
                <Grid item xs={6}>
                  <DescriptiveSegment label={DASH_PV_SELF_LAST30D}>
                    <ConsumptionValue
                      showPercentage
                      value={last30daysEnergyFlow.selfConsumption}
                    />
                  </DescriptiveSegment>
                </Grid>
              </Grid>
            </GridCollapse.Item>
          )}
        </GridCollapse>
      </Wrapper>
    )
  }
}

export default Logic(DashboardGridConsumptions)

const Wrapper = styled.div``
