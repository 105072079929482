/* eslint-disable max-len */
/**
 * DeviceUserInfo Scene
 * Invitation types:
 * - INVITE: when you add a user through the Users&Permissions page
 *    Status:
 *      - PENDING, EXPIRED, ACCEPTED, REJECTED, DELETED
 * - REQUEST: when a technician asks to access an unit in the technician portal
 *    Status:
 *      - PENDING, EXPIRED, ACCEPTED, REJECTED, DELETED
 * - ROLE: when a user already as access to the unit
 *    Status:
 *      - ACTIVE, INACTIVE, DELETED
 *
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import { type DeviceRole } from 'types/api'
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { convertEpochToDate, convertMillisToSec } from 'utils'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
import PageAdaptable from 'containers/PageAdaptable'
import FormFieldError from 'containers/FormFieldError'
import FormGeneralError from 'containers/FormGeneralError'
import UserPermission from 'containers/UserPermission'
import { getFormHeight } from './utils'

import {
  PageTitle,
  Button,
  Typography,
  Form,
  List,
  Modal,
  ModalHeader,
  ModalContent,
  Divider,
  InputText,
  MobileActionButton,
  ModalButtons,
  PageMainSection,
  Select,
  BackButton
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  inviteInfo: DeviceRole,
  loading: boolean,
  edit: boolean,
  dirty: boolean,
  error: string,
  form: any,
  userCanEdit: boolean
}

type State = {
  deleteModal: boolean
}

class DeviceUserInfo extends Component<Props, State> {
  actions: any

  state = {
    deleteModal: false
  }

  render () {
    const {
      isMobile,
      translations,
      inviteInfo,
      loading,
      edit,
      form,
      error,
      dirty,
      userCanEdit,
      deletingUser,
      currentDeviceId,
      showBackBar,
      showButtons
    } = this.props

    const {
      navigate,
      change,
      submit,
      deleteUser,
      resendInvitation,
      revokeRequest,
      acceptRequest
    } = this.actions

    const { deleteModal } = this.state

    const {
      UNITSETT_TITLE,
      UNITSETT_USERSPERM_USERINFO_TITLE,
      UNITSETT_USERSPERM_USERINFO_SAVE,
      UNITSETT_USERSPERM_USERINFO_CANCEL,
      UNITSETT_USERSPERM_INVITE_TITLE,
      UNITSETT_USERSPERM_INVITE_SEND,
      UNITSETT_USERSPERM_USERINFO_FIRST_NAME,
      UNITSETT_USERSPERM_USERINFO_LAST_NAME,
      UNITSETT_USERSPERM_USERINFO_EMAIL,
      UNITSETT_USERSPERM_USERINFO_DATE,
      UNITSETT_USERSPERM_USERINFO_STATUS,
      UNITSETT_USERSPERM_USERINFO_LEVEL,
      UNITSETT_USERSPERM_USERINFO_RESEND,
      UNITSETT_USERSPERM_USERINFO_DELETE,
      UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_TITLE,
      UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_SUBTITLE,
      UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_DESC,
      UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_CANCEL,
      UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_DELETE,
      UNITSETT_USERSPERM_USERINFO_ROLE_ACESS,
      UNITSETT_USERSPERM_USERINFO_ROLE_VIEW,
      UNITSETT_USERSPERM_USERINFO_ROLE_TECH,
      UNITSETT_USERSPERM_USERINFO_ROLE_CONF,
      UNITSETT_USERSPERM_USERINFO_ACCEPT_REQUEST,
      UNITSETT_USERSPERM_USERINFO_REVOKE_REQUEST,
      UNITSETT_USERSPERM_USERINFO_GRANTEDACCESSTIME,
      UNITSETT_USERSPERM_USERINFO_PERMANENTACCESS,
      UNITSETT_USERSPERM_USERINFO_30DAYSACCESS,
      UNITSETT_USERSPERM_USERINFO_7DAYSACCESS,
      UNITSETT_USERSPERM_USERINFO_1DAYACCESS,
      UNITSETT_USERSPERM_USERINFO_ACCESS_HELP
    } = translations

    const { user, status, createdAt, technician, type, validityPeriod, expiresAt } = inviteInfo

    const { email, role, grantedAccessTime } = form

    const formHeight = getFormHeight(type, status)

    const isTechnicianRequest = technician && status === 'PENDING' && type === 'REQUEST'

    let recurrencyOptions = []

    /** you cannot change the role to technician, you can only see that role if you are technician */
    if (technician) {
      recurrencyOptions = [
        { val: 'view', label: UNITSETT_USERSPERM_USERINFO_ROLE_VIEW },
        { val: 'write', label: UNITSETT_USERSPERM_USERINFO_ROLE_CONF },
        {
          val: 'roleManagement',
          label: UNITSETT_USERSPERM_USERINFO_ROLE_ACESS
        },
        { val: 'technician', label: UNITSETT_USERSPERM_USERINFO_ROLE_TECH }
      ]
    } else {
      recurrencyOptions = [
        { val: 'view', label: UNITSETT_USERSPERM_USERINFO_ROLE_VIEW },
        { val: 'write', label: UNITSETT_USERSPERM_USERINFO_ROLE_CONF },
        { val: 'roleManagement', label: UNITSETT_USERSPERM_USERINFO_ROLE_ACESS }
      ]
    }

    const grantedTimeOptions = [
      { val: '0', label: UNITSETT_USERSPERM_USERINFO_PERMANENTACCESS },
      { val: '30', label: UNITSETT_USERSPERM_USERINFO_30DAYSACCESS },
      { val: '7', label: UNITSETT_USERSPERM_USERINFO_7DAYSACCESS },
      { val: '1', label: UNITSETT_USERSPERM_USERINFO_1DAYACCESS }
    ]

    const formButtons = (
      <Form.Buttons
        align={isMobile ? 'center' : 'right'}
        mobile={isMobile}
        fixed={isMobile}
      >
        {isTechnicianRequest && userCanEdit && (
          <Fragment>
            <Button
              id={'PrimaryButton_Revoke'}
              buttonType={'primary-danger'}
              margin={'0 12px'}
              buttonWidth={'163px'}
              onClick={revokeRequest}
              disabled={!showButtons}
            >
              {UNITSETT_USERSPERM_USERINFO_REVOKE_REQUEST}
            </Button>
            <Button
              id={'PrimaryButton_Accept'}
              margin={'0 12px'}
              buttonWidth={'163px'}
              onClick={acceptRequest}
              disabled={!showButtons}
            >
              {UNITSETT_USERSPERM_USERINFO_ACCEPT_REQUEST}
            </Button>
          </Fragment>
        )}

        {!isTechnicianRequest && (
          <Fragment>
            <Button
              id={'PrimaryButton_Cancel'}
              buttonType={'primary-cancel'}
              margin={'0 12px'}
              buttonWidth={'163px'}
              onClick={() => navigate(`/${currentDeviceId}/settings/users/`)}
              disabled={!showButtons}
            >
              {UNITSETT_USERSPERM_USERINFO_CANCEL}
            </Button>
            {userCanEdit && (
              <Button
                id={'PrimaryButton_Save'}
                margin={'0 12px'}
                buttonWidth={'163px'}
                onClick={submit}
                disabled={!showButtons}
              >
                {UNITSETT_USERSPERM_USERINFO_SAVE}
              </Button>
            )}
          </Fragment>
        )}
      </Form.Buttons>
    )

    return (
      <Wrapper>
        <PageAdaptable
          title={UNITSETT_TITLE}
          menu={<DeviceSettingsMenu page={'users-permissions'} />}
          onBack={() => navigate(`/${currentDeviceId}/settings/users/`)}
          isForm
          isDirty={dirty}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle mobile={isMobile}>
              {!isMobile && (
                <BackButton
                  id={'BackButton'}
                  buttonMargin={'0 8px 0 0'}
                  onClick={() =>
                    navigate(`/${currentDeviceId}/settings/users/`)
                  }
                >
                  <Icon>{'arrow_back'}</Icon>
                </BackButton>
              )}
              {edit
                ? UNITSETT_USERSPERM_USERINFO_TITLE
                : UNITSETT_USERSPERM_INVITE_TITLE}
            </PageTitle>
            {isMobile && showButtons && !isTechnicianRequest && (
              <MobileActionButton
                onClick={submit}
                mobile
                icon={<Icon>{edit ? 'check' : 'send'}</Icon>}
                id={'Save'}
              >
                {edit
                  ? UNITSETT_USERSPERM_USERINFO_SAVE
                  : UNITSETT_USERSPERM_INVITE_SEND}
              </MobileActionButton>
            )}
            <Form
              mobile={isMobile}
              showButtons={(isMobile && isTechnicianRequest) || (!isMobile && showButtons)}
              buttons={formButtons}
              containerHeight={
                isMobile && !isTechnicianRequest
                  ? 'calc(100vh - 180px)'
                  : isMobile && isTechnicianRequest
                    ? 'calc(100vh - 210px)'
                    : !isMobile && showBackBar
                      ? 'calc(100vh - 400px)'
                      : 'calc(100vh - 350px)'
              }
            >
              <FormGeneralError dirty={dirty} error={error} full />
              <ScrollableForm formHeight={formHeight}>
                {edit && (
                  <Fragment>
                    <Form.Row displayType={'flex'}>
                      <HalfInput
                        id={'FirstName'}
                        label={UNITSETT_USERSPERM_USERINFO_FIRST_NAME}
                        value={user ? user.firstName : '-'}
                        inputProps={{
                          maxLength: 90
                        }}
                        disabled
                        inputmargin={'8px 12px 0 0'}
                      />
                      <HalfInput
                        id={'LastName'}
                        label={UNITSETT_USERSPERM_USERINFO_LAST_NAME}
                        value={user ? user.lastName : '-'}
                        inputProps={{
                          maxLength: 90
                        }}
                        disabled
                        inputmargin={'8px 0 0 12px'}
                      />
                    </Form.Row>
                    <Form.Row>
                      <InputText
                        id={'Email'}
                        label={UNITSETT_USERSPERM_USERINFO_EMAIL}
                        value={
                          user
                            ? user.email
                            : inviteInfo.email
                              ? inviteInfo.email
                              : ''
                        }
                        inputProps={{
                          maxLength: 90
                        }}
                        disabled
                      />
                    </Form.Row>
                    <Form.Row>
                      <HalfInput
                        id={'Date'}
                        label={UNITSETT_USERSPERM_USERINFO_DATE}
                        value={convertEpochToDate(
                          convertMillisToSec(parseInt(createdAt)),
                          'DD/MM/YYYY'
                        )}
                        disabled
                        inputmargin={'8px 12px 0 0'}
                      />
                      <HalfInput
                        id={'Status'}
                        label={UNITSETT_USERSPERM_USERINFO_STATUS}
                        value={
                          translations[`UNITSETT_USERSPERM_STATUS_${status}_PLACEHOLDER`] || ''
                        }
                        disabled
                        inputmargin={'8px 0 0 12px'}
                      />
                    </Form.Row>
                  </Fragment>
                )}
                {!edit && (
                  <Form.Row>
                    <InputText
                      id={'Email'}
                      label={UNITSETT_USERSPERM_USERINFO_EMAIL}
                      value={email.value}
                      type={'email'}
                      error={dirty && !email.valid}
                      onChange={({ target }) =>
                        change({ email: target.value.toLowerCase() })
                      }
                      inputProps={{
                        maxLength: 90
                      }}
                    />
                    <FormFieldError
                      id={'Email'}
                      showError={dirty && !email.valid}
                      errorMessage={email.message}
                    />
                  </Form.Row>
                )}
                <Form.Row>
                  <Select
                    id={'PermissionLevel'}
                    label={UNITSETT_USERSPERM_USERINFO_LEVEL}
                    options={recurrencyOptions}
                    selected={role.value}
                    error={dirty && !role.valid}
                    disabled={!userCanEdit || technician}
                    onChange={selected => change({ role: selected })}
                    containerMargin={'0px'}
                  />
                </Form.Row>
                {(technician && type === 'ROLE') && (
                  <Form.Row>
                    <InputText
                      id={'AccessExpirationDate'}
                      label={UNITSETT_USERSPERM_USERINFO_GRANTEDACCESSTIME}
                      value={
                        validityPeriod === 0
                          ? translations['UNITSETT_USERSPERM_USERINFO_PERMANENTACCESS']
                          : convertEpochToDate(convertMillisToSec(expiresAt), 'DD/MM/YYYY HH:mm')
                      }
                      disabled
                    />
                  </Form.Row>
                )}
                {(technician && type === 'REQUEST') && (
                  <Form.Row>
                    <Select
                      id={'GrantedAccessTime'}
                      label={UNITSETT_USERSPERM_USERINFO_GRANTEDACCESSTIME}
                      options={grantedTimeOptions}
                      selected={grantedAccessTime.value}
                      error={dirty && !grantedAccessTime.valid}
                      disabled={!userCanEdit}
                      onChange={selected => change({ grantedAccessTime: selected })}
                      containerMargin={'0px'}
                      full
                      helperText={UNITSETT_USERSPERM_USERINFO_ACCESS_HELP}
                    />
                  </Form.Row>
                )}
              </ScrollableForm>
              {edit && (
                <Fragment>
                  <ActionsRow full>
                    <List>
                      {status === 'PENDING' && type !== 'REQUEST' && (
                        <UserPermission permission={'RESEND_USER_INVITE'}>
                          <Divider variant={'inset'} component='li' />
                          <ListItem button onClick={resendInvitation}>
                            <List.ItemText
                              primary={UNITSETT_USERSPERM_USERINFO_RESEND}
                            />
                            <Icon
                              style={{ color: '#007BC1' }}
                            >{'keyboard_arrow_right'}</Icon>
                          </ListItem>
                        </UserPermission>
                      )}
                      {type !== 'REQUEST' && (
                        <UserPermission permission={'DELETE_USER_INVITE'}>
                          <Divider variant={'inset'} component='li' />
                          <ListItem
                            button
                            onClick={() => this.setState({ deleteModal: true })}
                          >
                            <DeleteItemText
                              primary={UNITSETT_USERSPERM_USERINFO_DELETE}
                            />
                            <Icon
                              style={{ color: '#CA3C3D' }}
                            >{'keyboard_arrow_right'}</Icon>
                          </ListItem>
                          <Divider variant={'inset'} component='li' />
                        </UserPermission>
                      )}
                    </List>
                  </ActionsRow>
                  <Modal
                    open={deleteModal}
                    size={'small'}
                    onClose={() => this.setState({ deleteModal: false })}
                  >
                    <div>
                      <ModalHeader
                        red
                        title={UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_TITLE}
                      />
                      <ModalContent padding size={'small'} mobile={isMobile}>
                        <Typography><b>{UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_SUBTITLE}</b></Typography>
                        <Typography>
                          {UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_DESC}
                        </Typography>
                      </ModalContent>
                      <ModalButtons>
                        <Button
                          id={'PrimaryButton_Cancel'}
                          buttonType={'primary-cancel'}
                          margin={'0 12px'}
                          buttonWidth={'156px'}
                          onClick={() =>
                            this.setState({ deleteModal: false })
                          }
                          disabled={deletingUser}
                        >
                          {UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_CANCEL}
                        </Button>
                        <Button
                          id={'PrimaryButton_DeleteUser'}
                          buttonType={'primary-danger'}
                          margin={'0 12px'}
                          buttonWidth={'156px'}
                          onClick={deleteUser}
                          disabled={deletingUser}
                        >
                          {UNITSETT_USERSPERM_USERINFO_DELETE_MODAL_DELETE}
                        </Button>
                      </ModalButtons>
                    </div>
                  </Modal>
                </Fragment>
              )}
            </Form>
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(DeviceUserInfo)

const Wrapper = styled.div``

const ListItem = styled(List.Item)`
  &&&& {
    padding: 16px 8px;
    justify-content: space-between;
  }
`

const ScrollableForm = styled.div`
  height: ${props => props.formHeight};
  overflow-y: auto;
`

const DeleteItemText = styled(props => <List.ItemText {...props} />)`
  &&&& {
    span {
      color: #ca3c3d!important;
    }
  }
`

const ActionsRow = styled(props => <Form.Row {...props} />)`
  &&&& {
    z-index: 1;
    position: absolute;
    bottom: 0;

    @media screen and (max-width: 1024px) {
      width: calc(100% - 100px);
    }
    @media screen and (max-width: 550px) {
      margin: 16px 12px 0;
      width: calc(100% - 70px);
    }
  }
`

const HalfInput = styled(InputText)`
  &&&& {
    width: calc(50% - 12px)!important;

    > div {
      height: 100%;
    }
  }
`
