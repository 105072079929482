// @flow
import { type User } from 'msal'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Logic from './logic'

import {
  ClearDayIcon,
  ClearNightIcon,
  CloudyIcon,
  FogIcon,
  HailIcon,
  PartlyCloudyDayIcon,
  PartlyCloudyNightIcon,
  RainIcon,
  SleetIcon,
  SnowIcon,
  ThunderstormIcon,
  TornadoIcon,
  WindIcon
} from 'xstorage-components'

// icons download on https://erikflowers.github.io/weather-icons/
const MAP_ICONS = {
  'clear-day': <ClearDayIcon />,
  'clear-night': <ClearNightIcon />,
  'cloudy': <CloudyIcon />,
  'fog': <FogIcon />,
  'hail': <HailIcon />,
  'partly-cloudy-day': <PartlyCloudyDayIcon />,
  'partly-cloudy-night': <PartlyCloudyNightIcon />,
  'rain': <RainIcon />,
  'sleet': <SleetIcon />,
  'snow': <SnowIcon />,
  'thunderstorm': <ThunderstormIcon />,
  'tornado': <TornadoIcon />,
  'wind': <WindIcon />
}

type Props = {
  color?: string,
  relative?: boolean,
  weatherInfo?: any,
  user: User
}

class WeatherBar extends PureComponent<Props, {}> {
  render () {
    const {
      weatherInfo,
      color,
      relative,
      user
    } = this.props

    const {
      temperature,
      summary,
      icon
    } = weatherInfo || {}

    const {
      weatherUnit
    } = user || {}

    if (!temperature) {
      return ''
    }

    return (
      <Wrapper color={color} relative={relative}>
        <WeatherContainer>
          <Icon color={color}>{MAP_ICONS[icon]}</Icon>
          <WeatherValue>
            {parseInt(temperature)} {weatherUnit === 'celsius' ? 'ºC' : 'ºF'}
          </WeatherValue>
          <WeatherLabel>{summary}</WeatherLabel>
        </WeatherContainer>
      </Wrapper>
    )
  }
}

export default Logic(WeatherBar)

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  padding: 6px 0 0 0;
  opacity: .5;
  height: 35px;
  color: ${({ color }) => color || '#FDFDFD'};
  top: 0;
  left: 0;

  ${({ relative }) => relative && `
    position: relative;
    width: auto;
  `}
`

const WeatherContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const Icon = styled.div`
  height: 24px;

  svg {
    width: 28px;
    height: 28px;

    path {
      fill: ${({ color }) => color || '#FDFDFD'};
    }
  }
`

const WeatherValue = styled.label`
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin: 0 5px;
`

const WeatherLabel = styled.label`
  font-size: 12px;
  font-family: Eaton;
  font-weight: bold;
`
