// @flow
import { kea } from 'kea'
import { put, call, delay } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import round from 'lodash/round'

import AppLogic from 'containers/App/logic'
import { API_BASE_URL, FETCH_DISKINFO_DELAY } from 'config'
import { generateUrl, log } from 'utils'
import { getApiErrorKey } from 'apiErrors'

// eslint-disable-next-line max-len
const DISKUSAGE_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/diagnostics`
// eslint-disable-next-line max-len
const FREEDISK_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/free-disk-space`

export default kea({
  path: () => ['scenes', 'MaintenanceMonitoring'],

  connect: {
    props: [
      AppLogic,
      [
        'translations',
        'isMobile',
        'currentDeviceId',
        'currentDevice',
        'showBackBar'
      ],

      ({ scenes }) => scenes.containers.MaintenanceSupportedFeatures,
      ['featureSupport']
    ],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    getDiskInfo: () => true,
    setDiskInfo: info => info,
    setCpuInfo: info => info,
    setMemoryInfo: info => info,
    setConfirmationModal: status => status,
    setSuccessMessage: success => success,
    setErrorMessage: error => error,
    setLoading: state => state,
    setLoadingData: state => state,
    setError: error => error,
    freeDiskSpace: () => true,
    reset: () => true,
    setFixNavbar: fix => fix,
    updateDiskInfo: () => true
  }),

  reducers: ({ actions }) => ({
    diskInfo: [
      [],
      PropTypes.array,
      {
        [actions.setDiskInfo]: (state, payload) => payload,
        [actions.reset]: () => []
      }
    ],

    cpuInfo: [
      {},
      PropTypes.object,
      {
        [actions.setCpuInfo]: (state, payload) => payload,
        [actions.reset]: () => ({ })
      }
    ],

    memoryInfo: [
      {},
      PropTypes.object,
      {
        [actions.setMemoryInfo]: (state, payload) => payload,
        [actions.reset]: () => ({ })
      }
    ],

    confirmationModal: [
      false,
      PropTypes.bool,
      {
        [actions.setConfirmationModal]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],

    errorMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setErrorMessage]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setLoading]: (state, payload) => payload,
        [actions.reset]: () => false,
        [actions.getDiskInfo]: () => true,
        [actions.setDiskInfo]: () => false,
        [actions.setError]: () => false
      }
    ],

    loadingData: [
      false,
      PropTypes.bool,
      {
        [actions.setLoadingData]: (state, payload) => payload,
        [actions.getDiskInfo]: () => true,
        [actions.updateDiskInfo]: () => true,
        [actions.reset]: () => false,
        [actions.setError]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ],

    fixNavbar: [
      false,
      PropTypes.bool,
      {
        [actions.setFixNavbar]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ]
  }),

  start: function * () {
    log('[XS-DiskInfo] Start Scene', 'yellow')

    const { getDiskInfo } = this.actions

    yield put(getDiskInfo())
  },

  stop: function * () {
    log('[XS-DiskInfo] Stop Scene', 'yellow')

    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getDiskInfo]: workers.getDiskInfo,
    [actions.freeDiskSpace]: workers.freeDiskSpace,
    [actions.updateDiskInfo]: workers.getDiskInfo
  }),

  workers: {
    * getDiskInfo () {
      const {
        setDiskInfo,
        setLoadingData,
        setLoading,
        updateDiskInfo,
        setError,
        setCpuInfo,
        setMemoryInfo
      } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      try {
        const url = generateUrl(DISKUSAGE_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const response = yield call(axios.get, url)
        const { result } = response.data
        const { diskUsage, ramUsage, cpuUsage } = result

        if (diskUsage) {
          let disk = cloneDeep(diskUsage.partition)
          disk.map(element => {
            element.usedPercentage =
              round((element.used / element.size) * 100, 2) + ' %'
            return element
          })

          yield put(setDiskInfo(disk || {}))
        }

        /** XPSD-1490: Add RAM and CPU usage to monitoring */
        if (ramUsage) {
          let ram = cloneDeep(ramUsage)
          ram.usedPercentage = round((ram.used / ram.total) * 100, 2) + ' %'
          yield put(setMemoryInfo(ram || {}))
        }

        /** XPSD-1490: Add RAM and CPU usage to monitoring */
        if (cpuUsage) {
          const cpu = {
            used: round(cpuUsage.used, 2)
          }
          yield put(setCpuInfo(cpu || {}))
        }

        yield put(setLoadingData(false))
        yield put(setLoading(false))

        yield delay(FETCH_DISKINFO_DELAY)
        yield put(updateDiskInfo())
      } catch (errorMessage) {
        yield put(setLoadingData(false))

        if (errorMessage.response) {
          const { data } = errorMessage.response
          const errorKey = getApiErrorKey(data.message)
          const translations = yield this.get('translations')
          yield put(setError(translations[errorKey] || ''))
        } else {
          console.log(errorMessage)
        }
      }
    },
    * freeDiskSpace () {
      const {
        setConfirmationModal,
        setLoading,
        setSuccessMessage,
        setErrorMessage,
        setError
      } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      yield put(setConfirmationModal(false))
      yield put(setLoading(true))

      try {
        const config = {
          headers: { 'content-type': 'application/json' }
        }
        const url = generateUrl(FREEDISK_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const response = yield call(axios.post, url, null, config)
        const { successful } = response.data

        if (successful) {
          yield put(setLoading(false))

          yield put(setSuccessMessage(true))
          yield delay(5000)
          yield put(setSuccessMessage(false))
        }
      } catch (errorMessage) {
        yield put(setLoading(false))

        if (errorMessage.response) {
          const { data } = errorMessage.response
          const errorKey = getApiErrorKey(data.message)
          const translations = yield this.get('translations')
          yield put(setError(translations[errorKey]))
        } else {
          yield put(setErrorMessage(true))
          yield delay(5000)
          yield put(setErrorMessage(false))
        }
      }
    }
  }
})
