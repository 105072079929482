/**
 * Tour Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
// @flow
import React, { PureComponent } from 'react'
import ReacTour from 'reactour'

import TourCard from 'containers/TourCard'

type Props = {
}

export default class Tour extends PureComponent<Props, void> {
  render () {
    return (
      <ReacTour
        {...this.props}
        position={'top'}
        accentColor={'#007BC1'}
        maskClassName={'tourMask'}
        CustomHelper={TourCard}
      />
    )
  }
}
