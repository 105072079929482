/* eslint-disable indent */
// @flow
import { kea } from 'kea'
import moment from 'moment-timezone'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import { log } from 'utils'
import { API_BASE_URL } from 'config'
import size from 'lodash/size'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'

const DEVICES_ENDPOINT = `${API_BASE_URL}/devices`
const PENDING_UNITS_ENDPOINT = `${API_BASE_URL}/devices/roles/requests`

export default kea({
  path: () => ['scenes', 'TechnicianUnits'],

  connect: {
    props: [AppLogic, ['isMobile', 'translations', 'user']],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    reset: () => true,
    setGrantedUnits: units => ({ units }),
    fetchGrantedUnits: () => true,
    setTotalGrantedUnits: total => ({ total }),
    error: () => true,
    setSwitcherSelected: switcher => ({ switcher }),
    setPanelToShow: panel => ({ panel }),
    setPendingUnits: units => ({ units }),
    fetchPendingUnits: () => true,
    setTotalPendingUnits: total => ({ total }),
    showUnitPanel: unit => ({ unit }),
    resetPendingUnits: () => true,
    setLoadingButton: status => ({ status }),
    setShowLoadMoreInfo: value => ({ value }),
    setLoading: status => ({ status })
  }),

  reducers: ({ actions }) => ({
    grantedUnits: [
      [],
      PropTypes.array,
      {
        [actions.setGrantedUnits]: (state, payload) => [
          ...state,
          ...payload.units
        ],
        [actions.reset]: () => [],
        [actions.setSwitcherSelected]: () => []
      }
    ],

    totalGrantedUnits: [
      0,
      PropTypes.number,
      {
        [actions.setTotalGrantedUnits]: (state, payload) => payload.total,
        [actions.error]: () => 0
      }
    ],

    pendingUnits: [
      [],
      PropTypes.array,
      {
        [actions.setPendingUnits]: (state, payload) => [
          ...state,
          ...payload.units
        ],
        [actions.resetPendingUnits]: (state, payload) => [],
        [actions.reset]: () => []
      }
    ],

    totalPendingUnits: [
      0,
      PropTypes.number,
      {
        [actions.setTotalPendingUnits]: (state, payload) => payload.total,
        [actions.error]: () => 0,
        [actions.resetPendingUnits]: () => 0
      }
    ],

    switcherSelected: [
      'pending',
      PropTypes.string,
      {
        [actions.setSwitcherSelected]: (state, payload) => payload.switcher
      }
    ],

    panelToShow: [
      '',
      PropTypes.string,
      {
        [actions.setPanelToShow]: (state, payload) => payload.panel,
        [actions.showUnitPanel]: (state, payload) => 'unit'
      }
    ],

    unitToShow: [
      {},
      PropTypes.object,
      {
        [actions.showUnitPanel]: (state, payload) => payload.unit
      }
    ],

    loadingButton: [
      false,
      PropTypes.bool,
      {
        [actions.setLoadingButton]: (state, payload) => payload.status,
        [actions.reset]: () => false
      }
    ],

    showLoadMoreInfo: [
      false,
      PropTypes.bool,
      {
        [actions.setShowLoadMoreInfo]: (state, payload) => payload.value,
        [actions.reset]: () => false
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setLoading]: (state, payload) => payload.status,
        [actions.reset]: () => false
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    grantedUnitsLength: [
      () => [selectors.grantedUnits],
      grantedUnits => size(grantedUnits),
      PropTypes.number
    ],
    pendingUnitsLength: [
      () => [selectors.pendingUnits],
      pendingUnits => size(pendingUnits),
      PropTypes.number
    ]
  }),

  start: function * () {
    const { setSwitcherSelected, fetchGrantedUnits } = this.actions

    log('[XS-TechnicianUnits] Start Scene', 'yellow')
    moment.tz.setDefault(null)
    yield put(setSwitcherSelected('pending'))
    yield put(fetchGrantedUnits())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-TechnicianUnits] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchGrantedUnits]: workers.fetchGrantedUnits,
    [actions.fetchPendingUnits]: workers.fetchPendingUnits,
    [actions.setSwitcherSelected]: workers.setSwitcherSelected
  }),

  workers: {
    * fetchGrantedUnits () {
      const {
        setGrantedUnits,
        error,
        setTotalGrantedUnits,
        setLoadingButton,
        setShowLoadMoreInfo,
        setLoading
      } = this.actions

      const grantedUnitsLength = yield this.get('grantedUnitsLength')
      const user = yield this.get('user')

      yield put(setLoadingButton(true))
      yield put(setLoading(true))

      let url = DEVICES_ENDPOINT + `?size=9&offset=${grantedUnitsLength}`

      try {
        if (user.isAdmin) {
          url = url + `&userId=${user.id}`
        }

        const response = yield call(axios.get, url)
        let { result, successful } = response.data

        if (successful) {
          let grantedUnits = cloneDeep(result.data)
          grantedUnits.map(unit => {
            const owner = find(unit.roles, role => role.owner === true)
            unit.owner = (owner && owner.user) || null
            return unit
          })

          yield put(setTotalGrantedUnits(result.total))
          yield put(setGrantedUnits(grantedUnits))
          yield put(setLoadingButton(false))
          yield put(setLoading(false))

          if (result.total > result.data.length) {
            yield put(setShowLoadMoreInfo(true))
          }
        }
      } catch (er) {
        console.log(er)
        yield put(setLoadingButton(false))
        yield put(setLoading(false))
        yield put(error())
      }
    },

    * fetchPendingUnits () {
      const {
        setPendingUnits,
        error,
        setTotalPendingUnits,
        resetPendingUnits,
        setLoadingButton,
        setShowLoadMoreInfo,
        setLoading
      } = this.actions

      yield put(setLoadingButton(true))
      yield put(setLoading(true))

      const pendingUnitsLength = yield this.get('pendingUnitsLength')
      const user = yield this.get('user')
      let url =
        PENDING_UNITS_ENDPOINT +
        `?status=PENDING&size=9&offset=${pendingUnitsLength}`

      try {
        if (user.isAdmin) {
          url = url + `&userId=${user.id}`
        }

        const response = yield call(axios.get, url)
        let { result, successful } = response.data

        if (successful) {
          if (result.total > 0) {
            yield put(setTotalPendingUnits(result.total))
            yield put(setPendingUnits(result.data))

            if (result.total > result.data.length) {
              yield put(setShowLoadMoreInfo(true))
            }
          } else {
            yield put(resetPendingUnits())
          }
        }

        yield put(setLoadingButton(false))
        yield put(setLoading(false))
      } catch (er) {
        console.log(er)
        yield put(setLoadingButton(false))
        yield put(setLoading(false))
        yield put(error())
      }
    },

    * setSwitcherSelected () {
      const switcherSelected = yield this.get('switcherSelected')
      const {
        fetchGrantedUnits,
        fetchPendingUnits,
        resetPendingUnits,
        setShowLoadMoreInfo
      } = this.actions

      yield put(setShowLoadMoreInfo(false))

      switch (switcherSelected) {
        case 'pending':
          yield put(resetPendingUnits())
          yield put(fetchPendingUnits())
          break

        case 'granted':
          yield put(fetchGrantedUnits())
          break

        default:
          break
      }
    }
  }
})
