// @flow
import { type Device, type DeviceRoleListResponse } from 'types/api'
import { kea } from 'kea'
import PropTypes from 'prop-types'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import sortBy from 'lodash/sortBy'

import { API_BASE_URL } from 'config'
import { log, generateUrl } from 'utils'
import { checkRolePermission } from 'containers/UserPermission/utils'
import AppLogic from 'containers/App/logic'

const INVITES_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles`

export default kea({
  path: () => ['scenes', 'DeviceUsers'],

  connect: {
    props: [
      AppLogic,
      ['isMobile', 'translations', 'currentDeviceId', 'userRole', 'showBackBar']
    ],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    fetchInvites: () => true,
    setInvites: invites => ({ invites }),
    setSelectedInviteType: type => type,

    reset: () => true,
    setFixNavbar: fix => ({ fix })
  }),

  reducers: ({ actions }) => ({
    invites: [
      [],
      PropTypes.array,
      {
        [actions.setInvites]: (state, payload) => payload.invites,
        [actions.reset]: () => []
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.setInvites]: () => false,
        [actions.reset]: () => true
      }
    ],

    /**
     * used to display a fixed navbar on top of the page in mobile
     * when the user scrolls down the page\
     */
    fixNavbar: [
      false,
      PropTypes.bool,
      {
        [actions.setFixNavbar]: (state, payload) => payload.fix,
        [actions.reset]: () => false
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    /** filter only the invites that is not pending and not owner */
    invites: [
      () => [selectors.invites],
      (invites: []) => {
        invites = invites.filter(invite => invite.status !== 'REJECTED')
        return sortBy(invites, ['createdAt'])
      },
      PropTypes.array
    ],

    userCanAddUser: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('ADD_USER_INVITE', userRole),
      PropTypes.bool
    ]
  }),

  start: function * () {
    const { fetchInvites, navigate } = this.actions

    log('[XS-DeviceUsers] Start scene', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    yield put(fetchInvites())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-DeviceUsers] Stop scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchInvites]: workers.fetchInvites
  }),

  workers: {
    * fetchInvites () {
      const { setInvites } = this.actions

      try {
        const currentDeviceId: Device = yield this.get('currentDeviceId')

        const url = generateUrl(INVITES_ENDPOINT, { id: currentDeviceId })
        const response = yield call(axios.get, url)
        const requestResult: DeviceRoleListResponse = response.data
        const { result, successful } = requestResult

        if (successful) {
          yield put(setInvites(result))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})
