// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { Button } from 'xstorage-components'
import Logic from './logic'
import Lottie from 'react-lottie'
import * as animationData from './data/error-went-wrong.json'
import * as animationDesktopData from './data/error-went-wrong-desktop.json'

type Props = {
  isMobile: boolean,
  translations: {
    ERROR_PAGE_NOT_FOUND: string,
    ERROR_404_DESC: string,
    API_GO_TO_HOMEPAGE: string
  }
}

class NotFound extends Component<Props, void> {
  render () {
    const { isMobile, translations } = this.props

    const {
      ERROR_PAGE_NOT_FOUND,
      ERROR_404_DESC,
      API_GO_TO_HOMEPAGE
    } = translations

    const options = {
      loop: true,
      autoplay: true,
      animationData: isMobile ? animationData : animationDesktopData
    }

    const animation = (
      <ErrorAnim mobile={isMobile}>
        <Lottie options={options} isClickToPauseDisabled />
      </ErrorAnim>
    )

    return (
      <Wrapper mobile={isMobile}>
        {animation}
        <ErrorContainer mobile={isMobile}>
          <Typography variant={'h1'} color={'primary'} paragraph>
            {'Oops!'}
          </Typography>
          <Typography variant={'h2'} color={'primary'} paragraph>
            {ERROR_PAGE_NOT_FOUND}
          </Typography>
          <Typography
            variant={'h3'}
            color={'textPrimary'}
            paragraph
            dangerouslySetInnerHTML={{ __html: ERROR_404_DESC }}
          />
          <Button
            id={'PrimaryButton_GoToHomepage'}
            buttonWidth={'160px'}
            onClick={() => {
              window.location = '/'
            }}
          >
            {API_GO_TO_HOMEPAGE}
          </Button>
        </ErrorContainer>
      </Wrapper>
    )
  }
}
export default Logic(NotFound)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorContainer = styled.div`
  ${({ mobile }) => !mobile && `
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 56px;
  `}

  ${({ mobile }) => mobile && `
    position: fixed;
    padding: 20px;
    bottom: 5vh;
    max-height: 40%;
    width: 100%;
    text-align: center;
  `}
`

const ErrorAnim = styled.div`
  position: relative;

  ${({ mobile }) => !mobile && `
    height: 100vh;

    > div {
      width: auto!important;
      height: 100vh!important;
      
      > svg {
        width: auto!important;
        height: 100vh!important;
      }
    }
  `}

  ${({ mobile }) => mobile && `
    > div:first-child {
      position: fixed;
      top: 0;
      height: auto!important;
    }
  `}
`
