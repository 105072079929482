// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import round from 'lodash/round'
import Typography from '@material-ui/core/Typography'

import {
  adjustMeterPhasesValue,
  getGridcodeValue,
  getInvBootloaderVersion
} from './utils'
import PageAdaptable from 'containers/PageAdaptable'
import MaintenanceMenu from 'containers/MaintenanceMenu'
// eslint-disable-next-line max-len
import MaintenanceTechParamsMeterPhases from 'containers/MaintenanceTechParamsMeterPhases'
import BmsFaultCodeModal from 'containers/BmsFaultCodeModal'
import {
  PageTitle,
  PageMainSection,
  LabelValueInline,
  FixedTopBar,
  SpaceDiv
} from 'xstorage-components'

type Props = {
  showBackBar: boolean,
  fixNavbar: boolean,
  isMobile: boolean,
  error?: string
}

export class MaintenanceTechParams extends Component<Props, void> {
  hideBar = () => {
    const { isMobile } = this.props
    const { setFixNavbar } = this.actions
    if (isMobile && window.scrollY > 50) {
      setFixNavbar(true)
    } else {
      setFixNavbar(false)
    }
  }

  componentDidMount () {
    const { isMobile } = this.props
    if (isMobile) {
      window.addEventListener('scroll', this.hideBar)
    }
  }

  componentWillUnmount () {
    const { isMobile } = this.props

    if (isMobile) {
      window.removeEventListener('scroll', this.hideBar)
    }
  }

  render () {
    const {
      translations,
      isMobile,
      paramsValues,
      weatherUnit,
      bmsFaultCodeModal,
      currentDeviceId,
      loading,
      showBackBar,
      fixNavbar,
      error
    } = this.props

    const { navigate, setBmsFaultCodeModal } = this.actions

    const {
      MAINTENANCE_TECH_PARAMS,
      MAINTENANCE_TITLE,
      MAINTENANCE_TECH_PARAMS_SYSTEM,
      MAINTENANCE_TECH_PARAMS_OPERATION_MODE,
      MAINTENANCE_TECH_PARAMS_GRID_VOLTAGE,
      MAINTENANCE_TECH_PARAMS_GRID_FREQUENCY,
      MAINTENANCE_TECH_PARAMS_CURRENT_TO_GRID,
      MAINTENANCE_TECH_PARAMS_BUS_VOLTAGE,
      MAINTENANCE_TECH_PARAMS_INVERTER_POWER,
      MAINTENANCE_TECH_PARAMS_GRID_CODE,
      MAINTENANCE_TECH_PARAMS_INVERTER_TEMPERATURE,
      // MAINTENANCE_TECH_PARAMS_DC_CURRENT_INJECTION,
      MAINTENANCE_TECH_PARAMS_INVERTER_MODEL,
      MAINTENANCE_TECH_PARAMS_PHOTOVOLTAIC_PANEL,
      MAINTENANCE_TECH_PARAMS_PV_CURRENT_1,
      MAINTENANCE_TECH_PARAMS_PV_CURRENT_2,
      MAINTENANCE_TECH_PARAMS_PV_VOLTAGE_1,
      MAINTENANCE_TECH_PARAMS_PV_VOLTAGE_2,
      MAINTENANCE_TECH_PARAMS_BATTERY,
      MAINTENANCE_TECH_PARAMS_VOLTAGE,
      MAINTENANCE_TECH_PARAMS_CURRENT,
      MAINTENANCE_TECH_PARAMS_TEMPERATURE,
      MAINTENANCE_TECH_PARAMS_AVERAGE_TEMPERATURE,
      MAINTENANCE_TECH_PARAMS_MAX_TEMPERATURE,
      MAINTENANCE_TECH_PARAMS_MIN_TEMPERATURE,
      MAINTENANCE_TECH_PARAMS_TOTAL_CHARGE,
      MAINTENANCE_TECH_PARAMS_TOTAL_DISCHARGE,
      MAINTENANCE_TECH_PARAMS_STATE_CHARGE,
      MAINTENANCE_TECH_PARAMS_BMS_CHARGE,
      MAINTENANCE_TECH_PARAMS_BMS_FAULT_CODE,
      MAINTENANCE_TECH_PARAMS_HIGHEST_CELL_VOLTAGE,
      MAINTENANCE_TECH_PARAMS_LOWEST_CELL_VOLTAGE,
      MAINTENANCE_TECH_PARAMS_METER,
      MAINTENANCE_ERROR,
      MAINTENANCE_ERRORS,
      MAINTENANCE_TECH_PARAMS_INVERTER_BOOTLOADER,
      MAINTENANCE_TECH_PARAMS_INVERTER_POWER_RATE
    } = translations

    return (
      <div>
        <PageAdaptable
          title={MAINTENANCE_TITLE}
          menu={<MaintenanceMenu page={'tech-params'} />}
          onBack={() => navigate(`/${currentDeviceId}/maintenance/`)}
          isForm={false}
          isDirty={false}
          loading={loading}
        >
          <PageMainSection padding={!isMobile} mobile={isMobile}>
            {fixNavbar && (
              <FixedTopBar
                // eslint-disable-next-line max-len
                backArrowClick={() => navigate(`/${currentDeviceId}/maintenance/`)}
                pageTitle={MAINTENANCE_TECH_PARAMS}
                isTechnician={showBackBar}
              />
            )}
            <PageTitle mobile={isMobile}>{MAINTENANCE_TECH_PARAMS}</PageTitle>
            <Typography variant={'h5'} color={'error'}>
              {error}
            </Typography>
            <SpaceDiv height={'20px'} />
            <ScrollableDiv mobile={isMobile}>
              <Typography variant={'h4'}>
                {MAINTENANCE_TECH_PARAMS_SYSTEM}
              </Typography>
              <LabelValueInline
                id={'OperationMode'}
                label={MAINTENANCE_TECH_PARAMS_OPERATION_MODE}
                value={
                  translations[`BATTERY_MODE_${paramsValues.operationMode}`] ||
                  '--'
                }
              />
              <LabelValueInline
                id={'GridVoltage'}
                label={MAINTENANCE_TECH_PARAMS_GRID_VOLTAGE}
                value={
                  paramsValues.gridVoltage !== null
                    ? `${round(paramsValues.gridVoltage, 2)} V`
                    : '--'
                }
              />
              <LabelValueInline
                id={'GridFrequency'}
                label={MAINTENANCE_TECH_PARAMS_GRID_FREQUENCY}
                value={
                  paramsValues.gridFrequency !== null
                    ? `${round(paramsValues.gridFrequency, 2)} Hz`
                    : '--'
                }
              />
              <LabelValueInline
                id={'CurrentToGrid'}
                label={MAINTENANCE_TECH_PARAMS_CURRENT_TO_GRID}
                value={
                  paramsValues.currentToGrid !== null
                    ? `${round(paramsValues.currentToGrid, 2)} A`
                    : '--'
                }
              />
              <LabelValueInline
                id={'InverterPower'}
                label={MAINTENANCE_TECH_PARAMS_INVERTER_POWER}
                value={
                  paramsValues.inverterPower !== null
                    ? `${round(paramsValues.inverterPower, 2)} W`
                    : '--'
                }
              />
              <LabelValueInline
                id={'InverterTemperature'}
                label={MAINTENANCE_TECH_PARAMS_INVERTER_TEMPERATURE}
                value={
                  paramsValues.inverterTemperature !== null
                    ? `${round(
                      paramsValues.inverterTemperature,
                      2
                    )} ${weatherUnit}`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BusVoltage'}
                label={MAINTENANCE_TECH_PARAMS_BUS_VOLTAGE}
                value={
                  paramsValues.busVoltage !== null
                    ? `${round(paramsValues.busVoltage, 2)} V`
                    : '--'
                }
              />
              <LabelValueInline
                id={'GridCode'}
                label={MAINTENANCE_TECH_PARAMS_GRID_CODE}
                value={
                  paramsValues.gridCode !== null
                    ? translations[getGridcodeValue(paramsValues.gridCode)]
                    : '--'
                }
              />
              <LabelValueInline
                id={'InverterModel'}
                label={MAINTENANCE_TECH_PARAMS_INVERTER_MODEL}
                value={paramsValues.tidaProtocolVersion || '--'}
              />
              <LabelValueInline
                id={'inverterPowerRating'}
                label={MAINTENANCE_TECH_PARAMS_INVERTER_POWER_RATE}
                value={
                  (paramsValues.inverterPowerRating &&
                    paramsValues.inverterPowerRating !== 0)
                    ? `${paramsValues.inverterPowerRating} kW`
                    : '--'
                }
              />
              <LabelValueInline
                id={'InverterBootloader'}
                label={MAINTENANCE_TECH_PARAMS_INVERTER_BOOTLOADER}
                value={
                  getInvBootloaderVersion(paramsValues.invBootloaderVersion) ||
                  '--'
                }
              />
              <SpacedTypography variant={'h4'}>
                {MAINTENANCE_TECH_PARAMS_PHOTOVOLTAIC_PANEL}
              </SpacedTypography>
              <LabelValueInline
                id={'Pv1Current'}
                label={MAINTENANCE_TECH_PARAMS_PV_CURRENT_1}
                value={
                  paramsValues.pv1Current !== null
                    ? `${round(paramsValues.pv1Current, 2)} A`
                    : '--'
                }
              />
              <LabelValueInline
                id={'Pv1Voltage'}
                label={MAINTENANCE_TECH_PARAMS_PV_VOLTAGE_1}
                value={
                  paramsValues.pv1Voltage !== null
                    ? `${round(paramsValues.pv1Voltage, 2)} V`
                    : '--'
                }
              />
              {paramsValues.tidaProtocolVersion === '1.1' && (
                <React.Fragment>
                  <LabelValueInline
                    id={'Pv2Current'}
                    label={MAINTENANCE_TECH_PARAMS_PV_CURRENT_2}
                    value={
                      paramsValues.pv2Current !== null
                        ? `${round(paramsValues.pv2Current, 2)} A`
                        : '--'
                    }
                  />
                  <LabelValueInline
                    id={'Pv2Voltage'}
                    label={MAINTENANCE_TECH_PARAMS_PV_VOLTAGE_2}
                    value={
                      paramsValues.pv2Voltage !== null
                        ? `${round(paramsValues.pv2Voltage, 2)} V`
                        : '--'
                    }
                  />
                </React.Fragment>
              )}
              <SpacedTypography variant={'h4'}>
                {MAINTENANCE_TECH_PARAMS_BATTERY}
              </SpacedTypography>
              <LabelValueInline
                id={'BmsVoltage'}
                label={MAINTENANCE_TECH_PARAMS_VOLTAGE}
                value={
                  paramsValues.bmsVoltage !== null
                    ? `${round(paramsValues.bmsVoltage, 2)} V`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsCurrent'}
                label={MAINTENANCE_TECH_PARAMS_CURRENT}
                value={
                  paramsValues.bmsCurrent !== null
                    ? `${round(paramsValues.bmsCurrent, 2)} A`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsTemperature'}
                label={MAINTENANCE_TECH_PARAMS_TEMPERATURE}
                value={
                  paramsValues.bmsTemperature !== null
                    ? `${round(paramsValues.bmsTemperature, 2)} ${weatherUnit}`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsAvgTemperature'}
                label={MAINTENANCE_TECH_PARAMS_AVERAGE_TEMPERATURE}
                value={
                  paramsValues.bmsAvgTemperature !== null
                    ? `${round(
                      paramsValues.bmsAvgTemperature,
                      2
                    )} ${weatherUnit}`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsMaxTemperature'}
                label={MAINTENANCE_TECH_PARAMS_MAX_TEMPERATURE}
                value={
                  paramsValues.bmsMaxTemperature !== null
                    ? `${round(
                      paramsValues.bmsMaxTemperature,
                      2
                    )} ${weatherUnit}`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsMinTemperature'}
                label={MAINTENANCE_TECH_PARAMS_MIN_TEMPERATURE}
                value={
                  paramsValues.bmsMinTemperature !== null
                    ? `${round(
                      paramsValues.bmsMinTemperature,
                      2
                    )} ${weatherUnit}`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsTotalCharge'}
                label={MAINTENANCE_TECH_PARAMS_TOTAL_CHARGE}
                value={
                  paramsValues.bmsTotalCharge !== null
                    ? `${round(paramsValues.bmsTotalCharge, 2)} Ah`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsTotalDischarge'}
                label={MAINTENANCE_TECH_PARAMS_TOTAL_DISCHARGE}
                value={
                  paramsValues.bmsTotalDischarge !== null
                    ? `${round(paramsValues.bmsTotalDischarge, 2)} Ah`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsStateOfCharge'}
                label={MAINTENANCE_TECH_PARAMS_STATE_CHARGE}
                value={
                  paramsValues.bmsStateOfCharge !== null
                    ? `${round(paramsValues.bmsStateOfCharge, 2)}%`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsState'}
                label={MAINTENANCE_TECH_PARAMS_BMS_CHARGE}
                value={
                  // eslint-disable-next-line max-len
                  translations[`MAINTENANCE_BATTERY_STATUS_${paramsValues.bmsState}`] || '--'
                }
              />
              <LabelValueInline
                id={'BmsFaultCode'}
                label={MAINTENANCE_TECH_PARAMS_BMS_FAULT_CODE}
                value={
                  paramsValues.bmsFaultCode !== null
                    ? `${paramsValues.bmsFaultCode.length} ${
                      paramsValues.bmsFaultCode.length === 1
                        ? MAINTENANCE_ERROR
                        : MAINTENANCE_ERRORS
                    }`
                    : '--'
                }
                onClick={
                  paramsValues.bmsFaultCode !== null &&
                  paramsValues.bmsFaultCode.length > 0
                    ? () => setBmsFaultCodeModal(true)
                    : null
                }
              />
              <LabelValueInline
                id={'BmsHighestCellVoltage'}
                label={MAINTENANCE_TECH_PARAMS_HIGHEST_CELL_VOLTAGE}
                value={
                  paramsValues.bmsHighestCellVoltage !== null
                    ? `${round(paramsValues.bmsHighestCellVoltage, 2)} mV`
                    : '--'
                }
              />
              <LabelValueInline
                id={'BmsLowestCellVoltage'}
                label={MAINTENANCE_TECH_PARAMS_LOWEST_CELL_VOLTAGE}
                value={
                  paramsValues.bmsLowestCellVoltage !== null
                    ? `${round(paramsValues.bmsLowestCellVoltage, 2)} mV`
                    : '--'
                }
              />
              {paramsValues.meters &&
                paramsValues.meters.map(meter => (
                  <React.Fragment key={meter.number}>
                    {meter.phases && (
                      <React.Fragment>
                        <SpacedTypography variant={'h4'}>
                          {MAINTENANCE_TECH_PARAMS_METER} {meter.number}
                        </SpacedTypography>
                        <MaintenanceTechParamsMeterPhases
                          meterPhaseValues={adjustMeterPhasesValue(
                            meter.phases
                          )}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
            </ScrollableDiv>
          </PageMainSection>
          <BmsFaultCodeModal
            open={bmsFaultCodeModal}
            onClose={() => setBmsFaultCodeModal(false)}
            bmsfaultcode={paramsValues.bmsFaultCode}
          />
        </PageAdaptable>
      </div>
    )
  }
}

export default Logic(MaintenanceTechParams)

const ScrollableDiv = styled.div`

  ${({ mobile }) => !mobile && `
    overflow-y: auto;
    padding-right: 2%;
    max-height: calc(100vh - 350px);

    &&::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &&::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  `}
`

const SpacedTypography = styled(Typography)`
  &&&& {
    margin: 40px 0 0;
  }
`
