// @flow
import { type DeviceRole, type Device } from 'types/api'
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, Typography } from '@material-ui/core'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  SuccessSavedAlert,
  PageTitle,
  Button,
  Form,
  List,
  Divider,
  InputText,
  PageMainSection
} from 'xstorage-components'

import FormGeneralError from 'containers/FormGeneralError'
import FormFieldError from 'containers/FormFieldError'

type Props = {
  isMobile: boolean,
  translations: any,
  form: any,
  dirty: boolean,
  error: string,
  loading: boolean,
  currentDevice: Device,
  pendingInvite: DeviceRole,
  currentDeviceId: string,
  successMessage: boolean
}

export class DeviceChangeOwnership extends Component<Props, void> {
  actions: any

  render () {
    const {
      isMobile,
      translations,
      form,
      dirty,
      error,
      loading,
      currentDevice,
      pendingInvite,
      currentDeviceId,
      successMessage
    } = this.props

    const {
      navigate,
      change,
      submit,
      resendInvitation,
      cancelInvitation
    } = this.actions

    const { email, confirmEmail } = form

    const {
      UNITSETT_TITLE,
      UNITSETT_CHANGEOWNER_TITLE,
      UNITSETT_CHANGEOWNER_SUB_TITLE,
      UNITSETT_CHANGEOWNER_N_OWNER,
      UNITSETT_CHANGEOWNER_N_OWNER_EMAIL,
      UNITSETT_CHANGEOWNER_N_OWNER_EMAIL_CONFIRM,
      UNITSETT_CHANGEOWNER_CANCEL,
      UNITSETT_CHANGEOWNER_SAVE,
      UNITSETT_CHANGEOWNER_TEXT_2,
      UNITSETT_CHANGEOWNER_TEXT_3,
      UNITSETT_USERSPERM_USERINFO_RESEND,
      UNITSETT_CHANGEOWNER_TEXT_1_1,
      UNITSETT_CHANGEOWNER_TEXT_1_2,
      UNITSETT_CHANGEOWNER_TEXT_1_3,
      UNITSETT_CHANGEOWNER_RESEND_INVITE_SUCCESS
    } = translations

    let { UNITSETT_CHANGEOWNER_TEXT_PENDING } = translations

    const unitSettChangeOwnerText = (
      <span>
        {UNITSETT_CHANGEOWNER_TEXT_1_1}
        <b>{currentDevice.name}</b>
        {UNITSETT_CHANGEOWNER_TEXT_1_2}
        <b>{currentDevice.inverterSerialNumber}</b>
        {UNITSETT_CHANGEOWNER_TEXT_1_3}
      </span>
    )

    if (
      pendingInvite &&
      typeof UNITSETT_CHANGEOWNER_TEXT_PENDING === 'string'
    ) {
      // eslint-disable-next-line max-len
      UNITSETT_CHANGEOWNER_TEXT_PENDING = UNITSETT_CHANGEOWNER_TEXT_PENDING.replace(
        '{email}',
        pendingInvite.email
      )
    }

    return (
      <Wrapper>
        <PageAdaptable
          title={UNITSETT_TITLE}
          menu={<DeviceSettingsMenu page={'change-ownership'} />}
          isForm
          isDirty={dirty}
          onBack={() => navigate(`/${currentDeviceId}/settings/`)}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <SuccessSavedAlert
              mobile={isMobile}
              out={!successMessage}
              icon={<Icon>{'check'}</Icon>}
              message={UNITSETT_CHANGEOWNER_RESEND_INVITE_SUCCESS}
            />
            <PageTitle mobile={isMobile}>
              {UNITSETT_CHANGEOWNER_TITLE}
            </PageTitle>
            {!pendingInvite && (
              <Fragment>
                <Typography variant={'h4'}>
                  {UNITSETT_CHANGEOWNER_SUB_TITLE}
                </Typography>
                <TextInfo>
                  <Typography paragraph>{unitSettChangeOwnerText}</Typography>
                  <Typography
                    paragraph
                    dangerouslySetInnerHTML={{
                      __html: UNITSETT_CHANGEOWNER_TEXT_2
                    }}
                  />
                  <Typography
                    paragraph
                    dangerouslySetInnerHTML={{
                      __html: UNITSETT_CHANGEOWNER_TEXT_3
                    }}
                  />
                </TextInfo>
              </Fragment>
            )}
            {pendingInvite && (
              <TextInfo>
                <PendingText
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: UNITSETT_CHANGEOWNER_TEXT_PENDING
                  }}
                />
              </TextInfo>
            )}
            <Typography variant={'h4'}>
              {UNITSETT_CHANGEOWNER_N_OWNER}
            </Typography>
            <FormContainer>
              <FormGeneralError dirty={dirty} error={error} />
              <Form.Row>
                <InputText
                  id={'Email'}
                  label={UNITSETT_CHANGEOWNER_N_OWNER_EMAIL}
                  type='text'
                  value={email.value}
                  error={dirty && !email.valid}
                  disabled={!!pendingInvite}
                  onChange={({ target }) =>
                    change({ email: target.value.toLowerCase() })
                  }
                />
                <FormFieldError
                  id={'Email'}
                  showError={dirty && !email.valid}
                  errorMessage={email.message}
                />
              </Form.Row>
              <Form.Row>
                <InputText
                  id={'ConfirmEmail'}
                  label={UNITSETT_CHANGEOWNER_N_OWNER_EMAIL_CONFIRM}
                  type='text'
                  value={confirmEmail.value}
                  error={dirty && !confirmEmail.valid}
                  disabled={!!pendingInvite}
                  onChange={({ target }) =>
                    change({ confirmEmail: target.value })
                  }
                />
                <FormFieldError
                  id={'ConfirmEmail'}
                  showError={dirty && !confirmEmail.valid}
                  errorMessage={confirmEmail.message}
                />
              </Form.Row>
            </FormContainer>
            {!pendingInvite && (
              <Form.Buttons mobile={isMobile}>
                <Button
                  id={'PrimaryButton_Save'}
                  margin={'0 12px'}
                  buttonWidth={'163px'}
                  onClick={submit}
                >
                  {UNITSETT_CHANGEOWNER_SAVE}
                </Button>
              </Form.Buttons>
            )}
            {pendingInvite && (
              <ActionsRow full>
                <List listOfButtons mobile={isMobile} >
                  <Divider variant={'inset'} component='li' />
                  <List.Item
                    button
                    id={'ListItem_ResendInvitation'}
                    onClick={resendInvitation}
                  >
                    <List.ItemText
                      primary={UNITSETT_USERSPERM_USERINFO_RESEND}
                    />
                    <Icon
                      style={{ color: '#007BC1' }}
                    >{'keyboard_arrow_right'}</Icon>
                  </List.Item>
                  <Divider variant={'inset'} component='li' />
                  <List.Item
                    button
                    id={'ListItem_CancelInvitation'}
                    onClick={cancelInvitation}
                  >
                    <DeleteItemText primary={UNITSETT_CHANGEOWNER_CANCEL} />
                    <Icon
                      style={{ color: '#CA3C3D' }}
                    >{'keyboard_arrow_right'}</Icon>
                  </List.Item>
                  <Divider variant={'inset'} component='li' />
                </List>
              </ActionsRow>
            )}
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(DeviceChangeOwnership)

const Wrapper = styled.div``

const TextInfo = styled.div`
  margin: 16px 0 32px 0;
`

const FormContainer = styled.div`
  margin-left: 8px;
  margin-bottom: 10vh;
`

const PendingText = styled(props => <Typography {...props} />)`
  &&&& {
    color: #ca3c3d;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
`

const DeleteItemText = styled(props => <List.ItemText {...props} />)`
  &&&& {
    span {
      color: #ca3c3d;
    }
  }
`

const ActionsRow = styled(props => <Form.Row {...props} />)`
  &&&& {
    margin-top: 115px;

    @media screen and (max-width: 1024px) {
      position: absolute;
      bottom: 0;
      width: calc(100% - 100px);
    }
    @media screen and (max-width: 550px) {
      position: absolute;
      bottom: 0;
      width: calc(100% - 40px);
    }
  }
`
