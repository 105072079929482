// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import NotificationsList from 'containers/NotificationsList'

import { PageTitle, BackButton } from 'xstorage-components'

type Props = {
  translations: {
    NOTIF_TITLE: string
  },
  isMobile: boolean,
  notificationsList: {},
  notificationsTotal: number,
  unreadNotifications: number,
  loadingButton: boolean,
  showLoadMoreInfo: boolean,
  currentDeviceId: string,
  unitRelatedPage: boolean,
  userIsTechnician: boolean
}

export class Notifications extends Component<Props, void> {
  actions: any

  render () {
    const {
      translations,
      isMobile,
      notificationsList,
      notificationsTotal,
      unreadNotifications,
      loadingButton,
      showLoadMoreInfo,
      currentDeviceId,
      unitRelatedPage,
      userIsTechnician
    } = this.props

    const { navigate, getNotifications } = this.actions

    const { NOTIF_TITLE } = translations

    const url =
      userIsTechnician && !unitRelatedPage
        ? '/tech-more/'
        : `/${currentDeviceId}/more/`
    return (
      <Wrapper>
        <BackButton id={'BackButton'} onClick={() => navigate(url)} />
        <PageTitle mobile={isMobile} blue>
          {NOTIF_TITLE}
        </PageTitle>
        <NotificationsList
          notifications={notificationsList}
          notificationsTotal={notificationsTotal}
          unreadNotifications={unreadNotifications}
          loadingButton={loadingButton}
          showLoadMoreInfo={showLoadMoreInfo}
          getNotifications={getNotifications}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;


  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

export default Logic(Notifications)
