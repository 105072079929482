// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'

import AppLogic from 'containers/App/logic'
import NotificationsPanelLogic from 'containers/NotificationsPanel/logic'
import UserBarLogic from 'containers/UserBar/logic'

export default kea({
  path: () => ['scenes', 'Notifications'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDeviceId',
        'userIsTechnician',
        'unitRelatedPage'
      ],
      NotificationsPanelLogic, [
        'notificationsList',
        'notificationsTotal',
        'unreadNotifications',
        'loadingButton',
        'showLoadMoreInfo'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ],
      NotificationsPanelLogic, [
        'getNotifications'
      ],
      UserBarLogic, [
        'getUnreadNotifications'
      ]
    ]
  },

  start: function * () {
    const { getNotifications, getUnreadNotifications } = this.actions

    yield put(getNotifications())
    yield put(getUnreadNotifications())
  }
})
