/**
 * UserMenu Container Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import { kea } from 'kea'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'containers', 'UserMenu'],

  connect: {
    props: [
      AppLogic, [
        'user',
        'translations',
        'userIsTechnician'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'logout',
        'setShowHelpTour'
      ]
    ]
  }

})
