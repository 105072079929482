// @flow
import React, { Component } from 'react'
import Logic from './logic'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import { convertEpochToDate, convertMillisToSec } from 'utils'

import { UnitDetails, Paper } from 'xstorage-components'

type Props = {
  grantedUnits: [],
  isMobile: boolean
}

class UnitGrantedAccess extends Component<Props> {
  render () {
    const { grantedUnits, isMobile, translations } = this.props
    const { navigate, fetchCurrentDeviceInfo } = this.actions
    const {
      TECH_UNITS_ACCESS_EXPIRES_ON,
      TECH_UNITS_INSTALLED_ON,
      UNITSETT_USERSPERM_ACCESS_NEVER_EXPIRES
    } = translations

    return (
      <Grid container spacing={3}>
        {grantedUnits &&
          grantedUnits.map(unit => (
            <Grid key={unit.id} item xs={12} sm={6} md={4}>
              <Paper showanimation mobile={isMobile} shadow>
                <UnitDetails
                  id={'GrantedAccessUnit_Card'}
                  name={unit.inverterSerialNumber}
                  email={unit.owner ? unit.owner.email : '-'}
                  date={`${TECH_UNITS_INSTALLED_ON} ${convertEpochToDate(
                    convertMillisToSec(unit.createdAt),
                    'DD/MM/YYYY HH:mm'
                  )}`}
                  expirationdate={
                    unit.accessValidityPeriod === 0
                      ? `${UNITSETT_USERSPERM_ACCESS_NEVER_EXPIRES}`
                      : `${TECH_UNITS_ACCESS_EXPIRES_ON} ${
                        convertEpochToDate(
                          convertMillisToSec(unit.accessExpiresAt),
                          'DD/MM/YYYY HH:mm'
                        )}`
                  }
                  icon={<Icon>{'arrow_forward'}</Icon>}
                  onClick={() => {
                    navigate(`/${unit.id}/dashboard/`)
                    fetchCurrentDeviceInfo()
                  }}
                />
              </Paper>
            </Grid>
          ))}
      </Grid>
    )
  }
}

export default Logic(UnitGrantedAccess)
