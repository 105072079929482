// @flow
import { type Device } from 'types/api'
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, Switch, FormControlLabel } from '@material-ui/core'

import PageAdaptable from 'containers/PageAdaptable'
import MaintenanceMenu from 'containers/MaintenanceMenu'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeatures from 'containers/MaintenanceSupportedFeatures'

import {
  Button,
  PageTitle,
  PageMainSection,
  SuccessSavedAlert,
  LabelValueInline,
  ErrorMessage,
  SpaceDiv
} from 'xstorage-components'

import { remainingTime } from 'utils'

type Props = {
  history: any,
  translations: {
    MAINTENANCE_TITLE: string,
    UNITSETT_SUPPORT_SSH_STATUS: string,
    UNITSETT_SUPPORT_SSHSTATUS_OPEN_SUCCESS: string,
    UNITSETT_SUPPORT_SSHSTATUS_SSHPORT: string,
    UNITSETT_SUPPORT_SSHSTATUS_ENABLED: string,
    UNITSETT_SUPPORT_SSHSTATUS_DISABLED: string,
    UNITSETT_SUPPORT_SSHSTATUS_SSHTUNNEL: string,
    UNITSETT_SUPPORT_SSHTUNNEL_EXPIRATION: string,
    UNITSETT_SUPPORT_UNITLOG_DOWNLOAD: string,
    UNITSETT_SUPPORT_SSH_CERTIFICATE: string,
  },
  loading: boolean,
  isMobile: boolean,
  successMessage: boolean,
  sshStatus: {
    expiresIn: number,
    enabled: boolean,
    port: number,
    keysArchive: {
      url: string,
    },
  },
  error: string,
  currentDevice: Device,
  featureSupport: {
    sshSupported: boolean
  },
  currentDeviceId: string
}

class MaintenanceSshConnection extends Component<Props, void> {
  render () {
    const {
      translations,
      isMobile,
      loading,
      successMessage,
      sshStatus,
      error,
      currentDevice,
      featureSupport,
      currentDeviceId
    } = this.props

    const {
      MAINTENANCE_TITLE,
      UNITSETT_SUPPORT_SSH_STATUS,
      UNITSETT_SUPPORT_SSHSTATUS_OPEN_SUCCESS,
      UNITSETT_SUPPORT_SSHSTATUS_SSHPORT,
      UNITSETT_SUPPORT_SSHSTATUS_ENABLED,
      UNITSETT_SUPPORT_SSHSTATUS_DISABLED,
      UNITSETT_SUPPORT_SSHSTATUS_SSHTUNNEL,
      UNITSETT_SUPPORT_SSHTUNNEL_EXPIRATION,
      UNITSETT_SUPPORT_UNITLOG_DOWNLOAD,
      UNITSETT_SUPPORT_SSH_CERTIFICATE
    } = translations

    const { sshSupported } = featureSupport
    const { enabled, port, expiresIn, keysArchive } = sshStatus || {}
    const { setEnableStatus, navigate, getFile } = this.actions

    return (
      <Wrapper>
        <PageAdaptable
          title={MAINTENANCE_TITLE}
          menu={<MaintenanceMenu page={'ssh-connection'} />}
          isForm={false}
          onBack={() => navigate(`/${currentDeviceId}/maintenance/`)}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle mobile={isMobile}>
              {UNITSETT_SUPPORT_SSH_STATUS}
            </PageTitle>
            <MaintenanceSupportedFeatures feature={'sshtunnel'} />
            <SuccessSavedAlert
              out={!successMessage}
              mobile={isMobile}
              icon={<Icon>{'check'}</Icon>}
              message={UNITSETT_SUPPORT_SSHSTATUS_OPEN_SUCCESS}
            />
            {sshSupported &&
              <ErrorMessage>{error}</ErrorMessage>
            }
            <SpaceDiv height={'20px'} />
            {sshSupported &&
              <Fragment>
                <Label>{UNITSETT_SUPPORT_SSHSTATUS_SSHTUNNEL}</Label>
                <SwitchLabel
                  id={'SwitchLabel_SSHStatusEnabled'}
                  control={
                    <PowerSwitch
                      id={'PowerSwitch_SSHStatusEnabled'}
                      checked={enabled}
                      onChange={() => setEnableStatus(!enabled)}
                      classes={{
                        checked: 'checked',
                        track: 'bar'
                      }}
                      disabled={!currentDevice.connected}
                    />
                  }
                  label={(
                    <TunnelState disabled={!enabled}>
                      <span>
                        {enabled
                          ? UNITSETT_SUPPORT_SSHSTATUS_ENABLED
                          : UNITSETT_SUPPORT_SSHSTATUS_DISABLED
                        }
                      </span>
                      {enabled && expiresIn && (
                        <span>
                          {UNITSETT_SUPPORT_SSHTUNNEL_EXPIRATION}
                          {remainingTime(expiresIn)}
                        </span>
                      )}
                    </TunnelState>
                  )}
                  disabled={!currentDevice.connected}
                />
                {enabled && (
                  <LabelValueInline
                    id={'LabelValueInline_SSHStatusEnabled'}
                    label={UNITSETT_SUPPORT_SSHSTATUS_SSHPORT}
                    value={(port && port.toString()) || '--'}
                  />
                )}

                {enabled && keysArchive && keysArchive.url && (
                  <TunnelCertificate>
                    <Label>{UNITSETT_SUPPORT_SSH_CERTIFICATE}</Label>
                    <Button
                      id={'Button_Download'}
                      buttonType={'secondary-default'}
                      margin={isMobile ? '0 auto' : '0'}
                      buttonWidth={'120px'}
                      onClick={() => { getFile(keysArchive.url) }}
                    >
                      {UNITSETT_SUPPORT_UNITLOG_DOWNLOAD}
                    </Button>
                  </TunnelCertificate>
                )}
              </Fragment>
            }
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceSshConnection)

const Wrapper = styled.div`
  #LabelValueInlineWrapper_LabelValueInline_SSHStatusEnabled {
    span:first-child {
      color: #424E54;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      font-family: 'Open Sans';
    }

    span:nth-child(2) {
      font-weight: 600;
    }
  }
`

const SwitchLabel = styled(FormControlLabel)`
  height: 48px;
  position: relative;
  margin-left: 0 !important;
  margin-right: 0;
  width: 100%;
  padding-left: 8px;

  > span:nth-child(2) {
    width: 85%;
    color: #424e54;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
  }
  box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.2);
`

const PowerSwitch = styled(Switch)`
  &&&& {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .checked {
      color: #007bc1;
    }
    .checked + .bar {
      background-color: #007bc1;
      opacity: 0.4;
    }

    ${({ disabled }) => disabled && `
      opacity: 0.5;
    `}
  }
`

const Label = styled.p`
  color: #333F48;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  padding-left: 8px;
  margin-top: 38px;
  margin-bottom: 28px;
`

const TunnelState = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;

  span:first-child {
    color: #424E54;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 8px;

    ${({ disabled }) => disabled && `
      font-size: 14px;
      font-weight: 400;
    `}
  }

  span:nth-child(2) {
    color: #424E54;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 16px;
  }
`

const TunnelCertificate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
