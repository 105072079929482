// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import UserProfileMenu from 'containers/UserProfileMenu'

import {
  PageTitle,
  BackButton,
  SuccessSavedAlert
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  currentDeviceId: string,
  userIsTechnician: boolean,
  successMessage: boolean
}

export class UserProfile extends Component<Props, void> {
  actions: any

  render () {
    const {
      translations,
      isMobile,
      currentDeviceId,
      userIsTechnician,
      successMessage
    } = this.props

    const { navigate } = this.actions

    const { USERPROF_TITLE, USERPROF_CHANGES_SAVED_SUCCESS } = translations

    const backUrl = userIsTechnician
      ? '/tech-more/'
      : `/${currentDeviceId}/more/`

    return (
      <Wrapper>
        <BackButton id={'BackButton'} onClick={() => navigate(backUrl)} />
        <SuccessSavedAlert
          mobile={isMobile}
          out={!successMessage}
          icon={<Icon>{'check'}</Icon>}
          message={USERPROF_CHANGES_SAVED_SUCCESS}
        />
        <ProfileIcon>{'account_circle'}</ProfileIcon>
        <PageTitle mobile={isMobile} center blue marginBottom={'30px'}>
          {USERPROF_TITLE}
        </PageTitle>
        <UserProfileMenu />
      </Wrapper>
    )
  }
}

export default Logic(UserProfile)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const ProfileIcon = styled(Icon)`
  color: #007bc1;
  font-size: 110px !important;
  margin: 0 auto;
  display: block;
`
