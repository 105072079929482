// @flow
import { type Device } from 'types/api'
import { type GeneralConfigs } from 'types/global'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, Switch } from '@material-ui/core'

import PageNavMenu from 'containers/PageNavMenu'

import {
  ModalMessage,
  Loading,
  Button,
  Typography,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  page?: string,
  translations: any,
  currentDevice: Device,
  loading: boolean,
  error: string,
  configs: GeneralConfigs,
  userCanPowerUnit: boolean,
  userCanChangeOwner: boolean,
  userCanUpdate: boolean
}

type State = {
  powerStateModal: boolean
}

class DeviceSettingsMenu extends Component<Props, State> {
  actions: any

  state = {
    powerStateModal: false
  }

  render () {
    const {
      isMobile,
      page,
      translations,
      currentDevice,
      currentDeviceId,
      loading,
      error,
      userCanPowerUnit,
      userCanChangeOwner,
      userCanUpdate
    } = this.props

    const { navigate, changePowerState, reset } = this.actions

    const { powerStateModal } = this.state

    const {
      UNITSETT_GENERAL,
      UNITSETT_USERS_PERMISSIONS,
      UNITSETT_UPDATES,
      UNITSETT_CHANGE_OWNER,
      UNITSETT_SUPPORT,
      UNITSETT_DELETE_UNIT,
      UNITSETT_UNIT_POWER,
      UNITSETT_MODEL_POWEROFF_TITLE,
      UNITSETT_MODEL_POWEROFF_SUBTITLE,
      UNITSETT_MODEL_POWEROFF_CANCEL,
      UNITSETT_MODEL_POWEROFF_SUBMIT
    } = translations

    const menuItems = [
      {
        id: 'UnitPower',
        key: 'unit-power',
        icon: <Icon>{'power_settings_new'}</Icon>,
        title: UNITSETT_UNIT_POWER,
        link: false
      },
      {
        id: 'GeneralSettings',
        key: 'general-settings',
        icon: <Icon>{'settings'}</Icon>,
        title: UNITSETT_GENERAL,
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/settings/general/`)
      },
      {
        id: 'UsersPermissions',
        key: 'users-permissions',
        icon: <Icon>{'supervisor_account'}</Icon>,
        title: UNITSETT_USERS_PERMISSIONS,
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/settings/users/`)
      },
      {
        id: 'Updates',
        key: 'updates',
        icon: <Icon>{'sync'}</Icon>,
        title: UNITSETT_UPDATES,
        link: true,
        hidden: !userCanUpdate || !currentDevice.connected,
        onClick: () => navigate(`/${currentDeviceId}/settings/updates/`)
      },
      {
        id: 'ChangeOwnership',
        key: 'change-ownership',
        icon: <Icon>{'vpn_key'}</Icon>,
        title: UNITSETT_CHANGE_OWNER,
        link: true,
        hidden: !userCanChangeOwner,
        onClick: () =>
          navigate(`/${currentDeviceId}/settings/change-ownership/`)
      },
      {
        id: 'Support',
        key: 'support',
        icon: <Icon>{'build'}</Icon>,
        title: UNITSETT_SUPPORT,
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/settings/support/`)
      },
      {
        id: 'DeleteUnit',
        key: 'delete-unit',
        icon: <Icon>{'delete'}</Icon>,
        title: UNITSETT_DELETE_UNIT,
        color: isMobile ? '#CA3C3D' : '#EFC5C5',
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/settings/delete-unit/`)
      }
    ]

    const changeState = () => {
      this.setState({ powerStateModal: false })
      changePowerState(!currentDevice.powerState)
    }

    return (
      <Wrapper>
        {loading && <Loading />}

        {error && (
          <ModalMessage
            icon={<Icon>{'report'}</Icon>}
            title={'Error ocurred'}
            text={error}
            label={'Please try again later'}
            mobile={isMobile}
            onClose={reset}
            closable
          />
        )}

        <PageNavMenu items={menuItems} page={page} />
        <PowerSwitch
          checked={currentDevice.powerState}
          onClick={() =>
            currentDevice.powerState
              ? this.setState({ powerStateModal: true })
              : changeState()
          }
          disabled={!userCanPowerUnit}
          classes={{
            checked: 'checked',
            track: 'bar'
          }}
        />
        {powerStateModal && (
          <Modal
            size={'small'}
            open={powerStateModal}
            onClose={() => this.setState({ powerStateModal: false })}
          >
            <div>
              <ModalHeader title={UNITSETT_MODEL_POWEROFF_TITLE} />
              <ModalContent padding size={'small'} mobile={isMobile}>
                <Typography>
                  <b>{UNITSETT_MODEL_POWEROFF_SUBTITLE}</b>
                </Typography>
              </ModalContent>
              <ModalButtons>
                <Button
                  margin={'0 12px'}
                  buttonType={'primary-cancel'}
                  buttonWidth={'156px'}
                  onClick={() => this.setState({ powerStateModal: false })}
                >
                  {UNITSETT_MODEL_POWEROFF_CANCEL}
                </Button>
                <Button
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={changeState}
                >
                  {UNITSETT_MODEL_POWEROFF_SUBMIT}
                </Button>
              </ModalButtons>
            </div>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default Logic(DeviceSettingsMenu)

const Wrapper = styled.div`
  position: relative;
`

const PowerSwitch = styled(Switch)`
  &&&& {
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 10;

    ${props => props.disabled && `
      opacity: 0.4;
    `}
    
    .checked {
      color: #00b2a9;

      ${props => props.disabled && `
        color: #bec2c5;
      `}

    }
    .checked + .bar {
      background-color: #00b2a9;
      opacity: 0.4;

      ${props => props.disabled && `
        background-color: #bbc0c3;
        opacity: 1;
      `}
    }
  }
`
