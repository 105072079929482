/**
 * MaintenanceUnitLog Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
// @flow
import React, { Component } from 'react'
import { type Device } from 'types/api'
import Logic from './logic'
import styled from 'styled-components'
import PageAdaptable from 'containers/PageAdaptable'
import MaintenanceMenu from 'containers/MaintenanceMenu'
import Icon from '@material-ui/core/Icon'
import moment from 'moment-timezone'
import LoadingGif from 'components/LoadingGif'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeatures from 'containers/MaintenanceSupportedFeatures'
import {
  PageTitle,
  PageMainSection,
  Button,
  TextList,
  Point,
  Modal,
  ModalButtons,
  ModalContent,
  ModalHeader,
  Typography,
  ErrorMessage
} from 'xstorage-components'

import Subtitle from 'components/Subtitle'
import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'

type Props = {
  translations: {
    MAINTENANCE_TITLE: string,
    UNITSETT_SUPPORT_UNITLOG_GETCOPY: string,
    UNITSETT_SUPPORT_UNITLOG: string,
    UNITSETT_SUPPORT_UNITLOG_REQUESTFILE: string,
    UNITSETT_SUPPORT_UNITLOG_NOTE: string,
    UNITSETT_SUPPORT_UNITLOG_REQUESTFILE_MODAL_DETAIL: string,
    UNITSETT_DELETE_MODAL_CANCEL: string,
    UNITSETT_SUPPORT_DISKMANAGEMENT_PROCEED: string,
    UNITSETT_SUPPORT_UNITLOG_UNITLOGFILE: string,
    UNITSETT_SUPPORT_UNITLOG_PLEASEWAIT: string,
    UNITSETT_SUPPORT_UNITLOG_DOWNLOAD: string,
    UNITSETT_TITLE: String,
  },
  isMobile: boolean,
  confirmationModal: boolean,
  fileToDownload: {
    id: string,
    filename: string,
    status: string,
    createdAt: number,
    file: {
      filename: string
    }
  },
  loading: boolean,
  loadingData: boolean,
  currentDevice: Device,
  error: string,
  isSupportMenu: boolean,
  featureSupport: {
    maintenanceSupported: boolean,
    maintenanceUpdateRequired: boolean
  }
}

class MaintenanceUnitLog extends Component<Props> {
  actions: {
    navigate: Function,
    setConfirmationModal: Function,
    requestLogDownload: Function,
    getFile: Function
  }

  render () {
    const {
      translations,
      isMobile,
      confirmationModal,
      fileToDownload,
      loading,
      loadingData,
      currentDevice,
      error,
      featureSupport,
      isSupportMenu
    } = this.props

    const {
      navigate,
      setConfirmationModal,
      requestLogDownload,
      getFile
    } = this.actions

    const {
      MAINTENANCE_TITLE,
      UNITSETT_SUPPORT_UNITLOG_GETCOPY,
      UNITSETT_SUPPORT_UNITLOG,
      UNITSETT_SUPPORT_UNITLOG_REQUESTFILE,
      UNITSETT_SUPPORT_UNITLOG_NOTE,
      UNITSETT_SUPPORT_UNITLOG_REQUESTFILE_MODAL_DETAIL,
      UNITSETT_DELETE_MODAL_CANCEL,
      UNITSETT_SUPPORT_DISKMANAGEMENT_PROCEED,
      UNITSETT_SUPPORT_UNITLOG_UNITLOGFILE,
      UNITSETT_SUPPORT_UNITLOG_PLEASEWAIT,
      UNITSETT_SUPPORT_UNITLOG_DOWNLOAD,
      UNITSETT_TITLE
    } = translations

    const points = Object.keys(translations).filter(key =>
      /UNITSETT_SUPPORT_UNITLOG_INFOPOINT(\d+)/.test(key)
    )

    const backUrl = isSupportMenu
      ? `/${currentDevice.id}/settings/support/`
      : `/${currentDevice.id}/maintenance/`

    const { maintenanceUpdateRequired } = featureSupport

    return (
      <Wrapper>
        <PageAdaptable
          title={isSupportMenu ? UNITSETT_TITLE : MAINTENANCE_TITLE}
          menu={
            isSupportMenu ? (
              <DeviceSettingsMenu page={'support'} />
            ) : (
              <MaintenanceMenu page={'unit-log'} />
            )
          }
          isForm={false}
          onBack={() => navigate(backUrl)}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle mobile={isMobile} marginBottom={'40px'}>
              {!isMobile && isSupportMenu && (
                <BackButton onClick={() => navigate(backUrl)}>
                  <Icon>{'arrow_back'}</Icon>
                </BackButton>
              )}
              {UNITSETT_SUPPORT_UNITLOG}
            </PageTitle>
            <LoadingGif loading={loadingData} />
            <MaintenanceSupportedFeatures feature={'maintenance'} />
            {!maintenanceUpdateRequired && fileToDownload.id && (
              <React.Fragment>
                <ErrorMessage>{error}</ErrorMessage>
                <Subtitle>{UNITSETT_SUPPORT_UNITLOG_UNITLOGFILE}</Subtitle>
                <FileWrapper>
                  <div>
                    <FileName>{fileToDownload.filename}</FileName>
                    <FileDate>
                      {moment(fileToDownload.createdAt).format(
                        'DD/MM/YYYY HH:mm'
                      )}
                    </FileDate>
                  </div>
                  {fileToDownload.status === 'PENDING' && (
                    <FileDownloading>
                      {UNITSETT_SUPPORT_UNITLOG_PLEASEWAIT}
                    </FileDownloading>
                  )}
                  {fileToDownload.status !== 'PENDING' && (
                    <Button
                      buttonType={'secondary-default'}
                      buttonWidth={'120px'}
                      buttonHeight={'32px'}
                      margin={'0'}
                      smallShadow
                      onClick={() => getFile()}
                    >
                      {UNITSETT_SUPPORT_UNITLOG_DOWNLOAD}
                    </Button>
                  )}
                </FileWrapper>
              </React.Fragment>
            )}
            {currentDevice.connected &&
              !maintenanceUpdateRequired &&
              (fileToDownload.status !== 'PENDING' || !fileToDownload.id) && (
              <React.Fragment>
                <Subtitle>{UNITSETT_SUPPORT_UNITLOG_GETCOPY}</Subtitle>
                <PointsTitle>{UNITSETT_SUPPORT_UNITLOG_NOTE}</PointsTitle>
                <TextList>
                  {points.map(point => (
                    <Point key={point}>{translations[point]}</Point>
                  ))}
                </TextList>
                <ButtonWrapper isMobile={isMobile}>
                  <Button
                    id={'PrimaryButton_RequestFile'}
                    buttonWidth={'200px'}
                    margin={'42px 0'}
                    smallShadow
                    onClick={() =>
                      fileToDownload.id
                        ? setConfirmationModal(true)
                        : requestLogDownload()
                    }
                  >
                    <Icon>{'get_app'}</Icon>{' '}
                    {UNITSETT_SUPPORT_UNITLOG_REQUESTFILE}
                  </Button>
                </ButtonWrapper>
              </React.Fragment>
            )}
          </PageMainSection>
          <Modal
            open={confirmationModal}
            size={'small'}
            onClose={() => setConfirmationModal(false)}
          >
            <div>
              <ModalHeader red title={UNITSETT_SUPPORT_UNITLOG_REQUESTFILE} />
              <ModalContent padding size={'small'} mobile={isMobile}>
                {UNITSETT_SUPPORT_UNITLOG_REQUESTFILE_MODAL_DETAIL && (
                  <Typography variant={'body1'}>
                    {UNITSETT_SUPPORT_UNITLOG_REQUESTFILE_MODAL_DETAIL.replace(
                      '<filename>',
                      (fileToDownload.file && fileToDownload.file.filename) ||
                        '--'
                    )}
                  </Typography>
                )}
              </ModalContent>
              <ModalButtons>
                <Button
                  id={'PrimaryButton_Cancel'}
                  buttonType={'primary-cancel'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={() => setConfirmationModal(false)}
                >
                  {UNITSETT_DELETE_MODAL_CANCEL}
                </Button>
                <Button
                  id={'PrimaryButton_Proceed'}
                  buttonType={'primary-danger'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={() => requestLogDownload()}
                >
                  {UNITSETT_SUPPORT_DISKMANAGEMENT_PROCEED}
                </Button>
              </ModalButtons>
            </div>
          </Modal>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceUnitLog)

const Wrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  z-index: 10;

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
  `}
`

const PointsTitle = styled.p`
  color: #333f48;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin: 0;
`

const FileDownloading = styled.span`
  opacity: 0.6;
  color: #727e84;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
`
const FileWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(114, 126, 132, 0.1);
  padding: 8px;
  margin-bottom: 80px;
`

const FileDate = styled.p`
  color: #727e84;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  margin: 2px 0;
`

const FileName = styled.p`
  color: #424e54;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 2px 0;
`

const BackButton = styled.div`
  background: transparent;
  border: 0;
  display: inline-flex;
  padding-right: 16px;
  cursor: pointer;
`
