// @flow
import { type ChartVariables, type ChartFilters } from 'scenes/Chart/types'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'
import {
  MAP_TIME_FILTER,
  getChartOptions,
  initializeBarChart,
  getChartData
} from './utils'

import { ChartTooltip } from 'xstorage-components'

type Props = {
  variables: ChartVariables,
  filters: ChartFilters,
  metrics: any,
  fetching: boolean,
  ticksToHide: string,
  updated: boolean,
  translations: {}
}

class ChartPeriodStats extends Component<Props, void> {
  constructor (props) {
    super(props)

    initializeBarChart()
  }

  render () {
    const {
      variables,
      filters,
      metrics,
      fetching,
      ticksToHide,
      translations,
      updated
    } = this.props

    if (fetching) {
      return <Wrapper />
    }

    const timeOption = (filters && filters.timeOption) || 'today'

    const options = getChartOptions(
      MAP_TIME_FILTER[timeOption],
      updated,
      variables,
      metrics,
      filters,
      ticksToHide
    )
    const data = canvas =>
      getChartData(
        canvas,
        metrics,
        variables,
        MAP_TIME_FILTER[timeOption],
        translations,
        filters
      )

    return (
      <Wrapper>
        <Bar data={data} options={options} redraw />
        <ChartTooltip />
      </Wrapper>
    )
  }
}

export default Logic(ChartPeriodStats)

const Wrapper = styled.div`
  height: 350px;

  @media screen and (min-width: 1424px) {
    height: 500px;
  }
`
