// @flow
import moment from 'moment-timezone'
import { cloneDeep } from 'lodash'

export const EXPORT_INCLUDED_INFO_VARIABLES = [
  {
    key: 'TOTAL_HOUSE_CONSUMPTION',
    name: 'CHART_VAR_TOTALHOUSECONSUMPTION'
  },
  {
    key: 'CRITICAL_LOADS',
    name: 'CHART_VAR_CRITICALLOADS'
  },
  {
    key: 'NON_CRITICAL_LOADS',
    name: 'CHART_VAR_NONCRITICALLOADS'
  },
  {
    key: 'BATTERY_STATE_OF_CHARGE',
    name: 'CHART_VAR_BATTERYSTATEOFCHARGE'
  },
  {
    key: 'PHOTOVOLTAIC_PRODUCTION_AGG',
    name: 'CHART_VAR_PHOTOVOLTAICPRODUCTION'
  },
  {
    key: 'SELF_CONSUMPTION',
    name: 'CHART_VAR_SELFCONSUMPTION'
  },
  {
    key: 'SELF_SUFFICIENCY',
    name: 'CHART_VAR_SELFSUFFICIENCY'
  },
  {
    key: 'GRID_INJECTION',
    name: 'CHART_VAR_GRIDINJECTION'
  }
]

export const EXPORT_GRANULARITY_VARIABLES = [
  {
    val: 'minute',
    label: 'CHART_EXPORT_GRANULARITY_5MINUTE'
  },
  {
    val: 'daily',
    label: 'CHART_EXPORT_GRANULARITY_DAILY'
  },
  {
    val: 'weekly',
    label: 'CHART_EXPORT_GRANULARITY_WEEKLY'
  },
  {
    val: 'montly',
    label: 'CHART_EXPORT_GRANULARITY_MONTHLY'
  }
]

export const EXPORT_TIMEOPTION_GRANULARITY = [
  {
    timeOption: 'today',
    granularity: 'minute'
  },
  {
    timeOption: 'last-7',
    granularity: 'daily'
  },
  {
    timeOption: 'last-30',
    granularity: 'weekly'
  },
  {
    timeOption: 'last-year',
    granularity: 'montly'
  }
]

export const EXPORT_VARIABLES_INCLUDEDINFO = [
  {
    variable: 'totalHouseConsumption',
    includedInfo: 'TOTAL_HOUSE_CONSUMPTION'
  },
  {
    variable: 'criticalLoads',
    includedInfo: 'CRITICAL_LOADS'
  },
  {
    variable: 'nonCriticalLoads',
    includedInfo: 'NON_CRITICAL_LOADS'
  },
  {
    variable: 'batteryStateOfCharge',
    includedInfo: 'BATTERY_STATE_OF_CHARGE'
  },
  {
    variable: 'photovoltaicProduction',
    includedInfo: 'PHOTOVOLTAIC_PRODUCTION_AGG'
  },
  {
    variable: 'selfConsumption',
    includedInfo: 'SELF_CONSUMPTION'
  },
  {
    variable: 'selfSufficient',
    includedInfo: 'SELF_SUFFICIENCY'
  },
  {
    variable: 'gridInjection',
    includedInfo: 'GRID_INJECTION'
  }
]

export const granularityOptionsFilter = (startDate, endDate) => {
  const weeksDiff = moment(endDate, 'DD/MM/YYYY').diff(
    moment(startDate, 'DD/MM/YYYY'),
    'weeks'
  )
  const daysDiff = moment(endDate, 'DD/MM/YYYY').diff(
    moment(startDate, 'DD/MM/YYYY'),
    'days'
  )

  const granularityOptions = cloneDeep(EXPORT_GRANULARITY_VARIABLES)

  if (!startDate || !endDate) {
    return null
  }

  if (weeksDiff > 3) {
    return granularityOptions
  } else if (weeksDiff < 4 && weeksDiff > 0) {
    const index = granularityOptions
      .map(x => {
        return x.val
      })
      .indexOf('monthly')

    granularityOptions.splice(index, 1)
    return granularityOptions
  } else if (weeksDiff === 0) {
    if (daysDiff > 0) {
      const index = granularityOptions
        .map(x => {
          return x.val
        })
        .indexOf('weekly')

      granularityOptions.splice(index, 2)
      return granularityOptions
    } else {
      const index = granularityOptions
        .map(x => {
          return x.val
        })
        .indexOf('daily')

      granularityOptions.splice(index, 3)
      return granularityOptions
    }
  } else {
    return granularityOptions
  }
}
