/* eslint-disable camelcase */
// @flow
import React, { Component, Fragment } from 'react'
import { type User } from 'types/api'
import Logic from './logic'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import Typography from '@material-ui/core/Typography'
import { replaceMultiple } from './utils'
import { removeUserSession } from 'utils'
import FormGeneralError from 'containers/FormGeneralError'
import FormFieldError from 'containers/FormFieldError'
import ExternalAppAccepted from 'containers/ExternalAppAccepted'

import {
  LogoEatonWhite,
  LogoXstorageWhite,
  BatteryBlue,
  SpaceDiv,
  Form,
  CheckboxesField,
  Loading
} from 'xstorage-components'

type Props = {
  translations: {
    EXTAPP_CHOOSEUNITS_TITLE: string,
    EXTAPP_CHOOSEUNITS_SUBMITUNITSBUTTON: string,
    EXTAPP_CHOOSEUNITS_CANCELBUTTON: string,
    EXTAPP_CHOOSEUNITS_SWITCHACCOUNT: string,
    EXTAPP_ACCEPTREQUEST_NOAPPFOUND: string,
    EXTAPP_ACCEPTREQUEST_NOAPPFOUND_DESC: string,
    EXTAPP_CHOOSEUNITS_DESC_MULTIPlEUNITS: string,
    EXTAPP_CHOOSEUNITS_DESC_ONEUNIT: string,
    EXTAPP_CHOOSEUNITS_DESC_NOUNITS: string
  },
  dirty: boolean,
  error: string,
  form: {
    unitsGranted: {
      value: [],
      valid: boolean,
      message?: string
    }
  },
  user: User,
  devicesList: [],
  appInfo: {
    scope: string,
    appName: string,
    organization: string
  },
  loading: boolean,
  isAppAccepted: boolean
}

class ExternalAppChooseUnits extends Component<Props> {
  actions: {
    change: Function,
    submit: Function
  }

  render () {
    const {
      translations,
      form,
      dirty,
      error,
      user,
      devicesList,
      appInfo,
      loading,
      isAppAccepted
    } = this.props

    const {
      EXTAPP_CHOOSEUNITS_TITLE,
      EXTAPP_CHOOSEUNITS_SUBMITUNITSBUTTON,
      EXTAPP_CHOOSEUNITS_SWITCHACCOUNT,
      EXTAPP_ACCEPTREQUEST_NOAPPFOUND,
      EXTAPP_ACCEPTREQUEST_NOAPPFOUND_DESC,
      EXTAPP_CHOOSEUNITS_DESC_MULTIPlEUNITS,
      EXTAPP_CHOOSEUNITS_DESC_ONEUNIT,
      EXTAPP_CHOOSEUNITS_DESC_NOUNITS
    } = translations

    const { unitsGranted } = form
    const { change, submit } = this.actions
    const {
      scope,
      appName,
      organization
    } = appInfo

    const hasAppInfo = appInfo && Object.keys(appInfo).length > 0
    const numberUnits = appInfo && devicesList.length

    const descNumberUnits = numberUnits === 0
      ? EXTAPP_CHOOSEUNITS_DESC_NOUNITS
      : numberUnits === 1 ? EXTAPP_CHOOSEUNITS_DESC_ONEUNIT
        : EXTAPP_CHOOSEUNITS_DESC_MULTIPlEUNITS

    const chooseUnitsDescription = replaceMultiple(
      descNumberUnits,
      /<PERMISSION_LEVEL>|<APP_NAME>/gi,
      {
        '<PERMISSION_LEVEL>': translations[`EXTAPP_${scope}`],
        '<APP_NAME>': `${appName}, ${organization}®`
      }
    )

    return (
      <Wrapper>
        {loading && <Loading />}
        <Header>
          <LogoEatonWhite size={{ width: 80, height: 20 }} />
          <XstorageLogo size={{ width: 60, height: 16 }} />
          <UserDiv>
            <Typography
              color={'secondary'}
              variant={'body2'}
            >
              {user.firstName} {user.lastName}
            </Typography>
            <Logout
              onClick={() => removeUserSession()}
            >
              {EXTAPP_CHOOSEUNITS_SWITCHACCOUNT}
            </Logout>
          </UserDiv>
        </Header>
        <Body>
          <SpaceDiv height={'52px'} />
          <BatteryBlue />
          {hasAppInfo &&
            <Fragment>
              {isAppAccepted &&
                <ExternalAppAccepted appInfo={appInfo} />
              }
              {!isAppAccepted &&
                <Fragment>
                  <Title>{EXTAPP_CHOOSEUNITS_TITLE}</Title>
                  {numberUnits === 0 &&
                    <AlignDescription>
                      <Typography variant={'body1'} color={'textPrimary'}>
                        {ReactHtmlParser(chooseUnitsDescription)}
                      </Typography>
                    </AlignDescription>
                  }
                  {numberUnits > 0 &&
                    <Fragment>
                      <Form formPadding={'0'}>
                        <FormGeneralError dirty={dirty} error={error} full />
                        <SpaceDiv height={'15px'} />
                        <AlignDescription>
                          <Typography variant={'body1'} color={'textPrimary'}>
                            {ReactHtmlParser(chooseUnitsDescription)}
                          </Typography>
                        </AlignDescription>
                        <Form.Row>
                          <CheckboxesField
                            options={devicesList}
                            selected={unitsGranted.value}
                            onChange={value => change({ unitsGranted: value })}
                            maxHeight={'30vh'}
                          />
                          <FormFieldError
                            id={'Granularity'}
                            showError={dirty && !unitsGranted.valid}
                            errorMessage={unitsGranted.message}
                          />
                        </Form.Row>
                      </Form>
                      <Button
                        disabled={!unitsGranted.valid}
                        onClick={submit}
                      >
                        {EXTAPP_CHOOSEUNITS_SUBMITUNITSBUTTON}
                      </Button>
                    </Fragment>
                  }
                </Fragment>
              }
            </Fragment>
          }
          {!hasAppInfo &&
            <Fragment>
              <Title>{EXTAPP_ACCEPTREQUEST_NOAPPFOUND}</Title>
              <AlignDescription>
                <Typography variant={'body1'} color={'textPrimary'}>
                  {EXTAPP_ACCEPTREQUEST_NOAPPFOUND_DESC}
                </Typography>
              </AlignDescription>
            </Fragment>
          }
        </Body>
      </Wrapper>
    )
  }
}

export default Logic(ExternalAppChooseUnits)

const Wrapper = styled.div``

const Header = styled.div`
  background-color: #007bc1;
  padding: 22px;
`

const XstorageLogo = styled(LogoXstorageWhite)`
  padding-left: 8px;
`

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
`

const Title = styled.h1`
  color: #007bc1;
  font-family: Eaton;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
  text-align: center;
`

const Button = styled.button`
  height: 48px;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  background-color: #007BC1;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  border: 0px;
  cursor: pointer;
`

const UserDiv = styled.div`
  float: right;  
  display: flex;
  flex-direction: column;
`

const Logout = styled.a`
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: #FFF;
  cursor: pointer;
`

const AlignDescription = styled.div`
  display: flex;
  justify-content: center;
  width: 75%;
  text-align: center;
  margin: 0 auto;
`
