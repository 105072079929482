// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import MultipleEnergyFlow from './images/MultipleEnergyFlow'
import { Icon } from '@material-ui/core'

import {
  ComponentShadowButton,
  MultiPVEnergyComponent,
  IcSolarPanel,
  IcSolarPanelExternal,
  EnergyFlowLabel
} from 'xstorage-components'

type Props = {
  onClose: Function,
  multipleSolarPanels: any,
  mobile: boolean
}

export default class EnergySolarMultipleFlow extends Component<Props, void> {
  render () {
    const { onClose, multipleSolarPanels, mobile } = this.props

    const bothPanelsOn =
      multipleSolarPanels.dcPvRole !== 'NONE' &&
      multipleSolarPanels.acPvRole !== 'NONE'
    const acPanelOn =
      multipleSolarPanels.dcPvRole === 'NONE' &&
      multipleSolarPanels.acPvRole !== 'NONE'
    const dcPanelOn =
      multipleSolarPanels.dcPvRole !== 'NONE' &&
      multipleSolarPanels.acPvRole === 'NONE'

    return (
      <Wrapper>
        <MultiPVEnergyComponent>
          <ImageSolarPanelExternal />
          <ImageSolarPanel />

          {bothPanelsOn && (
            <EnergyFlowContainer>
              <MultipleEnergyFlow
                acPanelOn={acPanelOn}
                dcPanelOn={dcPanelOn}
                bothPanelsOn={bothPanelsOn}
              />
              <FlowLabel imageIndex={1}>
                <EnergyFlowLabel
                  direction={'up'}
                  value={multipleSolarPanels.acPvValue}
                />
              </FlowLabel>
              <FlowLabel>
                <EnergyFlowLabel
                  direction={'up'}
                  value={multipleSolarPanels.dcPvValue}
                />
              </FlowLabel>
            </EnergyFlowContainer>
          )}

          {acPanelOn && (
            <EnergyFlowContainer>
              <MultipleEnergyFlow
                acPanelOn={acPanelOn}
                dcPanelOn={dcPanelOn}
                bothPanelsOn={bothPanelsOn}
              />
              <FlowLabel imageIndex={1}>
                <EnergyFlowLabel
                  direction={'up'}
                  value={multipleSolarPanels.acPvValue}
                />
              </FlowLabel>
            </EnergyFlowContainer>
          )}

          {dcPanelOn && (
            <EnergyFlowContainer>
              <MultipleEnergyFlow
                acPanelOn={acPanelOn}
                dcPanelOn={dcPanelOn}
                bothPanelsOn={bothPanelsOn}
              />
              <FlowLabel>
                <EnergyFlowLabel
                  direction={'up'}
                  value={multipleSolarPanels.dcPvValue}
                />
              </FlowLabel>
            </EnergyFlowContainer>
          )}
        </MultiPVEnergyComponent>
        <ComponentShadowButton onClose={onClose} mobile={mobile} closeButton>
          <Icon>{'close'}</Icon>
        </ComponentShadowButton>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div``

const ImageSolarPanel = styled(props => <IcSolarPanel {...props} />)`
  position: absolute !important;
  top: 35%;
  left: 25%;
`

const ImageSolarPanelExternal = styled(props => (
  <IcSolarPanelExternal {...props} />
))`
  position: absolute !important;
  top: 35%;
  left: 58%;
`

const EnergyFlowContainer = styled.div`
  // position: relative;
`

const FlowLabel = styled.div`
  position: absolute;
  width: 80px;
  z-index: 1000;

  ${({ imageIndex }) =>
    imageIndex
      ? `
    left: 70%;
    top: 55%;
  `
      : `
    left: 5%;
    top: 55%;
  `};
`
