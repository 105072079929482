// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Logic from './logic'
import {
  Arrow,
  Controls,
  Dot,
  Navigation,
  StepIndicator,
  SkipButton
} from 'xstorage-components'
type Props = {}

class TourCard extends PureComponent<Props, void> {
  render () {
    const {
      current,
      content,
      totalSteps,
      gotoStep,
      close,
      translations
    } = this.props
    const { HELP_TOUR_SKIP, HELP_TOUR_CLOSE } = translations

    return (
      <Wrapper>
        <StepIndicator>
          <span>{current + 1}</span>
        </StepIndicator>
        <SkipButton onClick={close}>
          {current + 1 === totalSteps ? HELP_TOUR_CLOSE : HELP_TOUR_SKIP}
        </SkipButton>
        {content}
        <Controls data-tour-elem='controls' className='CustomHelper__controls'>
          <Arrow
            onClick={() => gotoStep(current - 1)}
            disabled={current === 0}
            className='CustomHelper__navArrow'
          />
          <Navigation data-tour-elem='navigation'>
            {Array.from(Array(totalSteps).keys()).map((li, i) => (
              <Dot
                key={li}
                onClick={() => current !== i && gotoStep(i)}
                current={current}
                index={i}
                disabled={current === i}
                showNumber
                data-tour-elem='dot'
              />
            ))}
          </Navigation>
          <Arrow
            onClick={() => gotoStep(current + 1)}
            disabled={current === totalSteps - 1}
            className='CustomHelper__navArrow'
            inverted
          />
        </Controls>
      </Wrapper>
    )
  }
}

export default Logic(TourCard)

const Wrapper = styled.div`
  border-radius: 6px;
  background-color: #fdfdfd;
`
