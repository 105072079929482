// @flow
import { kea } from 'kea'
import { put, call, delay } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { log, generateUrl } from 'utils'
import { getDateDiff } from './utils'
import { getApiErrorKey } from 'apiErrors'
import { API_BASE_URL, RESYNC_METRICS_DELAY } from 'config'
import AppLogic from 'containers/App/logic'

// eslint-disable-next-line max-len
const SYNCMETRICS_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/resync-metrics`

export default kea({
  path: () => ['scenes', 'MaintenanceMetricsSync'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'isMobile',
        'currentDeviceId',
        'currentDevice'
      ],

      ({ scenes }) => scenes.containers.MaintenanceSupportedFeatures, [
        'featureSupport'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    getSyncData: () => true,
    setSyncData: (data) => (data),
    setSyncPanel: (status) => (status),
    setLoadingData: (status) => (status),
    setLoading: loading => loading,
    error: (error) => (error),
    reset: () => true,
    updateSyncData: () => true
  }),

  reducers: ({ actions }) => ({
    syncData: [{}, PropTypes.object, {
      [actions.setSyncData]: (state, payload) => payload,
      [actions.reset]: () => ({ })
    }],

    syncPanel: [false, PropTypes.bool, {
      [actions.setSyncPanel]: (state, payload) => payload,
      [actions.reset]: () => false
    }],

    loading: [false, PropTypes.bool, {
      [actions.setLoading]: (state, payload) => payload,
      [actions.reset]: () => false,
      [actions.getSyncData]: () => true,
      [actions.setSyncData]: () => false,
      [actions.error]: () => false
    }],

    loadingData: [false, PropTypes.bool, {
      [actions.setLoadingData]: (state, payload) => payload,
      [actions.reset]: () => false
    }],

    error: [null, PropTypes.string, {
      [actions.error]: (state, payload) => payload,
      [actions.getSyncData]: () => null,
      [actions.reset]: () => null
    }]
  }),

  start: function * () {
    log('[XS-MetricsSync] Start Scene', 'yellow')

    const { getSyncData } = this.actions

    yield put(getSyncData())
  },

  stop: function * () {
    log('[XS-MetricsSync] Stop Scene', 'yellow')

    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getSyncData]: workers.getSyncData,
    [actions.updateSyncData]: workers.getSyncData
  }),

  workers: {
    * getSyncData () {
      const {
        setSyncData,
        setLoadingData,
        updateSyncData,
        setLoading
      } = this.actions

      const translations = yield this.get('translations')
      const currentDeviceId = yield this.get('currentDeviceId')

      yield put(setLoadingData(true))

      try {
        const config = {
          headers: { 'content-type': 'application/json' }
        }

        // eslint-disable-next-line max-len
        const url = generateUrl(SYNCMETRICS_ENDPOINT, { deviceId: currentDeviceId })
        const response = yield call(axios.get, url, config)
        const { successful, result } = response.data

        if (successful) {
          // eslint-disable-next-line max-len
          const syncDateFormat = moment(result.timestamp).format('DD/MM/YYYY HH:mm:ss')
          const syncDateDiff = getDateDiff(result.timestamp, translations)

          yield put(setSyncData({
            date: syncDateFormat,
            diffDate: syncDateDiff,
            status: result.status
          }))
          yield put(setLoadingData(false))

          yield delay(RESYNC_METRICS_DELAY)
          yield put(updateSyncData())
        }
        yield put(setLoading(false))
      } catch (errorMessage) {
        yield put(setLoadingData(false))
        if (errorMessage.response) {
          const { error } = this.actions
          const { data } = errorMessage.response
          const errorKey = getApiErrorKey(data.message)

          yield put(error(translations[errorKey] || ''))
        } else {
          console.log(errorMessage)
        }
      }
    }
  }

})
