/**
 * UserProfileInfo Container Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */

// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'UserProfileInfo'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'user',
        'authProvider',
        'configs'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'editUserProfile'
      ]
    ]
  },

  actions: () => ({
    editProfile: () => true
  }),

  reducers: ({ actions }) => ({

    loading: [false, PropTypes.bool, {
      [actions.editProfile]: (state, payload) => true
    }]

  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.editProfile]: workers.editProfile
  }),

  workers: {

    * editProfile () {
      const { editUserProfile } = this.actions

      yield put(editUserProfile())
    }
  }

})
