// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'

import { PageTitle, BackButton, SuccessSavedAlert } from 'xstorage-components'

type Props = {
  translations: any,
  isMobile: boolean,
  currentDeviceId: string,
  successMessage: boolean
}

export class DeviceSettings extends Component<Props> {
  actions: any

  render () {
    const {
      translations,
      isMobile,
      currentDeviceId,
      successMessage
    } = this.props

    const { navigate } = this.actions

    const { UNITSETT_TITLE, UNITSETT_GENERAL_SAVED_SUCCESS } = translations

    return (
      <Wrapper>
        <BackButton
          id={'BackButton'}
          onClick={() => navigate(`/${currentDeviceId}/more/`)}
        />
        <SuccessSavedAlert
          mobile={isMobile}
          out={!successMessage}
          icon={<Icon>{'check'}</Icon>}
          message={UNITSETT_GENERAL_SAVED_SUCCESS}
        />
        <PageTitle mobile={isMobile} blue>
          {UNITSETT_TITLE}
        </PageTitle>
        <DeviceSettingsMenu />
      </Wrapper>
    )
  }
}

export default Logic(DeviceSettings)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`
