/* eslint-disable max-len */
// @flow
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Logic from './logic'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import TimeAgo from 'javascript-time-ago'
import Typography from '@material-ui/core/Typography'
import moment from 'moment-timezone'
import keys from 'lodash/keys'
import { convertMillisToSec, convertFromMilitaryTime } from 'utils'
import { getCommandTranslation, getUnitForCommandParam } from './utils'

import en from 'javascript-time-ago/locale/en'
import fr from 'javascript-time-ago/locale/fr'
import de from 'javascript-time-ago/locale/de'
import it from 'javascript-time-ago/locale/it'

import OperationModeParams from 'containers/OperationModeParams'
import UserPermission from 'containers/UserPermission'

import {
  Form,
  Button,
  Typography as SharedTypography,
  FormSlider,
  LoadingCircular
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  onCancel: Function,
  loading: boolean,
  form: any,
  operationMode: any,
  translations: any,
  isManualMode: boolean,
  isDefaultMode: boolean,
  isScheduleMode: boolean,
  timeLeft: any,
  defaultMode: any,
  language: string
}

type State = {}

class OperationModeForm extends Component<Props, State> {
  actions: any
  detailsHeight: number = 0

  getSliderMarks = () => {
    let sliderMarks = {}
    for (let i = 0; i < 13; i++) {
      sliderMarks[i] = i
    }

    return sliderMarks
  }

  componentDidUpdate () {
    const node: any = ReactDOM.findDOMNode(this.refs.details)
    this.detailsHeight = node ? node.scrollHeight : 0
  }

  render () {
    const {
      isMobile,
      onCancel,
      operationMode,
      defaultMode,
      loading,
      form,
      translations,
      isManualMode,
      isDefaultMode,
      isScheduleMode,
      timeLeft,
      language
    } = this.props

    const { change, submit } = this.actions

    if (loading) {
      return <LoadingCircular />
    }

    const {
      DASH_OPERATION_MODE_UNIT_WORK_DEFAULT_MODE,
      DASH_OPERATION_MODE_MANUALLY_CHANGED_BY,
      DASH_OPERATION_MODE_SCHEDULED_BY,
      DASH_OPERATION_MODE_END_TIME,
      DASH_OPERATION_MODE_CHANGE,
      DASH_OPERATION_MODE_DURATION,
      DASH_OPERATION_MODE_HOURS,
      DASH_OPERATION_MODE_CANCEL,
      DASH_OPERATION_MODE_SAVE
    } = translations

    const { duration } = form

    // TODO: Check to add languages dynamically or when is added more languages
    // maybe we can with https://github.com/jamiebuilds/react-loadable
    // to the system we have to import here
    TimeAgo.locale(en)
    TimeAgo.locale(fr)
    TimeAgo.locale(de)
    TimeAgo.locale(it)

    // set current language
    const timeAgo = new TimeAgo(language)

    return (
      <Wrapper>
        {operationMode.type && (
          <DetailsContainer ref='details'>
            <DetailsRow container spacing={8}>
              <Grid item xs={12}>
                <TypographyOperationMode id='Typography_OperationMode' variant={'h4'}>
                  {getCommandTranslation(
                    translations,
                    operationMode.command,
                    operationMode.parameters
                  )}
                </TypographyOperationMode>
                {isDefaultMode && (
                  <LightText gutterBottom>
                    {DASH_OPERATION_MODE_UNIT_WORK_DEFAULT_MODE}
                  </LightText>
                )}
                {operationMode.user && isManualMode && (
                  <LightText gutterBottom>
                    {DASH_OPERATION_MODE_MANUALLY_CHANGED_BY}{' '}
                    {operationMode.user.firstName}&nbsp;
                    <i>
                      {timeAgo.format(
                        moment
                          .unix(convertMillisToSec(operationMode.updatedAt))
                          .toDate()
                      )}
                    </i>
                  </LightText>
                )}
                {operationMode.user && isScheduleMode && (
                  <LightText gutterBottom>
                    {DASH_OPERATION_MODE_SCHEDULED_BY}{' '}
                    {operationMode.user.firstName}
                  </LightText>
                )}
              </Grid>
            </DetailsRow>
            <DetailsRow container spacing={8}>
              {!!operationMode.endTime && timeLeft.left > 0 && (
                <Grid item xs={6}>
                  <SharedTypography>
                    <b>{DASH_OPERATION_MODE_END_TIME}</b>
                  </SharedTypography>
                  <SharedTypography>
                    {convertFromMilitaryTime(operationMode.endTime)} (
                    {`${timeLeft.label} left`})
                  </SharedTypography>
                </Grid>
              )}
              {keys(operationMode.parameters)
                .filter(key => key !== 'action')
                .map(key => (
                  <Grid key={key} item xs={6}>
                    <SharedTypography>
                      <b>{translations[`OPERATION_MODE_PARAM_${key.toUpperCase()}`]}:</b>
                    </SharedTypography>
                    <SharedTypography>
                      {operationMode.parameters[key]}
                      {getUnitForCommandParam(key)}
                    </SharedTypography>
                  </Grid>
                ))}
            </DetailsRow>
          </DetailsContainer>
        )}
        <UserPermission permission={'CHANGE_OPERATION_MODE'}>
          <OperationModeFormTitle>
            {DASH_OPERATION_MODE_CHANGE}
          </OperationModeFormTitle>
          <Form
            mobile={isMobile}
            position={!isMobile && 'absolute'}
            leftPosition={!isMobile && '0'}
            containerHeight={
              !isMobile && `calc(100vh - (220px + ${this.detailsHeight}px) )`
            }
            formPadding={'0px'}
          >
            <OperationModeParams
              change={change}
              form={form}
              defaultMode={defaultMode}
              full
            />
            <Form.Row full>
              <FormSlider
                id={'Duration'}
                label={DASH_OPERATION_MODE_DURATION}
                value={
                  <LabelSlider>
                    {duration.value}
                    <span> {DASH_OPERATION_MODE_HOURS}</span>
                  </LabelSlider>
                }
                hideBottomScale
                sliderMax={12}
                sliderMin={1}
                sliderValue={duration.value}
                onChange={value => change({ duration: value })}
                divideSlider={13}
              />
            </Form.Row>
          </Form>
          <Form.Buttons fixedSideBar mobile={isMobile}>
            <Button
              id={'PrimaryButton_Cancel'}
              margin={'0 12px'}
              buttonType={'primary-cancel'}
              buttonWidth={'163px'}
              onClick={onCancel}
            >
              {DASH_OPERATION_MODE_CANCEL}
            </Button>
            <Button
              id={'PrimaryButton_Save'}
              margin={'0 12px'}
              smallShadow
              buttonWidth={'163px'}
              onClick={submit}
            >
              {DASH_OPERATION_MODE_SAVE}
            </Button>
          </Form.Buttons>
        </UserPermission>
      </Wrapper>
    )
  }
}

export default Logic(OperationModeForm)

const Wrapper = styled.div``
const LabelSlider = styled.label`
  margin-left: 8px;
`

const DetailsContainer = styled.div`
  background: #007bc1;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  padding: 20px;
  margin-top: 26px;

  @media screen and (min-width: 1024px) {
    margin-top: -6px;
  }

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    left: -50px;
    width: calc(100% + 100px);
    padding: 20px 50px;
  }

  * {
    color: #fdfdfd !important;
  }

  h2 {
    font-size: 14px !important;
    line-height: 14px !important;
  }

  p {
    font-size: 12px;
  }
`

const DetailsRow = styled(Grid)`
  &&&& {
    margin: 4px -4px;
  }
`

const LightText = styled(Typography)`
  opacity: 0.5;
`

const OperationModeFormTitle = styled(Typography)`
  &&&& {
    color: #007bc1;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    margin-top: 24px;
    margin-bottom: 8px;
  }
`

const TypographyOperationMode = styled(Typography)`
  &&&& {
    font-size: 14px;
    font-weight: 800;
    line-height: 14px;
  }
`
