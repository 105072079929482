// @flow
import { type Device } from 'types/api'
import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'

import { API_BASE_URL } from 'config'
import { generateUrl } from 'utils'
import { checkRolePermission } from 'containers/UserPermission/utils'
import AppLogic from 'containers/App/logic'

const DEVICE_POWER_COMMAND_ENDPOINT = `${API_BASE_URL}/devices/{id}/power`

export default kea({
  path: () => ['scenes', 'containers', 'DeviceSettingsMenu'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDevice',
        'currentDeviceId',
        'configs',
        'userRole'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    changePowerState: (state) => ({ state }),
    response: () => ({ }),
    error: (error) => ({ error }),
    reset: () => false
  }),

  reducers: ({ actions }) => ({
    loading: [false, PropTypes.bool, {
      [actions.changePowerState]: () => true,
      [actions.response]: () => false,
      [actions.error]: () => false
    }],

    error: [null, PropTypes.string, {
      [actions.changePowerState]: () => null,
      [actions.error]: (state, payload) => payload.error,
      [actions.reset]: () => null
    }]
  }),

  selectors: ({ selectors }) => ({

    /** put user permission on a prop */
    userCanPowerUnit: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('UNIT_POWER_ON_OFF', userRole),
      PropTypes.bool
    ],

    /** put user permission on a prop */
    userCanChangeOwner: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('CHANGE_UNIT_OWNER', userRole),
      PropTypes.bool
    ],

    /** put user permission on a prop */
    userCanUpdate: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('UPDATE_UNIT', userRole),
      PropTypes.bool
    ]

  }),

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.changePowerState]: workers.changePowerState
  }),

  workers: {
    * changePowerState (action) {
      const { state } = action.payload

      const { response, error } = this.actions

      try {
        const currentDevice: Device = yield this.get('currentDevice')

        const data = {
          parameters: { state }
        }

        // eslint-disable-next-line max-len
        const url = generateUrl(DEVICE_POWER_COMMAND_ENDPOINT, { id: currentDevice.id })
        yield call(axios.post, url, data)

        yield put(response())
        yield put(AppLogic.actions.fetchDevices())
      } catch (err) {
        console.log(err)

        // get error translation
        const translations: any = yield this.get('translations')
        yield put(error(translations['UNITSETT_ERR_UNIT_POWER']))

        yield put(AppLogic.actions.fetchDevices())
      }
    }
  }

})
