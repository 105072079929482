/* eslint-disable no-console */
/* eslint-disable no-undef */

/**
 * Index where the application begins
 * Where we configure the Hot Reloading
 */
import { AppContainer } from 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import { SENTRY_URL } from 'config'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { Theme, GlobalStyle } from 'globalStyles'
import { ConnectedRouter } from 'react-router-redux'
import { Store, history } from 'store'

// External Styling
import './index.css'
import 'rc-slider/assets/index.css'
import 'react-times/css/material/default.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datetime/css/react-datetime.css'

import App from './containers/App'

const app = document.getElementById('root')

// Version info for debugging
const VERSION = process.env.VERSION && process.env.VERSION.replace(/"/g, '')
const BUILD_DATE = process.env.BUILD_DATE
console.log(
  `%cCurrent Version: ${VERSION} built in ${BUILD_DATE}`,
  'color:green'
)

// Sentry
if (process.env.APP_ENV !== 'local') {
  const sentryConfig = {
    release: VERSION,
    environment: process.env.APP_ENV
  }

  const Raven = require('raven-js')
  Raven.config(SENTRY_URL, sentryConfig).install()
}

// const Routes = require('./routes').default

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <MuiThemeProvider theme={Theme}>
        <Provider store={Store}>
          <ConnectedRouter history={history}>
            <Component />
          </ConnectedRouter>
        </Provider>
        <GlobalStyle />
      </MuiThemeProvider>
    </AppContainer>,
    app
  )
}

export default render(App)
// render(Routes)

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    const Next = require('./routes').default
    render(Next)
  })
}
