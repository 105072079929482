// @flow

/**
 * Returns the translation key for given user role
 *
 * @param {*} user
 */
export const getUserRoleLabel = (invite: any, translations: any) => {
  let role = 'UNITSETT_USERSPERM_USERINFO_ROLE_VIEW'
  if (invite.owner) {
    role = 'UNITSETT_USERSPERM_STATUS_OWNER'
  } else if (invite.technician) {
    role = 'UNITSETT_USERSPERM_USERINFO_ROLE_TECH'
  } else if (invite.roleManagement) {
    role = 'UNITSETT_USERSPERM_USERINFO_ROLE_ACESS'
  } else if (invite.write) {
    role = 'UNITSETT_USERSPERM_USERINFO_ROLE_CONF'
  }

  return translations[role] ? translations[role] : role
}
