/**
 * App Component
 * This is the base component for the application
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 * @review Joana Garrido <jgarrido@ubiwhere.com>
 */

// @flow
import { type Device, type User } from 'types/api'
import { type GeneralConfigs } from 'types/global'

import axios from 'axios'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { mapValues, toArray, isFunction, find } from 'lodash'
import moment from 'moment-timezone'
import MockAdapter from 'axios-mock-adapter'

import {
  API_BASE_URL,
  BASE_URL,
  FAKE_ID_TOKEN,
  FETCH_DEVICE_INFO_DISCONNECTED_DELAY,
  FETCH_DEVICE_INFO_CONNECTED_DELAY,
  FETCH_WEATHER_DELAY,
  TECHNICIAN_USER_TYPE,
  CONSUMER_USER_TYPE,
  GERMANY_COUNTRY_ID,
  DEFAULT_UNITS
} from 'config'

import {
  getRoute,
  log,
  getTranslations,
  axiosAuthInterceptor,
  mockSwaggerSpecs,
  generateUrl,
  getUserRole,
  removeUserSession
} from 'utils'
import { kea } from 'kea'
import { put, call, delay } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { getRoutesForUser } from 'routes'
import {
  AuthService,
  routeForInvalidUser,
  getDefaultConfigs,
  generateTranslationComponent,
  APPLOADING_DEFAULTSTATUS,
  APPLOADING_RESETSTATUS
} from './utils'

const VERSION = process.env.VERSION && process.env.VERSION.replace('"', '')

const routeSelector = state => state.router

const DEVICES_ENDPOINT: string = `${API_BASE_URL}/devices`
const DEVICE_ENDPOINT: string = `${API_BASE_URL}/devices/{id}`
const AUTH_ENDPOINT: string = `${API_BASE_URL}/auth`
const ME_ENDPOINT: string = `${API_BASE_URL}/users/me`
const FETCH_LANGUAGES_ENDPOINT: string = `${API_BASE_URL}/internal/languages`
const WEATHER_ENDPOINT = `${API_BASE_URL}/external/weather`

const MAX_FAILURES: number = 3

export default kea({
  path: () => ['scenes', 'app'],

  connect: {
    props: [routeSelector, ['location']]
  },

  actions: () => ({
    start: () => true,
    navigate: path => ({ path }),
    setMobileState: state => ({ state }),
    setTranslations: translations => ({ translations }),
    setLanguage: language => ({ language }),
    fetchDefaultLanguage: () => ({}),

    fetchDevices: () => ({}),
    setDevices: devices => ({ devices }),
    fetchCurrentDeviceInfo: () => ({}),
    setCurrentDeviceInfo: info => ({ info }),
    setAppError: error => ({ error }),

    setCurrentUser: user => ({ user }),
    getCurrentUser: () => ({}),
    checkLoggedUser: () => ({}),
    logout: () => ({}),
    login: () => ({}),
    editUserProfile: () => ({}),
    changeUserPassword: () => ({}),
    addFailure: () => ({}),
    setAppLoadingStatus: status => ({ status }),
    resetAppLoadingStatus: () => true,
    changeConfig: config => ({ config }),
    fetchWeatherInfo: () => true,
    setWeatherInfo: info => ({ info }),
    setShowHelpTour: status => status
  }),

  reducers: ({ actions }) => ({
    user: [
      null,
      PropTypes.object,
      {
        [actions.setCurrentUser]: (state, payload) => payload.user,
        [actions.logout]: (state, payload) => null
      }
    ],

    configs: [
      getDefaultConfigs(),
      PropTypes.object,
      {
        [actions.changeConfig]: (state, payload) => ({
          ...state,
          ...payload.config
        })
      }
    ],

    language: [
      '',
      PropTypes.string,
      {
        [actions.setLanguage]: (state, payload) => payload.language
      }
    ],

    devices: [
      [],
      PropTypes.array,
      {
        [actions.setDevices]: (state, payload) => payload.devices
      }
    ],

    waitForDevices: [
      false,
      PropTypes.bool,
      {
        [actions.fetchDevices]: () => true,
        [actions.setDevices]: () => false,
        [actions.setAppError]: () => false
      }
    ],

    currentDevice: [
      {},
      PropTypes.object,
      {
        [actions.setCurrentDeviceInfo]: (state, payload) => payload.info
      }
    ],

    appLoadingStatus: [
      APPLOADING_DEFAULTSTATUS,
      PropTypes.object,
      {
        [actions.setAppLoadingStatus]: (state, payload) => ({
          ...state,
          ...payload.status
        }),
        [actions.logout]: () => APPLOADING_DEFAULTSTATUS,
        [actions.resetAppLoadingStatus]: () => APPLOADING_RESETSTATUS,
        [actions.setAppError]: () => APPLOADING_RESETSTATUS,
        [actions.start]: () => APPLOADING_DEFAULTSTATUS
      }
    ],

    appError: [
      null,
      PropTypes.string,
      {
        [actions.setAppError]: (state, payload) => payload.error
      }
    ],

    isMobile: [
      true,
      PropTypes.bool,
      {
        [actions.setMobileState]: (state, payload) => payload.state
      }
    ],

    translations: [
      {},
      PropTypes.object,
      {
        [actions.setTranslations]: (state, payload) => payload.translations
      }
    ],

    numFailures: [
      0,
      PropTypes.number,
      {
        [actions.addFailure]: (state, payload) => state + 1,
        [actions.setDevices]: (state, payload) => 0
      }
    ],

    weatherInfo: [
      null,
      PropTypes.object,
      {
        [actions.fetchWeatherInfo]: (state, payload) => null,
        [actions.setWeatherInfo]: (state, payload) => payload.info
      }
    ],

    showHelpTour: [
      false,
      PropTypes.bool,
      {
        [actions.setShowHelpTour]: (state, payload) => payload
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    /** Dict of all available routes */
    routes: [
      () => [selectors.configs, selectors.user],
      (configs, user) => {
        const routes = getRoutesForUser(configs.userType)

        if (user && !user.id) {
          return routeForInvalidUser(routes)
        }

        return routes
      },
      PropTypes.object
    ],

    /** Gets the current route */
    currentRoute: [
      () => [routeSelector, selectors.routes, selectors.isMobile],
      (selectors, routes, isMobile) => {
        // to avoid error on tests
        const { location } = selectors || {}

        if (!selectors || !location) {
          return { params: {} }
        }

        let route = getRoute(routes, location.pathname)
        const excluded = ['component', 'createUrl']

        route = mapValues(route, (value, key) => {
          // This makes Router Helpers possible
          // Send selectores inside the object so you can use them
          if (isFunction(value) && excluded.indexOf(key) === -1) {
            return value({ route, isMobile })
          }

          return value
        })
        // show not found route on pages to show only on mobile
        // or only to show only on desktop
        const notFound =
          (route.onlyMobile && !isMobile) || (route.onlyDesktop && isMobile)
        if (notFound) {
          return routes.notfound
        }

        return route
      },
      PropTypes.object
    ],

    /** put device connection in a variable */
    deviceDisconnected: [
      () => [selectors.currentDevice],
      (currentDevice: Device) => !currentDevice.connected,
      PropTypes.bool
    ],

    userValid: [
      () => [selectors.user],
      user => !!(user && user.id) || false,
      PropTypes.bool
    ],

    /** convert the user role for application permissions */
    userRole: [
      () => [selectors.currentDevice, selectors.user],
      (currentDevice: Device, user: User) => {
        // on user admin is always owner
        if (user && user.isAdmin) {
          return 'owner'
        }

        const { roles } = currentDevice
        let permission = 'view'

        if (roles && roles.length) {
          const role = find(roles, role => {
            if (role && role.user && user) {
              return role.user.id === user.id
            }
            return null
          })

          permission = getUserRole(role)
        }

        return permission
      },
      PropTypes.string
    ],

    userIsTechnician: [
      () => [selectors.user],
      user => (user && user.isDealer) || false,
      PropTypes.bool
    ],

    countryDevice: [
      () => [selectors.currentDevice],
      currentDevice =>
        (currentDevice.country && currentDevice.country.id) || null,
      PropTypes.string
    ],

    isGermany: [
      () => [selectors.countryDevice],
      countryDevice => countryDevice === GERMANY_COUNTRY_ID,
      PropTypes.bool
    ],

    unitRelatedPage: [
      () => [selectors.currentRoute],
      currentRoute => !!(currentRoute.params && currentRoute.params.id),
      PropTypes.bool
    ],

    /** Gets the only the navigation items */
    navItems: [
      () => [
        selectors.currentRoute,
        selectors.isMobile,
        selectors.currentDevice,
        selectors.isGermany,
        selectors.unitRelatedPage,
        selectors.userIsTechnician,
        selectors.showHelpTour,
        selectors.routes
      ],
      (
        currentRoute,
        isMobile,
        currentDevice,
        isGermany,
        unitRelatedPage,
        userIsTechnician,
        showHelpTour,
        routes
      ) => {
        let navbar = []
        const routesArray = [...toArray(routes)].filter(({ navbar }) => navbar)
        routesArray.forEach(route => {
          navbar.push({ ...route })
        })

        return navbar.map(route => {
          const excluded = ['component', 'createUrl']
          let mappedRoute = mapValues(route, (value, key) => {
            // This makes Router Helpers possible
            // Send selectores inside the object so you can use them
            if (isFunction(value) && excluded.indexOf(key) === -1) {
              return value({
                route,
                currentRoute,
                isMobile,
                currentDevice,
                isGermany,
                unitRelatedPage,
                userIsTechnician,
                showHelpTour
              })
            }
            return value
          })
          // is selected
          let isCurrentRoute =
            route.path === (currentRoute && currentRoute.path)
          let isCurrentTag = route.key === (currentRoute && currentRoute.tag)
          mappedRoute.active = isCurrentRoute || isCurrentTag

          return mappedRoute
        })
      },
      PropTypes.array
    ],

    /**
     *  this selector generate translations component
     *  based on configurations
     */
    translations: [
      () => [selectors.configs, selectors.translations],
      (configs: GeneralConfigs, translations: any) =>
        generateTranslationComponent(
          translations,
          configs.showTranslations,
          configs.showOnlyTranslationKeys
        ),
      PropTypes.object
    ],

    /**
     * Field to indicate that the system is working without battery
     * Jira XSPD-1206
     * Battery Ready Mode - Allow the system to work without battery
     */
    batteryReadyMode: [
      () => [selectors.currentDevice],
      currentDevice => !currentDevice.hasBattery,
      PropTypes.bool
    ],

    currentDeviceId: [
      () => [selectors.currentRoute],
      currentRoute =>
        (currentRoute.params && currentRoute.params.id) ||
        window.localStorage.getItem('currentDeviceId'),
      PropTypes.string
    ],

    authProvider: [
      () => [selectors.user],
      user => (user && user.providerType) || null,
      PropTypes.string
    ],

    appLoading: [
      () => [selectors.appLoadingStatus],
      appLoadingStatus => {
        const appLoadingValues = Object.keys(appLoadingStatus).map(
          key => appLoadingStatus[key]
        )
        return !appLoadingValues.every(item => item === false)
      },
      PropTypes.bool
    ],

    showBackBar: [
      () => [selectors.unitRelatedPage, selectors.userIsTechnician],
      (unitRelatedPage, userIsTechnician) =>
        unitRelatedPage && userIsTechnician,
      PropTypes.bool
    ]
  }),

  start: function * () {
    log('[XS-CORE] 🚀 Booting up ', 'cornflowerblue')
    const { start } = this.actions

    yield put(start())
  },

  takeEvery: ({ actions, workers }) => ({
    /* CORE */
    [actions.navigate]: workers.navigate,
    [actions.logout]: workers.logout,
    [actions.login]: workers.login,

    /* USER PROFILE */
    [actions.editUserProfile]: workers.editUserProfile,
    [actions.changeUserPassword]: workers.changeUserPassword,
    [actions.checkLoggedUser]: workers.checkLoggedUser,
    [actions.setLanguage]: workers.getTranslations,

    /* CONFIGS */
    [actions.changeConfig]: workers.changeConfig
  }),

  takeLatest: ({ actions, workers }) => ({
    /* USER PROFILE */
    [actions.getCurrentUser]: workers.getCurrentUser,
    [actions.fetchDefaultLanguage]: workers.fetchDefaultLanguage,

    /* DEVICES */
    [actions.fetchDevices]: workers.fetchDevices,
    [actions.fetchCurrentDeviceInfo]: workers.fetchCurrentDeviceInfo,
    [actions.fetchWeatherInfo]: workers.fetchWeatherInfo,
    [actions.start]: workers.start,
    [actions.setShowHelpTour]: workers.closeTour
  }),

  workers: {
    * start () {
      const { fetchDefaultLanguage } = this.actions

      moment.updateLocale('en', {
        week: {
          dow: 1
        }
      })

      // check if setting for mocking API is active
      // Mocking API
      const configs = yield this.get('configs')

      if (configs.fakeAPI) {
        const { resetAppLoadingStatus } = this.actions
        // create mock adapter
        this.mock = new MockAdapter(axios)

        // mock all requests
        yield mockSwaggerSpecs(this.mock, `${API_BASE_URL}/v2/api-docs`)
        yield put(resetAppLoadingStatus())
      }

      // Get the last version the user tried
      const localVersion = window.localStorage.getItem('version')
      const localIsTheLast = VERSION === localVersion

      // Set local to the new version
      if (!localIsTheLast) {
        window.localStorage.setItem('version', VERSION)
      }

      yield put(fetchDefaultLanguage())
    },

    * navigate (action) {
      const { path } = action.payload

      yield put(push(path))
    },

    logout () {
      // dispatch the auth service logout
      removeUserSession()
      this.authService && this.authService.logout()

      window.location.href = `${BASE_URL}`
    },

    * login () {
      const { setAppError } = this.actions
      // dispatch the auth service login
      if (this.authService.hasError) {
        yield put(setAppError('Something went wrong!'))
      } else {
        this.authService.login()
      }
    },

    editUserProfile () {
      // dispatch the auth service event
      this.authService.editUserProfile()
    },

    changeUserPassword () {
      // dispatch the auth service event
      this.authService.resetUserPassword()
    },

    * fetchDefaultLanguage () {
      const {
        setLanguage,
        setAppError,
        checkLoggedUser,
        setAppLoadingStatus,
        resetAppLoadingStatus
      } = this.actions

      log('[XS-CORE] fetch default language')
      yield put(setAppLoadingStatus({ fetchDefaultLanguage: false }))

      try {
        const config = {
          ignoreAuth: true
        }

        const response = yield call(axios.get, FETCH_LANGUAGES_ENDPOINT, config)
        const { result } = response.data || {}
        const languages = result.data || {}

        let userLang = navigator.language // check browser language
        // split for the first identifier (ex. en-US goes en)
        userLang = userLang.split('-')[0]

        /**
         * find browser language on API languages
         * if not find get the default API lang
        */
        let lang = languages.find(lang => lang.identifier.includes(userLang))

        if (!lang) {
          lang = languages.find(lang => lang.defaultValue)
        }

        const configs = yield this.get('configs')
        if (
          (process.env.APP_ENV === 'test' && !lang) ||
          (configs.fakeAPI && !lang)
        ) {
          lang = languages[0]
        }

        yield put(setLanguage(lang.identifier))

        const isIE = !!document.documentMode

        if (isIE) {
          return false
        }

        yield put(checkLoggedUser())
      } catch (error) {
        yield put(resetAppLoadingStatus())
        // raise an error for user
        if (error.message === 'Network Error') {
          yield put(setAppError('Connection error!'))
        }
      }
    },

    * checkLoggedUser () {
      const { getCurrentUser, login, setAppLoadingStatus } = this.actions

      this.authService = new AuthService()
      const user = yield this.get('user')

      if (!user) {
        log('[XS-CORE] try to get user info')
        yield put(setAppLoadingStatus({ checkLoggedUser: false }))

        let idToken = null
        const configs = yield this.get('configs')

        // for tests and mocking proposes
        // use fake token to login user
        if (configs.fakeLogin) {
          idToken = FAKE_ID_TOKEN
        } else {
          // get current token on localStorage
          idToken = window.localStorage.getItem('msal.idtoken')

          if (!idToken) {
            log('[XS-CORE] try to get user token')
            idToken = yield this.authService.getToken()
          }
        }

        if (!idToken) {
          // wait 3 seconds before make de redirect to login
          // azure redirect on first time before user login
          // doesn't return anything
          yield delay(3000)

          log('[XS-CORE] redirect to the login page')
          yield put(login())
          return false
        }

        // Add axios interceptor
        axios.interceptors.request.use(
          config => axiosAuthInterceptor(config, idToken),
          error => Promise.reject(error)
        )

        yield put(getCurrentUser())
      }
    },

    /**
     * cheking if login user is valid (/auth endpoint)
     */
    * getCurrentUser () {
      const {
        fetchCurrentDeviceInfo,
        setCurrentUser,
        setAppLoadingStatus,
        setLanguage,
        changeConfig,
        resetAppLoadingStatus,
        fetchDevices,
        navigate
      } = this.actions

      log('[XS-CORE] check if user is valid')
      yield put(setAppLoadingStatus({ getCurrentUser: false }))

      const configs = yield this.get('configs')
      const token = window.localStorage.getItem('msal.idtoken')
      const language = yield this.get('language')
      const unitRelatedPage = yield this.get('unitRelatedPage')
      const currentRoute = yield this.get('currentRoute')
      const inviteRoutes = ['DeviceInvite', 'DeviceOwnership']

      try {
        const data = {
          token: token,
          language: language
        }

        const config = {
          headers: { 'content-type': 'application/json' },
          ignoreAuth: true
        }

        const response = yield call(axios.post, AUTH_ENDPOINT, data, config)
        const { result } = response.data || {}

        if (result) {
          if (result.language && result.language !== language) {
            yield put(setLanguage(result.language))
          }

          if (result.isDealer && configs.userType !== TECHNICIAN_USER_TYPE) {
            yield put(changeConfig({ userType: TECHNICIAN_USER_TYPE }))
          } else if (
            !result.isDealer &&
            configs.userType !== CONSUMER_USER_TYPE
          ) {
            yield put(changeConfig({ userType: CONSUMER_USER_TYPE }))
          }

          if (configs.userType === CONSUMER_USER_TYPE) {
            ReactGA.initialize('UA-143596622-1')
          } else {
            ReactGA.initialize('UA-143596622-2')
          }

          ReactGA.pageview(window.location.pathname + window.location.search)
        }

        yield put(setCurrentUser(result))

        if (inviteRoutes.indexOf(currentRoute.name) !== -1) {
          yield put(navigate(window.location.pathname))
        }

        const userIsTechnician = yield this.get('userIsTechnician')

        if (userIsTechnician && !unitRelatedPage) {
          yield put(resetAppLoadingStatus())
        } else if (userIsTechnician && unitRelatedPage) {
          yield put(fetchCurrentDeviceInfo())
          yield put(setAppLoadingStatus({ fetchDevices: false }))
        } else {
          yield put(fetchDevices())
        }
      } catch (catchError) {
        const { status } = catchError.response || {}

        if (status === 412 && currentRoute.path.includes('/terms/')) {
          yield put(resetAppLoadingStatus())
        }
      }
    },

    * fetchDevices () {
      const {
        setDevices,
        setAppError,
        addFailure,
        fetchDevices,
        setCurrentDeviceInfo,
        navigate,
        fetchCurrentDeviceInfo,
        setAppLoadingStatus,
        resetAppLoadingStatus
      } = this.actions

      const user = yield this.get('user')

      if (user && !user.id) {
        yield put(resetAppLoadingStatus())
        return false
      }

      log('[XS-CORE] fetch all user devices')
      yield put(setAppLoadingStatus({ fetchDevices: false }))

      let hasError = false

      try {
        // return all devices
        const params = { size: -1 }
        const response = yield call(axios.get, DEVICES_ENDPOINT, { params })
        const devicesResult = response.data
        let { result, successful } = devicesResult

        hasError = !successful
        if (successful) {
          const devices = result.data
          yield put(setDevices(devices))

          if (result.total > 0) {
            let currentDeviceId = yield this.get('currentDeviceId')

            if (!currentDeviceId || currentDeviceId === '0') {
              currentDeviceId = devices[0].id
            }

            window.localStorage.setItem('currentDeviceId', currentDeviceId)

            if (
              window.location.pathname === '/' ||
              window.location.pathname === '/0/dashboard/'
            ) {
              yield put(navigate(`/${currentDeviceId}/dashboard/`))
            }

            let currentDevice = devices.find(
              device => device.id === currentDeviceId
            )
            if (!currentDevice) {
              currentDevice = devices[0]
            }
            yield put(setCurrentDeviceInfo(currentDevice))
            yield put(fetchCurrentDeviceInfo(currentDeviceId))
          } else {
            yield put(setCurrentDeviceInfo({}))
            yield put(resetAppLoadingStatus())

            if (window.location.pathname === '/') {
              yield put(navigate('/add-device/'))
            }
          }
        }
      } catch (catchError) {
        yield put(resetAppLoadingStatus())
        console.log(catchError)
        if (catchError.response) {
          hasError = true
        }
      }

      const numFailures = yield this.get('numFailures')
      // on error try again after 5 seconds
      if (hasError && numFailures < MAX_FAILURES) {
        yield put(addFailure())
        yield delay(5000)
        yield put(fetchDevices())
      } else if (numFailures === MAX_FAILURES) {
        yield put(setAppError('Connection error!'))
      }
    },

    * fetchCurrentDeviceInfo () {
      const {
        setCurrentDeviceInfo,
        fetchCurrentDeviceInfo,
        setAppLoadingStatus,
        resetAppLoadingStatus,
        fetchWeatherInfo,
        setShowHelpTour
      } = this.actions

      log('[XS-CORE] get current device info')

      let currentDeviceId = yield this.get('currentDeviceId')
      const weatherInfo = yield this.get('weatherInfo')
      const user = yield this.get('user')
      const userIsTechnician = yield this.get('userIsTechnician')

      if (!currentDeviceId) {
        yield put(resetAppLoadingStatus())
        return false
      }

      if (user && !user.didTour && !userIsTechnician) {
        yield put(setShowHelpTour(true))
      }

      yield put(setAppLoadingStatus({ fetchCurrentDeviceInfo: false }))

      try {
        const url = generateUrl(DEVICE_ENDPOINT, { id: currentDeviceId })
        const response = yield call(axios.get, url)
        const { result } = response.data

        window.localStorage.setItem('currentDeviceId', currentDeviceId)

        if (result && result.timezone) {
          moment.tz.setDefault(result.timezone.timezone)
        }

        yield put(setCurrentDeviceInfo(result))
        yield put(setAppLoadingStatus({ setCurrentDeviceInfo: false }))

        /**
         * update weather info
         * if the device timezone info doesn't match the weather info
         */
        const weatherOffset =
          weatherInfo && moment.tz(moment(), weatherInfo.timezone).utcOffset()
        const deviceOffset =
          result &&
          result.timezone &&
          moment.tz(moment(), result.timezone.timezone).utcOffset()

        if (weatherOffset !== deviceOffset) {
          yield put(fetchWeatherInfo())
        } else {
          yield put(resetAppLoadingStatus())
        }

        if (!result.connected) {
          yield delay(FETCH_DEVICE_INFO_DISCONNECTED_DELAY)
        } else {
          yield delay(FETCH_DEVICE_INFO_CONNECTED_DELAY)
        }

        yield put(fetchCurrentDeviceInfo())
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * get translations for current selected language
     */
    * getTranslations () {
      const { setTranslations } = this.actions

      const language = yield this.get('language')

      // set translations
      log(`[XS-CORE] set translations for language ${language}`)
      const translations = getTranslations(language)
      moment.locale(language)

      yield put(setTranslations(translations))
    },

    /**
     *  Changing app configurations
     */
    * changeConfig () {
      const configs = yield this.get('configs')

      // put configs on local storage
      window.localStorage.setItem('configs', JSON.stringify(configs))

      // reload the page
      window.location.reload()
    },

    * fetchWeatherInfo () {
      const {
        setWeatherInfo,
        setAppLoadingStatus,
        resetAppLoadingStatus,
        fetchWeatherInfo
      } = this.actions

      const currentDevice = yield this.get('currentDevice')
      const user = yield this.get('user')
      const language = yield this.get('language')
      const unit = user && user.weatherUnit ? user.weatherUnit : DEFAULT_UNITS

      if (
        Object.keys(currentDevice).length === 0 ||
        !currentDevice.latitude ||
        !currentDevice.longitude
      ) {
        yield put(resetAppLoadingStatus())
        return false
      }

      const data = {
        latitude: currentDevice.latitude,
        longitude: currentDevice.longitude,
        units: unit,
        language: language
      }

      try {
        const response = yield call(axios.post, WEATHER_ENDPOINT, data)
        const { result } = response.data

        yield put(setWeatherInfo(result))
        yield put(setAppLoadingStatus({ setWeatherInfo: false }))

        yield delay(FETCH_WEATHER_DELAY)

        yield put(fetchWeatherInfo())
      } catch (error) {
        yield put(resetAppLoadingStatus())
        console.log(error)
      }
    },

    * closeTour (status) {
      const user = yield this.get('user')
      const showHelpTour = status && status.payload

      if (!showHelpTour) {
        const { setCurrentUser } = this.actions

        user.didTour = true

        yield put(setCurrentUser(user))

        try {
          const data = {
            didTour: 'true'
          }

          yield call(axios.put, ME_ENDPOINT, data)
        } catch (errorResponse) {
          console.log(errorResponse)
        }
      }
    }
  }
})
