// @flow
import { kea } from 'kea'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import { API_BASE_URL } from 'config'
import { log, generateUrl, getFormValues, convertFromMilitaryTime } from 'utils'
import { mapValues, pick } from 'lodash'
import {
  COMMANDS_PARAMS,
  DEFAULT_COMMAND,
  processCommandParams,
  loadCommandInfo
} from 'containers/OperationModeForm/utils'
import {
  endTimeIsGreatThanStartTime,
  durationIsValid,
  peakConsumptionRequired
} from 'scenes/ScheduleMenu/Schedule/utils'
import { getApiErrorKey } from 'apiErrors'

import PropTypes from 'prop-types'
import * as Check from 'validations'

import ScheduleWeek from 'containers/ScheduleWeek/logic'
import AppLogic from 'containers/App/logic'
import { checkRolePermission } from 'containers/UserPermission/utils'
import moment from 'moment-timezone'

// eslint-disable-next-line max-len
const DEVICE_CREATE_EVENT_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/schedule/create`
// eslint-disable-next-line max-len
const DEVICE_EVENT_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/schedule/{eventId}`

export const DEFAULT_VALUES = {
  startTime: {
    value: moment()
      .add(1, 'hour')
      .format('HH:00'),
    valid: true
  },
  endTime: {
    value: moment()
      .add(2, 'hour')
      .format('HH:00'),
    valid: true
  },
  recurrence: {
    value: 'WEEK_DAYS',
    valid: true
  },
  command: {
    value: DEFAULT_COMMAND,
    valid: true
  },
  soc: {
    value: 0,
    valid: true
  },
  power: {
    value: 0,
    valid: true
  },
  maximumPower: {
    value: 0,
    valid: true
  },
  powerAllocation: {
    value: 0,
    valid: true
  },
  optimalSoc: {
    value: 0,
    valid: true
  },
  maxHousePeakConsumption: {
    value: 0,
    valid: true
  }
}

const VALIDATIONS = {
  endTime: [endTimeIsGreatThanStartTime, durationIsValid],
  maxHousePeakConsumption: [peakConsumptionRequired]
}

export default kea({
  path: () => ['scenes', 'containers', 'ScheduleEventForm'],

  connect: {
    props: [
      AppLogic,
      ['isMobile', 'translations', 'currentDeviceId', 'userRole']
    ],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    refreshSchedule: () => true,
    deleteEvent: () => true,
    fetchEvent: () => true,
    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => true,
    setLoading: loading => loading
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.submit]: () => false,
        [actions.change]: () => true
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.setForm]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => true,
        [actions.deleteEvent]: () => true,
        [actions.setLoading]: (state, payload) => payload,
        [actions.submit]: () => true
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.submit]: () => null,
        [actions.change]: () => null
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    /** put user permission on a prop */
    userCanEdit: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('EDIT_EVENT', userRole),
      PropTypes.bool
    ],

    /** put user permission on a prop */
    userCanDelete: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('DELETE_EVENT', userRole),
      PropTypes.bool
    ]
  }),

  start: function * () {
    const { fetchEvent, setLoading } = this.actions

    log('[XS-ScheduleEventForm] Start Container ', 'orange')

    const currentEventId = yield ScheduleWeek.get('currentEventId')

    if (currentEventId) {
      yield put(fetchEvent())
    } else {
      yield put(setLoading(false))
    }
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-ScheduleEventForm] Stop Container ')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
    [actions.refreshSchedule]: function * ({ payload }) {
      yield put(ScheduleWeek.actions.fetchEvents())
    },
    [actions.fetchEvent]: workers.fetchEvent,
    [actions.deleteEvent]: workers.deleteEvent
  }),

  workers: {
    * submit () {
      const { setError, setForm, refreshSchedule, navigate } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)

      let data = pick(params, ['command', 'startTime', 'endTime', 'recurrence'])
      data.parameters = pick(params, COMMANDS_PARAMS[data.command])
      data.startTime = parseInt(data.startTime.replace(':', ''))
      data.endTime = parseInt(data.endTime.replace(':', ''))

      data = processCommandParams(data)

      try {
        const currentDeviceId = yield this.get('currentDeviceId')
        const currentEventId = yield ScheduleWeek.get('currentEventId')

        let result
        if (currentEventId) {
          const params = { id: currentDeviceId, eventId: currentEventId }
          const url = generateUrl(DEVICE_EVENT_ENDPOINT, params)
          result = yield call(axios.put, url, data)
        } else {
          const url = generateUrl(DEVICE_CREATE_EVENT_ENDPOINT, {
            id: currentDeviceId
          })
          result = yield call(axios.post, url, data)
        }

        const { successful } = result.data || {}
        if (successful) {
          const isMobile = yield ScheduleWeek.get('isMobile')
          if (!isMobile) {
            yield put(refreshSchedule())
          } else {
            yield put(navigate(`/${currentDeviceId}/schedule/`))
          }
        }
      } catch (catchError) {
        const result = catchError.response

        if (result && result.data) {
          const { message } = result.data

          const errorKey = getApiErrorKey(message)

          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    },

    * fetchEvent () {
      const { setForm } = this.actions

      try {
        const currentDeviceId = yield this.get('currentDeviceId')
        const currentEventId = yield ScheduleWeek.get('currentEventId')

        const params = { id: currentDeviceId, eventId: currentEventId }
        const url = generateUrl(DEVICE_EVENT_ENDPOINT, params)
        const response = yield call(axios.get, url)
        const apiResult = response.data
        const { result, successful } = apiResult

        if (successful) {
          let formValues = {
            ...result,
            ...result.parameters
          }

          formValues = loadCommandInfo(formValues)

          const info = getFormValues(DEFAULT_VALUES, formValues)
          info.startTime.value = convertFromMilitaryTime(info.startTime.value)
          info.endTime.value = convertFromMilitaryTime(info.endTime.value)

          yield put(setForm(info))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * deleteEvent () {
      const { refreshSchedule, navigate } = this.actions

      try {
        const currentEventId = yield ScheduleWeek.get('currentEventId')
        const currentDeviceId = yield this.get('currentDeviceId')

        const params = { id: currentDeviceId, eventId: currentEventId }
        const url = generateUrl(DEVICE_EVENT_ENDPOINT, params)
        yield call(axios.delete, url)

        const isMobile = yield ScheduleWeek.get('isMobile')
        if (!isMobile) {
          yield put(refreshSchedule())
        } else {
          yield put(navigate(`/${currentDeviceId}/schedule/`))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    }
  }
})
