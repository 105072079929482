/**
 * UserProfileChangePass Scene
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  PageMainSection,
  TextList,
  Point
} from 'xstorage-components'

type Props = {
  loading: boolean,
  isMobile: boolean,
  translations: any
}

export class UserProfileChangePass extends Component<Props, void> {
  actions: any

  render () {
    const {
      loading,
      isMobile,
      translations
    } = this.props

    const {
      navigate,
      changePassword
    } = this.actions

    const {
      USERPROF_TITLE,
      USERPROF_CHANGE_PASS_TITLE,
      USERPROF_CHANGE_PASS_SUB_TITLE,
      USERPROF_CHANGE_PASS_BUTTON
    } = translations

    // eslint-disable-next-line max-len
    const pionts = Object.keys(translations).filter((key) => /USERPROF_CHANGE_PASS_INFOPOINT(\d+)/.test(key))

    return (
      <Wrapper>
        <PageAdaptable
          loading={loading}
          title={USERPROF_TITLE}
          menu={<UserProfileMenu page={'change-password'} />}
          isForm={false}
          onBack={() => navigate('/user-profile/')}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle
              mobile={isMobile}
            >
              {USERPROF_CHANGE_PASS_TITLE}
            </PageTitle>
            <FormButtons>
              <TextContent>
                <TextTitle>{USERPROF_CHANGE_PASS_SUB_TITLE}</TextTitle>
                <TextList>
                  {pionts.map(point =>
                    <Point key={point}>{translations[point]}</Point>
                  )}
                </TextList>
              </TextContent>
              <Wrapper >
                <Button onClick={changePassword}>
                  {USERPROF_CHANGE_PASS_BUTTON} <Icon>{'open_in_new'}</Icon>
                </Button>
              </Wrapper>
            </FormButtons>
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(UserProfileChangePass)

const Wrapper = styled.div`
`

const FormButtons = styled.div`
  margin: 15px 0;
`

const TextContent = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: 1024px) {
    margin-top: 50px;
  }  
`

const TextTitle = styled.h2`
  font-size: 16px;
  color: #333F48;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.5px;
`

const Button = styled.button`
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
  border-radius: 20px;
  border: 0px;
  background-color: #007BC1;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  text-align: center;
  display: inline-block;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-width: 30%;
  max-height: 40px;
  height: 40px;
  
  span.material-icons {
    font-size: 16px!important;
    line-height: 16px;
    overflow: visible;
    vertical-align: middle;
    height: 16px;
    margin-left: 15px;
  }
`
