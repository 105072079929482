/**
 * UserPermission Container Utils
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow

import permissions from './permissions.json'

/**
 *
 * @param {*} permission
 * @param {*} role
 */
export const checkRolePermission = (permission: string, role: string) => {
  const permissionRoles = permissions[permission]
  if (permissionRoles) {
    return permissionRoles.indexOf(role) !== -1
  }

  return false
}
