/* eslint-disable max-len */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import {
  Modal,
  ModalHeader,
  ModalContent,
  List,
  ListItemCollapse,
  Typography
} from 'xstorage-components'

type Props = {
  open: boolean,
  onClose: Function,
  translations: any,
  isMobile: boolean
}

class OperationModeHelpModal extends Component<Props, void> {
  render () {
    const { open, onClose, isMobile, translations } = this.props

    const {
      DASH_OPMODE_HELPMODAL_TITLE,
      DASH_OPMODE_HELPMODAL_SUBTITLE
    } = translations

    const topics = Object.keys(translations).filter(key =>
      /DASH_OPMODE_HELPMODAL_TOPIC(\d+)_TITLE/.test(key)
    )

    return (
      <Wrapper>
        {open && (
          <Modal open={open} size={'medium'} onClose={onClose}>
            <div>
              <ModalHeader
                title={DASH_OPMODE_HELPMODAL_TITLE}
                subtitle={DASH_OPMODE_HELPMODAL_SUBTITLE}
                closeButton
                onClose={onClose}
              />
              <ModalContent type={'diagonal'} size={'medium'} mobile={isMobile}>
                <List>
                  {topics.map((topic, index) => (
                    <ListItemCollapse
                      key={topic}
                      title={translations[topic]}
                      shadow
                      collapsible
                    >
                      <Typography>
                        {translations[`DASH_OPMODE_HELPMODAL_TOPIC${index + 1}_DESC`]}
                      </Typography>
                    </ListItemCollapse>
                  ))}
                </List>
              </ModalContent>
            </div>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default Logic(OperationModeHelpModal)

const Wrapper = styled.div``
