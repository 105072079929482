/**
 * ScheduleEvent Container Utils
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow

/**
 * returns conversion of military time to unit time
 *
 * @param {*} time
 */
export const convertMiliTimeToUnitTime = (time: number): number => {
  const value = Math.floor(time) + ((time - Math.floor(time)) / 0.60)
  return parseFloat(Number(value).toFixed(2))
}

/**
 * get the event size based on event start and end
 *
 * @param {*} start
 * @param {*} end
 */
export const getEventHeightAndTop = (start: number, end: number): any => {
  const blockHeight = 80

  const startTime = convertMiliTimeToUnitTime(start)
  const endTime = convertMiliTimeToUnitTime(end)

  // calculation of top
  let top = startTime * blockHeight

  // calculation of event height
  let duration = endTime - startTime

  // on very small events define a limit for not deform
  // the calendar grid
  if (duration < 0.35) {
    const decimal = startTime - Math.floor(startTime)
    if (decimal > 0.65) {
      top = (Math.floor(startTime) + 0.65) * blockHeight
    }
    duration = 0.35
  }
  let height = duration * blockHeight

  // remove paddings
  height = height - 10

  return {
    top,
    height
  }
}
