// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import { log } from 'utils'
import { DEFAULT_SELECTED_SWITCHER } from './utils'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'Schedule'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDeviceId'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    changeSwitcher: (switcher) => ({ switcher })
  }),

  reducers: ({ actions }) => ({
    switcherSelected: [DEFAULT_SELECTED_SWITCHER, PropTypes.string, {
      [actions.changeSwitcher]: (state, payload) => payload.switcher
    }]
  }),

  start: function * () {
    const { navigate } = this.actions

    log('[XS-Schedule] Start Scene ', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }
  }
})
