// @flow
import React, { Component, Fragment } from 'react'
import { type User } from 'types/api'
import Logic from './logic'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import {
  LogoEatonWhite,
  LogoXstorageWhite,
  SpaceDiv,
  BatteryBlue
} from 'xstorage-components'

type Props = {
  translations: {
    EXTAPP_CHOOSEUNITS_SWITCHACCOUNT: string,
    EXTAPP_SUCCESS_DESCRIPTION: string,
    EXTAPP_SUCCESS_TITLE: string,
    EXTAPP_SUCCESS_GOTODASHBOARD: string
  },
  appInfo: {
    appName: string,
    organization: string
  },
  user: User,
  updatedDevices: [],
  currentDeviceId: string,
  userIsTechnician: boolean,
  updatedDevicesString: string
}

class ExternalAppUpdateSuccess extends Component<Props, void> {
  render () {
    const {
      translations,
      appInfo,
      updatedDevices,
      currentDeviceId,
      userIsTechnician,
      updatedDevicesString
    } = this.props

    const {
      EXTAPP_SUCCESS_DESCRIPTION,
      EXTAPP_SUCCESS_TITLE,
      EXTAPP_SUCCESS_GOTODASHBOARD
    } = translations

    const { appName, organization } = appInfo || {}

    // eslint-disable-next-line max-len
    const description = EXTAPP_SUCCESS_DESCRIPTION.replace(
      '<APP_NAME>',
      `${appName}, ${organization}®`
    )

    const dashboardUrl = userIsTechnician
      ? '/'
      : `/${currentDeviceId}/dashboard/`

    return (
      <Wrapper>
        <Header>
          <LogoEatonWhite size={{ width: 80, height: 20 }} />
          <XstorageLogo size={{ width: 60, height: 16 }} />
        </Header>
        <Body>
          <SpaceDiv height={'52px'} />
          <BatteryBlue />
          <Title>{EXTAPP_SUCCESS_TITLE}</Title>
          {updatedDevices.length > 0 && (
            <Fragment>
              <Typography variant={'body1'} color={'textPrimary'}>
                {description}
              </Typography>
              <Typography
                variant={'h5'}
                component={'p'}
                color={'textPrimary'}
              >
                {updatedDevicesString}
              </Typography>
            </Fragment>
          )}
          <Button onClick={() => window.open(dashboardUrl, '_blank')}>
            {EXTAPP_SUCCESS_GOTODASHBOARD}
          </Button>
        </Body>
      </Wrapper>
    )
  }
}

export default Logic(ExternalAppUpdateSuccess)

const Wrapper = styled.div``

const Header = styled.div`
  background-color: #007bc1;
  padding: 22px;
`

const XstorageLogo = styled(LogoXstorageWhite)`
  padding-left: 8px;
`

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
`

const Title = styled.h1`
  color: #007bc1;
  font-family: Eaton;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
  text-align: center;
`

const Button = styled.button`
  height: 48px;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  background-color: #007bc1;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  border: 0px;
  cursor: pointer;
`
