import React, { Component } from 'react'
import { ENERGY_FLOW_DURATION_FLOW } from 'config'
import anime from 'animejs'
import styled from 'styled-components'

export default class NonCriticalFlow extends Component {
  componentDidMount () {
    const { reverse, id } = this.props

    const pathExists = document.querySelector(`#motion-${id} path`)
    if (pathExists) {
      const path = anime.path(`#motion-${id} path`)
      anime({
        targets: `#motion-${id} > div`,
        translateX: path('x'),
        translateY: path('y'),
        rotate: path('angle'),
        easing: 'linear',
        duration: 2000,
        loop: true,
        direction: reverse ? 'reverse' : 'normal'
      })
    }
  }

  render () {
    const { id, mobile } = this.props

    let dimensions, path, color
    if (mobile) {
      dimensions = {
        height: '160',
        width: '100',
        viewBox: '-10 30 100 55'
      }
      path = 'M0 0 H 0 V 125 H 60'
      color = '#FFF'
    } else {
      dimensions = {
        height: '86',
        width: '255',
        viewBox: '-10 20 255 56'
      }
      path = 'M0,15 l180,0 M185,15 l60,65 V 90 H250'
      color = '#007BC1'
    }

    return (
      <Wrapper id={`motion-${id}`} mobile={mobile}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          {...dimensions}
        >
          <path strokeDasharray='5, 16' strokeLinecap='round' d={path}
            fill='none' stroke={color} strokeWidth='3' id={`${id}-wire`} />
        </svg>
        <Circle mobile={mobile} />
      </Wrapper>
    )
  }
}

NonCriticalFlow.defaultProps = {
  id: 'NonCriticalFlow',
  duration: (ENERGY_FLOW_DURATION_FLOW + 0.5),
  begin: 0,
  reverse: false,
  mobile: false
}

const Wrapper = styled.div`
  position: absolute;

  ${({ mobile }) => !mobile && `
    right: 50px;
    top: -25px;
  `}

  ${({ mobile }) => mobile && `
    left: 5px;
    top: -89px;
  `}
`

const Circle = styled.div`
  box-shadow: 0px 0px 3px 2px rgba(51, 51, 51, 0.5);
  border-radius: 50%;
  position: absolute;

  ${({ mobile }) => !mobile && `
    background: #007BC1;
    border: 3px solid #FFF;
    top: -12px;
    left: 2px;
    width: 14px;
    height: 14px;
    box-shadow: 0px 0px 15px 1px rgba(0, 123, 193, 0.4);
  `}

  ${({ mobile }) => mobile && `
    background: #FFF;
    width: 12px;
    height: 12px;
    top: 16px;
    left: 4px;
  `}
`
