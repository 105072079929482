/**
 * Subtitle Component
 * Black bold title
 *
 * @author Joana Garrido <jgarrido@ubiwhere.com>
 */
// @flow
import styled from 'styled-components'

export default styled.h2`
  font-size: 16px;
  color: #333F48;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.5px;
  margin: ${({ headermargin }) => headermargin};
`
