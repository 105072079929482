// @flow
import { kea } from 'kea'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import ExternalAppChooseUnitsLogic from 'scenes/ExternalAppChooseUnits/logic'

export default kea({
  path: () => ['scenes', 'ExternalAppUpdateSuccess'],

  connect: {
    props: [
      AppLogic, [
        'currentDeviceId',
        'translations',
        'user',
        'currentRoute',
        'userIsTechnician'
      ],
      ExternalAppChooseUnitsLogic, [
        'appInfo',
        'updatedDevices'
      ]
    ],
    actions: [
      ExternalAppChooseUnitsLogic, [
        'getAppInfo'
      ]
    ]
  },

  selectors: ({ selectors }) => ({
    /** Dict of all available routes */
    updatedDevicesString: [
      () => [selectors.updatedDevices],
      (updatedDevices) => {
        let updatedDevicesString =
          updatedDevices.map(device => device.name)
        updatedDevicesString = updatedDevicesString.join(', ')
        return updatedDevicesString
      },
      PropTypes.string
    ]
  })
})
