/**
 * MaintenanceTechParams Container Utils
 * Please write a description
 *
 * @author Joana Garrido <jgarrido@ubiwhere.com>
 */
// @flow
import { round } from 'lodash'

export const adjustMeterPhasesValue = (phases) => {
  let phasesArray = {
    voltage: [],
    current: [],
    activePower: [],
    reactivePower: [],
    powerFactor: [],
    frequency: []
  }

  phases.map(phase => {
    let index = 0

    if (phase.phaseType === 'S') {
      index = 1
    } else if (phase.phaseType === 'T') {
      index = 2
    }

    phasesArray.voltage[index] = round(phase.voltage, 2)
    phasesArray.current[index] = round(phase.current, 2)
    phasesArray.activePower[index] = round(phase.activePower, 2)
    phasesArray.reactivePower[index] = round(phase.reactivePower, 2)
    phasesArray.powerFactor[index] = round(phase.powerFactor, 2)
    phasesArray.frequency[index] = round(phase.frequency, 2)
    return phasesArray
  })

  phasesArray.voltage = phasesArray.voltage.join(', ')
  phasesArray.current = phasesArray.current.join(', ')
  phasesArray.activePower = phasesArray.activePower.join(', ')
  phasesArray.reactivePower = phasesArray.reactivePower.join(', ')
  phasesArray.powerFactor = phasesArray.powerFactor.join(', ')
  phasesArray.frequency = phasesArray.frequency[0]

  return phasesArray
}

const gridCodeTranslation = {
  'VDE0126': 'MAINTENANCE_GRIDCODE_VDE0126',
  'VDE0126_1_1_A1': 'MAINTENANCE_GRIDCODE_VDE0126_1_1_A1',
  'VDE_AR_N_4105': 'MAINTENANCE_GRIDCODE_VDE_AR_N_4105',
  'CEI_0_21': 'MAINTENANCE_GRIDCODE_CEI_0_21',
  'RD_1699': 'MAINTENANCE_GRIDCODE_RD_1699',
  'UK_G59': 'MAINTENANCE_GRIDCODE_UK_G59',
  'UK_G83': 'MAINTENANCE_GRIDCODE_UK_G83',
  'UTE': 'MAINTENANCE_GRIDCODE_UTE',
  'UTE_ISLAND_50': 'MAINTENANCE_GRIDCODE_UTE_ISLAND_50HZ',
  'UTE_ISLAND_60': 'MAINTENANCE_GRIDCODE_UTE_ISLAND_60HZ',
  'EN50438_NO': 'MAINTENANCE_GRIDCODE_EN50438_NO',
  'EN50438_PT': 'MAINTENANCE_GRIDCODE_EN50438_PT',
  'EN50438_CZ': 'MAINTENANCE_GRIDCODE_EN50438_CZ',
  'EN50438_NL': 'MAINTENANCE_GRIDCODE_EN50438_NL',
  'C10_C11': 'MAINTENANCE_GRIDCODE_C10_C11',
  'ONORM': 'MAINTENANCE_GRIDCODE_ONORM',
  'EN50438_IE': 'MAINTENANCE_GRIDCODE_EN50438_IE',
  'EN50438_FI': 'MAINTENANCE_GRIDCODE_EN50438_FI',
  'NIE_G59_230': 'MAINTENANCE_GRIDCODE_NIE_G59_230V',
  'NIE_G83_230': 'MAINTENANCE_GRIDCODE_NIE_G83_230V',
  'NIE_G59_240': 'MAINTENANCE_GRIDCODE_NIE_G59_240V',
  'NIE_G83_240': 'MAINTENANCE_GRIDCODE_NIE_G83_240V',
  'UNKNOWN': 'MAINTENANCE_GRIDCODE_UNKNOWN',
  'NO_GRID_CODE': 'MAINTENANCE_GRIDCODE_NOGRIDCODE',
  'GUYANA': 'MAINTENANCE_GRIDCODE_GUYANA',
  'RSA_NRS_097_2_1': 'MAINTENANCE_GRIDCODE_RSA_NRS_097_2_1',
  'UK_G98': 'MAINTENANCE_GRIDCODE_UK_G98',
  'UK_G99': 'MAINTENANCE_GRIDCODE_UK_G99',
  'CEI_0_21_2019': 'MAINTENANCE_GRIDCODE_CEI_0_21_2019',
  'VDE_4105_2018': 'MAINTENANCE_GRIDCODE_VDE_4105_2018'
}

export const getGridcodeValue = (gridCode) => {
  return gridCodeTranslation[gridCode]
}

export const getInvBootloaderVersion = (version: string) => {
  if (!version) return null

  const bootloaderDict = {
    '03.07': 'v1',
    '03.08': 'v2',
    '04.00': 'v4'
  }

  return bootloaderDict[version]
}
