// @flow
import axios from 'axios'
import { kea } from 'kea'
import PropTypes from 'prop-types'
import { call, delay, put } from 'redux-saga/effects'

import { getApiErrorKey } from 'apiErrors'
import { API_BASE_URL, FETCH_SSH_STATUS_DELAY } from 'config'
import AppLogic from 'containers/App/logic'
import { generateUrl, log } from 'utils'

// eslint-disable-next-line max-len
const SSH_OPENTUNNEL_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/ssh_tunnel/open`
// eslint-disable-next-line max-len
const SSH_CLOSETUNNEL_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/ssh_tunnel/close`
// eslint-disable-next-line max-len
const SSH_TUNNEL_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/ssh_tunnel`

const DEFAULT_VALUES = {
  enabled: false
}

export default kea({
  path: () => ['scenes', 'MaintenanceSshConnection'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'isMobile',
        'currentDeviceId',
        'currentDevice'
      ],
      ({ scenes }) => scenes.containers.MaintenanceSupportedFeatures,
      ['featureSupport']
    ],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    getSshStatus: (number, expected) => ({ number, expected }),
    setSshStatus: ssh => ssh,
    setError: error => error,
    setLoading: loading => loading,
    getFile: file => file,
    reset: () => true,
    setEnableStatus: enabled => enabled
  }),

  reducers: ({ actions }) => ({
    sshStatus: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.setSshStatus]: (state, payload) => payload,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setLoading]: (state, payload) => payload,
        [actions.getSshStatus]: () => true,
        [actions.setSshStatus]: () => false,
        [actions.setEnableStatus]: () => true,
        [actions.reset]: () => false
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ]
  }),

  start: function * () {
    log('[XS-SSH Status] Start Scene', 'yellow')

    const { getSshStatus } = this.actions

    yield put(getSshStatus())
  },

  stop: function * () {
    log('[XS-SSH Status] Stop Scene', 'yellow')

    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getSshStatus]: workers.getSshStatus,
    [actions.setEnableStatus]: workers.setEnableStatus,
    [actions.getFile]: workers.getFile
  }),

  workers: {
    * getSshStatus ({ payload }) {
      const { number, expected } = payload

      const { setSshStatus, setLoading, setError, getSshStatus } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')
      const translations = yield this.get('translations')

      try {
        const url = generateUrl(SSH_TUNNEL_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const statusRequest = yield call(axios.get, url)
        const { result } = statusRequest.data

        if (result.expiresIn) {
          // Compile expiration data of ssh tunnel
          result.expiresAt = Date.now() + result.expiresIn
          result.expiresAt = Math.floor(result.expiresAt / 1000)
        }

        if (number && typeof expected !== 'undefined') {
          if (result.enabled !== expected) {
            yield delay(FETCH_SSH_STATUS_DELAY)
            yield put(getSshStatus(number - 1, expected))
            return
          }
        }

        yield put(setSshStatus(result))
      } catch (catchError) {
        yield put(setLoading(false))
        const { data } = catchError.response || {}
        if (data) {
          const errorKey = getApiErrorKey(data.message, 'API_ERROR_')
          yield put(setError(translations[errorKey] || ''))
        } else {
          console.log(catchError)
        }
      }
    },

    * setEnableStatus (actionPayload) {
      const { payload } = actionPayload
      const { getSshStatus, setError } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')
      const translations = yield this.get('translations')

      try {
        const closeOrOpenTunnel = payload
          ? SSH_OPENTUNNEL_ENDPOINT
          : SSH_CLOSETUNNEL_ENDPOINT

        const url = generateUrl(closeOrOpenTunnel, {
          deviceId: currentDeviceId
        })

        const config = {
          headers: { 'content-type': 'application/json' }
        }

        const response = yield call(axios.post, url, config)

        if (response) {
          yield delay(FETCH_SSH_STATUS_DELAY)
          yield put(getSshStatus(6, payload))
        }
      } catch (catchError) {
        const { data } = catchError.response || {}
        if (data) {
          const errorKey = getApiErrorKey(data.message)
          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    },

    * getFile ({ payload }) {
      const { setLoading } = this.actions
      yield put(setLoading(true))

      window.open(payload)
      yield put(setLoading(false))
    }
  }
})
