// @flow
import { type Device } from 'types/api'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { CONSUMER_USER_TYPE } from 'config'

import UserPermission from 'containers/UserPermission'

import {
  Modal,
  ModalHeader,
  ModalContent,
  BatteryBlue
} from 'xstorage-components'

type Props = {
  devices: Array<Device>,
  currentDevice: Device,
  translations: any
}

type State = {
  showDevicesList: boolean
}

class DeviceChooserMobile extends Component<Props, State> {
  actions: any

  state = {
    showDevicesList: false
  }

  render () {
    const {
      devices,
      currentDevice,
      currentDeviceId,
      translations,
      configs
    } = this.props

    const { userType } = configs

    const {
      navigate,
      updateDeviceStatus,
      fetchCurrentDeviceInfo
    } = this.actions

    const {
      DEVICE_CHOOSER_ADD_NEW_UNIT,
      DEVICE_CHOOSER_UNIT_SETTINGS,
      DEVICE_CHOOSER_VIEW_ALL_UNITS,
      DEVICE_CHOOSER_ALLUNITS_MODAL_TITLE,
      DEVICE_CHOOSER_ALLUNITS_MODAL_SUBTITLE
    } = translations

    const { showDevicesList } = this.state

    const selectDevice = id => {
      window.localStorage.setItem('currentDeviceId', id)
      navigate(`/${id}/dashboard/`)
      fetchCurrentDeviceInfo()
      updateDeviceStatus()
    }

    return (
      <Wrapper>
        <TopShadow />
        <InnerContainer>
          <CurrentDevice>
            <IconContainer>
              <ImageBattery />
            </IconContainer>
            <DeviceInfo>
              <DeviceName>
                {currentDevice.name || currentDevice.inverterSerialNumber}
              </DeviceName>
              <DeviceSettingsLink
                onClick={() => navigate(`/${currentDeviceId}/settings/`)}
              >
                <Icon>{'settings'}</Icon>
                <span>{DEVICE_CHOOSER_UNIT_SETTINGS}</span>
              </DeviceSettingsLink>
            </DeviceInfo>
          </CurrentDevice>
          {userType === CONSUMER_USER_TYPE && (
            <React.Fragment>
              <DeviceLink>
                <Linktext
                  onClick={() => this.setState({ showDevicesList: true })}
                >
                  <Icon>{'apps'}</Icon>
                  {DEVICE_CHOOSER_VIEW_ALL_UNITS}
                </Linktext>
              </DeviceLink>
              <DeviceLink>
                <UserPermission permission={'ADD_NEW_DEVICE'}>
                  <Linktext onClick={() => navigate('/onboarding/new/')}>
                    <Icon>{'add'}</Icon>
                    {DEVICE_CHOOSER_ADD_NEW_UNIT}
                  </Linktext>
                </UserPermission>
              </DeviceLink>
            </React.Fragment>
          )}
        </InnerContainer>
        <Modal
          open={showDevicesList}
          size={'medium'}
          onClose={() => this.setState({ showDevicesList: false })}
        >
          <div>
            <ModalHeader
              title={DEVICE_CHOOSER_ALLUNITS_MODAL_TITLE}
              subtitle={DEVICE_CHOOSER_ALLUNITS_MODAL_SUBTITLE}
              closeButton
              mobile
              onClose={() => this.setState({ showDevicesList: false })}
            />
            <ModalContent size={'medium'} type={'diagonal'} mobile>
              {devices.map((device, index) => (
                <DeviceItem
                  index={index}
                  key={device.id}
                  active={device.id === currentDevice.id}
                  onClick={
                    device.id !== currentDevice.id
                      ? () => selectDevice(device.id)
                      : undefined
                  }
                >
                  {device.id === currentDevice.id && <Icon>{'done'}</Icon>}
                  <DeviceItemName>
                    {device.name || device.inverterSerialNumber}
                  </DeviceItemName>
                </DeviceItem>
              ))}
            </ModalContent>
          </div>
        </Modal>
      </Wrapper>
    )
  }
}

export default Logic(DeviceChooserMobile)

const Wrapper = styled.div`
  height: 132px;
  overflow: hidden;
`

const InnerContainer = styled.div`
  background-color: rgba(66, 78, 84, 0.05);
  height: 132px;
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -10px;
  display: flex;
  padding: 0 0 0 15px;

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    padding: 0 40px;
  }
`

const TopShadow = styled.div`
  background-color: #0067c6;
  opacity: 0.4;
  box-shadow: 0 0 30px 0 rgba(0, 103, 198, 0.4);
  width: 80%;
  height: 10px;
  margin: 0 auto;
  position: relative;
  top: -10px;
`

const CurrentDevice = styled.div`
  width: 56%;
  display: flex;
`

const DeviceLink = styled.div`
  width: 22%;
  height: 68px;
  border-left: 1px solid rgba(114, 126, 132, 0.1);
  margin-top: 30px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  text-align: center;

  @media screen and (max-width: 550px) {
    padding: 0 10px;
  }
`

const ImageBattery = styled(props => <BatteryBlue {...props} />)`
  width: 66px;
  display: block;
  margin: 25px auto 0;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 375px) {
    width: 56px;
  }
`

const IconContainer = styled.div`
  float: left;
`

const DeviceInfo = styled.div`
  display: inline-block;
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 20%;

  @media screen and (max-width: 375px) {
    margin-left: 10px;
  }
`

const DeviceName = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #424e54;
  margin: 0;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 36vw;
`

const DeviceSettingsLink = styled.a`
  color: #007bc1;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;

  span:first-child {
    font-size: 16px;
    margin: 0 5px 0 0;
  }
  span:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 28vw;
  }
`

const Linktext = styled.a`
  color: #007bc1;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-flow: column;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
  `}

  span {
    font-size: 26px;
    margin: 0 0 5px 0;
  }
`

const DeviceItem = styled.div`
  font-size: 14px;
  height: 56px;
  align-items: center;
  padding: 0 15px;
  display: flex;
  color: #424E54;
  font-weight: 600;
  width: 94vw;
  position: relative;

  ${({ index }) =>
    index !== 0 &&
    `
    box-shadow: inset 0 1px 0 0 rgba(114,126,132,0.1);
  `}

  ${({ index }) =>
    index === 0 &&
    `
    margin-top: 10px;
  `}

  ${({ active }) =>
    active &&
    `
    color: #007BC1;

    span{
      margin-right: 5px;
    }
  `}
`

const DeviceItemName = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
