// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

import {
  PageTitle,
  Loading,
  Button,
  Alert
} from 'xstorage-components'
import InstalledUnitsList from 'containers/InstalledUnitsList'

type Props = {}

export class TechnicianInstallations extends Component<Props> {
  render () {
    const {
      translations,
      isMobile,
      onboardedUnitsLength,
      totalOnboardedUnits,
      onboardedUnits,
      loading,
      loadingButton,
      showLoadMoreInfo
    } = this.props

    const {
      TECH_UNIT_INSTALLATIONS,
      TECH_UNIT_UNITS_INSTALLED,
      TECH_UNITS_LOADING,
      TECH_UNIT_LOAD_MORE_BUTTON,
      TECH_UNIT_NO_MORE_RESULTS
    } = translations

    const { fetchOnboardedUnits } = this.actions

    return (
      <Wrapper mobile={isMobile}>
        {!showLoadMoreInfo && loading && <Loading />}
        <HeaderSection mobile={isMobile}>
          <PageTitle mobile={isMobile}>{TECH_UNIT_INSTALLATIONS}</PageTitle>
          <PageSubTitle>
            {totalOnboardedUnits} {TECH_UNIT_UNITS_INSTALLED}
          </PageSubTitle>
        </HeaderSection>
        <BodySection mobile={isMobile}>
          <InstalledUnitsList
            onboardedUnits={onboardedUnits}
            onboardedUnitsLength={onboardedUnitsLength}
          />
          {showLoadMoreInfo && loadingButton && (
            <LoadMoreDiv mobile={isMobile}>
              <Button
                id={'SecondaryButton_Loading'}
                buttonType={'secondary-default'}
                buttonWidth={'200px'}
                smallShadow
              >
                <Icon>{'autorenew'}</Icon>
                {TECH_UNITS_LOADING}
              </Button>
            </LoadMoreDiv>
          )}
          {showLoadMoreInfo && !loadingButton && (
            <React.Fragment>
              {totalOnboardedUnits > onboardedUnitsLength && (
                <LoadMoreDiv mobile={isMobile}>
                  <Button
                    id={'SecondaryButton_LoadMore'}
                    buttonType={'secondary-default'}
                    buttonWidth={'200px'}
                    smallShadow
                    onClick={fetchOnboardedUnits}
                  >
                    <Icon>{'add'}</Icon>
                    {TECH_UNIT_LOAD_MORE_BUTTON}
                  </Button>
                </LoadMoreDiv>
              )}
              {totalOnboardedUnits === onboardedUnitsLength && (
                <LoadMoreDiv mobile={isMobile}>
                  <Alert
                    icon={<Icon>{'error'}</Icon>}
                    message={TECH_UNIT_NO_MORE_RESULTS}
                  />
                </LoadMoreDiv>
              )}
            </React.Fragment>
          )}
        </BodySection>
      </Wrapper>
    )
  }
}

export default Logic(TechnicianInstallations)

const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const PageSubTitle = styled.h3`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 24px;
`

const HeaderSection = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    background-color: #007BC1;
    padding: 20px 25px 4px 25px;
  `}
`

const BodySection = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    @media screen and (max-width: 580px) {
      border-width: 18vw 100vw 0 0;
    }
    
    border-width: 50px 500px 0 0;
    border-color: #007bc1 #FFFFFF transparent transparent;
    display: inline-block;
    width: 0px;
    border-style: solid;
  `}
`

const LoadMoreDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 31px;
  margin-bottom: 31px;

  ${({ mobile }) =>
    mobile &&
    `
    width: 100vw;
  `}
`
