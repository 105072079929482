// @flow
import { type DeviceStatus, type Device } from 'types/api'

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Grid, Icon } from '@material-ui/core'

import { getCommandTranslation } from 'containers/OperationModeForm/utils'
import UserPermission from 'containers/UserPermission'
import EnergyGridFlow from 'components/EnergyGridFlow'
import EnergySolarFlow from 'components/EnergySolarFlow'
import EnergyNonCriticalFlow from 'components/EnergyNonCriticalFlow'
import EnergyCriticalFlow from 'components/EnergyCriticalFlow'

import { UPDATING_STATUS_VALUES } from './utils'

import { BatteryStatus, BatteryComponent, Button } from 'xstorage-components'

type Props = {
  deviceStatus: DeviceStatus,
  isMobile: boolean,
  operationModeClick?: Function,
  batteryClick?: Function,
  translations: any,
  currentDevice: Device,
  powerBatteryOn?: Function,
  solarpanelClick?: Function,
  solarpanelClose?: Function,
  multipleSolarPanels: any,
  multipvdetails: boolean,
  loading?: boolean
}

export default class EnergyFlow extends PureComponent<Props, void> {
  render () {
    const {
      deviceStatus,
      isMobile,
      operationModeClick,
      solarpanelClick,
      solarpanelClose,
      batteryClick,
      multipleSolarPanels,
      translations,
      currentDevice,
      powerBatteryOn,
      multipvdetails,
      loading
    } = this.props

    const {
      stateOfCharge,
      batteryStatus,
      dcPvRole,
      dcPvValue,
      gridRole,
      gridValue,
      criticalLoadRole,
      criticalLoadValue,
      nonCriticalLoadRole,
      nonCriticalLoadValue,
      batteryBackupLevel
    } = deviceStatus.energyFlow || {}

    const { command, parameters } = deviceStatus.currentMode || {}

    const { DASH_OPERATION_MODE_BUTTON } = translations || {}

    let gridconnected = gridRole !== 'DISCONNECTED' && gridRole !== 'NONE'

    const isOff =
      (currentDevice && !currentDevice.powerState) ||
      !getCommandTranslation(translations, command, parameters)
    const isUpdating =
      currentDevice &&
      UPDATING_STATUS_VALUES.includes(currentDevice.updateStatus)

    return (
      <EnergyFlowContainer mobile={isMobile}>
        <Grid container>
          <Grid item xs={6}>
            {((!isUpdating &&
              !isMobile &&
              currentDevice &&
              currentDevice.powerState) ||
              (!isUpdating && isMobile)) && (
              <EnergySolarFlow
                status={dcPvRole}
                multipleSolarPanels={multipleSolarPanels}
                mobile={isMobile}
                value={dcPvValue}
                hidden={isUpdating}
                onClick={solarpanelClick}
                onClose={solarpanelClose}
                multipvdetails={multipvdetails}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {((!isUpdating &&
              !isMobile &&
              currentDevice &&
              currentDevice.powerState) ||
              (!isUpdating && isMobile)) && (
              <EnergyGridFlow
                status={gridRole}
                mobile={isMobile}
                value={gridValue}
                hidden={isUpdating}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {isMobile && (
              <BatteryComponent
                percentage={stateOfCharge}
                mode={batteryStatus || ''}
                gridconnected={gridconnected}
                onClick={batteryClick}
                batteryBackupLevel={batteryBackupLevel}
              />
            )}
            {!isMobile && (
              <React.Fragment>
                <BatteryStatus
                  mode={getCommandTranslation(
                    translations,
                    command,
                    parameters
                  )}
                  powerOn={powerBatteryOn}
                  isMobile={false}
                  translations={translations}
                  currentDevice={currentDevice}
                  deviceStatus={deviceStatus}
                  loading={loading}
                />
                {((!isUpdating && !isOff) || !deviceStatus.currentMode) && (
                  <UserPermission permission={'VIEW_OPMODE_DETAILS'} >
                    <OperationModeDiv>
                      <Button
                        buttonType={'primary-default-dark'}
                        buttonWidth={'200px'}
                        onClick={operationModeClick}
                      >
                        <Icon>{'settings'}</Icon> {DASH_OPERATION_MODE_BUTTON}
                      </Button>
                    </OperationModeDiv>
                  </UserPermission>
                )}
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={6}>
            {((!isUpdating &&
              !isMobile &&
              currentDevice &&
              currentDevice.powerState) ||
              (!isUpdating && isMobile)) && (
              <EnergyCriticalFlow
                status={criticalLoadRole}
                mobile={isMobile}
                value={criticalLoadValue}
                hidden={isUpdating}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {((!isUpdating &&
              !isMobile &&
              currentDevice &&
              currentDevice.powerState) ||
              (!isUpdating && isMobile)) && (
              <EnergyNonCriticalFlow
                status={nonCriticalLoadRole}
                mobile={isMobile}
                value={nonCriticalLoadValue}
              />
            )}
          </Grid>
        </Grid>
      </EnergyFlowContainer>
    )
  }
}

// export default Logic(EnergyFlow)

const EnergyFlowContainer = styled.div`
  padding-top: 10px;
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    padding-top: 50px;
  }

  ${({ mobile }) =>
    mobile &&
    `
    max-width: 330px;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    max-width: 815px;
    height: 400px;
  `}
`

const OperationModeDiv = styled.div`
  display: flex;
  justify-content: center;
  bottom: -16px;
  position: absolute;
  width: 100%;
  z-index: 1;

  .bg-button {
    background-color: #005eab;
  }

  button:hover .bg-button {
    background-color: #005499;
  }
`
