// @flow
import { kea } from 'kea'

import { put } from 'redux-saga/effects'
import { log } from 'utils'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'AddDevice'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'user',
        'translations',
        'devices',
        'currentRoute',
        'currentDeviceId'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  start: function * () {
    const { navigate } = this.actions
    const currentDeviceId = yield this.get('currentDeviceId')

    log('[XS-AddDevice] Start Scene', 'yellow')

    const devices = yield this.get('devices')
    if (devices.length && process.env.APP_ENV !== 'local') {
      yield put(navigate(`/${currentDeviceId}/dashboard/`))
    }
  }
})
