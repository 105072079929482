// @flow
// Helpers Functions
import React from 'react'
import { type GeneralConfigs } from 'types/global'
import * as Msal from 'msal'
import {
  AZ_AUTH_CONFIGS,
  ROUTES_FOR_INVALID_USER,
  FAKE_API,
  FAKE_APP_LOGIN,
  DEFAULT_USER_TYPE,
  PROD_MODE
} from 'config'
import { taskResolution } from 'utils'
import { mapValues } from 'lodash'
import Raven from 'raven-js'

import { Translation } from 'xstorage-components'

// auth service class based on
// eslint-disable-next-line max-len
// https://github.com/Azure-Samples/active-directory-b2c-javascript-msal-singlepageapp/issues/9#issuecomment-347556074
// TODO: create unit tests for this service

export class AuthService {
  accessToken: string
  applicationConfig: any
  app: any
  hasError: boolean = false

  constructor () {
    this.applicationConfig = AZ_AUTH_CONFIGS

    this.app = new Msal.UserAgentApplication(
      this.applicationConfig.clientID,
      this.applicationConfig.authority,
      this._onTokenCallback,
      {
        redirectUri: this.applicationConfig.redirectUri,
        cacheLocation: 'localStorage',
        validateAuthority: false
      }
    )
  }

  login = () => {
    this.app.loginRedirect(this.applicationConfig.scopes)
  }

  logout = () => {
    this.app.clearCache()
  }

  editUserProfile = () => {
    this.app = new Msal.UserAgentApplication(
      this.applicationConfig.clientID,
      this.applicationConfig.editProfileAuthority,
      this._onTokenCallback,
      {
        redirectUri: this.applicationConfig.redirectUri,
        cacheLocation: 'localStorage',
        validateAuthority: false
      }
    )
    this.login()
  }

  resetUserPassword = () => {
    this.app = new Msal.UserAgentApplication(
      this.applicationConfig.clientID,
      this.applicationConfig.forgotPasswordAuthority,
      this._onTokenCallback,
      {
        redirectUri: this.applicationConfig.redirectUri,
        cacheLocation: 'localStorage',
        validateAuthority: false
      }
    )
    this.login()
  }
  getToken = () => {
    if (this.accessToken) {
      return this.accessToken
    }

    return this.app.acquireTokenSilent(this.applicationConfig.scopes).then(
      accessToken => {
        this.accessToken = accessToken
        return accessToken
      },
      error => {
        console.error(`Error getting user token: ${error}`)
      }
    )
  }

  getUser = () => {
    return this.app.getUser()
  }

  _onTokenCallback = (
    errorDesc: any,
    token: any,
    error: any,
    tokenType: any
  ) => {
    if (token) {
      this.accessToken = token
    }

    const ignoreErrors = ['AADB2C90091']
    const desc = errorDesc || ''

    // Forgot password Error
    // The description should contain: AADB2C90118
    if (desc.indexOf('AADB2C90118') > -1) {
      // Set the authority to the forgot password
      this.resetUserPassword()
    } else {
      let unexpected = true
      for (const ingoreError of ignoreErrors) {
        unexpected = desc.indexOf(ingoreError) === -1
      }

      if (unexpected && !!error) {
        // send to the console the error ocurred
        // console.log(`Unexpected login error: ${desc}`)
        const exception = new Error(`Unexpected login error: ${desc}`)
        Raven.captureException(exception)
        this.hasError = true
      }
    }
  }
}

/**
 * Helper function to wait for app. This is for tests!
 *
 * @param {*} wrapper
 */
export const waitForAppReady = async (wrapper: any, time: number = 1000) => {
  return taskResolution(resolve => {
    wrapper.update()

    if (wrapper.find('App').prop('appLoading') === 0) {
      resolve('complete')
    }
  }, time)
}

/**
 * Getting user routes for a invalid user
 *
 * @param {*} routes
 */
export const routeForInvalidUser = (routes: {}) => {
  let filteredRoutes = {}
  mapValues(routes, (route, key) => {
    if (ROUTES_FOR_INVALID_USER.indexOf(route.path) !== -1) {
      filteredRoutes[key] = route
    }
  })
  return filteredRoutes
}

/**
 * Returns the default configurations based on config.js file
 *
 */
export const getDefaultConfigs = (): GeneralConfigs => {
  let configs: GeneralConfigs = {
    fakeAPI: FAKE_API,
    fakeLogin: FAKE_APP_LOGIN,
    userType: DEFAULT_USER_TYPE,
    showTranslations: false,
    showOnlyTranslationKeys: false,
    prodMode: PROD_MODE
  }

  /** Removed SHOW_TOOLBOX condition because
   * after adding the technician profile,
   * the page was constantly refreshing. The technician profile was never set
   */
  // if (SHOW_TOOLBOX) {
  const storageConfigs = window.localStorage.getItem('configs')
  configs = storageConfigs ? JSON.parse(storageConfigs) : configs
  // }

  return configs
}

/**
 *  Helper function that returns translation component, with
 * options for: show translation key and copy the key
 *
 * @param {*} translations
 * @param {*} translationModeActive
 * @param {*} showOnlyKeys
 */
export const generateTranslationComponent = (
  translations: any,
  translationModeActive: boolean,
  showOnlyKeys: boolean
) => {
  const clone = { ...translations }
  return mapValues(clone, (value, key) => {
    const isPlaceholder = key.indexOf('PLACEHOLDER') !== -1
    let show = translationModeActive && !isPlaceholder
    if (show) {
      return (
        <Translation transKey={key} showKey={showOnlyKeys}>
          {value}
        </Translation>
      )
    }

    return value
  })
}

export const APPLOADING_DEFAULTSTATUS = {
  fetchDefaultLanguage: true,
  checkLoggedUser: true,
  getCurrentUser: true,
  fetchDevices: true,
  fetchCurrentDeviceInfo: true,
  setCurrentDeviceInfo: true,
  setWeatherInfo: true
}
export const APPLOADING_RESETSTATUS = {
  fetchDefaultLanguage: false,
  checkLoggedUser: false,
  getCurrentUser: false,
  fetchDevices: false,
  fetchCurrentDeviceInfo: false,
  setCurrentDeviceInfo: false,
  setWeatherInfo: false
}
