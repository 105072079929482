// @flow
import { type Device, type DeviceSettingsResponse } from 'types/api'
import { kea } from 'kea'
import axios from 'axios'
import { put, call, delay } from 'redux-saga/effects'
import PropTypes from 'prop-types'
import { mapValues, pick, orderBy } from 'lodash'
import * as Check from 'validations'

import { API_BASE_URL } from 'config'
import { log, generateUrl, getFormValues, scrollToFirstFormError } from 'utils'
import { getApiErrorKey } from 'apiErrors'
import {
  isOnValidInterval,
  isValidHouseThreshold,
  isValidMaxHousePeakConsumption
} from './utils'
import {
  DEFAULT_COMMAND,
  DEFAULT_COMMAND_GERMANY,
  loadCommandInfo,
  COMMANDS_PARAMS,
  processCommandParams
} from 'containers/OperationModeForm/utils'
import { checkRolePermission } from 'containers/UserPermission/utils'
import AppLogic from 'containers/App/logic'
import DeviceSettingsLogic from 'scenes/SettingsMenu/DeviceSettings/logic'

export const DEFAULT_VALUES = {
  name: {
    value: '',
    valid: true
  },
  updateBlockedState: {
    value: false
  },
  country: {
    value: ''
  },
  timezone: {
    value: ''
  },
  address: {
    value: ''
  },
  city: {
    value: ''
  },
  countryName: {
    value: ''
  },
  timezoneName: {
    value: ''
  },
  cityName: {
    value: ''
  },
  postalCode: {
    value: ''
  },
  bmsBackupLevel: {
    value: 0
  },
  command: {
    value: DEFAULT_COMMAND
  },
  soc: {
    value: 0
  },
  power: {
    value: 0
  },
  maximumPower: {
    value: 0
  },
  powerAllocation: {
    value: 0
  },
  optimalSoc: {
    value: 0
  },
  maxHousePeakConsumption: {
    value: 0
  },
  showEnergySaving: {
    value: false
  },
  energySavingMode: {
    value: false
  },
  houseConsumptionThreshold: {
    value: 300
  }
}

const VALIDATIONS = {
  name: [Check.isRequired],
  country: [Check.isRequired],
  city: [Check.isRequired],
  defaultMode: [Check.isRequired],
  bmsBackupLevel: [isOnValidInterval],
  houseConsumptionThreshold: [isValidHouseThreshold],
  maxHousePeakConsumption: [isValidMaxHousePeakConsumption]
}

export const DEFAULT_DEVICE_INFO = {
  inverterSerialNumber: '-',
  hasPv: '-',
  bmsSerialNumber: '-',
  inverterFirmwareVersion: '-',
  bmsFirmwareVersion: '-',
  firmwareVersion: '-',
  bmsCapacity: '-',
  powerMeters: null,
  networkInterfaces: null,
  inverterIsSinglePhase: '-',
  dns: '-',
  updateBlockedState: false
}
const DEVICE_SETTINGS_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/settings`
const COUNTRIES_ENDPOINT: string = `${API_BASE_URL}/internal/countries`
const CITIES_ENDPOINT: string = `${API_BASE_URL}/internal/cities`
const TIMEZONES_ENDPOINT: string = `${API_BASE_URL}/internal/timezones`

export default kea({
  path: () => ['scenes', 'DeviceGeneralSettings'],

  connect: {
    props: [
      AppLogic,
      [
        'isMobile',
        'translations',
        'currentDevice',
        'currentDeviceId',
        'userRole',
        'user',
        'batteryReadyMode',
        'isGermany',
        'showBackBar'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ],
      DeviceSettingsLogic, [
        'setShowSuccessMessage'
      ]
    ]
  },

  actions: () => ({
    fetchDeviceInfo: () => true,
    setDeviceInfo: info => ({ info }),
    change: field => ({ field }),
    setError: error => error,
    setForm: form => ({ form }),
    submit: () => true,
    reset: () => true,
    setSuccessMessage: success => success,
    getTimezones: () => true,
    setTimezones: timezones => ({ timezones }),
    getCountries: () => true,
    setCountries: countries => ({ countries }),
    getCities: () => true,
    setCities: cities => ({ cities }),
    toggleMore: () => true,
    setFixNavbar: fix => ({ fix })
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.fetchDeviceInfo]: () => false,
        [actions.submit]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.fetchDeviceInfo]: () => null,
        [actions.submit]: () => null,
        [actions.change]: () => null
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.setForm]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => true,
        [actions.submit]: () => true,
        [actions.fetchDeviceInfo]: () => true,
        [actions.setSuccessMessage]: () => false
      }
    ],

    deviceInfo: [
      DEFAULT_DEVICE_INFO,
      PropTypes.object,
      {
        [actions.setDeviceInfo]: (state, payload) => payload.info,
        [actions.reset]: () => DEFAULT_DEVICE_INFO
      }
    ],

    more: [
      false,
      PropTypes.bool,
      {
        [actions.submit]: () => true,
        [actions.toggleMore]: (state, payload) => !state,
        [actions.reset]: () => false
      }
    ],

    timezones: [
      [],
      PropTypes.array,
      {
        [actions.setTimezones]: (state, payload) => payload.timezones,
        [actions.reset]: () => []
      }
    ],

    countries: [
      [],
      PropTypes.array,
      {
        [actions.setCountries]: (state, payload) => payload.countries,
        [actions.reset]: () => []
      }
    ],

    cities: [
      [],
      PropTypes.array,
      {
        [actions.setCities]: (state, payload) => payload.cities,
        [actions.reset]: () => []
      }
    ],

    fixNavbar: [
      false,
      PropTypes.bool,
      {
        [actions.setFixNavbar]: (state, payload) => payload.fix,
        [actions.reset]: () => false
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => false,
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    /**
     * Permissions update based on
     * https://jira-prod.tcc.etn.com/browse/XSPD-1647
     */
    userCanEditOwnerTech: [
      () => [selectors.userRole],
      (userRole: string) =>
        checkRolePermission('EDIT_UNIT_SETTINGS_OWNER_TECH', userRole),
      PropTypes.bool
    ],

    userCanEditTech: [
      () => [selectors.userRole],
      (userRole: string) =>
        checkRolePermission('EDIT_UNIT_SETTINGS_TECH', userRole),
      PropTypes.bool
    ],

    userCanEditAllButViewer: [
      () => [selectors.userRole],
      (userRole: string) =>
        checkRolePermission('EDIT_UNIT_SETTINGS_ALL_BUT_VIEWER', userRole),
      PropTypes.bool
    ]
  }),

  start: function * () {
    const { fetchDeviceInfo, navigate, setForm } = this.actions

    log('[XS-DeviceGeneralSettings] Start scene', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    const isGermany = yield this.get('isGermany')

    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    const form = yield this.get('form')

    if (isGermany) {
      form.command.value = DEFAULT_COMMAND_GERMANY
      yield put(setForm(form))
    }

    yield put(fetchDeviceInfo())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-DeviceGeneralSettings] Stop scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
    [actions.fetchDeviceInfo]: workers.fetchDeviceSettings,
    [actions.getTimezones]: workers.getTimezones,
    [actions.getCountries]: workers.getCountries,
    [actions.getCities]: workers.getCities
  }),

  workers: {
    * submit () {
      const {
        setError,
        setForm,
        navigate,
        setSuccessMessage,
        setShowSuccessMessage
      } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const isMobile = yield this.get('isMobile')
      const translations = yield this.get('translations')
      const userCanEditOwnerTech = yield this.get('userCanEditOwnerTech')
      const userCanEditAllButViewer = yield this.get('userCanEditAllButViewer')

      yield delay(500)

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        scrollToFirstFormError(validation.form, 'GeneralSettingsForm')
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        scrollToFirstFormError(validation.form, 'GeneralSettingsForm')
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)

      let data = pick(params, [
        'name',
        'country',
        'timezone',
        'address',
        'city',
        'postalCode',
        'bmsBackupLevel',
        'updateBlockedState'
      ])

      let defaultMode = {}
      defaultMode.command = params.command
      defaultMode.parameters = pick(params, COMMANDS_PARAMS[params.command])

      defaultMode = processCommandParams(defaultMode)
      data.defaultMode = defaultMode
      data.bmsBackupLevel = parseInt(data.bmsBackupLevel)

      if (params.showEnergySaving) {
        data.energySavingMode = {
          enabled: params.energySavingMode,
          houseConsumptionThreshold: params.houseConsumptionThreshold
        }
      }

      try {
        const currentDevice: Device = yield this.get('currentDevice')

        const formData = {}

        if (userCanEditAllButViewer && !userCanEditOwnerTech) {
          formData.condensed = {
            defaultMode,
            energySavingMode: data.energySavingMode,
            bmsBackupLevel: data.bmsBackupLevel
          }
        } else {
          formData.settings = data
        }

        const url = generateUrl(DEVICE_SETTINGS_ENDPOINT, {
          id: currentDevice.id
        })
        const result = yield call(axios.put, url, formData)
        const { successful } = result.data

        if (successful) {
          yield put(AppLogic.actions.fetchDevices())
        }

        if (isMobile) {
          yield put(setShowSuccessMessage(true))
          yield put(navigate(`/${currentDevice.id}/settings/`))
        } else {
          yield put(setSuccessMessage(true))
          yield delay(5000)
          yield put(setSuccessMessage(false))
        }
      } catch (catchError) {
        const result = catchError.response

        if (result) {
          const { message } = result.data
          const errorKey = getApiErrorKey(message)
          yield put(setError(translations[errorKey]))
        } else {
          yield put(setError(translations['UNEXPECTED_ERROR_SAVE_FORM']))
        }
      }
    },

    * fetchDeviceSettings () {
      const { setForm, setDeviceInfo } = this.actions

      try {
        const currentDeviceId = yield this.get('currentDeviceId')
        const url = generateUrl(DEVICE_SETTINGS_ENDPOINT, {
          id: currentDeviceId
        })
        const response = yield call(axios.get, url)
        const statusResult: DeviceSettingsResponse = response.data
        const { result, successful } = statusResult

        if (successful) {
          yield put(setDeviceInfo(result))

          const defaultMode = result.defaultMode
          let formInfo: any = result
          if (defaultMode) {
            formInfo = {
              ...formInfo,
              command: defaultMode.command,
              ...defaultMode.parameters
            }
            formInfo = loadCommandInfo(formInfo)
          }

          formInfo.cityName = formInfo.city && formInfo.city.name
          formInfo.countryName = formInfo.country && formInfo.country.name
          formInfo.timezoneName =
            formInfo.timezone && (formInfo.timezone.name || '')

          formInfo.city = formInfo.city && formInfo.city.id
          formInfo.country = formInfo.country && formInfo.country.id
          formInfo.timezone = formInfo.timezone && formInfo.timezone.id

          if (formInfo.energySavingMode) {
            formInfo.showEnergySaving =
              Object.keys(formInfo.energySavingMode).length > 0
            formInfo.houseConsumptionThreshold =
              formInfo.energySavingMode.houseConsumptionThreshold
            formInfo.energySavingMode = formInfo.energySavingMode.enabled
          }

          const info = getFormValues(DEFAULT_VALUES, formInfo)

          yield put(setForm(info))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * getTimezones () {
      const { setTimezones } = this.actions

      try {
        const { country } = yield this.get('form')

        const params = { countriesIds: country.value, size: 0 }
        const request = yield call(axios.get, TIMEZONES_ENDPOINT, { params })
        const { result } = request.data
        const cities = orderBy(result.results, ['name'], ['asc'])
        yield put(setTimezones(cities))
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * getCountries () {
      const { setCountries } = this.actions

      try {
        const params = { size: 0 }
        const request = yield call(axios.get, COUNTRIES_ENDPOINT, { params })
        const { result } = request.data
        const countries = orderBy(result.results, ['name'], ['asc'])
        yield put(setCountries(countries))
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * getCities () {
      const { setCities } = this.actions

      try {
        const { country } = yield this.get('form')

        const params = { countriesIds: country.value, size: 0 }
        const request = yield call(axios.get, CITIES_ENDPOINT, { params })
        const { result } = request.data
        if (result) {
          const cities = orderBy(result.results, ['name'], ['asc'])
          yield put(setCities(cities))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    }
  }
})
