// @flow
import moment from 'moment-timezone'
import { convertFromMilitaryTime } from 'utils'

// MAP with parameterd of each command
export const COMMANDS_PARAMS = {
  SET_CHARGE: ['power', 'soc'],
  SET_DISCHARGE: ['power', 'soc'],
  SET_BASIC_MODE: [],
  SET_MAXIMIZE_AUTO_CONSUMPTION: [],
  SET_VARIABLE_GRID_INJECTION: ['maximumPower'],
  SET_FREQUENCY_REGULATION: ['powerAllocation', 'optimalSoc'],
  SET_PEAK_SHAVING: ['maxHousePeakConsumption']
  // 'DEFAULT': []
}

// definition of default command
export const DEFAULT_COMMAND = 'SET_BASIC_MODE'
export const DEFAULT_COMMAND_GERMANY = 'SET_VARIABLE_GRID_INJECTION'
/**
 * Function for processing the given operation mode by
 * returning the parameters for charge or discharge
 * and for peak shaving mode
 *
 * @param {*} data
 */
export const processCommandParams = (data: any) => {
  if (!data) {
    return {}
  }

  const chargeCommands = ['SET_CHARGE', 'SET_DISCHARGE']
  if (chargeCommands.indexOf(data.command) !== -1) {
    data.parameters.action =
      data.command === 'SET_CHARGE' ? 'ACTION_CHARGE' : 'ACTION_DISCHARGE'
    data.command = 'SET_CHARGE'
  } else if (data.command === 'SET_PEAK_SHAVING') {
    const conValue = parseInt(data.parameters.maxHousePeakConsumption)
    data.parameters.maxHousePeakConsumption = conValue
  }

  return data
}

/**
 * load command and check conditions on that command
 *
 * Condition 1:
 *  - SET_CHARGE -> action == ACTION_CHARGE returns SET_CHARGE
 *  - SET_CHARGE -> action == ACTION_DISCHARGE returns SET_DISCHARGE
 *
 * @param {*} data
 */
export const loadCommandInfo = (data: any) => {
  if (data && data.command === 'SET_CHARGE') {
    data.command =
      data.action === 'ACTION_CHARGE' ? 'SET_CHARGE' : 'SET_DISCHARGE'
  }

  return data
}

export const getCommandTranslation = (
  translations = {},
  command = '',
  parameters = {}
) => {
  if (!command || !translations || command.length === 0) {
    return null
  }

  const hasParameters = parameters && Object.keys(parameters).length > 0

  if (command === 'SET_CHARGE' && hasParameters) {
    command =
      parameters.action === 'ACTION_CHARGE' ? 'SET_CHARGE' : 'SET_DISCHARGE'
  }

  return translations[`OPERATION_MODE_${command}`]
}

export const getUnitForCommandParam = (parameter: string) => {
  const percentage = ['soc', 'power', 'powerAllocation', 'optimalSoc']
  const wats = ['maximumPower']

  if (percentage.indexOf(parameter) !== -1) {
    return '%'
  } else if (wats.indexOf(parameter) !== -1) {
    return 'W'
  }

  return ''
}

/**
 *  get time left for operation mode time
 *
 * @param {*} endTime
 */
export const getOperModeTimeLeft = (endTime: number) => {
  let label = ''
  let left = 0

  if (endTime) {
    let endTimeDate = `${moment().format(
      'MM-DD-YYYY'
    )} ${convertFromMilitaryTime(endTime)}`
    const currentTimeDate = moment().format('MM-DD-YYYY HH:mm')

    // subtract the current time on endTime
    let end = moment(endTimeDate, 'MM-DD-YYYY HH:mm').unix()
    let start = moment(currentTimeDate, 'MM-DD-YYYY HH:mm').unix()
    if (end < start) {
      endTimeDate = `${moment()
        .add(1, 'day')
        .format('MM-DD-YYYY')} ${convertFromMilitaryTime(endTime)}`
      end = moment(endTimeDate, 'MM-DD-YYYY HH:mm').unix()
    }
    left = end - start
    left = Math.round(left / 60)

    // calculate hours and minutes
    const hours = Math.floor(left / 60)
    const minutes = left % 60
    label = `${hours}h${minutes}m`
  }

  return {
    left,
    label
  }
}

export const checkDefaultMode = (defaultMode, form) => {
  if (!defaultMode || !form) {
    return false
  }

  const currentCommandParams = COMMANDS_PARAMS[defaultMode.command]
  var isDefault: boolean = false

  if (form.command.value === defaultMode.command) {
    isDefault = true
    if (currentCommandParams.length > 0) {
      currentCommandParams.forEach(param => {
        if (form[param].value !== defaultMode.parameters[param]) {
          isDefault = false
        }
      })
    }
  }

  return isDefault
}
