// @flow
import { type DeviceStatus, type User, type Device } from 'types/api'

import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import { getCommandTranslation } from 'containers/OperationModeForm/utils'

import EnergyFlow from 'components/EnergyFlow'
import DashboardGridConsumptions from 'containers/DashboardGridConsumptions'
// eslint-disable-next-line max-len
import DashboardDesktopConsumptions from 'containers/DashboardDesktopConsumptions'
import SlidePanel from 'components/SlidePanel'
import OperationModeForm from 'containers/OperationModeForm'
import EnergyFlowDetailsModal from 'containers/EnergyFlowDetailsModal'
import OperationModeHelpModal from 'containers/OperationModeHelpModal'
import WeatherBar from 'containers/WeatherBar'

import BGMobile from 'assets/background-blue-tablet.svg'

import {
  PageTitle,
  Switcher,
  BatteryStatus,
  ModalMessage,
  HelpButton,
  MessageAlert,
  Loading
} from 'xstorage-components'

type Props = {
  deviceStatus: DeviceStatus,
  loading: boolean,
  fetching: boolean,
  error: boolean,
  isMobile: boolean,
  user: User,
  translations: any,
  visibleModals: any,
  deviceDisconnected: boolean,
  currentDevice: Device,
  userCanPowerUnit: boolean,
  multipleSolarPanels: any,
  multipvdetails: boolean,
  userCanViewOpModeDetails: boolean
}

type State = {
  switcherSelected: string
}

export class Dashboard extends Component<Props, State> {
  actions: any

  state = {
    switcherSelected: 'battery'
  }

  render () {
    const {
      loading,
      error,
      isMobile,
      user,
      visibleModals,
      translations,
      deviceDisconnected,
      currentDevice,
      userCanPowerUnit,
      multipleSolarPanels,
      multipvdetails,
      showBackBar,
      deviceStatus,
      loadingDeviceStatus,
      userCanViewOpModeDetails
    } = this.props

    const {
      showModal,
      hideModal,
      navigate,
      changeToPowerOn,
      showMultiPVDetails,
      hideMultiPVDetails
    } = this.actions

    const { command, parameters } = deviceStatus.currentMode || {}

    const { firstName, lastName } = user || {}

    const { switcherSelected } = this.state

    const {
      DASH_WELCOME,
      DASH_TITLE,
      DASH_BATTERY_STATUS,
      DASH_ENERGY_FLOW,
      DASH_ERROR_DEVICE_OFF_TITLE,
      DASH_ERROR_DEVICE_OFF_MSG,
      DASH_ERROR_DEVICE_OFF_LABEL,
      DASH_OPERATION_MODE_TITLE,
      DASH_UNIT_OFFLINE_DESKTOP,
      DASH_UNIT_OFFLINE_MOBILE
    } = translations

    const switcherItems = [
      { slug: 'battery', name: DASH_BATTERY_STATUS },
      { slug: 'energy', name: DASH_ENERGY_FLOW, disabled: !deviceStatus }
    ]

    const operationModeVisible = visibleModals.indexOf('operationMode') !== -1
    const energyFlowInfoVisible = visibleModals.indexOf('energyFlowInfo') !== -1
    const operationModeHelpVisible =
      visibleModals.indexOf('operationModeHelp') !== -1

    // const showEnergyFlowInfo = () => showModal('energyFlowInfo')
    const hideEnergyFlowInfo = () => hideModal('energyFlowInfo')
    const showOperationMode = () => showModal('operationMode')
    const hideOperationMode = () => hideModal('operationMode')
    const showOperationModeHelp = () => showModal('operationModeHelp')
    const hideOperationModeHelp = () => hideModal('operationModeHelp')

    /**
     * variable to decide the position of the message alert
     * (xstorage unit offline)
     * when in technician profile
     */
    const showOfflineMessage =
      !loadingDeviceStatus && !loading && !error && deviceDisconnected

    return (
      <Wrapper bgfull={switcherSelected === 'energy'} mobile={isMobile}>
        {showOfflineMessage && (
          <MessageAlert
            mobile={isMobile}
            isTechnician={showBackBar}
            icon={<Icon>{'warning'}</Icon>}
            message={
              !isMobile ? DASH_UNIT_OFFLINE_DESKTOP : DASH_UNIT_OFFLINE_MOBILE
            }
          />
        )}

        {loading && <Loading />}

        {error && (
          <ModalMessage
            icon={<Icon>{'report'}</Icon>}
            title={DASH_ERROR_DEVICE_OFF_TITLE}
            text={DASH_ERROR_DEVICE_OFF_MSG}
            label={DASH_ERROR_DEVICE_OFF_LABEL}
            mobile={isMobile}
            closable
          />
        )}

        <PageTitle paddingTop={isMobile ? '12px' : '0px'} mobile={isMobile}>
          {DASH_TITLE}
        </PageTitle>

        {isMobile && (
          <React.Fragment>
            <WeatherBar />
            <Switcher
              items={switcherItems}
              selected={switcherSelected}
              onChange={selected =>
                this.setState({ switcherSelected: selected })
              }
            />
            <SwitcherContainer visible={switcherSelected === 'battery'}>
              <BatteryStatus
                mode={getCommandTranslation(translations, command, parameters)}
                powerOn={userCanPowerUnit ? changeToPowerOn : undefined}
                isMobile
                onClick={() =>
                  userCanViewOpModeDetails
                    ? navigate(`/${currentDevice.id}/operation-mode/`)
                    : undefined
                }
                translations={translations}
                currentDevice={currentDevice}
                deviceStatus={deviceStatus}
                loading={loadingDeviceStatus}
              />
              <DashboardGridConsumptions />
            </SwitcherContainer>
            <SwitcherContainer visible={switcherSelected === 'energy'}>
              <EnergyFlow
                deviceStatus={deviceStatus}
                isMobile={isMobile}
                currentDevice={currentDevice}
                multipleSolarPanels={multipleSolarPanels}
                translations={translations}
                solarpanelClick={showMultiPVDetails}
                solarpanelClose={hideMultiPVDetails}
                multipvdetails={multipvdetails}
                loading={loadingDeviceStatus}
              />
            </SwitcherContainer>
          </React.Fragment>
        )}

        {!isMobile && (
          <div>
            <PageSubTitle>
              {DASH_WELCOME} {firstName} {lastName}
              ...
            </PageSubTitle>
            <DesktopEnergyFlow>
              <EnergyFlow
                deviceStatus={deviceStatus}
                isMobile={isMobile}
                multipleSolarPanels={multipleSolarPanels}
                operationModeClick={showOperationMode}
                solarpanelClick={showMultiPVDetails}
                solarpanelClose={hideMultiPVDetails}
                multipvdetails={multipvdetails}
                currentDevice={currentDevice}
                powerBatteryOn={userCanPowerUnit ? changeToPowerOn : undefined}
                translations={translations}
                loading={loadingDeviceStatus}
              />
            </DesktopEnergyFlow>
            <DashboardDesktopConsumptions />
            <SlidePanel
              open={operationModeVisible}
              onClose={hideOperationMode}
              fixed={operationModeHelpVisible}
              title={
                <span>
                  {DASH_OPERATION_MODE_TITLE}{' '}
                  <HelpButton onClick={showOperationModeHelp} />
                </span>
              }
            >
              <OperationModeForm onCancel={hideOperationMode} />
            </SlidePanel>
          </div>
        )}

        {/* Page Modals */}
        <EnergyFlowDetailsModal
          open={energyFlowInfoVisible}
          onClose={hideEnergyFlowInfo}
        />
        <OperationModeHelpModal
          open={operationModeHelpVisible}
          onClose={hideOperationModeHelp}
        />
      </Wrapper>
    )
  }
}

export default Logic(Dashboard)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }

  ${({ mobile, bgfull }) =>
    mobile &&
    !bgfull &&
    `
    min-height: calc(100vh - 56px);
    background: #f7f7f9;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    background-image: url(${BGMobile})
  `}

  ${({ mobile, bgfull }) =>
    mobile &&
    bgfull &&
    `
    min-height: calc(100vh - 56px);
    background: #007ac1;
  `}
`

const SwitcherContainer = styled.div`
  display: none;
  max-width: 450px;
  margin: 0 auto;

  ${({ visible }) => visible && `
    animation: show 1s;
    display: block;
  `}

  @keyframes show {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      display: block !important;
    }
  }
`

const PageSubTitle = styled.h3`
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  line-height: 0;
`

const DesktopEnergyFlow = styled.div`
  margin-top: 56px;
`
