// @flow
import { kea } from 'kea'
import FormData from 'form-data'
import { API_BASE_URL } from 'config'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import AppLogic from 'containers/App/logic'
import NotificationsPanelLogic from 'containers/NotificationsPanel/logic'
import UserBarLogic from 'containers/UserBar/logic'

const NOTIFICATIONS_DELETE_ENDPOINT = `${API_BASE_URL}/notifications/delete`
const NOTIFICATIONS_MARK_ALL_READ = `${API_BASE_URL}/notifications/read/all`

export default kea({
  path: () => ['scenes', 'containers', 'NotificationsList'],

  connect: {
    props: [
      AppLogic,
      ['isMobile', 'translations', 'user', 'userRole', 'currentDevice'],
      NotificationsPanelLogic,
      ['notificationsList', 'loading']
    ],
    actions: [
      NotificationsPanelLogic,
      [
        'getNotifications',
        'resetNotifications',
        'setUnreadNotificationsValue',
        'updateNotifications',
        'setLoading'
      ],
      UserBarLogic,
      ['getUnreadNotifications', 'setUnreadNotifications']
    ]
  },

  actions: () => ({
    deleteNotification: notificationId => ({ notificationId }),
    markAllNotificationsRead: () => true
  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.deleteNotification]: workers.deleteNotification,
    [actions.markAllNotificationsRead]: workers.markAllNotificationsRead
  }),

  workers: {
    * deleteNotification (payload) {
      const notificationId = payload.payload.notificationId
      const {
        getNotifications,
        resetNotifications,
        getUnreadNotifications
      } = this.actions

      const data = new FormData()
      data.append('id', notificationId)

      try {
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }

        const response = yield call(
          axios.post,
          NOTIFICATIONS_DELETE_ENDPOINT,
          data,
          config
        )

        if (response.data.successful) {
          yield put(resetNotifications())
          yield put(getNotifications())
          yield put(getUnreadNotifications())
        }
      } catch (err) {
        console.log(err)
      }
    },

    * markAllNotificationsRead () {
      const {
        setUnreadNotificationsValue,
        updateNotifications,
        setUnreadNotifications,
        setLoading
      } = this.actions
      const notificationsList = yield this.get('notificationsList')
      yield put(setLoading(true))

      try {
        yield call(axios.post, NOTIFICATIONS_MARK_ALL_READ)
        yield put(setUnreadNotificationsValue(0))
        yield put(setUnreadNotifications(false))

        notificationsList.map(notification => {
          notification.status = 'READ'
          return notification
        })

        yield put(updateNotifications(notificationsList))
        yield put(setLoading(false))
      } catch (err) {
        yield put(setLoading(false))
        console.log(err)
      }
    }
  }
})
