// @flow
import { kea } from 'kea'

import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import * as Check from 'validations'
import { API_BASE_URL } from 'config'
import { generateUrl } from 'utils'
import { getApiErrorKey } from 'apiErrors'

import AppLogic from 'containers/App/logic'

// eslint-disable-next-line max-len
const ACCEPTREQ_DEVICES_ENDPOINT = `${API_BASE_URL}/thirdparty/access_request/{access_id}/devices`
// eslint-disable-next-line max-len
const ACCEPTREQ_INFO_ENDPOINT = `${API_BASE_URL}/thirdparty/access_request/{access_id}`
// eslint-disable-next-line max-len
const ACCEPTREQ_ENDPOINT = `${API_BASE_URL}/thirdparty/access_request/{access_id}/accept`
const DEFAULT_VALUES = {
  unitsGranted: {
    value: []
  }
}

const VALIDATIONS = {
  unitsGranted: [
    Check.isRequired
  ]
}

export default kea({
  path: () => ['scenes', 'ExternalAppChooseUnits'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'user',
        'currentRoute'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => true,
    logout: () => true,
    getDevicesList: accessid => accessid,
    setDevicesList: devices => devices,
    setUpdatedDevices: devices => devices,
    getAppInfo: accessid => accessid,
    setAppInfo: app => app
  }),

  reducers: ({ actions }) => ({
    form: [DEFAULT_VALUES, PropTypes.object, {
      [actions.change]: (state, payload) =>
        Check.setAndCheckValidation(state, payload, VALIDATIONS),
      [actions.setForm]: (state, payload) =>
        Check.checkValidation(payload.form, VALIDATIONS).form,
      [actions.reset]: () => DEFAULT_VALUES
    }],

    dirty: [false, PropTypes.bool, {
      [actions.change]: () => true,
      [actions.response]: () => false,
      [actions.setError]: () => true,
      [actions.reset]: () => false
    }],

    error: [null, PropTypes.string, {
      [actions.setError]: (state, payload) => payload,
      [actions.reset]: () => null
    }],

    devicesList: [[], PropTypes.array, {
      [actions.setDevicesList]: (state, payload) => payload,
      [actions.reset]: () => []
    }],

    updatedDevices: [[], PropTypes.array, {
      [actions.setUpdatedDevices]: (state, payload) => payload,
      [actions.reset]: () => []
    }],

    appInfo: [{}, PropTypes.object, {
      [actions.setAppInfo]: (state, payload) => payload,
      [actions.reset]: () => ({})
    }],

    loading: [false, PropTypes.bool, {
      [actions.getAppInfo]: () => true,
      [actions.setAppInfo]: () => false,
      [actions.getDevicesList]: () => true,
      [actions.setDevicesList]: () => false,
      [actions.setError]: () => false,
      [actions.reset]: () => false
    }]
  }),

  selectors: ({ selectors }) => ({
    isAppAccepted: [
      () => [selectors.appInfo],
      appInfo => appInfo && appInfo.status === 'ACCEPTED',
      PropTypes.bool
    ]
  }),

  start: function * () {
    const { getDevicesList, getAppInfo } = this.actions

    const currentRoute = yield this.get('currentRoute')
    const { accessid } = currentRoute.params

    yield put(getAppInfo(accessid))
    yield put(getDevicesList(accessid))
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
    [actions.getDevicesList]: workers.getDevicesList,
    [actions.getAppInfo]: workers.getAppInfo
  }),

  workers: {
    * getDevicesList (actionPayload) {
      const { payload } = actionPayload
      const { setDevicesList, setForm } = this.actions

      try {
        const url = generateUrl(
          ACCEPTREQ_DEVICES_ENDPOINT,
          { access_id: payload }
        )
        const response = yield call(axios.get, url)
        const { result } = response.data
        const { data } = result || {}

        data.map(device => {
          device.key = device.id
          return device
        })

        const unitsGranted = {
          value: data.map(a => a.key)
        }
        yield put(setDevicesList(data))
        yield put(setForm({unitsGranted}))
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * getAppInfo (actionPayload) {
      const { payload } = actionPayload
      const { setAppInfo, setError } = this.actions

      try {
        const url = generateUrl(
          ACCEPTREQ_INFO_ENDPOINT,
          { access_id: payload }
        )
        const response = yield call(axios.get, url)
        const { result } = response.data
        yield put(setAppInfo(result))
      } catch (catchError) {
        console.log(catchError)
        yield put(setError(''))
      }
    },

    * submit (action) {
      const {
        setForm,
        navigate,
        setUpdatedDevices,
        setError
      } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)

      try {
        const currentRoute = yield this.get('currentRoute')
        const { accessid, usercode } = currentRoute.params

        const url = generateUrl(
          ACCEPTREQ_ENDPOINT,
          { access_id: accessid }
        )
        params = {
          devices: params.unitsGranted,
          userCode: usercode
        }

        const response = yield call(axios.post, url, params)
        const { successful, result } = response.data
        const { updated } = result || {}

        yield put(setUpdatedDevices(updated))

        if (successful) {
          // eslint-disable-next-line max-len
          yield put(navigate(`/hub/access_request/${accessid}/${usercode}/update-success/`))
        }
      } catch (catchError) {
        const { response } = catchError

        if (response) {
          const { data } = response
          const errorKey = getApiErrorKey(data.message)
          yield put(setError(translations[errorKey]))
        }
      }
    }
  }
})
