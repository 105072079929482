/* eslint-disable max-len */
// @flow
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  PageMainSection,
  SpaceDiv,
  List,
  Divider,
  IcMyInstallations
} from 'xstorage-components'

type Props = {
  loading: boolean,
  isMobile: boolean,
  translations: {
    USERPROF_TITLE: string,
    USERPROF_MYAPP_TITLE: string,
    USERPROF_MYAPP_NOAPPSPRESENT: string,
    USERPROF_MYAPP_NOAPPSDESCRIPTION: string,
    USERPROF_MYAPP_ACTIVEEXTERNALAPPS: string
  },
  activeAppsCount: number,
  error: string,
  myApps: []
}

class UserProfileMyApps extends Component<Props, void> {
  actions: {
    navigate: Function
  }

  render () {
    const { navigate } = this.actions
    const {
      loading,
      isMobile,
      translations,
      error,
      activeAppsCount,
      myApps
    } = this.props

    const {
      USERPROF_TITLE,
      USERPROF_MYAPP_TITLE,
      USERPROF_MYAPP_NOAPPSPRESENT,
      USERPROF_MYAPP_NOAPPSDESCRIPTION,
      USERPROF_MYAPP_ACTIVEEXTERNALAPPS
    } = translations

    const activeAppsReplaced = USERPROF_MYAPP_ACTIVEEXTERNALAPPS.replace(
      '<NUM_ACTIVE_APPS>',
      activeAppsCount.toString()
    )

    return (
      <Wrapper>
        <PageAdaptable
          title={USERPROF_TITLE}
          menu={<UserProfileMenu page={'my-apps'} />}
          onBack={() => navigate('/user-profile/')}
          isForm={false}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle mobile={isMobile}>{USERPROF_MYAPP_TITLE}</PageTitle>
            <SpaceDiv height={'34px'} />
            <Typography variant={'h5'} color={'error'}>
              {error}
            </Typography>
            {activeAppsCount === 0 && (
              <Fragment>
                <Typography
                  variant={'h6'}
                  component={'h2'}
                  color={'textSecondary'}
                  gutterBottom
                >
                  {USERPROF_MYAPP_NOAPPSPRESENT}
                </Typography>
                <Typography variant={'body1'} color={'textSecondary'}>
                  {USERPROF_MYAPP_NOAPPSDESCRIPTION}
                </Typography>
              </Fragment>
            )}
            {activeAppsCount > 0 && (
              <Fragment>
                <OpacityTypography
                  color={'textPrimary'}
                  variant={'h5'}
                  component={'p'}
                >
                  {activeAppsReplaced}
                </OpacityTypography>
                <SpaceDiv height={'15px'} />
                {myApps.map(item => (
                  <List key={item.id}>
                    <ListItem
                      button
                      onClick={
                        () => navigate(`/user-profile/my-apps/${item.id}/`)
                      }
                    >
                      <ListTextDiv>
                        <AppInfo>
                          <Typography
                            variant={'h5'}
                            component={'span'}
                          >
                            {item.appName}
                          </Typography>
                          <Typography
                            variant={'body2'}
                            component={'span'}
                          >, {item.organization} ®
                          </Typography>
                        </AppInfo>
                        <UnitsInfo>
                          <IcMyInstallations />
                          <BreakTypography
                            variant={'h5'}
                            component={'span'}
                            color={'textPrimary'}
                          >
                            {item.unitsMessage}
                          </BreakTypography>
                        </UnitsInfo>
                      </ListTextDiv>
                      <FloatIcon
                        color={'primary'}
                      >{'keyboard_arrow_right'}</FloatIcon>

                    </ListItem>
                    <Divider variant={'inset'} component='li' />
                  </List>
                ))}
              </Fragment>
            )}
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(UserProfileMyApps)

const Wrapper = styled.div``

const OpacityTypography = styled(Typography)`
  opacity: 0.8;
`

const ListTextDiv = styled.div`
  padding-left: 8px;
  width: 100%;
  max-width: 100%;
`
const ListItem = styled(List.Item)`

  &&&& {
    padding-top: 0!important;
  }
`

const AppInfo = styled.div``

const UnitsInfo = styled.div`
  padding-top: 12px;
  display: flex;

  span {
    padding-left: 12px;
  }
`

const FloatIcon = styled(Icon)`
  position: absolute;
  right: 0
`

const BreakTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
`
