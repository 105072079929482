/* eslint-disable indent */
import axios from 'axios'
import { removeUserSession } from 'utils'

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error

    if (response) {
      const { status, data } = response

      if (status === 401) {
        removeUserSession()
      }

      if (status === 412) {
        console.log('error', data)
        const message = data.message !== undefined
          ? data.message : data.statusCode
        switch (message) {
          case USER_NEEDS_ACCEPT_TC:
            window.location.href = '/terms/conditions/'
            break

          case USER_NEEDS_ACCEPT_EULA:
            window.location.href = '/terms/eula/'
            break

          case PRECONDITION_FAILED:
            window.location.href = '/terms/eula/'
            break

          case USER_INVALID_TOKEN:
            removeUserSession()
            break

          default:
            window.location.href = '/?terms=true'
            break
        }
      }
    }

    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  config => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json;charset=utf-8'
    }
    return config
  },
  error => Promise.reject(error)
)

// User Types
export const TECHNICIAN_USER_TYPE: string = 'technician'
export const CONSUMER_USER_TYPE: string = 'consumer'

// API url based on environment
let apiUrl: string
let mediaUrl: string = ''
let baseUrl: string = ''
let fakeLogin: string
let AZClientId: string = ''
let AZTenant: string = ''
let AZTenantName: string = ''
let AZLoginPolicy: string = 'B2C_1_Signup'
let AZForgotPasswordPolicy: string = 'B2C_1_Reset'
let AZEditProfilePolicy: string = 'B2C_1_Profile'
let mockAPI: boolean = false
let userType: string = CONSUMER_USER_TYPE
let showToolbox: boolean = false
let prodMode: boolean = false

switch (process.env.APP_ENV) {
  case 'local':
    baseUrl = 'http://localhost:3000'
    // apiUrl = `https://xstorage-pmrocha.centralus.cloudapp.azure.com/api`
    // AZClientId = 'b9df41d0-fcd2-4fc3-96c5-9abcae9d44c8'
    // AZTenant = 'ubieaton.onmicrosoft.com'
    apiUrl = 'https://qa.xstoragehome.com/api'
    AZClientId = '4d7d3347-4bd5-4018-97c2-86c56077aa4c'
    AZTenant = 'ubieatonqa.onmicrosoft.com'
    AZTenantName = 'ubieatonqa'
    fakeLogin = false
    mockAPI = false
    showToolbox = true
    break
  case 'test':
    apiUrl = 'https://dev.xstoragehome.com/api'
    AZClientId = '10903b84-7dc5-4dbc-8185-e4fdb37d77ac'
    AZTenant = 'ubieatondev.onmicrosoft.com'
    AZTenantName = 'ubieatondev'
    fakeLogin = true
    mockAPI = true
    break
  case 'preview':
    baseUrl = process.env.PUBLIC_URL
    apiUrl = 'https://dev.xstoragehome.com/api'
    mediaUrl = ''
    fakeLogin = false
    AZClientId = '10903b84-7dc5-4dbc-8185-e4fdb37d77ac'
    AZTenant = 'ubieatondev.onmicrosoft.com'
    AZTenantName = 'ubieatondev'
    mockAPI = true
    showToolbox = true
    break
  case 'develop':
    baseUrl = 'https://dev.xstoragehome.com'
    mediaUrl = ''
    fakeLogin = false
    apiUrl = 'https://dev.xstoragehome.com/api'
    AZClientId = '8bce376e-262a-42a8-adb2-88d81acb168e'
    AZTenant = 'xStorageDev.onmicrosoft.com'
    AZTenantName = 'xStorageDev'
    showToolbox = true
    break
  case 'qa':
    baseUrl = 'https://qa.xstoragehome.com'
    apiUrl = 'https://qa.xstoragehome.com/api'
    mediaUrl = ''
    fakeLogin = false
    AZClientId = '4d7d3347-4bd5-4018-97c2-86c56077aa4c'
    AZTenant = 'ubieatonqa.onmicrosoft.com'
    AZTenantName = 'ubieatonqa'
    prodMode = true
    break
  case 'master':
    baseUrl = 'https://xstoragehome.com'
    apiUrl = 'https://xstoragehome.com/api'
    mediaUrl = ''
    fakeLogin = false
    AZClientId = '8f06f0db-5949-4130-9f6b-874715d63dbb'
    AZTenant = 'xstorageauth.onmicrosoft.com'
    AZTenantName = 'xstorageauth'
    prodMode = true
    break
  default:
    baseUrl = 'http://localhost:3000/'
    apiUrl = 'https://dev.xstoragehome.com/api'
    mediaUrl = ''
    fakeLogin = false
}

// Environment URLS
export const BASE_URL: string = baseUrl
export const API_BASE_URL: string = apiUrl
export const MEDIA_BASE_URL: string = mediaUrl

// Other urls
// eslint-disable-next-line max-len
export const SENTRY_URL: string = 'https://5dd1ab8e54dc4b8187b986c1f07b95a1@sentry2.ubiwhere.com/71'

// Core
export const DEFAULT_DATE_FORMAT: string = 'DD-MM-YYYY'
export const API_DATE_FORMAT: string = 'YYYY-MM-DD'
export const FAKE_APP_LOGIN: string = fakeLogin
export const FAKE_API: boolean = mockAPI
export const SHOW_TOOLBOX: boolean = showToolbox

export const DEFAULT_USER_TYPE: string = userType
export const PROD_MODE: boolean = prodMode

// Auth Constants
export const USER_NEEDS_ACCEPT_TC = 'User needs to accept the latest ' +
  'version of T&C'
export const USER_NEEDS_ACCEPT_EULA = 'User needs to accept the latest ' +
  'version of Eula'
export const PRECONDITION_FAILED = 'User needs to accept the latest ' +
  'version of Eula'
export const USER_INVALID_TOKEN = 'Invalid token'
export const ROUTES_FOR_INVALID_USER = [
  '/terms/eula/',
  '/terms/conditions/',
  '/user-profile/delete/',
  '/user-profile/download/',
  '/invite/:token/',
  '/ownership/:token/'
]

// let fakeToken: string = ``

// if (process.env.NODE_ENV === 'develop') {
// eslint-disable-next-line max-len
//   fakeToken = `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJleHAiOjE1NDY2MzA4MjUsIm5iZiI6MTU0NjYyNzIyNSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5taWNyb3NvZnRvbmxpbmUuY29tLzNjNDVhNzRkLTVjOGQtNDU5MS05NjJiLWFkMmQwODdmZjI1NC92Mi4wLyIsInN1YiI6IjVjYWU5NzZlLWNmOGEtNDA4ZS05MzJlLWYwNTIzYjBlZGViZCIsImF1ZCI6IjEwOTAzYjg0LTdkYzUtNGRiYy04MTg1LWU0ZmRiMzdkNzdhYyIsIm5vbmNlIjoiZGVmYXVsdE5vbmNlIiwiaWF0IjoxNTQ2NjI3MjI1LCJhdXRoX3RpbWUiOjE1NDY2MjcyMjUsImVtYWlscyI6WyJwbXJvY2hhQHViaXdoZXJlLmNvbSJdLCJvaWQiOiI1Y2FlOTc2ZS1jZjhhLTQwOGUtOTMyZS1mMDUyM2IwZWRlYmQiLCJnaXZlbl9uYW1lIjoiUGVkcm8iLCJmYW1pbHlfbmFtZSI6IlJvY2hhIiwibmFtZSI6InVua25vd24iLCJ0ZnAiOiJCMkNfMV9SZXNldCJ9.JO6pjRVFgX3nVl7qAxVrHVhGPbSmjyE5wa_7CU7KJGGcNateqjj9E8cGVJG3eiVmHKwoh4tmUfTTGI9GiOoKGuCR5ex_CQprUQsEAif72cggUXHtLePPrsyzs3pJoV7kvjSOqYHIAZ8iyAbLpZ0mq6D_6Yd9xbbwnGgEPR10y2aLJ-WRNHof_OYkTKMUNcLQ3Tv2QdRKvcJw4dvGAvz7EzKqcMDH01RmEtsg47UWdjajoewtQGl09XrlvaXs6-OrvTbgR5SOjoXG1kE_4VePRFG9A0qt9pZaCX32x6l0k77lObOudWkJMOGHwcwVM6Yyz2cQnmXI0Aq0n--hO1490w`
// }

// export const FAKE_ID_TOKEN = fakeToken

// eslint-disable-next-line max-len
export const FAKE_ID_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJleHAiOjE1NDY2MzA4MjUsIm5iZiI6MTU0NjYyNzIyNSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5taWNyb3NvZnRvbmxpbmUuY29tLzNjNDVhNzRkLTVjOGQtNDU5MS05NjJiLWFkMmQwODdmZjI1NC92Mi4wLyIsInN1YiI6IjVjYWU5NzZlLWNmOGEtNDA4ZS05MzJlLWYwNTIzYjBlZGViZCIsImF1ZCI6IjEwOTAzYjg0LTdkYzUtNGRiYy04MTg1LWU0ZmRiMzdkNzdhYyIsIm5vbmNlIjoiZGVmYXVsdE5vbmNlIiwiaWF0IjoxNTQ2NjI3MjI1LCJhdXRoX3RpbWUiOjE1NDY2MjcyMjUsImVtYWlscyI6WyJwbXJvY2hhQHViaXdoZXJlLmNvbSJdLCJvaWQiOiI1Y2FlOTc2ZS1jZjhhLTQwOGUtOTMyZS1mMDUyM2IwZWRlYmQiLCJnaXZlbl9uYW1lIjoiUGVkcm8iLCJmYW1pbHlfbmFtZSI6IlJvY2hhIiwibmFtZSI6InVua25vd24iLCJ0ZnAiOiJCMkNfMV9SZXNldCJ9.JO6pjRVFgX3nVl7qAxVrHVhGPbSmjyE5wa_7CU7KJGGcNateqjj9E8cGVJG3eiVmHKwoh4tmUfTTGI9GiOoKGuCR5ex_CQprUQsEAif72cggUXHtLePPrsyzs3pJoV7kvjSOqYHIAZ8iyAbLpZ0mq6D_6Yd9xbbwnGgEPR10y2aLJ-WRNHof_OYkTKMUNcLQ3Tv2QdRKvcJw4dvGAvz7EzKqcMDH01RmEtsg47UWdjajoewtQGl09XrlvaXs6-OrvTbgR5SOjoXG1kE_4VePRFG9A0qt9pZaCX32x6l0k77lObOudWkJMOGHwcwVM6Yyz2cQnmXI0Aq0n--hO1490w'

// Azure Auth Configurations
export const AZ_AUTH_CONFIGS: {} = {
  clientID: AZClientId,
  // eslint-disable-next-line max-len
  // authority: `https://login.microsoftonline.com/tfp/${AZTenant}/${AZLoginPolicy}`,
  // eslint-disable-next-line max-len
  authority: `https://${AZTenantName}.b2clogin.com/${AZTenant}/${AZLoginPolicy}`,

  // eslint-disable-next-line max-len
  // forgotPasswordAuthority: `https://login.microsoftonline.com/tfp/${AZTenant}/${AZForgotPasswordPolicy}`,
  // eslint-disable-next-line max-len
  forgotPasswordAuthority: `https://${AZTenantName}.b2clogin.com/${AZTenant}/${AZForgotPasswordPolicy}`,

  // eslint-disable-next-line max-len
  // editProfileAuthority: `https://login.microsoftonline.com/tfp/${AZTenant}/${AZEditProfilePolicy}`,
  // eslint-disable-next-line max-len
  editProfileAuthority: `https://${AZTenantName}.b2clogin.com/${AZTenant}/${AZEditProfilePolicy}`,
  scopes: ['openid'],
  redirectUri: BASE_URL
}

// Translations
export const DEFAULT_LANGUAGE_CODE: string = 'en'

// Application configurations
export const FETCH_DEVICE_STATUS_DELAY: number = 10 * 1000 // 10 seconds
export const FETCH_METRICS_DELAY: number = 1000 * 60 * 5 // 5 minutes
export const UNREAD_MESSAGES_DELAY: number = 30 * 1000 // 30 seconds
export const ENERGY_FLOW_DURATION_FLOW: number = 2
// eslint-disable-next-line max-len
export const FETCH_DEVICE_INFO_DISCONNECTED_DELAY: number = 10000 * 6 // 1 minute
// eslint-disable-next-line max-len
export const FETCH_DEVICE_INFO_CONNECTED_DELAY: number = 10000 * 6 * 5 // 5 minute
export const FETCH_WEATHER_DELAY: number = 10000 * 6 * 30 // 30 minutes
export const FETCH_PENDNG_LOG_DELAY: number = 30 * 1000 // 30 seconds
export const FETCH_DISKINFO_DELAY: number = 10 * 1000 // 10 seconds
export const FETCH_DATETIME_DELAY: number = 10 * 1000 // 10 seconds
export const RESYNC_METRICS_DELAY: number = 10 * 1000 // 10 seconds
export const FETCH_SSH_STATUS_DELAY: number = 10 * 1000 // 30 seconds

// Default Units for Weather
export const DEFAULT_UNITS = 'fahrenheit'
export const GERMANY_COUNTRY_ID = 'b25f4fe9-2a34-4e68-af6e-11b338293eac'
