// @flow
import React, { Component } from 'react'
import Logic from './logic'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import { convertEpochToDate, convertMillisToSec } from 'utils'

import { UnitDetails, Paper } from 'xstorage-components'

type Props = {}

class UnitPendingAccess extends Component<Props, void> {
  render () {
    const { pendingUnits, showUnitPanel, isMobile } = this.props

    return (
      <Grid container spacing={3}>
        {pendingUnits &&
          pendingUnits.map(unit => (
            <Grid key={unit.id} item xs={12} sm={6} md={4} >
              <Paper showanimation shadow mobile={isMobile}>
                <UnitDetails
                  id={'PendingAccessUnit_Card'}
                  name={
                    (unit.device && unit.device.inverterSerialNumber) || null
                  }
                  date={convertEpochToDate(
                    convertMillisToSec(unit.createdAt),
                    'DD/MM/YYYY HH:mm'
                  )}
                  icon={<Icon>{'more_horiz'}</Icon>}
                  onClick={() => showUnitPanel(unit)}
                />
              </Paper>
            </Grid>
          ))}
      </Grid>
    )
  }
}

export default Logic(UnitPendingAccess)
