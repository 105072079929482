// @flow
import { kea } from 'kea'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import UserBarLogic from 'containers/UserBar/logic'

export default kea({
  path: () => ['scenes', 'More'],

  connect: {
    props: [
      AppLogic,
      [
        'user',
        'translations',
        'currentRoute',
        'userIsTechnician',
        'unitRelatedPage',
        'currentDeviceId'
      ],
      UserBarLogic,
      ['unreadNotifications']
    ],
    actions: [
      AppLogic,
      ['logout', 'navigate', 'setShowHelpTour'],
      UserBarLogic,
      ['getUnreadNotifications']
    ]
  },

  selectors: ({ selectors }) => ({
    userCanViewProfile: [
      () => [selectors.currentRoute, selectors.userIsTechnician],
      (currentRoute, userIsTechnician) =>
        (userIsTechnician &&
          currentRoute &&
          currentRoute.path === '/tech-more/') ||
        (!userIsTechnician &&
          currentRoute &&
          currentRoute.path &&
          currentRoute.path.includes('/more/')),
      PropTypes.bool
    ],

    userCanViewDeviceChooser: [
      () => [
        selectors.unitRelatedPage,
        selectors.userIsTechnician,
        selectors.currentDeviceId
      ],
      (unitRelatedPage, userIsTechnician, currentDeviceId) =>
        (!userIsTechnician && currentDeviceId && currentDeviceId !== 'null') ||
        (userIsTechnician && unitRelatedPage),
      PropTypes.bool
    ]
  })
})
