/**
 * LoadingPage Component
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Loading } from 'xstorage-components'

type Props = {
}

export default class LoadingPage extends PureComponent<Props, void> {
  componentWillMount () {
    if (document.body) {
      document.body.style.overflowY = 'hidden'
    }
  }

  componentWillUnmount () {
    if (document.body) {
      document.body.style.overflowY = 'auto'
    }
  }

  render () {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  background: #007BC1;
  min-height: calc(100vh);
  display: inline-block;
  width: 100%;
`
