// @flow
import {
  type Device,
  type ScheduleListResponse,
  type ScheduleEvent
} from 'types/api'
import { kea } from 'kea'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import { API_BASE_URL } from 'config'
import { generateUrl } from 'utils'
// import { } from './utils'

import PropTypes from 'prop-types'
// import * as Check from 'validations'
import { checkRolePermission } from 'containers/UserPermission/utils'

import AppLogic from 'containers/App/logic'

const DEVICE_EVENTS_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/schedule`

export default kea({
  path: () => ['scenes', 'containers', 'ScheduleWeek'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDevice',
        'currentDeviceId',
        'userRole'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    hideEventForm: () => true,
    fetchEvents: () => true,
    setEvents: (events) => ({ events }),

    addNewEvent: (initialHour, recurrence) => ({ initialHour, recurrence }),
    updateEvent: (eventId) => ({ eventId }),
    showEventForm: () => true,
    reset: () => false
  }),

  reducers: ({ actions }) => ({

    eventFormVisible: [false, PropTypes.bool, {
      [actions.showEventForm]: () => true,
      [actions.hideEventForm]: () => false,
      [actions.fetchEvents]: () => false,
      [actions.reset]: () => false
    }],

    currentEventId: [null, PropTypes.string, {
      [actions.updateEvent]: (state, payload) => payload.eventId,
      [actions.hideEventForm]: () => null,
      [actions.addNewEvent]: () => null
    }],

    initialHour: [null, PropTypes.number, {
      [actions.addNewEvent]: (state, payload) => payload.initialHour,
      [actions.updateEvent]: () => null,
      [actions.hideEventForm]: () => null
    }],

    recurrence: [null, PropTypes.string, {
      [actions.addNewEvent]: (state, payload) => payload.recurrence || null,
      [actions.updateEvent]: () => null,
      [actions.hideEventForm]: () => null
    }],

    events: [[], PropTypes.array, {
      [actions.setEvents]: (state, payload) => payload.events,
      [actions.reset]: () => []
    }]

  }),

  selectors: ({ selectors }) => ({

    /** filter events every day events */
    everydaysEvents: [
      () => [selectors.events],
      (events: Array<ScheduleEvent>) =>
        events.filter(e => e.recurrence === 'EVERYDAY'),
      PropTypes.array
    ],

    /** filter events on week days events */
    workdaysEvents: [
      () => [selectors.events, selectors.everydaysEvents],
      (events: Array<ScheduleEvent>, everydaysEvents: Array<ScheduleEvent>) => [
        ...everydaysEvents,
        ...events.filter(e => e.recurrence === 'WEEK_DAYS')
      ],
      PropTypes.array
    ],

    /** filter events on weekends events */
    weekendsEvents: [
      () => [selectors.events, selectors.everydaysEvents],
      (events: Array<ScheduleEvent>, everydaysEvents: Array<ScheduleEvent>) => [
        ...everydaysEvents,
        ...events.filter(e => e.recurrence === 'WEEKENDS')
      ],
      PropTypes.array
    ],

    /** put user permission on a prop */
    userCanEdit: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('EDIT_EVENT', userRole),
      PropTypes.bool
    ]

  }),

  start: function * () {
    const { fetchEvents } = this.actions

    yield put(fetchEvents())
  },

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.addNewEvent]: workers.gotoEventForm,
    [actions.updateEvent]: workers.gotoEventForm,
    [actions.fetchEvents]: workers.fetchEvents
  }),

  workers: {

    /**
     * Function to redirect user for a specific event form
     *
     * @param {*} action
     */
    * gotoEventForm (action) {
      const { navigate, showEventForm } = this.actions
      const isMobile = yield this.get('isMobile')
      const currentDeviceId = yield this.get('currentDeviceId')
      const { eventId } = action.payload

      const canAdd = checkRolePermission(
        'ADD_NEW_EVENT',
        yield this.get('userRole')
      )

      if (!eventId && !canAdd) {
        return false
      }

      if (isMobile) {
        const url = eventId
          ? `/${currentDeviceId}/schedule/event/${eventId}/`
          : `/${currentDeviceId}/schedule/event/`

        yield put(navigate(url))
      } else {
        yield put(showEventForm())
      }
    },

    * fetchEvents () {
      const { setEvents, navigate } = this.actions

      try {
        const currentDevice: Device = yield this.get('currentDevice')

        // eslint-disable-next-line max-len
        const url = generateUrl(DEVICE_EVENTS_ENDPOINT, { id: currentDevice.id })
        const response = yield call(axios.get, url)
        const statusResult: ScheduleListResponse = response.data
        const { result, successful } = statusResult

        if (successful) {
          yield put(setEvents(result))
        }
      } catch (er) {
        const { status, data } = er.response

        if (
          status === 400 &&
          data.message === 'You are not authorized to view the resource'
        ) {
          yield put(navigate('/401'))
        }
      }
    }

  }

})
