// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

import { EnergyComponent, EnergyFlowLabel } from 'xstorage-components'

import NonCriticalIcon from './images/non-critical-icon'
import NonCriticalFlow from './images/NonCriticalFlow'

type Props = {
  status: string,
  value: number,
  mobile?: boolean
}

export default class EnergyNonCriticalFlow extends Component<Props, void> {
  render () {
    const { status, value, mobile } = this.props

    let none = !status || status === 'NONE'
    let disconnected = status === 'DISCONNECTED'
    let producer = status === 'PRODUCER'

    return (
      <Wrapper mobile={mobile}>
        <EnergyComponent
          disconnected={disconnected}
          align={'right'}
          white={!mobile}
        >
          <ImageHouse className='sixth-step'>
            <NonCriticalIcon color={mobile ? '#FFF' : 'rgba(0,123,193,0.5)'} />
          </ImageHouse>
        </EnergyComponent>
        {!disconnected && !none && (
          <EnergyFlowContainer>
            <NonCriticalFlow reverse={producer} mobile={mobile} />
            <FlowLabel mobile={mobile}>
              <EnergyFlowLabel direction={'down'} value={value} />
            </FlowLabel>
          </EnergyFlowContainer>
        )}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  // padding-top: 15px;
  height: 120px;

  ${({ mobile }) =>
    mobile &&
    `
    position: relative;
    display: inline-block;
    width: 100%;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    position: absolute;
    bottom: 0;
    right: 0;
  `}
`

const EnergyFlowContainer = styled.div`
  position: relative;
`

const ImageHouse = styled.div`
  margin: 10px 12px;
  position: absolute;

  svg {
    position: relative;
    top: 0;
    left: 0;
  }
`

const FlowLabel = styled.div`
  position: absolute;
  width: 100px;

  > div {
    float: right;
  }

  ${({ mobile }) =>
    mobile &&
    `
    left: 0;
    top: -20px;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    right: 10px;
    top: -35px;

    label{
      color: #727E84;
      text-shadow: none;
    }
  `}
`
