/**
 * DeviceChooser Container Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'

import AppLogic from 'containers/App/logic'
import DashboardLogic from 'scenes/DashboardMenu/Dashboard/logic'

export default kea({
  path: () => ['scenes', 'containers', 'DeviceChooser'],

  connect: {
    props: [
      AppLogic, [
        'currentDevice',
        'currentDeviceId',
        'currentRoute',
        'translations',
        'devices',
        'configs'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'fetchCurrentDeviceInfo'
      ]
    ]
  },

  actions: () => ({
    updateDeviceStatus: () => ({ }),
    fetchDeviceStatusAgg: () => true
  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchDeviceStatusAgg]: function * ({ payload }) {
      yield put(DashboardLogic.actions.fetchDeviceStatusAgg(true))
    },
    [actions.updateDeviceStatus]: function * ({ payload }) {
      yield put(DashboardLogic.actions.updateDeviceStatus(true))
    }
  })
})
