// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
import PageAdaptable from 'containers/PageAdaptable'
import SupportMaintenanceOptions from 'containers/SupportMaintenanceOptions'
import UserPermission from 'containers/UserPermission'

import {
  PageTitle,
  Typography,
  Card,
  PageMainSection
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  currentDeviceId: string
}

export class DeviceSupport extends Component<Props, void> {
  actions: any

  render () {
    const {
      isMobile,
      translations,
      currentDeviceId
    } = this.props

    const { navigate } = this.actions

    const {
      UNITSETT_TITLE,
      UNITSETT_SUPPORT_TITLE,
      UNITSETT_SUPPORT_EMAIL_LABEL,
      UNITSETT_SUPPORT_EMAIL
    } = translations

    return (
      <Wrapper>
        <PageAdaptable
          title={UNITSETT_TITLE}
          menu={<DeviceSettingsMenu page={'support'} />}
          isForm={false}
          onBack={() => navigate(`/${currentDeviceId}/settings/`)}
        >
          <PageMainSection padding={!isMobile} mobile={isMobile}>
            <PageTitle
              mobile={isMobile}
              marginBottom={'10px'}
            >
              {UNITSETT_SUPPORT_TITLE}
            </PageTitle>
            <Card
              // eslint-disable-next-line max-len
              onClick={() => { window.location = 'mailto:eatoncarexstorage@eaton.com' }}
              icon={<Icon>{'email'}</Icon>}
              contentMargin={'16px'}
            >
              <CardLabel>
                {UNITSETT_SUPPORT_EMAIL_LABEL}
              </CardLabel>
              <CardTitle>
                {UNITSETT_SUPPORT_EMAIL}
              </CardTitle>
            </Card>
            <UserPermission permission={'VIEW_SUPPORT_MAINTENANCE_OPTIONS'}>
              <SupportMaintenanceOptions />
            </UserPermission>
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(DeviceSupport)

const Wrapper = styled.div`

`

const CardLabel = styled(props => <Typography {...props} />)`
  &&&&{
    color: #007BC1;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-transform: uppercase;
  }
`

const CardTitle = styled(props => <Typography {...props} />)`
  &&&&{
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #007BC1;

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
    @media screen and (max-width: 350px) {
      font-size: 9px;
    }
  }
`
