// @flow
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Grid, Icon, Typography } from '@material-ui/core'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  Button,
  Typography as SharedTypography,
  List,
  Modal,
  ModalHeader,
  ModalContent,
  Divider,
  Paper,
  ModalButtons,
  PageMainSection
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  userValid: boolean
}

type State = {
  deleteModal: boolean
}

export class UserProfileDelete extends Component<Props, State> {
  actions: any

  state = {
    deleteModal: false
  }

  render () {
    const { isMobile, translations, userValid } = this.props

    const { navigate } = this.actions

    const { deleteModal } = this.state

    const {
      USERPROF_TITLE,
      USERPROF_DELETE_TITLE,
      USERPROF_DELETE_SUB_TITLE,
      USERPROF_DELETE_DOWNLOAD_INFO_BUTTON,
      USERPROF_DELETE_DELETE_BUTTON,
      USERPROF_DELETE_MODAL_TITLE,
      USERPROF_DELETE_MODAL_SUB_TITLE,
      USERPROF_DELETE_MODAL_LABEL,
      USERPROF_DELETE_MODAL_CANCEL,
      USERPROF_DELETE_MODAL_DELETE
    } = translations

    const pionts = Object.keys(translations).filter(key =>
      /USERPROF_DELETE_INFOPARAGRAPH(\d+)/.test(key)
    )

    const content = (
      <Fragment>
        <PageTitle mobile={isMobile} blue>
          {USERPROF_DELETE_TITLE}
        </PageTitle>
        <Typography variant={'h4'} gutterBottom>
          {USERPROF_DELETE_SUB_TITLE}
        </Typography>
        {pionts.map(point => (
          <SharedTypography
            paragraph
            key={point}
            dangerouslySetInnerHTML={{ __html: translations[point] }}
          />
        ))}
        <List mobile={isMobile} listOfButtons>
          <List.Item button onClick={() => navigate('/user-profile/download/')}>
            <Icon style={{ color: '#007BC1' }}>{'get_app'}</Icon>
            <List.ItemText primary={USERPROF_DELETE_DOWNLOAD_INFO_BUTTON} />
            <Icon style={{ color: '#007BC1' }}>{'keyboard_arrow_right'}</Icon>
          </List.Item>
          <Divider variant={'inset'} component='li' />
          <List.Item
            button
            onClick={() => this.setState({ deleteModal: true })}
          >
            <Icon style={{ color: '#CA3C3D' }}>{'delete'}</Icon>
            <DeleteItemText primary={USERPROF_DELETE_DELETE_BUTTON} />
            <Icon style={{ color: '#CA3C3D' }}>{'keyboard_arrow_right'}</Icon>
          </List.Item>
          <Divider variant={'inset'} component='li' />
        </List>
      </Fragment>
    )

    return (
      <div>
        {!userValid && !isMobile && (
          <Grid container spacing={3} justify={'center'}>
            <Grid item xs={8}>
              <Paper>{content}</Paper>
            </Grid>
          </Grid>
        )}
        {!userValid && isMobile && content}

        {userValid && (
          <PageAdaptable
            title={USERPROF_TITLE}
            menu={<UserProfileMenu page={'delete-account'} />}
            onBack={() => navigate('/user-profile/')}
            isForm={false}
          >
            <PageMainSection padding mobile={isMobile}>
              {content}
            </PageMainSection>
          </PageAdaptable>
        )}
        {deleteModal && (
          <Modal
            open={deleteModal}
            size={'small'}
            onClose={() => this.setState({ deleteModal: false })}
          >
            {/* TODO: maybe we can create a common container for this */}
            <div>
              <ModalHeader red title={USERPROF_DELETE_MODAL_TITLE} />
              <ModalContent padding size={'small'} mobile={isMobile}>
                <SharedTypography>
                  <b>{USERPROF_DELETE_MODAL_SUB_TITLE}</b>
                </SharedTypography>
                <SharedTypography>
                  {USERPROF_DELETE_MODAL_LABEL}
                </SharedTypography>
              </ModalContent>
              <ModalButtons>
                <Button
                  id={'PrimaryButton_Cancel'}
                  buttonType={'primary-cancel'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={() => this.setState({ deleteModal: false })}
                >
                  {USERPROF_DELETE_MODAL_CANCEL}
                </Button>
                <Button
                  id={'PrimaryButton_Delete'}
                  buttonType={'primary-danger'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                >
                  {USERPROF_DELETE_MODAL_DELETE}
                </Button>
              </ModalButtons>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

export default Logic(UserProfileDelete)

const DeleteItemText = styled(props => <List.ItemText {...props} />)`
  &&&& {
    span {
      color: #ca3c3d !important;
    }
  }
`
