// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { getSwitcherOptions } from './utils'
import BGBlue from './images/background_blue_tablet.svg'

import ScheduleWeek from 'containers/ScheduleWeek'

import { Switcher, PageTitle } from 'xstorage-components'

type Props = {
  isMobile: boolean,
  switcherSelected: string,
  translations: any
}

export class Schedule extends Component<Props, void> {
  actions: any

  render () {
    const { isMobile, switcherSelected, translations } = this.props

    const { changeSwitcher } = this.actions

    const { SCHEDULE_TITLE } = translations

    return (
      <Wrapper mobile={isMobile}>
        <HeaderContent mobile={isMobile}>
          <PageTitle paddingTop={isMobile ? '30px' : '0px'} mobile={isMobile}>
            {SCHEDULE_TITLE}
          </PageTitle>
          {isMobile && (
            <div>
              <Switcher
                items={getSwitcherOptions(translations)}
                selected={switcherSelected}
                onChange={selected => changeSwitcher(selected)}
              />
            </div>
          )}
        </HeaderContent>
        <ScheduleContainer mobile={isMobile}>
          <ScheduleWeek activeTab={switcherSelected} />
        </ScheduleContainer>
      </Wrapper>
    )
  }
}

export default Logic(Schedule)

const Wrapper = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    background: #f7f7f9;
    height: calc(100vh - 56px);
  `}
`

const HeaderContent = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }

  ${({ mobile }) => mobile && `
    background-image: url(${BGBlue});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    position: fixed;
    z-index: 10;
    min-height: 240px;

    @media screen and (max-width: 375px) {
      min-height: 224px;
    }
    @media screen and (min-width: 550px) and (max-width: 1024px) {
      min-height: 290px;
    }
  `}

`

const ScheduleContainer = styled.div`
  width: 100%;

  ${({ mobile }) =>
    !mobile &&
    `
    height: calc(100vh - 134px);
    background-color: #FFFFFF;
  `}

  ${({ mobile }) =>
    mobile &&
    `
    padding-top: 130px;

    @media screen and (max-width: 375px) {
      padding-top: 145px;
    }
  `}
`
