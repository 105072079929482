// @flow
import Icon from '@material-ui/core/Icon'
import moment from 'moment-timezone'
import React, { Component } from 'react'
import styled from 'styled-components'
import Logic from './logic'

import FormFieldError from 'containers/FormFieldError'
import FormGeneralError from 'containers/FormGeneralError'
import {
  Button,
  DatepickerLabeled,
  Form,
  Modal,
  ModalContent,
  ModalHeader
} from 'xstorage-components'

type Props = {}

export class ForceSyncPanel extends Component<Props, void> {
  validDate = true;

  render () {
    const { submit, change } = this.actions

    const {
      translations,
      isMobile,
      onCancel,
      form,
      error,
      dirty,
      loading
    } = this.props

    const { startDate } = form

    const {
      UNITSETT_SUPPORT_METRICS_SYNC_WARNING,
      CHART_EXPORT_STARTDATE,
      CHART_EXPORT_PANEL_CANCEL_BUTTON,
      UNITSETT_SUPPORT_METRICS_SYNC_SYNCHRONIZE,
      UNITSETT_SUPPORT_METRICS_SYNC,
      UNITSETT_UPDATES_WRONG_DATE
    } = translations

    const handleSubmit = () => {
      const today = moment(new Date()).valueOf()
      const aux = startDate.value.split('/')
      const auxDate = `${aux[2]}/${aux[1]}/${aux[0]}`
      const selectedDate = moment(auxDate).valueOf()
      console.log(selectedDate > today)
      if (selectedDate > today) {
        this.validDate = false
        return false
      }
      this.validDate = true
      submit()
    }

    return (
      <Wrapper>
        {!isMobile && (
          <React.Fragment>
            <WarningDiv>
              <Icon
                style={{ color: '#CA3C3D', fontSize: '20px' }}
              >{'report'}</Icon>
              <Message>{UNITSETT_SUPPORT_METRICS_SYNC_WARNING}</Message>
            </WarningDiv>
            <Form
              mobile={isMobile}
              containerHeight={'calc(100vh - 168px)'}
              formmargin={isMobile}
            >
              <FormGeneralError dirty={dirty} error={error} />
              <Form.Row displayType={'flex'}>
                <DatepickerLabeled
                  id={'datepickerOne'}
                  onChange={val => change({ startDate: val })}
                  maxDate={moment()}
                  locale={moment.locale()}
                  utcOffset={moment().utcOffset()}
                  popperPlacement={'bottom-start'}
                  label={CHART_EXPORT_STARTDATE}
                  selected={moment(startDate.value, 'DD/MM/YYYY')}
                  margin={'16px 4px 0 4px'}
                  wrapperWidth={'100%'}
                />
                <FormFieldError
                  id={'StartDate'}
                  showError={dirty && !startDate.valid}
                  errorMessage={startDate.message}
                />
              </Form.Row>
              {this.validDate === false &&
                <WarningDiv>
                  <Icon
                    style={{ color: '#CA3C3D', fontSize: '20px' }}
                  >{'report'}</Icon>
                  <Message>{UNITSETT_UPDATES_WRONG_DATE}</Message>
                </WarningDiv>
              }
            </Form>
            <Form.Buttons fixedSideBar mobile={false}>
              <Button
                id={'PrimaryButton_Cancel'}
                margin={'0 12px'}
                buttonType={'primary-cancel'}
                buttonWidth={'163px'}
                onClick={onCancel}
              >
                {CHART_EXPORT_PANEL_CANCEL_BUTTON}
              </Button>
              <Button
                id={'PrimaryButton_Synch'}
                margin={'0 12px'}
                smallShadow
                buttonWidth={'163px'}
                onClick={handleSubmit}
                disabled={loading}
              >
                {UNITSETT_SUPPORT_METRICS_SYNC_SYNCHRONIZE}
              </Button>
            </Form.Buttons>
          </React.Fragment>
        )}
        {isMobile && (
          <Modal open size={'medium'} onCancel={onCancel}>
            <div>
              <ModalHeader title={UNITSETT_SUPPORT_METRICS_SYNC} />
              <ModalContent type={'diagonal'} size={'medium'} mobile={isMobile}>
                <WarningDiv isMobile={isMobile}>
                  <WarningIcon>{'report'}</WarningIcon>
                  <Message>{UNITSETT_SUPPORT_METRICS_SYNC_WARNING}</Message>
                </WarningDiv>
                <Form
                  mobile
                  formModal
                  containerHeight={'calc(80vh - 300px)'}
                >
                  <FormGeneralError dirty={dirty} error={error} />
                  <Form.Row padding displayType={'inline-flex'}>
                    <DatepickerLabeled
                      id={'datepickerOne'}
                      onChange={val => change({ startDate: val })}
                      maxDate={moment()}
                      locale={moment.locale()}
                      utcOffset={moment().utcOffset()}
                      popperPlacement={'bottom-start'}
                      label={CHART_EXPORT_STARTDATE}
                      selected={moment(startDate.value, 'DD/MM/YYYY')}
                      margin={'0px 4px 0 4px'}
                      wrapperWidth={'100%'}
                    />
                    <FormFieldError
                      id={'StartDate'}
                      showError={dirty && !startDate.valid}
                      errorMessage={startDate.message}
                    />
                  </Form.Row>
                </Form>
                <Form.Buttons mobile>
                  <Button
                    id={'PrimaryButton_Cancel'}
                    margin={'0 7px'}
                    buttonType={'primary-cancel'}
                    onClick={onCancel}
                    disabled={loading}
                    buttonWidth={'156px'}
                  >
                    {CHART_EXPORT_PANEL_CANCEL_BUTTON}
                  </Button>
                  <Button
                    id={'PrimaryButton_Sync'}
                    smallShadow
                    onClick={handleSubmit}
                    disabled={loading}
                    buttonWidth={'156px'}
                    margin={'0 7px'}
                  >
                    {UNITSETT_SUPPORT_METRICS_SYNC_SYNCHRONIZE}
                  </Button>
                </Form.Buttons>
              </ModalContent>
            </div>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default Logic(ForceSyncPanel)

const Wrapper = styled.div``
const WarningIcon = styled(Icon)`
  color: #ca3c3d;
  font-size: 20px;
`

const WarningDiv = styled.div`
  height: 92px;
  width: 350px;
  box-shadow: inset 0 1px 0 0 rgba(114, 126, 132, 0.1),
    inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  ${({ isMobile }) =>
    isMobile &&
    `
    margin: 4px;
    width: calc(100vw - 8vw);
    padding: 4px;
    box-shadow: inset 0 -1px 0 0 rgba(114,126,132,0.1);
  `}
`

const Message = styled.p`
  margin: 0;
  color: #ca3c3d;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0 8px;
`
