/**
 * MaintenanceHttpsTunnel Scene
 * https://jira-prod.tcc.etn.com/browse/XSPD-1826
 * Open an HTTPS tunnel on demand
 */
// @flow
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, Switch, FormControlLabel } from '@material-ui/core'

import PageAdaptable from 'containers/PageAdaptable'
import MaintenanceMenu from 'containers/MaintenanceMenu'
import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeatures from 'containers/MaintenanceSupportedFeatures'

import {
  PageTitle,
  PageMainSection,
  LabelValueInline,
  ErrorMessage,
  SpaceDiv
} from 'xstorage-components'

type Props = {
  translations: {
    MAINTENANCE_TITLE: string,
    UNITSETT_SUPPORT_HTTPS_STATUS: string,
    UNITSETT_SUPPORT_HTTPSSTATUS_OPEN_SUCCESS: string,
    UNITSETT_SUPPORT_HTTPSSTATUS_HTTPSPORT: string,
    UNITSETT_SUPPORT_HTTPSSTATUS_ENABLED: string,
    UNITSETT_SUPPORT_HTTPSSTATUS_DISABLED: string,
    UNITSETT_SUPPORT_HTTPSSTATUS_HTTPSTUNNEL: string,
    UNITSETT_SUPPORT_HTTPSSTATUS_TECHUSERNAME: string,
    UNITSETT_SUPPORT_HTTPSSTATUS_TECHPASSWORD: string,
    UNITSETT_TITLE: string
  },
  isMobile: boolean,
  loading: boolean,
  httpsStatus: {
    enabled: boolean,
    port: number,
    technicianUsername: string,
    technicianPassword: string
  },
  error: string,
  currentDevice: {
    id: string,
    connected: boolean
  },
  featureSupport: {
    httpsUpdateRequired: boolean
  },
  isSupportMenu: boolean
}

export class MaintenanceHttpsTunnel extends Component<Props, void> {
  actions : {
    setOpenStatus: Function,
    navigate: Function
  }

  render () {
    const {
      translations,
      isMobile,
      loading,
      httpsStatus,
      error,
      currentDevice,
      featureSupport,
      isSupportMenu
    } = this.props

    const {
      MAINTENANCE_TITLE,
      UNITSETT_SUPPORT_HTTPS_STATUS,
      UNITSETT_SUPPORT_HTTPSSTATUS_HTTPSPORT,
      UNITSETT_SUPPORT_HTTPSSTATUS_ENABLED,
      UNITSETT_SUPPORT_HTTPSSTATUS_DISABLED,
      UNITSETT_SUPPORT_HTTPSSTATUS_HTTPSTUNNEL,
      UNITSETT_SUPPORT_HTTPSSTATUS_TECHPASSWORD,
      UNITSETT_SUPPORT_HTTPSSTATUS_TECHUSERNAME,
      UNITSETT_TITLE
    } = translations

    const {
      enabled,
      port,
      technicianPassword,
      technicianUsername
    } = httpsStatus || {}

    const { httpsUpdateRequired } = featureSupport
    const { setOpenStatus, navigate } = this.actions

    const backUrl = isSupportMenu
      ? `/${currentDevice.id}/settings/support/`
      : `/${currentDevice.id}/maintenance/`

    return (
      <Wrapper>
        <PageAdaptable
          title={isSupportMenu ? UNITSETT_TITLE : MAINTENANCE_TITLE}
          menu={
            isSupportMenu ? (
              <DeviceSettingsMenu page={'support'} />
            ) : (
              <MaintenanceMenu page={'https-tunnel'} />
            )
          }
          isForm={false}
          onBack={() => navigate(backUrl)}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle mobile={isMobile}>
              {!isMobile && isSupportMenu && (
                <BackButton id={'BackButton'} onClick={() => navigate(backUrl)}>
                  <Icon>{'arrow_back'}</Icon>
                </BackButton>
              )}
              {UNITSETT_SUPPORT_HTTPS_STATUS}
            </PageTitle>
            <MaintenanceSupportedFeatures feature={'HTTPStunnel'} />
            {!httpsUpdateRequired &&
              <ErrorMessage>{error}</ErrorMessage>
            }
            <SpaceDiv height={'20px'} />
            {!httpsUpdateRequired &&
              <Fragment>
                <Label>{UNITSETT_SUPPORT_HTTPSSTATUS_HTTPSTUNNEL}</Label>
                <SwitchLabel
                  id={'SwitchLabel_HTTPSStatusEnabled'}
                  control={
                    <PowerSwitch
                      id={'PowerSwitch_HTTPSStatusEnabled'}
                      checked={enabled}
                      onChange={() => setOpenStatus(!enabled)}
                      classes={{
                        checked: 'checked',
                        track: 'bar'
                      }}
                      disabled={!currentDevice.connected}
                    />
                  }
                  label={
                    enabled
                      ? UNITSETT_SUPPORT_HTTPSSTATUS_ENABLED
                      : UNITSETT_SUPPORT_HTTPSSTATUS_DISABLED
                  }
                  disabled={!currentDevice.connected}
                />
                {enabled && (
                  <Fragment>
                    <LabelValueInline
                      id={'LabelValueInline_HTTPSStatusEnabled'}
                      label={UNITSETT_SUPPORT_HTTPSSTATUS_HTTPSPORT}
                      value={(port && port.toString()) || '--'}
                    />
                    <LabelValueInline
                      id={'LabelValueInline_HTTPSStatusTechUsername'}
                      label={UNITSETT_SUPPORT_HTTPSSTATUS_TECHUSERNAME}
                      value={technicianUsername || '--'}
                    />
                    <LabelValueInline
                      id={'LabelValueInline_HTTPSStatusTechPass'}
                      label={UNITSETT_SUPPORT_HTTPSSTATUS_TECHPASSWORD}
                      value={technicianPassword || '--'}
                    />
                  </Fragment>
                )}
              </Fragment>
            }
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceHttpsTunnel)

const Wrapper = styled.div``

const SwitchLabel = styled(FormControlLabel)`
  height: 48px;
  position: relative;
  margin-left: 0 !important;
  margin-right: 0;
  width: 100%;
  padding-left: 8px;

  > span:nth-child(2) {
    width: 85%;
    color: #424e54;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
  }
  box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.2);
`

const PowerSwitch = styled(Switch)`
  &&&& {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .checked {
      color: #007bc1;
    }
    .checked + .bar {
      background-color: #007bc1;
      opacity: 0.4;
    }

    ${({ disabled }) => disabled && `
      opacity: 0.5;
    `}
  }
`

const Label = styled.p`
  color: #5b6770;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  padding-left: 8px;
  margin-bottom: 0;
`

const BackButton = styled.div`
  background: transparent;
  border: 0;
  display: inline-flex;
  padding-right: 16px;
  cursor: pointer;
`
