// @flow
import { type Device, type DeviceRoleListResponse } from 'types/api'
import { kea } from 'kea'
import axios from 'axios'

import { put, call, delay } from 'redux-saga/effects'
import { API_BASE_URL } from 'config'
import { log, generateUrl } from 'utils'
import { equalsToEmail } from './utils'
import { getApiErrorKey } from 'apiErrors'

import PropTypes from 'prop-types'
import * as Check from 'validations'
import { mapValues, omit } from 'lodash'
import { checkRolePermission } from 'containers/UserPermission/utils'

import AppLogic from 'containers/App/logic'

const CREATE_INVITE_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles/invites`
const INVITES_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles`
const INVITE_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles/invites/{inviteId}`

export const DEFAULT_VALUES = {
  email: {
    value: ''
  },
  confirmEmail: {
    value: ''
  }
}

const VALIDATIONS = {
  email: [Check.isRequired, Check.Email],
  confirmEmail: [Check.isRequired, Check.Email, equalsToEmail]
}

export default kea({
  path: () => ['scenes', 'DeviceChangeOwnership'],

  connect: {
    props: [
      AppLogic,
      [
        'isMobile',
        'translations',
        'currentDevice',
        'currentDeviceId',
        'userRole'
      ]
    ],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => true,

    fetchInvites: () => true,
    setPendingInvite: invite => ({ invite }),
    resendInvitation: () => true,
    cancelInvitation: () => true,
    setLoading: loading => loading,
    setSuccessMessage: success => success
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.submit]: () => false
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.fetchInvites]: () => true,
        [actions.setPendingInvite]: () => false,
        [actions.reset]: () => true,
        [actions.submit]: () => true,
        [actions.resendInvitation]: () => true,
        [actions.setLoading]: (state, payload) => payload,
        [actions.cancelInvitation]: () => true,
        [actions.setError]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.submit]: () => null,
        [actions.change]: () => null
      }
    ],

    pendingInvite: [
      null,
      PropTypes.object,
      {
        [actions.setPendingInvite]: (state, payload) => payload.invite,
        [actions.reset]: () => null
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    /** put user permission on a prop */
    userCanChangeOwner: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('CHANGE_UNIT_OWNER', userRole),
      PropTypes.bool
    ]
  }),

  start: function * () {
    log('[XS-DeviceChangeOwnership] Start scene', 'yellow')

    const { fetchInvites, navigate } = this.actions
    const userCanChangeOwner = yield this.get('userCanChangeOwner')

    if (!userCanChangeOwner) {
      yield put(navigate('/401'))
    }

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    yield put(fetchInvites())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-DeviceChangeOwnership] Stop scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
    [actions.fetchInvites]: workers.fetchInvites,
    [actions.resendInvitation]: workers.resendInvitation,
    [actions.cancelInvitation]: workers.cancelInvitation
  }),

  workers: {
    * submit () {
      const { setError, setForm, fetchInvites } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      const validation = Check.checkValidation(form, VALIDATIONS)
      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)
      params = omit(params, ['confirmEmail'])

      params.owner = true
      params.roleManagement = true
      params.write = true

      try {
        const currentDeviceId: string = yield this.get('currentDeviceId')

        const url = generateUrl(CREATE_INVITE_ENDPOINT, { id: currentDeviceId })
        yield call(axios.post, url, params)

        yield put(fetchInvites())
      } catch (catchError) {
        const { data } = catchError.response

        if (data && data.message) {
          const errorKey = data.message
            ? getApiErrorKey(data.message)
            : 'UNEXPECTED_ERROR_SAVE_FORM'
          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    },

    * fetchInvites () {
      const { setPendingInvite, setForm } = this.actions

      try {
        const currentDeviceId: string = yield this.get('currentDeviceId')

        const url = generateUrl(INVITES_ENDPOINT, { id: currentDeviceId })
        const response = yield call(axios.get, url)
        const requestResult: DeviceRoleListResponse = response.data
        const { result, successful } = requestResult

        if (successful) {
          const pendingInvite = result.find(
            invite => invite.owner && invite.status === 'PENDING'
          )

          if (pendingInvite) {
            const form = {
              email: { value: pendingInvite.email },
              confirmEmail: { value: pendingInvite.email }
            }

            yield put(setForm(form))
            yield put(setPendingInvite(pendingInvite))
          } else {
            const form = {
              email: { value: '' },
              confirmEmail: { value: '' }
            }

            yield put(setForm(form))

            yield put(setPendingInvite(null))
          }
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * cancelInvitation () {
      const { fetchInvites, setForm } = this.actions

      try {
        const currentDeviceId: string = yield this.get('currentDeviceId')
        const pendingInvite = yield this.get('pendingInvite')

        const params = {
          id: currentDeviceId,
          inviteId: pendingInvite.id
        }

        const url = generateUrl(INVITE_ENDPOINT, params)
        yield call(axios.delete, url)

        yield put(setForm(DEFAULT_VALUES))
        yield put(fetchInvites())
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * resendInvitation () {
      const { setSuccessMessage, setLoading } = this.actions

      try {
        const currentDeviceId: Device = yield this.get('currentDeviceId')
        const pendingInvite: any = yield this.get('pendingInvite')

        const inviteId = pendingInvite.id
        const urlParams = {
          id: currentDeviceId,
          inviteId: inviteId
        }
        const url = generateUrl(INVITE_ENDPOINT, urlParams)
        const response = yield call(axios.put, url, pendingInvite)
        const { successful } = response.data

        if (successful) {
          yield put(setLoading(false))
          yield put(setSuccessMessage(true))
          yield delay(5000)
          yield put(setSuccessMessage(false))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    }
  }
})
