// @flow
import { type Device, type DeviceStatus } from 'types/api'

import axios from 'axios'
import { kea } from 'kea'
import PropTypes from 'prop-types'
import { call, delay, put, select } from 'redux-saga/effects'

import { API_BASE_URL, FETCH_DEVICE_STATUS_DELAY } from 'config'
import AppLogic from 'containers/App/logic'
import { checkRolePermission } from 'containers/UserPermission/utils'
import { generateUrl, log } from 'utils'
import { multipleSolarPanelObject } from './utils'

const DEVICE_STATUS_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/status`
const DEVICE_STATUS_AGGREGATE_ENDPOINT: string =
  `${API_BASE_URL}/devices/{id}/status-agg`
const DEVICE_POWER_COMMAND_ENDPOINT = `${API_BASE_URL}/devices/{id}/power`
const LIMIT_PRIORITY_FETCHING: number = 6
const FETCH_DEVICE_STATUS_AGGREGATED_DELAY: number = 600000 // 5min
const FETCH_DEVICE_STATUS_PRIORITY_DELAY: number = 5000
const FETCH_DEVICE_STATUS_ERROR_DELAY: number = 10000

export default kea({
  path: () => ['scenes', 'Dashboard'],

  connect: {
    actions: [AppLogic, ['navigate', 'setCurrentDeviceInfo', 'fetchDevices']],
    props: [
      AppLogic,
      [
        'currentDevice',
        'deviceDisconnected',
        'appLoading',
        'isMobile',
        'user',
        'translations',
        'userRole',
        'currentDeviceId',
        'showBackBar'
      ]
    ]
  },

  actions: () => ({
    fetchDeviceStatus: () => true,
    fetchDeviceStatusAgg: () => true,
    updateDeviceStatus: () => true,
    setLoading: status => status,
    incrementPriorityCount: () => true,
    setDeviceStatus: status => ({ status }),
    showModal: modal => ({ modal }),
    hideModal: modal => ({ modal }),
    showMultiPVDetails: multipvdetails => ({ multipvdetails }),
    hideMultiPVDetails: multipvdetails => ({ multipvdetails }),

    error: () => true,
    reset: () => true,
    changeToPowerOn: () => true
  }),

  reducers: ({ actions }) => ({
    deviceStatus: [
      {},
      PropTypes.object,
      {
        [actions.setDeviceStatus]: (state, payload) => payload.status,
        [actions.updateDeviceStatus]: () => ({}),
        [actions.reset]: () => ({})
      }
    ],

    fetchWithPriority: [
      false,
      PropTypes.bool,
      {
        [actions.updateDeviceStatus]: () => true,
        [actions.fetchDeviceStatusAgg]: () => true,
        [actions.reset]: () => false
      }
    ],

    fetchWithPriorityCount: [
      0,
      PropTypes.number,
      {
        [actions.updateDeviceStatus]: () => 0,
        [actions.fetchDeviceStatusAgg]: () => 0,
        [actions.incrementPriorityCount]: state => state + 1,
        [actions.reset]: () => 0
      }
    ],

    visibleModals: [
      [],
      PropTypes.array,
      {
        [actions.showModal]: (state, payload) => [...state, payload.modal],
        [actions.hideModal]: (state, payload) =>
          state.filter(m => m !== payload.modal),
        [actions.updateDeviceStatus]: () => [],
        [actions.fetchDeviceStatusAgg]: () => [],
        [actions.reset]: () => []
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.reset]: () => true,
        [actions.setLoading]: (state, payload) => payload,
        [actions.setDeviceStatus]: () => false,
        [actions.error]: () => false,
        [actions.setCurrentDeviceInfo]: () => false
      }
    ],

    multipvdetails: [
      false,
      PropTypes.bool,
      {
        [actions.reset]: (state, payload) => false,
        [actions.showMultiPVDetails]: (state, payload) => true,
        [actions.hideMultiPVDetails]: (state, payload) => false
      }
    ],

    loadingDeviceStatus: [
      true,
      PropTypes.bool,
      {
        [actions.updateDeviceStatus]: () => true,
        [actions.fetchDeviceStatusAgg]: () => false,
        [actions.setDeviceStatus]: () => false,
        [actions.error]: () => false,
        [actions.reset]: () => false
      }
    ],

    error: [
      false,
      PropTypes.bool,
      {
        [actions.reset]: (state, payload) => false,
        [actions.setDeviceStatus]: (state, payload) => false,
        [actions.error]: (state, payload) => true
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    deviceStatus: [
      () => [selectors.deviceStatus, selectors.translations],
      (deviceStatus, translations) => {
        let { energyFlow } = deviceStatus || {}
        if (!energyFlow) {
          return deviceStatus
        }

        const mapStatus =
          translations[`BATTERY_STATUS_${energyFlow.batteryStatus}`]
        const cloneStatus = {
          energyFlow: {
            ...deviceStatus.energyFlow,
            ...{
              batteryStatus: mapStatus
            }
          }
        }

        return {
          ...deviceStatus,
          ...cloneStatus
        }
      },
      PropTypes.object
    ],
    multipleSolarPanels: [
      () => [selectors.deviceStatus],
      (deviceStatus: DeviceStatus) => multipleSolarPanelObject(deviceStatus),
      PropTypes.object
    ],
    /** put user permission on a prop */
    userCanPowerUnit: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('UNIT_POWER_ON_OFF', userRole),
      PropTypes.bool
    ],

    userCanViewOpModeDetails: [
      () => [selectors.userRole],
      (userRole: string) =>
        checkRolePermission('VIEW_OPMODE_DETAILS', userRole),
      PropTypes.bool
    ]
  }),

  start: function * () {
    const { updateDeviceStatus, fetchDeviceStatusAgg, navigate } = this.actions

    log('[XS-Dashboard] Start Scene ', 'yellow')

    /**
     * This delay was added to solve
     * XSPD-1333: After adding a new device,user is not redirected to the device
     * When the user doesn't have any devices, after onboarding,
     *  the currentDeviceId is set before redirecting
     * but it needs time to propagate its value to all the components,
     * otherwise the user is redirected to add-device page
     */
    yield delay(100)
    const currentDeviceId = yield this.get('currentDeviceId')
    if (!currentDeviceId || currentDeviceId === 'null') {
      yield put(navigate('/add-device/'))
      return false
    }
    yield put(updateDeviceStatus())
    yield delay(200)
    yield put(fetchDeviceStatusAgg())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-Dashboard] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchDeviceStatus]: workers.fetchDeviceStatus,
    [actions.updateDeviceStatus]: workers.fetchDeviceStatus,
    [actions.fetchDeviceStatusAgg]: workers.fetchDeviceStatusAgg,
    [actions.changeToPowerOn]: workers.changeToPowerOn
  }),

  workers: {
    * fetchDeviceStatus () {
      const {
        setDeviceStatus,
        fetchDeviceStatus,
        error,
        incrementPriorityCount,
        fetchCurrentDeviceInfo
      } = this.actions

      try {
        const appLoading = yield this.get('appLoading')
        const {deviceStatus} = yield select(state => state.scenes.Dashboard)
        const currentDevice = yield this.get('currentDevice')
        const currentDeviceId = yield this.get('currentDeviceId')
        if (
          (!currentDeviceId && appLoading) ||
          currentDeviceId !== currentDevice.id
        ) {
          yield delay(1000)
          yield put(fetchDeviceStatus())
          return false
        }

        if (
          currentDevice.updateStatus === 'UPDATE_WILL_START' ||
          currentDevice.updateStatus === 'UPDATE_INITIATED'
        ) {
          yield put(fetchCurrentDeviceInfo())
        }

        const url = generateUrl(DEVICE_STATUS_ENDPOINT, {
          id: currentDeviceId
        })

        const response = yield call(axios.get, url)
        const { result, successful } = response.data || {}

        if (successful) {
          delete result.last30daysEnergyFlow
          delete result.today
          const aux = {
            last30daysEnergyFlow: deviceStatus.last30daysEnergyFlow,
            today: deviceStatus.today,
            ...result
          }

          yield put(setDeviceStatus(aux))

          let timeDelay = FETCH_DEVICE_STATUS_DELAY
          const fetchWithPriority = yield this.get('fetchWithPriority')
          const fetchWithPriorityCount = yield this.get(
            'fetchWithPriorityCount'
          )
          if (
            fetchWithPriority &&
            fetchWithPriorityCount < LIMIT_PRIORITY_FETCHING
          ) {
            timeDelay = FETCH_DEVICE_STATUS_PRIORITY_DELAY
            yield put(incrementPriorityCount())
          }
          yield delay(timeDelay)
        } else {
          yield put(error())
          yield put(setDeviceStatus({}))
          yield delay(FETCH_DEVICE_STATUS_ERROR_DELAY)
        }
      } catch (errorResponse) {
        yield put(error())
        yield put(setDeviceStatus({}))
        yield delay(FETCH_DEVICE_STATUS_ERROR_DELAY)
      }
      yield put(fetchDeviceStatus())
    },

    * fetchDeviceStatusAgg () {
      const {
        setDeviceStatus,
        fetchDeviceStatusAgg,
        error
      } = this.actions

      try {
        const currentDeviceId = yield this.get('currentDeviceId')
        const {deviceStatus} = yield select(state => state.scenes.Dashboard)

        const url = generateUrl(DEVICE_STATUS_AGGREGATE_ENDPOINT, {
          id: currentDeviceId
        })

        const response = yield call(axios.get, url)
        const { result, successful } = response.data || {}
        if (successful) {
          const {last30daysEnergyFlow, today} = result

          const auxObject = {
            last30daysEnergyFlow,
            today,
            currentMode: deviceStatus.currentMode,
            energyFlow: deviceStatus.energyFlow
          }

          yield put(setDeviceStatus(auxObject))

          let timeDelay = FETCH_DEVICE_STATUS_AGGREGATED_DELAY
          yield delay(timeDelay)
        } else {
          yield put(error())
          yield delay(FETCH_DEVICE_STATUS_ERROR_DELAY)
        }
      } catch (errorResponse) {
        yield put(error())
        yield delay(FETCH_DEVICE_STATUS_ERROR_DELAY)
      }
      yield put(fetchDeviceStatusAgg())
    },

    * changeToPowerOn () {
      const { error, fetchDevices, setLoading } = this.actions

      yield put(setLoading(true))

      try {
        const currentDevice: Device = yield this.get('currentDevice')

        const data = {
          parameters: { state: true }
        }
        const url = generateUrl(DEVICE_POWER_COMMAND_ENDPOINT, {
          id: currentDevice.id
        })

        yield call(axios.post, url, data)

        yield put(fetchDevices())
      } catch (err) {
        console.log(err)
        yield put(error('Error trying to change device state'))
        yield put(fetchDevices())
      }
    }
  }
})
