// @flow
import { type GeneralConfigs } from 'types/global'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import {
  Icon,
  Drawer,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Divider,
  Typography
} from '@material-ui/core'
import { TECHNICIAN_USER_TYPE, CONSUMER_USER_TYPE } from 'config'

type Props = {
  configs: GeneralConfigs
}

type State = {
  open: boolean
}

class ConfigToolBox extends Component<Props, State> {
  actions: any

  state = {
    open: false
  }

  handleUserTypeChange = ({ target }) => {
    const { changeConfig, navigate } = this.actions
    changeConfig({ userType: target.value })
    navigate('/')
  }

  render () {
    const { configs } = this.props

    const { open } = this.state

    const { changeConfig } = this.actions

    const userTypes = [
      {
        value: CONSUMER_USER_TYPE,
        name: 'Consumer'
      },
      {
        value: TECHNICIAN_USER_TYPE,
        name: 'Technician'
      }
    ]

    const formContainer = (
      <div>
        <FormControl fullWidth>
          <InputLabel htmlFor='age-simple'>User Type</InputLabel>

          <Select
            native
            value={configs.userType}
            onChange={this.handleUserTypeChange}
          >
            {userTypes.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </Select>
          <FormHelperText>
            Allow to define the user type of the application
          </FormHelperText>
        </FormControl>
        <br />
        <br />
        <Divider />
        <FormControl component='fieldset'>
          <FormGroup>
            <FormControlLabel
              label='Use fake API'
              control={
                <Checkbox
                  checked={configs.fakeAPI}
                  onChange={() => changeConfig({ fakeAPI: !configs.fakeAPI })}
                  value='checkedB'
                  color='primary'
                />
              }
            />
            <FormControlLabel
              label='Use Admin User Token'
              control={
                <Checkbox
                  checked={configs.fakeLogin}
                  onChange={() =>
                    changeConfig({ fakeLogin: !configs.fakeLogin })
                  }
                  value='checkedB'
                  color='primary'
                />
              }
            />
            <FormControlLabel
              label='Show Translations'
              control={
                <Checkbox
                  checked={configs.showTranslations}
                  onChange={() =>
                    changeConfig({
                      showTranslations: !configs.showTranslations
                    })
                  }
                  value='checkedB'
                  color='primary'
                />
              }
            />
            <FormControlLabel
              label='Show only translation keys'
              control={
                <Checkbox
                  checked={configs.showOnlyTranslationKeys}
                  disabled={!configs.showTranslations}
                  onChange={() =>
                    changeConfig({
                      showOnlyTranslationKeys: !configs.showOnlyTranslationKeys
                    })
                  }
                  value='checkedB'
                  color='primary'
                />
              }
            />
            <FormControlLabel
              label='Production Mode'
              control={
                <Checkbox
                  checked={configs.prodMode}
                  onChange={() => changeConfig({ prodMode: !configs.prodMode })}
                  value='checkedB'
                  color='primary'
                />
              }
            />
          </FormGroup>
        </FormControl>
      </div>
    )

    return (
      <Wrapper>
        <ToolBoxButton
          color='primary'
          aria-label='Edit'
          open={open}
          onClick={() => this.setState({ open: !open })}
        >
          <Icon>{'tune'}</Icon>
        </ToolBoxButton>
        {open && (
          <Drawer
            anchor='right'
            open={open}
            onClose={() => this.setState({ open: false })}
          >
            <ConfigContainer>
              <Typography variant='display1' gutterBottom>
                Configurations
              </Typography>
              {formContainer}
            </ConfigContainer>
          </Drawer>
        )}
      </Wrapper>
    )
  }
}

export default Logic(ConfigToolBox)

const Wrapper = styled.div``

const ToolBoxButton = styled(Fab)`
  &&&& {
    position: fixed;
    right: -40px;
    top: 45%;
    border-radius: 10px 0 0 10px;
    z-index: 100000;

    ${({ open }) => open && `
      right: 400px;
    `}

    ${({ open }) => !open && `
      &:hover{
        right: 0;
      }
    `}
  }
`

const ConfigContainer = styled.div`
  width: 400px;
  padding: 26px;
`
