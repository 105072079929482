// @flow
import {
  type EulaResponse,
  type Eula,
  type UserResponse
} from 'types/api'
import { kea } from 'kea'

import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import { API_BASE_URL } from 'config'
import { log } from 'utils'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'

const FETCH_EULA_ENDPOINT: string = `${API_BASE_URL}/internal/eulas/current`
const UPDATE_EULA_ENDPOINT: string = `${API_BASE_URL}/users/me`

export default kea({
  path: () => ['scenes', 'TermsAndEula'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'user',
        'language',
        'userValid',
        'userIsTechnician'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'start'
      ]
    ]
  },

  actions: () => ({
    error: () => ({ }),
    reset: () => ({ }),

    fetchCurrentEula: () => ({ }),
    setCurrentEula: (eula: Eula) => ({ eula }),

    acceptCurrentEula: () => ({ })

  }),

  reducers: ({ actions }) => ({

    currentEula: [{}, PropTypes.object, {
      [actions.reset]: (state, payload) => ({}),
      [actions.setCurrentEula]: (state, payload) => payload.eula
    }],

    loading: [true, PropTypes.bool, {
      [actions.reset]: (state, payload) => true,
      [actions.error]: (state, payload) => false,
      [actions.setCurrentEula]: (state, payload) => false
    }],

    error: [null, PropTypes.any, {
      [actions.error]: (state, payload) => true
    }]

  }),

  start: function * () {
    log('[XS-TermsAndEula] Start Scene', 'yellow')

    const { fetchCurrentEula } = this.actions

    yield put(fetchCurrentEula())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-TermsAndEula] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchCurrentEula]: workers.fetchCurrentEula,
    [actions.acceptCurrentEula]: workers.acceptCurrentEula
  }),

  workers: {
    * fetchCurrentEula () {
      const { setCurrentEula, error } = this.actions

      const language: string = yield this.get('language')
      try {
        const data = {
          language: language
        }

        // eslint-disable-next-line max-len
        const response = yield call(axios.get, FETCH_EULA_ENDPOINT, { params: data })
        const statusResult: EulaResponse = response.data
        const { result, successful } = statusResult

        if (successful) {
          yield put(setCurrentEula(result))
        } else {
          yield put(error())
        }
      } catch (error) {
        console.log(error)
      }
    },

    * acceptCurrentEula () {
      const {
        navigate,
        start
      } = this.actions

      const currentEula: Eula = yield this.get('currentEula')
      const language: string = yield this.get('language')

      try {
        const data = {
          eulaVersion: currentEula.version,
          language: language
        }
        const response = yield call(axios.put, UPDATE_EULA_ENDPOINT, data)
        const statusResult: UserResponse = response.data
        const { successful } = statusResult

        if (successful) {
          yield put(navigate('/'))
          yield put(start())
        }
      } catch (error) {
        console.log(error)
      }
    }

  }

})
