// @flow
import { kea } from 'kea'

import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'
import { size } from 'lodash'
import { API_BASE_URL } from 'config'
import AppLogic from 'containers/App/logic'

const INSTALLATIONS_ENDPOINT = `${API_BASE_URL}/devices/onboarding`

export default kea({
  path: () => ['scenes', 'TechnicianInstallations'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'isMobile',
        'user'
      ]
    ]
  },

  actions: () => ({
    fetchOnboardedUnits: () => true,
    setOnboardedUnits: units => ({ units }),
    reset: () => true,
    error: () => true,
    setTotalOnboardedUnits: total => ({ total }),
    setLoadingButton: () => true,
    setShowLoadMoreInfo: () => true
  }),

  reducers: ({ actions }) => ({
    onboardedUnits: [[], PropTypes.array, {
      [actions.setOnboardedUnits]:
        (state, payload) => [ ...state, ...payload.units ],
      [actions.reset]: () => []
    }],

    loading: [true, PropTypes.bool, {
      [actions.setOnboardedUnits]: () => false,
      [actions.error]: () => false,
      [actions.reset]: () => true
    }],

    totalOnboardedUnits: [0, PropTypes.number, {
      [actions.setTotalOnboardedUnits]: (state, payload) => payload.total,
      [actions.error]: () => 0
    }],

    loadingButton: [false, PropTypes.bool, {
      [actions.setLoadingButton]: (state) => !state,
      [actions.reset]: () => false
    }],

    showLoadMoreInfo: [false, PropTypes.bool, {
      [actions.setShowLoadMoreInfo]: (state) => true,
      [actions.reset]: () => false
    }]
  }),

  selectors: ({ selectors }) => ({
    onboardedUnitsLength: [
      () => [selectors.onboardedUnits],
      (onboardedUnits) => size(onboardedUnits),
      PropTypes.number
    ]
  }),

  start: function * () {
    const { fetchOnboardedUnits } = this.actions

    yield put(fetchOnboardedUnits())
  },

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchOnboardedUnits]: workers.fetchOnboardedUnits
  }),

  workers: {
    * fetchOnboardedUnits () {
      const {
        setOnboardedUnits,
        error,
        setTotalOnboardedUnits,
        setLoadingButton,
        setShowLoadMoreInfo
      } = this.actions

      const onboardedUnitsLength = yield this.get('onboardedUnitsLength')
      const user = yield this.get('user')

      yield put(setLoadingButton())

      // eslint-disable-next-line max-len
      let url = INSTALLATIONS_ENDPOINT + `?size=9&offset=${onboardedUnitsLength}`

      try {
        if (user.isAdmin) {
          url = url + `&userId=${user.id}`
        }

        const response = yield call(axios.get, url)
        let { result, successful } = response.data

        if (successful) {
          yield put(setTotalOnboardedUnits(result.total))
          yield put(setOnboardedUnits(result.data))

          if (result.total > result.data.length) {
            yield put(setShowLoadMoreInfo())
          }
        }
        yield put(setLoadingButton())
      } catch (er) {
        yield put(error())
        yield put(setLoadingButton())
      }
    }
  }

})
