// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import OperationModeForm from 'containers/OperationModeForm'
import OperationModeHelpModal from 'containers/OperationModeHelpModal'

import { HelpButton } from 'xstorage-components'

type Props = {
  translations: any,
  operationModeHelpVisible: boolean
}

class OperationMode extends Component<Props, void> {
  actions: any

  render () {
    const {
      operationModeHelpVisible,
      translations,
      currentDeviceId
    } = this.props

    const {
      showOperationModeHelp,
      hideOperationModeHelp,
      navigate
    } = this.actions

    const { DASH_OPERATION_MODE_TITLE } = translations

    return (
      <Wrapper>
        <Typography variant={'h4'}>
          {DASH_OPERATION_MODE_TITLE}{' '}
          <HelpButton onClick={showOperationModeHelp} />
        </Typography>
        <OperationModeForm
          onCancel={() => navigate(`/${currentDeviceId}/dashboard/`)}
        />

        <OperationModeHelpModal
          open={operationModeHelpVisible}
          onClose={hideOperationModeHelp}
        />
      </Wrapper>
    )
  }
}

export default Logic(OperationMode)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0px;
`
