// @flow
import { type ChartVariables, type ChartFilters } from 'scenes/Chart/types'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import ChartComponent from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import { getChartData, getChartOptions } from './utils'
import Icon from '@material-ui/core/Icon'
import moment from 'moment-timezone'

import { ChartTooltip, IcSun } from 'xstorage-components'

type Props = {
  variables: ChartVariables,
  filters: ChartFilters,
  metrics: {},
  fetching: boolean,
  peak: number,
  ticksToHide: string,
  translations: {},
  updated: boolean,
  viewSun: boolean,
  showInvalidTzMessage: Function
}

class ChartDayStats extends Component<Props, void> {
  render () {
    const {
      variables,
      metrics,
      fetching,
      peak,
      ticksToHide,
      translations,
      updated,
      showInvalidTzMessage,
      viewSun,
      filters
    } = this.props

    if (fetching) {
      return <Wrapper />
    }

    const isCurrentDay =
      (filters &&
        moment(filters.selectedDayPicker).format('DD/MM/YYYY') ===
          moment().format('DD/MM/YYYY')) ||
      false
    const letShowSun = isCurrentDay ? viewSun : false

    const data = canvas =>
      getChartData(
        canvas,
        metrics,
        filters,
        variables,
        translations,
        letShowSun
      )
    const options = getChartOptions(ticksToHide, filters, updated)

    return (
      <Wrapper>
        {isCurrentDay && (
          <Sun
            peak={viewSun ? peak : 50}
            onClick={() => (!viewSun ? showInvalidTzMessage() : undefined)}
          >
            <ImageSun />
            {!viewSun && <Icon>{'report'}</Icon>}
          </Sun>
        )}
        <ChartComponent
          type='LineWithLine'
          data={data}
          options={options}
          redraw
        />
        <ChartTooltip />
      </Wrapper>
    )
  }
}

export default Logic(ChartDayStats)

const Wrapper = styled.div`
  height: 350px;
  padding-top: 30px;

  @media screen and (min-width: 1424px) {
    height: 500px;
  }
`

const Sun = styled.div`
  position: absolute;
  top: 0;
  left: ${({ peak }) => `calc(${peak}%  - 25px)`};

  ${({ onClick }) =>
    !!onClick &&
    `
    cursor: pointer;
  `}

  > span {
    color: rgba(202, 60, 61, 1);
    font-size: 22px;
    width: 30px;
    height: 30px;
    opacity: 1 !important;
    position: absolute;
    top: 0;
    right: 0;
  }
`

const ImageSun = styled(props => <IcSun {...props} />)``
