// @flow
import { type NavRoute } from 'types/global'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

import UserMenu from 'containers/UserMenu'
import WeatherBar from 'containers/WeatherBar'

type Props = {
  currentRoute: NavRoute
}

class UserBar extends Component<Props, void> {
  actions: any

  componentDidUpdate (prevProps) {
    if (prevProps.currentRoute.key !== this.props.currentRoute.key) {
      this.actions.getUnreadNotifications()
    }
  }

  render () {
    const { currentRoute, showBackBar, unreadNotifications } = this.props

    const { showNotifications } = this.actions

    return (
      <Wrapper showBackBar={showBackBar}>
        {!currentRoute.hideWheather && (
          <WeatherBar relative color={'#727E84'} />
        )}
        <React.Fragment>
          <Notifications
            onClick={showNotifications}
            unread={unreadNotifications}
            className='second-step'
          >
            <Icon>{'notifications'}</Icon>
          </Notifications>
          {!showBackBar && (
            <UserOptions>
              <UserMenu />
            </UserOptions>
          )}
        </React.Fragment>
      </Wrapper>
    )
  }
}

export default Logic(UserBar)

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 10px;
  display: flex;
  color: #727e84;
  z-index: 100;

  ${({ showBackBar }) =>
    showBackBar &&
    `
    top: 6vh;
    right: 1vw
  `}
`

const Notifications = styled.div`
  margin: 15px 20px 0 40px;
  position: relative;

  ${({ unread }) =>
    unread &&
    `
    &:after{
      position: absolute;
      content: '';
      right: 1px;
      top: 1px;
      height: 8px;
      width: 8px;
      background-color: #CA3C3D;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
  `}

  ${({ onClick }) =>
    !!onClick &&
    `
    cursor: pointer;
  `}
`

const UserOptions = styled.div`
  margin: 15px 20px 0;
`
