// @flow
import { type ScheduleEvent } from 'types/api'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import _ from 'lodash'
import ReactDOM from 'react-dom'
import { Icon } from '@material-ui/core'
import { scrollTo } from 'utils'
import { getCurrentFloatTime } from './utils'
import { getCommandTranslation } from 'containers/OperationModeForm/utils'

import SlidePanel from 'components/SlidePanel'
import ScheduleEventForm from 'containers/ScheduleEventForm'
import ScheduleEventInfo from 'components/ScheduleEvent'
import UserPermission from 'containers/UserPermission'

import { RoundedButton, ScheduleRow } from 'xstorage-components'

type Props = {
  isMobile: boolean,
  activeTab?: 'workdays' | 'weekends',
  eventFormVisible: boolean,
  currentEventId: number,
  translations: any,
  workdaysEvents: Array<ScheduleEvent>,
  weekendsEvents: Array<ScheduleEvent>
}

type State = {
  currentHourPosition: number,
  scrolled: boolean
}

class ScheduleWeek extends Component<Props, State> {
  actions: any

  state = {
    currentHourPosition: 0,
    scrolled: false
  }

  componentDidUpdate () {
    this.updateHourPosition()
  }

  componentDidMount () {
    this.updateHourPosition()
  }

  updateHourPosition = () => {
    const { isMobile } = this.props

    const floatTime: number = getCurrentFloatTime()
    const timeOffset: number = isMobile ? 240 : 80
    const node: any = ReactDOM.findDOMNode(this.refs.schedule)
    const currentHourPosition: number = node
      ? (node.scrollHeight * floatTime) / 24
      : 0

    if (this.state.currentHourPosition !== currentHourPosition) {
      // check if the page alredy been scrolled
      if (!this.state.scrolled) {
        setTimeout(() => {
          const hourNode: any = ReactDOM.findDOMNode(this.refs.hour)
          const scheduleElem = document.getElementById('schedule-container')
          if (hourNode) {
            scrollTo(scheduleElem, hourNode.offsetTop - timeOffset, 1000)
          }
        }, 200)
      }

      // update the state
      this.setState({
        currentHourPosition: currentHourPosition,
        scrolled: true
      })
    }
  }

  render () {
    const {
      isMobile,
      activeTab,
      eventFormVisible,
      currentEventId,
      translations,
      workdaysEvents,
      weekendsEvents
    } = this.props

    const { addNewEvent, updateEvent, hideEventForm } = this.actions

    const { currentHourPosition } = this.state

    const {
      SCHEDULE_WORKDAYS,
      SCHEDULE_WEEKENDS,
      SCHEDULE_EVENT_ADD,
      SCHEDULE_EVENT_EDIT
    } = translations

    // Function that generates the events for the schedule
    const generateEvents = (eventsList: Array<any>, full: boolean) => {
      return (
        eventsList &&
        eventsList.map(({ id, startTime, endTime, command, parameters }) => (
          <ScheduleEventInfo
            key={id}
            start={startTime / 100}
            end={endTime / 100}
            name={getCommandTranslation(translations, command, parameters)}
            mobile={isMobile}
            full={!isMobile && full}
            onClick={() => updateEvent(id)}
            eventIcon={'edit'}
          />
        ))
      )
    }

    // Workdays Column
    const workdays = (
      <Workdays mobile={isMobile}>
        {_.times(24, i => (
          <ScheduleRow
            key={i}
            hour={i}
            label={'true'}
            mobile={isMobile}
            onClick={hour => addNewEvent(hour, 'WEEK_DAYS')}
          />
        ))}
        {generateEvents(workdaysEvents, false)}
      </Workdays>
    )

    // Weekends Column
    const weekends = (
      <Weekends mobile={isMobile}>
        {_.times(24, i => (
          <ScheduleRow
            key={i}
            hour={i}
            label={isMobile ? 'true' : ''}
            mobile={isMobile}
            onClick={hour => addNewEvent(hour, 'WEEKENDS')}
          />
        ))}
        {generateEvents(weekendsEvents, true)}
      </Weekends>
    )

    // The Schedule cotent based on device type
    const schedule = (
      <div>
        {!isMobile && (
          <DesktopSchedule>
            {workdays}
            {weekends}
          </DesktopSchedule>
        )}
        {isMobile && (
          <MobileSchedule>
            {activeTab === 'workdays' && workdays}
            {activeTab === 'weekends' && weekends}
          </MobileSchedule>
        )}
      </div>
    )

    return (
      <Wrapper>
        {!isMobile && (
          <Header>
            <LabelTitle />
            <ColumnTitle>{SCHEDULE_WORKDAYS}</ColumnTitle>
            <ColumnTitle>{SCHEDULE_WEEKENDS}</ColumnTitle>
          </Header>
        )}
        <ScrollContainer
          mobile={isMobile}
          ref='schedule'
          id='schedule-container'
        >
          {schedule}
          <CurrentHour ref='hour' top={currentHourPosition} />
        </ScrollContainer>
        {!isMobile && (
          <SlidePanel
            overlay
            open={eventFormVisible}
            onClose={hideEventForm}
            fixed={eventFormVisible}
            title={!currentEventId ? SCHEDULE_EVENT_ADD : SCHEDULE_EVENT_EDIT}
          >
            <ScheduleEventForm
              eventId={currentEventId}
              onCancel={hideEventForm}
            />
          </SlidePanel>
        )}
        <UserPermission permission={'ADD_NEW_EVENT'}>
          <AddEventButton onClick={() => addNewEvent(0)}>
            <Icon>{'add'}</Icon>
          </AddEventButton>
        </UserPermission>
      </Wrapper>
    )
  }
}

export default Logic(ScheduleWeek)

const Wrapper = styled.div``

const ScrollContainer = styled.div`
  position: relative;
  overflow-y: scroll;

  ${({ mobile }) =>
    mobile &&
    `
    height: calc(100vh - 186px);
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    height: calc(100vh - 183px);
  `}
`

const DesktopSchedule = styled.div`
  width: 100%;
  display: flex;
  padding-left: 1px;
  height: calc(100vh - 178px);
  position: relative;
`

const MobileSchedule = styled.div`
  width: 100%;
  padding-top: 18%;
  position: relative;
  padding-bottom: 56px;

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    padding-top: 15%;
  }
`

const Workdays = styled.div`
  position: relative;
  width: 100%;
  display: table;

  ${({ mobile }) =>
    !mobile &&
    `
    width: calc(50% + 60px);
  `}

  ${({ mobile }) =>
    mobile &&
    `
    background: #f7f7f9;
  `}    

  > div:nth-child(1) > div:nth-child(1) div {
    display: none;
  }
`

const Weekends = styled.div`
  position: relative;
  width: 100%;
  display: table;

  ${({ mobile }) =>
    !mobile &&
    `
    width: calc(50% - 60px);
    background-color: rgba(114, 126, 132, 0.05);
  `}
`

const Header = styled.div`
  box-shadow: 0 5px 20px 0 rgba(0, 123, 193, 0.05),
    0 0 10px 0 rgba(114, 126, 132, 0.1);
  display: flex;
  position: relative;
  z-index: 10;
`

const ColumnTitle = styled.div`
  width: calc(50% - 50px);
  color: #007bc1;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-size: 16px;
  border-left: 1px solid rgba(114, 126, 132, 0.15);
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-left: none;
  }
`

const LabelTitle = styled(ColumnTitle)`
  width: 100px;
`

const AddEventButton = styled(props => <RoundedButton {...props} />)`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    bottom: 80px;
    right: 30px;
  }
`

const CurrentHour = styled.div`
  position: absolute;
  top: ${({ top }) => `calc(${top}px)`};
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 15px;
  border-color: transparent transparent transparent #007ac1;
`
