// @flow
import { kea } from 'kea'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import { getSunPeakPosition } from './utils'

export default kea({
  path: () => ['scenes', 'containers', 'ChartDayStats'],

  connect: {
    props: [
      AppLogic, [
        'translations'
      ],
      ({ scenes }) => scenes.Chart, [
        'filters',
        'variables',
        'metrics',
        'fetching',
        'updated',
        'filters'
      ]
    ]
  },

  selectors: ({ selectors }) => ({

    /* returns the position of the sun based on peak hour  */
    peak: [
      () => [selectors.metrics],
      (metrics) => metrics && getSunPeakPosition(metrics.sunData),
      PropTypes.number
    ]
  })

})
