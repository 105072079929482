// @flow
import { kea } from 'kea'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'containers', 'SupportMaintenanceOptions'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'isMobile',
        'currentDevice'
      ],
      ({ scenes }) => scenes.containers.MaintenanceSupportedFeatures, [
        'featureSupport'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  }
})
