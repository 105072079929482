// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Grid, Icon } from '@material-ui/core'

import OperationModeParams from 'containers/OperationModeParams'
import FormGeneralError from 'containers/FormGeneralError'
import FormFieldError from 'containers/FormFieldError'
import UserPermission from 'containers/UserPermission'

import {
  Button,
  Typography,
  LoadingCircular,
  Modal,
  ModalHeader,
  ModalContent,
  InputText,
  ModalButtons,
  TimePicker,
  Select,
  PanelActions,
  Form
} from 'xstorage-components'

type Props = {
  eventId: number,
  isMobile: boolean,
  form: any,
  onCancel: Function,
  translations: any,
  loading: boolean,
  error: string,
  dirty: boolean,
  userCanEdit: boolean,
  userCanDelete: boolean
}

type State = {
  confirmModal: boolean
}

class ScheduleEventForm extends Component<Props, State> {
  actions: any

  state = {
    confirmModal: false
  }

  render () {
    const {
      eventId,
      isMobile,
      form,
      onCancel,
      translations,
      loading,
      error,
      dirty,
      userCanEdit,
      userCanDelete
    } = this.props

    const { change, submit, deleteEvent } = this.actions

    const {
      SCHEDULE_EVENT_DELETE,
      SCHEDULE_EVENT_CANCEL,
      SCHEDULE_EVENT_SAVE,
      SCHEDULE_EVENT_START_TIME,
      SCHEDULE_EVENT_END_TIME,
      SCHEDULE_EVENT_RECURRENCY,
      SCHEDULE_EVENT_DELETE_MODAL_TITLE,
      SCHEDULE_EVENT_DELETE_MODAL_SUBTITLE,
      SCHEDULE_EVENT_DELETE_MODAL_SUBTITLE_1,
      SCHEDULE_EVENT_DELETE_MODAL_CANCEL,
      SCHEDULE_EVENT_DELETE_MODAL_DELETE,
      SCHEDULE_WORKDAYS,
      SCHEDULE_WEEKENDS,
      SCHEDULE_EVERYDAY
    } = translations

    const { startTime, endTime, recurrence } = form

    const { confirmModal } = this.state

    if (loading) {
      return <LoadingCircular />
    }

    let actions = []
    if (userCanDelete) {
      actions = [
        ...actions,
        {
          key: 'delete',
          label: SCHEDULE_EVENT_DELETE,
          icon: <DeleteIcon>{'delete'}</DeleteIcon>,
          onClick: () => this.setState({ confirmModal: true })
        }
      ]
    }

    let recurrenceOptions = [
      { val: 'WEEK_DAYS', label: SCHEDULE_WORKDAYS },
      { val: 'WEEKENDS', label: SCHEDULE_WEEKENDS },
      { val: 'EVERYDAY', label: SCHEDULE_EVERYDAY }
    ]

    return (
      <Wrapper>
        {!isMobile && eventId && !!actions.length && (
          <PanelActions actions={actions} />
        )}
        {isMobile && !!eventId && userCanDelete && (
          <Delete onClick={() => this.setState({ confirmModal: true })}>
            <Icon>{'delete'}</Icon>
          </Delete>
        )}
        <Form
          mobile={isMobile}
          position={'absolute'}
          leftPosition={'0'}
          formPadding={'0'}
          containerHeight={
            !isMobile && `calc(100vh - ${eventId ? 250 : 168}px)`
          }
          formmargin={isMobile}
        >
          <FormGeneralError dirty={dirty} error={error} full />
          <Form.Row>
            <CustomGrid mobile={isMobile ? 1 : 0}>
              <Grid item xs={6}>
                {!isMobile ? (
                  <TimePicker
                    id={'TimePicker_StartTime'}
                    label={SCHEDULE_EVENT_START_TIME}
                    time={startTime.value}
                    error={dirty && !startTime.valid}
                    draggable={false}
                    timeFormat={'HH:mm'}
                    disabled={!userCanEdit}
                    onTimeChange={({ hour, minute }) =>
                      change({
                        startTime: `${hour}:${minute}`,
                        endTime: endTime.value
                      })
                    }
                    containerMargin={'16px 0 0 8px'}
                  />
                ) : (
                  <InputText
                    id={'StartTime'}
                    label={SCHEDULE_EVENT_START_TIME}
                    type='time'
                    error={dirty && !startTime.valid}
                    value={startTime.value}
                    disabled={!userCanEdit}
                    onChange={({ target }) =>
                      change({ startTime: target.value })
                    }
                    padding={'0 16px 0 0'}
                    inputProps={{
                      step: 1800 // 30 min
                    }}
                  />
                )}
                <FormFieldError
                  id={'StartTime'}
                  showError={dirty && !startTime.valid}
                  errorMessage={startTime.message}
                />
              </Grid>
              <Grid item xs={6}>
                {!isMobile ? (
                  <TimePicker
                    id={'TimePicker_EndTime'}
                    label={SCHEDULE_EVENT_END_TIME}
                    error={dirty && !endTime.valid}
                    time={endTime.value}
                    timeFormat={'HH:mm'}
                    draggable={false}
                    disabled={!userCanEdit}
                    direction={'right'}
                    onTimeChange={({ hour, minute }) =>
                      change({
                        endTime: `${hour}:${minute}`,
                        startTime: startTime.value
                      })
                    }
                    containerMargin={'16px 8px 0 0'}
                  />
                ) : (
                  <InputText
                    id={'EndTime'}
                    label={SCHEDULE_EVENT_END_TIME}
                    type='time'
                    error={dirty && !endTime.valid}
                    value={endTime.value}
                    disabled={!userCanEdit}
                    onChange={({ target }) => change({ endTime: target.value })}
                    inputProps={{
                      step: 1800 // 30 min
                    }}
                  />
                )}
                <FormFieldError
                  id={'EndTime'}
                  showError={dirty && !endTime.valid}
                  errorMessage={endTime.message}
                />
              </Grid>
            </CustomGrid>
          </Form.Row>
          <Form.Row>
            <Select
              label={SCHEDULE_EVENT_RECURRENCY}
              options={recurrenceOptions}
              selected={recurrence.value}
              disabled={!userCanEdit}
              onChange={selected => change({ recurrence: selected })}
              containerMargin={!isMobile && '0 8px'}
              full
            />
          </Form.Row>
          <OperationModeParams
            change={change}
            form={form}
            dirty={dirty}
            disabled={!userCanEdit}
            isSchedule
            full
          />
          <UserPermission permission={'EDIT_EVENT'}>
            <Form.Buttons fixedSideBar mobile={isMobile}>
              <Button
                margin={'0 12px'}
                buttonType={'primary-cancel'}
                buttonWidth={'163px'}
                onClick={onCancel}
              >
                {SCHEDULE_EVENT_CANCEL}
              </Button>
              <Button
                margin={'0 12px'}
                buttonWidth={'163px'}
                onClick={submit}
              >
                {SCHEDULE_EVENT_SAVE}
              </Button>
            </Form.Buttons>
          </UserPermission>
        </Form>
        {confirmModal && (
          <Modal
            open={confirmModal}
            size={'small'}
            onClose={() => this.setState({ confirmModal: false })}
          >
            <div>
              <ModalHeader title={SCHEDULE_EVENT_DELETE_MODAL_TITLE} />
              <ModalContent padding size={'small'} mobile={isMobile}>
                <Typography>
                  <b>{SCHEDULE_EVENT_DELETE_MODAL_SUBTITLE}</b>
                </Typography>
                <Typography>
                  {SCHEDULE_EVENT_DELETE_MODAL_SUBTITLE_1}
                </Typography>
              </ModalContent>
              <ModalButtons>
                <Button
                  margin={'0 12px'}
                  buttonType={'primary-cancel'}
                  buttonWidth={'156px'}
                  onClick={() => this.setState({ confirmModal: false })}
                >
                  {SCHEDULE_EVENT_DELETE_MODAL_CANCEL}
                </Button>
                <Button
                  margin={'0 12px'}
                  smallShadow
                  buttonWidth={'156px'}
                  onClick={deleteEvent}
                >
                  {SCHEDULE_EVENT_DELETE_MODAL_DELETE}
                </Button>
              </ModalButtons>
            </div>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default Logic(ScheduleEventForm)

const Wrapper = styled.div``

const Delete = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 550px) {
    top: 25px;
    right: 20px;
  }
`

const DeleteIcon = styled(Icon)`
  font-size: 20px !important;

  &:hover {
    color: #006ead;
  }
`

const CustomGrid = styled(Grid)`
  display: flex;

  ${({ mobile }) =>
    !mobile &&
    `
    > div:first-child {
      margin-right: 24px;
    }
  `}
`
