// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import background from 'scenes/InternetExplorer/data/left-background@2x.png'
import chrome from 'scenes/InternetExplorer/data/chrome-logo@2x.png'
import safari from 'scenes/InternetExplorer/data/safari-logo@2x.png'
import edge from 'scenes/InternetExplorer/data/edge-logo@2x.png'
import opera from 'scenes/InternetExplorer/data/opera-logo@2x.png'
import firefox from 'scenes/InternetExplorer/data/firefox-logo@2x.png'

import { PageTitle, Typography } from 'xstorage-components'

type Props = {
  translations: {
    ERR_IE_BROWSER_NOT_SUPPORTED: string,
    ERR_IE_TRY_DIFFERENT_BROWSER: string,
    ERR_IE_SUPPORTED_BROWSER: string
  }
}

class InternetExplorer extends Component<Props, void> {
  render () {
    const { translations } = this.props

    const {
      ERR_IE_BROWSER_NOT_SUPPORTED,
      ERR_IE_TRY_DIFFERENT_BROWSER,
      ERR_IE_SUPPORTED_BROWSER
    } = translations

    return (
      <Wrapper>
        <BackgroundImage src={background} alt='error' />
        <ErrorContainer>
          <Title>{'Oops!'}</Title>
          <SubTitle id='SubTitle_BrowserNotSupported'>
            {ERR_IE_BROWSER_NOT_SUPPORTED}
          </SubTitle>
          <Text
            id='Text_TryDifferentBrowser'
            paragraph
            dangerouslySetInnerHTML={{ __html: ERR_IE_TRY_DIFFERENT_BROWSER }}
          />
          <Typography
            id='Typography_SupportedBrowsers'
            variant={'h4'}
            gutterBottom
          >
            {ERR_IE_SUPPORTED_BROWSER}
          </Typography>
          <a
            id='Logo_Chrome'
            // eslint-disable-next-line max-len
            href='https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAjw6vvoBRBtEiwAZq-T1ZWFyyp5n5lzw_VyHxo03LZe9T4CxBmFsWfFrOVjRbVJp9v747ZcghoCdK4QAvD_BwE&gclsrc=aw.ds'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LogoImage src={chrome} height={55} width={55} alt='chrome' />
          </a>
          <a
            id='Logo_Firefox'
            href='https://www.mozilla.org/en-US/firefox/new/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LogoImage src={firefox} height={55} width={55} alt='firefox' />
          </a>
          <a
            id='Logo_Opera'
            href='https://www.opera.com/download'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LogoImage src={opera} height={55} width={55} alt='opera' />
          </a>
          <a
            id='Logo_Edge'
            href='https://www.microsoft.com/en-us/windows/microsoft-edge'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LogoImage src={edge} height={55} width={55} alt='edge' />
          </a>
          <a
            id='Logo_Safari'
            href='https://support.apple.com/downloads/safari'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LogoImage src={safari} height={55} width={55} alt='safari' />
          </a>
        </ErrorContainer>
      </Wrapper>
    )
  }
}

export default Logic(InternetExplorer)

const LogoImage = styled.img`
  margin-right: 24px;
`

const BackgroundImage = styled.img`
  height: 99vh;
  width: auto;
`

const Wrapper = styled.div`
  max-height: 100%;
`

const ErrorContainer = styled.div`
  position: fixed;
  top: 25vh;
  left: 55vw;
  max-width: 35vw;
  text-align: left;
`

const Title = styled(props => <PageTitle {...props} />)`
  color: #007bc1;
  font-family: Eaton;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 56px;
  line-height: 56px;
  text-align: left;
`

const SubTitle = styled.h2`
  color: #007bc1;
  font-family: 'Open Sans';
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
`

const Text = styled(props => <Typography {...props} />)`
  &&&& {
    color: #424e54;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

    a {
      color: #333;
      text-decoration: underline;
    }
  }
`
