// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import UnitsSearchPage from 'containers/UnitsSearchPage'

import { PageTitle, BackButton } from 'xstorage-components'

type Props = {}

export class TechnicianUnitsSearch extends Component<Props, void> {
  actions: any

  render () {
    const { isMobile, translations } = this.props
    const { navigate } = this.actions
    const { TECH_UNIT_SEARCH_FOR_XSTORAGE_UNIT } = translations

    return (
      <React.Fragment>
        <HeaderContainer>
          <BackButton id={'BackButton'} onClick={() => navigate('/')} />
          <PageTitle mobile={isMobile} blue>
            {TECH_UNIT_SEARCH_FOR_XSTORAGE_UNIT}
          </PageTitle>
        </HeaderContainer>
        <UnitsSearchPage />
      </React.Fragment>
    )
  }
}

export default Logic(TechnicianUnitsSearch)

const HeaderContainer = styled.div`
  width: 100%;
  padding: 25px 24px 0;


  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`
