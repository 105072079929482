export const replaceMultiple = (
  findInString,
  stringsToReplace,
  matchingStrings
) => {
  return findInString.replace(
    stringsToReplace,
    matched => matchingStrings[matched]
  )
}
