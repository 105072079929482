// @flow

export const requiredOnAddMode = (value: any, state: any) => {
  if (state.formType.value === 'edit') {
    return { result: 'end' }
  }

  return { result: 'continue' }
}

export const getFormHeight = (type: string, status: string) => {
  const isNotRequest = ['INVITE', 'ROLE']

  if (type === 'REQUEST' && status === 'PENDING') {
    return '100%'
  } else if (type !== 'REQUEST' && status === 'PENDING') {
    return 'calc(100% - 110px)'
  } else if (isNotRequest.indexOf(type) !== -1) {
    return 'calc(100% - 55px)'
  }

  return '100%'
}
