/**
 * Onboarding Scene
 *
 * How to get to this page:
 * 1 - login with a tech user in cloud and add a unit
 * 2 - with the simulator running a unit wiht SN not registered,
 * finish setup (in the end, the onboarding request is done)
 * 3 - postman, there is an endpoint to create onboardings
 */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { Grid, Icon, Typography } from '@material-ui/core'
import * as animationData from './data/no-units.json'

import FormGeneralError from 'containers/FormGeneralError'
import FormFieldError from 'containers/FormFieldError'

import {
  Button,
  Form,
  InputText,
  BackButton,
  SpaceDiv,
  Loading
} from 'xstorage-components'

import BGMobile from 'assets/background-blue-tablet.svg'

type Props = {
  isMobile: boolean,
  translations: any,
  isOnboarding: boolean,
  newToken: string,
  form: {
    token: {
      value: string,
      valid: boolean,
      message: string
    },
    inverterSerialNumber: {
      value: string,
      valid: boolean,
      message: string
    },
    email: {
      value: string,
      valid: boolean,
      message: string
    }
  },
  dirty: boolean,
  error: string,
  currentDeviceId: string,
  loading: boolean
}

class Onboarding extends Component<Props> {
  actions: {
    change: Function,
    submit: Function,
    navigate: Function
  }

  render () {
    const {
      isMobile,
      translations,
      isOnboarding,
      newToken,
      form,
      dirty,
      error,
      currentDeviceId,
      loading
    } = this.props

    const { token, inverterSerialNumber, email } = form

    const { change, submit, navigate } = this.actions

    const {
      ADDUNIT_ONBOARDING_TITLE,
      ADDUNIT_ONBOARDING_SUBTITLE,
      ADDUNIT_ONBOARDING_DESC,
      ADDUNIT_ONBOARDING_UNIT_TOKEN,
      ADDUNIT_ONBOARDING_UNIT_SN,
      ADDUNIT_ONBOARDING_INVITE_EMAIL,
      ADDUNIT_ONBOARDING_HELP,
      ADDUNIT_ONBOARDING_SUBMIT
    } = translations

    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData
    }

    const animation = (
      <NoDevicesAnim mobile={isMobile} onboarding={isOnboarding}>
        <Lottie
          options={options}
          isClickToPauseDisabled
          height={isMobile ? 350 : '60vh'}
          width={isMobile ? 400 : '60vh'}
        />
      </NoDevicesAnim>
    )

    let content = (
      <TextContainer mobile={isMobile} onboarding>
        <Typography variant={'h1'} color={'primary'}>
          {ADDUNIT_ONBOARDING_TITLE}
        </Typography>
        <SpaceDiv height={'30px'} />
        <Typography
          variant={'h2'}
          color={'primary'}
          align={isMobile ? 'center' : 'left'}
          paragraph
        >
          {ADDUNIT_ONBOARDING_SUBTITLE}
        </Typography>
        <Typography
          variant={'h3'}
          color={'textPrimary'}
          align={isMobile ? 'center' : 'left'}
          dangerouslySetInnerHTML={{ __html: ADDUNIT_ONBOARDING_DESC }}
        />
        <FormContainer mobile={isMobile}>
          <FormGeneralError dirty={dirty} error={error} full />
          {newToken && (
            <Form.Row>
              <InputText
                id={'Token'}
                label={ADDUNIT_ONBOARDING_UNIT_TOKEN}
                type='text'
                value={token.value}
                error={dirty && !token.valid}
                onChange={({ target }) => change({ token: target.value })}
              />
              <FormFieldError
                id={'Token'}
                showError={dirty && !token.valid}
                errorMessage={token.message}
              />
            </Form.Row>
          )}
          <Form.Row>
            <InputText
              id={'SerialNumber'}
              label={ADDUNIT_ONBOARDING_UNIT_SN}
              type='text'
              value={inverterSerialNumber.value}
              error={dirty && !inverterSerialNumber.valid}
              onChange={({ target }) =>
                change({ inverterSerialNumber: target.value })
              }
            />
            <FormFieldError
              id={'SerialNumber'}
              showError={dirty && !inverterSerialNumber.valid}
              errorMessage={inverterSerialNumber.message}
            />
          </Form.Row>
          <Form.Row>
            <InputText
              id={'Email'}
              label={ADDUNIT_ONBOARDING_INVITE_EMAIL}
              type='text'
              value={email.value}
              error={dirty && !email.valid}
              onChange={({ target }) =>
                change({ email: target.value.toLowerCase() })
              }
            />
            <FormFieldError
              id={'Email'}
              showError={dirty && !email.valid}
              errorMessage={email.message}
            />
          </Form.Row>
          <InputHelp>{ADDUNIT_ONBOARDING_HELP}</InputHelp>
          <ButtonWrapper>
            <Button
              id={'PrimaryButton_Submit'}
              buttonWidth={'160px'}
              onClick={submit}
            >
              {ADDUNIT_ONBOARDING_SUBMIT}
            </Button>
          </ButtonWrapper>
        </FormContainer>
      </TextContainer>
    )

    const url = currentDeviceId
      ? `/${currentDeviceId}/dashboard/`
      : '/add-device/'

    return (
      <Wrapper mobile={isMobile} onboarding={isOnboarding}>
        {loading && <Loading />}
        {isMobile && (
          <div>
            <BackButton
              id={'BackButton'}
              color={'#FFFFFF'}
              buttonPosition={'absolute'}
              onClick={() => navigate(url)}
            />
            {animation}
            {content}
          </div>
        )}

        {!isMobile && (
          <div>
            <CloseButton onClick={() => navigate(url)}>
              <Icon>{'close'}</Icon>
            </CloseButton>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                {animation}
              </Grid>
              <Grid item xs={6} classes={{ item: 'grid-padding' }}>
                {content}
              </Grid>
            </Grid>
          </div>
        )}
      </Wrapper>
    )
  }
}

export default Logic(Onboarding)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 4px;
  }

  ${({ mobile }) =>
    mobile &&
    `
    min-height: calc(100vh - 56px);
    background: #f7f7f9;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    background-image: url(${BGMobile})
  `}
`

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`

const NoDevicesAnim = styled.div`
  position: relative;

  > * {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 10;
  }

  ${({ mobile }) =>
    !mobile &&
    `
    > * {
      position: fixed;

      left: 50%;
      transform: translateX(-65%);
      top: 20%;
    }

    &:after{
      width: 100vh;
      height: 100vw;
      left: 46vw;
      top: 0%;
      transform: rotate(13deg);
      background: #f7f7f9;
      position: fixed;
      z-index: 10;
    }
  `}

  ${({ mobile }) =>
    mobile &&
    `
    height: 280px;

    > * {
      position: absolute;
      bottom: 0;
      left: calc(50% - 200px);
    }

    &:after{
      width: 242px;
      height: 1400px;
      left: 45%;
      top: -490px;
      transform: rotate(71.4deg);
      background: #f7f7f9;

      @media screen and (max-width: 370px) {
        width: 230px;
        height: 1100px;
        left: 58.5%;
        top: -360px;
      }
    }
  `}
`

const TextContainer = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;    
    width: 100%;
    position: relative;
    z-index: 10;
    position: relative;
    z-index: 10;
    top: -90px;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    padding-left: 60px;
    z-index: 11; 
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding-left: 100px;
    padding-right: 40px;
  `}
`

const FormContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  position: relative;

  ${({ mobile }) =>
    mobile &&
    `
    padding-bottom: 20px;
    margin-bottom: -90px;

    > * {
      position: relative;
      z-index: 10;
    }

    &:after{
      width: 100vw;
      position: absolute;
      content: '';
      top: 0;
      left: -24px;
      height: 100%;
      background-color: #FFFFFF;
      box-shadow: 0 0 8px 0 rgba(81,141,197,0.1);
    }
  `}
`

const InputHelp = styled(props => <Typography {...props} />)`
  &&&& {
    color: rgba(114, 126, 132, 0.5);
    font-size: 12px;
    line-height: 16px;
    margin: 8px 0 22px;
  }
`

const CloseButton = styled.div`
  position: absolute;
  z-index: 100;
  right: 40px;
  top: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  color: #007bc1;
  cursor: pointer;
  background-color: #fdfdfd;
  box-shadow: 0 0 20px 0 rgba(0, 123, 193, 0.2);

  span {
    font-size: 22px;
    font-weight: bold;
  }
`
