// @flow
import React, { Component } from 'react'
import { type Device } from 'types/api'
import Logic from './logic'
import styled from 'styled-components'

import OfflineMessage from 'components/OfflineMessage'

type Props = {
  currentDevice: Device,
  featureSupport: {
    rebootSupported: boolean,
    rebootUpdateRequired: boolean,
    maintenanceSupported: boolean,
    maintenanceUpdateRequired: boolean,
    sshSupported: boolean,
    sshUpdateRequired: boolean,
    httpsSupported: boolean,
    httpsUpdateRequired: boolean
  },
  translations: {
    API_ERROR_UPDATE_REQUIRED_PART1: string,
    API_ERROR_UPDATE_REQUIRED_REBOOT_PART1: string,
    API_ERROR_UPDATES_MENU: string,
    API_ERROR_UPDATE_REQUIRED_PART2: string,
    API_ERROR_OFFLINE_UPDATE_REQUIRED: string,
    UNITSETT_SUPPORT_MAINTENANCE_OPTIONS_OFFLINE: string
  }
}

class MaintenanceSupportedFeatures extends Component<Props> {
  actions: {
    navigate: Function
  }

  render () {
    const { navigate } = this.actions
    const { currentDevice, featureSupport, translations, feature } = this.props

    const {
      maintenanceSupported,
      maintenanceUpdateRequired,
      sshSupported,
      sshUpdateRequired,
      httpsSupported,
      httpsUpdateRequired
    } = featureSupport || {}

    const {
      API_ERROR_UPDATE_REQUIRED_PART1,
      API_ERROR_UPDATE_REQUIRED_REBOOT_PART1,
      API_ERROR_UPDATES_MENU,
      API_ERROR_UPDATE_REQUIRED_PART2,
      API_ERROR_OFFLINE_UPDATE_REQUIRED,
      UNITSETT_SUPPORT_MAINTENANCE_OPTIONS_OFFLINE
    } = translations

    const featureSupported =
      (feature === 'sshtunnel' && sshSupported) ||
      (feature === 'HTTPStunnel' && httpsSupported) ||
      (feature === 'maintenance' && maintenanceSupported)

    const featureUpdateRequired =
      (feature === 'sshtunnel' && sshUpdateRequired) ||
      (feature === 'HTTPStunnel' && httpsUpdateRequired) ||
      (feature === 'maintenance' && maintenanceUpdateRequired)

    return (
      <Wrapper>
        {currentDevice.connected &&
          !featureSupported &&
          featureUpdateRequired && (
          <OfflineMessage>
            {!featureSupported
              ? API_ERROR_UPDATE_REQUIRED_PART1
              : API_ERROR_UPDATE_REQUIRED_REBOOT_PART1}
            <Link
              onClick={() =>
                navigate(`/${currentDevice.id}/settings/updates/`)
              }
            >
              {API_ERROR_UPDATES_MENU}
            </Link>
            {API_ERROR_UPDATE_REQUIRED_PART2}
          </OfflineMessage>
        )}
        {!currentDevice.connected &&
          !featureSupported &&
          featureUpdateRequired && (
          <OfflineMessage>{API_ERROR_OFFLINE_UPDATE_REQUIRED}</OfflineMessage>
        )}
        {!currentDevice.connected && featureSupported && (
          <OfflineMessage>
            {UNITSETT_SUPPORT_MAINTENANCE_OPTIONS_OFFLINE}
          </OfflineMessage>
        )}
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceSupportedFeatures)

const Wrapper = styled.div``

const Link = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans';
  color: #ca3c3d;
  text-decoration: underline;
  cursor: pointer;
`
