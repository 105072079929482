// @flow

export const equalsToEmail = (value: any, state: any) => {
  const message: string = 'UNITSETT_CHANGEOWNER_EMAILS_NOT_EQUALS'

  if (value !== state.email.value) {
    return { result: false, message }
  }

  return { result: true }
}
