/**
 * TechnicianUnitDetails Scene
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import LoadingPage from 'components/LoadingPage'
import UnitInfoForm from 'containers/UnitInfoForm'

import { PageTitle, BackButton } from 'xstorage-components'

type Props = {
  unitId: string
}

class TechnicianUnitDetails extends Component<Props, void> {
  actions: any

  render () {
    const { unitId, isMobile, appLoading, unit, translations } = this.props
    const { TECH_UNITS_UNIT_DETAILS, TECH_UNITS_ADD_UNIT } = translations

    const { navigate } = this.actions

    if (appLoading) {
      return <LoadingPage />
    }

    return (
      <React.Fragment>
        <HeaderContainer mobile={isMobile}>
          <BackButton
            id={'BackButton'}
            onClick={() => navigate('/untaken-units/')}
          />
          <PageTitle mobile={isMobile} blue>
            {unitId ? TECH_UNITS_UNIT_DETAILS : TECH_UNITS_ADD_UNIT}
          </PageTitle>
        </HeaderContainer>
        <UnitInfoForm
          currentUnit={unit}
          onCancel={() => navigate('/untaken-units/')}
        />
      </React.Fragment>
    )
  }
}

export default Logic(TechnicianUnitDetails)

const HeaderContainer = styled.div`
  width: 100%;
  padding: 16px 16px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`
