// @flow
import { isInteger } from 'lodash'

export const isOnValidInterval = (value: any, state = {}) => {
  const message: string = 'UNITSETT_GENERAL_FORMVAL_BATLEVEL'

  value = parseInt(value)
  if (!isInteger(value) || (value < 0 || value > 100)) {
    return { result: false, message }
  }

  return { result: true }
}

export const isValidHouseThreshold = (value: any, state: {}) => {
  const message: string = 'UNITSETT_GENERAL_HOUSE_THRESH_VALID_VALUE_ERROR'
  const { energySavingMode } = state || {}

  value = parseInt(value)
  if (
    !isInteger(value) ||
    (energySavingMode &&
      energySavingMode.value &&
      (value < 300 || value > 1000))
  ) {
    return { result: false, message }
  }

  return { result: true }
}

export const isValidMaxHousePeakConsumption = (value: any) => {
  // eslint-disable-next-line max-len
  const message: string = 'UNITSETT_GENERAL_MAXHOUSEPEAKCONSUMPTION_VALID_VALUE_ERROR'

  value = parseInt(value)
  if (!isInteger(value) || (value < -32768 || value > 32767)) {
    return { result: false, message }
  }

  return { result: true }
}
