/**
 * MaintenanceMonitoring Container Utils
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
// @flow

export const getDiskInfoColumns = () => {
  const tableColumns = [
    {
      id: 'name',
      key: 'UNITSETT_SUPPORT_DISKMANAGEMENT_NAME',
      dataMapping: 'name'
    },
    {
      id: 'diskusage',
      key: 'UNITSETT_SUPPORT_DISKMANAGEMENT_DISKUSAGE',
      dataMapping: 'usedPercentage'
    }
  ]

  return tableColumns
}
