/* eslint-disable indent */
// @flow
import {
  type DeviceMetricsResponse,
  type DeviceAvgMetricsResponse
} from 'types/api'
import { type ChartFilters } from './types'
import { type GeneralConfigs } from 'types/global'
import { kea } from 'kea'
import axios from 'axios'
import moment from 'moment-timezone'

import { put, call, delay } from 'redux-saga/effects'
import { FETCH_METRICS_DELAY } from 'config'
import { log, generateUrl, convertSecToMillis } from 'utils'
import {
  convertChartMetrics,
  getMetricsAddictParams,
  changeChartVariable,
  getChartTicksToHide,
  checkVarsOnFiltChange,
  DEFAULT_FILTERS,
  DEFAULT_VARIABLES,
  RESETED_VARIABLES,
  DEFAULT_METRICS,
  DEVICE_METRICS_ENDPOINTS,
  changeChartFilter,
  calcSunData
} from './utils'

import PropTypes from 'prop-types'
// import * as Check from 'validations'

import AppLogic from 'containers/App/logic'

const LOADING_COUNT: number = 1

export default kea({
  path: () => ['scenes', 'Chart'],

  connect: {
    actions: [AppLogic, ['navigate']],
    props: [
      AppLogic,
      [
        'currentDevice',
        'currentRoute',
        'appLoading',
        'isMobile',
        'translations',
        'configs',
        'currentDeviceId',
        'batteryReadyMode',
        'weatherInfo'
      ]
    ]
  },

  actions: () => ({
    changeFilter: filter => ({ filter }),
    changeVariable: variable => ({ variable }),
    resetAllVariables: () => true,
    showInvalidTzMessage: () => true,
    hideInvalidTzMessage: () => true,
    openExportChartPanel: () => true,
    closeExportChartPanel: () => true,
    setNoBatteryDetails: status => ({ status }),
    fetchMetrics: update => ({ update }),
    setMetrics: (metrics, averages) => ({ metrics, averages }),
    error: () => true,
    reset: () => true,
    start: () => true
  }),

  reducers: ({ actions }) => ({
    viewTzMessage: [
      false,
      PropTypes.bool,
      {
        [actions.showInvalidTzMessage]: () => true,
        [actions.hideInvalidTzMessage]: () => false,
        [actions.reset]: () => false
      }
    ],

    filters: [
      DEFAULT_FILTERS,
      PropTypes.object,
      {
        [actions.changeFilter]: changeChartFilter,
        [actions.start]: (state, payload) => ({
          ...DEFAULT_FILTERS,
          ...{ selectedDayPicker: moment() }
        }),
        [actions.reset]: (state, payload) => ({
          ...DEFAULT_FILTERS,
          ...{ selectedDayPicker: moment() }
        })
      }
    ],

    updated: [
      false,
      PropTypes.bool,
      {
        [actions.fetchMetrics]: (state, payload) => !!payload.update,
        [actions.reset]: (state, payload) => false,
        [actions.changeFilter]: (state, payload) => false
      }
    ],

    variables: [
      DEFAULT_VARIABLES,
      PropTypes.object,
      {
        [actions.changeVariable]: changeChartVariable,
        [actions.changeFilter]: checkVarsOnFiltChange,
        [actions.reset]: (state, payload) => DEFAULT_VARIABLES,
        [actions.resetAllVariables]: (state, payload) => RESETED_VARIABLES
      }
    ],

    metrics: [
      DEFAULT_METRICS,
      PropTypes.object,
      {
        [actions.setMetrics]: (state, payload) => payload.metrics,
        [actions.reset]: (state, payload) => DEFAULT_METRICS,
        [actions.changeFilter]: (state, payload) => DEFAULT_METRICS
      }
    ],

    averages: [
      null,
      PropTypes.object,
      {
        [actions.setMetrics]: (state, payload) => payload.averages || null,
        [actions.reset]: (state, payload) => null,
        [actions.changeFilter]: (state, payload) => null
      }
    ],

    loading: [
      LOADING_COUNT,
      PropTypes.number,
      {
        [actions.reset]: (state, payload) => LOADING_COUNT,
        [actions.error]: (state, payload) => (state > 0 ? state - 1 : 0),
        [actions.setMetrics]: (state, payload) => (state > 0 ? state - 1 : 0)
      }
    ],

    fetching: [
      true,
      PropTypes.bool,
      {
        [actions.reset]: (state, payload) => true,
        [actions.changeFilter]: (state, payload) => true,
        [actions.setMetrics]: (state, payload) => false,
        [actions.error]: (state, payload) => false
      }
    ],

    exportChartPanelState: [
      false,
      PropTypes.bool,
      {
        [actions.openExportChartPanel]: () => true,
        [actions.closeExportChartPanel]: () => false,
        [actions.reset]: () => false
      }
    ],

    noBatteryDetails: [
      false,
      PropTypes.bool,
      {
        [actions.setNoBatteryDetails]: (state, payload) => payload.status,
        [actions.reset]: () => false
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    /* flag to check if chart type is for today  */
    todayChart: [
      () => [selectors.filters],
      ({ timeOption }) => timeOption === 'today',
      PropTypes.bool
    ],

    /* string with id's of chart ticks to hide */
    ticksToHide: [
      () => [selectors.variables],
      variables => getChartTicksToHide(variables),
      PropTypes.string
    ],

    /* check if unit location timezone is different that unit timezone */
    invalidTimezone: [
      () => [selectors.weatherInfo, selectors.currentDevice],
      (weatherInfo, currentDevice) => {
        const currentDate = moment()

        const weatherOffset =
          weatherInfo &&
          moment.tz(currentDate, weatherInfo.timezone).utcOffset()
        const deviceOffset =
          currentDevice &&
          currentDevice.timezone &&
          moment.tz(currentDate, currentDevice.timezone.timezone).utcOffset()

        return weatherOffset !== deviceOffset
      },
      PropTypes.bool
    ]
  }),

  start: function * () {
    const {
      fetchMetrics,
      resetAllVariables,
      changeVariable,
      changeFilter,
      navigate,
      start
    } = this.actions

    yield put(start())

    log('[XS-Chart] Start Scene', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    const currentRoute = yield this.get('currentRoute')
    const { filter } = currentRoute.params
    if (filter) {
      yield put(resetAllVariables())
      switch (filter) {
        case 'grid-consumption':
          yield put(changeVariable({ totalHouseConsumption: true }))
          break

        case 'pv-production':
          yield put(changeVariable({ photovoltaicProduction: true }))
          break

        case 'pv-consumption':
          yield put(changeVariable({ selfConsumption: true }))
          yield put(changeFilter({ timeOption: 'last-7' }))
          break

        default:
          break
      }
    }

    yield put(fetchMetrics())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-Chart] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchMetrics]: workers.fetchMetrics,
    [actions.changeFilter]: workers.fetchMetrics
  }),

  workers: {
    * fetchMetrics () {
      const { setMetrics, fetchMetrics, error } = this.actions

      let hasError = false

      try {
        const currentDevice: any = yield this.get('currentDevice')
        const appLoading: boolean = yield this.get('appLoading')
        if (!currentDevice.id && appLoading) {
          yield delay(1000)
          yield put(fetchMetrics())
          return false
        }

        const filters: ChartFilters = yield this.get('filters')
        const configs: GeneralConfigs = yield this.get('configs')
        const weatherInfo = yield this.get('weatherInfo')

        const url: string = generateUrl(
          DEVICE_METRICS_ENDPOINTS[filters.timeOption],
          { id: currentDevice.id }
        )
        const options: any = getMetricsAddictParams(filters)

        const params = {
          from: convertSecToMillis(options.from),
          to: convertSecToMillis(options.to)
        }
        const response = yield call(axios.get, url, { params })
        const metricsResult: DeviceMetricsResponse | DeviceAvgMetricsResponse =
          response.data
        const { result, successful } = metricsResult

        hasError = !successful
        let metrics, averages

        if (successful) {
          metrics = yield convertChartMetrics(
            currentDevice,
            configs.fakeAPI,
            result.metrics,
            filters
          )
          averages = result.averages || result.total
        } else {
          metrics = yield convertChartMetrics(
            currentDevice,
            configs.fakeAPI,
            [],
            filters
          )
          averages = {}
        }

        if (weatherInfo) {
          metrics.sunData = yield calcSunData(
            Number(weatherInfo.sunriseTime),
            Number(weatherInfo.sunsetTime)
          )
        }

        const updatedMetrics = { ...metrics, ...options }
        yield put(setMetrics(updatedMetrics, averages))
      } catch (error) {
        console.log(error)
        hasError = true
      }

      if (hasError) {
        yield put(error())
      }

      // update data on charts
      yield delay(FETCH_METRICS_DELAY)
      yield put(fetchMetrics(true))
    }
  }
})
