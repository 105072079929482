/**
 * Dashboard Utils
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */

// @flow

import { type DeviceStatus } from 'types/api'

export const DEFAULT_DEVICE_STATUS: any = {
  energyFlow: {},
  last30daysEnergyFlow: {},
  today: {}
}

export const multipleSolarPanelObject = (deviceStatus: DeviceStatus) => {
  let { energyFlow } = deviceStatus || {}
  if (!energyFlow) {
    return {}
  }

  let multipleValueSum = energyFlow.acPvValue + energyFlow.dcPvValue
  let numberPV
  let onlyPvRole = ''
  let onlyPvType = ''
  let onlyPvValue = 0

  if (
    energyFlow.acPvRole === 'DISCONNECTED' &&
    energyFlow.dcPvRole === 'DISCONNECTED'
  ) {
    numberPV = 0
  } else if (
    (energyFlow.acPvRole === 'DISCONNECTED' &&
      energyFlow.dcPvRole !== 'DISCONNECTED') ||
    (energyFlow.acPvRole !== 'DISCONNECTED' &&
      energyFlow.dcPvRole === 'DISCONNECTED')
  ) {
    numberPV = 1

    if (energyFlow.acPvRole !== 'DISCONNECTED') {
      onlyPvRole = energyFlow.acPvRole
      onlyPvValue = energyFlow.acPvValue
      onlyPvType = 'ac'
    } else {
      onlyPvRole = energyFlow.dcPvRole
      onlyPvValue = energyFlow.dcPvValue
      onlyPvType = 'dc'
    }
  } else {
    numberPV = 2
  }

  return {
    acPvRole: energyFlow.acPvRole,
    acPvValue: energyFlow.acPvValue,
    dcPvRole: energyFlow.dcPvRole,
    dcPvValue: energyFlow.dcPvValue,
    pvValueSum: multipleValueSum,
    numberPV: numberPV,
    onePvType: onlyPvType,
    onePvRole: onlyPvRole,
    onePvValue: onlyPvValue
  }
}
