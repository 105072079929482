// @flow
import { type ChartFilters } from 'scenes/Chart/types'
import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import Icon from '@material-ui/core/Icon'

import { DatePicker, MonthYearPicker } from 'xstorage-components'

type Props = {
  label: any,
  filters: ChartFilters,
  changeFilter: Function,
  icon: any,
  isMobile: boolean
}

type State = {
  showDatePicker: boolean
}

class DateFilterChart extends Component<Props, State> {
  state = {
    showDatePicker: false
  }

  selectActiveWeek = () => {
    setTimeout(() => {
      let datepickElement = document.querySelectorAll('.react-datepicker__week')
      var allElements = []
      datepickElement.forEach(v => allElements.push(v))
      for (let element of allElements) {
        element.classList.remove('active')
      }
      let selectedDay: any = document.getElementsByClassName(
        'react-datepicker__day--selected'
      )
      if (selectedDay.length) {
        selectedDay[0].parentNode.classList.add('active')
      }
    }, 10)
  }

  goToPrevNextDate = type => {
    const { changeFilter, filters } = this.props

    const { timeOption, selectedDayPicker } = filters

    // definiftions
    const dateApplicationMap = {
      today: 'day',
      'last-7': 'week',
      'last-30': 'month',
      'last-year': 'year'
    }
    let date = moment(selectedDayPicker)

    // function that apply date change based on arrow direction
    const applyDate = (date, number, unit, type) => {
      if (type === 'prev') {
        return date.subtract(number, unit)
      } else if (type === 'next') {
        return date.add(number, unit)
      }
    }

    date = applyDate(date, 1, dateApplicationMap[timeOption], type)
    changeFilter({ selectedDayPicker: date })
  }

  render () {
    const { label, filters, changeFilter, icon, isMobile } = this.props

    const { timeOption, selectedDayPicker } = filters || {}

    const { showDatePicker } = this.state

    let todayDate = moment(new Date()).startOf('day')
    let selectedDate = filters
      ? moment(filters.selectedDayPicker).startOf('day')
      : moment().startOf('day')

    let showNextArrow = true
    if (moment(selectedDate).isSame(todayDate)) {
      showNextArrow = false
    }

    return (
      <Wrapper timeOption={timeOption}>
        <PreviousDate
          onClick={() => this.goToPrevNextDate('prev')}
          isMobile={isMobile}
        >
          <Icon>{'keyboard_arrow_left'}</Icon>
        </PreviousDate>
        {(timeOption === 'today' || timeOption === 'last-7') && (
          <DatePicker
            customInput={
              <CurrentDate>
                {icon}
                {label}
              </CurrentDate>
            }
            selected={selectedDayPicker}
            onChange={val => changeFilter({ selectedDayPicker: val })}
            onInputClick={() => {
              this.setState({ showDatePicker: !showDatePicker })
              this.selectActiveWeek()
            }}
            onClickOutside={() =>
              this.setState({ showDatePicker: !showDatePicker })
            }
            onSelect={() => this.setState({ showDatePicker: !showDatePicker })}
            onMonthChange={this.selectActiveWeek}
            onYearChange={this.selectActiveWeek}
            popperPlacement={'bottom'}
            open={showDatePicker}
            maxDate={moment()}
          />
        )}
        {(timeOption === 'last-30' || timeOption === 'last-year') && (
          <MonthYearPicker
            customInput={
              <CurrentDate>
                {icon}
                {label}
              </CurrentDate>
            }
            type={timeOption === 'last-30' ? 'month' : 'year'}
            timezone={moment().tz()}
            onChange={val => changeFilter({ selectedDayPicker: val })}
            value={selectedDayPicker}
          />
        )}
        <NextDate
          onClick={showNextArrow ? () => this.goToPrevNextDate('next') : null}
          isMobile={isMobile}
          disabled
          showNextArrow={showNextArrow}
        >
          <Icon>{'keyboard_arrow_right'}</Icon>
        </NextDate>
      </Wrapper>
    )
  }
}

export default DateFilterChart

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  position: relative;

  .react-datepicker {
    /* left: -75px!important; */
  }

  ${({ timeOption }) =>
    timeOption === 'last-7' &&
    `
    .react-datepicker__week{
      &:hover, &.active {
        background: #dcf1ff;
        border-radius: 20px;
      }
    }

    .react-datepicker__day{
      &:hover{
        background-color: transparent;
        border-radius: none;
      }
    }
    .react-datepicker__day--selected{
      background-color: transparent;
      border-radius: none;
      color: #333;
    }
  `}
`

const PreviousDate = styled.div`
  left: 0;
  outline: none;
  position: absolute;
  color: #007bc1;
  display: flex;
  align-items: center;
  padding: 10px;

  > span {
    opacity: 1 !important;
  }

  ${({ onClick }) =>
    !!onClick &&
    `
    cursor: pointer;
  `}

  ${({ isMobile }) =>
    !isMobile &&
    `
    left: calc(50% - 250px);
  `}
`

const NextDate = styled.div`
  outline: none;
  position: absolute;
  right: 0;
  color: #007bc1;
  display: flex;
  align-items: center;
  padding: 10px;
  
  > span {
    opacity: 1!important;
  }
  ${({ showNextArrow }) =>
    !showNextArrow &&
    `
    opacity: 0.2;
  `}
  
  ${({ onClick }) =>
    !!onClick &&
    `
    cursor: pointer;
  `}

  ${({ isMobile }) =>
    !isMobile &&
    `
    right: calc(50% - 250px);
  `}
`

const CurrentDate = styled.div`
  color: #007bc1;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  padding-top: 2px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`
