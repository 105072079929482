// @flow
import { kea } from 'kea'
import axios from 'axios'
import { put, call, delay } from 'redux-saga/effects'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import { generateUrl } from 'utils'
import { API_BASE_URL } from 'config'
import * as Check from 'validations'

const GET_APP_DETAILS_ENDPOINT = `${API_BASE_URL}/thirdparty/myapps/{appid}`
const UPDATE_APP_DETAILS_ENDPOINT = `${API_BASE_URL}/thirdparty/myapps/{appid}/`
const REVOKE_APP_ENDPOINT = `${API_BASE_URL}/thirdparty/myapps/{appid}/revoke`

const DEFAULT_VALUES = {
  unitsGranted: {
    value: []
  }
}

const VALIDATIONS = {
  unitsGranted: [
    Check.isRequired
  ]
}

export default kea({
  path: () => ['scenes', 'UserProfileMyAppsDetails'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'isMobile',
        'currentRoute'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    setAppDetails: app => app,
    getAppDetails: appid => appid,
    setUnitsList: units => units,
    setError: error => error,
    setForm: form => ({ form }),
    change: field => ({ field }),
    setRemoveAppModal: modal => modal,
    reset: () => true,
    submit: () => true,
    setSuccessMessage: message => message,
    revokeApp: () => true
  }),

  reducers: ({ actions }) => ({
    appDetails: [
      {},
      PropTypes.object,
      {
        [actions.reset]: (state, payload) => ({}),
        [actions.setAppDetails]: (state, payload) => payload
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.reset]: () => true,
        [actions.getAppDetails]: () => true,
        [actions.setAppDetails]: () => false,
        [actions.setError]: () => false,
        [actions.revokeApp]: () => true,
        [actions.navigate]: () => false,
        [actions.submit]: () => true,
        [actions.setSuccessMessage]: () => false
      }
    ],
    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.getAppDetails]: () => null,
        [actions.submit]: () => null
      }
    ],

    unitsList: [
      [],
      PropTypes.array,
      {
        [actions.reset]: (state, payload) => [],
        [actions.setUnitsList]: (state, payload) => payload
      }
    ],

    form: [DEFAULT_VALUES, PropTypes.object, {
      [actions.change]: (state, payload) =>
        Check.setAndCheckValidation(state, payload, VALIDATIONS),
      [actions.setForm]: (state, payload) =>
        Check.checkValidation(payload.form, VALIDATIONS).form,
      [actions.reset]: () => DEFAULT_VALUES
    }],

    dirty: [false, PropTypes.bool, {
      [actions.change]: () => true,
      [actions.error]: () => true,
      [actions.reset]: () => false,
      [actions.getAppDetails]: () => false,
      [actions.submit]: () => false
    }],

    removeAppModal: [false, PropTypes.bool, {
      [actions.setRemoveAppModal]: (state, payload) => payload,
      [actions.error]: () => false,
      [actions.reset]: () => false,
      [actions.revokeApp]: () => false
    }],

    successMessage: [false, PropTypes.bool, {
      [actions.change]: () => false,
      [actions.error]: () => false,
      [actions.reset]: () => false,
      [actions.setSuccessMessage]: (state, payload) => payload
    }]
  }),

  selectors: ({ selectors }) => ({
  }),

  start: function * () {
    const { getAppDetails } = this.actions

    const currentRoute = yield this.get('currentRoute')
    const { appid } = currentRoute.params

    yield put(getAppDetails(appid))
  },

  stop: function * () {
    const { reset } = this.actions
    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getAppDetails]: workers.getAppDetails,
    [actions.submit]: workers.submit,
    [actions.revokeApp]: workers.revokeApp
  }),

  workers: {
    * getAppDetails (workerPayload) {
      const { payload } = workerPayload
      const { setAppDetails, setUnitsList, setForm } = this.actions

      try {
        const url = generateUrl(
          GET_APP_DETAILS_ENDPOINT,
          { appid: payload }
        )

        const response = yield call(axios.get, url)
        const { result } = response.data

        const { authorizedDevices, nonAuthorizedDevices } = result || {}

        authorizedDevices.map(device => {
          device.key = device.id
          return device
        })

        nonAuthorizedDevices.map(device => {
          device.key = device.id
          return device
        })

        if (authorizedDevices && nonAuthorizedDevices) {
          const units = authorizedDevices.concat(nonAuthorizedDevices)
          const unitsWithAccess = authorizedDevices.map(a => a.key)

          const formUnits = {
            unitsGranted: {value: unitsWithAccess}
          }
          yield put(setUnitsList(units))
          yield put(setForm(formUnits))
        }
        yield put(setAppDetails(result))
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * submit () {
      const {
        setError,
        setForm,
        setSuccessMessage,
        navigate
      } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')
      const unitsList = yield this.get('unitsList')
      const isMobile = yield this.get('isMobile')

      const currentRoute = yield this.get('currentRoute')
      const { appid } = currentRoute.params

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let data = mapValues(form, ({ value }) => value)
      const authorizedDevices = data.unitsGranted
      const unitListId = unitsList.map(unit => unit.id)
      const nonAuthorizedDevices =
        unitListId.filter(unit => !authorizedDevices.includes(unit))

      try {
        const url = generateUrl(
          UPDATE_APP_DETAILS_ENDPOINT,
          { appid: appid }
        )

        data = {
          nonAuthorizedDevices,
          authorizedDevices
        }

        const result = yield call(axios.patch, url, data)
        const { successful } = result.data || {}

        if (successful) {
          if (isMobile) {
            yield put(navigate('/user-profile/my-apps/'))
          } else {
            yield put(setSuccessMessage(true))
            yield delay(2000)
            yield put(setSuccessMessage(false))
          }
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * revokeApp () {
      const { navigate } = this.actions
      const currentRoute = yield this.get('currentRoute')
      const { appid } = currentRoute.params

      try {
        const url = generateUrl(
          REVOKE_APP_ENDPOINT,
          { appid: appid }
        )

        const result = yield call(axios.post, url)
        const { successful } = result.data || {}

        if (successful) {
          yield put(navigate('/user-profile/my-apps/'))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    }
  }
})
