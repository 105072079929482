// @flow
import { type Device } from 'types/api'
import { type NavRoute, type GeneralConfigs } from 'types/global'
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import { CONSUMER_USER_TYPE } from 'config'
import UserPermission from 'containers/UserPermission'
import { BatteryBlue } from 'xstorage-components'

type Props = {
  devices: Array<Device>,
  currentDevice: Device,
  currentRoute: NavRoute,
  configs: GeneralConfigs,
  translations: any
}

type State = {
  open: boolean
}

class DeviceChooser extends Component<Props, State> {
  actions: any

  state = {
    open: false
  }

  render () {
    const { devices, currentDevice, configs, translations } = this.props

    const {
      navigate,
      updateDeviceStatus,
      fetchDeviceStatusAgg,
      fetchCurrentDeviceInfo
    } = this.actions

    const { DEVICE_CHOOSER_ADD_NEW_UNIT } = translations

    const { open } = this.state

    const { userType } = configs

    const selectDevice = id => {
      window.localStorage.setItem('currentDeviceId', id)
      navigate(`/${id}/dashboard/`)
      updateDeviceStatus()
      fetchCurrentDeviceInfo()
      fetchDeviceStatusAgg()
    }

    // only shows on consumer user type
    if (!devices.length || userType !== CONSUMER_USER_TYPE) {
      return ''
    }

    return (
      <Wrapper
        onMouseEnter={() => this.setState({ open: true })}
        onMouseLeave={() => this.setState({ open: false })}
      >
        <IconContainer open={open}>
          <ImageBattery size={{ width: 56, height: 76 }} />
        </IconContainer>
        <CurrentDevice>
          <CurrentDeviceName>
            {currentDevice.name || currentDevice.inverterSerialNumber}
          </CurrentDeviceName>
          <Icon>{open ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}</Icon>
        </CurrentDevice>
        <DevicesContainer open={open}>
          {open && (
            <Fragment>
              {devices.map(device => (
                <DeviceItem
                  key={device.id}
                  active={device.id === currentDevice.id}
                  onClick={
                    device.id !== currentDevice.id
                      ? () => selectDevice(device.id)
                      : undefined
                  }
                >
                  {device.id === currentDevice.id && <Icon>{'done'}</Icon>}
                  <DeviceName>
                    {device.name || device.inverterSerialNumber}
                  </DeviceName>
                </DeviceItem>
              ))}
              <UserPermission permission={'ADD_NEW_DEVICE'}>
                <DeviceItemCreate onClick={() => navigate('/onboarding/new/')}>
                  <Icon>{'add'}</Icon>
                  {DEVICE_CHOOSER_ADD_NEW_UNIT}
                </DeviceItemCreate>
              </UserPermission>
            </Fragment>
          )}
        </DevicesContainer>
      </Wrapper>
    )
  }
}

export default Logic(DeviceChooser)

const Wrapper = styled.div`
  background-color: rgba(114, 126, 132, 0.05);
  box-shadow: inset 0 1px 0 0 rgba(114, 126, 132, 0.05);
  height: 148px;
  width: 100%;
  bottom: 0;
  position: absolute;
  cursor: pointer;
`

const IconContainer = styled.div`
  ${({ open }) =>
    open &&
    `
    svg{
      width: 64px;
      height: 86px;
    }
  `}
`

const ImageBattery = styled(props => <BatteryBlue {...props} />)`
  display: block;
  margin: 25px auto 0;
  transition: all 0.1s ease-in-out;
`

const CurrentDevice = styled.div`
  color: #007bc1;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-top: -5px;

  span {
    margin-top: -5px;
    font-size: 20px;
    line-height: 26px;
  }
`

const CurrentDeviceName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 12px;
  max-width: 70px;
`

const DevicesContainer = styled.div`
  position: absolute;
  width: 250px;
  bottom: 0;
  left: 120px;
  visibility: hidden;
  opacity: 0;
  background-color: #fdfdfd;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  transform: translateX(-50px);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  ${({ open }) =>
    open &&
    `
    transform: translateX(0);
    visibility: visible;
    min-height: 148px;
    opacity: 1;
  `}
`

const DeviceItem = styled.div`
  font-size: 14px;
  height: 56px;
  align-items: center;
  padding: 0 15px;
  display: flex;
  color: #424e54;
  font-weight: 600;
  box-shadow: inset 0 1px 0 0 rgba(114, 126, 132, 0.1);

  ${({ active }) =>
    active &&
    `
    color: #007BC1;

    span{
      margin-right: 5px;
    }
  `}
`

const DeviceName = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DeviceItemCreate = styled.div`
  font-size: 12px;
  height: 56px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  display: flex;
  color: #007bc1;
  font-weight: 600;
  box-shadow: inset 0 1px 0 0 rgba(114, 126, 132, 0.1);

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
  `}
`
