// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import {
  ListItemInfo,
  Alert,
  Button,
  Link,
  IcEmailCheck,
  LoadingCircular
} from 'xstorage-components'
type Props = {
  isMobile: boolean,
  translations: any
}

class NotificationsList extends Component<Props, void> {
  render () {
    const {
      translations,
      notifications,
      notificationsTotal,
      showLoadMoreInfo,
      unreadNotifications,
      loadingButton,
      isMobile,
      currentDevice,
      user,
      loading
    } = this.props

    const {
      deleteNotification,
      getNotifications,
      markAllNotificationsRead
    } = this.actions

    const {
      NOTIF_UNREAD_TEXT,
      NOTIFICATIONS_LOADING,
      NOTIFICATIONS_LOAD_MORE,
      NOTIFICATIONS_NO_MORE_RESULTS,
      NOTIFICATIONS_MARK_ALL_READ
    } = translations || {}

    const unreadText =
      NOTIF_UNREAD_TEXT &&
      NOTIF_UNREAD_TEXT.replace('NUMBER_NOTIF', unreadNotifications)

    if (loading) {
      return <LoadingCircular />
    }

    return (
      <Wrapper>
        <UnreadNotifications>{unreadText}</UnreadNotifications>
        <ButtonsWrapper>
          <Link onClick={markAllNotificationsRead}>
            <IcEmailCheck />
            <span>{NOTIFICATIONS_MARK_ALL_READ}</span>
          </Link>
        </ButtonsWrapper>
        <List mobile={isMobile}>
          {notifications &&
            notifications.map(notification => (
              <ListItemInfo
                alert
                showAlert={notification.status === 'NORMAL'}
                close={
                  (!notification.deviceId && notification.userId === user.id) ||
                  (notification.deviceId && currentDevice.userRoles[0].write)
                }
                closeClick={() => deleteNotification(notification.id)}
                key={notification.id}
                title={notification.title}
                description={notification.subtitle}
                label={
                  notification.content && notification.content.sn
                    ? `${notification.content.sn} | ${notification.date}`
                    : notification.date
                }
                iconType={
                  notification.level === 'INFO'
                    ? 'info'
                    : notification.level === 'WARNING'
                      ? 'warning'
                      : 'error'
                }
                icon={
                  <Icon>
                    {notification.level === 'INFO'
                      ? 'info'
                      : notification.level === 'WARNING'
                        ? 'warning'
                        : 'error'}
                  </Icon>
                }
              />
            ))}
          {showLoadMoreInfo && loadingButton && (
            <LoadMoreDiv mobile={isMobile}>
              <LoadingDiv>
                <Icon>{'autorenew'}</Icon>
                <span>{NOTIFICATIONS_LOADING}</span>
              </LoadingDiv>
            </LoadMoreDiv>
          )}
          {showLoadMoreInfo && !loadingButton && (
            <React.Fragment>
              {notificationsTotal > notifications.length && (
                <LoadMoreDiv mobile={isMobile}>
                  <Button
                    buttonType={'secondary-default'}
                    buttonWidth={'200px'}
                    smallShadow
                    onClick={() => getNotifications()}
                  >
                    <Icon>{'add'}</Icon>
                    {NOTIFICATIONS_LOAD_MORE}
                  </Button>
                </LoadMoreDiv>
              )}
              {notificationsTotal === notifications.length && (
                <LoadMoreDiv mobile={isMobile}>
                  <Alert
                    icon={<Icon>{'error'}</Icon>}
                    message={NOTIFICATIONS_NO_MORE_RESULTS}
                  />
                </LoadMoreDiv>
              )}
            </React.Fragment>
          )}
        </List>
      </Wrapper>
    )
  }
}

export default Logic(NotificationsList)

const Wrapper = styled.div``

const UnreadNotifications = styled.div`
  font-size: 14px;
  color: #727e84;
  font-weight: 600;
  line-height: 20px;
`

const List = styled.div`
  overflow-y: auto;
  max-height: 85vh;

  ${({ mobile }) =>
    mobile &&
    `
    max-height: 70vh;
  `}

  > div:not(:last-child) {
    box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);
  }
`

const LoadMoreDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 31px;
  margin-bottom: 31px;

  ${({ mobile }) =>
    mobile &&
    `
    max-width: 100vw;
  `}
`

const ButtonsWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(114, 126, 132, 0.1);
  border-top: 1px solid rgba(114, 126, 132, 0.1);
  margin-top: 20px;
`

const LoadingDiv = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  span {
    color: #007bc1;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
  }

  span:nth-child(2) {
    padding-left: 4px;
  }
`
