import { createGlobalStyle } from 'styled-components'
import { createMuiTheme } from '@material-ui/core/styles'
import EatonRegular from 'fonts/Eaton-Regular.otf'
import EatonRegularEOT from 'fonts/Eaton-Regular.eot'
import EatonRegularWOFF from 'fonts/Eaton-Regular.woff'
import EatonRegularTTF from 'fonts/Eaton-Regular.ttf'
import EatonMedium from 'fonts/Eaton-Medium.otf'
import EatonMediumEOT from 'fonts/Eaton-Medium.eot'
import EatonMediumWOFF from 'fonts/Eaton-Medium.woff'
import EatonMediumTTF from 'fonts/Eaton-Medium.ttf'
import EatonBold from 'fonts/Eaton-Bold.otf'
import EatonBoldEOT from 'fonts/Eaton-Bold.eot'
import EatonBoldWOFF from 'fonts/Eaton-Bold.woff'
import EatonBoldTTF from 'fonts/Eaton-Bold.ttf'
import OpenSansTTF from 'fonts/OpenSans-Regular.ttf'
import OpenSansSemiBoldTTF from 'fonts/OpenSans-SemiBold.ttf'
import OpenSansItalicTTF from 'fonts/OpenSans-Italic.ttf'
import OpenSansBoldTTF from 'fonts/OpenSans-Bold.ttf'
import OpenSansExtraBoldTTF from 'fonts/OpenSans-ExtraBold.ttf'

// Theme variables
const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Open Sans'].join(',')
  },
  palette: {
    primary: {
      main: '#007BC1',
      dark: '#005EAB'
    },
    secondary: {
      main: '#FFFFFF'
    },
    error: {
      main: '#ca3c3d'
    }
  },
  overrides: {
    MuiTypography: {
      colorTextPrimary: {
        color: '#727E84'
      },
      colorTextSecondary: {
        color: '#333f48'
      },
      colorError: {
        color: '#ca3c3d'
      },
      colorSecondary: {
        color: '#FFF'
      }
    }
  }
})

theme.typography.h1 = {
  fontSize: '56px',
  fontFamily: 'Eaton',
  fontWeight: 'bold',
  letterSpacing: '0.5px',
  lineHeight: '56px',
  [theme.breakpoints.down('md')]: {
    fontSize: '40px',
    lineHeight: '40px'
  }
}

theme.typography.h2 = {
  fontSize: '24px',
  fontFamily: 'Open Sans',
  fontWeight: 'bold',
  lineHeight: '32px',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    lineHeight: '20px'
  }
}

theme.typography.h3 = {
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '24px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    lineHeight: '20px'
  }
}

theme.typography.h4 = {
  fontSize: '16px',
  fontFamily: 'Open Sans',
  textTransform: 'uppercase',
  fontWeight: '800',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: '#333F48'
}

theme.typography.h5 = {
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '20px'
}

theme.typography.h6 = {
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '900',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase'
}

theme.typography.body1 = {
  fontFamily: '"Open Sans"',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '400'
}

theme.typography.body2 = {
  fontFamily: '"Open Sans"',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '400'
}

export const Theme = theme

// Global styles
export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: normal;
    src: url('${OpenSansTTF}') format('truetype'); 
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 900;
    font-style: normal;
    src: url('${OpenSansExtraBoldTTF}')  format('truetype');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    font-style: normal;
    src: url('${OpenSansBoldTTF}')  format('truetype');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: normal;
    src: url('${OpenSansSemiBoldTTF}')  format('truetype');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: italic;
    src: url('${OpenSansItalicTTF}')  format('truetype');
  }
  @font-face {
    font-family: 'Eaton';
    font-weight: normal;
    font-style: normal;
    src: url('${EatonRegular}') format("opentype");
    src: url('${EatonRegularEOT}'); /* IE9 Compat Modes */
    src: url('${EatonRegularEOT}?#iefix') format('embedded-opentype'),
         url('${EatonRegularWOFF}') format('woff'),
         url('${EatonRegularTTF}')  format('truetype');
  }
  @font-face {
    font-family: 'Eaton';
    font-weight: 400;
    font-style: normal;
    src: url('${EatonMedium}') format("opentype");
    src: url('${EatonMediumEOT}'); /* IE9 Compat Modes */
    src: url('${EatonMediumEOT}?#iefix') format('embedded-opentype'),
         url('${EatonMediumWOFF}') format('woff'),
         url('${EatonMediumTTF}')  format('truetype');
  }
  @font-face {
    font-family: 'Eaton';
    font-weight: 600;
    font-style: normal;
    src: url('${EatonBold}') format("opentype");
    src: url('${EatonBoldEOT}'); /* IE9 Compat Modes */
    src: url('${EatonBoldEOT}?#iefix') format('embedded-opentype'),
         url('${EatonBoldWOFF}') format('woff');
         url('${EatonBoldTTF}')  format('truetype');
  }

  body {
    margin: 0;
  }
`
