/**
 * DeviceChooserMobile Container Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import { kea } from 'kea'

import AppLogic from 'containers/App/logic'
import DashboardLogic from 'scenes/DashboardMenu/Dashboard/logic'

export default kea({
  path: () => ['scenes', 'containers', 'DeviceChooserMobile'],

  connect: {
    props: [
      AppLogic, [
        'currentDevice',
        'currentDeviceId',
        'translations',
        'devices',
        'configs'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'fetchCurrentDeviceInfo'
      ],
      DashboardLogic, [
        'updateDeviceStatus'
      ]
    ]
  }
})
