// @flow
import {
  type User
} from 'types/api'
import { type GeneralConfigs } from 'types/global'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import isFunction from 'lodash/isFunction'
import Icon from '@material-ui/core/Icon'

import { IcDocument, SelectBox, IcFloater } from 'xstorage-components'

type Props = {
  user: User,
  translations: any,
  configs: GeneralConfigs,
  userIsTechnician: boolean
}

class UserMenu extends Component<Props, void> {
  actions: any

  onMenuChange = (value, options) => {
    let selected = options && options.find(({ val }) => val === value)
    if (isFunction(selected.action)) {
      let { action } = selected
      action()
    }
  }

  render () {
    const {
      user,
      translations,
      userIsTechnician
    } = this.props

    const {
      logout,
      navigate,
      setShowHelpTour
    } = this.actions

    const {
      USERMENU_ACCOUNTSETT,
      // USERMENU_HELP,
      USERMENU_TERMS,
      USERMENU_LOGOUT,
      USERMENU_VIEWTOUR
    } = translations

    const {
      firstName,
      lastName
    } = user || {}

    const MENU_OPTIONS = [
      {
        val: 'profile',
        label: USERMENU_ACCOUNTSETT,
        icon: <NavIcon>{'account_circle'}</NavIcon>,
        action: () => { navigate('/user-profile/info/') }
      },
      {
        val: 'review_tour',
        label: USERMENU_VIEWTOUR,
        hidden: userIsTechnician,
        icon: <Floater />,
        action: () => setShowHelpTour(true)
      },
      {
        val: 'terms',
        label: USERMENU_TERMS,
        icon: <Document />,
        action: () => { navigate('/terms/') }
      },
      {
        val: 'logout',
        label: USERMENU_LOGOUT,
        icon: <LogoutIcon>{'power_settings_new'}</LogoutIcon>,
        action: logout
      }
    ]

    return (
      <Wrapper>
        <Icon>{'account_circle'}</Icon>
        <SelectBox
          placeholder={`${firstName} ${lastName}`}
          onChange={this.onMenuChange}
          options={MENU_OPTIONS}
          openOnHover
        />
      </Wrapper>
    )
  }
}

export default Logic(UserMenu)

const Wrapper = styled.div`
  display: flex;

  > div > div > label{
    color: #727E84;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 5px;
  }
  > div > div > span {
    color: #727E84;
    font-size: 20px;
    line-height: 20px;
    margin-left: 0;
  }
  > div > div:nth-child(2) {
    width: 250px;
  }
`

const NavIcon = styled(Icon)`
  color: #007BC1;
  margin-right: 8px;
  font-size: 20px!important;
  float: left;
`

const Document = styled(props => <IcDocument {...props} />)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  float: left;
`

const Floater = styled(IcFloater)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  float: left;
`

const LogoutIcon = styled(Icon)`
  color: #CA3C3D;
  margin-right: 8px;
  font-size: 20px!important;
  float: left;
`
