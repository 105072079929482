// @flow
import { type Device } from 'types/api'
import { type NavRoute } from 'types/global'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Logic from './logic'
import { onlyUpdateForKeys } from 'recompose'

import DeviceChooser from 'containers/DeviceChooser'
import { AppLogo, NavItem } from 'xstorage-components'

// Creates a pure component that only render when needed
const PureNavItem = onlyUpdateForKeys(['active', 'disabled', 'href', 'mobile'])(
  NavItem
)

type Props = {
  navItems: [],
  currentRoute: NavRoute,
  isMobile: boolean,
  translations: any,
  devices: Array<Device>,
  userValid: boolean
}

class NavBar extends PureComponent<Props, void> {
  actions: any

  render () {
    const {
      navItems,
      isMobile,
      currentRoute,
      translations,
      devices,
      userValid,
      currentDeviceId,
      showBackBar,
      userIsTechnician,
      showHelpTour
    } = this.props

    const { navigate } = this.actions

    if ((isMobile && currentRoute.hideNavBar) || !userValid) {
      return ''
    }

    const showNav = navItems && !currentRoute.hideNavBar
    const showDeviceChooser =
      !isMobile && !!devices.length && !currentRoute.hideNavBar

    let items = navItems.filter(m => !m.hide)

    items.map(item => {
      item.path = item.path.replace(':id', currentDeviceId)
      return item
    })

    const url = userIsTechnician
      ? '/'
      : currentDeviceId
        ? `/${currentDeviceId}/dashboard/`
        : '/add-device/'

    return (
      <Wrapper
        mobile={isMobile}
        showBackBar={showBackBar}
        className='first-step'
      >
        {!isMobile && <AppLogo onClick={() => navigate(url)} />}
        {showNav &&
          items.map(({ key, icon, name, active, path, disabled }) => (
            <PureNavItem
              id={key}
              key={key}
              active={active}
              icon={icon}
              content={translations[name] ? translations[name] : name}
              disabled={disabled}
              href={path}
              mobile={isMobile}
              onClick={() => !showHelpTour && navigate(path)}
            />
          ))}
        {showDeviceChooser && <DeviceChooser />}
      </Wrapper>
    )
  }
}

export default Logic(NavBar)

const Wrapper = styled.div`
  background: #FFF;
  box-shadow: inset 0 1px 0 0 rgba(0,0,0,0.05), 0 -2px 5px 0 rgba(0,0,0,0.06);
  z-index: 1000;
  
  ${({ mobile }) =>
    mobile &&
    `
    position: fixed;
    height: 56px;
    width: 100%;
    bottom: 0;
    display: flex;
    min-width: 300px;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    position: fixed;
    left: 0;
    height: 100vh;
    width: 120px;
    display: block;
    top: 0;
  `}

  ${({ mobile, showBackBar }) =>
    !mobile &&
    !showBackBar &&
    `
    top: 0;
  `}

  ${({ mobile, showBackBar }) =>
    !mobile &&
    showBackBar &&
    `
    top: 56px;
  `}

`
