// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

import { PageTitle, Button, Typography } from 'xstorage-components'

type Props = {
  mobile: boolean,
  error: string
}

export default class FatalError extends Component<Props> {
  render () {
    const { mobile, error } = this.props

    return (
      <Wrapper>
        <ErrorContainer mobile={mobile}>
          <Title mobile={mobile}>{'Oops!'}</Title>
          <SubTitle>
            {error}
            <br />
            {'Please refresh and try again…'}
          </SubTitle>
          <Text paragraph>
            Please try again later. If the problem persists <br />
            contact us at{' '}
            <a href='email:EatonCarexStorage@Eaton.com'>
              EatonCarexStorage@Eaton.com
            </a>
          </Text>
          <Button
            id={'PrimaryButton_Retry'}
            buttonWidth={'160px'}
            onClick={() => window.location.reload()}
          >
            {'Retry'}
          </Button>
        </ErrorContainer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div``
const ErrorContainer = styled.div`
  position: relative;
  display: inline-block;
  padding: 20px;

  ${({ mobile }) =>
    !mobile &&
    `
    left: calc(50% + 80px);
    top: 320px;
  `}
`

const Title = styled(props => <PageTitle {...props} />)`
  &&&& {
    color: #007bc1;
    font-size: 56px;
  }
`

const SubTitle = styled.h2`
  color: #007bc1;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
`

const Text = styled(props => <Typography {...props} />)`
  &&&& {
    color: #424e54;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    a {
      color: #333;
      text-decoration: underline;
    }
  }
`
