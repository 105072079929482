// @flow
import { kea } from 'kea'
import { goBack } from 'react-router-redux'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'

import { log, generateUrl } from 'utils'
import { getApiErrorKey } from 'apiErrors'
import * as Check from 'validations'
import { API_BASE_URL } from 'config'

import AppLogic from 'containers/App/logic'

const ONBOARDING_ENDPOINT = `${API_BASE_URL}/devices/onboarding/{token}/claim`

export const DEFAULT_VALUES = {
  inverterSerialNumber: {
    value: ''
  },
  email: {
    value: ''
  },
  token: {
    value: ''
  }
}

const VALIDATIONS = {
  inverterSerialNumber: [Check.isRequired],
  email: [Check.isRequired, Check.Email],
  token: [Check.isRequired]
}

export default kea({
  path: () => ['scenes', 'Onboarding'],

  connect: {
    props: [
      AppLogic,
      ['isMobile', 'currentRoute', 'translations', 'currentDeviceId']
    ],
    actions: [AppLogic, ['navigate', 'setCurrentDeviceInfo', 'fetchDevices']]
  },

  actions: () => ({
    setNewTokenForm: () => true,

    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => true,
    navigateBack: () => true
  }),

  reducers: ({ actions }) => ({
    newToken: [
      false,
      PropTypes.bool,
      {
        [actions.setNewTokenForm]: () => true,
        [actions.reset]: () => false
      }
    ],

    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.submit]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.change]: () => null,
        [actions.submit]: () => null
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.navigate]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => false,
        [actions.submit]: () => true
      }
    ]
  }),

  start: function * () {
    const { setNewTokenForm, setForm } = this.actions

    log('[XS-Onboarding] Start Scene', 'yellow')

    const currentRoute = yield this.get('currentRoute')
    const { token } = currentRoute.params
    const form = yield this.get('form')

    if (token && token !== 'new') {
      const formToken = { value: token }
      yield put(setForm({ ...form, token: formToken }))
    } else if (token === 'new') {
      yield put(setNewTokenForm())
    }
  },

  stop: function * () {
    const { reset } = this.actions
    log('[XS-Onboarding] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
    [actions.navigateBack]: workers.navigateBack
  }),

  workers: {
    * submit () {
      const { setError, setForm, navigate, fetchDevices } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)

      try {
        const token = params.token
        delete params.token

        const url = generateUrl(ONBOARDING_ENDPOINT, { token: token })
        const resultData = yield call(axios.post, url, params)

        const { successful, result } = resultData.data

        if (successful) {
          window.localStorage.setItem('currentDeviceId', result.device.id)
          yield put(fetchDevices())
          yield put(navigate(`/${result.device.id}/dashboard/`))
        }
      } catch (catchError) {
        const { data } = catchError.response

        if (data && data.message) {
          const errorKey = data.message
            ? getApiErrorKey(data.message, 'ADDUNIT_')
            : 'ADDUNIT_INVALID_INFORMATION_ERROR'

          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    },

    * navigateBack () {
      yield put(goBack())
    }
  }
})
