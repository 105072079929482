/**
 * OfflineMessage Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
// @flow
import styled from 'styled-components'

export default styled.p`
  color: #CA3C3D;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`
