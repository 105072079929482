// @flow
import React, { Component } from 'react'
import { type Device } from 'types/api'
import Logic from './logic'
import { getDiskInfoColumns } from './utils'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import PageAdaptable from 'containers/PageAdaptable'
import MaintenanceMenu from 'containers/MaintenanceMenu'
import DiskInfoTable from 'containers/DiskInfoTable'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeatures from 'containers/MaintenanceSupportedFeatures'

import LoadingGif from 'components/LoadingGif'
import Subtitle from 'components/Subtitle'
import {
  PageTitle,
  PageMainSection,
  Modal,
  ModalHeader,
  ModalContent,
  Typography,
  ModalButtons,
  Button,
  SuccessSavedAlert,
  ErrorMessage,
  LabelValueInline,
  SpaceDiv,
  FixedTopBar
} from 'xstorage-components'

type Props = {
  translations: {
    MAINTENANCE_TITLE: string,
    UNITSETT_SUPPORT_MONITORING: string,
    UNITSETT_SUPPORT_DISKMANAGEMENT_FREE_DISK_SPACE: string,
    UNITSETT_SUPPORT_MONITORING_DISK: string,
    UNITSETT_SUPPORT_DISKMANAGEMENT_FREEDISKSPACE_MESSAGE: string,
    UNITSETT_DELETE_MODAL_CANCEL: string,
    UNITSETT_SUPPORT_DISKMANAGEMENT_PROCEED: string,
    UNITSETT_SUPPORT_DISKMANAGEMENT_DISKFREE_ERROR: string,
    UNITSETT_SUPPORT_DISKMANAGEMENT_DISKFREE_SUCCESS: string,
    UNITSETT_SUPPORT_MONITORING_MEMORY: string,
    UNITSETT_SUPPORT_MONITORING_MEMORY_USAGE: string,
    UNITSETT_SUPPORT_MONITORING_CPU: string,
    UNITSETT_SUPPORT_MONITORING_CPU_USAGE: string
  },
  isMobile: boolean,
  diskInfo: {},
  confirmationModal: boolean,
  loading: boolean,
  successMessage: boolean,
  errorMessage: boolean,
  currentDevice: Device,
  loadingData: boolean,
  error: string,
  showBackBar: boolean,
  fixNavbar: boolean,
  currentDeviceId: string,
  cpuInfo: {
    used: number
  },
  memoryInfo: {
    usedPercentage: string
  },
  featureSupport: {
    maintenanceUpdateRequired: boolean
  }
}

export class MaintenanceMonitoring extends Component<Props> {
  actions: {
    navigate: Function,
    setConfirmationModal: Function,
    freeDiskSpace: Function,
    setFixNavbar: Function
  }

  hideBar = () => {
    const { isMobile } = this.props
    const { setFixNavbar } = this.actions
    if (isMobile && window.scrollY > 50) {
      setFixNavbar(true)
    } else {
      setFixNavbar(false)
    }
  }

  componentDidMount () {
    const { isMobile } = this.props
    if (isMobile) {
      window.addEventListener('scroll', this.hideBar)
    }
  }

  componentWillUnmount () {
    const { isMobile } = this.props

    if (isMobile) {
      window.removeEventListener('scroll', this.hideBar)
    }
  }

  render () {
    const { navigate, setConfirmationModal, freeDiskSpace } = this.actions

    const {
      translations,
      isMobile,
      diskInfo,
      confirmationModal,
      loading,
      successMessage,
      errorMessage,
      currentDevice,
      loadingData,
      error,
      showBackBar,
      fixNavbar,
      currentDeviceId,
      cpuInfo,
      memoryInfo,
      featureSupport
    } = this.props

    const {
      MAINTENANCE_TITLE,
      UNITSETT_SUPPORT_MONITORING,
      UNITSETT_SUPPORT_DISKMANAGEMENT_FREE_DISK_SPACE,
      UNITSETT_SUPPORT_MONITORING_DISK,
      UNITSETT_SUPPORT_DISKMANAGEMENT_FREEDISKSPACE_MESSAGE,
      UNITSETT_DELETE_MODAL_CANCEL,
      UNITSETT_SUPPORT_DISKMANAGEMENT_PROCEED,
      UNITSETT_SUPPORT_DISKMANAGEMENT_DISKFREE_ERROR,
      UNITSETT_SUPPORT_DISKMANAGEMENT_DISKFREE_SUCCESS,
      UNITSETT_SUPPORT_MONITORING_MEMORY,
      UNITSETT_SUPPORT_MONITORING_MEMORY_USAGE,
      UNITSETT_SUPPORT_MONITORING_CPU,
      UNITSETT_SUPPORT_MONITORING_CPU_USAGE
    } = translations

    const { maintenanceUpdateRequired } = featureSupport

    return (
      <Wrapper>
        <PageAdaptable
          title={MAINTENANCE_TITLE}
          menu={<MaintenanceMenu page={'monitoring'} />}
          isForm={false}
          onBack={() => navigate(`/${currentDeviceId}/maintenance/`)}
          loading={loading}
        >
          <PageMainSection padding={!isMobile} mobile={isMobile}>
            {fixNavbar && (
              <FixedTopBar
                backArrowClick={() =>
                  navigate(`/${currentDeviceId}/maintenance/`)
                }
                pageTitle={UNITSETT_SUPPORT_MONITORING}
                isTechnician={showBackBar}
              />
            )}
            <PageTitle mobile={isMobile} marginBottom={'40px'}>
              {UNITSETT_SUPPORT_MONITORING}
            </PageTitle>
            <LoadingGif loading={loadingData} />
            <MaintenanceSupportedFeatures feature={'maintenance'} />
            <SuccessSavedAlert
              out={!successMessage}
              mobile={isMobile}
              icon={<Icon>{'check'}</Icon>}
              message={UNITSETT_SUPPORT_DISKMANAGEMENT_DISKFREE_SUCCESS}
            />
            <SuccessSavedAlert
              out={!errorMessage}
              mobile={isMobile}
              icon={<Icon>{'report'}</Icon>}
              message={UNITSETT_SUPPORT_DISKMANAGEMENT_DISKFREE_ERROR}
              alertColor={'#CA3C3D'}
            />
            <ErrorMessage>{error}</ErrorMessage>
            {!maintenanceUpdateRequired && Object.keys(cpuInfo).length > 0 && (
              <React.Fragment>
                <Subtitle headermargin={'16px 0'}>
                  {UNITSETT_SUPPORT_MONITORING_CPU}
                </Subtitle>
                <LabelValueInline
                  id={'CPUUsage'}
                  label={UNITSETT_SUPPORT_MONITORING_CPU_USAGE}
                  value={`${cpuInfo.used}%`}
                />
                <SpaceDiv height={'40px'} />
              </React.Fragment>
            )}
            {!maintenanceUpdateRequired &&
              Object.keys(memoryInfo).length > 0 && (
              <React.Fragment>
                <Subtitle headermargin={'16px 0'}>
                  {UNITSETT_SUPPORT_MONITORING_MEMORY}
                </Subtitle>
                <LabelValueInline
                  id={'MemoryUsage'}
                  label={UNITSETT_SUPPORT_MONITORING_MEMORY_USAGE}
                  value={memoryInfo.usedPercentage}
                />
                <SpaceDiv height={'40px'} />
              </React.Fragment>
            )}
            {!maintenanceUpdateRequired && (
              <React.Fragment>
                <Subtitle headermargin={'16px 0'}>
                  {UNITSETT_SUPPORT_MONITORING_DISK}
                </Subtitle>
                <DiskInfoTable data={diskInfo} columns={getDiskInfoColumns()} />
              </React.Fragment>
            )}

            {currentDevice.connected && !maintenanceUpdateRequired && (
              <ButtonWrapper isMobile={isMobile}>
                <Button
                  id={'PrimaryButton_FreeDisk'}
                  buttonWidth={'260px'}
                  margin={'42px 0'}
                  smallShadow
                  onClick={() => setConfirmationModal(true)}
                >
                  <Icon>{'clear_all'}</Icon>{' '}
                  {UNITSETT_SUPPORT_DISKMANAGEMENT_FREE_DISK_SPACE}
                </Button>
              </ButtonWrapper>
            )}
          </PageMainSection>
          <Modal
            open={confirmationModal}
            size={'medium'}
            onClose={() => setConfirmationModal(false)}
          >
            <div>
              <ModalHeader
                red
                title={UNITSETT_SUPPORT_DISKMANAGEMENT_FREE_DISK_SPACE}
              />
              <ModalContent padding size={'medium'} mobile={isMobile}>
                <Typography variant={'body1'}>
                  {UNITSETT_SUPPORT_DISKMANAGEMENT_FREEDISKSPACE_MESSAGE}
                </Typography>
              </ModalContent>
              <ModalButtons>
                <Button
                  id={'PrimaryButton_Cancel'}
                  buttonType={'primary-cancel'}
                  margin={'0 12px'}
                  buttonWidth={isMobile ? '156px' : '200px'}
                  onClick={() => setConfirmationModal(false)}
                >
                  {UNITSETT_DELETE_MODAL_CANCEL}
                </Button>
                <Button
                  id={'PrimaryButton_Proceed'}
                  buttonType={'primary-danger'}
                  margin={'0 12px'}
                  buttonWidth={isMobile ? '156px' : '200px'}
                  onClick={() => freeDiskSpace()}
                >
                  {UNITSETT_SUPPORT_DISKMANAGEMENT_PROCEED}
                </Button>
              </ModalButtons>
            </div>
          </Modal>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceMonitoring)

const Wrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  z-index: 10;

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
  `}
`
