// @flow
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, Grid, Typography } from '@material-ui/core'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  Button,
  Paper,
  PageMainSection,
  TextList,
  Point
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  userValid: boolean
}

export class UserProfileDownloadInfo extends Component<Props, void> {
  actions: any

  render () {
    const { isMobile, translations, userValid } = this.props

    const { navigate } = this.actions

    const {
      USERPROF_TITLE,
      USERPROF_DOWNLOAD_TITLE,
      USERPROF_DOWNLOAD_SUB_TITLE,
      // USERPROF_DOWNLOAD_ALERT,
      USERPROF_DOWNLOAD_ALERT_UNAVAILABLE,
      USERPROF_DOWNLOAD_REQUEST_BUTTON,
      USERPROF_DOWNLOAD_ISINCLUDED
    } = translations

    const pionts = Object.keys(translations).filter(key =>
      /USERPROF_DOWNLOAD_INFOPOINT(\d+)/.test(key)
    )

    const content = (
      <Fragment>
        <PageTitle mobile={isMobile} blue>
          {USERPROF_DOWNLOAD_TITLE}
        </PageTitle>
        <TextContent>
          <Typography variant={'h4'} gutterBottom>
            {USERPROF_DOWNLOAD_SUB_TITLE}
          </Typography>
          <Typography variant={'h5'}>
            {USERPROF_DOWNLOAD_ISINCLUDED}
          </Typography>
          <TextList>
            {pionts.map(point => (
              <Point key={point}>{translations[point]}</Point>
            ))}
          </TextList>
          <ErrorTypography color={'error'} variant={'h5'}>
            {USERPROF_DOWNLOAD_ALERT_UNAVAILABLE}
            <a href='email:EatonCarexStorage@Eaton.com'>
              EatonCarexStorage@Eaton.com
            </a>
          </ErrorTypography>
        </TextContent>
        <Buttons mobile={isMobile}>
          <Button
            id={'PrimaryButton_Download'}
            margin={'0px'}
            buttonWidth={'227px'}
            disabled
          >
            <Icon>{'get_app'}</Icon> {USERPROF_DOWNLOAD_REQUEST_BUTTON}
          </Button>
        </Buttons>
      </Fragment>
    )

    return (
      <div>
        {!userValid && !isMobile && (
          <Grid container spacing={3} justify={'center'}>
            <Grid item xs={8}>
              <Paper>{content}</Paper>
            </Grid>
          </Grid>
        )}
        {!userValid && isMobile && content}

        {userValid && (
          <PageAdaptable
            title={USERPROF_TITLE}
            menu={<UserProfileMenu page={'download-info'} />}
            onBack={() => navigate('/user-profile/')}
            isForm={false}
          >
            <PageMainSection padding mobile={isMobile}>
              {content}
            </PageMainSection>
          </PageAdaptable>
        )}
      </div>
    )
  }
}

export default Logic(UserProfileDownloadInfo)

const TextContent = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: 1024px) {
    margin-top: 50px;
  }
`

const Buttons = styled.div`
  margin: 24px 0;

  ${({ mobile }) =>
    mobile &&
    `
    text-align: center;
  `}

  > button {
    ${({ mobile }) =>
    mobile &&
      `
      margin: 0 auto;
    `}
  }
`

const ErrorTypography = styled(Typography)`
  &&&& {
    > a {
      color: #ca3c3d;
    }
  }
`
