// @flow
import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import { API_BASE_URL } from 'config'
import { log, generateUrl } from 'utils'
import { mapValues, orderBy, pick, size } from 'lodash'
import moment from 'moment-timezone'
import { getApiErrorKey } from 'apiErrors'

import PropTypes from 'prop-types'
import * as Check from 'validations'

import AppLogic from 'containers/App/logic'
// eslint-disable-next-line max-len
import TechnicianUntakenUnitsLogic from 'scenes/TechnicianProfile/TechnicianUntakenUnits/logic'

// eslint-disable-next-line max-len
const UPDATE_ONBOARDING_ENDPOINT = `${API_BASE_URL}/devices/onboarding/{deviceId}`
const CREATE_ONBOARDING_ENDPOINT = `${API_BASE_URL}/devices/onboarding/`
// eslint-disable-next-line max-len
const RESEND_ONBOARDING_ENDPOINT = `${API_BASE_URL}/devices/onboarding/{deviceId}/resend`
const COUNTRIES_ENDPOINT: string = `${API_BASE_URL}/internal/countries`
const CITIES_ENDPOINT: string = `${API_BASE_URL}/internal/cities`

const DEFAULT_VALUES = {
  firstName: {
    value: ''
  },
  lastName: {
    value: ''
  },
  email: {
    value: ''
  },
  confirmEmail: {
    value: ''
  },
  address: {
    value: ''
  },
  postalCode: {
    value: ''
  },
  country: {
    value: ''
  },
  city: {
    value: ''
  },
  installationDate: {
    value: moment()
  },
  inverterSerialNumber: {
    value: ''
  },
  confirmInfo: {
    value: false
  }
}

const VALIDATIONS = {
  firstName: [Check.isRequired],
  lastName: [Check.isRequired],
  email: [Check.isRequired, Check.Email],
  confirmEmail: [Check.isRequired, Check.Email],
  address: [Check.isRequired],
  postalCode: [Check.isRequired],
  country: [Check.isRequired],
  city: [Check.isRequired],
  installationDate: [Check.isRequired],
  inverterSerialNumber: [Check.isRequired],
  confirmInfo: [Check.isRequired]
}

export default kea({
  path: () => ['scenes', 'containers', 'UnitInfoForm'],

  connect: {
    props: [AppLogic, ['isMobile', 'translations']],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    editUnit: () => true,
    cancelInputMode: () => true,
    getUnitInfo: () => true,

    setInfo: info => ({ info }),
    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => true,
    showResendModal: () => true,
    hideResendModal: () => true,
    resendInvitation: () => true,
    showCancelOwnershipModal: () => true,
    hideCancelOwnershipModal: () => true,
    cancelOwnership: () => true,
    setCountries: countries => ({ countries }),
    getCountries: () => true,
    setCities: cities => ({ cities }),
    getCities: () => true,
    setLoadingInProgress: () => true,
    setLoading: loading => loading
  }),

  reducers: ({ actions }) => ({
    inputMode: [
      false,
      PropTypes.bool,
      {
        [actions.editUnit]: () => true,
        [actions.cancelInputMode]: () => false,
        [actions.reset]: () => false,
        [actions.setInfo]: (state, payload) => !payload.info
      }
    ],

    unitInfo: [
      {},
      PropTypes.object,
      {
        [actions.setInfo]: (state, payload) => payload.info,
        [actions.reset]: () => ({})
      }
    ],

    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.submit]: () => false,
        [actions.change]: () => true
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.setInfo]: () => false,
        [actions.submit]: () => true,
        [actions.setLoading]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.change]: () => null,
        [actions.submit]: () => null
      }
    ],

    resendModal: [
      false,
      PropTypes.bool,
      {
        [actions.showResendModal]: () => true,
        [actions.hideResendModal]: () => false,
        [actions.reset]: () => false
      }
    ],

    cancelOwnershipModal: [
      false,
      PropTypes.bool,
      {
        [actions.showCancelOwnershipModal]: () => true,
        [actions.hideCancelOwnershipModal]: () => false,
        [actions.reset]: () => false
      }
    ],

    countries: [
      [],
      PropTypes.array,
      {
        [actions.setCountries]: (state, payload) => payload.countries,
        [actions.reset]: () => []
      }
    ],

    cities: [
      [],
      PropTypes.array,
      {
        [actions.setCities]: (state, payload) => payload.cities,
        [actions.reset]: () => []
      }
    ],

    loadingInProgress: [
      false,
      PropTypes.bool,
      {
        [actions.setLoadingInProgress]: (state, payload) => !state,
        [actions.reset]: () => false
      }
    ]
  }),

  start: function * () {
    log('[XS-UnitInfoForm] Start container', 'orange')
    const { currentUnit } = this.props
    const { setInfo, setForm, getCountries, getCities } = this.actions
    const countries = yield this.get('countries')
    const form = yield this.get('form')

    yield put(setInfo(currentUnit))

    if (currentUnit) {
      const formUnit = mapValues(currentUnit, value => ({ value }))
      formUnit.confirmEmail = formUnit.email
      formUnit.confirmInfo = { value: true }
      formUnit.inverterSerialNumber = {
        value: currentUnit.device.inverterSerialNumber
      }
      formUnit.installationDate = {
        value: moment(formUnit.installationDate.value)
      }
      formUnit.country = {
        value: currentUnit.country && currentUnit.country.id
      }
      formUnit.city = { value: currentUnit.city && currentUnit.city.id }

      yield put(setForm(formUnit))
    }

    const inputMode = yield this.get('inputMode')
    if (inputMode && countries.length === 0) {
      yield put(getCountries())
    }

    if (inputMode && form.country.value !== '') {
      yield put(getCities())
    }
  },

  stop: function * () {
    log('[XS-UnitInfoForm] Stop container')

    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
    [actions.resendInvitation]: workers.resendInvitation,
    [actions.cancelOwnership]: workers.cancelOwnership,
    [actions.editUnit]: workers.getCountries,
    [actions.getCountries]: workers.getCountries,
    [actions.getCities]: workers.getCities,
    [actions.change]: workers.getCities,
    [actions.cancelInputMode]: workers.resetFormToUnitInfo
  }),

  workers: {
    * submit () {
      const { currentUnit } = this.props
      const { setError, setForm, navigate, setLoading } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')
      const isMobile = yield this.get('isMobile')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (form.email.value !== form.confirmEmail.value) {
        yield put(setError(translations['ERR_EMAIL_NOT_MATCH']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)
      params.installationDate = moment(params.installationDate).valueOf()

      let data = pick(params, [
        'inverterSerialNumber',
        'firstName',
        'lastName',
        'email',
        'city',
        'country',
        'installationDate',
        'address',
        'postalCode'
      ])

      data.countryId = data.country
      data.cityId = data.city
      try {
        if (currentUnit) {
          const url = generateUrl(UPDATE_ONBOARDING_ENDPOINT, {
            deviceId: currentUnit.id
          })
          const result = yield call(axios.put, url, data)
          const { successful } = result.data

          if (successful) {
            yield put(setLoading(false))

            if (!isMobile) {
              const { onCancel } = this.props
              yield call(onCancel)
            } else {
              yield put(navigate('/untaken-units/'))
            }
          }
        } else {
          const result = yield call(
            axios.post,
            CREATE_ONBOARDING_ENDPOINT,
            data
          )
          const { successful } = result.data

          if (successful) {
            if (!isMobile) {
              const { onCancel } = this.props
              yield call(onCancel)
            } else {
              yield put(navigate('/untaken-units/'))
            }
          }
        }
      } catch (catchError) {
        const { data } = catchError.response || {}
        if (data && data.message) {
          const translations = yield this.get('translations')
          const errorKey = getApiErrorKey(data.message, 'ADDUNIT_')
          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    },

    * resendInvitation () {
      const unitInfo = yield this.get('unitInfo')
      const { hideResendModal, setLoadingInProgress } = this.actions

      yield put(setLoadingInProgress())
      try {
        const config = {
          headers: { 'content-type': 'application/json' }
        }
        const url = generateUrl(RESEND_ONBOARDING_ENDPOINT, {
          deviceId: unitInfo.id
        })
        const request = yield call(axios.post, url, null, config)

        const { successful } = request.data

        if (successful) {
          yield put(hideResendModal())
        }
        yield put(setLoadingInProgress())
      } catch (catchError) {
        console.log(catchError)
        yield put(setLoadingInProgress())
      }
    },

    * cancelOwnership () {
      const unitInfo = yield this.get('unitInfo')
      const isMobile = yield this.get('isMobile')
      const {
        hideCancelOwnershipModal,
        setLoadingInProgress,
        navigate
      } = this.actions

      yield put(setLoadingInProgress())

      try {
        const config = {
          headers: { 'content-type': 'application/json' }
        }
        const url = generateUrl(UPDATE_ONBOARDING_ENDPOINT, {
          deviceId: unitInfo.id
        })
        const request = yield call(axios.delete, url, null, config)

        const { successful } = request.data

        if (successful) {
          yield put(hideCancelOwnershipModal())
          if (isMobile) {
            yield put(navigate('/untaken-units/'))
          } else {
            yield put(TechnicianUntakenUnitsLogic.actions.closeUnitPanel())
          }
        }
        yield put(setLoadingInProgress())
      } catch (catchError) {
        console.log(catchError)
        yield put(setLoadingInProgress())
      }
    },

    * getCountries () {
      const countries = yield this.get('countries')
      const { setCountries, getCities } = this.actions

      if (countries.length === 0) {
        try {
          const params = { size: -1 }
          const request = yield call(axios.get, COUNTRIES_ENDPOINT, { params })
          const { result } = request.data
          const countries = orderBy(result.data, ['name'], ['asc'])
          yield put(setCountries(countries))
        } catch (catchError) {
          console.log(catchError)
        }
      }

      yield put(getCities())
    },

    * getCities (action) {
      const { setCities, change } = this.actions
      const { field } = action.payload

      if (size(action.payload) === 0 || (field && field.city === '')) {
        try {
          const { country, city } = yield this.get('form')
          if (country.value) {
            const params = { countryId: country.value, size: 0 }
            const request = yield call(axios.get, CITIES_ENDPOINT, { params })
            const { result } = request.data
            if (result) {
              const cities = orderBy(result.data, ['name'], ['asc'])
              yield put(setCities(cities))
              if (city.value === '' && cities.length > 0) {
                yield put(change({ city: cities[0].id }))
              }
            }
          }
        } catch (catchError) {
          console.log(catchError)
        }
      }
    },

    * resetFormToUnitInfo () {
      const unitInfo = yield this.get('unitInfo')
      const { setForm } = this.actions

      if (unitInfo) {
        const formUnit = mapValues(unitInfo, value => ({ value }))
        formUnit.confirmEmail = formUnit.email
        formUnit.confirmInfo = { value: true }
        formUnit.inverterSerialNumber = {
          value: unitInfo.device.inverterSerialNumber
        }
        formUnit.installationDate = {
          value: moment(formUnit.installationDate.value)
        }
        formUnit.country = { value: unitInfo.country && unitInfo.country.id }
        formUnit.city = { value: unitInfo.city && unitInfo.city.id }

        yield put(setForm(formUnit))
      }
    }
  }
})
