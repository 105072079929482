// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { Paper, UnitInstallationCard } from 'xstorage-components'

type Props = {}

class InstalledUnitsList extends Component<Props, void> {
  render () {
    const {
      onboardedUnits,
      translations,
      onboardedUnitsLength,
      isMobile
    } = this.props
    const { TECH_UNITS_ONBOARDED, TECH_UNITS_NOT_ONBOARDED } = translations

    return (
      <Wrapper>
        <Grid container spacing={3}>
          {onboardedUnitsLength > 0 &&
            onboardedUnits.map(unit => (
              <Grid key={unit.id} item xs={12} sm={6} md={4}>
                <Paper showanimation mobile={isMobile} shadow>
                  <UnitInstallationCard
                    unit={unit}
                    onboarded={TECH_UNITS_ONBOARDED}
                    notonboarded={TECH_UNITS_NOT_ONBOARDED}
                  />
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Wrapper>
    )
  }
}

export default Logic(InstalledUnitsList)

const Wrapper = styled.div``
