// @flow
import { kea } from 'kea'

import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import { API_BASE_URL } from 'config'
import { log, generateUrl } from 'utils'
import { mapValues } from 'lodash'
import { getApiErrorKey } from 'apiErrors'

import PropTypes from 'prop-types'
import * as Check from 'validations'

import AppLogic from 'containers/App/logic'

const ACCEPT_ENDPOINT = `${API_BASE_URL}/devices/roles/invites/{token}/claim`
const REJECT_ENDPOINT = `${API_BASE_URL}/devices/roles/invites/{token}/reject`

export const DEFAULT_VALUES = {
  email: {
    value: ''
  }
}

const VALIDATIONS = {
  email: [Check.isRequired, Check.Email]
}

export default kea({
  path: () => ['scenes', 'DeviceInvite'],

  connect: {
    props: [
      AppLogic,
      [
        'isMobile',
        'user',
        'translations',
        'devices',
        'currentRoute',
        'currentDeviceId'
      ]
    ],
    actions: [AppLogic, ['navigate', 'fetchDevices']]
  },

  actions: () => ({
    change: field => ({ field }),
    submit: reject => ({ reject }),
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => true
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.setError]: () => true,
        [actions.change]: () => true,
        [actions.reset]: () => false
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setForm]: () => false,
        [actions.navigate]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => false,
        [actions.submit]: () => true
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.change]: () => null,
        [actions.submit]: () => null
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    /** checking for ownership invite page */
    isOwnership: [
      () => [selectors.currentRoute],
      ({ path }: any) => path && path.indexOf('ownership') !== -1,
      PropTypes.bool
    ]
  }),

  start: function * () {
    yield log('[XS-DeviceInvite] Start Scene', 'yellow')
  },

  stop: function * () {
    const { reset } = this.actions
    log('[XS-DeviceInvite] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit
  }),

  workers: {
    * submit (action) {
      const { setError, setForm, navigate, fetchDevices } = this.actions

      const { reject } = action.payload

      const form = yield this.get('form')
      const currentDeviceId = yield this.get('currentDeviceId')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)

      try {
        const currentRoute = yield this.get('currentRoute')
        const { token } = currentRoute.params

        let endpoint = reject ? REJECT_ENDPOINT : ACCEPT_ENDPOINT
        const url = generateUrl(endpoint, { token: token })
        const resultData = yield call(axios.post, url, params)
        const { successful, result } = resultData.data
        if (successful) {
          if (reject) {
            if (currentDeviceId) {
              yield put(navigate(`/${currentDeviceId}/dashboard/`))
            } else {
              yield put(navigate('/add-device/'))
            }
          } else {
            window.localStorage.setItem('currentDeviceId', result.deviceId)
            yield put(fetchDevices())
            yield put(navigate(`/${result.deviceId}/dashboard/`))
          }
        }
      } catch (catchError) {
        if (catchError.response) {
          const { data } = catchError.response

          const errorKey = data.message
            ? getApiErrorKey(data.message, 'UNITINVITE_')
            : 'UNITINVITE_INVALID_INFORMATION_ERROR'

          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    }
  }
})
