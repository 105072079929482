// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Prompt } from 'react-router'
import Typography from '@material-ui/core/Typography'

import {
  PageTitle,
  Loading,
  BackButton,
  Modal,
  ModalButtons,
  Button,
  ModalContent,
  ModalHeader
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  loading: boolean,
  children: any,
  menu: any,
  onBack: Function,
  title: string
}

class PageAdaptable extends Component<Props, void> {
  first = true

  componentWillMount () {
    this.first = true
  }

  handleBlockedNavigation = nextLocation => {
    const { isForm, isDirty } = this.props
    const { showConfirmationModal, setNextLocation } = this.actions

    if (isForm && isDirty && this.first) {
      this.first = false
      showConfirmationModal()
      setNextLocation(nextLocation)
      return false
    }

    return true
  }

  render () {
    const {
      isMobile,
      loading,
      children,
      menu,
      onBack,
      title,
      translations,
      confirmationModal,
      isForm,
      isDirty
    } = this.props

    const {
      showConfirmationModal,
      hideConfirmationModal,
      handleConfirmNavigationClick
    } = this.actions

    const {
      GENERAL_CANCEL,
      GENERAL_CONTINUE,
      BACK_BUTTON_MODAL_TEXT,
      BACK_BUTTON_MODAL_TITLE
    } = translations

    return (
      <Wrapper mobile={isMobile}>
        {!!loading && <Loading />}

        {isMobile && (
          <div>
            <BackButton
              id={'PageAdaptable_BackButton'}
              onClick={isForm && isDirty ? showConfirmationModal : onBack}
            />
            {children}
          </div>
        )}

        {!isMobile && (
          <div>
            <Prompt
              when={isForm && isDirty}
              message={this.handleBlockedNavigation}
            />
            <PageTitle mobile={isMobile}>{title}</PageTitle>
            <MenuContainer>{menu}</MenuContainer>
            <TabContainer>{children}</TabContainer>
          </div>
        )}
        <Modal size={'small'} open={confirmationModal}>
          <div>
            <ModalHeader title={BACK_BUTTON_MODAL_TITLE} />
            <ModalContent padding size={'small'} mobile={isMobile}>
              <Typography variant={'body1'}>
                {BACK_BUTTON_MODAL_TEXT}
              </Typography>
            </ModalContent>
            <ModalButtons>
              <Button
                margin={'0 12px'}
                buttonType={'primary-cancel'}
                buttonWidth={'156px'}
                onClick={hideConfirmationModal}
              >
                {GENERAL_CANCEL}
              </Button>
              <Button
                margin={'0 12px'}
                buttonWidth={'156px'}
                onClick={isMobile ? onBack : handleConfirmNavigationClick}
              >
                {GENERAL_CONTINUE}
              </Button>
            </ModalButtons>
          </div>
        </Modal>
      </Wrapper>
    )
  }
}

export default Logic(PageAdaptable)

const Wrapper = styled.div`
  width: 100%;
  padding: 16px 0 0 24px;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const MenuContainer = styled.div`
  width: 40%;
  padding-right: 5%;
  float: left;
  position: relative;
  color: #fff;
  margin-top: 25px;
`

const TabContainer = styled.div`
  width: 60%;
  float: left;
  padding-right: 0;
`
