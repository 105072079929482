// @flow
import { timeStringToFloat } from 'utils'
import moment from 'moment-timezone'

/**
 * Helper function that returns the current on float number
 *
 * TODO: create tests for this function
 */
export const getCurrentFloatTime = (): number => {
  const hours = moment(new Date()).hour()
  const minutes = moment(new Date()).minute()

  let time = `${hours}:${minutes}`

  // after 23:50 is causing a bug with layout because the
  // hour indicater pass through the limit of container
  // so we just difini the limit for the hour indicator
  if (hours === 23 && minutes > 50) {
    time = '23:50'
  }

  const result = timeStringToFloat(time)
  return parseFloat(result.toFixed(2))
}
