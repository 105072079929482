import { getStore } from 'kea'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import sagaPlugin from 'kea-saga'

export const history = createBrowserHistory({
  basename: ''
})

history.listen(() => {
  ReactGA.pageview(window.location.pathname + window.location.search)
})

export const createNewStore = () => getStore({
  plugins: [ sagaPlugin ],
  middleware: [
    routerMiddleware(history)
  ],
  reducers: {
    router: routerReducer
  }
})

export const Store = createNewStore()
