/**
 * AddDevice Scene
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import {
  type User
} from 'types/api'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { Grid, Icon } from '@material-ui/core'
import * as animationData from './data/no-units.json'
import BGMobile from 'assets/background-blue-tablet.svg'

import {
  PageTitle,
  Button,
  Typography,
  SpaceDiv
} from 'xstorage-components'

type Props = {
  user: User,
  isMobile: boolean,
  translations: any,
  isOnboarding: boolean
}

class AddDevice extends Component<Props> {
  actions: {
    navigate: Function
  }

  render () {
    const {
      user,
      isMobile,
      translations,
      isOnboarding
    } = this.props

    const {
      navigate
    } = this.actions

    const {
      firstName,
      lastName
    } = user || {}

    const {
      ADDUNIT_TITLE,
      ADDUNIT_WELCOME,
      ADDUNIT_CONTENT_TEXT,
      ADDUNIT_ADD_BUTTON,
      ADDUNIT_VISIT_SITE_BUTTON
    } = translations

    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData
    }

    const animation = (
      <NoDevicesAnim
        mobile={isMobile}
        onboarding={isOnboarding}
      >
        <Lottie
          options={options}
          isClickToPauseDisabled
          height={isMobile ? 350 : '60vh'}
          width={isMobile ? 400 : '60vh'}
        />
      </NoDevicesAnim>
    )

    let content = (
      <TextContainer mobile={isMobile}>
        <Text mobile={isMobile.toString()}>
          {ADDUNIT_CONTENT_TEXT}
        </Text>
        <div>
          <Button
            id={'PrimaryButton_Add'}
            iconfloat={'center'}
            margin={'0 0 28px 0'}
            buttonWidth={'263px'}
            onClick={() => navigate('/onboarding/new/')}
          >
            <Icon>{'add'}</Icon> {ADDUNIT_ADD_BUTTON}
          </Button>
        </div>
        <div>
          <Button
            id={'SecondaryButton_VisitSite'}
            buttonType={'secondary-default'}
            iconfloat={'center'}
            buttonWidth={'263px'}
            onClick={() => {
              // eslint-disable-next-line max-len
              window.open('https://uk.eaton.com/content/gb/en/products/electrical/xStorage.html', '_blank')
            }}
          >
            <Icon>{'public'}</Icon> {ADDUNIT_VISIT_SITE_BUTTON}
          </Button>
        </div>
        <SpaceDiv height={'10vh'} />
      </TextContainer>
    )

    return (
      <Wrapper mobile={isMobile} >
        {isMobile &&
          <div>
            <MobilePageTitle
              mobile={isMobile}
              center
            >
              {ADDUNIT_WELCOME} {firstName}
            </MobilePageTitle>
            {animation}
            {content}
          </div>
        }

        {!isMobile &&
          <div>
            <PageTitle mobile={isMobile}>{ADDUNIT_TITLE}</PageTitle>
            <PageSubTitle>
              {ADDUNIT_WELCOME} {firstName} {lastName}...
            </PageSubTitle>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                {animation}
              </Grid>
              <Grid item xs={6}>
                {content}
              </Grid>
            </Grid>
          </div>
        }
      </Wrapper>
    )
  }
}

export default Logic(AddDevice)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }

  ${({ mobile }) => mobile && `
    min-height: calc(100vh - 56px);
    background: #FFFFFF;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    background-image: url(${BGMobile})
  `}
`

const MobilePageTitle = styled(props => <PageTitle {...props} />)`
  &&&& {
    max-height: 47px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 400px) {
      font-size: 26px;
    }
  }
`

const PageSubTitle = styled.h3`
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 600;
  line-height: 16px;
  margin-top: -5px;
`

const NoDevicesAnim = styled.div`
  position: relative;

  > * {
    outline: none;
  }

  &:after{
    content: '';
    position: absolute;
    z-index: 10;
  }

  ${({ mobile }) => !mobile && `
    > * {
      position: fixed;

      left: 50%;
      transform: translateX(-65%);
      top: 20%;
    }

    &:after{
      width: 100vh;
      height: 100vw;
      left: 46vw;
      top: 0%;
      transform: rotate(13deg);
      background: #f7f7f9;
      position: fixed;
      z-index: 10;
    }
  `}

  ${({ mobile }) => mobile && `
    height: 300px;

    @media screen and (max-width: 370px) {
      height: 240px;

      > * {
        height: 280px!important;
      }
    }

    > * {
      position: absolute;
      bottom: 0;
      left: calc(50% - 200px);
    }

    &:after{
      width: 93px;
      height: 1100px;
      left: 45%;
      top: -313px;
      transform: rotate(71.4deg);
      background: #FFF;

      @media screen and (max-width: 550px) {
        left: 50.5%;
      }
      @media screen and (max-width: 400px) {
        left: 50.5%;
      }
      @media screen and (max-width: 370px) {
        width: 100px;
        height: 1100px;
        left: 50.5%;
        top: -360px;
      }
    }
  `}
`

const TextContainer = styled.div`

  ${({ mobile }) => mobile && `
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;    
    width: 100%;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 370px) {
      top: -30px;
    }
  `}

  ${({ mobile }) => !mobile && `
    padding-left: 60px;
    z-index: 11; 
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  `}
`

const Text = styled(props => <Typography {...props} />)`
  &&&&{
    color: #007BC1;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    max-width: 311px;
    margin-bottom: 26px;

    ${({ mobile }) => mobile === 'true' && `
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      text-align: center;
    `}
  }
`
