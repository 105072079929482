// @flow
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

type Props = {
  open: boolean,
  title: any,
  onClose: Function,
  children: any,
  overlay?: boolean,
  fixed?: boolean,
  mobile?: boolean,
  type?: "content-not-padded" | ""
}

class SlidePanel extends PureComponent<Props, void> {
  actions: any
  slideContainer: any

  componentDidMount () {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside = (event: any) => {
    const {
      open,
      onClose,
      fixed
    } = this.props

    if (!open || fixed) {
      return
    }

    const domNode = ReactDOM.findDOMNode(this.slideContainer)
    if (!domNode || !domNode.contains(event.target)) {
      onClose()
    }
  }

  render () {
    const {
      open,
      title,
      overlay,
      children,
      onClose,
      type,
      mobile,
      marginTitle
    } = this.props

    return (
      <Wrapper>
        <Container mobile={mobile} open={open}>
          <SlideContainer
            open={open}
            mobile={mobile}
            ref={comp => { this.slideContainer = comp }}
          >
            {open &&
              <div>
                <CloseButton
                  id={'Close_Button'}
                  mobile={mobile}
                  onClick={onClose}
                >
                  <Icon>{'close'}</Icon>
                </CloseButton>
                <TitleContainer>
                  <Title
                    id={'SlidePanel_Title'}
                    mobile={mobile}
                    marginTitle={marginTitle}
                  >{title}</Title>
                </TitleContainer>
                <PanelContainer padding={type !== 'content-not-padded'}>
                  {children}
                </PanelContainer>
              </div>
            }
          </SlideContainer>
        </Container>
        {(overlay && open) && <Overlay />}
      </Wrapper>
    )
  }
}

export default SlidePanel

const Wrapper = styled.div`
`

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1199;
  background-color: rgba(0,0,0,0.5);
`

const Container = styled.div`
  position: fixed;
  z-index: 1200;
  overflow: hidden;

  ${({ mobile }) => !mobile && `
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
  `}

  ${({ mobile }) => mobile && `
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
  `}

  ${({ open, mobile }) => (open && !mobile) && `
    width: 410px;
    transition: all 0.5s ease-in-out;
  `}

  ${({ open, mobile }) => (open && mobile) && `
    height: 410px;
    transition: all 0.5s ease-in-out;
  `}
`

const SlideContainer = styled.div`
  position: absolute;
  background: #FDFDFD;
  box-shadow: 0 0 16px 0 rgba(66,78,84,0.1);

  ${({ mobile }) => !mobile && `
    right: -398px;
    width: 398px;
    height: 100%;
  `}

  ${({ mobile }) => mobile && `
    bottom: -180px;
    right: 0;
    width: 100%;
    height: 180px;
    border-radius: 6px 6px 0 0;
  `}

  ${({ mobile }) => !mobile && `
    transition: all 0.5s;
    transform: translate3d(-398px, 0, 0);
    animation-timing-function: 1s ease-in-out;
  `}

  ${({ mobile }) => mobile && `
    transition: all 0.5s;
    transform: translate3d(0, -180px, 0);
    animation-timing-function: 1s ease-in-out;
  `}
`

const TitleContainer = styled.div`
  padding: 20px 20px 0;
`

const PanelContainer = styled.div`
  padding: 0 20px;
  
  ${({ padding }) => padding && `
    padding: 20px;
  `}  
`

const Title = styled.h2`
  color: #007BC1;
  font-family: Eaton;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: ${({ marginTitle }) => marginTitle || '15px 0'};

  ${({ mobile }) => mobile && `
    color: #333F48;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin-top: 0px;
  `}

`

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 40px;
  color: #007BC1;
  cursor: pointer;

  span{
    font-size: 20px;
    font-weight: bold;
  }

  ${({ mobile }) => mobile && `
    top: 20px;
  `}
`
