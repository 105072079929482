// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import {
  PageTitle,
  BackButton,
  MoreNavItem,
  IcDocument
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: {
    EULA_TERMS_TITLE: string,
    EULA_TERMS_EULA: string
  },
  userIsTechnician: boolean,
  currentDeviceId: string
}

export class Terms extends Component<Props, void> {
  actions: any

  render () {
    const {
      isMobile,
      translations,
      userIsTechnician,
      currentDeviceId
    } = this.props

    const { navigate } = this.actions

    const { EULA_TERMS_TITLE, EULA_TERMS_EULA } = translations

    const backUrl = userIsTechnician
      ? '/tech-more/'
      : `/${currentDeviceId}/more/`

    return (
      <Wrapper>
        {isMobile && (
          <BackButton id={'BackButton'} onClick={() => navigate(backUrl)} />
        )}
        <PageTitle mobile={isMobile} blue={isMobile}>
          {EULA_TERMS_TITLE}
        </PageTitle>

        {isMobile && (
          <MobileButtons>
            <MoreNavItem
              id={'Eulas'}
              title={EULA_TERMS_EULA}
              onClick={() => navigate('/terms/eula/')}
              link
            />
            {/* <MoreNavItem
              title={EULA_TERMS_TERMS_AND_COND}
              onClick={() => navigate('/terms/conditions/')}
              link
            /> */}
          </MobileButtons>
        )}

        {!isMobile && (
          <ButtonsContainer>
            <TermsButtton onClick={() => navigate('/terms/eula/')}>
              <ContentButton>
                <Document />
                <Label>{EULA_TERMS_EULA}</Label>
              </ContentButton>
              <ShadowButton />
            </TermsButtton>
            {/* <TermsButtton onClick={() => navigate(`/terms/conditions/`)}>
              <ContentButton>
                <Document />
                <Label>{EULA_TERMS_TERMS_AND_COND}</Label>
              </ContentButton>
              <ShadowButton />
            </TermsButtton> */}
          </ButtonsContainer>
        )}
      </Wrapper>
    )
  }
}

export default Logic(Terms)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25vh;
`

const TermsButtton = styled.div``

const ContentButton = styled.div`
  min-height: 300px;
  min-width: 300px;
  margin: 0 10px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(114, 126, 132, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
`

const ShadowButton = styled.div`
  position: relative;
  z-index: 1;
  width: calc(100% - 48px);
  left: 24px;
  height: 10px;
  top: -10px;
  background-color: #727e84;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
`

const Label = styled.span`
  color: #424e54;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

const Document = styled(props => <IcDocument {...props} />)`
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
`

const MobileButtons = styled.div`
  > div:before {
    content: '';
    position: absolute;
    width: calc(100% - 20px);
    height: 56px;
    box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);

    @media screen and (min-width: 550px) and (max-width: 1024px) {
      width: calc(100% - 50px);
    }
  }
`
