/**
 * UserProfileChangePass Container Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */

// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'UserProfileChangePass'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'configs',
        'authProvider'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'changeUserPassword'
      ]
    ]
  },

  actions: () => ({
    changePassword: () => ({ })
  }),

  reducers: ({ actions }) => ({

    loading: [false, PropTypes.bool, {
      [actions.changePassword]: (state, payload) => true
    }]

  }),

  start: function * () {
    const authProvider = yield this.get('authProvider')
    const { navigate } = this.actions

    if (authProvider !== 'MICROSOFT') {
      yield put(navigate('/401'))
    }
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.changePassword]: workers.changePassword
  }),

  workers: {

    * changePassword () {
      const { changeUserPassword } = this.actions

      yield put(changeUserPassword())
    }
  }

})
