// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Logic from './logic'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageMainSection,
  PageTitle
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  error?: string
}

type State = {
  showReleaseNotes: boolean
}

export class DeviceUpdates extends Component<Props, State> {
  state = {
    showReleaseNotes: false
  }

  render() {
    const {
      isMobile,
      translations,
      // currentDevice,
      // mostRecentUpdate,
      // updateInProgress,
      // showConfirmationUpdate,
      // userCanUpdateUnit,
      loading,
      // userRole,
      currentDeviceId,
      // error,
      // successMessage
    } = this.props

    const {
      navigate,
      // updateUnit,
      // setConfirmationUpdate,
      // getUpdatesList
    } = this.actions

    // const { showReleaseNotes } = this.state
    // const { bundleVersion } = currentDevice

    const {
      UNITSETT_TITLE,
      UNITSETT_UPDATES_TITLE,
      // UNITSETT_UPDATES_CURRENT_VERSION,
      // UNITSETT_UPDATES_AVAIL_VERSION,
      // UNITSETT_UPDATES_VIEW_R_NOTES,
      // UNITSETT_UPDATES_ALERT,
      // UNITSETT_UPDATES_INSTALL_BUTTON,
      // UNITSETT_UPDATES_R_NOTES_TITLE,
      // UNITSETT_UPDATES_R_NOTES_SUB_TITLE,
      // UNITSETT_UPDATE_PROGRESS,
      // SCHEDULE_EVENT_DELETE_MODAL_CANCEL,
      // UNITSETT_UPDATES_CONFIRM,
      // UNITSETT_UPDATES_CHECK_BUTTON,
      // UNITSETT_UPDATES_NO_UPDATES,
      // UNITSETT_UPDATES_UPDATES_BLOCKED,
      // UNITSETT_UPDATES_UPDATES_BLOCKED_CONTACT_TECH,
      // UNITSETT_UPDATES_UPDATES_BLOCKED_GO_SETTINGS,
      // UNITSETT_UPDATES_STARTED_SUCCESSFULLY,
      UNITSETT_UPDATES_TEMPORARILY_DISABLED
    } = translations

    // const areThereReleaseNotesToDisplay =
    //   mostRecentUpdate &&
    //   mostRecentUpdate.releaseNotes &&
    //   mostRecentUpdate.releaseNotes.length > 0

    return (
      <Wrapper>
        <PageAdaptable
          title={UNITSETT_TITLE}
          menu={<DeviceSettingsMenu page={'updates'} />}
          isForm={false}
          onBack={() => navigate(`/${currentDeviceId}/settings/`)}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle mobile={isMobile}>{UNITSETT_UPDATES_TITLE}</PageTitle>
            <TmpText>{UNITSETT_UPDATES_TEMPORARILY_DISABLED}</TmpText>
            {/* <SuccessSavedAlert
              mobile={isMobile}
              out={!successMessage}
              icon={<Icon>{'check'}</Icon>}
              message={UNITSETT_UPDATES_STARTED_SUCCESSFULLY}
            />
            {currentDevice.updateBlockedState && (
              <React.Fragment>
                <TextAlert>{UNITSETT_UPDATES_UPDATES_BLOCKED}</TextAlert>
                {userRole === 'technician' && (
                  <TextAlert black={'true'}>
                    {UNITSETT_UPDATES_UPDATES_BLOCKED_GO_SETTINGS}
                  </TextAlert>
                )}
                {userRole !== 'technician' && (
                  <TextAlert black={'true'}>
                    {UNITSETT_UPDATES_UPDATES_BLOCKED_CONTACT_TECH}
                  </TextAlert>
                )}
              </React.Fragment>
            )}
            {!currentDevice.updateBlockedState && (
              <React.Fragment>
                {isMobile && (
                  <MobileActionButton
                    mobile={isMobile}
                    icon={<Icon>{'sync'}</Icon>}
                    onClick={getUpdatesList}
                    id={'Check'}
                  >
                    {UNITSETT_UPDATES_CHECK_BUTTON}
                  </MobileActionButton>
                )}
                <List disablePadding>
                  <List.Item>
                    <List.ItemText
                      primary={UNITSETT_UPDATES_CURRENT_VERSION}
                      secondary={bundleVersion}
                    />
                  </List.Item>
                  {Object.keys(mostRecentUpdate).length > 0 && (
                    <React.Fragment>
                      <Divider variant={'inset'} component='li' />
                      <List.Item>
                        <List.ItemText
                          primary={UNITSETT_UPDATES_AVAIL_VERSION}
                          secondary={mostRecentUpdate && mostRecentUpdate.name}
                        />
                      </List.Item>
                      {areThereReleaseNotesToDisplay && (
                        <ReleaseNotesDiv>
                          <Link
                            padding={'0'}
                            onClick={() =>
                              this.setState({ showReleaseNotes: true })
                            }
                          >
                            {UNITSETT_UPDATES_VIEW_R_NOTES}
                          </Link>
                        </ReleaseNotesDiv>
                      )}
                    </React.Fragment>
                  )}
                </List>
                <Divider variant={'inset'} />
                {Object.keys(mostRecentUpdate).length === 0 && (
                  <TextAlert black={'true'}>
                    {UNITSETT_UPDATES_NO_UPDATES}
                  </TextAlert>
                )}
                <SpaceDiv height={'16px'} />
                {Object.keys(mostRecentUpdate).length > 0 && (
                  <Typography variant={'h5'} color={'error'}>
                    {updateInProgress
                      ? UNITSETT_UPDATE_PROGRESS
                      : error !== ''
                        ? error
                        : UNITSETT_UPDATES_ALERT }
                  </Typography>
                )}
                <SpaceDiv height={'40px'} />
                <Buttons mobile={isMobile}>
                  {!isMobile && (
                    <Button
                      id={'PrimaryButton_Check'}
                      buttonType={'secondary-default'}
                      buttonWidth={'220px'}
                      margin={'0px'}
                      onClick={getUpdatesList}
                      disabled={updateInProgress || !userCanUpdateUnit}
                    >
                      <Icon>{'sync'}</Icon> {UNITSETT_UPDATES_CHECK_BUTTON}
                    </Button>
                  )}
                  {Object.keys(mostRecentUpdate).length > 0 && (
                    <Button
                      id={'PrimaryButton_Install'}
                      buttonWidth={'200px'}
                      margin={'0 12px'}
                      onClick={() => setConfirmationUpdate(true)}
                      disabled={updateInProgress || !userCanUpdateUnit}
                    >
                      <Icon>{'get_app'}</Icon> {UNITSETT_UPDATES_INSTALL_BUTTON}
                    </Button>
                  )}
                </Buttons>
              </React.Fragment>
            )} */}
          </PageMainSection>
          {/* {areThereReleaseNotesToDisplay && (
            <Modal
              open={showReleaseNotes}
              size={'medium'}
              onClose={() => this.setState({ showReleaseNotes: false })}
            >
              <div>
                <ModalHeader
                  title={UNITSETT_UPDATES_R_NOTES_TITLE}
                  subtitle={UNITSETT_UPDATES_R_NOTES_SUB_TITLE}
                  closeButton
                  onClose={() => this.setState({ showReleaseNotes: false })}
                />
                <ModalContent
                  padding
                  type={'diagonal'}
                  size={'medium'}
                  mobile={isMobile}
                >
                  <SharedTypography
                    dangerouslySetInnerHTML={{
                      __html: mostRecentUpdate.releaseNotes[0].htmlText
                    }}
                  />
                </ModalContent>
              </div>
            </Modal>
          )}
          <Modal open={showConfirmationUpdate} size={'small'}>
            <div>
              <ModalHeader title={UNITSETT_UPDATES_TITLE} />
              <ModalContent padding size={'small'} mobile={isMobile}>
                <SharedTypography>{UNITSETT_UPDATES_CONFIRM}</SharedTypography>
              </ModalContent>
              <ModalButtons>
                <Button
                  id={'PrimaryButton_Cancel'}
                  buttonType={'primary-cancel'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={() => setConfirmationUpdate(false)}
                  disabled={loading}
                >
                  {SCHEDULE_EVENT_DELETE_MODAL_CANCEL}
                </Button>
                <Button
                  id={'PrimaryButton_Install'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={updateUnit}
                  disabled={loading}
                >
                  {UNITSETT_UPDATES_INSTALL_BUTTON}
                </Button>
              </ModalButtons>
            </div>
          </Modal> */}
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(DeviceUpdates)

const Wrapper = styled.div``

const TmpText = styled.div`
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans';
  margin: 7px 0px;
`

// const Buttons = styled.div`
//   display: flex;
// `

// const TextAlert = styled(props => <SharedTypography {...props} />)`
//   &&&& {
//     padding: 8px;
//     padding-left: 0px !important;
//     font-weight: 600;

//     ${({ black }) =>
//     !black &&
//     `
//       color: #CA3C3D;
//     `}

//     ${({ black }) => black === 'true' && `
//       color: #5B6770;
//     `}
//   }
// `

// const ReleaseNotesDiv = styled.div`
//   display: flex;
//   padding-bottom: 16px;
// `
