// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

import { Modal } from 'xstorage-components'

import EnergySolarMultipleFlow from 'components/EnergySolarMultipleFlow'

type Props = {
  open: boolean,
  mobile: boolean,
  multipleSolarPanels: any,
  onClose: Function
};

class MultipleSolarPVModal extends Component<Props, void> {
  render () {
    const { open, onClose, multipleSolarPanels, mobile } = this.props

    return (
      <Wrapper>
        {open && (
          <ModalWithoutBackground open={open} size={'medium'} onClose={onClose}>
            <div>
              <EnergySolarMultipleFlow
                mobile={mobile}
                multipleSolarPanels={multipleSolarPanels}
                onClose={onClose}
              />
            </div>
          </ModalWithoutBackground>
        )}
      </Wrapper>
    )
  }
}

export default MultipleSolarPVModal

const Wrapper = styled.div``

const ModalWithoutBackground = styled(props => <Modal {...props} />)`
  > div[role="document"] {
    background: transparent;
  }
`
