/* eslint-disable indent */
// @flow
import { kea } from 'kea'

import { put, call, delay } from 'redux-saga/effects'
import { log, generateUrl } from 'utils'
import axios from 'axios'
import PropTypes from 'prop-types'
import { API_BASE_URL } from 'config'
import AppLogic from 'containers/App/logic'
import FormData from 'form-data'
import { checkRolePermission } from 'containers/UserPermission/utils'
import { getApiErrorKey } from 'apiErrors'

const DEVICEUPDATE_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/updates`
const DEVICE_ENDPOINT: string = `${API_BASE_URL}/devices/{id}`

export default kea({
  path: () => ['scenes', 'DeviceUpdates'],

  connect: {
    props: [
      AppLogic,
      [
        'isMobile',
        'translations',
        'currentDeviceId',
        'currentDevice',
        'userRole'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'fetchCurrentDeviceInfo',
        'setCurrentDeviceInfo'
      ]
    ]
  },

  actions: () => ({
    reset: () => true,
    getUpdatesList: () => true,
    setUpdate: update => ({ update }),
    updateUnit: () => true,
    setUpdateInProgress: updateStatus => ({ updateStatus }),
    setConfirmationUpdate: update => update,
    setLoading: loading => loading,
    getDeviceInfo: () => true,
    setError: error => error,
    setSuccessMessage: success => success
  }),

  reducers: ({ actions }) => ({
    mostRecentUpdate: [
      {},
      PropTypes.object,
      {
        [actions.setUpdate]: (state, payload) => payload.update,
        [actions.reset]: () => ({})
      }
    ],

    updateInProgress: [
      false,
      PropTypes.bool,
      {
        [actions.setUpdateInProgress]: (state, payload) => payload.updateStatus,
        [actions.reset]: () => false
      }
    ],

    showConfirmationUpdate: [
      false,
      PropTypes.bool,
      {
        [actions.setConfirmationUpdate]: (state, payload) => payload,
        [actions.setUpdateInProgress]: () => false,
        [actions.updateUnit]: () => false,
        [actions.reset]: () => false
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setLoading]: (state, payload) => payload,
        [actions.getUpdatesList]: () => true,
        [actions.getDeviceInfo]: () => true,
        [actions.updateUnit]: () => true,
        [actions.setUpdate]: () => false,
        [actions.setCurrentDeviceInfo]: () => false,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ]
  }),
  selectors: ({ selectors }) => ({
    /** put user permission on a prop */
    userCanUpdateUnit: [
      () => [selectors.userRole],
      (userRole: string) => checkRolePermission('UPDATE_UNIT', userRole),
      PropTypes.bool
    ]
  }),

  start: function * () {
    const {
      reset,
      navigate,
      getUpdatesList,
      setUpdateInProgress
    } = this.actions

    yield put(reset())

    log('[XS-DeviceUpdates] Start Scene', 'yellow')

    const userCanUpdateUnit = yield this.get('userCanUpdateUnit')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    const currentDevice = yield this.get('currentDevice')

    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    if (!userCanUpdateUnit || !currentDevice.connected) {
      yield put(navigate('/401'))
    }

    if (currentDevice.updateStatus === 'UPDATE_INITIATED' ||
      currentDevice.updateStatus === 'UPDATE_WILL_START') {
      yield put(setUpdateInProgress(true))
    } else {
      yield put(setUpdateInProgress(false))
    }

    if (!currentDevice.updateBlockedState) {
      yield put(getUpdatesList())
    }
  },

  stop: function * () {
    log('[XS-DeviceUpdates] Stop Scene')

    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getUpdatesList]: workers.getUpdatesList,
    [actions.updateUnit]: workers.updateUnit,
    [actions.getDeviceInfo]: workers.getDeviceInfo
  }),

  workers: {
    * getUpdatesList () {
      const currentDeviceId = yield this.get('currentDeviceId')
      const { setUpdate, setLoading } = this.actions

      try {
        const url = generateUrl(DEVICEUPDATE_ENDPOINT, { id: currentDeviceId })
        const response = yield call(axios.get, url)

        const { successful } = response.data || {}

        if (successful) {
          const { result } = response.data
          const { results } = result

          const recentUpdate = results[0]
          yield put(setUpdate(recentUpdate))
        }
        yield put(setLoading(false))
      } catch (er) {
        console.log(er)
      }
    },

    * updateUnit () {
      const mostRecentUpdate = yield this.get('mostRecentUpdate')
      const currentDeviceId = yield this.get('currentDeviceId')
      const translations = yield this.get('translations')

      const {
        setUpdateInProgress,
        fetchCurrentDeviceInfo,
        getDeviceInfo,
        setError
      } = this.actions

      try {
        const data = new FormData()
        data.append('updateId', mostRecentUpdate.id)

        const url = generateUrl(DEVICEUPDATE_ENDPOINT, { id: currentDeviceId })

        const config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }

        const response = yield call(axios.post, url, data, config)

        const { successful } = response.data || {}

        if (successful) {
          yield delay(5000)
          yield put(getDeviceInfo())
        }
      } catch (catchError) {
        yield put(setUpdateInProgress(false))

        const { response } = catchError || {}
        const { result } = response || {}

        if (response && result) {
          const { message } = result.data

          const errorKey = getApiErrorKey(message)

          yield put(setError(translations[errorKey] || ''))

          if (errorKey === 'API_ERROR_UPDATES_BLOCKED_TECH') {
            yield put(fetchCurrentDeviceInfo())
          }
        }
      }
    },

    * getDeviceInfo () {
      const {
        getDeviceInfo,
        setUpdateInProgress,
        setError,
        setCurrentDeviceInfo,
        setConfirmationUpdate,
        setSuccessMessage
      } = this.actions
      const { history } = this.props
      const currentDevice = yield this.get('currentDevice')
      const translations = yield this.get('translations')

      try {
        const url = generateUrl(DEVICE_ENDPOINT, { id: currentDevice.id })
        const response = yield call(axios.get, url)

        const { data } = response || {}
        const { successful, result } = data || {}

        if (successful && result) {
          const { updateStatus } = result || {}

          /**
           * Check if the last update state from the currentDevice is the same
           * as the one received in the last request
           * Delay 5 seconds and repeat the request to update the state
            if (currentDevice.updateStatus === updateStatus) {
              yield delay(5000)
              yield put(getDeviceInfo())
            }
           */

          switch (updateStatus) {
            case 'UPDATE_WILL_START':
              yield put(setUpdateInProgress(true))
              yield put(setCurrentDeviceInfo(result))

              yield put(setSuccessMessage(true))
              yield delay(5000)
              yield put(setSuccessMessage(false))
              yield delay(2000)

              yield call(history.push, `/${currentDevice.id}/dashboard/`)

              break
            case 'UPDATE_COMPLETE':
            case 'UPDATE_INITIATED':
              yield delay(5000)
              yield put(setCurrentDeviceInfo(result))
              yield put(getDeviceInfo())
              break
            case 'ERR_UPDATE_INSUFFICIENT_DISK_SPACE':
            case 'ERR_UPDATE_LOW_BATTERY':
            case 'ERR_UPDATE_DOWNLOAD_FAILED':
            case 'ERR_UPDATE_INVALID_HASH':
            case 'ERR_UPDATE_COMMCARD_FAILURE':
              yield put(setConfirmationUpdate(false))
              yield put(setError(translations[updateStatus] || ''))
              break
            default:
              yield call(history.push, `/${currentDevice.id}/dashboard/`)
              break
          }
        }
      } catch (catchError) {
        const { response } = catchError || {}
        const { data } = response || {}

        if (response && data) {
        }
      }
    }
  }
})
