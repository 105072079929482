/**
 * This file conains the MAPPING for all API errors.
 * Convert the api error on frontend key
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow

export const API_ERRORS = {
  'The event(s) you are trying to save collides with another stored value':
    'SCHEDULE_DUPLICATE_EVENT',
  'The email doesn\'t match to the one used to request the invite':
    'INVALID_INVITE_EMAIL',
  'Object Not Found': 'OBJECT_NOT_FOUND',
  'Invalid Dealer User Id': 'INVALID_DEALER_ID',
  'The device has already been onboarded': 'DEVICE_ALREADY_ONBOARDED',
  'Invalid token or token expired': 'TOKEN_INVALID_OR_EXPIRED',
  'This user already has a role over this device': 'ROLES_EXISTS',
  'You can\'t invite yourself to control a device': 'USER_INVITING_USER',
  'An invite to the same user already exists': 'INVITE_ALREADY_EXISTS',
  'You already have a role for this device': 'USER_CLAIMING_ROLE',
  'Invalid Device Serial Number': 'INVALID_DEVICE_SN',
  'You are not authorized to view the resource': 'NOT_AUTHORIZED_RESOURCE',
  'This email address isn\'t associated with this onboarding request':
    'INVALID_ONBOARDING_EMAIL',
  'You have reached the maxium number of roles permited for a device':
    'REACHED_MAX_DEVICE_INVITES',
  'There is already a pending request for this device':
    'ERR_REQUEST_PENDING_DEVICE',
  'Start date greater than the last 12 months of stored data':
    'ERR_EXPORT_MAX_ONEYEAR',
  'You can only obtain entries in a maximum interval of 3 months':
    'ERR_EXPORT_MAX_3MONTHS',
  'An error ocurred during csv file generation': 'ERR_EXPORT_FILE_GENERATION',
  'An error ocurred during csv file upload': 'ERR_EXPORT_FILE_UPLOAD',
  'Invalid device': 'ERROR_EXPORT_INVALID_DEVICE',
  'This device is missing the timezone setting':
    'ERROR_EXPORT_INVALID_TIMEZONE',
  'Only a device owner can change it\'s ownership': 'OWNER_CHANGE_OWNERSHIP',
  'This invite has already been accepted': 'OWNERSHIP_ACCEPTED',
  'This invite has already been rejected': 'OWNERSHIP_REJECTED',
  'Device update already in progress': 'UPDATE_IN_PROGRESS',
  'Device update required': 'UPDATE_REQUIRED',
  'There\'s already another record with the same (userId and deviceId values)':
    'CHANGE_OWNERSHIP_DUPLICATED_RECORD',
  'Invalid \'houseConsumptionThreshold\' property value':
    'SETTINGS_INVALID_HOUSE_THRESHOLD',
  'Invalid \'enabled\' property value':
    'SETTINGS_INVALID_ENABLED_ENERGY_SAVING_MODE',
  'Access is denied': 'PERMISSIONS',
  // eslint-disable-next-line max-len
  'This device serial number is already associated with another onboarding request':
    'SERIAL_NUMBER_ALREADY_EXISTS',
  'This device has already been onboarded': 'DEVICE_ONBOARDED',
  'Invalid Parameters for Peak Shaving Command': 'SETTINGS_PEAKSHAVING',
  // eslint-disable-next-line max-len
  'Device is disconnected , this operation only can be performed on connected devices':
    'UPDATES_CONNECTED_DEVICES',
  'Device update feature was blocked by a technician': 'UPDATES_BLOCKED_TECH',
  // eslint-disable-next-line max-len
  'An request to the same device by the same user already exists': 'REQUEST_ALREADY_EXISTS',
  'The access request is not active': 'EXTAPP_REQACCESS_NOT_ACTIVE',
  'ERR_CODE_FEATURE_NOT_SUPPORTED': 'ERR_CODE_FEATURE_NOT_SUPPORTED',
  'ERR_CODE_DEVICE_NOT_CONNECTED': 'ERR_CODE_DEVICE_NOT_CONNECTED',
  'ERR_CODE_UPDATE_IN_PROGRESS': 'ERR_CODE_UPDATE_IN_PROGRESS',
  'Device Not Active': 'DEVICE_NOT_ACTIVE'
}

/**
 *  Helper function that returns the KEY for each API error
 *  with or without a given context
 *
 * @param {*} message
 * @param {*} prefix (optional)
 */
export const getApiErrorKey = (
  message: string,
  prefix: string = 'API_ERROR_'
) => {
  return API_ERRORS[message]
    ? `${prefix}${API_ERRORS[message]}`
    : 'UNEXPECTED_ERROR'
}
