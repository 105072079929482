// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import PageNavMenu from 'containers/PageNavMenu'
import {
  IcCloudSync,
  IcCalendarSync,
  IcChartAreaspline,
  IcDocument,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  TextList,
  Point,
  Button,
  Typography,
  IcConsole
} from 'xstorage-components'

type Props = {
  page: string,
  translations: {
    MAINTENANCE_TECH_PARAMS: string,
    UNITSETT_SUPPORT_METRICS_SYNC: string,
    UNITSETT_SUPPORT_DATETIMESYNC: string,
    UNITSETT_SUPPORT_MONITORING: string,
    UNITSETT_SUPPORT_UNITLOG: string,
    UNITSETT_SUPPORT_REBOOTUNIT_COMMCARD: string,
    UNITSETT_SUPPORT_REBOOTUNIT_CONFIRMATION: string,
    UNITSETT_SUPPORT_REBOOTUNIT_CANCEL: string,
    UNITSETT_SUPPORT_REBOOTUNIT_SUBMIT: string,
    UNITSETT_SUPPORT_REBOOTUNIT_NOTE: string,
    UNITSETT_SUPPORT_SSH_STATUS: string
  },
  currentDeviceId: string,
  isMobile: boolean,
  rebootModal: boolean,
  featureSupport: {
    rebootSupported: boolean,
    rebootUpdateRequired: boolean,
    maintenanceSupported: boolean,
    maintenanceUpdateRequired: boolean,
    sshSupported: boolean,
    sshUpdateRequired: boolean,
    httpsSupported: boolean,
    httpsUpdateRequired: boolean
  }
}

class MaintenanceMenu extends Component<Props> {
  actions: {
    navigate: Function,
    setRebootModal: Function,
    rebootUnit: Function
  }

  render () {
    const {
      page,
      translations,
      currentDeviceId,
      isMobile,
      rebootModal,
      featureSupport
    } = this.props

    const { navigate, setRebootModal, rebootUnit } = this.actions

    const {
      MAINTENANCE_TECH_PARAMS,
      UNITSETT_SUPPORT_METRICS_SYNC,
      UNITSETT_SUPPORT_DATETIMESYNC,
      UNITSETT_SUPPORT_MONITORING,
      UNITSETT_SUPPORT_UNITLOG,
      UNITSETT_SUPPORT_REBOOTUNIT_COMMCARD,
      UNITSETT_SUPPORT_REBOOTUNIT_CONFIRMATION,
      UNITSETT_SUPPORT_REBOOTUNIT_CANCEL,
      UNITSETT_SUPPORT_REBOOTUNIT_SUBMIT,
      UNITSETT_SUPPORT_REBOOTUNIT_NOTE,
      UNITSETT_SUPPORT_SSH_STATUS,
      UNITSETT_SUPPORT_HTTPS_STATUS
    } = translations

    const {
      sshSupported,
      sshUpdateRequired,
      rebootSupported,
      maintenanceSupported,
      maintenanceUpdateRequired,
      httpsSupported,
      httpsUpdateRequired
    } = featureSupport || {}

    const menuItems = [
      {
        id: 'TechParams',
        key: 'tech-params',
        icon: (
          <StyledIcon
            transformproperty={'rotate(180deg)'}
          >{'error_outline'}</StyledIcon>
        ),
        title: MAINTENANCE_TECH_PARAMS,
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/maintenance/techparams`)
      },
      {
        id: 'MetricsSync',
        key: 'metrics-sync',
        icon: <IcCloudSync fillColor={isMobile ? '#007BC1' : '#FFFFFF'} />,
        title: UNITSETT_SUPPORT_METRICS_SYNC,
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/maintenance/metrics-sync`),
        hidden: !maintenanceSupported && !maintenanceUpdateRequired
      },
      {
        id: 'DatetimeSync',
        key: 'datetime-sync',
        icon: <IcCalendarSync fillColor={isMobile ? '#007BC1' : '#FFFFFF'} />,
        title: UNITSETT_SUPPORT_DATETIMESYNC,
        link: true,
        onClick: () =>
          navigate(`/${currentDeviceId}/maintenance/datetime-sync`),
        hidden: !maintenanceSupported && !maintenanceUpdateRequired
      },
      {
        id: 'Monitoring',
        key: 'monitoring',
        icon: (
          <IcChartAreaspline fillColor={isMobile ? '#007BC1' : '#FFFFFF'} />
        ),
        title: UNITSETT_SUPPORT_MONITORING,
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/maintenance/monitoring`),
        hidden: !maintenanceSupported && !maintenanceUpdateRequired
      },
      {
        id: 'UnitLog',
        key: 'unit-log',
        icon: <IcDocument fillColor={isMobile ? '#007BC1' : '#FFFFFF'} />,
        title: UNITSETT_SUPPORT_UNITLOG,
        link: true,
        onClick: () => navigate(`/${currentDeviceId}/maintenance/unit-log`),
        hidden: !maintenanceSupported && !maintenanceUpdateRequired
      },
      {
        id: 'SSHConnection',
        key: 'ssh-connection',
        icon: <IcConsole
          fillColor={isMobile ? '#007BC1!important' : '#FFFFFF'}
        />,
        title: UNITSETT_SUPPORT_SSH_STATUS,
        link: true,
        onClick: () =>
          navigate(`/${currentDeviceId}/maintenance/ssh-connection`),
        hidden: !sshSupported && !sshUpdateRequired
      },
      {
        id: 'HTTPSTunnel',
        key: 'https-tunnel',
        icon: <Icon>{'https'}</Icon>,
        title: UNITSETT_SUPPORT_HTTPS_STATUS,
        link: true,
        onClick: () =>
          navigate(`/${currentDeviceId}/maintenance/https-tunnel/`),
        hidden: !httpsSupported && !httpsUpdateRequired
      },
      {
        id: 'RebootUnit',
        key: 'reboot-unit',
        icon: <Icon>{'power_settings_new'}</Icon>,
        title: UNITSETT_SUPPORT_REBOOTUNIT_COMMCARD,
        color: isMobile ? '#CA3C3D' : '#EFC5C5',
        onClick: () => setRebootModal(true),
        hidden: !rebootSupported
      }
    ]

    const points = Object.keys(translations).filter(key =>
      /UNITSETT_SUPPORT_REBOOTUNIT_INFOPOINT(\d+)/.test(key)
    )

    return (
      <div>
        <PageNavMenu items={menuItems} page={page} />
        <Modal
          open={rebootModal}
          size={'large'}
          onClose={() => setRebootModal(false)}
        >
          <div>
            <ModalHeader red title={UNITSETT_SUPPORT_REBOOTUNIT_COMMCARD} />
            <ModalContent padding size={'large'} mobile={isMobile}>
              <PointsTitle>{UNITSETT_SUPPORT_REBOOTUNIT_NOTE}</PointsTitle>
              <TextList>
                {points.map(point => (
                  <Point key={point}>{translations[point]}</Point>
                ))}
              </TextList>
              <Typography variant={'body1'}>
                {UNITSETT_SUPPORT_REBOOTUNIT_CONFIRMATION}
              </Typography>
            </ModalContent>
            <ModalButtons>
              <Button
                margin={'0 12px'}
                buttonType={'primary-cancel'}
                buttonWidth={'156px'}
                onClick={() => setRebootModal(false)}
              >
                {UNITSETT_SUPPORT_REBOOTUNIT_CANCEL}
              </Button>
              <Button
                margin={'0 12px'}
                buttonWidth={'156px'}
                buttonType={'primary-danger'}
                onClick={rebootUnit}
              >
                {UNITSETT_SUPPORT_REBOOTUNIT_SUBMIT}
              </Button>
            </ModalButtons>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Logic(MaintenanceMenu)

const StyledIcon = styled(Icon)`
  transform: ${({ transformproperty }) => `${transformproperty}`};
`

const PointsTitle = styled.p`
  color: #424e54;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin: 0;
`
