/**
 * Formgeneralerror Container
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { Component, Fragment } from 'react'
import Logic from './logic'

import { Form, FormErrorMessage } from 'xstorage-components'

type Props = {
  dirty: boolean,
  error: string,
  full: boolean,
  translations: any
}

class FormGeneralError extends Component<Props, void> {
  render () {
    const {
      dirty,
      error,
      full,
      translations
    } = this.props

    const {
      FORM_SAVE_ERRORS_TITLE
    } = translations

    const showError = dirty && error && error.length > 0

    return (
      <Fragment>
        {showError &&
          <Form.Row full={full}>
            <FormErrorMessage><b>{FORM_SAVE_ERRORS_TITLE}</b></FormErrorMessage>
            <FormErrorMessage>{error}</FormErrorMessage>
          </Form.Row>
        }
      </Fragment>
    )
  }
}

export default Logic(FormGeneralError)
