/**
 * UnitAccessPage Container Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'containers', 'TechnicianBackBar'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'currentDevice'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'setCurrentDeviceInfo'
      ]
    ]
  },

  stop: function * () {
    const { setCurrentDeviceInfo } = this.actions
    yield window.localStorage.removeItem('currentDeviceId')
    yield put(setCurrentDeviceInfo({}))
  }
})
