// @flow
import { kea } from 'kea'

import { put, call } from 'redux-saga/effects'
import { log } from 'utils'
import axios from 'axios'
import { size } from 'lodash'
import { API_BASE_URL } from 'config'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'

const UNITS_ENDPOINT = `${API_BASE_URL}/devices/onboarding?status=false`

export default kea({
  path: () => ['scenes', 'TechnicianUntakenUnits'],

  connect: {
    props: [AppLogic, ['isMobile', 'translations', 'user']],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    reset: () => true,
    closeUnitPanel: () => true,
    showUnitInfo: unit => ({ unit }),
    fetchUntakenUnits: () => true,
    setUntakenUnits: units => ({ units }),
    addUnit: () => true,
    error: () => true,
    setLoadingButton: () => true,
    setShowLoadMoreInfo: () => true,
    setTotalUntakenUnits: total => ({ total })
  }),

  reducers: ({ actions }) => ({
    untakenUnits: [
      [],
      PropTypes.array,
      {
        [actions.setUntakenUnits]: (state, payload) => [
          ...state,
          ...payload.units
        ],
        [actions.closeUnitPanel]: () => [],
        [actions.reset]: () => []
      }
    ],

    totalUntakenUnits: [
      0,
      PropTypes.number,
      {
        [actions.setTotalUntakenUnits]: (state, payload) => payload.total,
        [actions.reset]: () => 0
      }
    ],

    unitPanelVisible: [
      false,
      PropTypes.bool,
      {
        [actions.closeUnitPanel]: () => false,
        [actions.showUnitInfo]: () => true,
        [actions.addUnit]: () => true,
        [actions.reset]: () => false
      }
    ],

    currentUnit: [
      null,
      PropTypes.any,
      {
        [actions.showUnitInfo]: (state, payload) => payload.unit,
        [actions.addUnit]: () => null,
        [actions.closeUnitPanel]: () => null,
        [actions.reset]: () => null
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.fetchUntakenUnits]: () => true,
        [actions.setUntakenUnits]: () => false,
        [actions.closeUnitPanel]: () => true,
        [actions.error]: () => false
      }
    ],

    loadingButton: [
      false,
      PropTypes.bool,
      {
        [actions.setLoadingButton]: state => !state,
        [actions.reset]: () => false
      }
    ],

    showLoadMoreInfo: [
      false,
      PropTypes.bool,
      {
        [actions.setShowLoadMoreInfo]: state => true,
        [actions.closeUnitPanel]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    untakenUnitsLength: [
      () => [selectors.untakenUnits],
      untakenUnits => size(untakenUnits),
      PropTypes.number
    ]
  }),

  start: function * () {
    const { fetchUntakenUnits } = this.actions
    log('[XS-TechnicianUntakenUnits] Start Scene', 'yellow')

    yield put(fetchUntakenUnits())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-TechnicianUntakenUnits] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchUntakenUnits]: workers.fetchUntakenUnits,
    [actions.closeUnitPanel]: workers.fetchUntakenUnits
  }),

  workers: {
    * fetchUntakenUnits () {
      const {
        setUntakenUnits,
        error,
        setShowLoadMoreInfo,
        setTotalUntakenUnits,
        setLoadingButton
      } = this.actions
      const untakenUnitsLength = yield this.get('untakenUnitsLength')
      const user = yield this.get('user')
      let url = UNITS_ENDPOINT + `&size=9&offset=${untakenUnitsLength}`

      yield put(setLoadingButton())

      try {
        if (user.isAdmin) {
          url = url + `&userId=${user.id}`
        }

        const response = yield call(axios.get, url)
        let { result, successful } = response.data

        if (successful) {
          yield put(setUntakenUnits(result.data))
          yield put(setTotalUntakenUnits(result.total))

          if (result.total > result.data.length) {
            yield put(setShowLoadMoreInfo())
          }

          yield put(setLoadingButton())
        }
      } catch (er) {
        console.log(er)
        yield put(error())
      }
    }
  }
})
