// @flow
import { type DeviceMetrics } from 'types/api'
import { type ChartFilters, type ChartVariables } from './types'
import { type EnergyValue } from 'types/global'
import moment from 'moment-timezone'
import {
  mapValues,
  mapKeys,
  reverse,
  max,
  cloneDeep,
  round,
  uniqWith,
  isEqual
} from 'lodash'
import { API_BASE_URL } from 'config'
import {
  convertTimeToLocalNaive,
  convertMillisToSec,
  convertSecToMillis,
  getEnergyValue
} from 'utils'

export const DEFAULT_FILTERS: ChartFilters = {
  timeOption: 'today',
  selectedDayPicker: moment()
}

export const DEFAULT_VARIABLES: ChartVariables = {
  totalHouseConsumption: true,
  criticalLoads: false,
  nonCriticalLoads: false,
  batteryStateOfCharge: true,
  photovoltaicProduction: false,
  selfConsumption: false,
  selfSufficient: false
}

export const RESETED_VARIABLES: ChartVariables = {
  totalHouseConsumption: false,
  photovoltaicProduction: false,
  batteryStateOfCharge: false,
  criticalLoads: false,
  nonCriticalLoads: false,
  selfConsumption: false,
  selfSufficient: false
}

export const DEFAULT_METRICS = {
  sunData: [],
  totalHouseConsumption: [],
  photovoltaicProduction: [],
  batteryStateOfCharge: [],
  criticalLoads: [],
  nonCriticalLoads: [],
  selfConsumption: [],
  selfSufficient: [],
  from: null,
  to: null
}

export const DEFAULT_METRIC = {
  totalHouseConsumption: null,
  photovoltaicProduction: null,
  batteryStateOfCharge: null,
  criticalLoads: null,
  nonCriticalLoads: null,
  selfConsumption: null,
  selfSufficient: null,
  time: null,
  month: null,
  year: null
}

// Map for filter API endpoints
export const DEVICE_METRICS_ENDPOINTS: {} = {
  today: `${API_BASE_URL}/devices/{id}/metrics`,
  'last-7': `${API_BASE_URL}/devices/{id}/metrics/daily`,
  'last-30': `${API_BASE_URL}/devices/{id}/metrics/weekly`,
  'last-year': `${API_BASE_URL}/devices/{id}/metrics/montly`
}

// format date for filter
export const FILTER_DATE_FORMAT: string = 'MMMM, Do YYYY'

// Options for time filter on charts data
export const TIME_FILTER_OPTIONS: Array<any> = [
  { val: 'today', label: 'CHART_FILTER_TODAY' },
  { val: 'last-7', label: 'CHART_FILTER_LAST7D' },
  { val: 'last-30', label: 'CHART_FILTER_LAST30D' },
  { val: 'last-year', label: 'CHART_FILTER_LASTYEAR' }
]

export const TIME_MOBILE_FILTER_OPTIONS: Array<any> = [
  { val: 'today', label: 'CHART_MOBILE_FILTER_TODAY' },
  { val: 'last-7', label: 'CHART_MOBILE_FILTER_LAST7D' },
  { val: 'last-30', label: 'CHART_MOBILE_FILTER_LAST30D' },
  { val: 'last-year', label: 'CHART_MOBILE_FILTER_LASTYEAR' }
]

// Maping of each variable for correspondent axis
export const MAP_TICKS_VARS: {} = {
  A: [
    'nonCriticalLoads',
    'criticalLoads',
    'photovoltaicProduction',
    'totalHouseConsumption'
  ],
  B: ['batteryStateOfCharge', 'selfConsumption', 'selfSufficient']
}

/**
 * Function that calculate times for the sunData
 * based on sunrise and sunset
 *
 * @param {*} sunriseTime
 * @param {*} sunsetTime
 */
export const calcSunData = (sunriseTime, sunsetTime) => {
  // calculate the peak of the sun data
  const peakTime = sunriseTime + (sunsetTime - sunriseTime) / 2

  return [
    {
      x: convertTimeToLocalNaive(moment.unix(sunriseTime)),
      y: 0
    },
    {
      x: convertTimeToLocalNaive(moment.unix(peakTime)),
      y: 95
    },
    {
      x: convertTimeToLocalNaive(moment.unix(sunsetTime)),
      y: 0
    }
  ]
}

/**
 * helper function to convert the time of metric record
 *
 * @param {*} m
 * @param {*} monthlyData
 */
export const convertChartMetricTime = (
  m: DeviceMetrics,
  timeOption: string,
  filters: any
) => {
  const time: number = convertMillisToSec(m.time)
  let convertedTime

  if (timeOption === 'last-year') {
    convertedTime = moment.tz(
      `01-${parseInt(m.month) + 1}-${m.year}`,
      'DD-MM-YYYY',
      moment().tz()
    )
  } else if (timeOption === 'last-7') {
    convertedTime = moment.tz(
      `${moment.unix(time).format('YYYY-MM-DD')} 00:00`,
      'YYYY-MM-DD HH:mm',
      moment().tz()
    )
  } else if (timeOption === 'last-30') {
    convertedTime = m.week
  } else {
    convertedTime = convertTimeToLocalNaive(time)
  }

  return convertedTime
}

export const generateFakeData = (timeOption: string): Array<DeviceMetrics> => {
  let fakeData: Array<DeviceMetrics> = []

  // specs for each chart to generate fake data
  const optionSpecs = {
    today: {
      interval: 60 * 5,
      start: moment()
        .startOf('day')
        .unix(),
      end: moment().unix()
    },
    'last-7': {
      interval: 60 * 60 * 24,
      start: moment()
        .startOf('day')
        .subtract(6, 'day')
        .unix(),
      end: moment()
        .endOf('day')
        .unix()
    },
    'last-30': {
      interval: 60 * 60 * 24 * 7,
      start: moment()
        .startOf('day')
        .subtract(1, 'month')
        .unix(),
      end: moment()
        .endOf('day')
        .unix()
    },
    'last-year': {
      interval: 60 * 60 * 24 * 31,
      start: moment()
        .startOf('day')
        .subtract(1, 'year')
        .unix(),
      end: moment()
        .startOf('day')
        .unix()
    }
  }
  const option = optionSpecs[timeOption]

  // generate radom value based on previous value
  const generateValue = (field: string, last: number) => {
    let max: number = last + Math.random() * (0.5 - 0.1)
    let min: number = last

    if (field === 'batteryStateOfCharge') {
      max = last < 90 ? last + 0.5 : 85
    }

    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  // generates fake metrics for each chart
  const generateNewMetric = (index: number, fake: any) => {
    let metric: any = {}

    // simulation of a fake interval on today chart
    const inFakeInterval =
      index > option.start + 60 * 5 * 20 && index < option.start + 60 * 5 * 50
    if (inFakeInterval && timeOption === 'today') {
      return metric
    }

    // fake metric map values
    mapValues(DEFAULT_METRIC, (v, k) => {
      let last: number =
        fake[fake.length - 1] && fake[fake.length - 1][k]
          ? fake[fake.length - 1][k]
          : 30
      let value
      if (k === 'time') {
        value = convertSecToMillis(moment.utc(index, 'X').unix())
      } else if (k === 'month') {
        value = parseInt(moment.utc(index, 'X').format('MM')) - 1
      } else if (k === 'year') {
        value = moment.utc(index, 'X').format('YYYY')
      } else {
        value = generateValue(k, last)
      }
      metric[k] = value
    })

    return metric
  }

  for (
    let index = option.start;
    index < option.end;
    index = index + option.interval
  ) {
    const metric = generateNewMetric(index, fakeData)
    fakeData = metric.time ? [...fakeData, metric] : fakeData
  }

  return fakeData
}

/**
 * convert the Status List returned by the API
 * to the data needed on charts
 *
 * @param {*} metrics
 * @param {*} timeOption
 */
export const convertChartMetrics = async (
  currentDevice = {},
  fakeApi = true,
  metrics = [],
  filters = {}
) => {
  // Check if fake data option is setted
  const timeOption = filters.timeOption
  if (fakeApi) {
    metrics = generateFakeData(timeOption)
    metrics = reverse(metrics)
  }
  let data = cloneDeep(DEFAULT_METRICS)

  metrics = uniqWith(metrics, isEqual)
  // convert data from API to put on Charts
  metrics.forEach((metric, index) => {
    mapValues(metric, (v, k) => {
      if (!data[k] || index === 0) {
        data[k] = []
      }

      // only on today chart
      // check if exists a interval greatest than 30m
      // and if exists put a metric as null for the chart
      // not draw a continuous line
      if (timeOption === 'today') {
        const lastMetric = metrics[index - 1]
        const limitInterval = 30 * 60 * 1000 // 30m

        if (
          lastMetric &&
          Math.abs(lastMetric.time - metric.time) > limitInterval
        ) {
          lastMetric.time = lastMetric.time + 5 * 1000
          const newMetric = {
            x: convertChartMetricTime(lastMetric, timeOption, filters),
            y: null
          }
          data[k] = [...data[k], newMetric]
        }
      }

      const newMetric = {
        x: convertChartMetricTime(metric, timeOption, filters),
        y: round(v, 2)
      }
      data[k] = [...data[k], newMetric]
    })
  })

  return data
}

/**
 * function to get all parameters nedeed to
 * make the request to the API
 *
 * @param {*} timeOption
 */
export const getMetricsAddictParams = (filters: ChartFilters) => {
  const { timeOption, selectedDayPicker } = filters

  const timeParamsMap = {
    today: {
      from: moment(selectedDayPicker)
        .startOf('day')
        .unix(),
      to: moment(selectedDayPicker)
        .endOf('day')
        .unix()
    },
    'last-7': {
      from: moment(selectedDayPicker)
        .startOf('week')
        .unix(),
      to: moment(selectedDayPicker)
        .endOf('week')
        .unix()
    },
    'last-30': {
      from: moment(selectedDayPicker)
        .startOf('day')
        .startOf('month')
        .unix(),
      to: moment(selectedDayPicker)
        .endOf('day')
        .endOf('month')
        .unix()
    },
    'last-year': {
      from: moment(selectedDayPicker)
        .startOf('day')
        .startOf('year')
        .unix(),
      to: moment(selectedDayPicker)
        .endOf('day')
        .endOf('year')
        .unix()
    }
  }
  const params = timeParamsMap[timeOption]

  // For Debuging
  // console.log(moment.unix(params.from).format('YYYY-MM-DDTHH:mm:ss'))
  // console.log(moment.unix(params.to).format('YYYY-MM-DDTHH:mm:ss'))

  return params
}

/**
 * change chart variable change event
 *
 * @param {*} state
 * @param {*} payload
 */
export const changeChartVariable = (state: {}, payload: any) => {
  let stateChanged = { ...state, ...payload.variable }
  let activeVars: number = 0
  mapValues(stateChanged, v => v && activeVars++)

  // only return the state changed if active vars is > 0
  return activeVars ? stateChanged : state
}

/**
 * Check active variables on filter change
 * If timeOption equals today selfConsumption can't be active, so
 * put it as false and then check if we have any var active, if
 * not put default vars
 *
 * @param {*} state
 * @param {*} payload
 */
export const checkVarsOnFiltChange = (state: any, payload: any) => {
  const { filter } = payload

  if (filter.timeOption === 'today') {
    state.selfConsumption = false
  }

  let activeVars = 0
  mapValues(state, v => v && activeVars++)

  return activeVars ? state : DEFAULT_VARIABLES
}

/**
 * Function to check wich chart ticks is to hide
 * that depends the active variabled and on wich
 * tick each one is
 *
 * @param {*} variables
 */
export const getChartTicksToHide = (variables: {}) => {
  let ticksToHide: string = ''

  mapKeys(MAP_TICKS_VARS, (value, key) => {
    let validVariables: number = 0
    value.forEach(variable => {
      validVariables = !variables[variable]
        ? validVariables + 1
        : validVariables
    })
    ticksToHide =
      validVariables === value.length ? `${ticksToHide}${key}` : ticksToHide

    return value
  })

  return ticksToHide
}

/**
 * Function to check when a filter is changed
 *
 * @param {*} state
 * @param {*} payload
 */
export const changeChartFilter = (state: any, payload: any) => {
  const stateClone = { ...state }

  // when change the time Option always put the selected day
  // with default value (current day)
  if (payload.filter.timeOption) {
    stateClone.selectedDayPicker = moment()
  }

  return { ...stateClone, ...payload.filter }
}

/**
 * Helper function to generates a custom tooltip for all charts
 *
 * @param {*} tooltipModel
 */
export const generateCustomTooltip = function (tooltipModel: any) {
  // Tooltip Element
  var tooltipEl: any = document.getElementById('chartjs-tooltip')

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    tooltipEl.style.display = 'none'
    return
  }

  if (!tooltipModel.body.length) {
    return
  }

  // Set caret Position
  tooltipEl.classList.remove('left', 'right', 'center')
  tooltipEl.classList.add(tooltipModel.xAlign)

  function getBody (bodyItem) {
    return bodyItem.lines
  }

  // Set Text
  var titleLines = tooltipModel.title || []
  var bodyLines = tooltipModel.body.map(getBody)

  var innerHtml = '<thead>'
  titleLines.forEach(function (title) {
    innerHtml += '<tr><th>' + title + '</th></tr>'
  })
  innerHtml += '</thead><tbody>'

  bodyLines.forEach(function (body, i) {
    var colors = tooltipModel.labelColors[i]
    var span = `<span style="background: ${colors.borderColor};"></span>`
    innerHtml += `<tr><td>${span}${body}</td></tr>`
  })
  innerHtml += '</tbody>'

  var tableRoot = tooltipEl.querySelector('table')
  tableRoot.innerHTML = innerHtml

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.display = 'block'
  tooltipEl.style.position = 'absolute'
  if (tooltipModel.xAlign === 'left' || tooltipModel.xAlign === 'center') {
    tooltipEl.style.left = tooltipModel.caretX + 10 + 'px'
  } else {
    tooltipEl.style.left = tooltipModel.caretX - 155 + 'px'
  }
  tooltipEl.style.top = tooltipModel.caretY + 'px'
}

/**
 * function to put units on tooltips
 *
 * @param {*} tooltipItems
 * @param {*} data
 */
export const tooltipLabelCallback = (
  tooltipItems: any,
  data: any,
  unit: string = 'W'
) => {
  const energyDataSets = [
    'totalHouseConsumption',
    'photovoltaicProduction',
    'criticalLoads',
    'nonCriticalLoads'
  ]
  const percentageDataSets = [
    'batteryStateOfCharge',
    'selfConsumption',
    'selfSufficient'
  ]
  const dataSet = data.datasets[tooltipItems.datasetIndex].key
  if (percentageDataSets.indexOf(dataSet) !== -1) {
    return `${tooltipItems.yLabel}%`
  } else if (energyDataSets.indexOf(dataSet) !== -1) {
    const energy: EnergyValue | null = getEnergyValue(tooltipItems.yLabel, unit)
    return energy && energy.label
  }

  return tooltipItems.yLabel
}

/**
 * Get value label of Y axis with correspondent unit
 *
 * @param {*} value
 * @param {*} values
 */
export const getChartYAxisValue = (
  value: number,
  values: Array<number>,
  unit: string = 'W'
) => {
  const maxValue = max(values)
  const maxValueKw = maxValue / 1000
  const maxValueMw = maxValueKw / 1000

  let unitToConvert = unit
  if (Math.floor(Math.abs(maxValueMw))) {
    unitToConvert = `M${unit}`
  } else if (Math.floor(Math.abs(maxValueKw))) {
    unitToConvert = `k${unit}`
  }

  const energy = getEnergyValue(value, unit, unitToConvert)

  if (!energy) {
    return null
  }

  return `${energy.value} ${energy.unit}`
}
