/**
 * ScheduleEvent Scene
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import ScheduleEventForm from 'containers/ScheduleEventForm'

import { PageTitle, BackButton } from 'xstorage-components'

type Props = {
  currentEventId: number,
  translations: any
}

export class ScheduleEvent extends Component<Props, void> {
  actions: any

  render () {
    const {
      currentEventId,
      translations,
      isMobile,
      currentDeviceId
    } = this.props

    const { navigate } = this.actions

    const { SCHEDULE_EVENT_ADD, SCHEDULE_EVENT_EDIT } = translations

    return (
      <Wrapper>
        <TopDiv>
          <BackButton
            id={'BackButton'}
            color={'#FFFFFF'}
            onClick={() => navigate(`/${currentDeviceId}/schedule/`)}
          />
          <PageTitle mobile={isMobile}>
            {!currentEventId ? SCHEDULE_EVENT_ADD : SCHEDULE_EVENT_EDIT}
          </PageTitle>
        </TopDiv>
        <PageContainer>
          <ScheduleEventForm
            eventId={currentEventId}
            onCancel={() => navigate(`/${currentDeviceId}/schedule/`)}
          />
        </PageContainer>
      </Wrapper>
    )
  }
}

export default Logic(ScheduleEvent)

const Wrapper = styled.div``

const TopDiv = styled.div`
  background-color: #007bc1;
  height: 18vh;
  padding: 25px 20px;

  @media screen and (max-width: 580px) {
    height: 20vh;
  }
`

const PageContainer = styled.div`
  border-width: 11vw 100vw 0 0;
  border-color: #007bc1 #ffffff transparent transparent;
  display: inline-block;
  width: 0px;
  border-style: solid;
`
