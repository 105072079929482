// @flow
import { timeStringToFloat } from 'utils'

export const DEFAULT_SELECTED_SWITCHER = 'workdays'

/**
 * returns schedule switcher options with translations
 *
 * @param {*} translations
 */
export const getSwitcherOptions = (translations: any) => {
  const {
    SCHEDULE_WORKDAYS,
    SCHEDULE_WEEKENDS
  } = translations

  return [
    { slug: 'workdays', name: SCHEDULE_WORKDAYS },
    { slug: 'weekends', name: SCHEDULE_WEEKENDS }
  ]
}

/**
 * Validation function that check if start time is greater that end time
 *
 * @param {*} value
 * @param {*} state
 */
export const endTimeIsGreatThanStartTime = (value: any, state: any) => {
  const message: string = 'SCHEDULE_VALIDATION_ERR_ENDTIME'
  const start = timeStringToFloat(state.startTime.value)
  const end = timeStringToFloat(value)
  const valid = start < end

  return { result: valid, message }
}

/**
 * Validation function that check if duration is greater then 30m
 *
 * @param {*} value
 * @param {*} state
 */
export const durationIsValid = (value: any, state: any) => {
  const message: string = 'SCHEDULE_VALIDATION_ERR_DURATION'
  const start = timeStringToFloat(state.startTime.value)
  const end = timeStringToFloat(value)
  const duration = end - start
  const valid = duration >= 0.5

  return { result: valid, message }
}

/**
 * Validation function that checks that one field is required just
 * for Peak shaving command
 *
 * @param {*} value
 * @param {*} state
 */
export const peakConsumptionRequired = (value: any, state: any) => {
  if (state.command.value !== 'SET_PEAK_SHAVING') {
    return { result: 'end' }
  }

  if (!value) {
    return { result: false, message: 'FORM_VALIDATION_FIELD_REQUIRED' }
  }

  return { result: true }
}
