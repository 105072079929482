/* eslint-disable max-len */
// @flow
import React, { PureComponent } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import { MoreNavItem } from 'xstorage-components'

type Props = {
  page?: string,
  isMobile: boolean,
  items: Array<{
    key: string,
    icon: any,
    title: string,
    onClick: Function,
    disabled: boolean,
    link: boolean,
    color?: string,
    hidden?: boolean,
    id: string
  }>
}

class PageNavMenu extends PureComponent<Props, void> {
  actions: any

  render () {
    const { page, isMobile, items, margin } = this.props

    const activeMenuShadow = (
      <div>
        <NavItemActiveBg />
        <NavItemActiveShadow />
      </div>
    )

    return (
      <Wrapper margin={margin}>
        {items &&
          items.map(
            ({
              key,
              icon,
              title,
              onClick,
              disabled,
              link,
              color,
              hidden,
              id
            }) => {
              if (!hidden) {
                return (
                  <NavItemContainer
                    key={key}
                    active={page === key}
                    mobile={isMobile}
                    color={color}
                    link={link}
                  >
                    <MoreNavItem
                      id={id}
                      icon={icon}
                      title={title}
                      onClick={onClick}
                      link={link}
                      disabled={disabled}
                      color={color}
                    />
                    {page === key && activeMenuShadow}
                  </NavItemContainer>
                )
              }

              return ''
            }
          )}
      </Wrapper>
    )
  }
}

export default Logic(PageNavMenu)

const Wrapper = styled.div`
  margin: ${({ margin }) => margin};
`

const NavItemContainer = styled.div`
  cursor: pointer;
  position: relative;
  height: 56px;
  width: 100%;

  span.material-icons {
    color: ${({ color }) => color || '#007BC1'};
  }
  
  ${({ mobile, color }) =>
    !mobile &&
    `
    * {
      color: ${color || '#FFF!important'};
    }

    span.material-icons {
      color: ${color || '#FFF'};
    }

    > div:nth-child(1) {
      padding-left: 10px;
      z-index: 3;
      position: absolute;
      width: 100%;
      box-shadow: inset 0 -1px 0 0 rgba(253,253,253,0.1), 0 0 5px 0 rgba(81,141,197,0.1);
      transition: background-color 0.1s ease-in-out;
    }
  `}

  ${({ mobile, color }) => mobile && `
    * {
      color: ${color || '#424E54'}
    }

    > div:before {
      content: '';
      position: absolute;
      width: calc(100% + 16px);
      height: 56px;
      box-shadow: inset 0 -1px 0 0 rgba(114,126,132,0.1);

      @media screen and (min-width: 550px) and (max-width: 1024px) {
        width: calc(100% + 50px);
      }
    }
  `}

  ${({ active, mobile }) =>
    active &&
    !mobile &&
    `
    > div:nth-child(1) {
      position: relative;
      background-color: #007BC1;
      background: linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
      box-shadow: inset 0 -1px 0 0 rgba(253,253,253,0.1), 0 0 5px 0 rgba(81,141,197,0.1);
    }
  `}

${({ active, mobile, link }) => !active && !mobile && link && `
  &:hover > div:nth-child(1) {
    background-color: #0074b8;
  }
`}
`

const NavItemActiveBg = styled.div`
  position: absolute;
  height: 56px;
  width: 100%;
  background-color: #007bc1;
  z-index: 2;
  top: 0;
  left: 0;
`

const NavItemActiveShadow = styled.div`
  position: absolute;
  height: 10px;
  width: calc(100% - 48px);
  opacity: 0.4;
  background-color: #0067c6;
  box-shadow: 0 0 20px 0 #424e54;
  bottom: 0;
  right: 24px;
  z-index: 1;
`
