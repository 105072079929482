import React, { Component } from 'react'
import { ENERGY_FLOW_DURATION_FLOW } from 'config'
import anime from 'animejs'
import styled from 'styled-components'

export default class CriticalFlow extends Component {
  componentDidMount () {
    const { reverse, id, mobile } = this.props

    const pathExists = document.querySelector(`#motion-${id} path`)
    if (pathExists) {
      const path = anime.path(`#motion-${id} path`)
      anime({
        targets: `#motion-${id} > div`,
        translateX: path('x'),
        translateY: path('y'),
        rotate: path('angle'),
        easing: 'linear',
        duration: 2000,
        loop: true,
        direction:
          (reverse && mobile) || (!reverse && !mobile) ? 'reverse' : 'normal'
      })
    }
  }

  render () {
    const { id, mobile } = this.props

    let dimensions, path
    if (mobile) {
      dimensions = {
        height: '160',
        width: '100',
        viewBox: '-10 30 100 55'
      }
      path = 'M80 0 H 80 V 125 H 0'
    } else {
      dimensions = {
        height: '86',
        width: '255',
        viewBox: '-10 20 255 56'
      }
      path = 'M0,75 0,75 M0,75 l60,-60 H65 V 15 H 250'
    }

    return (
      <Wrapper id={`motion-${id}`} mobile={mobile}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          {...dimensions}
        >
          <path
            strokeDasharray='5, 16'
            strokeLinecap='round'
            d={path}
            fill='none'
            stroke='#fff'
            strokeWidth='3'
            id={`${id}-wire`}
          />
        </svg>
        <Circle mobile={mobile} />
      </Wrapper>
    )
  }
}

CriticalFlow.defaultProps = {
  id: 'CriticalFlow',
  duration: ENERGY_FLOW_DURATION_FLOW + 0.5,
  begin: 0,
  reverse: false,
  mobile: false
}

const Wrapper = styled.div`
  position: absolute;

  ${({ mobile }) =>
    !mobile &&
    `
    left: 45px;
    top: -45px;
  `}

  ${({ mobile }) =>
    mobile &&
    `
    right: 0;
    top: -90px;
  `}
`

const Circle = styled.div`
  box-shadow: 0px 0px 3px 2px rgba(51, 51, 51, 0.5);
  border-radius: 50%;
  position: absolute;

  ${({ mobile }) =>
    !mobile &&
    `
    background: #007BC1;
    border: 3px solid #FFF;
    top: -12px;
    left: 2px;
    width: 14px;
    height: 14px;
    box-shadow: 0px 0px 15px 1px rgba(0, 123, 193, 0.4);
  `}

  ${({ mobile }) =>
    mobile &&
    `
    background: #FFF;
    width: 12px;
    height: 12px;
    top: 16px;
    left: 4px;
  `}
`
