import { kea } from 'kea'
import axios from 'axios'
import { put, call, delay } from 'redux-saga/effects'
import { API_BASE_URL, DEFAULT_UNITS } from 'config'
import { log } from 'utils'
import mapValues from 'lodash/mapValues'

import PropTypes from 'prop-types'
import * as Check from 'validations'
import AppLogic from 'containers/App/logic'
import UserProfileLogic from 'scenes/UserProfile/logic'

const UPDATE_USER_ENDPOINT = `${API_BASE_URL}/users/me`
const AUTH_ENDPOINT = `${API_BASE_URL}/auth`

export const DEFAULT_VALUES = {
  weatherUnit: {
    value: ''
  }
}

const VALIDATIONS = {
  weatherUnit: [Check.isRequired]
}

export default kea({
  path: () => ['scenes', 'UserProfileWeather'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'user',
        'configs'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'setCurrentUser',
        'fetchWeatherInfo'
      ],
      UserProfileLogic, [
        'setShowSuccessMessage'
      ]
    ]
  },

  actions: () => ({
    reset: () => true,
    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    updateCurrentUserInfo: () => true,
    setFormUserUnit: () => true,
    setSuccessMessage: success => success
  }),

  reducers: ({ actions }) => ({
    loading: [
      true,
      PropTypes.bool,
      {
        [actions.reset]: () => false,
        [actions.setForm]: () => false,
        [actions.submit]: () => true,
        [actions.updateCurrentUserInfo]: () => false,
        [actions.setError]: () => false
      }
    ],

    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.updateCurrentUserInfo]: () => false,
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.setFormUserUnit]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.submit]: () => null
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => false,
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  start: function * () {
    log('[XS-UserProfileWeather] Start Scene', 'yellow')

    const { setFormUserUnit } = this.actions
    yield put(setFormUserUnit())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-UserProfileWeather] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
    [actions.updateCurrentUserInfo]: workers.updateCurrentUserInfo,
    [actions.setFormUserUnit]: workers.setFormUserUnit
  }),

  workers: {
    * setFormUserUnit () {
      const { setForm } = this.actions
      const user = yield this.get('user')

      if (user.weatherUnit && user.weatherUnit !== 'N/A') {
        yield put(setForm({ weatherUnit: { value: user.weatherUnit } }))
      } else {
        yield put(setForm({ weatherUnit: { value: DEFAULT_UNITS } }))
      }
    },

    * submit () {
      const {
        setError,
        setForm,
        updateCurrentUserInfo
      } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let data = mapValues(form, ({ value }) => value)

      try {
        const result = yield call(axios.put, UPDATE_USER_ENDPOINT, data)
        const statusResult = result.data
        const { successful } = statusResult

        if (successful) {
          yield put(updateCurrentUserInfo())
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * updateCurrentUserInfo () {
      const {
        setCurrentUser,
        navigate,
        fetchWeatherInfo,
        setSuccessMessage,
        setShowSuccessMessage
      } = this.actions

      const token = window.localStorage.getItem('msal.idtoken')
      const user = yield this.get('user')
      const isMobile = yield this.get('isMobile')

      const data = {
        token: token,
        language: user.language
      }

      const config = {
        headers: { 'content-type': 'application/json' },
        ignoreAuth: true
      }

      const response = yield call(axios.post, AUTH_ENDPOINT, data, config)
      const { result } = response.data || {}

      yield put(setCurrentUser(result))
      yield put(fetchWeatherInfo())

      if (isMobile) {
        yield put(setShowSuccessMessage(true))
        yield put(navigate('/user-profile/'))
      } else {
        yield put(setSuccessMessage(true))
        yield delay(2000)
        yield put(setSuccessMessage(false))
      }
    }
  }
})
