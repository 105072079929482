/**
 * General Utils
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */

// @flow
import { type EnergyValue } from 'types/global'

import pathToRegexp from 'path-to-regexp'
import {
  mapValues,
  isNumber,
  isNaN,
  round,
  keys,
  pick,
  isObject,
  omit,
  toArray,
  merge,
  extend
} from 'lodash'
import { resetKeaCache, keaReducer } from 'kea'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import moment from 'moment-timezone'
import fs from 'fs'
import { MEDIA_BASE_URL, DEFAULT_LANGUAGE_CODE, API_BASE_URL } from 'config'
import Swagmock from 'swagmock'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

// import translations files
import enTranslations from 'translations/en.json'
import frTranslations from 'translations/fr.json'
import itTranslations from 'translations/it.json'
import deTranslations from 'translations/de.json'

/**
 * Splits a pathname
 *
 * @param {*} p
 */
export const splitPath = (p: string) => p.substring(1).split('/')

/**
 * Gets route based on path
 *
 * @param {*} routes
 * @param {*} path
 */
export const getRoute = (routes: any, path: string) => {
  for (var key in routes) {
    if (routes.hasOwnProperty(key)) {
      const route = routes[key]
      const regex = pathToRegexp(route.path, [])
      let result = regex.exec(path)
      if (result) {
        let keys = pathToRegexp.parse(route.path)
        let params = {}
        keys = keys.filter(value => isObject(value))
        result = toArray(omit(result, ['index', 'input', 'groups']))
        result.shift()

        if (keys.length) {
          result.map((value, key) => {
            params[keys[key].name] = value
            return value
          })
        }

        route.params = params
        route.createUrl = pathToRegexp.compile(route.path)
        return route
      }
    }
  }

  return routes.notfound
}

/**
 * Gets the current store
 */
export const getStore = function (): any {
  resetKeaCache()

  const reducers = combineReducers({
    scenes: keaReducer('scenes')
  })

  const sagaMiddleware = createSagaMiddleware()
  const finalCreateStore = compose(applyMiddleware(sagaMiddleware))(createStore)

  const store = finalCreateStore(reducers)

  return store
}

/**
 * Transforms url with {} tags based on object
 *
 * @param {*} url
 * @param {*} obj
 */
export const generateUrl = (url: string, obj: any): string => {
  let newUrl = url

  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      newUrl = newUrl.replace(`{${key}}`, value)
    }
  }

  return newUrl
}

/**
 * Finds a get parameter in url
 *
 * @param {*} parameterName
 */
export const findGetParameter = (parameterName: string): string | null => {
  let result = null
  let tmp = []
  let items = window.location.search.substr(1).split('&')
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=')
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
  }
  return result
}

/**
 *
 * @param {*} uri
 */
export const generateMediaUrl = (uri: string): string => {
  return `${MEDIA_BASE_URL}${uri}`
}

/**
 * Downloads a file
 *
 * @param {*} uri
 */
export const downloadFile = (uri: string) => {
  var link = document.createElement('a')
  link.download = uri
  link.target = '_blank'
  link.href = generateMediaUrl(uri)
  link.click()
}

/**
 * Maps onChange from inputs and other DOM elements to state
 *
 * @param {*} state
 * @param {*} payload
 */
export const mapOnChangeToState = (state: {}, payload: { field: {} }): {} => {
  const key = Object.keys(payload.field)[0]
  const value = payload.field[key]
  const oldState = state[key] || {}
  return { ...state, ...{ [key]: { ...oldState, value } } }
}

/**
 * get just the filename on fullpath string
 * /full-path/file.ext -> file.ext
 *
 * @param {*} fullPath
 */
export const getFilenameOnFullPath = (fullPath: any): string =>
  fullPath.replace(/^.*(\\|\/|:)/, '')

/**
 * Round float number
 *
 * @param {*} num
 */
export const roundNumberToInt = (num: number): number => Math.round(num)

/**
 * Function to log messages on console
 *
 * @param {*} message
 * @param {*} color
 */
export const log = (message: string, color?: string) => {
  if (process.env.APP_ENV !== 'master') {
    console.log(`%c${message}`, `color:${color || '#CCC'}`)
  }
}

/**
 * helper function to get translations by browser language if
 * the language exists on translations folder
 */
export const getTranslations = (language: string): {} => {
  // TODO: try to import translations files dynamically
  // maybe we can with https://github.com/jamiebuilds/react-loadable
  const languages: {} = {
    en: enTranslations,
    'en-gb': enTranslations,
    fr: frTranslations,
    it: itTranslations,
    de: deTranslations
  }
  let translations: {} = languages[language]
    ? languages[language]
    : languages[DEFAULT_LANGUAGE_CODE]

  // merge the current language with default lang (en language)
  translations = merge({}, enTranslations, translations)

  /*
  const browserLanguage: string = navigator.language.split(/[-_]/)[0]
  if (languages[browserLanguage]) {
    translations = languages[browserLanguage]
  } */

  let result = {}
  mapValues(translations, pageTrans => {
    result = { ...result, ...pageTrans }
  })

  return result
}

/**
 * Helper that pad a number with zero on left by 2 digits
 *
 * @param {*} num
 */
export const padNumber = (num: number): string => {
  return `${num < 10 ? '0' : ''}${num}`
}

/**
 * Utils function to get Energy value on valid unit
 *
 * @param {*} value
 * @param {*} unit
 * @param {*} convertUnit
 */
export const getEnergyValue = (
  value: number,
  unit: string = 'W',
  convertUnit: string = ''
): EnergyValue | null => {
  const valid = isNumber(value) && !isNaN(value)
  if (!valid) {
    return null
  }
  const mUnit = `M${unit}`
  const kUnit = `k${unit}`

  const valueKw = value / 1000
  const valueMw = valueKw / 1000

  if (Math.floor(Math.abs(valueMw)) || convertUnit === mUnit) {
    unit = mUnit
    value = valueMw
  } else if (Math.floor(Math.abs(valueKw)) || convertUnit === kUnit) {
    unit = kUnit
    value = valueKw
  }
  value = round(value, 2)

  return {
    value: value,
    unit: unit,
    label: `${value}${unit}`
  }
}

/**
 * Function to return as decimal version of a specific
 * time (10:30 -> 10.5)
 *
 * @param {*} time
 */
export const timeStringToFloat = (time: string): number => {
  var hoursMinutes = time.split(/[.:]/)
  var hours = parseInt(hoursMinutes[0], 10)
  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
  return hours + minutes / 60
}

/**
 * Function to get swagger specs and mock all requets made by the
 * application
 *
 * @param {*} mock
 * @param {*} specsUrl
 * @param {*} specificPaths
 */
export const mockSwaggerSpecs = async (
  mock: any,
  specsUrl: string,
  specificPaths: {} = {},
  ignoreFile: boolean = false
) => {
  let responses = []
  const expectedCode = 200

  const getPaths = (error, paths, fileExists) => {
    if (!error) {
      if (!fileExists && !ignoreFile && process.env.APP_ENV === 'test') {
        fs.writeFile('src/swaggerDocs.json', JSON.stringify(paths))
      }

      mapValues(paths, (methods, url) =>
        mapValues(methods, (methodInfo, method) => {
          const swaggerPath = [method, url, expectedCode, methodInfo.responses]
          const path = specificPaths[url] ? specificPaths[url] : swaggerPath
          responses.push(path)
        })
      )
    }

    // Match ALL requests
    mock.onAny().reply(config => {
      const res = responses.find(r => {
        const [method, url, code] = r
        // replace {id} by regex pattern
        let cleanedUrl = RegExp(`${url.replace('{id}', '([^/]+)')}$`)
        return (
          config.method === method &&
          cleanedUrl.test(config.url) &&
          code === expectedCode
        )
      })

      if (!res) {
        // Unexpected request, error out
        return [404, {}]
      }
      let [method, url, code, response] = res

      // TODO: check method and put response as request method

      // put always as successful
      if (!response.successful && !specificPaths[url]) {
        response.successful = true
      }
      return method && url && [code, response]
    })
  }

  if (
    process.env.APP_ENV === 'test' &&
    !ignoreFile &&
    (await fs.existsSync('src/swaggerDocs.json'))
  ) {
    const data = require('swaggerDocs.json')
    getPaths(null, data, true)
  } else {
    let mockgen = Swagmock(specsUrl)
    await mockgen.swagger
      .then(res => {
        mockgen.responses(
          {
            response: expectedCode
          },
          getPaths
        )
      })
      .catch(error => {
        console.log(error)
        // throw new Error('No connection to Swagger API')
        const data = require('swaggerDocs.json')
        getPaths(null, data, true)
      })
  }
}

/**
 * Helper function to mock all api responses
 *
 * @param {*} paths
 */
export const mockApiResponses = async (paths: {} = {}) => {
  const mock = new MockAdapter(axios)
  await mockSwaggerSpecs(mock, `${API_BASE_URL}/v2/api-docs`, paths)
}

/**
 * helper function for tests
 *
 * @param {*} func
 * @param {*} period
 */
export const taskResolution = (func: Function, period: number): any => {
  return new Promise((resolve: Function, reject: Function) => {
    setTimeout(() => {
      func(resolve)
    }, period)
  })
}

/**
 * Convert a time to local naive time
 *
 * @param {*} time
 * @param {*} format
 */
export const convertTimeToLocalNaive = (
  time: any,
  format: string = 'YYYY-MM-DDTHH:mm:ss'
) => {
  if (typeof time !== 'object') {
    time = moment.unix(time)
  }
  const date = time.format(format)

  return date
}

/**
 * Convert duration in milliseconds into remaining time
 * @param {number} ms
 * @return {string[]} [hours, minutes, seconds, milliseconds]
 */
export const msToTime = (ms: number) => {
  return [
    Math.floor((ms / (1000 * 60 * 60)) % 24),
    Math.floor((ms / (1000 * 60)) % 60),
    Math.floor((ms / 1000) % 60),
    Math.floor((ms % 1000) / 100)
  ]
}

/**
 * Return formated remaining time
 * @param {number} duration
 */
export const remainingTime = (
  duration: number
) => {
  const [
    hours,
    minutes,
    seconds
  ] = msToTime(duration)

  if (hours) {
    return `${hours}h${minutes}m`
  }

  if (minutes) {
    return `${minutes}m`
  }

  return `${seconds}s`
}

/**
 * Helper function for axios interceptor
 *
 * @param {*} config
 * @param {*} idToken
 */
export const axiosAuthInterceptor = (config: any, idToken: string) => {
  if (!config.ignoreAuth) {
    config.headers.Authorization = `Bearer ${idToken}`
  }
  return config
}

/**
 * Helper function to remove all data of user session
 */
export const removeUserSession = () => {
  Object.keys(window.localStorage)
    .filter(k => /msal/.test(k))
    .forEach(k => window.localStorage.removeItem(k))

  window.localStorage.removeItem('currentDeviceId')

  // reload page to redirect user for login
  window.location.reload()
}

/**
 * Helper time functions to convert a epoch timestamp to
 * string date on spcecific format
 *
 * @param {*} epoch
 * @param {*} format
 */
export const convertEpochToDate = (
  epoch: number,
  format: string = 'DD/MM/YYYY'
) => {
  return moment.utc(epoch, 'X').format(format)
}

/**
 * Helper time function that converts timstamps on millis
 * to sconds
 *
 * @param {*} millis
 */
export const convertMillisToSec = (millis: number): number => {
  return millis / 1000
}

/**
 * Helper time function that converts seconds to millis
 *
 * @param {*} seconds
 */
export const convertSecToMillis = (seconds: number): number => {
  return seconds * 1000
}

/**
 * Helper function to get form values by object returned by API
 * based on default values object
 *
 * @param {*} defaultValues
 * @param {*} values
 */
export const getFormValues = (defaultValues: any, values: any) => {
  const formKeys = keys(defaultValues)
  const info = pick(values, formKeys)
  const formInfo = mapValues(info, value => ({ value }))

  return extend({}, defaultValues, formInfo)
}

/**
 * convert from military time to default time
 *
 * @param {*} time
 */
export const convertFromMilitaryTime = (time: number) => {
  let val = time.toString()

  let hours = 0
  if (val.length > 2) {
    hours = val.substring(0, val.length - 2)
  }
  const minutes = val.substring(val.length - 2, val.length)

  return `${padNumber(parseInt(hours))}:${padNumber(parseInt(minutes))}`
}

/**
 * Function to scroll to an element on DOM
 *
 * @param {*} element
 * @param {*} to
 * @param {*} duration
 */
export const scrollTo = (element: any, to: number, duration: number) => {
  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  // definition of attributes
  let start = element.scrollTop
  let change = to - start
  let currentTime = 0
  let increment = 20

  // animate function
  let animateScroll = () => {
    currentTime += increment
    let val = easeInOutQuad(currentTime, start, change, duration)
    element.scrollTop = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

export const scrollToFirstFormError = (form: {}, containerId: 'body') => {
  let scrolled = false
  mapValues(form, (value, key) => {
    if (!value.valid && !scrolled) {
      // scroller.scrollTo(key, { smooth: true, containerId: containerId })
      let elementScroll = document.getElementsByName(key)
      if (elementScroll && elementScroll.length > 0) {
        elementScroll[0].scrollIntoView()
      }
      scrolled = true
    }
  })
}

export const getUserRole = user => {
  let role = 'view'
  if (user) {
    if (user.owner) {
      role = 'owner'
    } else if (user.technician) {
      role = 'technician'
    } else if (user.roleManagement) {
      role = 'roleManagement'
    } else if (user.write) {
      role = 'write'
    }
  }

  return role
}
