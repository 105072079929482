// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Grid, Icon } from '@material-ui/core'

import SlidePanel from 'components/SlidePanel'
import UnitInfoForm from 'containers/UnitInfoForm'

import {
  Loading,
  PageTitle,
  RoundedButton,
  UnitDetails,
  Paper,
  Button,
  Alert
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  currentUnit: any,
  unitPanelVisible: boolean,
  untakenUnitsLength: number,
  untakenUnits: [],
  translations: any,
  loading: boolean,
  loadingButton: boolean,
  showLoadMoreInfo: boolean,
  totalUntakenUnits: number
}

export class TechnicianUntakenUnits extends Component<Props> {
  render () {
    const {
      isMobile,
      currentUnit,
      unitPanelVisible,
      untakenUnitsLength,
      untakenUnits,
      translations,
      loading,
      loadingButton,
      showLoadMoreInfo,
      totalUntakenUnits
    } = this.props

    const {
      TECH_UNITS_UNTAKEN_UNITS,
      TECH_UNITS_ADD_UNIT,
      TECH_UNITS_UNIT_DETAILS,
      TECH_UNIT_LOAD_MORE_BUTTON,
      TECH_UNIT_NO_MORE_RESULTS,
      TECH_UNITS_LOADING
    } = translations

    const {
      closeUnitPanel,
      showUnitInfo,
      addUnit,
      navigate,
      fetchUntakenUnits
    } = this.actions

    return (
      <Wrapper mobile={isMobile}>
        {!showLoadMoreInfo && loading && <Loading />}
        <HeaderSection mobile={isMobile}>
          <PageTitle mobile={isMobile}>{TECH_UNITS_UNTAKEN_UNITS}</PageTitle>
          <PageSubTitle>
            {untakenUnitsLength} {TECH_UNITS_UNTAKEN_UNITS}
          </PageSubTitle>
        </HeaderSection>
        <BodySection mobile={isMobile}>
          <Grid container spacing={3}>
            {untakenUnitsLength > 0 &&
              untakenUnits.map(unit => (
                <Grid key={unit.id} item xs={12} sm={6} md={4}>
                  <Paper
                    onClick={() =>
                      !isMobile
                        ? showUnitInfo(unit)
                        : navigate(`/unit/${unit.id}/`)
                    }
                    shadow
                    mobile={isMobile}
                    showanimation
                  >
                    <UnitDetails
                      name={unit.device.inverterSerialNumber}
                      user={`${unit.firstName} ${unit.lastName}`}
                      email={unit.email}
                      country={unit.country && unit.country.name}
                      icon={<Icon>{'more_horiz'}</Icon>}
                    />
                  </Paper>
                </Grid>
              ))}
          </Grid>
          {showLoadMoreInfo && loadingButton && (
            <LoadMoreDiv mobile={isMobile}>
              <Button
                id={'SecondaryButton_Loading'}
                buttonType={'secondary-default'}
                buttonWidth={'200px'}
                smallShadow
              >
                <Icon>{'autorenew'}</Icon>
                {TECH_UNITS_LOADING}
              </Button>
            </LoadMoreDiv>
          )}
          {showLoadMoreInfo && !loadingButton && (
            <React.Fragment>
              {totalUntakenUnits > untakenUnitsLength && (
                <LoadMoreDiv mobile={isMobile}>
                  <Button
                    id={'SecondaryButton_LoadMore'}
                    buttonType={'secondary-default'}
                    buttonWidth={'200px'}
                    smallShadow
                    onClick={fetchUntakenUnits}
                  >
                    <Icon>{'add'}</Icon>
                    {TECH_UNIT_LOAD_MORE_BUTTON}
                  </Button>
                </LoadMoreDiv>
              )}
              {totalUntakenUnits === untakenUnitsLength && (
                <LoadMoreDiv mobile={isMobile}>
                  <Alert
                    icon={<Icon>{'error'}</Icon>}
                    message={TECH_UNIT_NO_MORE_RESULTS}
                  />
                </LoadMoreDiv>
              )}
            </React.Fragment>
          )}
        </BodySection>
        <AddButton
          onClick={() => (!isMobile ? addUnit() : navigate('/add-unit/'))}
        >
          <Icon>{'add'}</Icon>
        </AddButton>
        {!isMobile && (
          <div>
            <SlidePanel
              overlay
              open={unitPanelVisible}
              type={'content-not-padded'}
              onClose={closeUnitPanel}
              fixed={unitPanelVisible}
              title={
                !currentUnit
                  ? `${TECH_UNITS_ADD_UNIT}`
                  : `${TECH_UNITS_UNIT_DETAILS}`
              }
            >
              <UnitInfoForm
                currentUnit={currentUnit}
                onCancel={closeUnitPanel}
              />
            </SlidePanel>
          </div>
        )}
      </Wrapper>
    )
  }
}

export default Logic(TechnicianUntakenUnits)

const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const HeaderSection = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    background-color: #007BC1;
    padding: 20px 25px 4px 25px;
  `}
`

const BodySection = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    @media screen and (max-width: 580px) {
      border-width: 18vw 100vw 0 0;
    }
    
    border-width: 50px 500px 0 0;
    border-color: #007bc1 #FFFFFF transparent transparent;
    display: inline-block;
    width: 0px;
    border-style: solid;
  `}
`

const PageSubTitle = styled.h3`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 24px;
  text-transform: lowercase;
`

const AddButton = styled(props => <RoundedButton {...props} />)`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    bottom: 80px;
    right: 30px;
  }
`

const LoadMoreDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 31px;
  margin-bottom: 31px;

  ${({ mobile }) =>
    mobile &&
    `
    width: 100vw;
  `}
`
