// @flow
import React, { Component } from 'react'
import { type Device } from 'types/api'
import styled from 'styled-components'
import Logic from './logic'

import { MoreNavItem } from 'xstorage-components'
import Subtitle from 'components/Subtitle'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeatures from 'containers/MaintenanceSupportedFeatures'
import UserPermission from 'containers/UserPermission'

type Props = {
  translations: {
    UNITSETT_SUPPORT_MAINTENANCE_OPTIONS: string,
    UNITSETT_SUPPORT_UNITLOG: string,
    UNITSETT_SUPPORT_UNITLOG_DETAIL: string,
    UNITSETT_SUPPORT_REBOOTUNIT: string,
    UNITSETT_SUPPORT_REBOOTUNIT_DETAIL: string,
    UNITSETT_SUPPORT_HTTPS: string,
    UNITSETT_SUPPORT_HTTPS_DETAIL: string
  },
  isMobile: boolean,
  currentDevice: Device,
  featureSupport: {
    rebootSupported: boolean,
    maintenanceSupported: boolean,
    httpsSupported: boolean
  }
}

class SupportMaintenanceOptions extends Component<Props, void> {
  render () {
    const { navigate } = this.actions
    const { translations, isMobile, currentDevice, featureSupport } = this.props
    const {
      UNITSETT_SUPPORT_MAINTENANCE_OPTIONS,
      UNITSETT_SUPPORT_UNITLOG,
      UNITSETT_SUPPORT_UNITLOG_DETAIL,
      UNITSETT_SUPPORT_REBOOTUNIT,
      UNITSETT_SUPPORT_REBOOTUNIT_DETAIL,
      UNITSETT_SUPPORT_HTTPS,
      UNITSETT_SUPPORT_HTTPS_DETAIL
    } = translations

    const {
      rebootSupported,
      maintenanceSupported,
      httpsSupported
    } = featureSupport || {}

    return (
      <Wrapper>
        <Subtitle>{UNITSETT_SUPPORT_MAINTENANCE_OPTIONS}</Subtitle>
        <MaintenanceSupportedFeatures feature={'HTTPStunnel'} />
        <OptionsMenu>
          <UserPermission permission={'SUPPORT_UNIT_LOG'}>
            <MoreNavItem
              id={'UnitLog'}
              onClick={() =>
                navigate(`/${currentDevice.id}/settings/support/unit-log`)
              }
              link
              title={UNITSETT_SUPPORT_UNITLOG}
              description={UNITSETT_SUPPORT_UNITLOG_DETAIL}
              color={'#5B6770'}
              descriptionColor={'#424E54'}
              descriptionFontSize={!isMobile && '14px'}
              descriptionMargin={'4px 0 0'}
              disabled={!maintenanceSupported}
            />
          </UserPermission>
          <UserPermission permission={'HTTPS_TUNNEL'}>
            <MoreNavItem
              id={'HttpsTunnel'}
              onClick={() =>
                navigate(`/${currentDevice.id}/settings/support/https-tunnel`)
              }
              link
              title={UNITSETT_SUPPORT_HTTPS}
              description={UNITSETT_SUPPORT_HTTPS_DETAIL}
              color={'#5B6770'}
              descriptionColor={'#424E54'}
              descriptionFontSize={!isMobile && '14px'}
              descriptionMargin={'4px 0 0'}
              disabled={!httpsSupported}
            />
          </UserPermission>
          <UserPermission permission={'REBOOT_UNIT'}>
            <MoreNavItem
              id={'RebootUnit'}
              onClick={() =>
                navigate(`/${currentDevice.id}/settings/support/reboot-unit`)
              }
              link
              title={UNITSETT_SUPPORT_REBOOTUNIT}
              description={UNITSETT_SUPPORT_REBOOTUNIT_DETAIL}
              color={'#5B6770'}
              descriptionColor={'#424E54'}
              descriptionFontSize={!isMobile && '14px'}
              descriptionMargin={'4px 0 0'}
              disabled={!rebootSupported}
            />
          </UserPermission>
        </OptionsMenu>
      </Wrapper>
    )
  }
}

export default Logic(SupportMaintenanceOptions)

const Wrapper = styled.div`
  margin-top: 80px;
`

const OptionsMenu = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: 30vh;

  > div:before {
    content: '';
    width: calc(100% - 20px);
    height: 70px;
    position: absolute;
    box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);
  }

  > div {
    height: 70px;
  }

  > div > div {
    padding-left: 8px;
  }
`
