// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import { log } from 'utils'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'ScheduleEvent'],

  connect: {
    props: [
      AppLogic, [
        'currentRoute',
        'translations',
        'currentDeviceId',
        'isMobile'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    reset: () => true,
    setEventId: (eventId) => ({ eventId })
  }),

  reducers: ({ actions }) => ({
    currentEventId: [null, PropTypes.string, {
      [actions.setEventId]: (state, payload) => payload.eventId,
      [actions.reset]: () => null
    }]
  }),

  start: function * () {
    const {
      setEventId,
      navigate
    } = this.actions

    log('[XS-ScheduleEvent] Start Scene ', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    const currentRoute = yield this.get('currentRoute')
    const { eventId } = currentRoute.params

    if (eventId) {
      yield put(setEventId(eventId))
    }
  },

  stop: function * () {
    const {
      reset
    } = this.actions

    log('[XS-ScheduleEvent] Stop Scene ')

    yield put(reset())
  }
})
