// @flow
import React, { Component } from 'react'
import { type Device } from 'types/api'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import moment from 'moment-timezone'
import {
  PageTitle,
  PageMainSection,
  LabelValueInline,
  Button,
  SuccessSavedAlert,
  ErrorMessage
} from 'xstorage-components'

import LoadingGif from 'components/LoadingGif'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeatures from 'containers/MaintenanceSupportedFeatures'
import MaintenanceMenu from 'containers/MaintenanceMenu'
import PageAdaptable from 'containers/PageAdaptable'

type Props = {
  translations: {
    MAINTENANCE_TITLE: string,
    UNITSETT_SUPPORT_DATETIMESYNC: string,
    UNITSETT_SUPPORT_METRICS_SYNC_FORCE_SYNC: string,
    UNITSETT_SUPPORT_DATETIMESYNC_UNIT_DATETIME: string,
    UNITSETT_SUPPORT_DATETIMESYNC_TIMEZONE: string,
    UNITSETT_SUPPORT_DATETIMESYNC_SYNCMETHOD: string,
    UNITSETT_SUPPORT_DATETIME_SUCCESSSYNC: string
  },
  isMobile: boolean,
  datetimeValues: {
    time: number,
    timezone: string,
    clockSyncMethod: string
  },
  successMessage: boolean,
  loading: boolean,
  loadingData: boolean,
  currentDevice: Device,
  error: string,
  featureSupport: {
    maintenanceUpdateRequired: boolean
  }
}

export class MaintenanceDatetimeSync extends Component<Props> {
  actions: {
    navigate: Function,
    forcesync: Function
  }

  render () {
    const {
      translations,
      isMobile,
      datetimeValues,
      successMessage,
      loading,
      loadingData,
      currentDevice,
      error,
      featureSupport
    } = this.props

    const { navigate, forcesync } = this.actions

    const { maintenanceUpdateRequired } = featureSupport

    const {
      MAINTENANCE_TITLE,
      UNITSETT_SUPPORT_DATETIMESYNC,
      UNITSETT_SUPPORT_METRICS_SYNC_FORCE_SYNC,
      UNITSETT_SUPPORT_DATETIMESYNC_UNIT_DATETIME,
      UNITSETT_SUPPORT_DATETIMESYNC_TIMEZONE,
      UNITSETT_SUPPORT_DATETIMESYNC_SYNCMETHOD,
      UNITSETT_SUPPORT_DATETIME_SUCCESSSYNC
    } = translations

    return (
      <Wrapper>
        <PageAdaptable
          title={MAINTENANCE_TITLE}
          menu={<MaintenanceMenu page={'datetime-sync'} />}
          isForm={false}
          onBack={() => navigate(`/${currentDevice.id}/maintenance/`)}
          loading={loading}
        >
          <PageMainSection padding={!isMobile} mobile={isMobile}>
            <SuccessSavedAlert
              mobile={isMobile}
              out={!successMessage}
              icon={<Icon>{'check'}</Icon>}
              message={UNITSETT_SUPPORT_DATETIME_SUCCESSSYNC}
            />
            <PageTitle mobile={isMobile} marginBottom={'40px'}>
              {UNITSETT_SUPPORT_DATETIMESYNC}
            </PageTitle>
            <LoadingGif loading={loadingData} />
            <MaintenanceSupportedFeatures feature={'maintenance'} />
            {!maintenanceUpdateRequired && (
              <React.Fragment>
                <ErrorMessage>{error}</ErrorMessage>
                <LabelValueInline
                  id={'DateTime'}
                  label={UNITSETT_SUPPORT_DATETIMESYNC_UNIT_DATETIME}
                  value={moment(datetimeValues.time).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}
                />
                <LabelValueInline
                  id={'Timezone'}
                  label={UNITSETT_SUPPORT_DATETIMESYNC_TIMEZONE}
                  value={datetimeValues.timezone || '--'}
                />
                <LabelValueInline
                  id={'SyncMethod'}
                  label={UNITSETT_SUPPORT_DATETIMESYNC_SYNCMETHOD}
                  value={
                    // eslint-disable-next-line max-len
                    translations[`UNITSETT_SUPPORT_DATETIMESYNC_${datetimeValues.clockSyncMethod}`] || '--'
                  }
                />
              </React.Fragment>
            )}
            {currentDevice.connected && !maintenanceUpdateRequired && (
              <ButtonWrapper isMobile={isMobile}>
                <Button
                  id={'PrimaryButton_ForceSync'}
                  buttonWidth={'260px'}
                  margin={'42px 0'}
                  smallShadow
                  onClick={forcesync}
                >
                  <Icon>{'autorenew'}</Icon>{' '}
                  {UNITSETT_SUPPORT_METRICS_SYNC_FORCE_SYNC}
                </Button>
              </ButtonWrapper>
            )}
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceDatetimeSync)

const Wrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  z-index: 10;

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
  `}
`
