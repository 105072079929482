// @flow
import { kea } from 'kea'
import { log } from 'utils'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'UserProfileDelete'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'userValid'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  start: function * () {
    yield log('[XS-UserProfileDelete] Start Scene ', 'yellow')
  },

  stop: function * () {
    yield log('[XS-UserProfileDelete] Stop Scene ')
  }
})
