import { kea } from 'kea'

import axios from 'axios'
import { put, call, delay } from 'redux-saga/effects'
import { API_BASE_URL } from 'config'
import { log } from 'utils'
import mapValues from 'lodash/mapValues'

import PropTypes from 'prop-types'
import * as Check from 'validations'

import AppLogic from 'containers/App/logic'
import UserProfileLogic from 'scenes/UserProfile/logic'

const FETCH_LANGUAGES_ENDPOINT = `${API_BASE_URL}/internal/languages`
const UPDATE_LANGUAGE_ENDPOINT = `${API_BASE_URL}/users/me`
const AUTH_ENDPOINT = `${API_BASE_URL}/auth`

export const DEFAULT_VALUES = {
  language: {
    value: ''
  }
}

const VALIDATIONS = {
  language: [Check.isRequired]
}

export default kea({
  path: () => ['scenes', 'UserProfileLanguage'],

  connect: {
    props: [AppLogic, ['isMobile', 'translations', 'user', 'configs']],
    actions: [
      AppLogic, [
        'navigate',
        'setCurrentUser',
        'setLanguage'
      ],
      UserProfileLogic, [
        'setShowSuccessMessage'
      ]
    ]
  },

  actions: () => ({
    reset: () => true,
    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),

    fetchLanguages: () => true,
    setLanguages: languages => ({ languages }),
    updateCurrentUserInfo: () => true,
    setSuccessMessage: success => success
  }),

  reducers: ({ actions }) => ({
    languages: [
      [],
      PropTypes.array,
      {
        [actions.reset]: (state, payload) => [],
        [actions.setLanguages]: (state, payload) => payload.languages
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.reset]: () => true,
        [actions.submit]: () => true,
        [actions.fetchLanguages]: () => true,
        [actions.setLanguages]: () => false,
        [actions.setLanguage]: () => false,
        [actions.setError]: () => false
      }
    ],

    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.setError]: () => true,
        [actions.reset]: () => false,
        [actions.updateCurrentUserInfo]: () => false,
        [actions.fetchLanguages]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.submit]: () => null,
        [actions.change]: () => null
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => false,
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  start: function * () {
    log('[XS-UserProfileLanguage] Start Scene', 'yellow')

    const { fetchLanguages } = this.actions

    yield put(fetchLanguages())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-UserProfileLanguage] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchLanguages]: workers.fetchLanguages,
    [actions.submit]: workers.submit,
    [actions.updateCurrentUserInfo]: workers.updateCurrentUserInfo
  }),

  workers: {
    * fetchLanguages () {
      const { setLanguages, setForm } = this.actions
      const user = yield this.get('user')

      try {
        const response = yield call(axios.get, FETCH_LANGUAGES_ENDPOINT)
        const statusResult = response.data
        const { result, successful } = statusResult

        if (successful) {
          yield put(setLanguages(result.data))
          yield put(setForm({ language: { value: user.language } }))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * submit () {
      const { setError, setForm, updateCurrentUserInfo } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let data = mapValues(form, ({ value }) => value)

      try {
        const result = yield call(axios.put, UPDATE_LANGUAGE_ENDPOINT, data)
        const { successful } = result.data || {}

        if (successful) {
          yield put(updateCurrentUserInfo())
        }
      } catch (catchError) {
        console.log(catchError)
        yield put(setError(translations['UNEXPECTED_ERROR_SAVE_FORM']))
      }
    },

    * updateCurrentUserInfo () {
      const {
        setCurrentUser,
        setLanguage,
        navigate,
        setSuccessMessage,
        setShowSuccessMessage
      } = this.actions

      const token = window.localStorage.getItem('msal.idtoken')
      const user = yield this.get('user')
      const isMobile = yield this.get('isMobile')

      const data = {
        token: token,
        language: user.language
      }

      const config = {
        headers: { 'content-type': 'application/json' },
        ignoreAuth: true
      }

      const response = yield call(axios.post, AUTH_ENDPOINT, data, config)
      const { result } = response.data || {}

      yield put(setCurrentUser(result))
      if (result.language) {
        yield put(setLanguage(result.language))
      }

      if (isMobile) {
        yield put(setShowSuccessMessage(true))
        yield put(navigate('/user-profile/'))
      } else {
        yield put(setSuccessMessage(true))
        yield delay(5000)
        yield put(setSuccessMessage(false))
      }
    }
  }
})
