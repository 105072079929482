// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import {
  Modal,
  ModalHeader,
  ModalContent,
  List,
  ListItemCollapse
} from 'xstorage-components'

type Props = {
  open: boolean,
  onClose: Function
}

class EnergyFlowDetailsModal extends Component<Props, void> {
  render () {
    const { open, onClose, isMobile } = this.props

    return (
      <Wrapper>
        {open && (
          <Modal open={open} onClose={onClose} size={'medium'}>
            <div>
              <ModalHeader
                title={'Energy flow details'}
                subtitle={'Storage Unit'}
                closeButton
                onClose={onClose}
              />
              <ModalContent type={'diagonal'} mobile={isMobile} size={'medium'}>
                <List>
                  <ListItemCollapse title={'Voltage'} label={'95.5 V'} shadow />
                  <ListItemCollapse title={'Current'} label={'0.00 A'} shadow />
                  <ListItemCollapse
                    title={'Temperature'}
                    label={<span>24.5 &deg;C</span>}
                    shadow
                  />
                  <ListItemCollapse
                    title={'Average Temperature after installation'}
                    label={<span>-0.1 &deg;C</span>}
                    shadow
                  />
                  <ListItemCollapse
                    title={'Average Temperature before installation'}
                    label={<span>23.0 &deg;C</span>}
                    shadow
                  />
                  <ListItemCollapse
                    title={'Total charge'}
                    label={'367 Ah'}
                    shadow
                  />
                  <ListItemCollapse
                    title={'Total discharge'}
                    label={'227 Ah'}
                  />
                </List>
              </ModalContent>
            </div>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default Logic(EnergyFlowDetailsModal)

const Wrapper = styled.div``
