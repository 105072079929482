// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import PageAdaptable from 'containers/PageAdaptable'
import MaintenanceMenu from 'containers/MaintenanceMenu'
import FormFieldError from 'containers/FormFieldError'
import FormGeneralError from 'containers/FormGeneralError'
import {
  PageTitle,
  PageMainSection,
  MobileActionButton,
  Button,
  InputText,
  Form
} from 'xstorage-components'

type Props = {
  translations: any,
  isMobile: boolean,
  dirty: boolean,
  form: {
    overvoltage: {
      value: string,
      message: string,
      valid: boolean
    },
    undervoltage1: {
      value: string,
      message: string,
      valid: boolean
    },
    undervoltage2: {
      value: string,
      message: string,
      valid: boolean
    },
    undervoltage3: {
      value: string,
      message: string,
      valid: boolean
    },
    undervoltage4: {
      value: string,
      message: string,
      valid: boolean
    }
  },
  error: string
}

export class MaintenanceChangeUnitParams extends Component<Props> {
  render () {
    const { translations, isMobile, dirty, form, error } = this.props

    const {
      overvoltage,
      undervoltage1,
      undervoltage2,
      undervoltage3,
      undervoltage4
    } = form

    const { navigate, submit, change } = this.actions

    const {
      MAINTENANCE_CHANGE_UNIT_PARAMS,
      MAINTENANCE_TITLE,
      UNITSETT_GENERAL_SAVE,
      MAINTENANCE_CHANGE_PARAMS_OVERVOLTAGE,
      UNITSETT_GENERAL_CANCEL,
      MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE1,
      MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE2,
      MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE3,
      MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE4
    } = translations

    const formButtons = (
      <Form.Buttons align={'right'}>
        <Button
          buttonType={'primary-cancel'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={() => window.location.reload()}
        >
          {UNITSETT_GENERAL_CANCEL}
        </Button>
        <Button margin={'0 12px'} buttonWidth={'163px'} onClick={submit}>
          {UNITSETT_GENERAL_SAVE}
        </Button>
      </Form.Buttons>
    )

    return (
      <Wrapper>
        <PageAdaptable
          title={MAINTENANCE_TITLE}
          menu={<MaintenanceMenu page={'unit-params'} />}
          onBack={() => navigate('/maintenance/')}
          isForm
          isDirty={dirty}
        >
          <PageMainSection padding={!isMobile} mobile={isMobile}>
            <PageTitle mobile={isMobile}>
              {MAINTENANCE_CHANGE_UNIT_PARAMS}
            </PageTitle>
            {isMobile && (
              <MobileActionButton
                mobile
                icon={<Icon>{'check'}</Icon>}
                onClick={submit}
                id={'Save'}
              >
                {UNITSETT_GENERAL_SAVE}
              </MobileActionButton>
            )}
            <Form
              showButtons
              mobile={isMobile}
              buttons={formButtons}
              containerHeight={'62vh'}
            >
              <FormGeneralError dirty={dirty} error={error} full={isMobile} />
              <Form.Row full={isMobile} relative>
                <InputText
                  label={MAINTENANCE_CHANGE_PARAMS_OVERVOLTAGE}
                  error={dirty && !overvoltage.valid}
                  value={overvoltage.value}
                  onChange={({ target }) =>
                    change({ overvoltage: target.value })
                  }
                  inputProps={{
                    maxLength: 90
                  }}
                />
                <FormFieldError
                  id={'Overvoltage'}
                  showError={dirty && !overvoltage.valid}
                  errorMessage={overvoltage.message}
                />
              </Form.Row>
              <Form.Row full={isMobile} relative>
                <InputText
                  id={'Undervoltage1'}
                  label={MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE1}
                  error={dirty && !undervoltage1.valid}
                  value={undervoltage1.value}
                  onChange={({ target }) =>
                    change({ undervoltage1: target.value })
                  }
                  inputProps={{
                    maxLength: 90
                  }}
                  endtext={'V'}
                />
                <FormFieldError
                  id={'Undervoltage1'}
                  showError={dirty && !undervoltage1.valid}
                  errorMessage={undervoltage1.message}
                />
              </Form.Row>
              <Form.Row full={isMobile} relative>
                <InputText
                  label={MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE2}
                  error={dirty && !undervoltage2.valid}
                  value={undervoltage2.value}
                  onChange={({ target }) =>
                    change({ undervoltage2: target.value })
                  }
                  inputProps={{
                    maxLength: 90
                  }}
                  endtext={'V'}
                />
                <FormFieldError
                  id={'Undervoltage2'}
                  showError={dirty && !undervoltage2.valid}
                  errorMessage={undervoltage2.message}
                />
              </Form.Row>
              <Form.Row full={isMobile} relative>
                <InputText
                  label={MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE3}
                  error={dirty && !undervoltage3.valid}
                  value={undervoltage3.value}
                  onChange={({ target }) =>
                    change({ undervoltage3: target.value })
                  }
                  inputProps={{
                    maxLength: 90
                  }}
                  endtext={'V'}
                />
                <FormFieldError
                  id={'Undervoltage3'}
                  showError={dirty && !undervoltage3.valid}
                  errorMessage={undervoltage3.message}
                />
              </Form.Row>
              <Form.Row full={isMobile} relative>
                <InputText
                  label={MAINTENANCE_CHANGE_PARAMS_UNDERVOLTAGE4}
                  error={dirty && !undervoltage4.valid}
                  value={undervoltage4.value}
                  onChange={({ target }) =>
                    change({ undervoltage4: target.value })
                  }
                  inputProps={{
                    maxLength: 90
                  }}
                  endtext={'V'}
                />
                <FormFieldError
                  id={'Undervoltage4'}
                  showError={dirty && !undervoltage4.valid}
                  errorMessage={undervoltage4.message}
                />
              </Form.Row>
            </Form>
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceChangeUnitParams)

const Wrapper = styled.div``
