// @flow
import { kea } from 'kea'
import axios from 'axios'
import { put, call, delay } from 'redux-saga/effects'
import { API_BASE_URL } from 'config'
import { generateUrl, getFormValues } from 'utils'
import {
  COMMANDS_PARAMS,
  DEFAULT_COMMAND,
  DEFAULT_COMMAND_GERMANY,
  processCommandParams,
  getOperModeTimeLeft,
  loadCommandInfo,
  checkDefaultMode
} from './utils'
import { mapValues, pick } from 'lodash'

import PropTypes from 'prop-types'
import * as Check from 'validations'

import AppLogic from 'containers/App/logic'
import DashboardLogic from 'scenes/DashboardMenu/Dashboard/logic'

const DEVICE_COMMAND_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/command`
const DEVICE_SETTINGS_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/settings`

export const DEFAULT_VALUES = {
  command: {
    value: DEFAULT_COMMAND
  },
  soc: {
    value: 0
  },
  power: {
    value: 3
  },
  maximumPower: {
    value: 0
  },
  powerAllocation: {
    value: 0
  },
  optimalSoc: {
    value: 0
  },
  maxHousePeakConsumption: {
    value: 0
  },
  duration: {
    value: 1
  }
}

const VALIDATIONS = {}

export default kea({
  path: () => ['scenes', 'containers', 'OperationModeForm'],

  connect: {
    props: [
      AppLogic,
      ['isMobile', 'translations', 'currentDeviceId', 'language', 'isGermany'],
      DashboardLogic,
      ['deviceStatus']
    ],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    setOperationMode: mode => ({ mode }),
    updateDeviceStatus: () => true,
    getDeviceStatus: () => true,
    fetchDefaultMode: () => true,
    setDefaultMode: mode => ({ mode }),

    change: field => ({ field }),
    submit: () => true,
    setError: error => error,
    setForm: form => ({ form }),
    reset: () => false
  }),

  reducers: ({ actions }) => ({
    operationMode: [
      {},
      PropTypes.object,
      {
        [actions.setOperationMode]: (state, payload) => payload.mode || {},
        [actions.reset]: () => ({})
      }
    ],

    defaultMode: [
      {},
      PropTypes.object,
      {
        [actions.setDefaultMode]: (state, payload) => payload.mode || {},
        [actions.reset]: () => ({})
      }
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.setDefaultMode]: () => false,
        [actions.submit]: () => true,
        [actions.setError]: () => false,
        [actions.reset]: () => true
      }
    ],

    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.submit]: () => false,
        [actions.setError]: () => true,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    isManualMode: [
      () => [selectors.operationMode],
      (operationMode: any) => operationMode.type === 'MANUAL',
      PropTypes.bool
    ],

    isDefaultMode: [
      () => [selectors.operationMode],
      (operationMode: any) => operationMode.type === 'DEFAULT',
      PropTypes.bool
    ],

    isScheduleMode: [
      () => [selectors.operationMode],
      (operationMode: any) => operationMode.type === 'SCHEDULE',
      PropTypes.bool
    ],

    timeLeft: [
      () => [selectors.operationMode],
      (operationMode: any) => getOperModeTimeLeft(operationMode.endTime),
      PropTypes.object
    ]
  }),

  start: function * () {
    const { getDeviceStatus } = this.actions

    yield put(getDeviceStatus())
  },

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getDeviceStatus]: workers.getDeviceStatus,
    [actions.setOperationMode]: workers.fetchDefaultMode,
    [actions.setDefaultMode]: function * () {
      const form = yield this.get('form')
      const defaultMode = yield this.get('defaultMode')
      const { change } = this.actions

      if (checkDefaultMode(defaultMode, form)) {
        yield put(change({ command: 'DEFAULT' }))
      }
    },
    [actions.submit]: workers.submit,
    [actions.change]: workers.onFormChange,
    [actions.updateDeviceStatus]: function * ({ payload }) {
      yield put(DashboardLogic.actions.updateDeviceStatus())
    }
  }),

  workers: {
    * submit () {
      const { setError, setForm, updateDeviceStatus, navigate } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')
      const translations = yield this.get('translations')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      if (!dirty && validation.invalid) {
        yield put(setForm(validation.form))
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)

      let data = pick(params, ['command', 'duration'])

      if (data.command === 'DEFAULT') {
        const defaultMode = yield this.get('defaultMode')
        data.command = defaultMode.command
      }
      data.parameters = pick(params, COMMANDS_PARAMS[data.command])
      data = processCommandParams(data)

      try {
        const isMobile = yield this.get('isMobile')
        const currentDeviceId = yield this.get('currentDeviceId')

        const url = generateUrl(DEVICE_COMMAND_ENDPOINT, {
          id: currentDeviceId
        })
        yield call(axios.post, url, data)

        if (!isMobile) {
          yield put(updateDeviceStatus())
        } else {
          yield put(navigate(`/${currentDeviceId}/dashboard/`))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    * getDeviceStatus () {
      const { getDeviceStatus, setOperationMode, change } = this.actions

      const deviceStatus = yield this.get('deviceStatus')
      const isGermany = yield this.get('isGermany')

      if (!deviceStatus || Object.keys(deviceStatus).length === 0) {
        yield delay(1000)
        yield put(getDeviceStatus())
        return false
      }

      const { currentMode } = deviceStatus

      if (Object.keys(currentMode).length > 0) {
        yield put(setOperationMode(currentMode))

        const { command, parameters, duration } = currentMode || {}

        let operationModeCommand = command

        if (parameters && Object.keys(parameters).length > 0) {
          if (command === 'SET_CHARGE') {
            operationModeCommand =
              parameters.action === 'ACTION_CHARGE'
                ? 'SET_CHARGE'
                : 'SET_DISCHARGE'
          }

          const parametersChanges = {
            soc: parameters.soc || 0,
            power: parameters.power || 3,
            maximumPower: parameters.maximumPower || 0,
            powerAllocation: parameters.powerAllocation || 0,
            optimalSoc: parameters.optimalSoc || 0,
            maxHousePeakConsumption: parameters.maxHousePeakConsumption || 0
          }

          yield put(change(parametersChanges))
        }

        yield put(
          change({
            command: isGermany ? DEFAULT_COMMAND_GERMANY : operationModeCommand,
            duration: duration || 1
          })
        )
      }
    },

    * fetchDefaultMode () {
      const { setDefaultMode } = this.actions

      // if defaultMode exists dont do the request
      const defaultMode = yield this.get('defaultMode')

      if (defaultMode.command) {
        yield put(setDefaultMode(defaultMode))

        return false
      }

      try {
        const currentDeviceId = yield this.get('currentDeviceId')

        const url = generateUrl(DEVICE_SETTINGS_ENDPOINT, {
          id: currentDeviceId
        })
        const response = yield call(axios.get, url)
        const statusResult = response.data
        const { result, successful } = statusResult

        if (successful) {
          yield put(setDefaultMode(result.defaultMode))
        }
      } catch (catchError) {
        console.log(catchError)
      }
    },

    /**
     * function that checks if the operation mode changed for
     * default mode option, if is changed then put the defined
     * values on default mode
     *
     */
    * onFormChange () {
      const { setForm } = this.actions

      const form = yield this.get('form')
      const params = mapValues(form, ({ value }) => value)

      if (params.command === 'DEFAULT') {
        const defaultMode = yield this.get('defaultMode')
        let formInfo = {
          ...params,
          ...defaultMode.parameters
        }
        formInfo = loadCommandInfo(formInfo)
        const info = getFormValues(DEFAULT_VALUES, formInfo)
        yield put(setForm(info))
      }
    }
  }
})
