// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

type Props = {
}

class ExternalAppAccepted extends Component<Props> {
  render () {
    const { appInfo, translations } = this.props
    const {
      scope,
      appName,
      organization
    } = appInfo

    const { EXTAPP_ACCEPTEDAPP } = translations

    const acceptedappdesc =
      EXTAPP_ACCEPTEDAPP.replace(
        '<PERMISSION_LEVEL>',
        translations[`EXTAPP_${scope}`]
      )

    return (
      <Wrapper>
        <Title>{`${appName}, ${organization}®`}</Title>
        <Typography variant={'body1'} color={'textPrimary'}>
          {acceptedappdesc}
        </Typography>
      </Wrapper>
    )
  }
}

export default Logic(ExternalAppAccepted)

const Wrapper = styled.div``

const Title = styled.h1`
  color: #007bc1;
  font-family: Eaton;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
  text-align: center;
`
