// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

import SlidePanel from 'components/SlidePanel'
import UnitAccessPage from 'containers/UnitAccessPage'
import UnitsSearchPage from 'containers/UnitsSearchPage'
import UnitGrantedAccess from 'containers/UnitGrantedAccess'
import UnitPendingAccess from 'containers/UnitPendingAccess'

import {
  PageTitle,
  Switcher,
  RoundedButton,
  Button,
  Alert,
  Loading
} from 'xstorage-components'

type Props = {
  isMobile: boolean
}

export class TechnicianUnits extends Component<Props, void> {
  render () {
    const {
      isMobile,
      translations,
      switcherSelected,
      panelToShow,
      totalPendingUnits,
      totalGrantedUnits,
      grantedUnits,
      pendingUnits,
      unitToShow,
      pendingUnitsLength,
      grantedUnitsLength,
      loadingButton,
      showLoadMoreInfo,
      loading
    } = this.props

    const {
      TECH_UNIT_GRANTED_ACCESS,
      TECH_UNIT_PENDING_ACCESS,
      TECH_UNITS_MY_UNITS,
      TECH_UNIT_SEARCH_FOR_XSTORAGE_UNIT,
      TECH_UNIT_LOAD_MORE_BUTTON,
      TECH_UNIT_NO_MORE_RESULTS,
      TECH_UNITS_LOADING,
      TECH_UNITS_PENDING,
      TECH_UNITS_GRANTED
    } = translations

    const {
      navigate,
      setSwitcherSelected,
      setPanelToShow,
      showUnitPanel,
      fetchPendingUnits,
      fetchGrantedUnits
    } = this.actions

    const switcherItems = [
      { slug: 'pending', name: TECH_UNIT_PENDING_ACCESS },
      { slug: 'granted', name: TECH_UNIT_GRANTED_ACCESS }
    ]

    return (
      <Wrapper mobile={isMobile}>
        {loading && <Loading />}
        <HeaderSection mobile={isMobile}>
          <PageTitle id={'Units_Title'} mobile={isMobile}>
            {TECH_UNITS_MY_UNITS}
          </PageTitle>
          <PageSubTitle>
            <Icon>{'schedule'}</Icon>
            <span>
              {totalPendingUnits} {TECH_UNITS_PENDING}
            </span>
            <span>{'|'}</span>
            <Icon>{'check'}</Icon>
            <span>
              {totalGrantedUnits} {TECH_UNITS_GRANTED}
            </span>
          </PageSubTitle>
          <Switcher
            items={switcherItems}
            selected={switcherSelected}
            onChange={selected => setSwitcherSelected(selected)}
          />
        </HeaderSection>
        <BodySection mobile={isMobile}>
          <SwitcherContainer
            mobile={isMobile}
            visible={switcherSelected === 'pending'}
          >
            {switcherSelected === 'pending' && (
              <UnitPendingAccess
                showUnitPanel={showUnitPanel}
                pendingUnits={pendingUnits}
              />
            )}
            {showLoadMoreInfo && loadingButton && (
              <LoadMoreDiv mobile={isMobile}>
                <Button
                  id={'SecondaryButton_Loading'}
                  buttonType={'secondary-default'}
                  buttonWidth={'200px'}
                  smallShadow
                >
                  <Icon>{'autorenew'}</Icon>
                  {TECH_UNITS_LOADING}
                </Button>
              </LoadMoreDiv>
            )}
            {showLoadMoreInfo && !loadingButton && (
              <React.Fragment>
                {totalPendingUnits > pendingUnitsLength && (
                  <LoadMoreDiv mobile={isMobile}>
                    <Button
                      id={'SecondaryButton_LoadMore'}
                      buttonType={'secondary-default'}
                      buttonWidth={'200px'}
                      smallShadow
                      onClick={fetchPendingUnits}
                    >
                      <Icon>{'add'}</Icon>
                      {TECH_UNIT_LOAD_MORE_BUTTON}
                    </Button>
                  </LoadMoreDiv>
                )}
                {totalPendingUnits === pendingUnitsLength && (
                  <LoadMoreDiv mobile={isMobile}>
                    <Alert
                      icon={<Icon>{'error'}</Icon>}
                      message={TECH_UNIT_NO_MORE_RESULTS}
                    />
                  </LoadMoreDiv>
                )}
              </React.Fragment>
            )}
          </SwitcherContainer>
          <SwitcherContainer
            mobile={isMobile}
            visible={switcherSelected === 'granted'}
          >
            <UnitGrantedAccess grantedUnits={grantedUnits} />
            {showLoadMoreInfo && loadingButton && (
              <LoadMoreDiv mobile={isMobile}>
                <Button
                  id={'SecondaryButton_Loading'}
                  buttonType={'secondary-default'}
                  buttonWidth={'200px'}
                  smallShadow
                >
                  <Icon>{'autorenew'}</Icon>
                  {TECH_UNITS_LOADING}
                </Button>
              </LoadMoreDiv>
            )}
            {showLoadMoreInfo && !loadingButton && (
              <React.Fragment>
                {totalGrantedUnits > grantedUnitsLength && (
                  <LoadMoreDiv mobile={isMobile}>
                    <Button
                      id={'SecondaryButton_LoadMore'}
                      buttonType={'secondary-default'}
                      buttonWidth={'200px'}
                      smallShadow
                      onClick={fetchGrantedUnits}
                    >
                      <Icon>{'add'}</Icon>
                      {TECH_UNIT_LOAD_MORE_BUTTON}
                    </Button>
                  </LoadMoreDiv>
                )}
                {totalGrantedUnits === grantedUnitsLength && (
                  <LoadMoreDiv mobile={isMobile}>
                    <Alert
                      icon={<Icon>{'error'}</Icon>}
                      message={TECH_UNIT_NO_MORE_RESULTS}
                    />
                  </LoadMoreDiv>
                )}
              </React.Fragment>
            )}
          </SwitcherContainer>
        </BodySection>
        <SearchButton
          id={'Search_Button'}
          onClick={() =>
            !isMobile ? setPanelToShow('search') : navigate('/units-search/')
          }
          mobile={isMobile}
        >
          <Icon>{'search'}</Icon>
        </SearchButton>
        {!isMobile && (
          <div>
            <SlidePanel
              overlay
              type={'content-not-padded'}
              open={panelToShow === 'search'}
              onClose={() => setPanelToShow('')}
              title={TECH_UNIT_SEARCH_FOR_XSTORAGE_UNIT}
            >
              <UnitsSearchPage />
            </SlidePanel>
          </div>
        )}
        <SlidePanel
          overlay
          type={'content-not-padded'}
          open={panelToShow === 'unit'}
          onClose={() => setPanelToShow('')}
          fixed={panelToShow === 'unit'}
          title={
            (unitToShow.device && unitToShow.device.inverterSerialNumber) ||
            null
          }
          mobile={isMobile}
        >
          <UnitAccessPage unit={unitToShow} />
        </SlidePanel>
      </Wrapper>
    )
  }
}

export default Logic(TechnicianUnits)

const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const HeaderSection = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    background-color: #007BC1;
    padding: 20px 25px 4px 25px;
  `}
`

const BodySection = styled.div`
  ${({ mobile }) =>
    mobile &&
    `
    @media screen and (max-width: 580px) {
      border-width: 18vw 100vw 0 0;
    }
    
    border-width: 50px 500px 0 0;
    border-color: #007bc1 #FFFFFF transparent transparent;
    display: inline-block;
    width: 0px;
    border-style: solid;
  `}
`

const SwitcherContainer = styled.div`
  display: none;
  position: relative;
  z-index: 10;

  ${({ mobile }) =>
    !mobile &&
    `
    margin-top: 18px;
  `}

  ${({ visible }) =>
    visible &&
    `
    animation: show 1s;
    display: block;
  `}

  @keyframes show {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      display: block !important;
    }
  }
`

const PageSubTitle = styled.h3`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  span.material-icons {
    margin-right: 8px;
  }

  span:nth-child(3) {
    margin: 0px 16px;
  }
`

const SearchButton = styled(props => <RoundedButton {...props} />)`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
  padding: 14px;

  @media screen and (max-width: 1024px) {
    bottom: 80px;
    right: 30px;
  }

  ${({ mobile }) =>
    !mobile &&
    `
    top: 120px;
    bottom: auto;
    right: 20px;
  `}

  span.material-icons {
    font-size: 28px!important;
  }
`

const LoadMoreDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 31px;
  margin-bottom: 31px;

  ${({ mobile }) =>
    mobile &&
    `
    width: 100vw;
  `}
`
