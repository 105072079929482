// @flow
import { type Eula, type User } from 'types/api'
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Grid, Icon, Typography } from '@material-ui/core'
import { convertEpochToDate, convertMillisToSec } from 'utils'

import {
  PageTitle,
  Loading,
  BackButton,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  List,
  Divider,
  Paper,
  ModalButtons
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  currentEula: Eula,
  user: User,
  userValid: boolean,
  loading: boolean,
  error: any
}

type State = {
  confirmModal: boolean
}

class TermsAndEula extends Component<Props, State> {
  actions: any

  state = {
    confirmModal: false
  }

  render () {
    const {
      isMobile,
      currentEula,
      user,
      userValid,
      loading,
      error,
      translations,
      userIsTechnician
    } = this.props

    const { navigate, acceptCurrentEula } = this.actions

    const { confirmModal } = this.state

    const {
      EULA_TITLE,
      EULA_LAST_UPDATE,
      EULA_LAST_UPDATE_2,
      EULA_ACCEPT_BUTTON,
      EULA_REVOKE_BUTTON,
      EULA_REVOKE_MODAL_TITLE,
      EULA_REVOKE_MODAL_DESC_1,
      EULA_REVOKE_MODAL_DESC_2,
      EULA_REVOKE_MODAL_CANCEL,
      EULA_REVOKE_MODAL_PROCEED
    } = translations

    const updated = convertMillisToSec(parseInt(currentEula.updatedAt))
    const showAcceptance =
      !user || (user && user.eulaVersion !== currentEula.version)
    const ready = !loading && !error
    /**
     * List of buttons to show in the eulas page,
     * when accessed through the user dropdown menu option
     */
    const revokeSection = ready && userValid && (
      <Fragment>
        <InfoText paragraph>
          <b>{EULA_LAST_UPDATE}</b> {convertEpochToDate(updated)}
        </InfoText>
        <List>
          <List.Item
            disabled={userIsTechnician}
            button
            onClick={() => this.setState({ confirmModal: true })}
          >
            <Icon style={{ color: '#CA3C3D' }}>{'close'}</Icon>
            <RevokeText primary={EULA_REVOKE_BUTTON} />
          </List.Item>
          <Divider variant={'inset'} component='li' />
        </List>
      </Fragment>
    )

    /**
     * Buttons to show on the acceptance page,
     * when the user login and the terms are not accepted
     */
    const acceptance = ready && showAcceptance && (
      <AcceptanceSection>
        <Button
          id={'PrimaryButton_Accept'}
          buttonWidth={'280px'}
          smallShadow
          onClick={acceptCurrentEula}
        >
          <Icon>{'check'}</Icon>
          {EULA_ACCEPT_BUTTON}
        </Button>
        <RevokeButton
          buttonType={'secondary-default'}
          onClick={() => this.setState({ confirmModal: true })}
          disabled={userIsTechnician}
        >
          {EULA_REVOKE_BUTTON}
        </RevokeButton>
      </AcceptanceSection>
    )

    const title = (
      <PageTitle
        mobile={isMobile}
        blue={isMobile || !userValid}
        marginBottom={'26px'}
      >
        {!isMobile && userValid && (
          <BackButton
            color={'white'}
            buttonMargin={'0 15px 0 0'}
            id={'BackButton'}
            onClick={() => navigate('/terms/')}
          />
        )}
        {EULA_TITLE}
      </PageTitle>
    )

    const updatedInfo = !userValid && (
      <UpdatedText paragraph>
        {EULA_LAST_UPDATE_2} {convertEpochToDate(updated, 'D of MMMM, YYYY')}
      </UpdatedText>
    )

    return (
      <Wrapper>
        {loading && <Loading />}
        {isMobile && userValid && (
          <BackButton id={'BackButton'} onClick={() => navigate('/terms/')} />
        )}
        {isMobile && (
          <Fragment>
            {title}
            <MobileContent>
              {updatedInfo}
              <MobileContentText>
                <Typography
                  dangerouslySetInnerHTML={{ __html: currentEula.text }}
                />
              </MobileContentText>
              {acceptance}
              {revokeSection}
            </MobileContent>
          </Fragment>
        )}
        {!isMobile && (
          <Fragment>
            {userValid && title}
            <Grid container spacing={3} justify={'center'}>
              <Grid item xs={8}>
                <Paper>
                  {!userValid && (
                    <Fragment>
                      {title}
                      {updatedInfo}
                    </Fragment>
                  )}
                  <Typography
                    dangerouslySetInnerHTML={{ __html: currentEula.text }}
                  />
                  {acceptance}
                </Paper>
              </Grid>
              {userValid && (
                <Grid item xs={4}>
                  {!loading && !error && revokeSection}
                </Grid>
              )}
            </Grid>
          </Fragment>
        )}
        {confirmModal && (
          <Modal
            open={confirmModal}
            size={'small'}
            onClose={() => this.setState({ confirmModal: false })}
          >
            {/* TODO: maybe we can create a common container for this */}
            <div>
              <ModalHeader red title={EULA_REVOKE_MODAL_TITLE} />
              <ModalContent padding size={'small'} mobile={isMobile}>
                <Typography>
                  <b>{EULA_REVOKE_MODAL_DESC_1}</b>
                </Typography>
                <Typography>{EULA_REVOKE_MODAL_DESC_2}</Typography>
              </ModalContent>
              <ModalButtons>
                <Button
                  id={'PrimaryButton_Cancel'}
                  buttonType={'primary-cancel'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={() => this.setState({ confirmModal: false })}
                >
                  {EULA_REVOKE_MODAL_CANCEL}
                </Button>
                <Button
                  id={'PrimaryButton_Proceed'}
                  buttonType={'primary-danger'}
                  margin={'0 12px'}
                  buttonWidth={'156px'}
                  onClick={() => navigate('/user-profile/delete/')}
                >
                  {EULA_REVOKE_MODAL_PROCEED}
                </Button>
              </ModalButtons>
            </div>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default Logic(TermsAndEula)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const InfoText = styled(props => <Typography {...props} />)`
  &&&& {
    color: #727e84;
  }
`

const RevokeText = styled(props => <List.ItemText {...props} />)`
  &&&& {
    span {
      color: #ca3c3d !important;
    }
  }
`

const MobileContent = styled.div``

const RevokeButton = styled.button`
  border-radius: 20px;
  border: 0px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans';
  text-align: center;
  display: inline-block;
  padding: 5px 20px;
  background-color: #fdfdfd;
  color: #ca3c3d;
  display: block;
  margin: 10px auto;
  text-align: center;

  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
  `}
`

const MobileContentText = styled.div`
  margin-bottom: 40px;

  > p > h1 {
    line-height: 1.5em;
  }
`

const UpdatedText = styled(props => <Typography {...props} />)`
  &&&& {
    color: #ca3c3d;
    font-weight: bold;
  }
`

const AcceptanceSection = styled.div`
  margin-top: 50px;
  text-align: center;
`
