/* eslint-disable max-len */
// @flow
import React, { Fragment, Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { convertEpochToDate, convertMillisToSec } from 'utils'

import {
  Button,
  Typography,
  UnitDetails,
  Paper,
  SearchBar
} from 'xstorage-components'

type Props = {
  unitSearch: string,
  searchedUnit: {
    createdAt: number
  },
  searched: boolean,
  translations: {
    TECH_UNITS_SEARCH_PLACEHOLDER: string,
    TECH_UNITS_UNIT_INV_SERIAL_NUMBER: string,
    TECH_UNITS_SEARCH_NO_ACCESS_TO_UNIT: string,
    TECH_UNITS_SEARCH_REQUEST_UNIT_ACCESS: string,
    TECH_UNITS_SEARCH_REQUEST_ACCESS_BUTTON: string,
    TECH_UNITS_SEARCH_NO_UNITS_FOUND: string
  },
  isMobile: boolean,
  error: string
}

class UnitsSearchPage extends Component<Props> {
  actions: {
    setValue: Function,
    performSearch: Function,
    requestAccessToUnit: Function,
    goToDashboard: Function,
    goToPendingUnitDetails: Function,
    clearSearch: Function
  }

  render () {
    const {
      unitSearch,
      searchedUnit,
      searched,
      translations,
      isMobile,
      error
    } = this.props

    const {
      TECH_UNITS_SEARCH_PLACEHOLDER,
      TECH_UNITS_UNIT_INV_SERIAL_NUMBER,
      TECH_UNITS_SEARCH_NO_ACCESS_TO_UNIT,
      TECH_UNITS_SEARCH_REQUEST_UNIT_ACCESS,
      TECH_UNITS_SEARCH_REQUEST_ACCESS_BUTTON,
      TECH_UNITS_SEARCH_NO_UNITS_FOUND
    } = translations

    const {
      setValue,
      performSearch,
      requestAccessToUnit,
      goToDashboard,
      goToPendingUnitDetails,
      clearSearch
    } = this.actions

    const emptySearchedUnit = Object.keys(searchedUnit).length === 0
    const createdDate = convertEpochToDate(
      convertMillisToSec(searchedUnit.createdAt),
      'DD/MM/YYYY HH:mm'
    )

    return (
      <Wrapper>
        <SearchBar
          placeholder={TECH_UNITS_SEARCH_PLACEHOLDER}
          label={TECH_UNITS_UNIT_INV_SERIAL_NUMBER}
          value={unitSearch}
          onChange={value => setValue(value)}
          onSearch={performSearch}
          clearSearch={clearSearch}
        />
        <UnitsContainer mobile={isMobile}>
          {searched && !error && (
            <Fragment>
              {!emptySearchedUnit && searchedUnit.asAccess && (
                <Paper shadow>
                  <UnitDetails
                    name={unitSearch}
                    user={
                      searchedUnit.owner
                        ? `${searchedUnit.owner.firstName} ${searchedUnit.owner.lastName}`
                        : '-'
                    }
                    date={createdDate}
                    icon={<Icon>{'arrow_forward'}</Icon>}
                    onClick={() => goToDashboard(searchedUnit)}
                  />
                </Paper>
              )}
              {!emptySearchedUnit && !searchedUnit.asAccess && (
                <WithoutResults>
                  <WithoutAccessText paragraph>
                    {TECH_UNITS_SEARCH_NO_ACCESS_TO_UNIT} {unitSearch}
                  </WithoutAccessText>
                  <WithoutAccessSubtext paragraph>
                    {TECH_UNITS_SEARCH_REQUEST_UNIT_ACCESS}
                  </WithoutAccessSubtext>
                  <Button
                    id={'RequestAccess_Button'}
                    buttonWidth={'240px'}
                    onClick={requestAccessToUnit}
                  >
                    {TECH_UNITS_SEARCH_REQUEST_ACCESS_BUTTON}
                  </Button>
                </WithoutResults>
              )}
              {emptySearchedUnit && (
                <WithoutResults>
                  <WithoutAccessText paragraph>
                    {TECH_UNITS_SEARCH_NO_UNITS_FOUND} {unitSearch}
                  </WithoutAccessText>
                </WithoutResults>
              )}
            </Fragment>
          )}
          {error && (
            <React.Fragment>
              <ErrorMessage paddingBottom={'1rem'}>{error}</ErrorMessage>
              <Paper shadow>
                <UnitDetails
                  name={unitSearch}
                  date={convertEpochToDate(
                    convertMillisToSec(searchedUnit.createdAt),
                    'DD/MM/YYYY HH:mm'
                  )}
                  icon={<Icon>{'more_horiz'}</Icon>}
                  onClick={goToPendingUnitDetails}
                />
              </Paper>
            </React.Fragment>
          )}
        </UnitsContainer>
      </Wrapper>
    )
  }
}

export default Logic(UnitsSearchPage)

const Wrapper = styled.div`
  padding-top: 46px;
`

const UnitsContainer = styled.div`
  width: 100%;
  box-shadow: inset 0 1px 0 0 rgba(224, 238, 248, 0.4);
  background-color: #f9fbfd;
  min-height: 100vh;
  padding: 16px;

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    padding: 16px 50px 16px;
  }
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 320px);
  }

  ${({ mobile }) =>
    mobile &&
    `
    background-color: #FFFFFF;
  `}
`

const WithoutResults = styled.div`
  text-align: center;
`

const WithoutAccessText = styled(props => <Typography {...props} />)`
  &&&& {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #007bc1;
  }
`

const WithoutAccessSubtext = styled(props => <Typography {...props} />)`
  &&&& {
    font-size: 14px;
    line-height: 20px;
    color: #727e84;
  }
`

const ErrorMessage = styled.p`
  color: #ca3c3d;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
`
