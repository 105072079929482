// @flow
import moment from 'moment-timezone'
type TRANSLATIONS = {
  UNITSETT_SUPPORT_METRICS_DAY_AGO: string,
  UNITSETT_SUPPORT_METRICS_DAYS_AGO: string,
  UNITSETT_SUPPORT_METRICS_HOUR_AGO: string,
  UNITSETT_SUPPORT_METRICS_HOURS_AGO: string,
  NOTIFICATIONS_JUST_NOW: string,
  NOTIFICATIONS_MINUTE_AGO: string,
  NOTIFICATIONS_MINUTES_AGO: string
}

export const getDateDiff = (syncdate: number, translations: TRANSLATIONS) => {
  let syncDateDiff = ''
  const duration = moment.duration(moment().diff(moment(syncdate)))
  const isToday = moment().isSame(moment(syncdate), 'd')

  if (!isToday) {
    const durationDays = duration.days()
    const durationMonths = duration.months()

    if (durationMonths === 0 && durationDays === 0) {
      syncDateDiff = translations['UNITSETT_SUPPORT_METRICS_DAY_AGO'].replace(
        'NUMBER_DAY',
        durationDays + 1
      )
    } else if (durationMonths === 0 && durationDays >= 1 && durationDays < 10) {
      syncDateDiff = translations['UNITSETT_SUPPORT_METRICS_DAYS_AGO'].replace(
        'NUMBER_DAY',
        durationDays
      )
    } else {
      syncDateDiff = null
    }
  } else {
    const durationHours = duration.hours()

    if (durationHours === 1) {
      syncDateDiff = translations['UNITSETT_SUPPORT_METRICS_HOUR_AGO'].replace(
        'NUMBER_HOUR',
        durationHours
      )
    } else if (durationHours > 1) {
      syncDateDiff = translations['UNITSETT_SUPPORT_METRICS_HOURS_AGO'].replace(
        'NUMBER_HOUR',
        durationHours
      )
    } else {
      const durationMinutes = duration.minutes()

      if (durationMinutes === 0) {
        syncDateDiff = `${translations['NOTIFICATIONS_JUST_NOW']}`
      } else if (durationMinutes === 1) {
        syncDateDiff = `${translations['NOTIFICATIONS_MINUTE_AGO']}`
      } else {
        syncDateDiff = `${translations['NOTIFICATIONS_MINUTES_AGO'].replace(
          'NUMBER_MIN',
          durationMinutes
        )}`
      }
    }
  }
  return syncDateDiff
}
