// @flow
import { type User } from 'types/api'

import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import DeviceChooserMobile from 'containers/DeviceChooserMobile'

import {
  PageTitle,
  MoreNavItem,
  IcDocument,
  IcFloater,
  IcScrewdriver
} from 'xstorage-components'

type Props = {
  user: User,
  translations: any
}

type State = {
  logoutFixed: boolean
}

export class More extends Component<Props, State> {
  actions: any
  logoutRef: any
  lastItemRef: any
  onResize: Function

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize, false)
  }

  componentDidMount () {
    // this.updateLogoutPosition()
    window.addEventListener('resize', this.onResize, false)
  }

  render () {
    const {
      user,
      translations,
      userIsTechnician,
      unreadNotifications,
      unitRelatedPage,
      userCanViewDeviceChooser,
      userCanViewProfile,
      currentDeviceId
    } = this.props

    const { logout, navigate, setShowHelpTour } = this.actions

    const {
      MORE_TITLE,
      MORE_NAV_NOTIFICATIONS,
      MORE_NAV_PROFILE,
      MORE_NAV_TERMS,
      MORE_NAV_LOGOUT,
      MORE_MAINTENANCE,
      USERMENU_VIEWTOUR
    } = translations

    return (
      <Wrapper>
        <PageTitle mobile blue>
          {MORE_TITLE}
        </PageTitle>
        {userCanViewDeviceChooser && <DeviceChooserMobile />}
        <MoreNav>
          <MoreNavItem
            id={'Notifications'}
            icon={
              <NavIcon
                unread={unreadNotifications ? 1 : 0}
              >{'notifications'}</NavIcon>
            }
            title={MORE_NAV_NOTIFICATIONS}
            onClick={() =>
              navigate(
                userIsTechnician && unitRelatedPage
                  ? `/${currentDeviceId}/notifications/`
                  : '/notifications/'
              )
            }
            link
          />
          {userIsTechnician && unitRelatedPage && (
            <MoreNavItem
              id={'Maintenance'}
              icon={<IcScrewdriver />}
              title={MORE_MAINTENANCE}
              onClick={() =>
                navigate(`/${currentDeviceId}/maintenance/`)
              }
              link
            />
          )}
          {!userIsTechnician &&
            currentDeviceId &&
            currentDeviceId !== 'null' && (
            <MoreNavItem
              id={'ViewHelpTour'}
              icon={<IcFloater />}
              title={USERMENU_VIEWTOUR}
              onClick={() => setShowHelpTour(true)}
              link
            />
          )}
          {userCanViewProfile && (
            <React.Fragment>
              <MoreNavItem
                id={'UserProfile'}
                icon={<NavIcon>{'account_circle'}</NavIcon>}
                title={MORE_NAV_PROFILE}
                onClick={() => navigate('/user-profile/')}
                link
              />
              <MoreNavItem
                id={'Terms'}
                icon={<IcDocument />}
                title={MORE_NAV_TERMS}
                ref={el => {
                  this.lastItemRef = el
                }}
                onClick={() => navigate('/terms/')}
                link
              />
            </React.Fragment>
          )}
        </MoreNav>
        <MoreNavItem
          id={'Logout'}
          logoutItem
          icon={<LogoutIcon>power_settings_new</LogoutIcon>}
          title={MORE_NAV_LOGOUT}
          description={user && `${user.firstName} ${user.lastName}`}
          onClick={() => logout()}
        />
      </Wrapper>
    )
  }
}

export default Logic(More)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;
  height: 100%;
  position: relative;
  padding-bottom: 56px;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }
`

const MoreNav = styled.div`
  position: relative;

  > div:before {
    content: "";
    position: absolute;
    width: calc(100% - 20px);
    height: 56px;
    box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);

    @media screen and (min-width: 550px) and (max-width: 1024px) {
      width: calc(100% - 50px);
    }
  }
`

const NavIcon = styled(Icon)`
  color: #007bc1;

  ${({ unread }) =>
    unread &&
    `
    &:after{
      position: absolute;
      content: '';
      left: 14px;
      top: 20px;
      height: 8px;
      width: 8px;
      background-color: #CA3C3D;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
    }
  `}
`

const LogoutIcon = styled(Icon)`
  color: #ca3c3d;
`
