/* eslint-disable max-len */
// @flow
import { type DeviceRole } from 'types/api'
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { getUserRoleLabel } from './utils'
import { convertEpochToDate, convertMillisToSec } from 'utils'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
import PageAdaptable from 'containers/PageAdaptable'
import UserPermission from 'containers/UserPermission'

import {
  PageTitle,
  Typography,
  MobileActionButton,
  RoundedButton,
  PageMainSection,
  FixedTopBar
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  invites: Array<DeviceRole>,
  loading: boolean,
  showBackBar: boolean
}

export class DeviceUsers extends Component<Props, void> {
  actions: any

  hideBar = () => {
    const { isMobile } = this.props
    const { setFixNavbar } = this.actions
    if (isMobile && window.scrollY > 50) {
      setFixNavbar(true)
    } else {
      setFixNavbar(false)
    }
  }

  componentDidMount () {
    const { isMobile } = this.props

    if (isMobile) {
      window.addEventListener('scroll', this.hideBar)
    }
  }

  componentWillUnmount () {
    const { isMobile } = this.props

    if (isMobile) {
      window.removeEventListener('scroll', this.hideBar)
    }
  }

  render () {
    const {
      isMobile,
      translations,
      invites,
      loading,
      fixNavbar,
      currentDeviceId,
      userCanAddUser,
      showBackBar
    } = this.props

    const { navigate } = this.actions

    const {
      UNITSETT_TITLE,
      UNITSETT_USERSPERM_TITLE,
      UNITSETT_USERSPERM_NUMBER_RESULTS,
      UNITSETT_USERSPERM_RESULTS_OF,
      UNITSETT_USERSPERM_NO_USERS_INVITED,
      UNITSETT_USERSPERM_INVITE_TITLE,
      UNITSETT_USERSPERM_STATUS_PENDING,
      API_ERROR_REACHED_MAX_DEVICE_INVITES,
      UNITSETT_USERSPERM_TECH_PENDING_REQUEST,
      UNITSETT_USERSPERM_STATUS_EXPIRED,
      UNITSETT_USERSPERM_ACCESS_EXPIRES_IN,
      UNITSETT_USERSPERM_ACCESS_NEVER_EXPIRES
    } = translations

    return (
      <Wrapper>
        <PageAdaptable
          title={UNITSETT_TITLE}
          menu={<DeviceSettingsMenu page={'users-permissions'} />}
          onBack={() => navigate(`/${currentDeviceId}/settings/`)}
          isForm={false}
          loading={loading}
        >
          <PageMainSection padding={!isMobile} mobile={isMobile}>
            {isMobile && (
              <UserPermission permission={'ADD_USER_INVITE'}>
                <MobileActionButton
                  mobile
                  icon={<Icon>{'add'}</Icon>}
                  onClick={() =>
                    navigate(`/${currentDeviceId}/settings/users/new/`)
                  }
                  disabled={invites.length === 10}
                  title={
                    invites.length === 10
                      ? API_ERROR_REACHED_MAX_DEVICE_INVITES
                      : ''
                  }
                  id={'MobileActionButton_Invite'}
                >
                  {UNITSETT_USERSPERM_INVITE_TITLE}
                </MobileActionButton>
              </UserPermission>
            )}

            {fixNavbar && (
              <FixedTopBar
                backArrowClick={() => navigate(`/${currentDeviceId}/settings/`)}
                pageTitle={UNITSETT_USERSPERM_TITLE}
                actionButtonIcon={userCanAddUser ? <Icon>{'add'}</Icon> : null}
                actionButtonTitle={userCanAddUser
                  ? UNITSETT_USERSPERM_INVITE_TITLE
                  : null
                }
                actionButtonClick={() =>
                  userCanAddUser
                    ? navigate(`/${currentDeviceId}/settings/users/new/`)
                    : null
                }
                isTechnician={showBackBar}
              />
            )}
            <PageTitle mobile={isMobile} marginBottom={'32px'}>
              {UNITSETT_USERSPERM_TITLE}
            </PageTitle>
            {!!invites.length && (
              <NumberOfUsers>
                {UNITSETT_USERSPERM_NUMBER_RESULTS} {invites.length}{' '}
                {UNITSETT_USERSPERM_RESULTS_OF} 10
              </NumberOfUsers>
            )}
            {!invites.length && !loading && (
              <Typography>{UNITSETT_USERSPERM_NO_USERS_INVITED}</Typography>
            )}
            <UsersList mobile={isMobile}>
              {invites.map(invite => (
                <UserLink
                  key={invite.id}
                  onClick={
                    !invite.owner
                      ? () =>
                        navigate(
                          // eslint-disable-next-line max-len
                          `/${currentDeviceId}/settings/users/${invite.id}/${invite.type}`
                        )
                      : null
                  }
                >
                  <UserDetails>
                    <Fragment>
                      <UserInfo>
                        <Name>
                          {invite.type === 'REQUEST' && (
                            <React.Fragment>
                              <UserName>
                                {invite.createdBy
                                  // eslint-disable-next-line max-len
                                  ? `${invite.createdBy.firstName} ${invite.createdBy.lastName}`
                                  : '-'}
                              </UserName>
                              {invite.status === 'PENDING' && (
                                <Status color='rgba(240,170,31, 0.6)'>
                              ({UNITSETT_USERSPERM_TECH_PENDING_REQUEST})
                                </Status>
                              )}
                              {invite.status === 'ACCEPTED' && (
                                <UserType
                                  color={
                                    invite.technician
                                      ? '#F0AA1F'
                                      : undefined
                                  }
                                >
                              ({getUserRoleLabel(invite, translations)})
                                </UserType>
                              )}
                              {invite.status === 'EXPIRED' && (
                                <Status>
                                  ({UNITSETT_USERSPERM_STATUS_EXPIRED})
                                </Status>
                              )}
                            </React.Fragment>
                          )}
                          {invite.type !== 'REQUEST' && (
                            <React.Fragment>
                              <UserName>
                                {invite.user
                                  // eslint-disable-next-line max-len
                                  ? `${invite.user.firstName} ${invite.user.lastName}`
                                  : '-'}
                              </UserName>
                              {invite.status === 'ACTIVE' && (
                                <UserType
                                  color={
                                    invite.technician
                                      ? '#F0AA1F'
                                      : undefined
                                  }
                                >
                              ({getUserRoleLabel(invite, translations)})
                                </UserType>
                              )}
                              {invite.status === 'EXPIRED' && (
                                <Status>
                              ({UNITSETT_USERSPERM_STATUS_EXPIRED})
                                </Status>
                              )}
                              {invite.status === 'PENDING' && (
                                <Status>
                              ({UNITSETT_USERSPERM_STATUS_PENDING})
                                </Status>
                              )}
                            </React.Fragment>
                          )}
                        </Name>
                        {invite.type === 'REQUEST' ? (
                          <Email>
                            {(invite.createdBy && invite.createdBy.email) ||
                          invite.email}
                          </Email>
                        ) : (
                          <Email>
                            {(invite.user && invite.user.email) || invite.email}
                          </Email>
                        )}
                        {(isMobile && invite.type !== 'REQUEST' && invite.technician) && (
                          <ExpirationDate isMobile={isMobile}>
                            {invite.validityPeriod === 0 &&
                            `(${UNITSETT_USERSPERM_ACCESS_NEVER_EXPIRES})`
                            }
                            {invite.validityPeriod !== 0 &&
                            `${UNITSETT_USERSPERM_ACCESS_EXPIRES_IN} ${convertEpochToDate(convertMillisToSec(invite.expiresAt), 'DD/MM/YYYY')}`
                            }
                          </ExpirationDate>
                        )}
                      </UserInfo>
                      {(!isMobile && invite.type !== 'REQUEST' && invite.technician) && (
                        <ExpirationDate isMobile={isMobile}>
                          {invite.validityPeriod === 0 &&
                            `(${UNITSETT_USERSPERM_ACCESS_NEVER_EXPIRES})`
                          }
                          {invite.validityPeriod !== 0 &&
                            `(${UNITSETT_USERSPERM_ACCESS_EXPIRES_IN} ${convertEpochToDate(convertMillisToSec(invite.expiresAt), 'DD/MM/YYYY')})`
                          }
                        </ExpirationDate>
                      )}
                    </Fragment>
                  </UserDetails>
                  {!invite.owner && (
                    <LinkIcon>{'keyboard_arrow_right'}</LinkIcon>
                  )}
                </UserLink>
              ))}
            </UsersList>
            {!isMobile && (
              <UserPermission permission={'ADD_USER_INVITE'}>
                <AddInviteButton
                  onClick={
                    invites.length < 10
                      ? () =>
                        navigate(`/${currentDeviceId}/settings/users/new/`)
                      : undefined
                  }
                  disabled={invites.length === 10}
                  title={
                    invites.length === 10
                      ? API_ERROR_REACHED_MAX_DEVICE_INVITES
                      : ''
                  }
                >
                  <Icon>{'add'}</Icon>
                </AddInviteButton>
              </UserPermission>
            )}
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(DeviceUsers)

const Wrapper = styled.div``

const NumberOfUsers = styled.div`
  color: #727e84;
  font-size: 14px;
  line-height: 14px;
  opacity: 0.8;
`

const UsersList = styled.div`
  margin-top: 16px;

  ${({ mobile }) => !mobile && `
    max-height: calc(100vh - 300px);
    overflow: hidden;
    overflow-y: auto;
  `}
`

const UserLink = styled.div`
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);
  padding: 12px 0 12px 8px;

  ${({ onClick }) => !!onClick && `
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover{
      background: #f0f0f4;
    }
  `}
`

const UserDetails = styled.div`
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
`

const UserInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Name = styled.div`
  color: #5b6770;
  rgba(114,126,132,0.4)font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
`

const UserName = styled.span`
  color: #5b6770;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;
`

const UserType = styled.span`
  color: ;
  color: ${props => (props.color ? props.color : '#007BC1')};
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-left: 5px;
`

const Status = styled.span`
  color: ${props => (props.color ? props.color : 'rgba(114,126,132,0.4)')};
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-left: 10px;
`

const Email = styled.div`
  margin: 0;
  color: #424e54;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
`

const LinkIcon = styled(Icon)`
  color: #007bc1;
  float: right;
`

const AddInviteButton = styled(props => <RoundedButton {...props} />)`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    bottom: 80px;
    right: 30px;
  }
`

const ExpirationDate = styled.p`
  color: rgba(114,126,132,0.5);
  font-family: "Open Sans";
  letter-spacing: 0;
  line-height: 14px;
  margin: 0;

  ${({ isMobile }) => isMobile && `
    font-size: 10px;
  `}

  ${({ isMobile }) => !isMobile && `
    font-size: 12px;
    text-align: right;
    width: calc(40% + 16px);
    padding-right: 16px;
  `}
`
