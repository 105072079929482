/**
 * PageAdaptable Container Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import PropTypes from 'prop-types'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'containers', 'PageAdaptable'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    showConfirmationModal: () => true,
    hideConfirmationModal: () => true,
    handleConfirmNavigationClick: () => true,
    setNextLocation: location => ({ location })
  }),

  reducers: ({ actions }) => ({

    confirmationModal: [false, PropTypes.bool, {
      [actions.showConfirmationModal]: () => true,
      [actions.hideConfirmationModal]: () => false
    }],

    nextLocation: [{}, PropTypes.object, {
      [actions.setNextLocation]: (state, payload) => payload.location
    }]

  }),

  stop: function * () {
    const { hideConfirmationModal } = this.actions

    yield put(hideConfirmationModal())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.handleConfirmNavigationClick]: workers.handleConfirmNavigationClick
  }),

  workers: {
    * handleConfirmNavigationClick () {
      const { navigate, hideConfirmationModal } = this.actions
      const nextLocation = yield this.get('nextLocation')

      yield put(hideConfirmationModal())
      yield put(navigate(nextLocation))
    }
  }

})
