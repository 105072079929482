// @flow
import { type DeviceStatus, type Device } from 'types/api'

import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import {
  DescriptiveSegment,
  ElGrid,
  ElPvProd,
  ElPvSelf,
  ConsumptionValue
} from 'xstorage-components'

type Props = {
  deviceStatus: DeviceStatus,
  navigate: Function,
  translations: any,
  currentDevice: Device
}

class DashboardDesktopConsumptions extends Component<Props, void> {
  actions: any

  render () {
    const {
      deviceStatus,
      translations,
      currentDevice,
      currentDeviceId
    } = this.props

    const { navigate } = this.actions

    if (!deviceStatus) {
      return ''
    }

    const {
      DASH_GRID_CONSUMPTION,
      DASH_GRID_CONSUMPTION_TODAY,
      DASH_GRID_CONSUMPTION_LAST30D,
      DASH_PV_PROD,
      DASH_PV_PROD_TODAY,
      DASH_PV_PROD_LAST30D,
      DASH_PV_SELF,
      DASH_PV_SELF_TODAY,
      DASH_PV_SELF_LAST30D
    } = translations

    const today = deviceStatus.today || {}
    const last30daysEnergyFlow = deviceStatus.last30daysEnergyFlow || {}

    return (
      <Wrapper>
        <Grid container alignItems={'center'} justify={'center'}>
          <Grid item xs={currentDevice.hasPv ? 4 : 5}>
            <ConsumptionBlockContainer
              onClick={() =>
                navigate(`/${currentDeviceId}/chart/grid-consumption/`)
              }
            >
              <ConsumptionBlock>
                <ConsumptionIcon>
                  <IconImage>
                    <ElGrid />
                  </IconImage>
                </ConsumptionIcon>
                <ConsumptionInfo>
                  <Title>{DASH_GRID_CONSUMPTION}</Title>
                  <Grid container>
                    <Grid item xs={6}>
                      <DescriptiveSegment label={DASH_GRID_CONSUMPTION_TODAY}>
                        <ConsumptionValue
                          value={today.gridConsumption}
                          color={'#007BC1'}
                          showArrow={
                            today.gridConsumption !==
                            last30daysEnergyFlow.gridConsumption
                          }
                          arrow={
                            today.gridConsumption >
                            last30daysEnergyFlow.gridConsumption
                              ? 'up'
                              : 'down'
                          }
                          arrowColor={
                            today.gridConsumption >
                            last30daysEnergyFlow.gridConsumption
                              ? 'red'
                              : 'green'
                          }
                        />
                      </DescriptiveSegment>
                    </Grid>
                    <Grid item xs={6}>
                      <DescriptiveSegment label={DASH_GRID_CONSUMPTION_LAST30D}>
                        <ConsumptionValue
                          value={last30daysEnergyFlow.gridConsumption}
                        />
                      </DescriptiveSegment>
                    </Grid>
                  </Grid>
                </ConsumptionInfo>
              </ConsumptionBlock>
              <ConsumptionBlockShadow />
            </ConsumptionBlockContainer>
          </Grid>
          {currentDevice.hasPv && (
            <Grid item xs={4}>
              <ConsumptionBlockContainer
                onClick={() =>
                  navigate(`/${currentDeviceId}/chart/pv-production/`)
                }
              >
                <ConsumptionBlock>
                  <ConsumptionIcon>
                    <IconImage>
                      <ElPvProd />
                    </IconImage>
                  </ConsumptionIcon>
                  <ConsumptionInfo>
                    <Title>{DASH_PV_PROD}</Title>
                    <Grid container>
                      <Grid item xs={6}>
                        <DescriptiveSegment label={DASH_PV_PROD_TODAY}>
                          <ConsumptionValue
                            color={'#007BC1'}
                            value={today.photovoltaicProduction}
                            showArrow={
                              today.photovoltaicProduction !==
                              last30daysEnergyFlow.photovoltaicProduction
                            }
                            arrow={
                              today.photovoltaicProduction >
                              last30daysEnergyFlow.photovoltaicProduction
                                ? 'up'
                                : 'down'
                            }
                            arrowColor={
                              today.photovoltaicProduction >
                              last30daysEnergyFlow.photovoltaicProduction
                                ? 'green'
                                : 'red'
                            }
                          />
                        </DescriptiveSegment>
                      </Grid>
                      <Grid item xs={6}>
                        <DescriptiveSegment label={DASH_PV_PROD_LAST30D}>
                          <ConsumptionValue
                            value={last30daysEnergyFlow.photovoltaicProduction}
                          />
                        </DescriptiveSegment>
                      </Grid>
                    </Grid>
                  </ConsumptionInfo>
                </ConsumptionBlock>
                <ConsumptionBlockShadow />
              </ConsumptionBlockContainer>
            </Grid>
          )}
          {currentDevice.hasPv && (
            <Grid item xs={4}>
              <ConsumptionBlockContainer
                onClick={() =>
                  navigate(`/${currentDeviceId}/chart/pv-consumption/`)
                }
              >
                <ConsumptionBlock>
                  <ConsumptionIcon>
                    <IconImage>
                      <ElPvSelf />
                    </IconImage>
                  </ConsumptionIcon>
                  <ConsumptionInfo>
                    <Title>{DASH_PV_SELF}</Title>
                    <Grid container>
                      <Grid item xs={6}>
                        <DescriptiveSegment label={DASH_PV_SELF_TODAY}>
                          <ConsumptionValue
                            color={'#007BC1'}
                            showPercentage
                            value={today.selfConsumption}
                            showArrow={
                              today.selfConsumption !==
                              last30daysEnergyFlow.selfConsumption
                            }
                            arrow={
                              today.selfConsumption >
                              last30daysEnergyFlow.selfConsumption
                                ? 'up'
                                : 'down'
                            }
                            arrowColor={
                              today.selfConsumption >
                              last30daysEnergyFlow.selfConsumption
                                ? 'green'
                                : 'red'
                            }
                          />
                        </DescriptiveSegment>
                      </Grid>
                      <Grid item xs={6}>
                        <DescriptiveSegment label={DASH_PV_SELF_LAST30D}>
                          <ConsumptionValue
                            showPercentage
                            value={last30daysEnergyFlow.selfConsumption}
                          />
                        </DescriptiveSegment>
                      </Grid>
                    </Grid>
                  </ConsumptionInfo>
                </ConsumptionBlock>
                <ConsumptionBlockShadow />
              </ConsumptionBlockContainer>
            </Grid>
          )}
        </Grid>
      </Wrapper>
    )
  }
}

export default Logic(DashboardDesktopConsumptions)

const Wrapper = styled.div`
  padding: 60px 5% 0;
`

const ConsumptionBlockContainer = styled.div`
  position: relative;
`

const ConsumptionBlock = styled.div`
  background-color: #fdfdfd;
  height: 136px;
  margin: 0 10px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(114, 126, 132, 0.1);
  display: flex;
  align-items: center;
  padding: 25px 10px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(114, 126, 132, 0.3);
  }
`

const ConsumptionBlockShadow = styled.div`
  position: absolute;
  height: 10px;
  width: calc(100% - 96px);
  background-color: #727e84;
  box-shadow: 0 0 30px 0 rgba(114, 126, 132, 0.3);
  z-index: 1;
  left: 48px;
  bottom: 0;
`

const ConsumptionIcon = styled.div`
  width: 25%;
  display: flex;
`

const ConsumptionInfo = styled.div`
  width: 75%;

  label {
    font-size: 12px;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 800;
  color: #007bc1;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 10px;
`

const IconImage = styled.div`
  margin: 0 auto;
  display: block;
`
