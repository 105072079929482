// @flow
import { kea } from 'kea'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import { log } from 'utils'
import { API_BASE_URL } from 'config'

const GET_APPS_ENDPOINT = `${API_BASE_URL}/thirdparty/myapps`

export default kea({
  path: () => ['scenes', 'UserProfileMyApps'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'isMobile'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    getAppsList: () => true,
    setMyApps: apps => apps,
    setError: error => error,
    reset: () => true,
    setActiveAppsCount: count => count
  }),

  reducers: ({ actions }) => ({
    myApps: [
      [],
      PropTypes.array,
      {
        [actions.reset]: (state, payload) => [],
        [actions.setMyApps]: (state, payload) => payload
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.reset]: () => true,
        [actions.getAppsList]: () => true,
        [actions.setMyApps]: () => false,
        [actions.setError]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.getAppsList]: () => null
      }
    ],

    activeAppsCount: [
      0,
      PropTypes.number,
      {
        [actions.setActiveAppsCount]: (state, payload) => payload,
        [actions.reset]: () => 0
      }
    ]
  }),

  start: function * () {
    log('[XS-UserProfileMyApps] Start Scene', 'yellow')

    const { getAppsList } = this.actions

    yield put(getAppsList())
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-UserProfileMyApps] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getAppsList]: workers.getAppsList
  }),

  workers: {
    * getAppsList () {
      const { setMyApps, setActiveAppsCount } = this.actions
      const translations = yield this.get('translations')

      try {
        const response = yield call(axios.get, GET_APPS_ENDPOINT)
        const { result } = response.data
        const { data, total } = result || {}

        const appsData = data.map(app => {
          const { nonAuthorizedDevices, authorizedDevices, scope } = app
          if (!nonAuthorizedDevices || nonAuthorizedDevices.length === 0) {
            // eslint-disable-next-line max-len
            app.unitsMessage = translations[`USERPROF_MYAPP_ALLUNITSACCESS_${scope}`]
          } else {
            let unitsName = authorizedDevices.map(device => device.name)
            unitsName = unitsName.join(', ')
            // eslint-disable-next-line max-len
            app.unitsMessage = `${translations[`USERPROF_MYAPP_TOUNITSACCESS_${scope}`]}  ${unitsName}`
          }
          return app
        })

        yield put(setActiveAppsCount(total))
        yield put(setMyApps(appsData))
      } catch (catchError) {
        console.log(catchError)
      }
    }
  }
})
