// @flow
import { kea } from 'kea'
import { API_BASE_URL } from 'config'
import { generateUrl } from 'utils'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'
import AppLogic from 'containers/App/logic'
// eslint-disable-next-line max-len
import TechnicianUnitsLogic from 'scenes/TechnicianProfile/TechnicianUnits/logic'

// eslint-disable-next-line max-len
const REQUEST_ACCESS_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/roles/requests/{requestId}`

export default kea({
  path: () => ['scenes', 'containers', 'UnitAccessPage'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'user'
      ]
    ]
  },
  actions: () => ({
    cancelRequestToUnit: () => ({ }),
    showCancelRequestModal: () => ({ }),
    hideCancelRequestModal: () => ({ }),
    setLoadingInProgress: () => ({ }),
    reset: () => ({ })
  }),

  reducers: ({ actions }) => ({
    cancelRequestModal: [false, PropTypes.bool, {
      [actions.showCancelRequestModal]: () => true,
      [actions.hideCancelRequestModal]: () => false,
      [actions.reset]: () => false
    }],

    loadingInProgress: [false, PropTypes.bool, {
      [actions.setLoadingInProgress]: (state, payload) => !state
    }]

  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.cancelRequestToUnit]: workers.cancelRequestToUnit
  }),

  workers: {
    * cancelRequestToUnit () {
      const { unit } = this.props
      const { setLoadingInProgress } = this.actions

      yield put(setLoadingInProgress())

      const urlParams = {
        deviceId: unit.device.id,
        requestId: unit.id
      }

      const url = generateUrl(REQUEST_ACCESS_ENDPOINT, urlParams)

      try {
        const response = yield call(axios.delete, url)
        let { successful } = response.data

        if (successful) {
          yield put(setLoadingInProgress())
          yield put(TechnicianUnitsLogic.actions.setSwitcherSelected('pending'))
          yield put(TechnicianUnitsLogic.actions.fetchPendingUnits())
          yield put(TechnicianUnitsLogic.actions.setPanelToShow(''))
        }
      } catch (er) {
        yield put(setLoadingInProgress())
        console.log(er)
      }
    }
  }

})
