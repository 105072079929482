// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import { log } from 'utils'
import { checkRolePermission } from 'containers/UserPermission/utils'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'OperationMode'],

  connect: {
    props: [AppLogic, ['translations', 'currentDeviceId', 'userRole']],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    showOperationModeHelp: () => true,
    hideOperationModeHelp: () => true,
    reset: () => false
  }),

  reducers: ({ actions }) => ({
    operationModeHelpVisible: [
      false,
      PropTypes.bool,
      {
        [actions.showOperationModeHelp]: () => true,
        [actions.hideOperationModeHelp]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  start: function * () {
    log('[XS-OperationMode] Start Scene ', 'yellow')

    const { navigate } = this.actions
    const userRole = yield this.get('userRole')

    const userCanViewOpModeDetails = checkRolePermission(
      'VIEW_OPMODE_DETAILS',
      userRole
    )

    if (!userCanViewOpModeDetails) {
      yield put(navigate('/401'))
    }

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-OperationMode] Stop Scene')

    yield put(reset())
  }
})
