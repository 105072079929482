// @flow
import React, { Component } from 'react'
import Logic from './logic'
import { LabelValueInline } from 'xstorage-components'

type Props = {}

class MaintenanceTechParamsMeterPhases extends Component<Props, void> {
  render () {
    const { translations, meterPhaseValues } = this.props

    const {
      MAINTENANCE_TECH_PARAMS_METER_VOLTAGE,
      MAINTENANCE_TECH_PARAMS_METER_CURRENT,
      MAINTENANCE_TECH_PARAMS_METER_ACTIVE_POWER,
      MAINTENANCE_TECH_PARAMS_METER_REACTIVE_POWER,
      MAINTENANCE_TECH_PARAMS_METER_POWER_FACTOR,
      MAINTENANCE_TECH_PARAMS_METER_FREQUENCY
    } = translations

    if (!meterPhaseValues) {
      return ''
    }

    return (
      <React.Fragment>
        <LabelValueInline
          id={'LabelValueInline_Voltage'}
          label={MAINTENANCE_TECH_PARAMS_METER_VOLTAGE}
          value={
            meterPhaseValues.voltage !== null
              ? `${meterPhaseValues.voltage} V`
              : '--'
          }
        />
        <LabelValueInline
          id={'LabelValueInline_Current'}
          label={MAINTENANCE_TECH_PARAMS_METER_CURRENT}
          value={
            meterPhaseValues.current !== null
              ? `${meterPhaseValues.current} A`
              : '--'
          }
        />
        <LabelValueInline
          id={'LabelValueInline_ActivePower'}
          label={MAINTENANCE_TECH_PARAMS_METER_ACTIVE_POWER}
          value={
            meterPhaseValues.activePower !== null
              ? `${meterPhaseValues.activePower} W`
              : '--'
          }
        />
        <LabelValueInline
          id={'LabelValueInline_ReactivePower'}
          label={MAINTENANCE_TECH_PARAMS_METER_REACTIVE_POWER}
          value={
            meterPhaseValues.reactivePower !== null
              ? `${meterPhaseValues.reactivePower} VAR`
              : '--'
          }
        />
        <LabelValueInline
          id={'LabelValueInline_PowerFactor'}
          label={MAINTENANCE_TECH_PARAMS_METER_POWER_FACTOR}
          value={meterPhaseValues.powerFactor || '--'}
        />
        <LabelValueInline
          id={'LabelValueInline_Frequency'}
          label={MAINTENANCE_TECH_PARAMS_METER_FREQUENCY}
          value={
            meterPhaseValues.frequency !== null
              ? `${meterPhaseValues.frequency} Hz`
              : '--'
          }
        />
      </React.Fragment>
    )
  }
}

export default Logic(MaintenanceTechParamsMeterPhases)
