// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import moment from 'moment-timezone'

import {
  List,
  Divider,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  Typography
} from 'xstorage-components'

type Props = {
  unit: {
    createdAt: number
  },
  translations: {
    TECH_UNITS_REQUEST_ACCESS_TO_UNIT: string,
    TECH_UNITS_ALREADY_REQUESTED: string,
    TECH_UNITS_CANCEL_REQUEST: string,
    TECH_UNITS_CANCEL_REQUEST_MODAL_TITLE: string,
    TECH_UNIT_CANCEL_REQUEST_MESSAGE_1: string,
    TECH_UNIT_CANCEL_REQUEST_MESSAGE_2: string,
    TECH_UNIT_RESEND_INVITATION_CANCEL: string,
    TECH_UNIT_CANCEL_OWNERSHIP_SUBMIT: string
  },
  isMobile: boolean,
  cancelRequestModal: boolean,
  loadingInProgress: boolean
}

class UnitAccessPage extends Component<Props> {
  actions: {
    cancelRequestToUnit: Function,
    hideCancelRequestModal: Function,
    showCancelRequestModal: Function
  }

  render () {
    const {
      unit,
      translations,
      isMobile,
      cancelRequestModal,
      loadingInProgress
    } = this.props
    const {
      TECH_UNITS_REQUEST_ACCESS_TO_UNIT,
      TECH_UNITS_ALREADY_REQUESTED,
      TECH_UNITS_CANCEL_REQUEST,
      TECH_UNITS_CANCEL_REQUEST_MODAL_TITLE,
      TECH_UNIT_CANCEL_REQUEST_MESSAGE_1,
      TECH_UNIT_CANCEL_REQUEST_MESSAGE_2,
      TECH_UNIT_RESEND_INVITATION_CANCEL,
      TECH_UNIT_CANCEL_OWNERSHIP_SUBMIT
    } = translations

    const {
      cancelRequestToUnit,
      hideCancelRequestModal,
      showCancelRequestModal
    } = this.actions

    const createdDate = unit
      ? moment(unit.createdAt).format('DD/MM/YYYY HH:mm')
      : moment().format('DD/MM/YYYY HH:mm')

    return (
      <Wrapper>
        <List>
          <Divider variant={'inset'} component='li' />
          <List.Item>
            <List.ItemText
              textmargin={'0 0 4px 0'}
              mobile={isMobile.toString()}
              primary={TECH_UNITS_REQUEST_ACCESS_TO_UNIT}
              secondary={`${TECH_UNITS_ALREADY_REQUESTED} ${createdDate}`}
            />
          </List.Item>
          <List.Item button onClick={showCancelRequestModal}>
            <CancelItemText primary={TECH_UNITS_CANCEL_REQUEST} />
            <Icon style={{ color: '#CA3C3D' }}>{'keyboard_arrow_right'}</Icon>
          </List.Item>
        </List>
        <Modal open={cancelRequestModal} size={'small'}>
          <div>
            <ModalHeader title={TECH_UNITS_CANCEL_REQUEST_MODAL_TITLE} red />
            <ModalContent padding size={'small'} mobile={isMobile}>
              <Typography variant={'body1'}>
                {TECH_UNIT_CANCEL_REQUEST_MESSAGE_1}
                <br />
                {TECH_UNIT_CANCEL_REQUEST_MESSAGE_2}
              </Typography>
            </ModalContent>
            <ModalButtons>
              <Button
                id={'PrimaryButton_Cancel'}
                buttonType={'primary-cancel'}
                margin={'0 12px'}
                buttonWidth={'156px'}
                onClick={hideCancelRequestModal}
                disabled={loadingInProgress}
              >
                {TECH_UNIT_RESEND_INVITATION_CANCEL}
              </Button>
              <Button
                id={'PrimaryButton_Submit'}
                buttonType={'primary-danger'}
                margin={'0 12px'}
                buttonWidth={'156px'}
                onClick={cancelRequestToUnit}
                disabled={loadingInProgress}
              >
                {TECH_UNIT_CANCEL_OWNERSHIP_SUBMIT}
              </Button>
            </ModalButtons>
          </div>
        </Modal>
      </Wrapper>
    )
  }
}

export default Logic(UnitAccessPage)

const Wrapper = styled.div``

const CancelItemText = styled(props => <List.ItemText {...props} />)`
  &&&& {
    span {
      color: #ca3c3d;
    }
  }
`
