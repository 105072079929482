// @flow
import { kea } from 'kea'

import PropTypes from 'prop-types'
import axios from 'axios'
import { put, call, delay } from 'redux-saga/effects'

import { API_BASE_URL, UNREAD_MESSAGES_DELAY } from 'config'
import AppLogic from 'containers/App/logic'
import NotificationsPanelLogic from 'containers/NotificationsPanel/logic'

// eslint-disable-next-line max-len
const UNREAD_NOTIFICATIONS_ENDPOINT = `${API_BASE_URL}/notifications/unread?channel=BOTH,SOCKET`

export default kea({
  path: () => ['scenes', 'containers', 'UserBar'],

  connect: {
    props: [
      AppLogic, [
        'currentRoute',
        'showBackBar'
      ]
    ],
    actions: [
      NotificationsPanelLogic, [
        'showNotifications',
        'hideNotifications',
        'setUnreadNotificationsValue'
      ]
    ]
  },

  actions: () => ({
    getUnreadNotifications: () => true,
    setUnreadNotifications: (unread) => ({ unread })
  }),

  reducers: ({ actions }) => ({
    unreadNotifications: [false, PropTypes.bool, {
      [actions.setUnreadNotifications]: (state, payload) => payload.unread
    }]
  }),

  start: function * () {
    const { getUnreadNotifications } = this.actions

    yield put(getUnreadNotifications())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getUnreadNotifications]: workers.getUnreadNotifications,
    [actions.hideNotifications]: workers.getUnreadNotifications
  }),

  workers: {
    * getUnreadNotifications () {
      const {
        setUnreadNotifications,
        setUnreadNotificationsValue,
        getUnreadNotifications
      } = this.actions

      try {
        const response = yield call(axios.get, UNREAD_NOTIFICATIONS_ENDPOINT)
        const { result } = response.data
        yield put(setUnreadNotificationsValue(result.total))

        if (result && result.total > 0) {
          yield put(setUnreadNotifications(true))
        } else {
          yield put(setUnreadNotifications(false))
        }

        let timeDelay = UNREAD_MESSAGES_DELAY
        yield delay(timeDelay)

        yield put(getUnreadNotifications())
      } catch (err) {
        console.log(err)
      }
    }
  }

})
