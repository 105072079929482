// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import {
  COMMANDS_PARAMS,
  getCommandTranslation
} from 'containers/OperationModeForm/utils'
import { getOperationModeList } from './utils'

import FormFieldError from 'containers/FormFieldError'

import { Form, FormSlider, InputText, Select } from 'xstorage-components'

type Props = {
  form: any,
  change: Function,
  translations: any,
  dirty: boolean,
  label?: string,
  defaultMode?: any,
  settings?: boolean,
  disabled?: boolean,
  full?: boolean
}

class OperationModeParams extends Component<Props, void> {
  render () {
    const {
      form,
      change,
      translations,
      dirty,
      label,
      defaultMode,
      settings,
      disabled,
      full,
      isMobile,
      isGermany,
      batteryReadyMode
    } = this.props

    const operationModeCriteria = {
      isGermany: isGermany,
      isSettings: settings,
      batteryReadyMode: batteryReadyMode
    }

    let operationModeOptions = getOperationModeList(
      translations,
      operationModeCriteria
    )

    // append default mode to the operation mode options
    if (defaultMode && Object.keys(defaultMode).length > 0) {
      operationModeOptions = [
        {
          val: 'DEFAULT',
          label: (
            <span>
              Default{' '}
              <LightSelectValue>
                ({getCommandTranslation(translations, defaultMode.command)})
              </LightSelectValue>
            </span>
          )
        },
        ...operationModeOptions
      ]
    }

    const {
      command,
      soc,
      powerAllocation,
      power,
      maximumPower,
      optimalSoc,
      maxHousePeakConsumption
    } = form || {}

    const {
      OPERATION_MODE_DEFAULT_LABEL,
      OPERATION_MODE_PARAM_MAXHOUSEPEAKCONSUMPTION,
      OPERATION_MODE_PARAM_OPTIMALSOC,
      OPERATION_MODE_PARAM_POWERALLOCATION,
      OPERATION_MODE_PARAM_MAXIMUMPOWER,
      OPERATION_MODE_PARAM_SOC,
      OPERATION_MODE_PARAM_POWER
    } = translations

    let currentCommand: string = command && command.value

    if (currentCommand === 'DEFAULT' && defaultMode) {
      currentCommand = defaultMode.command
    }

    const comandParams = COMMANDS_PARAMS[currentCommand] || []

    return (
      <Wrapper>
        <Form.Row>
          <Select
            id={'OperationMode'}
            label={label || OPERATION_MODE_DEFAULT_LABEL}
            options={operationModeOptions}
            selected={command ? command.value : null}
            onChange={selected => {
              if (selected === 'SET_CHARGE' || selected === 'SET_DISCHARGE') {
                change({ power: 5 })
                change({ soc: 5 })
              }

              change({ command: selected })
            }}
            disabled={disabled || (isGermany && settings)}
            containerMargin={(!isMobile && full) ? '0 8px' : null}
            full={full}
          />
        </Form.Row>
        {comandParams.indexOf('power') !== -1 && (
          <Form.Row full>
            <FormSlider
              id={'Power'}
              label={OPERATION_MODE_PARAM_POWER}
              value={`${power.value}%`}
              disabled={disabled || (command && command.value === 'DEFAULT')}
              hideBottomScale
              sliderMin={5}
              sliderMax={100}
              sliderValue={power.value}
              sliderStep={5}
              onChange={value => change({ power: value })}
            />
          </Form.Row>
        )}
        {comandParams.indexOf('powerAllocation') !== -1 && (
          <Form.Row full={full}>
            <FormSlider
              id={'PowerAllocation'}
              label={OPERATION_MODE_PARAM_POWERALLOCATION}
              disabled={disabled || (command && command.value === 'DEFAULT')}
              value={`${powerAllocation.value}%`}
              hideBottomScale
              sliderMin={5}
              sliderMax={100}
              sliderValue={powerAllocation.value}
              onChange={value => change({ powerAllocation: value })}
            />
          </Form.Row>
        )}
        {!isGermany && comandParams.indexOf('maximumPower') !== -1 && (
          <Form.Row full={full}>
            <FormSlider
              id={'MaxPowerAllocation'}
              label={OPERATION_MODE_PARAM_MAXIMUMPOWER}
              value={`${maximumPower.value}W`}
              hideBottomScale
              disabled={disabled || (command && command.value === 'DEFAULT')}
              sliderMin={-1000}
              sliderMax={30000}
              sliderValue={maximumPower.value}
              onChange={value => change({ maximumPower: value })}
            />
          </Form.Row>
        )}
        {comandParams.indexOf('soc') !== -1 && (
          <Form.Row full={full}>
            <FormSlider
              id={'StateOfCharge'}
              label={OPERATION_MODE_PARAM_SOC}
              value={`${soc.value}%`}
              disabled={disabled || (command && command.value === 'DEFAULT')}
              hideBottomScale
              sliderMin={5}
              sliderMax={100}
              sliderStep={5}
              sliderValue={soc.value}
              onChange={value => change({ soc: value })}
            />
          </Form.Row>
        )}
        {comandParams.indexOf('optimalSoc') !== -1 && (
          <Form.Row full={full}>
            <FormSlider
              id={'OptimalStateOfCharge'}
              label={OPERATION_MODE_PARAM_OPTIMALSOC}
              value={`${optimalSoc.value}%`}
              hideBottomScale
              disabled={disabled || (command && command.value === 'DEFAULT')}
              sliderMin={0}
              sliderMax={100}
              sliderValue={optimalSoc.value}
              onChange={value => change({ optimalSoc: value })}
            />
          </Form.Row>
        )}
        {comandParams.indexOf('maxHousePeakConsumption') !== -1 && (
          <Form.Row full={full}>
            <InputText
              id={'MaxHousePeakConsumption'}
              label={OPERATION_MODE_PARAM_MAXHOUSEPEAKCONSUMPTION}
              type='number'
              value={maxHousePeakConsumption.value}
              error={dirty && !maxHousePeakConsumption.valid}
              disabled={disabled || (command && command.value === 'DEFAULT')}
              onChange={({ target }) =>
                change({ maxHousePeakConsumption: target.value })
              }
            />
            <FormFieldError
              id={'MaxHousePeakConsumption'}
              showError={dirty && !maxHousePeakConsumption.valid}
              errorMessage={maxHousePeakConsumption.message}
            />
          </Form.Row>
        )}
      </Wrapper>
    )
  }
}

export default Logic(OperationModeParams)

const Wrapper = styled.div``

const LightSelectValue = styled.span`
  color: rgba(114, 126, 132, 0.5);
  font-size: 12px;
`
