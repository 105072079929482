// @flow
import React, { Component } from 'react'
import { type Device } from 'types/api'
import Logic from './logic'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

import PageAdaptable from 'containers/PageAdaptable'
import MaintenanceMenu from 'containers/MaintenanceMenu'
import ForceSyncPanel from 'containers/ForceSyncPanel'
// eslint-disable-next-line max-len
import MaintenanceSupportedFeatures from 'containers/MaintenanceSupportedFeatures'
import LoadingGif from 'components/LoadingGif'
import SlidePanel from 'components/SlidePanel'
import {
  PageTitle,
  PageMainSection,
  Button,
  ErrorMessage
} from 'xstorage-components'

type Props = {
  translations: {
    MAINTENANCE_TITLE: string,
    UNITSETT_SUPPORT_METRICS_SYNC: string,
    UNITSETT_SUPPORT_METRICS_LAST_SYNC: string,
    UNITSETT_SUPPORT_METRICS_SYNC_FORCE_SYNC: string,
    UNITSETT_UPDATES_WRONG_DATE: string
  },
  isMobile: boolean,
  syncData: {
    diffDate: string,
    date: string,
    status: string
  },
  syncPanel: boolean,
  loadingData: boolean,
  error: string,
  currentDevice: Device,
  featureSupport: {
    maintenanceUpdateRequired: boolean
  },
  loading: boolean
}

export class MaintenanceMetricsSync extends Component<Props> {
  actions: {
    setSyncPanel: Function,
    navigate: Function
  }

  render () {
    const { navigate, setSyncPanel } = this.actions
    const {
      translations,
      isMobile,
      syncData,
      syncPanel,
      loadingData,
      error,
      currentDevice,
      featureSupport,
      loading
    } = this.props

    const {
      MAINTENANCE_TITLE,
      UNITSETT_SUPPORT_METRICS_SYNC,
      UNITSETT_SUPPORT_METRICS_LAST_SYNC,
      UNITSETT_SUPPORT_METRICS_SYNC_FORCE_SYNC,
      UNITSETT_SUPPORT_DATETIME_SUCCESSSYNC
    } = translations

    const { maintenanceUpdateRequired } = featureSupport

    return (
      <Wrapper>
        <PageAdaptable
          title={MAINTENANCE_TITLE}
          menu={<MaintenanceMenu page={'metrics-sync'} />}
          isForm={false}
          onBack={() => navigate(`/${currentDevice.id}/maintenance/`)}
          loading={loading}
        >
          <PageMainSection padding={!isMobile} mobile={isMobile}>
            <PageTitle mobile={isMobile} marginBottom={'40px'}>
              {UNITSETT_SUPPORT_METRICS_SYNC}
            </PageTitle>
            <LoadingGif loading={loadingData} />
            <MaintenanceSupportedFeatures feature={'maintenance'} />
            {!maintenanceUpdateRequired && (
              <React.Fragment>
                <ErrorMessage>{error}</ErrorMessage>
                <LastSyncInfo isMobile={isMobile}>
                  <LastSyncTitle>
                    {UNITSETT_SUPPORT_METRICS_LAST_SYNC}
                  </LastSyncTitle>
                  <LastSyncDate isMobile={isMobile}>
                    {syncData.diffDate && syncData.date && (
                      <React.Fragment>
                        <span>{syncData.diffDate}</span>
                        <span> ({syncData.date})</span>
                      </React.Fragment>
                    )}
                    {!syncData.diffDate && syncData.date && (
                      <span>{syncData.date}</span>
                    )}
                    {!syncData.diffDate && !syncData.date && <span>--</span>}
                  </LastSyncDate>
                </LastSyncInfo>
                {syncData.status === 'PROCESSING' && (
                  <SyncInProgressDiv>
                    <Icon>{'access_time'}</Icon>
                    <Message>{UNITSETT_SUPPORT_DATETIME_SUCCESSSYNC}</Message>
                  </SyncInProgressDiv>
                )}
                {syncData.status !== 'PROCESSING' &&
                  currentDevice.connected && (
                  <ButtonWrapper isMobile={isMobile}>
                    <Button
                      id={'PrimaryButton_Sync'}
                      buttonWidth={'260px'}
                      margin={'42px 0'}
                      smallShadow
                      onClick={() => setSyncPanel(true)}
                    >
                      <Icon>{'autorenew'}</Icon>{' '}
                      {UNITSETT_SUPPORT_METRICS_SYNC_FORCE_SYNC}
                    </Button>
                  </ButtonWrapper>
                )}
              </React.Fragment>
            )}
          </PageMainSection>
          {isMobile && syncPanel && (
            <ForceSyncPanel onCancel={() => setSyncPanel(false)} />
          )}
          {!isMobile && (
            <SlidePanel
              overlay
              open={syncPanel}
              onClose={() => setSyncPanel(false)}
              title={UNITSETT_SUPPORT_METRICS_SYNC_FORCE_SYNC}
            >
              <ForceSyncPanel onCancel={() => setSyncPanel(false)} />
            </SlidePanel>
          )}
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(MaintenanceMetricsSync)

const Wrapper = styled.div`
  position: relative;
`
const ButtonWrapper = styled.div`
  z-index: 10;

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
  `}
`

const LastSyncInfo = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-top: 16px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 41px;
    box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);

    ${({ isMobile }) =>
    isMobile &&
    `
      height: 48px;
    `}
  }
`

const LastSyncTitle = styled.span`
  color: #424e54;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 8px;
`

const LastSyncDate = styled.p`
  margin: 0;
  padding-right: 8px;
  display: inline-flex;
  align-items: center;

  ${({ isMobile }) =>
    isMobile &&
    `
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 50vw;

    span {
      width: 100%;
      text-align: right;
    }
  `}

  span {
    font-family: 'Open Sans';
    line-height: 20px;
  }

  span:nth-child(1) {
    color: #007bc1;
    font-size: 14px;
    font-weight: bold;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:nth-child(2) {
    color: #727e84;
    font-size: 12px;
    margin-left: 5px;
  }
`

const SyncInProgressDiv = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 42px;

  span.material-icons {
    color: #00b2a9;
    margin-right: 8px;
  }
`

const Message = styled.span`
  color: #727e84;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
`
