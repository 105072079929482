// @flow
import { kea } from 'kea'
import axios from 'axios'
import PropTypes from 'prop-types'
import { put, call } from 'redux-saga/effects'

import { generateUrl } from 'utils'
import { API_BASE_URL } from 'config'
import AppLogic from 'containers/App/logic'
// eslint-disable-next-line max-len
const FEATURES_ENDPOINT = `${API_BASE_URL}/devices/{id}/features/{feature_group_code}`

export default kea({
  path: () => ['scenes', 'containers', 'MaintenanceSupportedFeatures'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'currentDevice',
        'currentDeviceId'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    reset: () => true,
    checkMaintenanceFeatureAccess: () => true,
    checkRebootFeatureAccess: () => true,
    checkSshtunelFeatureAccess: () => true,
    checkHttpstunelFeatureAccess: () => true,
    setFeatures: feature => ({ feature }),
    setFirmwareVersion: version => version
  }),

  reducers: ({ actions }) => ({
    featureSupport: [{}, PropTypes.object, {
      [actions.setFeatures]:
        (state, payload) => ({ ...state, ...payload.feature }),
      [actions.reset]: () => ({})
    }],

    deviceFirmwareVersion: ['', PropTypes.string, {
      [actions.setFirmwareVersion]: (state, payload) => payload
    }]
  }),

  start: function * () {
    const {
      checkMaintenanceFeatureAccess,
      checkRebootFeatureAccess,
      checkSshtunelFeatureAccess,
      setFirmwareVersion,
      checkHttpstunelFeatureAccess
    } = this.actions
    const currentDevice = yield this.get('currentDevice')
    const deviceFirmwareVersion = yield this.get('deviceFirmwareVersion')

    if (currentDevice.firmwareVersion !== deviceFirmwareVersion) {
      yield put(setFirmwareVersion(currentDevice.firmwareVersion))
      yield put(checkMaintenanceFeatureAccess())
      yield put(checkRebootFeatureAccess())
      yield put(checkSshtunelFeatureAccess())
      yield put(checkHttpstunelFeatureAccess())
    }
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.checkMaintenanceFeatureAccess]:
      workers.checkMaintenanceFeatureAccess,
    [actions.checkRebootFeatureAccess]: workers.checkRebootFeatureAccess,
    [actions.checkSshtunelFeatureAccess]: workers.checkSshtunelFeatureAccess,
    [actions.checkHttpstunelFeatureAccess]: workers.checkHttpstunelFeatureAccess
  }),

  workers: {
    * checkMaintenanceFeatureAccess () {
      const { setFeatures } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      try {
        // eslint-disable-next-line max-len
        const url = generateUrl(FEATURES_ENDPOINT, { id: currentDeviceId, feature_group_code: 'maintenance' })
        const response = yield call(axios.get, url)
        const { result } = response.data

        const supportedMaintenance = {
          maintenanceSupported: result.supported,
          maintenanceUpdateRequired: result.updateRequired
        }

        yield put(setFeatures(supportedMaintenance))
      } catch (err) {
        console.log(err)
      }
    },

    * checkRebootFeatureAccess () {
      const { setFeatures } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      try {
        // eslint-disable-next-line max-len
        const url = generateUrl(FEATURES_ENDPOINT, { id: currentDeviceId, feature_group_code: 'reboot' })
        const response = yield call(axios.get, url)
        const { result } = response.data

        const supportedReboot = {
          rebootSupported: result.supported,
          rebootUpdateRequired: result.updateRequired
        }

        yield put(setFeatures(supportedReboot))
      } catch (err) {
        console.log(err)
      }
    },

    * checkSshtunelFeatureAccess () {
      const { setFeatures } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      try {
        // eslint-disable-next-line max-len
        const url = generateUrl(FEATURES_ENDPOINT, { id: currentDeviceId, feature_group_code: 'ssh_tunnel' })
        const response = yield call(axios.get, url)
        const { result } = response.data

        const supportedReboot = {
          sshSupported: result.supported,
          sshUpdateRequired: result.updateRequired
        }

        yield put(setFeatures(supportedReboot))
      } catch (err) {
        console.log(err)
      }
    },

    * checkHttpstunelFeatureAccess () {
      const { setFeatures } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      try {
        // eslint-disable-next-line max-len
        const url = generateUrl(FEATURES_ENDPOINT, { id: currentDeviceId, feature_group_code: 'https_tunnel' })
        const response = yield call(axios.get, url)
        const { result } = response.data

        const supportedReboot = {
          httpsSupported: result.supported,
          httpsUpdateRequired: result.updateRequired
        }

        yield put(setFeatures(supportedReboot))
      } catch (err) {
        console.log(err)
      }
    }

  }
})
