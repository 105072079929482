/* eslint-disable max-len */
// @flow
import { type Device } from 'types/api'
import React, { Component, Fragment } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import {
  Icon,
  FormControlLabel,
  Switch,
  InputAdornment
} from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import DeviceSettingsMenu from 'containers/DeviceSettingsMenu'
import PageAdaptable from 'containers/PageAdaptable'
import OperationModeParams from 'containers/OperationModeParams'
import FormFieldError from 'containers/FormFieldError'
import FormGeneralError from 'containers/FormGeneralError'

import {
  PageTitle,
  Button,
  Form,
  InputText,
  MobileActionButton,
  PageMainSection,
  SuccessSavedAlert,
  FixedTopBar
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  deviceInfo: Device,
  form: any,
  loading: false,
  dirty: boolean,
  error: string,
  timezones: [],
  countries: [],
  cities: [],
  userCanEditOwnerTech: boolean,
  userCanEditAllButViewer: boolean,
  userCanEditTech: boolean,
  more: boolean,
  fixNavbar: boolean,
  successMessage: boolean,
  batteryReadyMode: boolean,
  currentDeviceId: string,
  showBackBar: boolean
}

type State = {
  more: boolean
}

export class DeviceGeneralSettings extends Component<Props, State> {
  actions: any

  state = {
    more: false
  }

  hideBar = () => {
    const { isMobile } = this.props
    const { setFixNavbar } = this.actions
    if (isMobile && window.scrollY > 50) {
      setFixNavbar(true)
    } else {
      setFixNavbar(false)
    }
  }

  componentDidMount () {
    const { isMobile } = this.props
    if (isMobile) {
      window.addEventListener('scroll', this.hideBar)
    }
  }

  componentWillUnmount () {
    const { isMobile } = this.props

    if (isMobile) {
      window.removeEventListener('scroll', this.hideBar)
    }
  }

  render () {
    const {
      isMobile,
      translations,
      deviceInfo,
      loading,
      form,
      dirty,
      error,
      userCanEditOwnerTech,
      userCanEditAllButViewer,
      userCanEditTech,
      more,
      fixNavbar,
      successMessage,
      batteryReadyMode,
      currentDeviceId,
      showBackBar
    } = this.props

    const {
      navigate,
      change,
      submit,
      toggleMore,
      fetchDeviceInfo
    } = this.actions

    const {
      UNITSETT_TITLE,
      UNITSETT_GENERAL_TITLE,
      UNITSETT_GENERAL_CANCEL,
      UNITSETT_GENERAL_SAVE,
      UNITSETT_GENERAL_SHOW_MORE,
      UNITSETT_GENERAL_SHOW_LESS,
      UNITSETT_GENERAL_INVERTER_SN,
      UNITSETT_GENERAL_UNIT_NAME,
      UNITSETT_GENERAL_BAT_BACKUP_LEVEL,
      UNITSETT_GENERAL_COUNTRY,
      UNITSETT_GENERAL_TIMEZONE,
      UNITSETT_GENERAL_REGION,
      UNITSETT_GENERAL_CITY,
      UNITSETT_GENERAL_POSTAL_CODE,
      UNITSETT_GENERAL_DEFAULT_MODE,
      UNITSETT_GENERAL_HAS_PV,
      UNITSETT_GENERAL_BAT_SERIAL_NUMBER,
      UNITSETT_GENERAL_INVERTER_SOFT_VERSION,
      UNITSETT_GENERAL_BAT_MANG_SYS_VERSION,
      UNITSETT_GENERAL_SYS_CAPACITY,
      UNITSETT_GENERAL_XSTORAGE_VERSION,
      UNITSETT_GENERAL_THIRD_PHASE_OF_SPP,
      UNITSETT_GENERAL_SINGLE_PHASE_OF_POWER,
      UNITSETT_GENERAL_THIRD_PHASE_OF_POWER,
      UNITSETT_GENERAL_NETWORK_INTERFACE,
      UNITSETT_GENERAL_NETWORK_INTERFACE_IP,
      UNITSETT_GENERAL_NETWORK_INTERFACE_DNS,
      UNITSETT_GENERAL_NETWORK_INTERFACE_MAC,
      UNITSETT_GENERAL_BLOCKED_STATUS,
      UNITSETT_GENERAL_SAVED_SUCCESS,
      UNITSETT_GENERAL_BATTERY_CONNECTED,
      UNITSETT_GENERAL_ENERGY_SAVING_MODE,
      UNITSETT_GENERAL_HOUSE_CONSUMPTION_THRESHOLD,
      UNITSETT_GENERAL_ENERGY_SAVING_MODE_DISABLED,
      UNITSETT_GENERAL_ENERGY_SAVING_MODE_ENABLED
    } = translations

    const {
      inverterSerialNumber,
      hasPv,
      bmsSerialNumber,
      // commCardFirmwareVersion,
      inverterFirmwareVersion,
      bmsFirmwareVersion,
      firmwareVersion,
      bmsCapacity,
      powerMeters,
      networkInterfaces,
      inverterIsSinglePhase,
      dns,
      hasBattery
    } = deviceInfo

    const OrderedPowerMeters = orderBy(powerMeters, ['position'], ['asc'])

    const {
      cityName,
      address,
      name,
      postalCode,
      countryName,
      timezoneName,
      bmsBackupLevel,
      updateBlockedState,
      showEnergySaving,
      energySavingMode,
      houseConsumptionThreshold
    } = form

    const formButtons = (
      <Form.Buttons align={'right'}>
        <Button
          id={'PrimaryButton_Cancel'}
          buttonType={'primary-cancel'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={fetchDeviceInfo}
          disabled={!dirty}
        >
          {UNITSETT_GENERAL_CANCEL}
        </Button>
        <Button
          id={'PrimaryButton_Save'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={submit}
          disabled={!dirty}
        >
          {UNITSETT_GENERAL_SAVE}
        </Button>
      </Form.Buttons>
    )

    const showAllFields = !isMobile ? true : more

    return (
      <Wrapper>
        <PageAdaptable
          title={UNITSETT_TITLE}
          menu={<DeviceSettingsMenu page={'general-settings'} />}
          onBack={() => navigate(`/${currentDeviceId}/settings/`)}
          isForm
          isDirty={dirty}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <SuccessSavedAlert
              mobile={isMobile}
              out={!successMessage}
              icon={<Icon>{'check'}</Icon>}
              message={UNITSETT_GENERAL_SAVED_SUCCESS}
            />
            {fixNavbar && (
              <FixedTopBar
                backArrowClick={() => navigate(`/${currentDeviceId}/settings/`)}
                pageTitle={UNITSETT_GENERAL_TITLE}
                actionButtonIcon={
                  dirty ? <Icon>{'check'}</Icon> : null
                }
                actionButtonTitle={
                  dirty ? UNITSETT_GENERAL_SAVE : null
                }
                actionButtonClick={dirty ? submit : null}
                isTechnician={showBackBar}
              />
            )}
            <PageTitle
              marginTop={'17px'}
              marginBottom={'40px'}
              mobile={isMobile}
              id={'PageTitle_GeneralSettings'}
            >
              {UNITSETT_GENERAL_TITLE}
            </PageTitle>
            {isMobile && dirty && (
              <MobileActionButton
                mobile
                icon={<Icon>{'check'}</Icon>}
                onClick={submit}
                id={'Save'}
              >
                {UNITSETT_GENERAL_SAVE}
              </MobileActionButton>
            )}
            <Form
              mobile={isMobile}
              showButtons={!isMobile && dirty}
              buttons={formButtons}
              id={'FormContainer_GeneralSettingsForm'}
              containerHeight={
                !isMobile && showBackBar
                  ? 'calc(100vh - 405px)'
                  : !isMobile && !showBackBar
                    ? 'calc(100vh - 350px)'
                    : 'auto'
              }
            >
              <FormGeneralError dirty={dirty} error={error} full={isMobile} />
              {userCanEditTech && (
                <Form.Row relative>
                  <SwitchLabel
                    control={
                      <PowerSwitch
                        id={'PowerSwitch_UpdateBlockedState'}
                        checked={updateBlockedState.value}
                        onChange={() =>
                          change({
                            updateBlockedState: !updateBlockedState.value
                          })
                        }
                        classes={{
                          checked: 'checked',
                          track: 'bar'
                        }}
                      />
                    }
                    disabled={!userCanEditTech}
                    label={UNITSETT_GENERAL_BLOCKED_STATUS}
                    id={'SwitchLabel_UpdateBlockedState'}
                  />
                  <FormFieldError
                    id={'UpdateBlockedState'}
                    showError={dirty && !updateBlockedState.valid}
                    errorMessage={updateBlockedState.message}
                  />
                </Form.Row>
              )}
              <Form.Row name={'name'}>
                <InputText
                  id={'UnitName'}
                  label={UNITSETT_GENERAL_UNIT_NAME}
                  error={dirty && !name.valid}
                  value={name.value}
                  disabled={!userCanEditOwnerTech}
                  onChange={({ target }) => change({ name: target.value })}
                  inputProps={{
                    maxLength: 90
                  }}
                />
                <FormFieldError
                  id={'UnitName'}
                  showError={dirty && !name.valid}
                  errorMessage={name.message}
                />
              </Form.Row>
              {showEnergySaving.value && (
                <Fragment>
                  <Form.Row relative>
                    <EnergySavingDiv disabled={!userCanEditAllButViewer}>
                      <Label disabled={!userCanEditAllButViewer}>
                        {UNITSETT_GENERAL_ENERGY_SAVING_MODE}
                      </Label>
                      <SwitchLabel
                        control={
                          <PowerSwitch
                            id={'PowerSwitch_EnergySavingMode'}
                            checked={energySavingMode.value}
                            onChange={() =>
                              change({
                                energySavingMode: !energySavingMode.value,
                                houseConsumptionThreshold:
                                  houseConsumptionThreshold.value
                              })
                            }
                            classes={{
                              checked: 'checked',
                              track: 'bar'
                            }}
                            disabled={!userCanEditAllButViewer}
                          />
                        }
                        id={'SwitchLabel_EnergySavingMode'}
                        label={
                          energySavingMode.value
                            ? UNITSETT_GENERAL_ENERGY_SAVING_MODE_ENABLED
                            : UNITSETT_GENERAL_ENERGY_SAVING_MODE_DISABLED
                        }
                        disabled={!userCanEditAllButViewer}
                      />
                    </EnergySavingDiv>
                  </Form.Row>
                  <Form.Row full={isMobile}>
                    <InputText
                      id={'HouseConsumptionThreshold'}
                      label={UNITSETT_GENERAL_HOUSE_CONSUMPTION_THRESHOLD}
                      value={houseConsumptionThreshold.value}
                      disabled={
                        !energySavingMode.value || !userCanEditAllButViewer
                      }
                      error={dirty && !houseConsumptionThreshold.valid}
                      onChange={({ target }) =>
                        change({
                          houseConsumptionThreshold: target.validity.valid
                            ? target.value
                            : target.value.slice(0, -1)
                        })
                      }
                      margin='normal'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>W</InputAdornment>
                        )
                      }}
                    />
                    <FormFieldError
                      id={'HouseConsumptionThreshold'}
                      showError={dirty && !houseConsumptionThreshold.valid}
                      errorMessage={houseConsumptionThreshold.message}
                    />
                  </Form.Row>
                </Fragment>
              )}
              <Form.Row>
                <OperationModeParams
                  label={UNITSETT_GENERAL_DEFAULT_MODE}
                  change={change}
                  form={form}
                  disabled={!userCanEditAllButViewer}
                  settings
                  full={false}
                  dirty={dirty}
                />
              </Form.Row>
              {!batteryReadyMode && (
                <Form.Row name={'bmsBackupLevel'}>
                  <InputText
                    id={'BatteryBackupLevel'}
                    label={UNITSETT_GENERAL_BAT_BACKUP_LEVEL}
                    type={'number'}
                    min={0}
                    max={100}
                    disabled={!userCanEditAllButViewer}
                    value={
                      bmsBackupLevel.value === null ? '' : bmsBackupLevel.value
                    }
                    error={dirty && !bmsBackupLevel.valid}
                    onChange={({ target }) =>
                      change({ bmsBackupLevel: target.value })
                    }
                  />
                  <FormFieldError
                    id={'BatteryBackupLevel'}
                    showError={dirty && !bmsBackupLevel.valid}
                    errorMessage={bmsBackupLevel.message}
                  />
                </Form.Row>
              )}
              <Form.Row name={'countryName'}>
                <InputText
                  id={'Country'}
                  label={UNITSETT_GENERAL_COUNTRY}
                  value={countryName.value}
                  error={dirty && !countryName.valid}
                  disabled
                  onChange={({ target }) =>
                    change({ countryName: target.value })
                  }
                />
                <FormFieldError
                  id={'Country'}
                  showError={dirty && !countryName.valid}
                  errorMessage={countryName.message}
                  full={isMobile}
                />
              </Form.Row>
              <Form.Row name={'cityName'}>
                <InputText
                  id={'City'}
                  label={UNITSETT_GENERAL_CITY}
                  value={cityName.value}
                  error={dirty && !cityName.valid}
                  disabled
                  onChange={({ target }) => change({ cityName: target.value })}
                />
                <FormFieldError
                  id={'City'}
                  showError={dirty && !cityName.valid}
                  errorMessage={cityName.message}
                  full={isMobile}
                />
              </Form.Row>
              <Form.Row name={'timezoneName'}>
                <InputText
                  id={'Timezone'}
                  label={UNITSETT_GENERAL_TIMEZONE}
                  value={timezoneName.value || ''}
                  error={dirty && !timezoneName.valid}
                  disabled
                  onChange={({ target }) =>
                    change({ timezoneName: target.value })
                  }
                />
                <FormFieldError
                  id={'Timezone'}
                  showError={dirty && !timezoneName.valid}
                  errorMessage={timezoneName.message}
                  full={isMobile}
                />
              </Form.Row>
              <Form.Row name={'address'}>
                <InputText
                  label={UNITSETT_GENERAL_REGION}
                  error={dirty && !address.valid}
                  value={address.value}
                  disabled={!userCanEditOwnerTech}
                  onChange={({ target }) => change({ address: target.value })}
                  inputProps={{
                    maxLength: 90
                  }}
                />
                <FormFieldError
                  id={'Address'}
                  showError={dirty && !address.valid}
                  errorMessage={address.message}
                />
              </Form.Row>
              <Form.Row name={'postalCode'}>
                <InputText
                  id={'PostalCode'}
                  label={UNITSETT_GENERAL_POSTAL_CODE}
                  error={dirty && !postalCode.valid}
                  value={postalCode.value || ''}
                  disabled={!userCanEditOwnerTech}
                  onChange={({ target }) =>
                    change({ postalCode: target.value })
                  }
                  inputProps={{
                    maxLength: 90
                  }}
                />
                <FormFieldError
                  id={'PostalCode'}
                  showError={dirty && !postalCode.valid}
                  errorMessage={postalCode.message}
                />
              </Form.Row>
              {showAllFields && (
                <Form.Row name={'firmwareVersion'}>
                  <InputText
                    id={'FirmwareVersion'}
                    label={UNITSETT_GENERAL_XSTORAGE_VERSION}
                    value={firmwareVersion || ''}
                    disabled
                  />
                </Form.Row>
              )}
              <Form.Row name={'inverterSerialNumber'}>
                <InputText
                  id={'InverterSerialNumber'}
                  label={UNITSETT_GENERAL_INVERTER_SN}
                  value={inverterSerialNumber}
                  disabled
                />
              </Form.Row>
              <Form.Row name={'inverterFirmwareVersion'}>
                <InputText
                  id={'InverterFirmwareVersion'}
                  label={UNITSETT_GENERAL_INVERTER_SOFT_VERSION}
                  value={`${inverterFirmwareVersion}`}
                  disabled
                />
              </Form.Row>
              {!batteryReadyMode && (
                <Form.Row name={'bmsSerialNumber'}>
                  <InputText
                    id={'BmsSerialNumber'}
                    label={UNITSETT_GENERAL_BAT_SERIAL_NUMBER}
                    value={bmsSerialNumber || ''}
                    disabled
                  />
                </Form.Row>
              )}
              {showAllFields && (
                <React.Fragment>
                  {!batteryReadyMode && (
                    <React.Fragment>
                      <Form.Row name={'bmsFirmwareVersion'}>
                        <InputText
                          id={'BmsFirmwareVersion'}
                          label={UNITSETT_GENERAL_BAT_MANG_SYS_VERSION}
                          value={bmsFirmwareVersion || ''}
                          disabled
                        />
                      </Form.Row>
                      <Form.Row name={'bmsCapacity'}>
                        <InputText
                          id={'BmsCapacity'}
                          label={UNITSETT_GENERAL_SYS_CAPACITY}
                          value={bmsCapacity || ''}
                          disabled
                        />
                      </Form.Row>
                    </React.Fragment>
                  )}
                  <Form.Row name={'dns'}>
                    <InputText
                      id={'Dns'}
                      label={UNITSETT_GENERAL_NETWORK_INTERFACE_DNS}
                      value={dns || ''}
                      disabled
                    />
                  </Form.Row>
                  {networkInterfaces &&
                    networkInterfaces.map((network, index) => (
                      <Form.Row key={network.id}>
                        <NetworkInterface>
                          <InterfaceName id={`InterfaceName_network_${index}`}>
                            {UNITSETT_GENERAL_NETWORK_INTERFACE} {network.name}
                          </InterfaceName>
                          <InterfaceDetails>
                            <InterfaceInfo
                              id={`InterfaceInfoIp_network_${index}`}
                            >
                              <b>{UNITSETT_GENERAL_NETWORK_INTERFACE_IP}</b>{' '}
                              {network.ipAddress}
                            </InterfaceInfo>
                            <InterfaceInfo
                              id={`InterfaceInfoMac_network_${index}`}
                            >
                              <b>{UNITSETT_GENERAL_NETWORK_INTERFACE_MAC}</b>{' '}
                              {network.macAddress}
                            </InterfaceInfo>
                          </InterfaceDetails>
                        </NetworkInterface>
                      </Form.Row>
                    ))}
                </React.Fragment>
              )}

              {/* <Form.Row>
                  <InputText
                    label={UNITSETT_GENERAL_COM_CARD_FIRM_VERSION}
                    defaultValue={inverterSerialNumber || `-`}
                    disabled
                  />
                </Form.Row> */}

              {showAllFields && (
                <React.Fragment>
                  <Form.Row name={'inverterIsSinglePhase'}>
                    <InputText
                      id={'InverterIsSinglePhase'}
                      label={UNITSETT_GENERAL_THIRD_PHASE_OF_SPP}
                      value={
                        inverterIsSinglePhase
                          ? UNITSETT_GENERAL_SINGLE_PHASE_OF_POWER
                          : UNITSETT_GENERAL_THIRD_PHASE_OF_POWER
                      }
                      disabled
                    />
                  </Form.Row>
                  {OrderedPowerMeters.map((meter, index) => (
                    <Form.Row name={`meter_${index + 1}`} key={meter.id}>
                      <InputText
                        id={`MeterModel_${index}`}
                        label={`Meter ${index + 1} model`}
                        value={meter.model}
                        disabled
                      />
                    </Form.Row>
                  ))}
                  <Form.Row name={'hasPv'}>
                    <InputText
                      id={'HasPv'}
                      label={UNITSETT_GENERAL_HAS_PV}
                      value={hasPv ? 'Yes' : 'No'}
                      disabled
                    />
                  </Form.Row>
                  <Form.Row name={'hasBattery'}>
                    <InputText
                      id={'HasBattery'}
                      label={UNITSETT_GENERAL_BATTERY_CONNECTED}
                      value={hasBattery ? 'Yes' : 'No'}
                      disabled
                    />
                  </Form.Row>
                </React.Fragment>
              )}

              {isMobile && (
                <ShowButton onClick={toggleMore}>
                  {more
                    ? UNITSETT_GENERAL_SHOW_LESS
                    : UNITSETT_GENERAL_SHOW_MORE}
                  <Icon>{more ? 'expand_less' : 'expand_more'}</Icon>
                </ShowButton>
              )}
            </Form>
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(DeviceGeneralSettings)

const Wrapper = styled.div``

const ShowButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  color: #007bc1;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`

const NetworkInterface = styled.div`
  color: #424e54;
  font-family: 'Open Sans';
  padding: 0 0 16px 8px;
  box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.1);
`

const InterfaceName = styled.label`
  font-weight: bold;
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgba(114, 126, 132, 0.5);
`

const InterfaceDetails = styled.div`
  opacity: 0.6;
  padding-top: 10px;
`

const InterfaceInfo = styled.div`
  width: 50%;
  display: inline-block;
  font-size: 14px;
`

const PowerSwitch = styled(Switch)`
  &&&& {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .checked {
      color: #007bc1;
    }
    .checked + .bar {
      background-color: #007bc1;
      opacity: 0.4;
    }
  }
`

const SwitchLabel = styled(FormControlLabel)`
  height: 48px;
  position: relative;
  margin-left: 0 !important;
  margin-right: 0;
  width: 100%;
  padding-left: 8px;

  > span:nth-child(2) {
    width: 85%;
    color: #424e54;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
  }
  box-shadow: inset 0 -1px 0 0 rgba(114, 126, 132, 0.2);

  ${props =>
    props.disabled &&
    `
    opacity: 0.6;
  `}
`

const Label = styled.p`
  color: #5b6770;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  padding-left: 8px;
  margin-bottom: 0;

  ${props =>
    props.disabled &&
    `
    color: rgba(114,126,132,0.5);
  `}
`

const EnergySavingDiv = styled.div`
  ${props =>
    props.disabled &&
    `
  opacity: 0.6;
`}
`
