/**
 * Router Base
 * Add new routes here if you want
 * Uses react-router-redux
 */
// @flow
import React from 'react'

import toArray from 'lodash/toArray'
import {
  TECHNICIAN_USER_TYPE,
  CONSUMER_USER_TYPE,
  DEFAULT_USER_TYPE
} from 'config'

import {
  IcWaifUnits,
  IcMyInstallations,
  IcInstallations,
  IcScrewdriver
} from 'xstorage-components'

// Import page components bellow here
// Keep them organized as the routes object
/* eslint-disable import/first */
/** This routes need to be dynamically required
 * so the tests dont fail with kea connects
 **/
// const App = () => require('containers/App').default
const Dashboard = () => require('scenes/DashboardMenu/Dashboard').default
const OperationMode = () =>
  require('scenes/DashboardMenu/OperationMode').default
const Schedule = () => require('scenes/ScheduleMenu/Schedule').default
const ScheduleEvent = () => require('scenes/ScheduleMenu/ScheduleEvent').default
const DeviceSettings = () =>
  require('scenes/SettingsMenu/DeviceSettings').default
const DeviceGeneralSettings = () =>
  require('scenes/SettingsMenu/DeviceGeneralSettings').default
const DeviceUsers = () =>
  require('scenes/SettingsMenu/Users/DeviceUsers').default
const DeviceUserInfo = () =>
  require('scenes/SettingsMenu/Users/DeviceUserInfo').default
const DeviceUpdates = () => require('scenes/SettingsMenu/DeviceUpdates').default
const DeviceChangeOwnership = () =>
  require('scenes/SettingsMenu/DeviceChangeOwnership').default
const DeviceSupport = () => require('scenes/SettingsMenu/DeviceSupport').default
const MaintenanceMetricsSync = () =>
  require('scenes/MaintenanceMenu/MaintenanceMetricsSync').default
const MaintenanceMonitoring = () =>
  require('scenes/MaintenanceMenu/MaintenanceMonitoring').default
const MaintenanceDatetimeSync = () =>
  require('scenes/MaintenanceMenu/MaintenanceDatetimeSync').default
const MaintenanceUnitLog = () =>
  require('scenes/MaintenanceMenu/MaintenanceUnitLog').default
const MaintenanceSshConnection = () =>
  require('scenes/MaintenanceMenu/MaintenanceSshConnection').default
const More = () => require('scenes/More').default
const Notifications = () => require('scenes/Notifications').default
const NotFound = () => require('scenes/NotFound').default
const Chart = () => require('scenes/Chart').default
const AddDevice = () => require('scenes/AddDevice').default
const Unauthorized = () => require('scenes/Unauthorized').default
const Onboarding = () => require('scenes/Onboarding').default
const InternetExplorer = () => require('scenes/InternetExplorer').default
const Terms = () => require('scenes/Terms').default
const TermsAndConditions = () => require('scenes/TermsAndConditions').default
const TermsAndEula = () => require('scenes/TermsAndEula').default
const UserProfile = () => require('scenes/UserProfile').default
const UserProfileInfo = () => require('scenes/UserProfileInfo').default
const UserProfileChangePass = () =>
  require('scenes/UserProfileChangePass').default
const UserProfileLanguage = () => require('scenes/UserProfileLanguage').default
const UserProfileWeather = () => require('scenes/UserProfileWeather').default
const UserProfileDownloadInfo = () =>
  require('scenes/UserProfileDownloadInfo').default
const UserProfileDelete = () => require('scenes/UserProfileDelete').default
const TechnicianUnits = () =>
  require('scenes/TechnicianProfile/TechnicianUnits').default
const TechnicianUntakenUnits = () =>
  require('scenes/TechnicianProfile/TechnicianUntakenUnits').default
const TechnicianUnitsSearch = () =>
  require('scenes/TechnicianProfile/TechnicianUnitsSearch').default
const TechnicianUnitDetails = () =>
  require('scenes/TechnicianProfile/TechnicianUnitDetails').default
const TechnicianInstallations = () =>
  require('scenes/TechnicianProfile/TechnicianInstallations').default
const DeviceInvite = () => require('scenes/DeviceInvite').default
const DeviceDelete = () => require('scenes/DeviceDelete').default
const MaintenanceTechParams = () =>
  require('scenes/MaintenanceMenu/MaintenanceTechParams').default
const MaintenanceChangeUnitParams = () =>
  require('scenes/MaintenanceMenu/MaintenanceChangeUnitParams').default
const MaintenanceMenuMobile = () =>
  require('scenes/MaintenanceMenu/MaintenanceMenuMobile').default
const UserProfileMyApps = () => require('scenes/UserProfileMyApps').default
const UserProfileMyAppsDetails = () =>
  require('scenes/UserProfileMyAppsDetails').default
const ExternalAppChooseUnits = () =>
  require('scenes/ExternalAppChooseUnits').default

const ExternalAppUpdateSuccess = () =>
  require('scenes/ExternalAppUpdateSuccess').default
const MaintenanceHttpsTunnel = () =>
  require('scenes/MaintenanceMenu/MaintenanceHttpsTunnel').default
/* injector */
/* eslint-enable import/first */

/**
 * Selectors function
 * values in selectors are injected in App logic, navItems Selector
 */
const isMobile = selectors => selectors && selectors.isMobile
const isDesktop = selectors => selectors && !selectors.isMobile

const userWithoutDevices = ({ currentDevice }) =>
  currentDevice && !currentDevice.id

const isGermany = selectors =>
  selectors && selectors.isGermany && !selectors.showHelpTour
const userIsTechnician = selectors => selectors && selectors.userIsTechnician
const userIsConsumer = selectors => selectors && !selectors.userIsTechnician

const unitRelatedPage = selectors => selectors && selectors.unitRelatedPage
const techRelatedPage = selectors =>
  selectors && !selectors.unitRelatedPage && userIsTechnician(selectors)

const isNotProfile = selectors =>
  selectors &&
  selectors.currentRoute &&
  selectors.currentRoute.key &&
  selectors.currentRoute.key.indexOf([
    'AddDevice',
    'Terms',
    'TermsAndConditions',
    'UserProfile'
  ]) !== -1

const isDesktopOrTechRelatedPage = selectors =>
  isDesktop(selectors) || techRelatedPage(selectors)
const isDesktopOrUnitRelatedPage = selectors =>
  isDesktop(selectors) || unitRelatedPage(selectors)
const isMobileOrTechRelatedPage = selectors =>
  isMobile(selectors) || techRelatedPage(selectors)

// Conditions needed to show the icons in the profile pages
const isMobileOrTechRelatedPageAndisNotProfile = selectors =>
  isMobile(selectors) ||
  (techRelatedPage(selectors) &&
    ((userIsConsumer(selectors) && isNotProfile(selectors)) ||
      userIsTechnician(selectors)))

const IsGermanyOrTechRelatedPageAndisNotProfile = selectors =>
  isGermany(selectors) ||
  (techRelatedPage(selectors) &&
    ((userIsConsumer(selectors) && isNotProfile(selectors)) ||
      userIsTechnician(selectors)))

const isNotProfileAndTechRelatedPage = selectors =>
  techRelatedPage(selectors) &&
  ((userIsConsumer(selectors) && isNotProfile(selectors)) ||
    userIsTechnician(selectors))

const COMMON_ROUTES = {
  Dashboard: {
    key: 'dashboard',
    name: 'MENU_DASHBOARD',
    path: '/:id/dashboard/',
    component: Dashboard,
    crumb: [],
    exact: true,
    navbar: true,
    hide: isNotProfileAndTechRelatedPage,
    icon: 'home'
  },

  Chart: {
    key: 'chart',
    name: 'MENU_CHART',
    path: '/:id/chart/',
    component: Chart,
    crumb: [],
    exact: true,
    navbar: true,
    hide: isNotProfileAndTechRelatedPage,
    disabled: userWithoutDevices,
    icon: 'equalizer'
  },

  UserProfile: {
    key: 'UserProfile',
    name: 'UserProfile',
    path: '/user-profile/',
    component: UserProfile,
    hideNavBar: isMobile,
    onlyMobile: true,
    bgPartial: true,
    exact: true,
    crumb: [],
    tag: 'UserProfile'
  },

  UserProfileInfo: {
    key: 'UserProfileInfo',
    name: 'UserProfileInfo',
    path: '/user-profile/info/',
    component: UserProfileInfo,
    hideNavBar: isMobile,
    bgPartial: true,
    exact: true,
    crumb: [],
    tag: 'UserProfile'
  },

  UserProfileChangePass: {
    key: 'UserProfileChangePass',
    name: 'UserProfileChangePass',
    path: '/user-profile/change-password/',
    component: UserProfileChangePass,
    hideNavBar: isMobile,
    bgPartial: true,
    exact: true,
    crumb: [],
    tag: 'UserProfile'
  },

  UserProfileDownloadInfo: {
    key: 'UserProfileDownloadInfo',
    name: 'UserProfileDownloadInfo',
    path: '/user-profile/download/',
    component: UserProfileDownloadInfo,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: [],
    tag: 'UserProfile'
  },

  UserProfileMyApps: {
    key: 'UserProfileMyApps',
    name: 'UserProfileMyApps',
    path: '/user-profile/my-apps/',
    component: UserProfileMyApps,
    hideNavBar: isMobile,
    bgPartial: true,
    tag: 'UserProfile',
    crumb: ['inicio'],
    exact: true
  },

  UserProfileMyAppsDetails: {
    key: 'UserProfileMyAppsDetails',
    name: 'UserProfileMyAppsDetails',
    path: '/user-profile/my-apps/:appid/',
    component: UserProfileMyAppsDetails,
    hideNavBar: isMobile,
    bgPartial: true,
    tag: 'UserProfile',
    crumb: ['inicio'],
    exact: true
  },

  UserProfileDelete: {
    key: 'UserProfileDelete',
    name: 'UserProfileDelete',
    path: '/user-profile/delete/',
    component: UserProfileDelete,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: [],
    tag: 'UserProfile'
  },

  UserProfileLanguage: {
    key: 'UserProfileLanguage',
    name: 'UserProfileLanguage',
    path: '/user-profile/language/',
    component: UserProfileLanguage,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: [],
    tag: 'UserProfile'
  },

  UserProfileWeather: {
    key: 'UserProfileWeather',
    name: 'UserProfileWeather',
    path: '/user-profile/weather/',
    component: UserProfileWeather,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: [],
    tag: 'UserProfile'
  },

  InternetExplorer: {
    key: 'InternetExplorer',
    name: 'InternetExplorer',
    path: '/not-supported',
    component: InternetExplorer,
    crumb: ['inicio']
  },

  notfound: {
    key: 'notfound',
    name: 'Página não encontrada',
    path: '/404',
    component: NotFound,
    crumb: []
  },

  unauthorized: {
    key: 'unauthorized',
    name: 'Unauthorized',
    path: '/401',
    component: Unauthorized,
    crumb: []
  },

  FilteredChart: {
    key: 'filteredChart',
    tag: 'chart',
    path: '/:id/chart/:filter/',
    component: Chart,
    crumb: [],
    exact: true
  },

  Schedule: {
    key: 'schedule',
    name: 'MENU_SCHEDULE',
    path: '/:id/schedule/',
    component: Schedule,
    crumb: [],
    exact: true,
    navbar: true,
    hide: IsGermanyOrTechRelatedPageAndisNotProfile,
    disabled: userWithoutDevices,
    icon: 'calendar_today'
  },

  ScheduleEvent: {
    key: 'ScheduleEvent',
    name: 'ScheduleEvent',
    path: '/:id/schedule/event/',
    component: ScheduleEvent,
    hideNavBar: isMobile,
    onlyMobile: true,
    exact: true,
    crumb: []
  },

  ScheduleEventEdit: {
    key: 'ScheduleEventEdit',
    name: 'ScheduleEventEdit',
    path: '/:id/schedule/event/:eventId/',
    component: ScheduleEvent,
    hideNavBar: isMobile,
    onlyMobile: true,
    exact: true,
    crumb: []
  },

  DeviceSettings: {
    key: 'DeviceSettings',
    name: 'Settings',
    path: '/:id/settings/',
    component: DeviceSettings,
    onlyMobile: true,
    hideNavBar: isMobile,
    crumb: [],
    exact: true,
    disabled: userWithoutDevices,
    bgPartial: true
  },

  DeviceGeneralSettings: {
    key: 'DeviceGeneralSettings',
    name: 'DeviceGeneralSettings',
    path: '/:id/settings/general/',
    component: DeviceGeneralSettings,
    exact: true,
    crumb: [],
    navbar: true,
    hide: isMobileOrTechRelatedPageAndisNotProfile,
    disabled: userWithoutDevices,
    hideNavBar: isMobile,
    bgPartial: true,
    icon: 'settings'
  },

  DeviceUsers: {
    key: 'DeviceUsers',
    name: 'DeviceUsers',
    path: '/:id/settings/users/',
    component: DeviceUsers,
    tag: 'DeviceGeneralSettings',
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: []
  },

  DeviceUserInfo: {
    key: 'DeviceUserInfo',
    name: 'DeviceUserInfo',
    path: '/:id/settings/users/:userId/:type?',
    tag: 'DeviceGeneralSettings',
    component: DeviceUserInfo,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: []
  },

  DeviceUpdates: {
    key: 'DeviceUpdates',
    name: 'DeviceUpdates',
    path: '/:id/settings/updates/',
    tag: 'DeviceGeneralSettings',
    component: DeviceUpdates,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: []
  },

  DeviceChangeOwnership: {
    key: 'DeviceChangeOwnership',
    name: 'DeviceChangeOwnership',
    path: '/:id/settings/change-ownership/',
    tag: 'DeviceGeneralSettings',
    component: DeviceChangeOwnership,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: []
  },

  DeviceDelete: {
    key: 'DeviceDelete',
    name: 'DeviceDelete',
    path: '/:id/settings/delete-unit/',
    tag: 'DeviceGeneralSettings',
    component: DeviceDelete,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: []
  },

  DeviceSupportList: {
    key: 'DeviceSupportList',
    name: 'DeviceSupportList',
    path: '/:id/settings/support/',
    tag: 'DeviceGeneralSettings',
    component: DeviceSupport,
    hideNavBar: isMobile,
    exact: true,
    bgPartial: true,
    crumb: []
  },

  OperationMode: {
    key: 'OperationMode',
    name: 'OperationMode',
    path: '/:id/operation-mode/',
    component: OperationMode,
    hideNavBar: isMobile,
    onlyMobile: true,
    exact: true,
    crumb: []
  },

  more: {
    key: 'more',
    name: 'MENU_MORE',
    path: '/:id/more/',
    component: More,
    crumb: [],
    exact: true,
    navbar: true,
    hide: isDesktopOrTechRelatedPage,
    onlyMobile: true,
    icon: 'more_horiz'
  },

  Terms: {
    key: 'Terms',
    name: 'Terms',
    path: '/terms/',
    component: Terms,
    hideNavBar: isMobile,
    exact: true,
    crumb: []
  },

  TermsAndConditions: {
    key: 'TermsAndConditions',
    name: 'TermsAndConditions',
    path: '/terms/conditions/',
    component: TermsAndConditions,
    hideNavBar: isMobile,
    exact: true,
    crumb: []
  },

  TermsAndEula: {
    key: 'TermsAndEula',
    name: 'TermsAndEula',
    path: '/terms/eula/',
    component: TermsAndEula,
    hideNavBar: isMobile,
    exact: true,
    crumb: []
  },

  Notifications: {
    key: 'Notifications',
    name: 'Notifications',
    path: '/notifications/',
    component: Notifications,
    hideNavBar: isMobile,
    onlyMobile: true,
    exact: true,
    crumb: []
  },

  SupportUnitLog: {
    key: 'SupportUnitLog',
    name: 'SupportUnitLog',
    path: '/:id/settings/support/unit-log',
    component: MaintenanceUnitLog,
    tag: 'DeviceGeneralSettings',
    bgPartial: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  SupportHttpsTunnel: {
    key: 'SupportHttpsTunnel',
    name: 'SupportHttpsTunnel',
    path: '/:id/settings/support/https-tunnel',
    component: MaintenanceHttpsTunnel,
    tag: 'DeviceGeneralSettings',
    hideNavBar: isMobile,
    bgPartial: true,
    crumb: ['inicio']
  },

  ExternalAppChooseUnits: {
    key: 'ExternalAppChooseUnits',
    name: 'ExternalAppChooseUnits',
    path: '/hub/access_request/:accessid/:usercode',
    component: ExternalAppChooseUnits,
    crumb: ['inicio'],
    trdPartyApp: true,
    exact: true
  },

  ExternalAppUpdateSuccess: {
    key: 'ExternalAppUpdateSuccess',
    name: 'ExternalAppUpdateSuccess',
    path: '/hub/access_request/:accessid/:usercode/update-success/',
    component: ExternalAppUpdateSuccess,
    crumb: ['inicio'],
    trdPartyApp: true,
    exact: true
  }
}

// Define routes here
const CONSUMER_ROUTES: any = {
  AddDevice: {
    key: 'AddDevice',
    name: 'AddDevice',
    path: '/add-device/',
    component: AddDevice,
    hideWheather: true,
    exact: true,
    crumb: []
  },

  OnBoarding: {
    key: 'OnBoarding',
    name: 'OnBoarding',
    path: '/onboarding/:token/',
    component: Onboarding,
    hideNavBar: true,
    hideWheather: true,
    hideUserBar: true,
    exact: true,
    crumb: []
  },

  DeviceInvite: {
    key: 'DeviceInvite',
    name: 'DeviceInvite',
    path: '/invite/:token/',
    component: DeviceInvite,
    hideNavBar: isDesktop,
    hideWheather: true,
    exact: true,
    crumb: []
  },

  DeviceOwnership: {
    key: 'DeviceOwnership',
    name: 'DeviceOwnership',
    path: '/ownership/:token/',
    component: DeviceInvite,
    hideNavBar: isDesktop,
    hideWheather: true,
    exact: true,
    crumb: []
  }
}

const TECHNICIAN_ROUTES: any = {
  TechnicianUnits: {
    key: 'TechnicianUnits',
    name: 'MENU_MYUNITS',
    path: '/',
    component: TechnicianUnits,
    exact: true,
    navbar: true,
    hide: unitRelatedPage,
    icon: <IcMyInstallations />,
    hideWheather: true,
    crumb: []
  },

  TechnicianUntakenUnits: {
    key: 'TechnicianUntakenUnits',
    name: 'MENU_UNTAKENUNITS',
    path: '/untaken-units/',
    component: TechnicianUntakenUnits,
    exact: true,
    navbar: true,
    hide: unitRelatedPage,
    icon: <IcWaifUnits />,
    hideWheather: true,
    crumb: []
  },

  TechnicianUnitsSearch: {
    key: 'TechnicianUnitsSearch',
    name: 'TechnicianUnitsSearch',
    path: '/units-search/',
    component: TechnicianUnitsSearch,
    crumb: [],
    onlyMobile: true,
    hideNavBar: isMobile,
    hideWheather: true,
    exact: true
  },

  TechnicianUnitDetails: {
    key: 'TechnicianUnitDetails',
    name: 'TechnicianUnitDetails',
    path: '/unit/:unitId/',
    component: TechnicianUnitDetails,
    crumb: [],
    onlyMobile: true,
    hideNavBar: isMobile,
    hideWheather: true,
    exact: true
  },

  TechnicianUnitAdd: {
    key: 'TechnicianUnitAdd',
    name: 'TechnicianUnitAdd',
    path: '/add-unit/',
    component: TechnicianUnitDetails,
    crumb: [],
    onlyMobile: true,
    hideNavBar: isMobile,
    hideWheather: true,
    exact: true
  },

  TechnicianInstallations: {
    key: 'TechnicianInstallations',
    name: 'MENU_INSTALLATIONS',
    path: '/installations/',
    component: TechnicianInstallations,
    icon: <IcInstallations />,
    navbar: true,
    hide: unitRelatedPage,
    hideWheather: true,
    crumb: ['inicio']
  },

  moreTechnician: {
    key: 'moretech',
    name: 'MENU_MORE',
    path: '/tech-more/',
    component: More,
    crumb: [],
    exact: true,
    navbar: true,
    hide: isDesktopOrUnitRelatedPage,
    onlyMobile: true,
    hideWheather: true,
    icon: 'more_horiz'
  }
}

const TECHNICIAN_UNIT_ROUTES: any = {
  TechUnitNotifications: {
    key: 'TechUnitNotifications',
    name: 'TechUnitNotifications',
    path: '/:id/notifications/',
    component: Notifications,
    hideNavBar: isMobile,
    onlyMobile: true,
    exact: true,
    crumb: []
  },

  MaintenanceTechParams: {
    key: 'MaintenanceTechParams',
    name: 'MaintenanceTechParams',
    path: '/:id/maintenance/techparams',
    component: MaintenanceTechParams,
    navbar: true,
    hide: isMobileOrTechRelatedPage,
    tag: 'MaintenanceTechParams',
    bgPartial: true,
    hideNavBar: isMobile,
    icon: <IcScrewdriver />,
    crumb: ['inicio']
  },

  MaintenanceMetricsSync: {
    key: 'MaintenanceMetricsSync',
    name: 'MaintenanceMetricsSync',
    path: '/:id/maintenance/metrics-sync',
    component: MaintenanceMetricsSync,
    tag: 'MaintenanceTechParams',
    bgPartial: true,
    exact: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  MaintenanceDatetimeSync: {
    key: 'MaintenanceDatetimeSync',
    name: 'MaintenanceDatetimeSync',
    path: '/:id/maintenance/datetime-sync',
    component: MaintenanceDatetimeSync,
    tag: 'MaintenanceTechParams',
    bgPartial: true,
    exact: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  MaintenanceMonitoring: {
    key: 'MaintenanceMonitoring',
    name: 'MaintenanceMonitoring',
    path: '/:id/maintenance/monitoring',
    component: MaintenanceMonitoring,
    tag: 'MaintenanceTechParams',
    bgPartial: true,
    exact: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  MaintenanceChangeUnitParams: {
    key: 'MaintenanceChangeUnitParams',
    name: 'MaintenanceChangeUnitParams',
    path: '/:id/maintenance/unit-params',
    tag: 'MaintenanceTechParams',
    component: MaintenanceChangeUnitParams,
    bgPartial: true,
    exact: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  MaintenanceUnitLog: {
    key: 'MaintenanceUnitLog',
    name: 'MaintenanceUnitLog',
    path: '/:id/maintenance/unit-log',
    component: MaintenanceUnitLog,
    tag: 'MaintenanceTechParams',
    bgPartial: true,
    exact: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  MaintenanceSshConnection: {
    key: 'MaintenanceSshConnection',
    name: 'MaintenanceSshConnection',
    path: '/:id/maintenance/ssh-connection',
    component: MaintenanceSshConnection,
    tag: 'MaintenanceTechParams',
    bgPartial: true,
    exact: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  MaintenanceHttpsTunnel: {
    key: 'MaintenanceHttpsTunnel',
    name: 'MaintenanceHttpsTunnel',
    path: '/:id/maintenance/https-tunnel/',
    component: MaintenanceHttpsTunnel,
    tag: 'MaintenanceTechParams',
    bgPartial: true,
    exact: true,
    hideNavBar: isMobile,
    crumb: ['inicio']
  },

  MaintenanceMenuMobile: {
    key: 'MaintenanceMenuMobile',
    name: 'MaintenanceMenuMobile',
    path: '/:id/maintenance/',
    tag: 'MaintenanceMenuMobile',
    component: MaintenanceMenuMobile,
    hideNavBar: isMobile,
    bgPartial: true,
    exact: true,
    onlyMobile: true,
    crumb: ['inicio']
  }
}

/**
 * Function that returns the routes for current user type
 *
 * @param {*} userType
 */
export const getRoutesForUser = (userType: string = DEFAULT_USER_TYPE): any => {
  const routes = {
    [CONSUMER_USER_TYPE]: { ...CONSUMER_ROUTES, ...COMMON_ROUTES },
    [TECHNICIAN_USER_TYPE]: {
      ...TECHNICIAN_ROUTES,
      ...COMMON_ROUTES,
      ...TECHNICIAN_UNIT_ROUTES
    }
  }

  return routes[userType]
}

export const Routes = {
  ...COMMON_ROUTES,
  ...CONSUMER_ROUTES,
  ...TECHNICIAN_ROUTES,
  ...TECHNICIAN_UNIT_ROUTES
}
export default toArray(Routes)
