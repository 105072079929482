// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'
import FormFieldError from 'containers/FormFieldError'
import FormGeneralError from 'containers/FormGeneralError'

import {
  PageTitle,
  PageMainSection,
  SpaceDiv,
  BackButton,
  Form,
  CheckboxesField,
  Divider,
  List,
  ConfirmationModal,
  Button,
  SuccessSavedAlert,
  MobileActionButton
} from 'xstorage-components'

type Props = {
  loading: boolean,
  isMobile: boolean,
  translations: {
    USERPROF_TITLE: string,
    USERPROF_MYAPPDETAILS_CHOOSEACCESS_BASIC: string,
    USERPROF_MYAPPDETAILS_REMOVEAPPACCESS: string,
    USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS: string,
    USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS_AREYOUSURE: string,
    USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS_UNDOABLE: string,
    USERPROF_MYAPPDETAILS_MODAL_CANCEL: string,
    USERPROF_MYAPPDETAILS_MODAL_REMOVE: string,
    USERPROF_MYAPPDETAILS_UPDATE: string,
    USERPROF_MYAPPDETAILS_CANCEL: string,
    USERPROF_MYAPPDETAILS_CHANGE_SUCCESS: string
  },
  appDetails: {
    appName: string,
    organization: string,
    scope: string
  },
  error: string,
  unitsList: [],
  dirty: boolean,
  form: {
    unitsGranted: {
      value: [],
      valid: boolean,
      message: string
    }
  },
  removeAppModal: boolean,
  successMessage: boolean
}

class UserProfileMyAppsDetails extends Component<Props, void> {
  actions: {
    navigate: Function,
    change: Function,
    setRemoveAppModal: Function,
    getAppDetails: Function,
    submit: Function,
    revokeApp: Function
  }

  render () {
    const {
      navigate,
      change,
      setRemoveAppModal,
      getAppDetails,
      submit,
      revokeApp
    } = this.actions

    const {
      isMobile,
      translations,
      loading,
      appDetails,
      error,
      unitsList,
      dirty,
      form,
      removeAppModal,
      successMessage
    } = this.props

    const {
      USERPROF_TITLE,
      USERPROF_MYAPPDETAILS_CHOOSEACCESS_BASIC,
      USERPROF_MYAPPDETAILS_REMOVEAPPACCESS,
      USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS,
      USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS_AREYOUSURE,
      USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS_UNDOABLE,
      USERPROF_MYAPPDETAILS_MODAL_CANCEL,
      USERPROF_MYAPPDETAILS_MODAL_REMOVE,
      USERPROF_MYAPPDETAILS_UPDATE,
      USERPROF_MYAPPDETAILS_CANCEL,
      USERPROF_MYAPPDETAILS_CHANGE_SUCCESS
    } = translations

    const { appName, organization, scope } = appDetails || {}

    const { unitsGranted } = form || {}

    // eslint-disable-next-line max-len
    const scopeDescription = (scope && translations[`USERPROF_MYAPPDETAILS_CHOOSEACCESS_${scope.toUpperCase()}`]) || USERPROF_MYAPPDETAILS_CHOOSEACCESS_BASIC

    const formButtons = (
      <Form.Buttons align={'right'}>
        <Button
          id={'PrimaryButton_Cancel'}
          buttonType={'primary-cancel'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={() => getAppDetails(appDetails.id)}
          disabled={!dirty}
        >
          {USERPROF_MYAPPDETAILS_CANCEL}
        </Button>
        <Button
          id={'PrimaryButton_Save'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={submit}
          disabled={!dirty}
        >
          {USERPROF_MYAPPDETAILS_UPDATE}
        </Button>
      </Form.Buttons>
    )

    return (
      <Wrapper>
        <PageAdaptable
          title={USERPROF_TITLE}
          menu={<UserProfileMenu page={'my-apps'} />}
          onBack={() => navigate('/user-profile/my-apps/')}
          isForm={false}
          loading={loading}
        >
          <PageMainSection padding mobile={isMobile}>
            <SuccessSavedAlert
              mobile={isMobile}
              out={!successMessage}
              icon={<Icon>{'check'}</Icon>}
              message={USERPROF_MYAPPDETAILS_CHANGE_SUCCESS}
            />
            <PageTitle mobile={isMobile}>
              {!isMobile &&
                <BackButton
                  id={'BackButton'}
                  onClick={() => navigate('/user-profile/my-apps/')}
                >
                  <Icon>{'arrow_back'}</Icon>
                </BackButton>
              }
              {appName}, {organization}®
            </PageTitle>
            {isMobile && dirty && (
              <MobileActionButton
                icon={<Icon>{'check'}</Icon>}
                onClick={submit}
                mobile
                id={'MobileActionButton_Update'}
              >
                {USERPROF_MYAPPDETAILS_UPDATE}
              </MobileActionButton>
            )}
            <SpaceDiv height={'34px'} />
            <Typography variant={'h5'} color={'error'}>
              {error}
            </Typography>
            <Typography variant={'h6'} component={'h2'}>
              {scopeDescription}
            </Typography>
            <Form
              containerHeight={
                isMobile ? '60vh' : '57vh'
              }
              showButtons={!isMobile && dirty}
              buttons={formButtons}
            >
              <FormGeneralError dirty={dirty} error={error} full />
              <Form.Row full>
                <CheckboxesField
                  options={unitsList}
                  selected={unitsGranted.value}
                  onChange={value => change({ unitsGranted: value })}
                  maxHeight={'calc(57vh - 70px)'}
                />
                <FormFieldError
                  id={'Granularity'}
                  showError={dirty && !unitsGranted.valid}
                  errorMessage={unitsGranted.message}
                />
              </Form.Row>
              <ActionsRow full>
                <List>
                  <Divider variant={'inset'} component='li' />
                  <List.Item button onClick={() => setRemoveAppModal(true)}>
                    <DeleteItemText
                      primary={USERPROF_MYAPPDETAILS_REMOVEAPPACCESS}
                    />
                    <FloatIcon color={'error'}>
                      {'keyboard_arrow_right'}
                    </FloatIcon>
                  </List.Item>
                </List>
              </ActionsRow>
            </Form>
            <ConfirmationModal
              open={removeAppModal}
              onClose={() => setRemoveAppModal(false)}
              modalTitle={USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS}
              modalContent={
                USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS_AREYOUSURE
              }
              modalDescription={
                USERPROF_MYAPPDETAILS_MODAL_REMOVEAPPACCESS_UNDOABLE
              }
              cancelButtonText={USERPROF_MYAPPDETAILS_MODAL_CANCEL}
              submitButtonText={USERPROF_MYAPPDETAILS_MODAL_REMOVE}
              submitButtonAction={revokeApp}
              dangerModal
            />
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(UserProfileMyAppsDetails)

const Wrapper = styled.div``

const ActionsRow = styled(props => <Form.Row {...props} />)`
  &&&& {
    z-index: 1;
    position: absolute;
    bottom: 0;

    @media screen and (max-width: 1024px) {
      width: calc(100% - 100px);
    }
    @media screen and (max-width: 550px) {
      margin: 16px 12px 0;
      width: calc(100% - 70px);
    }
  }
`

const DeleteItemText = styled(List.ItemText)`
  &&&& {
    span {
      color: #ca3c3d !important;
    }
  }
`

const FloatIcon = styled(Icon)`
  position: absolute;
  right: 0;
`
