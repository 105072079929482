// @flow
import { type Language } from 'types/api'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, FormControlLabel } from '@material-ui/core'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  Loading,
  Button,
  Typography,
  MobileActionButton,
  Radio,
  RadioGroup,
  PageMainSection,
  Form,
  SuccessSavedAlert
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  languages: Array<Language>,
  form: any,
  loading: boolean,
  submiting: boolean
}

export class UserProfileLanguage extends Component<Props, void> {
  render () {
    const {
      isMobile,
      translations,
      languages,
      form,
      loading,
      dirty,
      successMessage
    } = this.props

    const { navigate, change, submit, fetchLanguages } = this.actions

    const { language } = form

    const {
      USERPROF_TITLE,
      USERPROF_CHANGE_LANG_TITLE,
      USERPROF_CHANGE_LANG_SAVE,
      USERPROF_CHANGE_LANG_CANCEL,
      USERPROF_CHANGE_LANG_LABEL,
      USERPROF_CHANGE_LANG_SUCCESS
    } = translations

    const showFormButtons = !isMobile && dirty

    const formButtons = (
      <Form.Buttons align={'right'}>
        <Button
          id={'PrimaryButton_Cancel'}
          buttonType={'primary-cancel'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={fetchLanguages}
          disabled={!showFormButtons}
        >
          {USERPROF_CHANGE_LANG_CANCEL}
        </Button>
        <Button
          id={'PrimaryButton_Save'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={submit}
          disabled={!showFormButtons}
        >
          {USERPROF_CHANGE_LANG_SAVE}
        </Button>
      </Form.Buttons>
    )

    return (
      <Wrapper>
        {loading && <Loading />}
        <PageAdaptable
          title={USERPROF_TITLE}
          menu={<UserProfileMenu page={'change-language'} />}
          onBack={() => navigate('/user-profile/')}
          isForm
          isDirty={dirty}
        >
          <PageMainSection padding mobile={isMobile}>
            <SuccessSavedAlert
              mobile={isMobile}
              out={!successMessage}
              icon={<Icon>{'check'}</Icon>}
              message={USERPROF_CHANGE_LANG_SUCCESS}
            />
            <PageTitle mobile={isMobile}>
              {USERPROF_CHANGE_LANG_TITLE}
            </PageTitle>
            {isMobile && dirty && (
              <MobileActionButton
                icon={<Icon>{'check'}</Icon>}
                onClick={submit}
                mobile
                id={'MobileActionButton_Save'}
              >
                {USERPROF_CHANGE_LANG_SAVE}
              </MobileActionButton>
            )}
            <Form
              mobile={isMobile}
              showButtons={showFormButtons}
              buttons={formButtons}
              containerHeight={
                isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 350px)'
              }
            >
              <Form.Row full={isMobile} margin={isMobile}>
                <Label>{USERPROF_CHANGE_LANG_LABEL}</Label>
                <RadioGroup>
                  {languages.map(lang => (
                    <CustomFormControlLabel
                      key={lang.identifier}
                      value={lang.identifier}
                      control={
                        <Radio
                          onChange={({ target }) =>
                            change({ language: target.value })
                          }
                          checked={language.value === lang.identifier}
                        />
                      }
                      label={lang.name}
                    />
                  ))}
                </RadioGroup>
              </Form.Row>
            </Form>
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(UserProfileLanguage)

const Wrapper = styled.div``

const Label = styled(props => <Typography {...props} />)`
  &&&& {
    font-weight: bold;
    color: #5b6770;
    // margin-top: 40px;
  }
`

const CustomFormControlLabel = styled(FormControlLabel)`
  > span:nth-child(2) {
    color: #424e54;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 20px;
  }
`
