// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import Logic from './logic'

type Props = {}

class TechnicianBackBar extends PureComponent<Props, void> {
  render () {
    const { translations, currentDevice } = this.props
    const { TECH_UNIT_ACCESSING_UNIT } = translations
    const { navigate } = this.actions

    return (
      <Wrapper>
        <ArrowIcon
          id={'Back_Icon'}
          onClick={() => navigate('/')}
        >
          <Icon>{'arrow_back'}</Icon>
        </ArrowIcon>
        <Title id={'TechnicianBar_Title'}>
          {TECH_UNIT_ACCESSING_UNIT} {currentDevice.inverterSerialNumber}
        </Title>
      </Wrapper>
    )
  }
}

export default Logic(TechnicianBackBar)

const Wrapper = styled.div`
  height: 56px;
  width: 100%;
  background-color: #fdfdfd;
  box-shadow: inset 0 -2px 0 0 #f0cb2f, 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 101;
  display: flex;
  align-items: center;
`

const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  color: #007bc1;
  padding-left: 1vw;
  cursor: pointer;
`

const Title = styled.span`
  color: #007bc1;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
`
