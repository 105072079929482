// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { Grid, Typography } from '@material-ui/core'

import * as animationData from './data/no-units.json'

import {
  SpaceDiv,
  Loading,
  Button,
  InputText,
  Form
} from 'xstorage-components'

import FormGeneralError from 'containers/FormGeneralError'
import FormFieldError from 'containers/FormFieldError'

import BGBlue from 'assets/background-blue-tablet.svg'

type Props = {
  isMobile: boolean,
  form: any,
  dirty: boolean,
  loading: boolean,
  error: string,
  translations: any,
  isOwnership: boolean
}

export class DeviceInvite extends Component<Props> {
  actions: any

  render () {
    const {
      isMobile,
      form,
      dirty,
      loading,
      error,
      translations,
      isOwnership
    } = this.props

    const { change, submit } = this.actions

    const { email } = form

    const {
      UNITINVITE_TITLE,
      UNITINVITE_SUBTITLE,
      UNITINVITE_TEXT,
      UNITINVITE_EMAIL,
      UNITINVITE_ACCEPT,
      UNITINVITE_DECLINE,
      UNITINVITE_OWNERSHIP_TITLE,
      UNITINVITE_OWNERSHIP_SUBTITLE,
      UNITINVITE_OWNERSHIP_TEXT
    } = translations

    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData
    }

    const animation = (
      <NoDevicesAnim mobile={isMobile}>
        <Lottie
          options={options}
          isClickToPauseDisabled
          height={isMobile ? 350 : '60vh'}
          width={isMobile ? 400 : '60vh'}
        />
      </NoDevicesAnim>
    )
    const formButtons = (
      <Form.Buttons>
        <Button
          id={'PrimaryButton_Accept'}
          buttonWidth={'163px'}
          margin={'0 12px'}
          onClick={() => submit(false)}
        >
          {UNITINVITE_ACCEPT}
        </Button>
        <Button
          id={'PrimaryButton_Decline'}
          buttonType={'primary-danger'}
          buttonWidth={'163px'}
          margin={'0 12px'}
          onClick={() => submit(true)}
        >
          {UNITINVITE_DECLINE}
        </Button>
      </Form.Buttons>
    )

    let content = (
      <TextContainer mobile={isMobile} onboarding>
        <Typography variant={'h1'} color={'primary'}
          align={isMobile ? 'center' : 'left'}>
          {!isOwnership ? UNITINVITE_TITLE : UNITINVITE_OWNERSHIP_TITLE}
        </Typography>
        <SpaceDiv height={'30px'} />
        <Typography
          variant={'h2'}
          color={'primary'}
          align={isMobile ? 'center' : 'left'}
          paragraph
        >
          {!isOwnership ? UNITINVITE_SUBTITLE : UNITINVITE_OWNERSHIP_SUBTITLE}
        </Typography>
        <SpaceDiv height={'10px'} />
        <Typography
          variant={'h3'}
          color={'textPrimary'}
          align={isMobile ? 'center' : 'left'}
        >
          {!isOwnership ? UNITINVITE_TEXT : UNITINVITE_OWNERSHIP_TEXT}
        </Typography>
        <Form showButtons mobile={isMobile} buttons={formButtons}>
          <FormGeneralError dirty={dirty} error={error} full />
          <Form.Row>
            <InputText
              id={'Email'}
              label={UNITINVITE_EMAIL}
              type='text'
              value={email.value}
              error={dirty && !email.valid}
              onChange={({ target }) =>
                change({ email: target.value.toLowerCase() })
              }
            />
            <FormFieldError
              id={'Email'}
              showError={dirty && !email.valid}
              errorMessage={email.message}
            />
          </Form.Row>
        </Form>
      </TextContainer>
    )

    return (
      <Wrapper mobile={isMobile}>
        {loading && <Loading />}
        {isMobile && (
          <div>
            {animation}
            {content}
          </div>
        )}

        {!isMobile && (
          <div>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {animation}
              </Grid>
              <Grid item xs={6}>
                {content}
              </Grid>
            </Grid>
          </div>
        )}
      </Wrapper>
    )
  }
}

export default Logic(DeviceInvite)

const Wrapper = styled.div`
  width: 100%;
  padding: 25px 24px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px;
  }

  ${({ mobile }) =>
    mobile &&
    `
    min-height: calc(100vh - 56px);
    background: #f7f7f9;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    background-image: url(${BGBlue})
  `}
`

const NoDevicesAnim = styled.div`
  position: relative;

  > * {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 10;
  }

  ${({ mobile }) => !mobile && `
    > * {
      position: fixed;

      left: 50%;
      transform: translateX(-65%);
      top: 20%;
    }

    &:after{
      width: 100vh;
      height: 100vw;
      left: 46vw;
      top: 0%;
      transform: rotate(13deg);
      background: #f7f7f9;
      position: fixed;
      z-index: 10;
    }
  `}

  ${({ mobile }) =>
    mobile &&
    `
    height: 280px;

    > * {
      position: absolute;
      bottom: 0;
      left: calc(50% - 200px);
    }

    &:after{
      width: 242px;
      height: 1400px;
      left: 45%;
      top: -490px;
      transform: rotate(71.4deg);
      background: #f7f7f9;

      @media screen and (max-width: 370px) {
        width: 230px;
        height: 1100px;
        left: 58.5%;
        top: -360px;
      }
    }
  `}
`

const TextContainer = styled.div`
  ${({ mobile }) => mobile && `
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;    
    width: 100%;
    position: relative;
    z-index: 10;
    top: -50px;
  `}

  ${({ mobile }) => !mobile && `
    padding-top: 25%;
    padding-left: 60px;
    position: relative;
    z-index: 10; 
    padding-left: 100px;
    padding-right: 40px;
  `}
`
