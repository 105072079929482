/**
 * Notificationspanel Container
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { PureComponent } from 'react'
import Logic from './logic'
// import styled from 'styled-components'

import SlidePanel from 'components/SlidePanel'
import NotificationsList from 'containers/NotificationsList'

type Props = {
  notificationsVisible: boolean,
  translations: any
}

class NotificationsPanel extends PureComponent<Props, void> {
  actions: any

  render () {
    const {
      notificationsVisible,
      translations,
      notificationsList,
      loadingButton,
      unreadNotifications,
      notificationsTotal,
      showLoadMoreInfo
    } = this.props

    const {
      hideNotifications,
      getNotifications
    } = this.actions

    const {
      NOTIF_TITLE
    } = translations

    return (
      <SlidePanel
        open={notificationsVisible}
        onClose={hideNotifications}
        title={NOTIF_TITLE}
        type={'content-not-padded'}
        marginTitle={'15px 0 8px'}
      >
        <NotificationsList
          notifications={notificationsList}
          notificationsTotal={notificationsTotal}
          unreadNotifications={unreadNotifications}
          loadingButton={loadingButton}
          showLoadMoreInfo={showLoadMoreInfo}
          getNotifications={getNotifications}
        />
      </SlidePanel>
    )
  }
}

export default Logic(NotificationsPanel)
