// @flow
import { type Device, type User, type Role } from 'types/api'
import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'
import find from 'lodash/find'

import { API_BASE_URL } from 'config'
import { getApiErrorKey } from 'apiErrors'
import { log, generateUrl } from 'utils'
import AppLogic from 'containers/App/logic'

const INVITE_ENDPOINT = `${API_BASE_URL}/devices/{id}/roles/{inviteId}`

export default kea({
  path: () => ['scenes', 'DeviceDelete'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDevice',
        'currentDeviceId',
        'userRole',
        'user',
        'devices'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'fetchDevices',
        'setDevices'
      ]
    ]
  },

  actions: () => ({

    deleteUser: () => true,
    reset: () => true,
    showDeleteModal: () => true,
    hideDeleteModal: () => true,
    error: (error) => ({ error }),
    setLoading: (loading) => (loading)
  }),

  reducers: ({ actions }) => ({

    deleteModal: [false, PropTypes.bool, {
      [actions.showDeleteModal]: () => true,
      [actions.hideDeleteModal]: () => false,
      [actions.error]: () => false,
      [actions.reset]: () => false
    }],

    loading: [false, PropTypes.bool, {
      [actions.setLoading]: (state, payload) => payload,
      [actions.error]: () => false,
      [actions.reset]: () => false
    }],

    error: [null, PropTypes.any, {
      [actions.error]: (state, payload) => payload.error,
      [actions.reset]: () => null
    }]

  }),

  selectors: ({ selectors }) => ({

    /** put user permission on a prop */
    isOwner: [
      () => [selectors.userRole],
      (userRole: string) => userRole === 'owner',
      PropTypes.bool
    ]

  }),

  start: function * () {
    const { navigate } = this.actions

    log('[XS-DeviceDelete] Start scene', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-DeviceDelete] Stop scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({

    [actions.deleteUser]: workers.deleteUser

  }),

  workers: {
    * deleteUser () {
      const {
        navigate,
        fetchDevices,
        error,
        setLoading,
        hideDeleteModal,
        setDevices
      } = this.actions

      yield put(setLoading(true))
      yield put(hideDeleteModal())

      try {
        const currentDevice: Device = yield this.get('currentDevice')
        const devices = yield this.get('devices')
        const user: User = yield this.get('user')
        const userRole: string = yield this.get('userRole')

        const { roles } = currentDevice
        if (!roles || !roles.length) {
          return false
        }
        const role: Role = find(roles, role => role.user.id === user.id) || {}

        const params = {
          id: currentDevice.id,
          inviteId: role.id
        }

        const url = generateUrl(INVITE_ENDPOINT, params)
        yield call(axios.delete, url)

        // reset all devices data on App Logic
        if (userRole === 'technician') {
          window.localStorage.removeItem('currentDeviceId')
          yield put(navigate('/'))
        } else {
          // eslint-disable-next-line max-len
          const devicesLeft = devices.find(device => device.id !== currentDevice.id)
          if (devicesLeft && Object.keys(devicesLeft).length > 0) {
            window.localStorage.setItem('currentDeviceId', devicesLeft.id)
            yield put(navigate(`/${devicesLeft.id}/dashboard/`))
            yield put(fetchDevices())
          } else {
            yield put(setDevices([]))
            yield put(navigate('/add-device/'))
          }
        }
      } catch (catchError) {
        console.log(catchError)
        if (catchError.response) {
          const { data } = catchError.response || {}
          const translations = yield this.get('translations')
          const errorKey = data.message
            ? getApiErrorKey(data.message, 'UNITSETT_DELETE_')
            : 'UNEXPECTED_ERROR_SAVE_FORM'

          yield put(error(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    }
  }
})
