/**
 * Formfielderror Container
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'

import { FormErrorMessage } from 'xstorage-components'

type Props = {
  id: string,
  showError: boolean,
  errorMessage: string,
  translations: any,
  full?: boolean
}

class FormFieldError extends Component<Props, void> {
  render () {
    const {
      translations,
      full,
      id,
      showError,
      errorMessage
    } = this.props

    return (
      <Wrapper id={`FormFieldError_${id || '0'}`}>
        {showError &&
          <FormErrorMessage full={full}>
            {translations[errorMessage] || errorMessage}
          </FormErrorMessage>
        }
      </Wrapper>
    )
  }
}

export default Logic(FormFieldError)

const Wrapper = styled.div``
