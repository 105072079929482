// @flow
import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import * as Check from 'validations'
import { generateUrl } from 'utils'
import { API_BASE_URL } from 'config'
import { getApiErrorKey } from 'apiErrors'
// eslint-disable-next-line max-len
import MaintenanceMetricsSyncLogic from 'scenes/MaintenanceMenu/MaintenanceMetricsSync/logic'
import AppLogic from 'containers/App/logic'

// eslint-disable-next-line max-len
const SYNCMETRICS_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/resync-metrics/start`

export const DEFAULT_VALUES = {
  startDate: {
    value: moment().format('DD/MM/YYYY')
  }
}

const VALIDATIONS = {
  startDate: [Check.isRequired]
}

export default kea({
  path: () => ['scenes', 'containers', 'ForceSyncPanel'],

  connect: {
    props: [AppLogic, ['translations', 'isMobile', 'currentDeviceId']],
    actions: [MaintenanceMetricsSyncLogic, ['setSyncPanel', 'getSyncData']]
  },

  actions: () => ({
    change: field => ({ field }),
    setForm: form => ({ form }),
    setError: error => error,
    submit: () => true,
    reset: () => true,
    setLoading: loading => loading
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.setError]: () => true,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.change]: () => null,
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null,
        [actions.submit]: () => null
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.submit]: () => true,
        [actions.setLoading]: (state, payload) => payload,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit
  }),

  workers: {
    * submit () {
      const { setSyncPanel, setLoading, getSyncData } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')
      const form = yield this.get('form')
      const today = moment(new Date()).valueOf()
      const selectedDate = moment(form.startDate.value).valueOf()
      const params = {
        timestamp: moment(form.startDate.value, 'DD/MM/YYYY').valueOf()
      }

      if (selectedDate > today) {
        console.error('Date is not valid')
        return false
      }

      try {
        const url = generateUrl(SYNCMETRICS_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const response = yield call(axios.post, url, params)
        const { successful } = response.data
        if (successful) {
          yield put(getSyncData())
          yield put(setLoading(false))
          yield put(setSyncPanel(false))
        }
      } catch (catchError) {
        yield put(setLoading(false))

        if (catchError.response) {
          const translations = yield this.get('translations')
          const { setError } = this.actions
          const { data } = catchError.response
          const errorKey = getApiErrorKey(data.message)

          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    }
  }
})
