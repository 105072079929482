/**
 * Main app component
 * Only add stuff here when you need something in all routes
 */
// @flow
import { type NavRoute } from 'types/global'

import toArray from 'lodash/toArray'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import Logic from './logic'
import Raven from 'raven-js'
import { SHOW_TOOLBOX } from 'config'

import FatalError from 'scenes/FatalError'
import InternetExplorer from 'scenes/InternetExplorer'
import NotFound from 'scenes/NotFound'
import Unauthorized from 'scenes/Unauthorized'

import TechnicianBackBar from 'containers/TechnicianBackBar'
import NavBar from 'containers/NavBar'
import UserBar from 'containers/UserBar'
import NotificationsPanel from 'containers/NotificationsPanel'
import ConfigToolBox from 'containers/ConfigToolBox'
import TourLayout from 'containers/TourLayout'
import LoadingPage from 'components/LoadingPage'

import { MobileDetector } from 'xstorage-components'

import Background from './images/background-blue.svg'
import BackgroundPartial from './images/background-blue-partial.svg'

class ErrorBoundaryRoute extends PureComponent<any, any> {
  state = {
    hasError: false,
    error: null,
    info: null
  }

  componentDidCatch (error: any, info: any) {
    this.setState({
      hasError: true,
      error,
      info
    })

    Raven.captureException(error)
  }

  render () {
    const { setError } = this.props

    // If Route failed with any error return a failed route so user can go back
    if (this.state.hasError) {
      setError('Something went wrong!')

      return false
    }

    return this.props.children
  }
}

type Props = {
  children: any,
  location: string,
  appError: string,
  appLoading: number,
  currentRoute: NavRoute,
  isMobile: boolean,
  routes: any,
  userValid: boolean
}

class App extends PureComponent<Props, {}> {
  state = {
    isTourOpen: true
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    const {
      appError,
      appLoading,
      isMobile,
      currentRoute,
      routes,
      userValid,
      showBackBar,
      showHelpTour
    } = this.props

    const { setMobileState, setAppError } = this.actions

    const isIE = !!document.documentMode

    const { trdPartyApp } = currentRoute

    /* Variable introduced here for backend purposes
    * https://gitlab.ubiwhere.com/xstorage/xstorage-token
    */
    const isLoggedIn =
      window.localStorage.getItem('msal.idtoken') !== '' &&
      window.localStorage.getItem('msal.idtoken') !== null

    if (isIE) {
      return (
        <Main>
          <InternetExplorer mobile={isMobile} />
        </Main>
      )
    }

    if (appLoading) {
      return (
        <React.Fragment>
          {/* Div introduced here for backend purposes
              https://gitlab.ubiwhere.com/xstorage/xstorage-token
            */}
          <LoggedIn id='checkLogIn'>{isLoggedIn.toString()}</LoggedIn>
          <LoadingPage />
        </React.Fragment>
      )
    }

    if (appError) {
      return (
        <Main>
          <RouteContainer mobile={isMobile} hidenav uservalid={false}>
            <FatalError mobile={isMobile} error={appError} />
          </RouteContainer>
          <MobileDetector mobile={isMobile} setMobileState={setMobileState} />
        </Main>
      )
    }

    if (showHelpTour) {
      return (
        <Main>
          <TourLayout />
          <MobileDetector mobile={isMobile} setMobileState={setMobileState} />
        </Main>
      )
    }

    if (trdPartyApp) {
      return (
        <Main>
          <Route
            key={currentRoute.key}
            path={currentRoute.path}
            component={currentRoute.component()}
            exact={!!currentRoute.exact}
          />
          <MobileDetector mobile={isMobile} setMobileState={setMobileState} />
        </Main>
      )
    }

    return (
      <Main>
        {currentRoute.key === 'notfound' ? (
          <NotFound />
        ) : currentRoute.key === 'unauthorized' ? (
          <Unauthorized />
        ) : (
          <React.Fragment>
            {showBackBar && <TechnicianBackBar mobile={isMobile} />}
            <RouteContainer
              showBackBar={showBackBar}
              mobile={isMobile}
              bgpartial={currentRoute && currentRoute.bgPartial}
              hidenav={currentRoute && currentRoute.hideNavBar}
              uservalid={userValid}
            >
              {toArray(routes).map(route => (
                <ErrorBoundaryRoute
                  {...route}
                  setError={setAppError}
                  key={`${route.key}-bond`}
                >
                  <Route
                    key={route.key}
                    path={route.path}
                    component={route.component()}
                    exact={!!route.exact}
                  />
                </ErrorBoundaryRoute>
              ))}
            </RouteContainer>
            <NavBar showBackBar={showBackBar} />
            {!isMobile && userValid && !currentRoute.hideUserBar && (
              <div>
                <UserBar showBackBar={showBackBar} />
                <NotificationsPanel showBackBar={showBackBar} />
              </div>
            )}
          </React.Fragment>
        )}
        <MobileDetector mobile={isMobile} setMobileState={setMobileState} />
        {SHOW_TOOLBOX && <ConfigToolBox />}
      </Main>
    )
  }
}

export default Logic(App)

const RouteContainer = styled.div`
  height: auto;
  position: absolute;
  min-height: 100vh;

  ${({ mobile, showBackBar }) =>
    !mobile &&
    showBackBar &&
    `
    top: 56px;
    min-height: calc(100vh - 56px);
  `}

  ${({ mobile, showBackBar }) =>
    mobile &&
    showBackBar &&
    `
    top: 56px;
    min-height: calc(100vh - 56px);
  `}

  ${({ mobile }) =>
    mobile &&
    `
    padding-bottom: 56px;
    background: #FFF;
    min-width: 300px;
    width: 100vw;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    background: #f7f7f9;
    min-width: 1024px;
    width: 100%;
    display: inline-block;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
  `}

  ${({ mobile, uservalid }) =>
    !mobile &&
    !!uservalid &&
    `
    margin-left: 120px;
    width: calc(100% - 120px);
    min-width: 904px;
  `}

  ${({ mobile, bgpartial }) =>
    !mobile &&
    bgpartial &&
    `
    background-image: url(${BackgroundPartial});
  `}

  ${({ mobile, hidenav }) =>
    mobile &&
    hidenav &&
    `
    padding-bottom: 0;
  `}

`

const Main = styled.div`
  width: 100%;
`

const LoggedIn = styled.div`
  display: none;
`
