// @flow
import { type GeneralConfigs } from 'types/global'
import React, { PureComponent } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { CONSUMER_USER_TYPE, TECHNICIAN_USER_TYPE } from 'config'

import PageNavMenu from 'containers/PageNavMenu'

type Props = {
  page?: string,
  isMobile: boolean,
  translations: any,
  configs: GeneralConfigs,
  authProvider: string
}

class UserProfileMenu extends PureComponent<Props, void> {
  actions: any

  render () {
    const {
      page,
      isMobile,
      translations,
      configs,
      authProvider
    } = this.props

    const {
      navigate
    } = this.actions

    const { userType } = configs

    const {
      USERPROF_MENU_PERSONAL_INFO,
      USERPROF_MENU_CHANGE_PASS,
      USERPROF_MENU_CHANGE_LANG,
      USERPROF_MENU_CHANGE_WEATHER,
      USERPROF_MENU_DOWNLOAD,
      USERPROF_MENU_DELETE,
      USERPROF_MENU_MYAPPS
    } = translations

    const common = [
      {
        id: 'ProfileInfo',
        key: 'profile',
        icon: <Icon>{'account_circle'}</Icon>,
        title: USERPROF_MENU_PERSONAL_INFO,
        link: true,
        onClick: () => navigate('/user-profile/info/')
      },
      {
        id: 'ChangeLanguage',
        key: 'change-language',
        icon: <Icon>{'language'}</Icon>,
        title: USERPROF_MENU_CHANGE_LANG,
        link: true,
        onClick: () => navigate('/user-profile/language/')
      },
      {
        id: 'ChangeWeather',
        key: 'change-weather',
        icon: <Icon>{'brightness_7'}</Icon>,
        title: USERPROF_MENU_CHANGE_WEATHER,
        link: true,
        onClick: () => navigate('/user-profile/weather/')
      },
      {
        id: 'MyApps',
        key: 'my-apps',
        icon: <Icon>{'extension'}</Icon>,
        title: USERPROF_MENU_MYAPPS,
        link: true,
        onClick: () => navigate('/user-profile/my-apps/')
      }
    ]

    if (authProvider === 'MICROSOFT') {
      common.push(
        {
          id: 'ChangePassword',
          key: 'change-password',
          icon: <Icon>{'https'}</Icon>,
          title: USERPROF_MENU_CHANGE_PASS,
          link: true,
          onClick: () => navigate('/user-profile/change-password/')
        }
      )
    }

    const consumerItems = [
      ...common,
      {
        id: 'DownloadInfo',
        key: 'download-info',
        icon: <Icon>{'get_app'}</Icon>,
        title: USERPROF_MENU_DOWNLOAD,
        link: true,
        onClick: () => navigate('/user-profile/download/')
      },
      {
        id: 'DeleteAccount',
        key: 'delete-account',
        icon: <Icon>{'delete'}</Icon>,
        title: USERPROF_MENU_DELETE,
        link: true,
        color: isMobile ? '#CA3C3D' : '#EFC5C5',
        onClick: () => navigate('/user-profile/delete/')
      }
    ]

    const techniciansItems = common

    const menuItems = {
      [CONSUMER_USER_TYPE]: consumerItems,
      [TECHNICIAN_USER_TYPE]: techniciansItems
    }

    return (
      <Wrapper>
        <PageNavMenu
          items={menuItems[userType]}
          page={page}
        />
      </Wrapper>
    )
  }
}

export default Logic(UserProfileMenu)

const Wrapper = styled.div`

`
