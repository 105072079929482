// @flow
import { Component } from 'react'
import Logic from './logic'
import { checkRolePermission } from 'containers/UserPermission/utils'
// import styled from 'styled-components'

type Props = {
  children: any,
  permission: string,
  userRole: string
}

class UserPermission extends Component<Props, void> {
  render () {
    const {
      children,
      permission,
      userRole
    } = this.props

    const userHasPermission = checkRolePermission(permission, userRole)

    if (!userHasPermission) {
      return ''
    }

    return children
  }
}

export default Logic(UserPermission)
