// @flow
import { kea } from 'kea'
import { put, delay } from 'redux-saga/effects'
import PropTypes from 'prop-types'

import { log } from 'utils'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'DeviceSettings'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDeviceId'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    reset: () => true,
    setChangesSaved: success => success,
    setShowSuccessMessage: success => success
  }),

  reducers: ({ actions }) => ({
    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setChangesSaved]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],
    showSuccessMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setShowSuccessMessage]: (state, payload) => payload,
        [actions.reset]: () => false
      }

    ]
  }),

  start: function * () {
    const { navigate } = this.actions

    log('[XS-DeviceSettings] Start Scene', 'yellow')

    const currentDeviceId: string = yield this.get('currentDeviceId')
    if (!currentDeviceId) {
      yield put(navigate('/add-device/'))
      return false
    }

    const showSuccessMessage = yield this.get('showSuccessMessage')
    const { setShowSuccessMessage, setChangesSaved } = this.actions

    if (showSuccessMessage) {
      yield delay(100)
      yield put(setChangesSaved(true))
      yield delay(2000)
      yield put(setChangesSaved(false))
      yield put(setShowSuccessMessage(false))
    }
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-DeviceSettings] Stop Scene')

    yield put(reset())
  }
})
