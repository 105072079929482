// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

import {
  EnergyComponent,
  ItemCritical,
  EnergyFlowLabel
} from 'xstorage-components'

import CriticalFlow from './images/CriticalFlow'

type Props = {}

export default class EnergyCriticalFlow extends Component<Props, void> {
  render () {
    const { status, value, mobile } = this.props

    let none = !status || status === 'NONE'
    let disconnected = status === 'DISCONNECTED'
    let producer = status === 'PRODUCER'

    return (
      <Wrapper mobile={mobile}>
        <CriticalComponent disconnected={disconnected}>
          <ImageCritical className='fifth-step' />
        </CriticalComponent>
        {!disconnected && !none && (
          <EnergyFlowContainer>
            <CriticalFlow reverse={producer} mobile={mobile} />
            <FlowLabel mobile={mobile}>
              <EnergyFlowLabel direction={'down'} value={value} />
            </FlowLabel>
          </EnergyFlowContainer>
        )}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  height: 100px;

  ${({ mobile }) =>
    mobile &&
    `
    position: relative;

    // > div > div{
    //   margin: 15px 0;
    // }
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    position: absolute;
    bottom: 0;
    left: 0;
  `}
`

const EnergyFlowContainer = styled.div`
  position: relative;
`

const CriticalComponent = styled(EnergyComponent)`
  > div {
    position: relative;
  }
`

const ImageCritical = styled(props => <ItemCritical {...props} />)`
  position: absolute !important;
  top: 15px !important;
  left: 25px !important;
`

const FlowLabel = styled.div`
  position: absolute;
  width: 80px;

  ${({ mobile }) =>
    mobile &&
    `
    right: 20px;
    top: -20px;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    left: 0;
    top: -35px;
  `}
`
