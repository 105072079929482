/**
 * Terms Scene Logic
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */

// @flow
import { kea } from 'kea'

import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'Terms'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'userIsTechnician',
        'currentDeviceId'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  }
})
