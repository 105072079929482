// @flow
import { kea } from 'kea'
import { put, call, delay } from 'redux-saga/effects'
import axios from 'axios'
import PropTypes from 'prop-types'

import { API_BASE_URL, FETCH_DATETIME_DELAY } from 'config'
import { generateUrl, log } from 'utils'
import { getApiErrorKey } from 'apiErrors'
import AppLogic from 'containers/App/logic'

// eslint-disable-next-line max-len
const DATETIME_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/date-time`

export default kea({
  path: () => ['scenes', 'MaintenanceDatetimeSync'],

  connect: {
    props: [
      AppLogic,
      ['translations', 'isMobile', 'currentDevice', 'currentDeviceId'],

      ({ scenes }) => scenes.containers.MaintenanceSupportedFeatures,
      ['featureSupport']
    ],
    actions: [AppLogic, ['navigate']]
  },

  actions: () => ({
    getDatetimeInfo: () => true,
    setDatetimeInfo: info => info,
    setSuccessMessage: state => state,
    forcesync: () => true,
    setLoading: state => state,
    setLoadingData: state => state,
    setError: error => error,
    updateDateTimeInfo: () => true,

    reset: () => true
  }),

  reducers: ({ actions }) => ({
    datetimeValues: [
      {},
      PropTypes.object,
      {
        [actions.setDatetimeInfo]: (state, payload) => payload,
        [actions.reset]: () => ({})
      }
    ],

    successMessage: [
      false,
      PropTypes.bool,
      {
        [actions.setSuccessMessage]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setLoading]: (state, payload) => payload,
        [actions.reset]: () => false,
        [actions.getDatetimeInfo]: () => true,
        [actions.setDatetimeInfo]: () => false,
        [actions.setError]: () => false
      }
    ],

    loadingData: [
      false,
      PropTypes.bool,
      {
        [actions.setLoadingData]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ]
  }),

  start: function * () {
    log('[XS-DATETIMESYNC] Start Scene', 'yellow')

    const { getDatetimeInfo } = this.actions

    yield put(getDatetimeInfo())
  },

  stop: function * () {
    log('[XS-DATETIMESYNC] Stop Scene', 'yellow')

    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getDatetimeInfo]: workers.getDatetimeInfo,
    [actions.forcesync]: workers.forcesync,
    [actions.updateDateTimeInfo]: workers.getDatetimeInfo
  }),

  workers: {
    * getDatetimeInfo () {
      const {
        setDatetimeInfo,
        setLoadingData,
        updateDateTimeInfo
      } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')
      yield put(setLoadingData(true))

      try {
        const url = generateUrl(DATETIME_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const response = yield call(axios.get, url)
        if (response) {
          const { result } = response.data

          yield put(setDatetimeInfo(result || {}))
          yield put(setLoadingData(false))

          yield delay(FETCH_DATETIME_DELAY)
          yield put(updateDateTimeInfo())
        }
      } catch (catchError) {
        console.log(catchError)
        yield put(setLoadingData(false))
        const { setError } = this.actions

        if (catchError.response) {
          const { data } = catchError.response

          if (data && data.message) {
            const errorKey = getApiErrorKey(data.message)
            const translations = yield this.get('translations')

            yield put(setError(translations[errorKey] || ''))
          }
        }
      }
    },

    * forcesync () {
      const { setSuccessMessage, setLoading } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')
      yield put(setLoading(true))

      try {
        const url = generateUrl(DATETIME_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const response = yield call(axios.post, url)
        const { successful } = response.data

        if (successful) {
          yield put(setLoading(false))
          yield put(setSuccessMessage(true))
          yield delay(5000)
          yield put(setSuccessMessage(false))
        }
      } catch (catchError) {
        console.log(catchError)
        yield put(setLoading(false))

        if (catchError.response) {
          const { setError } = this.actions
          const { data } = catchError.response
          const errorKey = getApiErrorKey(data.message)
          const translations = yield this.get('translations')

          yield put(setError(translations[errorKey] || ''))
        }
      }
    }
  }
})
