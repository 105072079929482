// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

import { EnergyComponent, EnergyFlowLabel } from 'xstorage-components'

import GridFlow from './images/GridFlow'
import IconGrid from './images/grid-icon'

type Props = {
  status: string,
  value: number,
  mobile?: boolean
}

export default class EnergyGridFlow extends Component<Props, void> {
  render () {
    const { status, value, mobile } = this.props

    let none = !status || status === 'NONE'
    let disconnected = status === 'DISCONNECTED'
    let consumer = status === 'CONSUMER'

    return (
      <Wrapper mobile={mobile}>
        <EnergyComponent
          align={'right'}
          disconnected={disconnected}
          white={!mobile}
        >
          <ImageGrid className='seventh-step'>
            <IconGrid color={mobile ? '#FFF' : 'rgba(0,123,193,0.5)'} />
          </ImageGrid>
        </EnergyComponent>
        {!disconnected && !none && (
          <EnergyFlowContainer>
            <GridFlow reverse={consumer} mobile={mobile} />
            <FlowLabel mobile={mobile}>
              <EnergyFlowLabel
                direction={consumer ? 'up' : 'down'}
                value={value}
              />
            </FlowLabel>
          </EnergyFlowContainer>
        )}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  height: 100px;

  ${({ mobile }) =>
    mobile &&
    `
    position: relative;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    position: absolute;
    top: 0;
    right: 0;
  `}
`

const EnergyFlowContainer = styled.div`
  position: relative;
`

const ImageGrid = styled.div`
  margin: 10px 20px;
  position: absolute;

  svg {
    position: relative;
    top: 0;
    left: 0;
  }
`

const FlowLabel = styled.div`
  position: absolute;
  width: 100px;

  > div {
    float: right;
  }

  ${({ mobile }) =>
    mobile &&
    `
    right: 70px;
    top: 115px;
  `}

  ${({ mobile }) =>
    !mobile &&
    `
    right: 10px;
    top: 130px;

    label{
      color: #727E84;
      text-shadow: none;
    }
  `}
`
