import React, { Component } from 'react'
import { ENERGY_FLOW_DURATION_FLOW } from 'config'
import anime from 'animejs'
import styled from 'styled-components'

export default class SolarFlow extends Component {
  componentDidMount () {
    const { reverse, id } = this.props

    const pathExists = document.querySelector(`#motion-${id} path`)
    if (pathExists) {
      const path = anime.path(`#motion-${id} path`)
      anime({
        targets: `#motion-${id} > div`,
        translateX: path('x'),
        translateY: path('y'),
        rotate: path('angle'),
        easing: 'linear',
        duration: 2000,
        loop: true,
        direction: reverse ? 'reverse' : 'normal'
      })
    }
  }

  render () {
    const { id, mobile, multipvdetails } = this.props

    let dimensions, path
    if (mobile) {
      dimensions = {
        height: '100',
        width: '120',
        viewBox: '-10 20 110 55'
      }
      path = 'M0 10 H 0 V 85 H 80'
    } else {
      if (multipvdetails) {
        dimensions = {
          height: '250',
          width: '400',
          viewBox: '-150 -150 400 250'
        }
        path = 'M 0 0 H 250'
      } else {
        dimensions = {
          height: '110',
          width: '250',
          viewBox: '-20 31 250 55'
        }
        path = 'M0,0 l0,0 M0,10 l65,65 H65 V 75 H 250'
      }
    }

    return (
      <Wrapper
        id={`motion-${id}`}
        mobile={mobile}
        multipvdetails={multipvdetails}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          {...dimensions}
        >
          <path
            strokeDasharray='5, 16'
            strokeLinecap='round'
            d={path}
            fill='none'
            stroke='#fff'
            strokeWidth='3'
            id={`${id}-wire`}
          />
        </svg>
        {!multipvdetails && <Circle mobile={mobile} />}
      </Wrapper>
    )
  }
}

SolarFlow.defaultProps = {
  id: 'EnergySolarFlow',
  duration: ENERGY_FLOW_DURATION_FLOW,
  begin: 0,
  reverse: false,
  mobile: false
}

const Wrapper = styled.div`
  position: absolute;

  ${({ mobile, multipvdetails }) =>
    !mobile && multipvdetails
      ? `
    left: 20px;
    top: -150px;
  `
      : !mobile && !multipvdetails
        ? `
    left: 50px;
    top: 90px;
  `
        : `
    left: 51px;
    top: 80px;
  `};
`

const Circle = styled.div`
  box-shadow: 0px 0px 3px 2px rgba(51, 51, 51, 0.5);
  border-radius: 50%;
  position: absolute;

  ${({ mobile }) =>
    !mobile
      ? `
    background: #FFF;
    width: 12px;
    height: 12px;
    top: -10px;
    left: 12px;
  `
      : `
    background: #FFF;
    width: 12px;
    height: 12px;
    top: 0;
    left: 4px;
  `};
`
