// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { getEventHeightAndTop } from './utils'

type Props = {
  start: number,
  end: number,
  name: string,
  mobile: boolean,
  full: boolean,
  onClick?: Function,
  eventIcon?: string
}

export default class ScheduleEvent extends PureComponent<Props, void> {
  render () {
    const {
      start,
      end,
      name,
      mobile,
      full,
      onClick,
      eventIcon
    } = this.props

    const { top, height } = getEventHeightAndTop(start, end)

    return (
      <Wrapper top={top} full={full}>
        <Event
          height={height || 0}
          clickable={mobile}
          onClick={mobile ? onClick : null}
        >
          <EventLabel>{name}</EventLabel>
          {(!mobile && !!onClick) &&
            <EditButton
              disabled={!onClick}
              onClick={onClick}
              small={height < 30}
            >
              <Icon>{eventIcon || 'edit'}</Icon>
            </EditButton>
          }
        </Event>
        <EventShadow />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin: 5px;
  position: absolute;
  width: calc(100% - 110px);
  left: 100px;
  
  top: ${({ top }) => `${top}px`};

  @media screen and (max-width: 550px) {
    width: calc(100% - 80px);
    left: 70px;
  }

  ${({ full }) => full && `
    width: calc(100% - 10px);
    left: 0;
  `}
`

const Event = styled.div`
  width: 100%;
  border: 2px solid #80BDE0;
  border-radius: 6px;
  position: relative;
  background-color: #F9FBFD;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => `${height}px`};

  ${({ clickable }) => clickable && `
    cursor: pointer;
  `}
`

const EventShadow = styled.div`
  background-color: #FFFFFF;
  position: relative;
  bottom: 8px;
  border-radius: 6px;
  height: 8px;
  width: 80%;
  left: 10%;
  z-index: 1;
  box-shadow: 0 0 20px 0 rgba(114,126,132,0.5);
`

const EventLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #268FCA;
  line-height: 17px;
`

const EditButton = styled.a`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #007BC1;
  cursor: pointer;

  span{
    font-size: 18px;
  }

  ${({ disabled }) => disabled && `
    opacity: .6;
  `}

  ${({ small }) => small && `
    top: -2px;

    span{
      font-size: 14px;
    }
  `}
`
