/**
 * UserProfileWeather Scene
 * Please write a description
 *
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
// @flow
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon, FormControlLabel } from '@material-ui/core'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  Loading,
  Button,
  Typography,
  MobileActionButton,
  Radio,
  RadioGroup,
  PageMainSection,
  Form,
  SuccessSavedAlert
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  translations: any,
  form: any,
  loading: boolean,
  submiting: boolean
}

export class UserProfileWeather extends Component<Props, void> {
  actions: any

  render () {
    const {
      isMobile,
      translations,
      form,
      loading,
      dirty,
      successMessage
    } = this.props

    const { navigate, change, submit, setFormUserUnit } = this.actions

    const { weatherUnit } = form

    const {
      USERPROF_TITLE,
      USERPROF_CHANGE_WEATHER_TITLE,
      USERPROF_CHANGE_WEATHER_SAVE,
      USERPROF_CHANGE_WEATHER_CANCEL,
      USERPROF_CHANGE_WEATHER_LABEL,
      USERPROF_CHANGE_WEATHER_SUCCESS
    } = translations

    const units = [
      { identifier: 'celsius', name: 'Celsius' },
      { identifier: 'fahrenheit', name: 'Fahrenheit' }
    ]

    const showFormButtons = !isMobile && dirty

    const formButtons = (
      <Form.Buttons align={'right'}>
        <Button
          id={'PrimaryButton_Cancel'}
          buttonType={'primary-cancel'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={setFormUserUnit}
          disabled={!showFormButtons}
        >
          {USERPROF_CHANGE_WEATHER_CANCEL}
        </Button>
        <Button
          id={'PrimaryButton_Save'}
          margin={'0 12px'}
          buttonWidth={'163px'}
          onClick={submit}
          disabled={!showFormButtons}
        >
          {USERPROF_CHANGE_WEATHER_SAVE}
        </Button>
      </Form.Buttons>
    )

    return (
      <Wrapper>
        {loading && <Loading />}
        <PageAdaptable
          title={USERPROF_TITLE}
          menu={<UserProfileMenu page={'change-weather'} />}
          onBack={() => navigate('/user-profile/')}
          isForm
          isDirty={dirty}zz
        >
          <PageMainSection padding mobile={isMobile}>
            <SuccessSavedAlert
              mobile={isMobile}
              out={!successMessage}
              icon={<Icon>{'check'}</Icon>}
              message={USERPROF_CHANGE_WEATHER_SUCCESS}
            />
            <PageTitle mobile={isMobile}>
              {USERPROF_CHANGE_WEATHER_TITLE}
            </PageTitle>
            {isMobile && dirty && (
              <MobileActionButton
                mobile
                icon={<Icon>{'check'}</Icon>}
                onClick={submit}
                id={'MobileActionButton_Save'}
              >
                {USERPROF_CHANGE_WEATHER_SAVE}
              </MobileActionButton>
            )}
            <Form
              mobile={isMobile}
              buttons={formButtons}
              showButtons={showFormButtons}
              containerHeight={
                isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 350px)'
              }
            >
              <Form.Row full={isMobile} margin={isMobile}>
                <Label>{USERPROF_CHANGE_WEATHER_LABEL}</Label>
                <RadioGroup>
                  {units.map(unit => (
                    <FormControlLabel
                      key={unit.identifier}
                      value={unit.identifier}
                      control={
                        <Radio
                          onChange={({ target }) =>
                            change({ weatherUnit: target.value })
                          }
                          checked={weatherUnit.value === unit.identifier}
                        />
                      }
                      label={unit.name}
                    />
                  ))}
                </RadioGroup>
              </Form.Row>
            </Form>
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(UserProfileWeather)

const Wrapper = styled.div``

const Label = styled(props => <Typography {...props} />)`
  &&&& {
    font-weight: bold;
    color: #5b6770;
    // margin-top: 40px;
  }
`
