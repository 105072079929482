// @flow
import { kea } from 'kea'
import AppLogic from 'containers/App/logic'
import PropTypes from 'prop-types'
import { put, call, delay } from 'redux-saga/effects'
import axios from 'axios'

import { API_BASE_URL, FETCH_PENDNG_LOG_DELAY } from 'config'
import { generateUrl, log } from 'utils'
import { getApiErrorKey } from 'apiErrors'

const UNITLOGS_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/log`

export default kea({
  path: () => ['scenes', 'MaintenanceUnitLog'],

  connect: {
    props: [
      AppLogic,
      [
        'translations',
        'isMobile',
        'currentDeviceId',
        'currentDevice',
        'currentRoute'
      ],

      ({ scenes }) => scenes.containers.MaintenanceSupportedFeatures,
      ['featureSupport']
    ],
    actions: [AppLogic, ['navigate', 'fetchCurrentDeviceInfo']]
  },

  actions: () => ({
    setFilesToDownload: file => file,
    requestLogDownload: () => true,
    getFile: () => true,
    getFilesToDownload: () => true,
    setConfirmationModal: status => status,
    setLoading: loading => loading,
    setLoadingData: loading => loading,
    setError: error => error,
    reset: () => true
  }),

  reducers: ({ actions }) => ({
    fileToDownload: [
      {},
      PropTypes.object,
      {
        [actions.setFilesToDownload]: (state, payload) => payload,
        [actions.reset]: () => ({})
      }
    ],

    confirmationModal: [
      false,
      PropTypes.bool,
      {
        [actions.setConfirmationModal]: (state, payload) => payload,
        [actions.reset]: () => false,
        [actions.setFilesToDownload]: () => false
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setLoading]: (state, payload) => payload,
        [actions.requestLogDownload]: () => true,
        [actions.setFilesToDownload]: () => false,
        [actions.reset]: () => false
      }
    ],

    loadingData: [
      false,
      PropTypes.bool,
      {
        [actions.setLoadingData]: (state, payload) => payload,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ]
  }),

  selectors: ({ selectors }) => ({
    isSupportMenu: [
      () => [selectors.currentRoute],
      (currentRoute) => currentRoute && currentRoute.key === 'SupportUnitLog',
      PropTypes.bool
    ]
  }),

  start: function * () {
    log('[XS-UNITLOG] Start Scene', 'yellow')

    const { getFilesToDownload } = this.actions
    yield put(getFilesToDownload())
  },

  stop: function * () {
    const { reset } = this.actions
    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.requestLogDownload]: workers.requestLogDownload,
    [actions.getFile]: workers.getFile,
    [actions.getFilesToDownload]: workers.getFilesToDownload
  }),

  workers: {
    * requestLogDownload () {
      const {
        setFilesToDownload,
        setLoading,
        setConfirmationModal,
        getFilesToDownload
      } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')

      try {
        const config = {
          headers: { 'content-type': 'application/json' }
        }
        const url = generateUrl(UNITLOGS_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const response = yield call(axios.post, url, null, config)
        const { result } = response.data
        const currentDevice = yield this.get('currentDevice')
        // eslint-disable-next-line max-len
        result.filename = `${currentDevice.inverterSerialNumber}-${result.createdAt}.zip`
        yield put(setFilesToDownload(result || {}))

        if (result.status === 'PENDING') {
          yield delay(FETCH_PENDNG_LOG_DELAY)

          yield put(getFilesToDownload())
        }
      } catch (error) {
        console.log(error)
        yield put(setLoading(false))
        yield put(setConfirmationModal(false))
      }
    },

    * getFilesToDownload () {
      const {
        setFilesToDownload,
        setLoadingData,
        getFilesToDownload,
        setError
      } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')
      yield put(setLoadingData(true))

      try {
        const url = generateUrl(UNITLOGS_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const response = yield call(axios.get, url)
        const { result } = response.data
        const currentDevice = yield this.get('currentDevice')

        if (result) {
          // eslint-disable-next-line max-len
          result.filename = `${currentDevice.inverterSerialNumber}-${result.createdAt}.zip`
        }

        yield put(setFilesToDownload(result || {}))
        yield put(setLoadingData(false))

        if (result && result.status === 'PENDING') {
          yield delay(FETCH_PENDNG_LOG_DELAY)

          yield put(getFilesToDownload())
        }
      } catch (catchError) {
        yield put(setLoadingData(false))

        if (catchError.response) {
          const { data } = catchError.response
          const errorKey = getApiErrorKey(data.message)
          const translations = yield this.get('translations')

          yield put(setError(translations[errorKey] || ''))
        } else {
          console.log(catchError)
        }
      }
    },

    * getFile () {
      const fileToDownload = yield this.get('fileToDownload')
      window.open(fileToDownload.file.url, '_blank')
    }
  }
})
