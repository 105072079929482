// @flow
import { kea } from 'kea'
import AppLogic from 'containers/App/logic'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import * as Check from 'validations'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import { indexOf, cloneDeep, find, findKey } from 'lodash'
import { API_BASE_URL } from 'config'
import { generateUrl } from 'utils'
import { getApiErrorKey } from 'apiErrors'
import {
  EXPORT_TIMEOPTION_GRANULARITY,
  EXPORT_VARIABLES_INCLUDEDINFO
} from './utils'

// eslint-disable-next-line max-len
const EXPORT_CHART_MINUTE_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/metrics/export`
// eslint-disable-next-line max-len
const EXPORT_CHART_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/metrics/{frequency}/export`

export const DEFAULT_VALUES = {
  startDate: {
    value: moment().format('DD/MM/YYYY')
  },
  endDate: {
    value: moment().format('DD/MM/YYYY')
  },
  granularity: {
    value: 'daily'
  },
  includedInfo: {
    value: []
  }
}

const VALIDATIONS = {
  startDate: [Check.isRequired],
  endDate: [Check.isRequired],
  granularity: [Check.isRequired],
  includedInfo: [Check.isEmptyArray]
}

export default kea({
  path: () => ['scenes', 'containers', 'ExportChartPanel'],

  connect: {
    props: [
      AppLogic,
      ['isMobile', 'translations', 'currentDeviceId', 'batteryReadyMode']
    ],
    actions: [AppLogic, []]
  },

  actions: () => ({
    change: field => ({ field }),
    setForm: form => ({ form }),
    setError: error => error,
    submit: () => true,
    reset: () => true,
    setLoading: loading => loading
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    dirty: [
      false,
      PropTypes.bool,
      {
        [actions.change]: () => true,
        [actions.setError]: () => true,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.change]: () => null,
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.submit]: () => true,
        [actions.setLoading]: (state, payload) => payload,
        [actions.setError]: () => false,
        [actions.reset]: () => false
      }
    ]
  }),

  start: function * () {
    const { variables, timeFilter, timeInterval } = this.props
    const { change } = this.actions
    let includedInfoArray = []
    let variableIncludedInfo = []

    let fromDate = moment().format('DD/MM/YYYY')
    let toDate = moment().format('DD/MM/YYYY')

    if (timeInterval[0]) {
      fromDate = moment.unix(timeInterval[0]).format('DD/MM/YYYY')
    }

    if (timeInterval[1]) {
      toDate = moment.unix(timeInterval[1]).format('DD/MM/YYYY')
    }

    /** Convert time filter from chart to export granularity */
    const timeGranularity = find(EXPORT_TIMEOPTION_GRANULARITY, function (
      element
    ) {
      return element.timeOption === timeFilter
    })

    /** Convert chart filter variables to export variables */
    findKey(variables, function (key, element) {
      if (key) {
        includedInfoArray.push(element)
      }
    })

    includedInfoArray.forEach(elementFromVariable => {
      const includedElement = find(EXPORT_VARIABLES_INCLUDEDINFO, function (
        elementFromDic
      ) {
        return elementFromDic.variable === elementFromVariable
      })

      variableIncludedInfo.push(includedElement.includedInfo)
    })

    yield put(change({ granularity: timeGranularity.granularity }))
    yield put(change({ includedInfo: variableIncludedInfo }))
    yield put(change({ startDate: fromDate }))
    yield put(change({ endDate: toDate }))
  },

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit
  }),

  workers: {
    * submit () {
      const { setError, setLoading } = this.actions

      const form = yield this.get('form')
      const translations = yield this.get('translations')
      const currentDeviceId = yield this.get('currentDeviceId')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (validation.invalid) {
        yield put(setError(translations['FORM_SAVE_ERRORS_FIELDS']))
        return false
      }

      const fromHour = moment(form.startDate.value, 'DD/MM/YYYY').format(
        'DD/MM/YYYY 00:00:00'
      )
      const toHour = moment(form.endDate.value, 'DD/MM/YYYY').format(
        'DD/MM/YYYY 23:59:59'
      )
      const from = moment(fromHour, 'DD/MM/YYYY HH:mm:ss').valueOf()
      const to = moment(toHour, 'DD/MM/YYYY HH:mm:ss').valueOf()

      if (
        moment(from).diff(to) > 0 &&
        form.startDate.value !== form.endDate.value
      ) {
        yield put(
          setError(translations['CHART_EXPORT_STARTDATE_GREATER_ENDDATE'])
        )
        return false
      }

      const findPV = indexOf(
        form.includedInfo.value,
        'PHOTOVOLTAIC_PRODUCTION_AGG'
      )
      let cloneIncludedInfo = cloneDeep(form.includedInfo.value)

      if (findPV > -1) {
        cloneIncludedInfo.splice(
          findPV + 1,
          0,
          'PHOTOVOLTAIC_PRODUCTION_METER_1'
        )
        cloneIncludedInfo.splice(
          findPV + 2,
          0,
          'PHOTOVOLTAIC_PRODUCTION_METER_2'
        )
      }

      cloneIncludedInfo.splice(0, 0, 'DATE')
      cloneIncludedInfo = cloneIncludedInfo.toString()

      const params = {
        from: from,
        to: to,
        field: cloneIncludedInfo
      }

      try {
        let url = null

        if (form.granularity.value === 'minute') {
          url = generateUrl(EXPORT_CHART_MINUTE_ENDPOINT, {
            id: currentDeviceId
          })
        } else {
          url = generateUrl(EXPORT_CHART_ENDPOINT, {
            id: currentDeviceId,
            frequency: form.granularity.value
          })
        }

        const response = yield call(axios.get, url, { params })
        const { successful, result } = response.data

        if (successful) {
          window.open(result.url, '_blank')
          yield put(setLoading(false))
        }
      } catch (catchError) {
        const result = catchError.response

        if (result && result.data) {
          const { message } = result.data
          const errorKey = getApiErrorKey(message, '')

          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    }
  }
})
