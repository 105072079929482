// @flow
import { kea } from 'kea'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'MaintenanceMenuMobile'],

  connect: {
    props: [AppLogic, ['isMobile', 'translations', 'currentDeviceId']],
    actions: [AppLogic, ['navigate']]
  }
})
