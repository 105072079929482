// @flow
import { kea } from 'kea'

import { put, call } from 'redux-saga/effects'
import axios from 'axios'
import { API_BASE_URL } from 'config'
import { log, generateUrl } from 'utils'
import PropTypes from 'prop-types'

import AppLogic from 'containers/App/logic'

const LOADING_COUNT: number = 2
const DEVICES_ID_ENDPOINT = `${API_BASE_URL}/devices/onboarding/{deviceId}`

export default kea({
  path: () => ['scenes', 'TechnicianUnitDetails'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'currentRoute',
        'isMobile'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    reset: () => true,
    setUnitId: unitId => ({ unitId }),
    setUnit: unit => ({ unit }),
    resetAppLoading: () => true
  }),

  reducers: ({ actions }) => ({

    appLoading: [LOADING_COUNT, PropTypes.number, {
      [actions.setUnitId]: () => 1,
      [actions.setUnit]: () => 0,
      [actions.resetAppLoading]: () => 0
    }],

    unitId: [null, PropTypes.string, {
      [actions.setUnitId]: (state, payload) => payload.unitId,
      [actions.reset]: (state, payload) => null
    }],

    unit: [null, PropTypes.object, {
      [actions.setUnit]: (state, payload) => payload.unit,
      [actions.reset]: (state, payload) => null
    }]

  }),

  start: function * () {
    log('[XS-TechnicianUnitDetails] Start Scene', 'yellow')

    const { setUnitId, resetAppLoading } = this.actions
    const currentRoute = yield this.get('currentRoute')
    const { unitId } = currentRoute.params

    if (unitId) {
      yield put(setUnitId(unitId))
    } else {
      yield put(resetAppLoading())
    }
  },

  stop: function * () {
    const { reset } = this.actions

    log('[XS-TechnicianUnitDetails] Stop Scene')

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.setUnitId]: workers.getUnitInfo
  }),

  workers: {
    * getUnitInfo () {
      const unitId = yield this.get('unitId')
      const { setUnit } = this.actions

      try {
        const url = generateUrl(DEVICES_ID_ENDPOINT, { deviceId: unitId })
        const resultRequest = yield call(axios.get, url)
        const { successful, result } = resultRequest.data
        if (successful) {
          yield put(setUnit(result))
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

})
