// @flow
import moment from 'moment-timezone'
import { Chart } from 'react-chartjs-2'
import { timeStringToFloat, convertTimeToLocalNaive } from 'utils'
import {
  generateCustomTooltip,
  tooltipLabelCallback,
  getChartYAxisValue
} from 'scenes/Chart/utils'

// DEFAULT DATA FOR CHART
const CHART_DEFAULT_DATA = {
  labels: [],
  datasets: [
    {
      key: 'sunData',
      data: [],
      label: 'sunLight',
      backgroundColor: '#F0CB2F',
      borderColor: '#F0CB2F',
      borderDash: [5, 8],
      borderWidth: 3,
      fill: false,
      hidden: false,
      yAxisID: 'B'
    },
    {
      key: 'batteryStateOfCharge',
      data: [],
      hidden: false,
      label: 'batteryState',
      backgroundColor: '#007BC1',
      borderColor: '#007BC1',
      borderWidth: 2,
      lineTension: 0,
      pointHoverRadius: 0,
      yAxisID: 'B'
    },
    {
      key: 'totalHouseConsumption',
      data: [],
      hidden: false,
      label: 'houseConsumption',
      backgroundColor: '#DA3227',
      borderColor: '#DA3227',
      borderWidth: 2,
      lineTension: 0,
      pointHoverRadius: 0,
      yAxisID: 'A'
    },
    {
      key: 'photovoltaicProduction',
      data: [],
      hidden: false,
      label: 'pvProduction',
      backgroundColor: '#00B2A9',
      borderColor: '#00B2A9',
      borderWidth: 2,
      lineTension: 0,
      pointHoverRadius: 0,
      yAxisID: 'A'
    },
    {
      key: 'criticalLoads',
      data: [],
      hidden: false,
      label: 'criticalLoads',
      backgroundColor: '#5B6770',
      borderColor: '#5B6770',
      borderWidth: 2,
      lineTension: 0,
      pointHoverRadius: 0,
      yAxisID: 'A'
    },
    {
      key: 'nonCriticalLoads',
      data: [],
      hidden: false,
      label: 'nonCriticalLoads',
      backgroundColor: '#87c6d4',
      borderColor: '#87c6d4',
      borderWidth: 2,
      lineTension: 0,
      pointHoverRadius: 0,
      yAxisID: 'A'
    }
  ]
}

/**
 * ctx helper to create a gradient color for
 * chart on canvas
 *
 * @param {*} ctx
 * @param {*} start
 * @param {*} stop
 */
export const generateGradient = (ctx: any, start: string, stop: string) => {
  let gradient = ctx.createLinearGradient(0, 0, 0, 400)
  gradient.addColorStop(0, start)
  gradient.addColorStop(1, stop)

  return gradient
}

/**
 * Get all line chart options
 *
 * @param {*} yTicksToHide
 */
export const getChartOptions = (
  yTicksToHide?: string = '',
  filters: { selectedDayPicker: number } = {},
  updated: boolean = false
) => {
  Chart.defaults.LineWithLine = Chart.defaults.line

  const { selectedDayPicker } = filters

  // Draw line on chart hover
  Chart.controllers.LineWithLine = Chart.controllers.line.extend({
    draw: function (ease) {
      Chart.controllers.line.prototype.draw.apply(this, arguments)

      const ctx = this.chart.ctx
      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const activePoint = this.chart.tooltip._active[0]
        const x = activePoint.tooltipPosition().x
        const topY = this.chart.scales['A'].top
        const bottomY = this.chart.scales['A'].bottom

        if (activePoint._index > 2) {
          // draw line
          ctx.save()
          ctx.beginPath()
          ctx.moveTo(x, topY)
          ctx.lineTo(x, bottomY)
          ctx.lineWidth = 1
          ctx.strokeStyle = '#007BC1'
          ctx.stroke()
          ctx.restore()
        }
      }
    }
  })

  // values on left axis
  const yAxisLeftCallback = (
    value: number,
    index: number,
    values: Array<number>
  ) => getChartYAxisValue(value, values)

  // values on right axis
  const yAxisRightCallback = (
    value: number,
    index: number,
    values: Array<number>
  ) => `${value}%`

  // tooltip title
  const tooltipTitleCallback = (tooltipItem: any, data: any) => {
    return tooltipItem[0]
      ? moment(tooltipItem[0].xLabel).format('DD/MM/YYYY HH:mm')
      : ''
  }

  // some definitions of needed vars
  const currentTime = convertTimeToLocalNaive(moment())
  const startOfDay = convertTimeToLocalNaive(
    moment(selectedDayPicker).startOf('day')
  )
  const endOfDay = convertTimeToLocalNaive(
    moment(selectedDayPicker).endOf('day')
  )

  // construction of options Object
  const options: any = {
    elements: {
      point: {
        radius: 0
      }
    },
    animation: {
      duration: 0
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false,
      mode: 'x-axis',
      filter: (tooltipItem: any) => tooltipItem.datasetIndex !== 0,
      callbacks: {
        label: tooltipLabelCallback,
        title: tooltipTitleCallback
      },
      custom: generateCustomTooltip
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 50,
        bottom: 0
      }
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          gridLines: {
            display: false
          },
          time: {
            displayFormats: {
              hour: 'HH:mm'
            },
            unit: 'hour',
            stepSize: 6
          },
          ticks: {
            source: 'labels',
            fontColor: 'rgba(91,103,112, 0.5)',
            fontSize: 10,
            autoSkip: false,
            min: startOfDay,
            max: endOfDay
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position: 'left',
          beginAtZero: true,
          gridLines: {
            display: false
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 0,
            display: true,
            fontColor: yTicksToHide.indexOf('A') === -1 ? '#007BC1' : '#CCC',
            fontStyle: 'bold',
            callback: yAxisLeftCallback
          }
        },
        {
          id: 'B',
          position: 'right',
          gridLines: {
            display: false
          },
          ticks: {
            min: 0,
            max: 100,
            stepSize: 20,
            display: true,
            fontColor: yTicksToHide.indexOf('B') === -1 ? '#A0C599' : '#CCC',
            fontStyle: 'bold',
            callback: yAxisRightCallback
          }
        }
      ]
    }
  }

  // check if chart is updated
  // need to be on if, if is setted on options raise an exception when
  // value is true
  if (updated) {
    options.animation = false
  }

  const isCurrentDay =
    moment(selectedDayPicker).format('DD/MM/YYYY') ===
    moment().format('DD/MM/YYYY')

  if (isCurrentDay) {
    options.annotation = {
      annotations: [
        {
          type: 'line',
          drawTime: 'afterDatasetsDraw',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: currentTime,
          borderWidth: 2,
          borderColor: '#a0a6a9',
          label: {
            position: 'top',
            enabled: true,
            xPadding: 2.5,
            yPadding: -1,
            cornerRadius: 10,
            content: ' ',
            backgroundColor: '#a0a6a9',
            fontColor: '#fff',
            yAdjust: 0
          }
        },
        {
          type: 'line',
          drawTime: 'beforeDatasetsDraw',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: currentTime,
          borderWidth: 0,
          borderColor: '#a0a6a9',
          label: {
            position: 'top',
            enabled: true,
            backgroundColor: 'transparent',
            fontColor: '#a0a6a9',
            content: moment().format('HH:mm'),
            xAdjust: moment().format('HH') < 19 ? -20 : 25,
            yAdjust: 5
          }
        }
      ]
    }
  }

  return options
}

/**
 * Function that returns chart data with each dataset options
 *
 * @param {*} canvas
 * @param {*} metrics
 * @param {*} variables
 * @param {*} translations
 * @param {*} showSun
 */
export const getChartData = (
  canvas: any,
  metrics = {},
  filters = {},
  variables = {},
  translations = {},
  showSun = false
) => {
  // put bg colors on each dataset
  const ctx = canvas.getContext('2d')
  const bgColors = {
    sunData: '#F0CB2F',
    batteryStateOfCharge: generateGradient(
      ctx,
      'rgba(0,123,193,0.5)',
      'rgba(0,123,193,0)'
    ),
    totalHouseConsumption: generateGradient(
      ctx,
      'rgba(218,220,221,0.5)',
      'rgba(218,220,221,0)'
    ),
    photovoltaicProduction: generateGradient(
      ctx,
      'rgba(0,178,169,0.5)',
      'rgba(0,178,169,0)'
    ),
    criticalLoads: generateGradient(
      ctx,
      'rgba(218,50,39,0.5)',
      'rgba(218,50,39,0)'
    ),
    nonCriticalLoads: generateGradient(
      ctx,
      'rgba(135,198,212,0.5)',
      'rgba(135,198,212,0)'
    ),
    selfConsumption: generateGradient(
      ctx,
      'rgba(152,63,240,0.5)',
      'rgba(152,63,240,0)'
    )
  }

  const { selectedDayPicker } = filters

  // define some needed values for chart labels
  const start = convertTimeToLocalNaive(
    moment(selectedDayPicker).startOf('day')
  )
  const end = convertTimeToLocalNaive(moment(selectedDayPicker).endOf('day'))
  const middleNight = convertTimeToLocalNaive(moment(start).add(6, 'hours'))
  const middle = convertTimeToLocalNaive(moment(start).add(12, 'hours'))
  const middleAfternoon = convertTimeToLocalNaive(
    moment(start).add(18, 'hours')
  )

  const data: any = { ...CHART_DEFAULT_DATA }
  data.labels = [start, middleNight, middle, middleAfternoon, end]
  data.datasets = [...data.datasets].map(dataset => {
    const labelKey = `CHART_VAR_${dataset.key.toUpperCase()}`
    return {
      ...dataset,
      ...{
        label: translations[labelKey] ? translations[labelKey] : dataset.key,
        backgroundColor: bgColors[dataset.key],
        data: metrics[dataset.key],
        hidden: dataset.key === 'sunData' ? !showSun : !variables[dataset.key]
      }
    }
  })

  return data
}

/**
 * Helper function to calculate the position of Sun Peak
 *
 * @param {*} sunData
 */
export const getSunPeakPosition = (sunData: Array<any>): number => {
  let peak: number = 50
  if (sunData.length) {
    const peakHour: string = moment(sunData[1].x).format('HH:mm')
    const floatTime: number = timeStringToFloat(peakHour)
    peak = (floatTime * 100) / 24
  }

  return peak
}
