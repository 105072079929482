// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import gif from 'assets/mini-loader.gif'

type Props = {
  loading: boolean
}

export default class LoadingGif extends PureComponent<Props> {
  render () {
    const { loading } = this.props
    return (
      <Wrapper>
        {loading &&
          <img src={gif} alt={'loading'} />
        }
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  height: 24px;
  padding-left: 8px;
`
