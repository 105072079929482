// @flow
import { kea } from 'kea'
import { put, call, delay } from 'redux-saga/effects'
import { generateUrl } from 'utils'
import { API_BASE_URL, FETCH_DEVICE_STATUS_DELAY } from 'config'
import PropTypes from 'prop-types'
import axios from 'axios'
import { getApiErrorKey } from 'apiErrors'

import AppLogic from 'containers/App/logic'

// eslint-disable-next-line max-len
const DEVICE_STATUS_PARAMS_ENDPOINT: string = `${API_BASE_URL}/devices/{id}/technical/status`

export const DEFAULT_VALUES = {
  operationMode: null,
  gridVoltage: null,
  gridFrequency: null,
  currentToGrid: null,
  inverterPower: null,
  inverterTemperature: null,
  busVoltage: null,
  gridCode: null,
  dcCurrentInjectionR: null,
  dcCurrentInjectionS: null,
  dcCurrentInjectionT: null,
  inverterModel: null,
  pv1Voltage: null,
  pv1Current: null,
  pv2Voltage: null,
  pv2Current: null,
  bmsVoltage: null,
  bmsCurrent: null,
  bmsTemperature: null,
  bmsAvgTemperature: null,
  bmsMaxTemperature: null,
  bmsMinTemperature: null,
  bmsTotalCharge: null,
  bmsTotalDischarge: null,
  bmsStateOfCharge: null,
  bmsState: null,
  bmsFaultCode: null,
  bmsHighestCellVoltage: null,
  bmsLowestCellVoltage: null,
  tidaProtocolVersion: null,
  invBootloaderVersion: null,
  meters: [
    {
      number: null,
      voltage: null,
      current: null,
      activePower: null,
      reactivePower: null,
      powerFactor: null,
      frequency: null
    }
  ]
}

export default kea({
  path: () => ['scenes', 'MaintenanceTechParams'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'currentDevice',
        'currentDeviceId',
        'user',
        'showBackBar'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate'
      ]
    ]
  },

  actions: () => ({
    setParamsValues: values => ({ values }),
    getParamsValue: () => true,
    reset: () => true,
    setBmsFaultCodeModal: (state) => ({ state }),
    setFixNavbar: fix => fix,
    updateParamsValue: () => true,
    setError: error => error
  }),

  reducers: ({ actions }) => ({
    paramsValues: [ DEFAULT_VALUES, PropTypes.object, {
      [actions.setParamsValues]: (state, payload) => payload.values,
      [actions.reset]: () => DEFAULT_VALUES
    }],

    bmsFaultCodeModal: [false, PropTypes.bool, {
      [actions.setBmsFaultCodeModal]: (state, payload) => payload.state,
      [actions.reset]: () => false
    }],

    loading: [false, PropTypes.bool, {
      [actions.getParamsValue]: () => true,
      [actions.setParamsValues]: () => false,
      [actions.setError]: () => false,
      [actions.reset]: () => false
    }],

    fixNavbar: [false, PropTypes.bool, {
      [actions.setFixNavbar]: (state, payload) => payload,
      [actions.reset]: () => false
    }],

    error: [null, PropTypes.string, {
      [actions.setError]: (state, payload) => payload,
      [actions.reset]: () => null
    }]
  }),
  selectors: ({ selectors }) => ({

    /** Dict of all available routes */
    weatherUnit: [
      () => [ selectors.user ], (user) => {
        if (user && user.weatherUnit === 'celsius') {
          return 'ºC'
        } else {
          return 'F'
        }
      }
    ]
  }),

  start: function * () {
    const { getParamsValue } = this.actions

    yield put(getParamsValue())
  },

  stop: function * () {
    const { reset } = this.actions

    yield put(reset())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getParamsValue]: workers.getParamsValue,
    [actions.updateParamsValue]: workers.getParamsValue
  }),

  workers: {
    * getParamsValue () {
      const { setParamsValues, updateParamsValue, setError } = this.actions

      try {
        const currentDeviceId = yield this.get('currentDeviceId')

        const url = generateUrl(DEVICE_STATUS_PARAMS_ENDPOINT, {
          id: currentDeviceId
        })
        const statusRequest = yield call(axios.get, url)
        const { result } = statusRequest.data

        yield put(setParamsValues(result))

        let timeDelay = FETCH_DEVICE_STATUS_DELAY
        yield delay(timeDelay)

        yield put(updateParamsValue())
      } catch (catchError) {
        const { data } = catchError.response || {}
        if (data && data.message) {
          const translations = yield this.get('translations')
          const errorKey = getApiErrorKey(data.message, 'TECH_PARAMS_')
          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    }
  }

})
