// @flow
import {
  type User
} from 'types/api'
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

import UserProfileMenu from 'containers/UserProfileMenu'
import PageAdaptable from 'containers/PageAdaptable'

import {
  PageTitle,
  InputText,
  PageMainSection,
  SpaceDiv
} from 'xstorage-components'

type Props = {
  isMobile: boolean,
  loading: boolean,
  user: User,
  translations: any
}

export class UserProfileInfo extends Component<Props, void> {
  actions: any

  render () {
    const {
      user,
      isMobile,
      loading,
      translations,
      authProvider
    } = this.props

    const {
      navigate,
      editProfile
    } = this.actions

    const {
      USERPROF_TITLE,
      USERPROF_INFO_TITLE,
      USERPROF_INFO_EDIT_BUTTON,
      USERPROF_INFO_FIRST_NAME,
      USERPROF_INFO_LAST_NAME,
      USERPROF_INFO_PHONE_NUMBER
    } = translations

    return (
      <Wrapper>
        <PageAdaptable
          loading={loading}
          title={USERPROF_TITLE}
          menu={<UserProfileMenu page={'profile'} />}
          onBack={() => navigate('/user-profile/')}
          isForm={false}
        >
          <PageMainSection padding mobile={isMobile}>
            <PageTitle mobile={isMobile}>{USERPROF_INFO_TITLE}</PageTitle>
            <InputText
              id={'FirstName'}
              label={USERPROF_INFO_FIRST_NAME}
              value={user ? user.firstName : ''}
              disabled
            />
            <InputText
              id={'LastName'}
              label={USERPROF_INFO_LAST_NAME}
              value={user ? user.lastName : ''}
              disabled
            />
            <InputText
              id={'Phone'}
              label={USERPROF_INFO_PHONE_NUMBER}
              value={(user && user.phone) ? user.phone : '-'}
              disabled
            />
            <SpaceDiv height={'20px'} />
            {authProvider === 'MICROSOFT' &&
              <Button
                id={'EditButton'}
                noMargin
                onClick={editProfile}
              >
                {USERPROF_INFO_EDIT_BUTTON}
                <Icon>{'open_in_new'}</Icon>
              </Button>
            }
          </PageMainSection>
        </PageAdaptable>
      </Wrapper>
    )
  }
}

export default Logic(UserProfileInfo)

const Wrapper = styled.div`
`

const Button = styled.button`
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
  border-radius: 20px;
  border: 0px;
  background-color: #007BC1;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  text-align: center;
  display: inline-block;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-width: 30%;
  max-height: 40px;
  height: 40px;

  span.material-icons {
    font-size: 16px!important;
    line-height: 16px;
    overflow: visible;
    vertical-align: middle;
    height: 16px;
    margin-left: 15px;
  }
`
