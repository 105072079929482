/* eslint-disable max-len */
// @flow
import { clone, findIndex, mapValues } from 'lodash'
import moment from 'moment-timezone'
import { Chart } from 'react-chartjs-2'
import {
  generateCustomTooltip,
  getChartYAxisValue,
  tooltipLabelCallback
} from 'scenes/Chart/utils'

// filters for select on chart page
export const MAP_TIME_FILTER: {} = {
  'last-7': 'day',
  'last-30': 'week',
  'last-year': 'month'
}

// DEFAULT DATA FOR CHART
const CHART_DEFAULT_DATA = {
  labels: [],
  datasets: [
    {
      key: 'batteryStateOfCharge',
      data: [],
      hidden: false,
      label: 'batteryStateOfCharge',
      backgroundColor: '#007BC1',
      borderColor: '#007BC1',
      yAxisID: 'B'
    },
    {
      key: 'selfConsumption',
      data: [],
      hidden: false,
      label: 'selfConsumption',
      backgroundColor: '#E57F0A',
      borderColor: '#E57F0A',
      yAxisID: 'B'
    },
    {
      key: 'totalHouseConsumption',
      data: [],
      hidden: false,
      label: 'totalHouseConsumption',
      backgroundColor: '#DA3227',
      borderColor: '#DA3227',
      yAxisID: 'A'
    },
    {
      key: 'photovoltaicProduction',
      data: [],
      hidden: false,
      label: 'photovoltaicProduction',
      backgroundColor: '#00B2A9',
      borderColor: '#00B2A9',
      yAxisID: 'A'
    },
    {
      key: 'criticalLoads',
      data: [],
      hidden: false,
      label: 'criticalLoads',
      backgroundColor: '#5B6770',
      borderColor: '#5B6770',
      yAxisID: 'A'
    },
    {
      key: 'nonCriticalLoads',
      data: [],
      hidden: false,
      label: 'nonCriticalLoads',
      backgroundColor: '#87c6d4',
      borderColor: '#87c6d4',
      yAxisID: 'A'
    },
    {
      key: 'selfSufficient',
      data: [],
      hidden: false,
      label: 'selfSufficient',
      backgroundColor: '#408B35',
      borderColor: '#408B35',
      yAxisID: 'B'
    }
  ]
}

/**
 * helper function to draw on bar chart rounder bars
 *
 */
export const initializeBarChart = () => {
  // draw the rounded bars
  Chart.elements.Rectangle.prototype.draw = function () {
    let ctx = this._chart.ctx
    let vm = this._view
    let left, right, top, bottom, signX, signY, borderSkipped, radius
    let borderWidth = vm.borderWidth
    // Set Radius Here
    // If radius is large enough to cause drawing errors a max radius is imposed
    let cornerRadius = 20

    if (!vm.horizontal) {
      // bar
      left = vm.x - vm.width / 2
      right = vm.x + vm.width / 2
      top = vm.y
      bottom = vm.base
      signX = 1
      signY = bottom > top ? 1 : -1
      borderSkipped = vm.borderSkipped || 'bottom'
    } else {
      // horizontal bar
      left = vm.base
      right = vm.x
      top = vm.y - vm.height / 2
      bottom = vm.y + vm.height / 2
      signX = right > left ? 1 : -1
      signY = 1
      borderSkipped = vm.borderSkipped || 'left'
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
      // borderWidth shold be less than bar width and bar height.
      let barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom))
      borderWidth = borderWidth > barSize ? barSize : borderWidth
      let halfStroke = borderWidth / 2
      // Adjust borderWidth when bar top position is near vm.base(zero).
      let borderLeft =
        left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
      let borderRight =
        right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
      let borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
      let borderBottom =
        bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
      // not become a vertical line?
      if (borderLeft !== borderRight) {
        top = borderTop
        bottom = borderBottom
      }
      // not become a horizontal line?
      if (borderTop !== borderBottom) {
        left = borderLeft
        right = borderRight
      }
    }

    ctx.beginPath()
    ctx.fillStyle = vm.backgroundColor
    ctx.strokeStyle = vm.borderColor
    ctx.lineWidth = borderWidth

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    let corners = [
      [left, bottom],
      [left, top],
      [right, top],
      [right, bottom]
    ]

    // Find first (starting) corner with fallback to 'bottom'
    let borders = ['bottom', 'left', 'top', 'right']
    let startCorner = borders.indexOf(borderSkipped, 0)
    if (startCorner === -1) {
      startCorner = 0
    }

    function cornerAt (index) {
      return corners[(startCorner + index) % 4]
    }

    // Draw rectangle from 'startCorner'
    let corner = cornerAt(0)
    ctx.moveTo(corner[0], corner[1])

    for (var i = 1; i < 4; i++) {
      let width = corners[2][0] - corners[1][0]
      let height = corners[0][1] - corners[1][1]
      let x = corners[1][0]
      let y = corners[1][1]

      radius = cornerRadius
      // Fix radius being too large
      if (radius > Math.abs(height) / 2) {
        radius = Math.floor(Math.abs(height) / 2)
      }
      if (radius > Math.abs(width) / 2) {
        radius = Math.floor(Math.abs(width) / 2)
      }

      if (height < 0) {
        // Negative values in a standard bar chart
        let xTl = x
        let xTr = x + width
        let yTl = y + height
        let yTr = y + height

        let xBl = x
        let xBr = x + width
        let yBl = y
        let yBr = y

        // Draw
        ctx.moveTo(xBl + radius, yBl)
        ctx.lineTo(xBr - radius, yBr)
        ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - 0)
        ctx.lineTo(xTr, yTr + radius)
        ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr)
        ctx.lineTo(xTl + radius, yTl)
        ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius)
        ctx.lineTo(xBl, yBl - 0)
        ctx.quadraticCurveTo(xBl, yBl, xBl + 0, yBl)
      } else if (width < 0) {
        // Negative values in a horizontal bar chart
        let xTl = x + width
        let xTr = x
        let yTl = y
        let yTr = y

        let xBl = x + width
        let xBr = x
        let yBl = y + height
        let yBr = y + height

        // Draw
        ctx.moveTo(xBl + radius, yBl)
        ctx.lineTo(xBr - radius, yBr)
        ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - 0)
        ctx.lineTo(xTr, yTr + radius)
        ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr)
        ctx.lineTo(xTl + radius, yTl)
        ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius)
        ctx.lineTo(xBl, yBl - 0)
        ctx.quadraticCurveTo(xBl, yBl, xBl + 0, yBl)
      } else {
        // Positive Value
        ctx.moveTo(x + radius, y)
        ctx.lineTo(x + width - radius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
        ctx.lineTo(x + width, y + height - radius)

        ctx.quadraticCurveTo(x + width, y + height, x + width - 0, y + height)
        ctx.lineTo(x + 0, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - 0)

        ctx.lineTo(x, y + radius)
        ctx.quadraticCurveTo(x, y, x + radius, y)
      }
    }

    ctx.fill()
    if (borderWidth) {
      ctx.stroke()
    }
  }
}

/**
 * get all bar chart options
 *
 * @param {*} timeFilter
 * @param {*} yTicksToHide
 */
export const getChartOptions = (
  timeFilter: string,
  updated: boolean,
  variables: any,
  metrics: any,
  filters: any,
  yTicksToHide?: string = ''
) => {
  // mappings
  const labelOffsetMap = {
    day: 0,
    week: 5,
    month: -3
  }
  const titleDisplayFormat = {
    day: 'DD-MM-YYYY',
    week: 'DD-MM-YYYY',
    month: 'MM-YYYY'
  }

  // values on x axis label
  const xAxisLabelCallback = (
    value: number,
    index: number,
    values: Array<number>
  ) => {
    // on week values change the label format

    /**
     * moment.tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
     * this piece of code allows the conversion of the xaxis values to the unit timezone
     * chartjs has a bug that allows gets the browser timezone and doesn't have the option to change it
     */
    if (values.length && timeFilter === 'week') {
      let startOfWeek = moment
        .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
        .startOf('week')
        .format('MMM DD')
      let endOfWeek = moment
        .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
        .endOf('week')
        .format('DD')

      /* Adjust xaxis labels to start on the 1st day of month
      and end at the last day of the month */

      if (index === 0) {
        startOfWeek = `${moment
          .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
          .endOf('week')
          .format('MMM')} ${moment
          .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
          .startOf('month')
          .format('DD')}`
        endOfWeek = moment
          .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
          .endOf('week')
          .format('DD')
      } else if (index === values.length - 1) {
        startOfWeek = moment
          .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
          .startOf('week')
          .format('MMM DD')
        endOfWeek = moment
          .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
          .endOf('week')
          .format('DD')

        let startOfWeekMonth = moment(
          moment
            .tz(
              moment.tz(values[index].value, moment.tz.guess()),
              moment().tz()
            )
            .startOf('week'),
          'MM-DD-YYYY'
        ).month()
        let endOfWeekMonth = moment(
          moment
            .tz(
              moment.tz(values[index].value, moment.tz.guess()),
              moment().tz()
            )
            .endOf('week'),
          'MM-DD-YYYY'
        ).month()

        if (startOfWeekMonth < endOfWeekMonth || endOfWeekMonth === 0) {
          endOfWeek = moment(
            moment
              .tz(
                moment.tz(values[index].value, moment.tz.guess()),
                moment().tz()
              )
              .startOf('week')
          )
            .endOf('month')
            .format('DD')
        }
      }

      return `${startOfWeek} - ${endOfWeek}`
    }

    if (values.length && timeFilter === 'day') {
      return moment
        .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
        .format('MMM DD')
    }

    if (values.length && timeFilter === 'month') {
      return moment
        .tz(moment.tz(values[index].value, moment.tz.guess()), moment().tz())
        .format('MMM')
    }

    return value
  }

  // values on left axis
  const yAxisLeftCallback = (
    value: number,
    index: number,
    values: Array<number>
  ) => getChartYAxisValue(value, values, 'Wh')

  // values on right axis
  const yAxisRightCallback = (value: number) => `${value}%`

  // value of title tooltip
  const tooltipTitleCallback = (tooltipItem, data) => {
    let date = tooltipItem[0].xLabel.split(' ')
    date = date.join(' ')
    const index = tooltipItem[0].index

    if (timeFilter === 'week') {
      const firstDateFromData = moment(data.datasets[0].data[0].x).format(
        'DD MM YYYY'
      )
      const startOfWeekMonth = moment(
        moment(data.labels[index]).startOf('week')
      ).month()
      const endOfWeekMonth = moment(
        moment(data.labels[index]).endOf('week')
      ).month()
      const firstDateFromDataMonth = moment(
        firstDateFromData,
        'DD MM YYYY'
      ).month()

      if (
        startOfWeekMonth < firstDateFromDataMonth ||
        (startOfWeekMonth > endOfWeekMonth &&
          firstDateFromDataMonth === 0 &&
          endOfWeekMonth === 0)
      ) {
        return moment(firstDateFromData, 'DD MM YYYY')
          .startOf('month')
          .format(titleDisplayFormat[timeFilter])
      } else if (
        endOfWeekMonth > firstDateFromDataMonth ||
        (startOfWeekMonth > endOfWeekMonth &&
          firstDateFromDataMonth !== 0 &&
          endOfWeekMonth === 0)
      ) {
        return moment(firstDateFromData, 'DD MM YYYY')
          .endOf('month')
          .format(titleDisplayFormat[timeFilter])
      } else {
        return tooltipItem[0]
          ? moment(data.labels[index]).format(titleDisplayFormat[timeFilter])
          : ''
      }
    }

    return tooltipItem[0]
      ? moment(date, 'MMM DD, YYYY').format(titleDisplayFormat[timeFilter])
      : ''
  }

  let activeVars: number = 0
  mapValues(variables, v => v && activeVars++)
  // initialize the options chart object

  const options: any = {
    elements: {
      point: {
        radius: 0
      }
    },
    animation: {
      duration: 0
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false,
      intersect: false,
      callbacks: {
        label: (tooltipItems, data) =>
          tooltipLabelCallback(tooltipItems, data, 'Wh'),
        title: tooltipTitleCallback
      },
      custom: generateCustomTooltip
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 50,
        bottom: 0
      }
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          distribution: 'linear',
          offset: true,
          gridLines: {
            display: false
          },
          barPercentage: activeVars > 1 ? 0.9 : 0.4,
          time: {
            displayFormats: {
              day: 'MM-DD-YYYY',
              week: 'MM-DD-YYYY',
              month: 'MMM'
            },
            unit: timeFilter || 'day',
            stepSize: 1
          },
          ticks: {
            fontColor: 'rgba(91,103,112, 0.5)',
            fontSize: 10,
            autoSkip: false,
            labelOffset: labelOffsetMap[timeFilter],
            callback: xAxisLabelCallback,
            source: 'labels'
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position: 'left',
          ticks: {
            min: 0,
            display: true,
            fontColor: yTicksToHide.indexOf('A') === -1 ? '#007BC1' : '#CCCCCC',
            fontStyle: 'bold',
            callback: yAxisLeftCallback
          }
        },
        {
          id: 'B',
          position: 'right',
          gridLines: {
            display: false
          },
          ticks: {
            min: 0,
            max: 100,
            stepSize: 20,
            display: true,
            fontColor: yTicksToHide.indexOf('B') === -1 ? '#A0C599' : '#CCCCCC',
            fontStyle: 'bold',
            callback: yAxisRightCallback
          }
        }
      ]
    }
  }

  // check if chart is updated
  // need to be on if, if is setted on options raise an exception when
  // value is true
  if (updated) {
    options.animation = false
  }

  return options
}

/**
 * Function that returns chart data with each dataset options
 *
 * @param {*} canvas
 * @param {*} metrics
 * @param {*} variables
 * @param {*} translations
 */
export const getChartData = (
  canvas,
  metrics = {},
  variables = {},
  timeFilter = 'today',
  translations = {},
  filters = {}
) => {
  const data: any = { ...CHART_DEFAULT_DATA }

  let from = null
  let to = null

  if (timeFilter === 'week') {
    from = moment(filters.selectedDayPicker)
      .startOf('day')
      .startOf('month')
      .startOf('week')
    to = moment(filters.selectedDayPicker)
      .endOf('day')
      .endOf('month')
      .endOf('week')
  } else {
    from = moment.tz(moment.unix(metrics.from), moment().tz()).format()
    to = moment.tz(moment.unix(metrics.to), moment().tz()).format()
  }

  data.labels = [from, to]
  data.datasets = [...data.datasets].map(dataset => {
    return {
      ...dataset,
      ...{
        label: translations[`CHART_VAR_${dataset.key.toUpperCase()}`],
        data: metrics[dataset.key],
        hidden: !variables[dataset.key]
      }
    }
  })

  // Check if there are days without data and insert null data for those days
  if (timeFilter === 'day') {
    let dataDatasets = data.datasets
    dataDatasets.map(datasetData => {
      let dData = datasetData.data
      for (var i = 0; i < dData.length; i++) {
        if (i + 1 < dData.length) {
          var date1 = dData[i].x
          var date2 = dData[i + 1].x

          if (moment(date1).diff(date2, 'days') > 1) {
            let dateMinusOneDay = moment(date1).subtract(1, 'days')
            dData.splice(i + 1, 0, { x: dateMinusOneDay, y: null })
          }
        }
      }
      return dData
    })

    var labelsArray = []

    for (var i = 0; i < 7; i++) {
      let nextDay = null
      nextDay = moment(from).add(i, 'days')

      labelsArray.push(moment.utc(moment.tz(nextDay, moment().tz()).format()))
    }

    data.labels = labelsArray
  }

  if (timeFilter === 'week') {
    let weeksInMonth = getNumberWeeksInMonth(
      moment(to)
        .startOf('week')
        .month(),
      moment(to)
        .startOf('week')
        .year()
    )
    const { datasets } = data
    let labelsArray = []
    let dataWeek = []

    datasets.forEach(dataset => {
      labelsArray = []
      dataWeek = []
      let { data } = dataset
      weeksInMonth.forEach((week, index) => {
        let xDate = null
        const startOfWeekMonth = moment(to).startOf('week').month()
        /** Last week of December ending in January */
        if (week === 1 && startOfWeekMonth === 11) {
          const fixedYear =
            moment(to)
              .startOf('week')
              .year() + 1
          xDate = moment
            .tz()
            .day('Monday')
            .isoWeek(week)
            .isoWeekYear(fixedYear)
            .toDate()
          /** Last week of December ending in January */
        } else if (week === 52 && startOfWeekMonth === 11) {
          const fixedYear =
            moment(to)
              .startOf('week')
              .year()
          const aux = moment
            .tz()
            .day('Monday')
            .isoWeek(week)
            .isoWeekYear(fixedYear)
            .add(1, 'year')
            .subtract(1, 'day')
            .toDate()
          xDate = moment(aux).subtract(1, 'year')
        } else if (week === 53 && startOfWeekMonth === 11) {
          const fixedYear =
            moment(to)
              .startOf('week')
              .year()
          xDate = moment
            .tz()
            .day('Monday')
            .isoWeek(week)
            .isoWeekYear(fixedYear)
            .add(1, 'year')
            .subtract(1, 'day')
            .toDate()
        } else if (week === 52 && startOfWeekMonth === 0) {
          const fixedYear =
            moment(to)
              .startOf('week')
              .year() - 1
          xDate = moment
            .tz()
            .day('Monday')
            .isoWeek(week)
            .isoWeekYear(fixedYear)
            .toDate()
        } else {
          xDate = moment
            .tz()
            .day('Monday')
            .isoWeek(week)
            .isoWeekYear(moment(to).startOf('week').year())
            .toDate()
        }
        let indexData = findIndex(data, { x: week.toString() })
        if (indexData === -1 && (week === 52 || week === 53)) {
          indexData = findIndex(data, { x: '0' })
        }
        if (indexData !== -1) {
          dataWeek.push({ x: xDate, y: data[indexData].y })
        } else {
          dataWeek.push({ x: xDate, y: 0 })
        }
      })

      dataWeek.forEach(element => {
        labelsArray.push(element.x)
      })

      dataset.data = clone(dataWeek)
    })
    data.labels = labelsArray
  }

  if (timeFilter === 'month') {
    let dataDatasets = data.datasets
    dataDatasets.map(datasetData => {
      let dData = datasetData.data
      for (var i = 0; i < dData.length; i++) {
        if (i + 1 < dData.length) {
          var date1 = dData[i].x
          var date2 = dData[i + 1].x

          if (moment(date1).diff(date2, 'month') > 1) {
            let dateMinusOneDay = moment(date1).subtract(1, 'month')
            dData.splice(i + 1, 0, { x: dateMinusOneDay, y: null })
          }
        }
      }
      return dData
    })

    labelsArray = []

    for (var j = 0; j < 12; j++) {
      let nextMonth = null
      nextMonth = moment(from).add(j, 'month')

      labelsArray.push(moment.utc(moment.tz(nextMonth, moment().tz()).format()))
    }

    data.labels = labelsArray
  }

  return data
}

export const getNumberWeeksInMonth = function (month, year) {
  let monthCorrect = month + 1

  const firstDayOfMonth = moment(`${year}-${monthCorrect}`, 'YYYY-MM-DD')
  const numOfDays = firstDayOfMonth.daysInMonth()
  let weeks = new Set()

  for (let i = 0; i < numOfDays; i++) {
    const currentDay = moment(firstDayOfMonth, 'YYYY-MM-DD').add(i, 'days')
    weeks.add(currentDay.isoWeek())
  }
  var weeksArray = []
  weeks.forEach(v => weeksArray.push(v))
  return weeksArray
}
