import {
  COMMANDS_PARAMS,
  getCommandTranslation
} from 'containers/OperationModeForm/utils'
import cloneDeep from 'lodash/cloneDeep'

const OPERATION_MODES = {
  isGermany: ['SET_CHARGE', 'SET_VARIABLE_GRID_INJECTION'],
  isSettings: [
    'SET_BASIC_MODE',
    'SET_MAXIMIZE_AUTO_CONSUMPTION',
    'SET_VARIABLE_GRID_INJECTION',
    'SET_FREQUENCY_REGULATION',
    'SET_PEAK_SHAVING'
  ],
  batteryReadyMode: ['SET_BASIC_MODE']
}

export const getOperationModeList = (translations, operationModeCriteria) => {
  let operationModeOptions = Object.keys(COMMANDS_PARAMS).map(command => ({
    val: command,
    label: getCommandTranslation(translations, command)
  }))

  for (var element in operationModeCriteria) {
    if (operationModeCriteria[element]) {
      const operationModesList = cloneDeep(OPERATION_MODES[element])

      operationModeOptions = operationModeOptions.filter(opMode => {
        return operationModesList.indexOf(opMode.val) !== -1
      })
    }
  }

  return operationModeOptions
}
