/**
 * MaintenanceHttpsTunnel Scene
 * https://jira-prod.tcc.etn.com/browse/XSPD-1826
 * Open an HTTPS tunnel on demand
 */
// @flow
import { kea } from 'kea'
import axios from 'axios'
import PropTypes from 'prop-types'
import { API_BASE_URL } from 'config'
import { generateUrl, log } from 'utils'
import { getApiErrorKey } from 'apiErrors'
import AppLogic from 'containers/App/logic'
import { put, call, delay } from 'redux-saga/effects'
import { checkRolePermission } from 'containers/UserPermission/utils'

// eslint-disable-next-line max-len
const HTTPS_OPENTUNNEL_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/https_tunnel/open`
// eslint-disable-next-line max-len
const HTTPS_CLOSETUNNEL_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/https_tunnel/close`
// eslint-disable-next-line max-len
const HTTPS_TUNNEL_ENDPOINT = `${API_BASE_URL}/devices/{deviceId}/maintenance/https_tunnel`

export const DEFAULT_VALUES = {
  enabled: false,
  technicianPassword: null,
  port: null
}

export default kea({
  path: () => ['scenes', 'MaintenanceHttpsTunnel'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'isMobile',
        'currentDeviceId',
        'currentDevice',
        'currentRoute',
        'userRole'
      ],
      ({ scenes }) => scenes.containers.MaintenanceSupportedFeatures, [
        'featureSupport'
      ]
    ],
    actions: [
      AppLogic, [
        'navigate',
        'fetchCurrentDeviceInfo'
      ]
    ]
  },

  actions: () => ({
    getHttpsStatus: () => true,
    setHttpsStatus: status => status,
    reset: () => true,
    setOpenStatus: status => status,
    setError: error => error,
    setLoading: loading => loading,
    setNumberGetRequests: requests => requests,
    updateHttpsStatus: tunnelrequest => ({ tunnelrequest })
  }),

  reducers: ({ actions }) => ({
    httpsStatus: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.setHttpsStatus]: (state, payload) => payload,
        [actions.reset]: () => DEFAULT_VALUES
      }
    ],

    loading: [
      false,
      PropTypes.bool,
      {
        [actions.setLoading]: (state, payload) => payload,
        [actions.getHttpsStatus]: () => true,
        [actions.setHttpsStatus]: () => false,
        [actions.setError]: () => false,
        [actions.setOpenStatus]: () => true,
        [actions.reset]: () => false
      }
    ],

    error: [
      null,
      PropTypes.string,
      {
        [actions.getHttpsStatus]: () => null,
        [actions.setError]: (state, payload) => payload,
        [actions.reset]: () => null
      }
    ],

    numberGetRequests: [0, PropTypes.number, {
      [actions.setNumberGetRequests]: (state, payload) => payload,
      [actions.updateHttpsStatus]: (state, payload) => state + 1,
      [actions.reset]: () => 0
    }]
  }),

  selectors: ({ selectors }) => ({
    isSupportMenu: [
      () => [selectors.currentRoute],
      (currentRoute) =>
        currentRoute && currentRoute.key === 'SupportHttpsTunnel',
      PropTypes.bool
    ]
  }),

  start: function * () {
    log('[XS-HTTPS Status] Start Scene', 'yellow')

    const { navigate } = this.actions
    const userRole = yield this.get('userRole')

    const userCanViewHttpsTunnel = checkRolePermission(
      'HTTPS_TUNNEL',
      userRole
    )

    if (!userCanViewHttpsTunnel) {
      yield put(navigate('/401'))
    }

    const { getHttpsStatus } = this.actions
    yield put(getHttpsStatus())
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getHttpsStatus]: workers.getHttpsStatus,
    [actions.updateHttpsStatus]: workers.getHttpsStatus,
    [actions.setOpenStatus]: workers.setOpenStatus
  }),

  workers: {
    * getHttpsStatus (actionPayload) {
      const { payload } = actionPayload
      const { tunnelrequest } = payload || {}
      const isUpdate = typeof tunnelrequest === 'boolean'

      const {
        setHttpsStatus,
        setLoading,
        setError,
        updateHttpsStatus,
        setNumberGetRequests
      } = this.actions
      const currentDeviceId = yield this.get('currentDeviceId')
      const translations = yield this.get('translations')
      const numberGetRequests = yield this.get('numberGetRequests')

      try {
        const url = generateUrl(HTTPS_TUNNEL_ENDPOINT, {
          deviceId: currentDeviceId
        })
        const statusRequest = yield call(axios.get, url)
        const { result } = statusRequest.data

        const changedStatus = tunnelrequest === result.enabled

        if (
          numberGetRequests > 0 &&
          numberGetRequests < 4 &&
          isUpdate &&
          !changedStatus
        ) {
          yield delay(4000)
          yield put(updateHttpsStatus())
        } else {
          yield put(setNumberGetRequests(0))
          yield put(setHttpsStatus(result))
        }

        if (result.enabled && result.technicianPassword === '') {
          yield put(
            setError(
              translations['UNITSETT_SUPPORT_HTTPSSTATUS_EMPTY_TECHPASS']
            )
          )
        }
      } catch (catchError) {
        yield put(setLoading(false))
        const { data } = catchError.response || {}
        if (data) {
          const errorKey = getApiErrorKey(data.message, 'API_ERROR_')
          yield put(setError(translations[errorKey] || ''))
        } else {
          console.log(catchError)
        }
      }
    },

    * setOpenStatus (actionPayload) {
      const { payload } = actionPayload
      const {
        updateHttpsStatus,
        setError,
        fetchCurrentDeviceInfo,
        setLoading
      } = this.actions

      const currentDeviceId = yield this.get('currentDeviceId')
      const translations = yield this.get('translations')

      try {
        const closeOrOpenTunnel = payload
          ? HTTPS_OPENTUNNEL_ENDPOINT
          : HTTPS_CLOSETUNNEL_ENDPOINT
        const url = generateUrl(closeOrOpenTunnel, {
          deviceId: currentDeviceId
        })

        const config = {
          headers: { 'content-type': 'application/json' }
        }

        const response = yield call(axios.post, url, config)

        const { status, data } = response
        const { successful } = data

        if (successful) {
          if (status === 202) {
            yield put(setLoading(false))
            yield put(fetchCurrentDeviceInfo())
          } else {
            yield delay(4000)
            yield put(updateHttpsStatus(payload))
          }
        }
      } catch (catchError) {
        const { data } = catchError.response || {}

        if (data) {
          const errorKey = getApiErrorKey(data.message)
          yield put(setError(translations[errorKey]))
        } else {
          console.log(catchError)
        }
      }
    }
  }

})
