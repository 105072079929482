/**
 * TourLayout Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
// @flow
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import PropTypes from 'prop-types'
import AppLogic from 'containers/App/logic'

export default kea({
  path: () => ['scenes', 'containers', 'TourLayout'],

  connect: {
    props: [
      AppLogic, [
        'isMobile',
        'translations',
        'user',
        'showHelpTour'
      ]
    ],
    actions: [
      AppLogic, [
        'setShowHelpTour'
      ]
    ]
  },

  actions: () => ({
    setSwitcherSelected: status => status,
    reset: () => true
  }),

  reducers: ({ actions }) => ({
    switcherSelected: [ 'battery', PropTypes.string, {
      [actions.setSwitcherSelected]: (state, payload) => payload,
      [actions.reset]: () => 'battery'
    }]
  }),

  stop: function * () {
    const { reset } = this.actions
    yield put(reset())
  }

})
