// @flow
import { kea } from 'kea'
import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import { API_BASE_URL } from 'config'
import { replaceRefByTranslation } from './utils'
import { generateUrl } from 'utils'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import FormData from 'form-data'
import AppLogic from 'containers/App/logic'

// eslint-disable-next-line max-len
const NOTIFICATIONS_ENDPOINT = `${API_BASE_URL}/notifications?channel=BOTH,SOCKET&status=NORMAL,READ`
// eslint-disable-next-line max-len
const NOTIFICATIONS_ADMIN_ENDPOINT = `${API_BASE_URL}/notifications?channel=BOTH,SOCKET&status=NORMAL,READ&userId={userId}`
const NOTIFICATIONS_READ_ENDPOINT = `${API_BASE_URL}/notifications/read`

export default kea({
  path: () => ['scenes', 'containers', 'NotificationsPanel'],

  connect: {
    props: [
      AppLogic, [
        'translations',
        'user'
      ]
    ]
  },

  actions: () => ({
    showNotifications: () => true,
    hideNotifications: () => true,
    getNotifications: () => true,
    resetNotifications: () => true,
    setNotifications: (notifications) => ({ notifications }),
    setNotificationsTotal: (total) => ({ total }),
    setLoadingButton: () => true,
    setLoading: loading => loading,
    setShowLoadMoreInfo: () => true,
    markNotificationsAsRead: (notifications) => ({ notifications }),
    setUnreadNotificationsValue: (unread) => ({ unread }),
    updateNotifications: (notifications) => (notifications)
  }),

  reducers: ({ actions }) => ({
    notificationsVisible: [false, PropTypes.bool, {
      [actions.showNotifications]: (state, payload) => true,
      [actions.hideNotifications]: (state, payload) => false
    }],

    notificationsList: [[], PropTypes.array, {
      [actions.setNotifications]:
        (state, payload) => [ ...state, ...payload.notifications ],
      [actions.updateNotifications]: (state, payload) => payload,
      [actions.hideNotifications]: () => [],
      [actions.resetNotifications]: () => []
    }],

    notificationsTotal: [0, PropTypes.number, {
      [actions.setNotificationsTotal]: (state, payload) => payload.total,
      [actions.hideNotifications]: () => 0,
      [actions.resetNotifications]: () => 0
    }],

    unreadNotifications: [0, PropTypes.number, {
      [actions.setUnreadNotificationsValue]: (state, payload) => payload.unread,
      [actions.hideNotifications]: () => 0
    }],

    loadingButton: [false, PropTypes.bool, {
      [actions.setLoadingButton]: (state) => !state,
      [actions.hideNotifications]: () => false
    }],

    showLoadMoreInfo: [false, PropTypes.bool, {
      [actions.setShowLoadMoreInfo]: (state) => true,
      [actions.hideNotifications]: () => false
    }],

    loading: [false, PropTypes.bool, {
      [actions.setLoading]: (state, payload) => payload,
      [actions.showNotifications]: () => true,
      [actions.setNotifications]: () => false
    }]
  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.getNotifications]: workers.getNotifications,
    [actions.showNotifications]: workers.getNotifications,
    [actions.markNotificationsAsRead]: workers.markNotificationsAsRead
  }),

  workers: {
    * getNotifications () {
      const notificationsList = yield this.get('notificationsList')
      const translations = yield this.get('translations')
      const user = yield this.get('user')

      const {
        setNotifications,
        setNotificationsTotal,
        setShowLoadMoreInfo,
        setLoadingButton,
        markNotificationsAsRead
      } = this.actions

      yield put(setLoadingButton())

      try {
        // eslint-disable-next-line max-len
        let url = NOTIFICATIONS_ENDPOINT + `&size=4&offset=${notificationsList.length}`

        if (user.isAdmin) {
          // eslint-disable-next-line max-len
          url = generateUrl(NOTIFICATIONS_ADMIN_ENDPOINT, { userId: user.id }) + `&size=4&offset=${notificationsList.length}`
        }
        const response = yield call(axios.get, url)
        const { result } = response.data

        yield put(setNotificationsTotal(result.total))

        if (result.total > result.size) {
          yield put(setShowLoadMoreInfo())
        }

        if (result && result.results.length > 0) {
          result.results.forEach(notification => {
            // eslint-disable-next-line max-len
            notification.title = translations['NOTIFICATIONS_TITLE_' + notification.subType]

            if (notification.previousAlertId && notification.level === 'INFO') {
              // eslint-disable-next-line max-len
              notification.subtitle = translations['NOTIFICATIONS_SUBTITLE_ALARM_RESOLVED']
            } else {
              // eslint-disable-next-line max-len
              notification.subtitle = translations['NOTIFICATIONS_SUBTITLE_' + notification.subType]
            }

            const isToday = moment().isSame(moment(notification.timestamp), 'd')

            if (!isToday) {
              // eslint-disable-next-line max-len
              notification.date = moment(notification.timestamp).format('DD/MM/YYYY, HH:mm')
            } else {
              // eslint-disable-next-line max-len
              const duration = moment.duration(moment().diff(moment(notification.timestamp)))
              const durationHours = duration.hours()

              if (durationHours >= 1) {
                // eslint-disable-next-line max-len
                notification.date = `${translations['NOTIFICATIONS_TODAY']}, ${moment(notification.timestamp).format('HH:mm')}`
              } else {
                const durationMinutes = duration.minutes()

                if (durationMinutes === 0) {
                  // eslint-disable-next-line max-len
                  notification.date = `${translations['NOTIFICATIONS_TODAY']}, ${translations['NOTIFICATIONS_JUST_NOW']}`
                } else if (durationMinutes === 1) {
                  // eslint-disable-next-line max-len
                  notification.date = `${translations['NOTIFICATIONS_TODAY']}, ${translations['NOTIFICATIONS_MINUTE_AGO']}`
                } else {
                  // eslint-disable-next-line max-len
                  notification.date = `${translations['NOTIFICATIONS_TODAY']}, ${translations['NOTIFICATIONS_MINUTES_AGO'].replace('NUMBER_MIN', durationMinutes)}`
                }
              }
            }

            if (notification.content) {
              const keysContent = Object.keys(notification.content)

              keysContent.forEach(keyContent => {
                if (keyContent === 'url') {
                  // eslint-disable-next-line max-len
                  notification.title = notification.title.replace('<' + keyContent + '>', `<a href="${notification.content[keyContent]}" target="_blank">URL</a>`)
                  // eslint-disable-next-line max-len
                  notification.subtitle = notification.subtitle.replace('<' + keyContent + '>', `<a href="${notification.content[keyContent]}" target="_blank">URL</a>`)
                } else {
                  if (notification.title.includes('<' + keyContent + '>')) {
                    // eslint-disable-next-line max-len
                    notification.title = notification.title.replace('<' + keyContent + '>', notification.content[keyContent])
                  }

                  if (
                    notification.subtitle &&
                    notification.subtitle.includes('<' + keyContent + '>')
                  ) {
                    // eslint-disable-next-line max-len
                    notification.subtitle = notification.subtitle.replace('<' + keyContent + '>', notification.content[keyContent])
                  }

                  if (
                    notification.subtitle &&
                    notification.subtitle.includes('<' + keyContent + '>')
                  ) {
                    // eslint-disable-next-line max-len
                    notification.subtitle = notification.subtitle.replace('<' + keyContent + '>', notification.content[keyContent])
                  }
                }
              })

              // eslint-disable-next-line max-len
              notification.title = replaceRefByTranslation(notification.title, translations)
              if (notification.subtitle) {
                // eslint-disable-next-line max-len
                notification.subtitle = replaceRefByTranslation(notification.subtitle, translations)
              }
            }
          })

          yield put(setNotifications(result.results))

          yield put(markNotificationsAsRead(result.results))
        } else {
          yield put(setNotifications([]))
        }

        yield put(setLoadingButton())
      } catch (err) {
        console.log(err)
      }
    },
    * markNotificationsAsRead (payload) {
      const notificationsList = payload.payload.notifications
      let markAsReadArray = []

      notificationsList.forEach(notification => {
        if (notification.status === 'NORMAL') {
          markAsReadArray.push(notification.id)
        }
      })

      if (markAsReadArray.length > 0) {
        const notificationMarkAsRead = markAsReadArray.join()
        const data = new FormData()
        data.append('id', notificationMarkAsRead)

        try {
          const config = {
            headers: { 'Content-Type': 'multipart/form-data' }
          }

          yield call(axios.post, NOTIFICATIONS_READ_ENDPOINT, data, config)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

})
